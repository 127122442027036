import React from 'react';

import { SVG } from 'types/svg';

// TODO: After fating is in main, fix this
function StartFastingFasting(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={104} height={104} xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="prefix__897c0eeb-cd93-4551-9e85-36991f6c4282"
        >
          <stop stopColor="#03CEA4" offset="0%" />
          <stop stopColor="#29ABE2" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          fill="url(#prefix__897c0eeb-cd93-4551-9e85-36991f6c4282)"
          cx={52}
          cy={52}
          r={52}
        />
        <path d="M24 24h56v56H24z" />
        <path
          d="M39.764 39.921c-1.52 1.402-3.272 3.038-5.025 5.609-1.636 2.57-5.375 9.464-3.622 17.644 1.402 6.66 6.193 12.736 12.385 15.54 1.169.585 2.454.935 3.74 1.286h.233c.584 0 1.052-.35 1.169-.935.117-.584-.234-1.285-.818-1.402-1.169-.234-4.908-1.519-6.427-4.79-1.636-3.623.117-7.946 2.22-10.634.935-1.168 1.52-1.636 2.337-2.22.701-.584 1.52-1.168 2.454-2.22 2.103-2.454 3.389-5.258 3.74-8.53.817 1.402 1.285 2.805 1.401 4.09 0 1.051-.117 2.103-.467 3.271-.35 1.636-.701 3.622-.818 6.66 0 1.52 0 3.973 1.986 4.791 1.636.584 3.74-.117 4.908-1.169.35-.35.584-.7.818-1.168.35.584.7 1.051.935 1.636 1.519 3.622-.234 6.777-1.052 7.945-.35.468-.233 1.169.117 1.52.468.35 1.052.467 1.52.116 4.673-3.505 8.062-7.712 10.048-12.385 3.038-7.362 2.22-15.307-2.454-22.902-7.712-12.035-23.02-16.475-27.46-17.644-.467-.117-.934.117-1.285.468-.233.35-.233.935 0 1.285 2.454 3.272 3.389 6.193 2.921 8.881-.233 2.337-1.635 3.622-3.505 5.258z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default StartFastingFasting;
