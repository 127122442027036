import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { User, UnitSystem } from 'types/user';
import { isKeto, isKetoGo, isHealthgenom } from 'helpers/themeHelper';

import { ReactComponent as CongratsIcon } from 'assets/images/modals/congrats.svg';
import { ReactComponent as CongratsIconKeto } from 'assets/images/modals/keto/congratsKeto.svg';
import { ReactComponent as CongratsIconKetogo } from 'assets/images/modals/ketogo/congratsKetogo.svg';
import s from './TestimonialItem.module.scss';

const TestimonialItem: React.FC = () => {
  const { t } = useTranslation('testimonial');
  const { first_name, unit_system, stats } = useSelector<any, User>(
    (store) => store.rootReducer.user
  );

  const lost = unit_system === UnitSystem.si ? stats?.lost : stats?.lost_in_lbs;

  const renderCongratsIcon = () => {
    if (isKeto()) {
      return <CongratsIconKeto className={s.icon} />;
    }
    if (isKetoGo()) {
      return <CongratsIconKetogo className={s.icon} />;
    }
    if (isHealthgenom()) {
      return <CongratsIconKeto className={s.icon} />;
    }

    return <CongratsIcon className={s.icon} />;
  };

  return (
    <Link to="/testimonial" className={s.link}>
      <div className={s.container}>
        <div className={s.header}>
          {renderCongratsIcon()}
          <h3 className={s.title}>
            {t('testimonial:modal.title', {
              name: first_name,
              weight: lost,
              unit: t('units.weight', { context: unit_system }),
            })}
          </h3>
        </div>
        <p className={s.text}>{t('testimonial:modal.text')}</p>
      </div>
    </Link>
  );
};

export default TestimonialItem;
