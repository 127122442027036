import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { User } from 'types/user';
import { FileExtension } from 'helpers/imageHelper';
import { isPurchasableExpressPlan } from 'helpers/expressPlanHelper';

import { ResponsiveImage } from 'components/common';
import ExpressPlanCarousel from './ExpressPlanCarousel';
import ExpressPlanFooter from './ExpressPlanFooter';
import ExpressPlanTimer from './ExpressPlanTimer';

import s from './ExpressPlan.module.scss';

function ExpressPlan() {
  const { t } = useTranslation('expressplan');
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const isPurchasable = isPurchasableExpressPlan(user);

  return (
    <div className={s.container}>
      <div className={s.header}>
        <ResponsiveImage
          imagePath="./express-plan/nutrionist"
          imageExtension={FileExtension.png}
          altText="nutrionist"
          style={s.nutrionistImage}
        />
        <p className={s.nutrionistText}>
          <Trans
            i18nKey="expressplan:express_plan_program.nutrionist_text"
            components={[<span className={s.nutrionistTextBolded} />]}
          />
        </p>
        <p className={s.nutrionistBubble}>
          {t('express_plan_program.nutrionist_bubble', {
            name: user.first_name,
          })}
        </p>
        <ExpressPlanTimer />
      </div>
      <div
        className={`${s.carousel} ${
          !isPurchasable && s.nonPurchasableCarousel
        }`}
      >
        <ExpressPlanCarousel user={user} />
        {isPurchasable && (
          <div className={s.footerContainer}>
            <ExpressPlanFooter user={user} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ExpressPlan;
