import i18n from 'i18n';
import { ThunkActionType } from 'store/types';
import { addError } from 'redux-flash-messages';

import {
  addFreeAccessToUserAPI,
  getUnsubscribeCommonDataAPI,
  unsubscribe,
} from 'api/SubscriptionAPI';
import * as types from 'actions/actionTypes';
import ResponseHandler from 'actions/ResponseHandler';
import { api } from 'helpers/api';
import { UnsubscribeCommonData } from 'types/unsubscribe';
import { getUserData } from './programActions';

type PayloadAny = any;

export const unsubscribeBeginAction = () => ({
  type: types.UNSUBSCRIBE.ACTION,
});

export const unsubscribeSuccessAction = (user: PayloadAny) => ({
  type: types.UNSUBSCRIBE.SUCCESS,
  user,
});

export const unsubscribeErrorAction = (error: PayloadAny) => ({
  type: types.UNSUBSCRIBE.ERROR,
  error,
});

export const unsubscribeUserAction =
  (id: number): ThunkActionType =>
  (dispatch) => {
    dispatch(unsubscribeBeginAction());
    return unsubscribe(id)
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) throw resHandler.getFlashMsgText();
        else
          dispatch(
            unsubscribeSuccessAction(resHandler.getNormalizedResponse())
          );
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(unsubscribeErrorAction(error));
      });
  };

export const getUnsubscribeV4Data = async (subscriptionId: number) => {
  try {
    const response = await api<UnsubscribeCommonData>(
      `api/v1/users/subscriptions/${subscriptionId}/unsubscribe-custom-data`,
      {
        method: 'GET',
      }
    );
    const resHandler = new ResponseHandler(response);

    return resHandler.getNormalizedResponse();
  } catch (e) {
    const text = i18n.t('error.500_error');
    addError({ text });
    return false;
  }
};

export const setSubscriptionPrice = async (
  subscriptionId: number,
  offerPriceId: number
) => {
  try {
    await api(
      `api/v1/users/subscriptions/${subscriptionId}/prices/${offerPriceId}`,
      {
        method: 'PUT',
      }
    );
    return true;
  } catch (e) {
    const text = i18n.t('error.500_error');
    addError({ text });
    return false;
  }
};

export const unsubscribeCommondataLoad = () => ({
  type: types.UNSUBSCRIBE_COMMONDATA_LOAD,
});

export const unsubscribeCommondataSuccess = (
  unsubscribeCommondata: UnsubscribeCommonData
) => ({
  type: types.UNSUBSCRIBE_COMMONDATA_SUCCESS,
  unsubscribeCommondata,
});

export const unsubscribeCommondataError = () => ({
  type: types.UNSUBSCRIBE_COMMONDATA_ERROR,
});

export const getUnsubscribeData =
  (subscriptionId: number): ThunkActionType =>
  (dispatch) => {
    dispatch(unsubscribeCommondataLoad());
    return getUnsubscribeCommonDataAPI(subscriptionId)
      .then((response) => {
        const resHandler = new ResponseHandler(response);

        if (resHandler.hasError()) {
          throw resHandler.getFlashMsgText();
        } else {
          dispatch(
            unsubscribeCommondataSuccess(resHandler.getNormalizedResponse())
          );
        }
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(unsubscribeCommondataError());
      });
  };

export const freeAccessToUserLoad = () => ({
  type: types.FREE_ACCESS_TO_USER_LOAD,
});

export const freeAccessToUserSuccess = () => ({
  type: types.FREE_ACCESS_TO_USER_SUCCESS,
});

export const freeAccessToUserError = () => ({
  type: types.FREE_ACCESS_TO_USER_ERROR,
});

export type FreeAccessPayload = {
  items: string[];
};

export const addFreeAccessToUser =
  (
    subscriptionId: number,
    freeAccessPayload: FreeAccessPayload
  ): ThunkActionType =>
  (dispatch) => {
    dispatch(freeAccessToUserLoad());
    return addFreeAccessToUserAPI(subscriptionId, freeAccessPayload)
      .then(() => {
        dispatch(getUserData());
        dispatch(freeAccessToUserSuccess());
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(freeAccessToUserError());
      });
  };
