import React from 'react';

import { SVG } from 'types/svg';

function Check(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6.31 20.45a2.72 2.72 0 0 0 3.83 0L23.21 7.38a2.71 2.71 0 1 0-3.83-3.83L8.23 14.71 4.62 11.1a2.71 2.71 0 0 0-3.83 3.83z" />
    </svg>
  );
}

export default Check;
