import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { addModal } from 'actions/modalActions';
import { ModalType } from 'types/modals';
import { Cycle, Timer } from 'types/fasting';

import { HorseShoe, HorseShoeStale } from 'components/ProgressBar';
import { SubmitButton } from 'components/common';

import s from './FastingBody.module.scss';

type Props = {
  cycle: Cycle;
  timer?: Timer;
};

function FastingBody({ cycle, timer }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={s.cardContainer}>
      <div className={s.cardWrapper}>
        <div className={s.leftSide}>
          <span className={s.cardTitle}>
            {t('items.type.fasting.first_timer.title')}
          </span>
          <p className={s.cardTimer}>{cycle.fasting}h</p>
          <p className={s.cardText}>
            {t('items.type.fasting.first_timer.text')}
          </p>
        </div>
        <div className={s.rightSide}>
          {timer ? (
            <HorseShoe cycle={cycle} timer={timer} />
          ) : (
            <HorseShoeStale />
          )}
        </div>
      </div>
      <SubmitButton
        label={t('items.type.fasting.first_timer.button')}
        type="large"
        onClick={() => dispatch(addModal({ modal: ModalType.startFasting }))}
      />
    </div>
  );
}

export default FastingBody;
