import { getCurrentLocale } from 'i18n';

import {
  FlowTypes,
  UnsubscribeV8FreeOfferSteps,
  UnsubscribeV8RecapBodyElements,
  UnsubscribeV8RecapLinks,
  UnsubscribeV8RecapStepName,
  UnsubscribeV8RecapSteps,
  UnsubscribeV8Steps,
} from 'types/unsubscribeV8';
import { UnitSystem, User, UserStatistics } from 'types/user';
import { UnsubscribeCommondata } from 'types/unsubscribe';
import { AccessItems, AccessName, AccessType } from 'types/access';

import { userHasPremium, userHasShoppingListAccess } from './userHelper';
import {
  getCurrentTheme,
  isFasting,
  isHealthgenom,
  themes,
} from './themeHelper';
import settings from './settings';
import {
  getFreeItems,
  isBasicProAccess,
  isChatAccess,
  isPremiumUpsellAccess,
  isShoppingListAccess,
} from './accessItemsHelper';
import { FileExtension, getDynamicImageBundle } from './imageHelper';

export const getAllowedUserCategories = (): string[] | null => {
  const categories = settings.UNSUBSCRIBEV8_ALLOWED_CATEGORIES;

  const categoriesArray = categories.split(',');

  if (categoriesArray.length < 1 || categoriesArray[0] === '') return null;

  return categoriesArray;
};
export const getAllowedCategorieForFreeOffer = (): string[] | null => {
  const categories = settings.UNSUBSCRIBEV8_FREE_OFFER_CATEGORIES;

  const categoriesArray = categories.split(',');

  if (categoriesArray.length < 1 || categoriesArray[0] === '') return null;

  return categoriesArray;
};

export const isUnsubscribeV8FreeOfferAllowed = (
  userCategory: number
): boolean => {
  const allowedCategories = getAllowedCategorieForFreeOffer();

  if (!allowedCategories) {
    return false;
  }
  // Restricted for Baltic locales
  const disallowedLocales = ['lv', 'et', 'lt'];
  const currentLocale = getCurrentLocale();
  if (disallowedLocales.includes(currentLocale)) {
    return false;
  }

  return allowedCategories.includes(userCategory.toString());
};

export const isUnsubscribeV8Enabled = (userCategory: number): boolean => {
  const allowedCategories = getAllowedUserCategories();
  const currentTheme = getCurrentTheme();
  const allowedThemes = [
    themes.keto,
    themes.ketogo,
    themes.fasting,
    themes.healthgenom,
  ];

  if (!allowedThemes.includes(currentTheme)) {
    return false;
  }
  if (!allowedCategories) {
    return false;
  }
  if (!allowedCategories.includes(userCategory.toString())) {
    return false;
  }

  return true;
};

const isValidV8Step = (
  stepId: any,
  flowType: FlowTypes.recap | FlowTypes.freeOffer
) => {
  const stepFlowName = getCurrentStepFlowName(stepId);
  if (stepFlowName !== flowType) return false;

  const stepObject = UnsubscribeV8Steps[flowType];

  return Object.values(stepObject).includes(stepId);
};

export const isValidUnsubscribeV8Step = (stepId: any): boolean => {
  const isValidRecap = isValidV8Step(stepId, FlowTypes.recap);
  const isValidFreeOffer = isValidV8Step(stepId, FlowTypes.freeOffer);

  return isValidRecap || isValidFreeOffer;
};

export const getCurrentStepFlowName = (stepId: string): FlowTypes => {
  if (stepId.includes('recap')) {
    return FlowTypes.recap;
  }
  if (stepId.includes('free-offer')) {
    return FlowTypes.freeOffer;
  }

  return FlowTypes.none;
};

export const getCurrentRecapStepName = (
  stepId: UnsubscribeV8RecapSteps
): string => UnsubscribeV8RecapStepName[stepId];

export const getGenderLetter = (userGender: string): string =>
  userGender === 'male' ? 'm' : 'f';

export const getFreeOfferLink = (accessItems: AccessItems): string | null => {
  const isPremium = accessItems[AccessName.premiumUpsell];
  const isShoppingList = accessItems[AccessName.shoppingList];

  if (!isPremium && !isShoppingList) {
    return UnsubscribeV8FreeOfferSteps.bundle;
  }
  if (!isPremium) {
    return UnsubscribeV8FreeOfferSteps.premium;
  }
  if (!isShoppingList) {
    return UnsubscribeV8FreeOfferSteps.shoppingList;
  }

  return null;
};

export const getNextRecapStepLink = (
  stepId: UnsubscribeV8RecapSteps,
  user: User,
  accessItems: AccessItems
): string => {
  const isPremium = userHasPremium(user);
  const isShoppingList = userHasShoppingListAccess(user);
  const genderLetter = getGenderLetter(user.gender);

  if (
    stepId === UnsubscribeV8RecapSteps.meals_male ||
    stepId === UnsubscribeV8RecapSteps.meals_female
  ) {
    // If doesnt have premium go to shoppinglist
    if (!isPremium) {
      return UnsubscribeV8RecapLinks[`8-recap-4-premium-${genderLetter}`];
    }
  }
  if (
    stepId === UnsubscribeV8RecapSteps.premium_female ||
    stepId === UnsubscribeV8RecapSteps.premium_male
  ) {
    if (!isShoppingList) {
      return UnsubscribeV8RecapLinks[`9-recap-5-shoppinglist-${genderLetter}`];
    }
  }
  if (
    stepId === UnsubscribeV8RecapSteps.results &&
    isUnsubscribeV8FreeOfferAllowed(user.category)
  ) {
    const freeOfferType = getFreeOfferLink(accessItems);
    if (freeOfferType) {
      return freeOfferType;
    }
  }

  return UnsubscribeV8RecapLinks[stepId];
};

export const getUserGoalWeight = (user: User) => {
  const { unit_system, goal_weight_in_lbs, goal_weight } = user;
  if (unit_system === UnitSystem.imperial) {
    return `${goal_weight_in_lbs}lbs`;
  }

  return `${goal_weight}kg`;
};

export const getUserStartWeight = (user: User) => {
  const { unit_system, weight, weight_in_lbs } = user;
  if (unit_system === UnitSystem.imperial) {
    return `${weight_in_lbs}lbs`;
  }

  return `${weight}kg`;
};

export const getUserCurrentWeight = (user: User) => {
  const { current_weight, current_weight_in_lbs } = user;
  if (isImperial(user)) {
    return `${current_weight_in_lbs}lbs`;
  }

  return `${current_weight}kg`;
};

// Display linked block for basic
export const isWeightBasicBlockLinked = (
  stepName: string,
  user: User
): boolean => {
  if (stepName === 'weight_female' || stepName === 'weight_male') {
    if (isMetric(user) && user.stats && user.stats.lost < 2) {
      return true;
    }
    if (isImperial(user) && user.stats && user.stats.lost_in_lbs < 4) {
      return true;
    }
  }

  return false;
};

export const getUsersLostWeight = (user: User) => {
  const { stats } = user;
  if (!stats) return '';

  const { lost, lost_in_lbs } = stats;

  if (isMetric(user)) {
    return `${lost}kg`;
  }

  return `${lost_in_lbs}lbs`;
};

export const isMealsEatenCountZero = (
  stepName: string,
  unsubscribeCommondata: UnsubscribeCommondata
): boolean => {
  if (stepName === 'meals_female' || stepName === 'meals_male') {
    const mealCount = 0;
    if (mealCount === unsubscribeCommondata.meals_eaten) {
      return true;
    }
  }

  return false;
};

export const getMealsPercentage = (mealsEaten: number, mealsCount: number) => {
  const diff = Math.round(100 - (mealsEaten / mealsCount) * 100);
  const percentage = `${100 - diff}%`;

  return percentage;
};

export const getBodyElementsArray = (
  stepName: string,
  ticketItems: AccessItems
) => {
  const bodyElements = UnsubscribeV8RecapBodyElements;

  if (stepName === 'investment_female' || stepName === 'investment_male') {
    let investmentArray = UnsubscribeV8RecapBodyElements[stepName].elements;

    if (!isBasicProAccess(ticketItems)) {
      investmentArray = investmentArray.filter(
        (investment) => investment.transKey !== 'investment_card_1'
      );
    }
    if (!isChatAccess(ticketItems)) {
      investmentArray = investmentArray.filter(
        (investment) => investment.transKey !== 'investment_card_2'
      );
    }
    if (!isShoppingListAccess(ticketItems)) {
      investmentArray = investmentArray.filter(
        (investment) => investment.transKey !== 'investment_card_3'
      );
    }
    if (!isPremiumUpsellAccess(ticketItems)) {
      investmentArray = investmentArray.filter(
        (investment) => investment.transKey !== 'investment_card_4'
      );
    }

    bodyElements.investment_female.elements = investmentArray;
    bodyElements.investment_male.elements = investmentArray;
  }

  return bodyElements[stepName].elements;
};

export const isMetric = (user: User): boolean =>
  user.unit_system === UnitSystem.si;

export const isImperial = (user: User): boolean =>
  user.unit_system === UnitSystem.imperial;

export enum InvestmentTranslationlabel {
  subscription = 'subscription',
  lifetime = 'lifetime',
  free = 'free',
}

export const getRecapInvestmentTranslationLabel = (
  accessType: AccessType
): InvestmentTranslationlabel => {
  if (accessType === AccessType.subscription) {
    return InvestmentTranslationlabel.subscription;
  }
  if (accessType === AccessType.payment) {
    return InvestmentTranslationlabel.lifetime;
  }

  return InvestmentTranslationlabel.free;
};

export const getPrettifiedUnitSystem = (unitSystem: UnitSystem) =>
  unitSystem === UnitSystem.si ? 'metric' : 'imperial';

export const getImageBundle = (transKey: string) => {
  const changedImages = [
    'weight_card_1',
    'weight_card_2',
    'weight_card_3',
    'shoppinglist_card_1',
    'investment_card_3',
  ];

  if (isFasting() && changedImages.includes(transKey)) {
    return getDynamicImageBundle(
      `./unsubscribev8/fasting/${transKey}`,
      FileExtension.png
    );
  }
  if (isHealthgenom() && changedImages.includes(transKey)) {
    return getDynamicImageBundle(
      `./unsubscribev8/healthgenom/${transKey}`,
      FileExtension.png
    );
  }

  return getDynamicImageBundle(
    `./unsubscribev8/${transKey}`,
    FileExtension.png
  );
};

export const getUserLoseWeight = (user: User) => {
  const { unit_system, stats } = user;
  const { lose, lose_in_lbs } = stats as UserStatistics;

  if (unit_system === UnitSystem.imperial) {
    return `${lose_in_lbs}lbs`;
  }

  return `${lose}kg`;
};

export const getFreeItemName = (accessItems: AccessItems) => {
  const freeItems = getFreeItems(accessItems);
  if (!freeItems) return null;

  const isAccessInArray = (access: string) => freeItems.includes(access);

  if (isAccessInArray('premium_upsell') && isAccessInArray('shopping_list'))
    return 'bundle';
  if (isAccessInArray('premium_upsell')) return 'premium';
  if (isAccessInArray('shopping_list')) return 'shoppinglist';

  return null;
};
