import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentBodyStatus } from 'helpers/fastingHelper';
import { Cycle, Timer } from 'types/fasting';

import { useFastingPercentage } from 'hooks';

import FastingBurn from 'assets/images/program/fasting/fasting-burn.svg';
import SmallFire from 'assets/images/program/fasting/small-fire.svg';
import SmallUtensils from 'assets/images/program/fasting/small-utensils.svg';
import SugarRises from 'assets/images/program/benefits/sugar_rises.svg';
import SugarDrops from 'assets/images/program/benefits/sugar_drops.svg';
import SugarSettles from 'assets/images/program/benefits/sugar_settles.svg';
import FatBurn from 'assets/images/program/benefits/fat_burn.svg';
import Ketosis from 'assets/images/program/benefits/ketosis.svg';

import s from './FastingHeader.module.scss';

type Props = {
  cycle: Cycle;
  timer: Timer;
};

const benefitImages: { [key: string]: any } = {
  sugar_rises: SugarRises,
  sugar_drops: SugarDrops,
  sugar_settles: SugarSettles,
  fat_burn: FatBurn,
  ketosis: Ketosis,
};

function FastingHeader({ cycle, timer }: Props) {
  const { t } = useTranslation();
  const { percentageProgress } = useFastingPercentage({
    cycle,
    timer,
  });
  const bodyStatus = getCurrentBodyStatus(percentageProgress);

  return (
    <div className={s.header}>
      <div className={s.headerInformation}>
        <img className={s.icon} src={FastingBurn} alt="fasting-burn" />
        <div className={s.text}>
          <p className={s.info}>
            <img src={SmallFire} alt="small-flame" />
            <span className={s.fastingSmall}>{cycle.fasting}h</span>
            <img src={SmallUtensils} alt="small-utensils" />
            <span className={s.fastingSmall}>{cycle.eating}h</span>
          </p>
          <h3 className={s.title}>{t('items.type.fasting.title')}</h3>
        </div>
      </div>
      <div className={s.benefitContainer}>
        <span className={s.benefit}>
          <img
            className={s.benefitImage}
            src={benefitImages[bodyStatus]}
            alt={bodyStatus}
          />{' '}
          {t(`fasting.benefits.${bodyStatus}`)}
        </span>
      </div>
    </div>
  );
}

export default FastingHeader;
