import { useState, useEffect } from 'react';

import { UpsellList } from 'types/upsell';

import { getUserOfferAds } from 'api/OfferAPI';

function useUpsell(): { isLoading: boolean; upsellList: UpsellList } {
  const [isLoading, setIsLoading] = useState(true);
  const [upsellList, setUpsellList] = useState<UpsellList>({});

  useEffect(() => {
    const loadUpsellList = async () => {
      const response = await getUserOfferAds();
      setUpsellList(response);
      setIsLoading(false);
    };

    loadUpsellList();
  }, []);

  return { isLoading, upsellList };
}

export default useUpsell;
