import { TFunction } from 'i18next';
import { getCurrentLocale } from 'i18n';

import {
  formatISODate,
  getCurrentDifferenceInCalendarMonthsISO,
} from 'helpers/dateHelper';

import { User } from 'types/user';
import { Subscription } from 'types/subscription';
import { Paymodes, OfferPriceType } from 'types/offer';
import { Frequency, ItemType } from 'types/payment';
import { getUserActiveProSubscription, getUserCustomField } from './userHelper';

const PAYPAL_PAYMODES = [
  Paymodes.paypal_classic,
  Paymodes.paypal_express,
  Paymodes.paypal_recurring,
];

export const getCompanyTranslations = (
  subscription: Subscription,
  domain: any
): any =>
  subscription.company_identifier
    ? domain.company[subscription.company_identifier]
    : domain.company[domain.default_tos];

export const isPaypalProvider = (subscription: Subscription | null) => {
  if (!subscription) {
    return false;
  }

  const { provider } = subscription;

  return PAYPAL_PAYMODES.includes(provider as Paymodes);
};

export const getFormattedNextChargeDate = (nextCharge: string) => {
  if (nextCharge) {
    return formatISODate(nextCharge, 'dd-LLL-yyyy').toUpperCase();
  }

  return '';
};

export const isUnsubscribeLocked = (subscription: Subscription) =>
  subscription.unsubscribe_locked;

export const isProSubscribtionLocked = (subscriptions: Subscription[]) =>
  subscriptions.find(
    (subscription) =>
      subscription.item_type === 'pro_subscription' &&
      subscription.status === 'active' &&
      subscription.unsubscribe_locked
  );

export const getOldestProSubscription = (subscriptions: Subscription[]) => {
  const proSubscriptions = subscriptions.filter(
    (subscription) => subscription.item_type === 'pro_subscription'
  );

  const subscriptionOrderedByDate = proSubscriptions.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
  );

  if (!subscriptionOrderedByDate) {
    return null;
  }

  return subscriptionOrderedByDate;
};

type PreUnsubscribeV4DateFormats = {
  subscriptionMonths: number;
  subscriptionStartYear: string;
  subscriptionStartMonth: string;
  subscriptionStartShort: string;
};

const getUserSubscriptionStartMonth = (
  t: TFunction,
  first_subscription_start_date: string
) => {
  const locale = getCurrentLocale();

  if (locale === 'lv') {
    const monthNumeric = formatISODate(first_subscription_start_date, 'L');

    return t(`first_subscription_start_months.c${monthNumeric}`);
  }

  return '';
};

const getUserSubscriptionMonths = (date: string) => {
  const subscriptionMonths = getCurrentDifferenceInCalendarMonthsISO(date);

  return Math.max(subscriptionMonths, 1);
};

export const getAllDateFormatsForPreUnsubscribeV4Page = (
  firstSubscriptionStartDate: string,
  activeSubscriptionStartDate: string,
  t: TFunction
): PreUnsubscribeV4DateFormats => {
  const subscriptionMonths = getUserSubscriptionMonths(
    activeSubscriptionStartDate
  );
  const subscriptionStartYear = formatISODate(
    firstSubscriptionStartDate,
    'yyyy'
  );
  const subscriptionStartMonth = getUserSubscriptionStartMonth(
    t,
    firstSubscriptionStartDate
  );
  const subscriptionStartShort = formatISODate(
    firstSubscriptionStartDate,
    'LL-yyyy'
  );

  return {
    subscriptionMonths,
    subscriptionStartYear,
    subscriptionStartMonth,
    subscriptionStartShort,
  };
};

export const isUnsubscribePriceSubscription = (subscription: Subscription) =>
  subscription.offer_price.type === OfferPriceType.unsubscribe;

export const getDiscountPercentageFromTwoPrices = (
  priceFrom: number,
  price: number
) => {
  const getPercentage = Math.round(100 - (price / priceFrom) * 100);

  return `${Math.round(getPercentage / 5) * 5}%`;
};

export const isAnnualProSubscription = (subscription: Subscription): boolean =>
  subscription.purchase_item?.frequency === Frequency.year &&
  subscription.purchase_item?.item.type === ItemType.pro;

export const isSubscriptionPaused = (
  subscription: Subscription | any
): boolean => {
  if (!subscription) return false;

  return subscription.is_paused;
};

export const isSubscriptionPauseAllowed = (user: User) => {
  const activeSubscription = getUserActiveProSubscription(user);

  if (!activeSubscription) return false;
  if (isSubscriptionPaused(activeSubscription)) return false;
  if (!activeSubscription.is_pausable) return false;

  return true;
};

export const isWelcomeBackPausedScreenNeeded = (user: User) => {
  const activeSubscription = getUserActiveProSubscription(user);
  const seenWelcomeBack = getUserCustomField(
    user,
    'pause_welcome_back_popup_seen'
  );

  if (!activeSubscription) {
    return false;
  }
  if (seenWelcomeBack) {
    return false;
  }
  if (activeSubscription.is_paused) {
    return false;
  }
  if (!activeSubscription.paused_till) {
    return false;
  }

  return true;
};
