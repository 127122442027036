import {
  apiHost,
  requestHeadersWithToken,
  fetchData,
  requestHeaders,
} from 'helpers/requestHelpers';
import { api } from 'helpers/api';
import { ajax } from 'rxjs/ajax';
import { pluck } from 'rxjs/operators';
import { UserCustomFields } from 'types/user';

export const createUser = (user: any) =>
  ajax
    .post(`${apiHost()}/api/v1/users/registration`, user, requestHeaders())
    .pipe(pluck('response'));

export const updateUserData = (user: any) => {
  const request = new Request(`${apiHost()}/api/v1/users/profile`, {
    method: 'PUT',
    headers: requestHeadersWithToken(),
    body: JSON.stringify(user),
  });
  return fetchData(request);
};

export const updateUserPhoneNumber = (user: any) => {
  const request = new Request(
    `${apiHost()}/api/v1/users/profile/change_user_phone_number`,
    {
      method: 'PUT',
      headers: requestHeadersWithToken(),
      body: JSON.stringify(user),
    }
  );
  return fetchData(request);
};

export const updatePassword = (user: any) =>
  api('api/v1/users/profile/change_user_password', {
    method: 'PUT',
    body: { user },
  });

export const updateEmail = (email: any) =>
  api('api/v1/users/profile/change-email', {
    method: 'PATCH',
    body: { email },
  });

export const newPassword = (user: any) => {
  const request = new Request(`${apiHost()}/api/v1/users/enter_password`, {
    method: 'POST',
    headers: requestHeadersWithToken(),
    body: JSON.stringify(user),
  });
  return fetchData(request);
};

export const acceptTOS = (subscriptionId: any, choice: any) => {
  const request = new Request(
    `${apiHost()}/api/v1/users/subscriptions/${subscriptionId}/tos/${choice}`,
    {
      method: 'POST',
      headers: requestHeadersWithToken(),
    }
  );
  return fetchData(request);
};

export const updateUserCountry = (user: any) => {
  const request = new Request(
    `${apiHost()}/api/v1/users/profile/update_user_country`,
    {
      method: 'PUT',
      headers: requestHeadersWithToken(),
      body: JSON.stringify(user),
    }
  );
  return fetchData(request);
};

export const fbConnect = (payload: any) =>
  ajax
    .post(
      `${apiHost()}/api/v1/users/facebook_connect`,
      payload,
      requestHeadersWithToken()
    )
    .pipe(pluck('response'));

export const updateUserCid = (cid: any) => {
  const request = new Request(`${apiHost()}/api/v1/users/update_ga_app_cid`, {
    method: 'POST',
    headers: requestHeadersWithToken(),
    body: JSON.stringify({ ga_app_cid: cid }),
  });
  return fetchData(request);
};

export const acceptMedicalAgreement = () => {
  const request = new Request(
    `${apiHost()}/api/v1/users/accept_medical_agreement`,
    {
      method: 'POST',
      headers: requestHeadersWithToken(),
    }
  );
  return fetchData(request);
};

export const saveUserCustomField = ({
  field,
  value,
}: {
  field: UserCustomFields;
  value: string | number | boolean;
}): any =>
  api('api/v1/users/save-custom-field', {
    method: 'POST',
    body: { field, value },
  });
