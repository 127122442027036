import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingAnimation } from 'components/common';
import useTheme from 'helpers/themeHook';

import base from './OfflineMessage.module.scss';
import diet from './OfflineMessage_diet.module.scss';

const OfflineMessage: React.FC = () => {
  const [s] = useTheme({ base, diet });
  const { t } = useTranslation();

  return (
    <div className={s.container}>
      <h4 className={s.header}>
        {t('offline.title_1')}
        <br />
        {t('offline.title_2')}
      </h4>
      <LoadingAnimation />
    </div>
  );
};

export default OfflineMessage;
