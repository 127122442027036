import React from 'react';

import { SVG } from 'types/svg';

function Height(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.85 4.79L19.21.15a.51.51 0 0 0-.36-.15.51.51 0 0 0-.37.15L17 1.67l-1.5 1.47-4.12 4.12-4.12 4.12-7.11 7.1a.52.52 0 0 0 0 .73l4.64 4.64a.51.51 0 0 0 .36.15.51.51 0 0 0 .37-.15L23.85 5.52a.52.52 0 0 0 0-.73zm-18.7 18l-3.91-3.94.76-.77.79.8a.53.53 0 0 0 .37.15.51.51 0 0 0 .36-.15.52.52 0 0 0 0-.73l-.79-.8.76-.76 2 1.95a.51.51 0 0 0 .36.15.55.55 0 0 0 .37-.15.51.51 0 0 0 0-.72l-2-2 1.3-1.31.8.8a.51.51 0 0 0 .36.15.51.51 0 0 0 .37-.15.52.52 0 0 0 0-.73l-.8-.79 1.36-1.36 2 1.95a.51.51 0 0 0 .72 0 .49.49 0 0 0 0-.72l-2-2 1.33-1.33.8.79a.48.48 0 0 0 .72 0 .49.49 0 0 0 0-.72l-.79-.8 1.33-1.33 2 2a.49.49 0 0 0 .72 0 .49.49 0 0 0 0-.72l-1.95-2 1.3-1.3.8.79a.51.51 0 0 0 .36.15.53.53 0 0 0 .37-.15.52.52 0 0 0 0-.73l-.8-.79 1.36-1.36 1.95 1.95a.52.52 0 0 0 .37.16.48.48 0 0 0 .36-.16.51.51 0 0 0 0-.72l-1.95-2 .74-.74.79.8a.51.51 0 0 0 .37.15.51.51 0 0 0 .36-.15.52.52 0 0 0 0-.73L18.05 2l.8-.8 3.91 3.91z" />
    </svg>
  );
}

export default Height;
