import React from 'react';

import { SVG } from 'types/svg';

function PremiumBackButtonFasting(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          id="prefix__d62f3f13-c097-4be5-bd74-d50290f23824"
          d="M30.82 14.82L28 12 16 24l12 12 2.82-2.82L21.66 24z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__e0d6666c-9128-4391-a0f9-318df5584100" fill="#fff">
          <use xlinkHref="#prefix__d62f3f13-c097-4be5-bd74-d50290f23824" />
        </mask>
        <g
          fill="#29abe2"
          mask="url(#prefix__e0d6666c-9128-4391-a0f9-318df5584100)"
        >
          <path d="M0 0h48v48H0z" />
        </g>
      </g>
    </svg>
  );
}

export default PremiumBackButtonFasting;
