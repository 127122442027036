import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';
import { GenomPlusSurveyStateType } from 'pages/GenomPlusSurvey/types';

export default (
  state = initialState.genomPlusSurvey,
  action: types.GenomPlusSurveyActionTypes
): GenomPlusSurveyStateType => {
  switch (action.type) {
    case types.GENOM_PLUS_SURVEY_LOAD:
      return {
        ...state,
        isLoading: true,
      };

    case types.GENOM_PLUS_SURVEY_LOAD_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };

    case types.GENOM_PLUS_SURVEY_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.GENOM_PLUS_SURVEY_SAVE:
      return {
        ...state,
        isLoading: true,
      };

    case types.GENOM_PLUS_SURVEY_SAVE_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };

    case types.GENOM_PLUS_SURVEY_SAVE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
