import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import browserHistory from 'helpers/history';
import isEmpty from 'lodash/isEmpty';
import { Online, Offline } from 'react-detect-offline';

import {
  getUserData,
  getCalendarDataAction,
  getClientLocaleAction,
} from 'actions/programActions';
import { getCableUrlAction } from 'actions/chatActions';
import { storeClientId } from 'actions/userActions';

import auth from 'helpers/authenticator';
import { getNavbarTabName, urlContainsValues } from 'helpers/pathHelper';
import { mealSurveyNotFinished } from 'helpers/userHelper';
import { isMobile } from 'helpers/programHelper';
import initializeGoogleTagManager from 'helpers/googleTagManager';
import { Headings, BgVideo, LoadingAnimation, Navbar } from 'components/common';
import { getStyle, isMonacofit } from 'helpers/themeHelper';

import OfferBanner from 'components/OfferBanner/OfferBanner';
import FlashMessageList from 'components/common/FlashMessageList';
import OfflineMessage from 'components/common/OfflineMessage/OfflineMessage';
import { getLocaleDirection } from 'i18n';
import GlobalModals from './GlobalModals';

import base from './App.module.scss';
import diet from './App_diet.module.scss';
import keto from './App_keto.module.scss';
import ketogo from './App_ketogo.module.scss';
import fasting from './App_fasting.module.scss';
import healthgenom from './App_healthgenom.module.scss';

const s = getStyle(base, diet, keto, ketogo, fasting, healthgenom);

const pollingOptions = {
  enabled: false,
};

class App extends Component {
  initialDataLoaded = false;

  static propTypes = {
    children: PropTypes.object.isRequired,
    getUserData: PropTypes.func.isRequired,
    getCalendarDataAction: PropTypes.func.isRequired,
    getClientLocaleAction: PropTypes.func.isRequired,
    getCableUrlAction: PropTypes.func.isRequired,
    storeClientId: PropTypes.func.isRequired,
    user: PropTypes.object,
    location: PropTypes.object,
    calendar: PropTypes.object,
    flashMessage: PropTypes.object,
    isLoading: PropTypes.bool,
    locale: PropTypes.string,
  };

  componentDidMount = () => {
    const eventType = 'googleAnalyticsPageViewCallback';

    // Removes the base loader, after hydration has been done
    const baseLoader = document.getElementById('loader-wrapper');
    if (baseLoader) {
      baseLoader.remove();
    }

    window.addEventListener(eventType, (e) => {
      const { storeClientId } = this.props;
      const { clientId } = e.detail;
      storeClientId(clientId);
    });

    document.body.style.background = 'transparent';

    initializeGoogleTagManager();
  };

  navigation = () => {
    const { isLoading, location, user } = this.props;
    if (auth.loggedIn()) {
      return (
        <Navbar
          active={getNavbarTabName(location.pathname)}
          isLoading={isLoading}
          user={user}
        />
      );
    }

    if (isMonacofit() && !isMobile()) {
      return <BgVideo />;
    }

    return <div className={`bg__image ${s.background}`} />;
  };

  renderOfferBanner = () => {
    const { user } = this.props;

    if (!user) {
      return null;
    }
    if (!auth.loggedIn()) {
      return null;
    }
    if (
      urlContainsValues([
        'offer',
        'registration',
        'sub_tos',
        'email_unsubscribe',
      ])
    ) {
      return null;
    }
    if (mealSurveyNotFinished(user)) {
      return null;
    }

    return <OfferBanner user={user} />;
  };

  render = () => {
    let appReturn;
    const {
      getUserData,
      getCableUrlAction,
      getCalendarDataAction,
      getClientLocaleAction,
      user,
      calendar,
      children,
      flashMessage,
      locale,
      session,
    } = this.props;

    if (
      !browserHistory.location.pathname.includes('direct_login') &&
      auth.loggedIn() &&
      session.status
    ) {
      if (!this.initialDataLoaded) {
        // Do all requests async
        getUserData(); // gets user data
        getCableUrlAction(); // Subscribe chat
        getCalendarDataAction(); // gets calendar data

        this.initialDataLoaded = true;
      }
      if (!user?.id || isEmpty(calendar)) {
        appReturn = <LoadingAnimation />;
      } else {
        appReturn = children;
      }
    } else {
      this.initialDataLoaded = false;
      if (typeof locale !== 'undefined') {
        appReturn = children;
      } else {
        getClientLocaleAction(); // gets client locale, if user not logged in
      }
    }

    return (
      <div className={s.container} dir={getLocaleDirection()}>
        <Headings locale={locale} />
        <FlashMessageList messages={flashMessage.messages} />
        {this.navigation()}
        {this.renderOfferBanner()}
        <Offline polling={pollingOptions}>
          <OfflineMessage />
        </Offline>
        <Online polling={pollingOptions}>{appReturn}</Online>
        <GlobalModals />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  session: state.rootReducer.session,
  user: state.rootReducer.user,
  calendar: state.rootReducer.calendar,
  flashMessage: state.rootReducer.flashMessage,
  isLoading: state.rootReducer.isLoading,
  locale: state.rootReducer.currentLocale.locale,
});

const mapDispatchToProps = {
  getUserData,
  getCalendarDataAction,
  getClientLocaleAction,
  getCableUrlAction,
  storeClientId,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
