import React from 'react';

import HeartIcon from 'assets/images/program/fasting/fasting-card/default/heart.svg';
import LightningIcon from 'assets/images/program/fasting/fasting-card/default/lightning.svg';
import FlameIcon from 'assets/images/program/fasting/fasting-card/default/flame.svg';
import SkullIcon from 'assets/images/program/fasting/fasting-card/default/skull.svg';
import ShieldIcon from 'assets/images/program/fasting/fasting-card/default/shield.svg';

import s from './HorseShoeStale.module.scss';

function HorseShoeStale() {
  return (
    <div className={s.container}>
      <div className={s.svgContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="130px"
          height="130px"
        >
          <defs>
            <linearGradient id="baseBar">
              <stop offset="0%" stopColor="#03cea4" />
              <stop offset="100%" stopColor="#29abe2" />
            </linearGradient>
          </defs>
          <circle cx="65" cy="65" r="50" strokeLinecap="round" />
        </svg>
        <img src={HeartIcon} className={s.heart} alt="heart" />
        <img src={LightningIcon} className={s.lightning} alt="lightning" />
        <img src={FlameIcon} className={s.flame} alt="flame" />
        <img src={SkullIcon} className={s.skull} alt="skull" />
        <img src={ShieldIcon} className={s.shield} alt="shield" />
        <div className={s.percentage}>
          <span>0%</span>
        </div>
        <div className={s.svgContainer}>
          <svg
            id="progressionBar"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="130px"
            height="130px"
          >
            <defs>
              <linearGradient id="progressBar">
                <stop offset="0%" stopColor="#03cea4" />
                <stop offset="100%" stopColor="#29abe2" />
              </linearGradient>
            </defs>
            <circle cx="65" cy="65" r="50" strokeLinecap="round" />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default HorseShoeStale;
