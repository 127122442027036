import React from 'react';

import { SVG } from 'types/svg';

function FlagHealthgenom(props: SVG) {
  return (
    <svg
      width={15}
      height={17}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          id="prefix__810fa55a-10da-4a40-b294-0abe61482701"
          d="M9.4 2 9 0H0v17h2v-7h5.6l.4 2h7V2z"
        />
      </defs>
      <use
        fill="#2884AA"
        xlinkHref="#prefix__810fa55a-10da-4a40-b294-0abe61482701"
      />
    </svg>
  );
}

export default FlagHealthgenom;
