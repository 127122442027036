import React from 'react';

function EatingCheckmarkBig() {
  return (
    <svg width={212} height={211} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M184.458 19.63c-29.094 22.104-66.659 70.87-87.651 114.024L87.6 152.95l-14.363-27.015c-13.995-26.313-14.731-27.014-22.465-22.103-4.42 2.807-16.941 10.175-27.253 16.139C12.839 126.286 4 131.9 4 132.601c0 .702 8.102 5.965 17.678 11.929 9.943 5.964 29.462 22.454 43.825 36.487l26.148 25.26 13.995-21.05c28.357-41.399 71.446-87.008 94.28-98.586 9.207-4.56 9.575-5.613 5.892-15.788-2.21-6.666-2.946-22.103-1.841-38.592 1.473-15.437 1.841-27.717 1.473-27.717-.368 0-9.944 6.666-20.992 15.087"
        fill="#03CEA4"
        stroke="#FFF"
        strokeWidth={8}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EatingCheckmarkBig;
