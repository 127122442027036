import React from 'react';

import { SVG } from 'types/svg';

function LogoKeto(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#264653"
          d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm-1.852 6h-1.69A.458.458 0 008 7.475v9.067c0 .229.193.458.458.458h1.69c.299 0 .44-.23.44-.458V14.8l1.62-2.112 1.725 3.855c.088.211.335.458.599.458h1.743c.264 0 .457-.176.457-.37 0-.035 0-.07-.017-.088l-2.747-6.162 2.236-2.905c0-.264-.14-.475-.44-.475h-1.725c-.37 0-.634.264-.775.475l-2.676 3.45v-3.45c0-.264-.141-.475-.44-.475z"
        />
      </g>
    </svg>
  );
}

export default LogoKeto;
