import React from 'react';

function Exercise(props: any) {
  const { fill, ...rest } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 62 62" {...rest}>
      <defs>
        <style>{'.prefix__cls-2{fill:#fff}'}</style>
      </defs>
      <g id="exercise-content" data-name="BIG ICON 03">
        <circle cx={31} cy={31} r={31} fill={fill} />
        <path
          className="prefix__cls-2"
          d="M34.58 20.78l1.94 1.15a7.16 7.16 0 0 1 2.9 3.19l1.28 2.81 4-3.31a1.8 1.8 0 0 1 2.48.17 1.82 1.82 0 0 1-.18 2.56l-4.92 4.14a2.41 2.41 0 0 1-3.44-.38l-2.56-3.39L32.55 33l4 3a3.55 3.55 0 0 1 1.15 4.21l-3.06 7.12a2.12 2.12 0 0 1-2.89 1 2.13 2.13 0 0 1-.95-2.69l2.73-6-6.16-4.39a4.35 4.35 0 0 1-1.2-5.79l3.55-5.83-5.08 1-3.83 3.47a2 2 0 0 1-2.65-.67 1.94 1.94 0 0 1 .71-2.77l4.3-3.55a2.42 2.42 0 0 1 .74-.29l5.25-.82a7 7 0 0 1 5.42.78z"
        />
        <path
          className="prefix__cls-2"
          d="M26 35.58l4 2.56-1.07 4.47a3.91 3.91 0 0 1-2 2.48l-7.73 3.72a2 2 0 0 1-2.61-.91 1.92 1.92 0 0 1 .87-2.56l7.24-3.72z"
        />
        <circle className="prefix__cls-2" cx={37.93} cy={16.85} r={3.85} />
      </g>
    </svg>
  );
}

export default Exercise;
