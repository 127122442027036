import React from 'react';

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12.3118C2 6.79177 6.48 2.31177 12 2.31177C17.52 2.31177 22 6.79177 22 12.3118C22 17.8318 17.52 22.3118 12 22.3118C6.48 22.3118 2 17.8318 2 12.3118ZM5 12.3118L10 17.3118L19 8.31177L17.59 6.89177L10 14.4818L6.41 10.9018L5 12.3118Z"
        fill="#2A9D8F"
      />
      <mask
        id="mask0_7041_5337"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="21"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12.3118C2 6.79177 6.48 2.31177 12 2.31177C17.52 2.31177 22 6.79177 22 12.3118C22 17.8318 17.52 22.3118 12 22.3118C6.48 22.3118 2 17.8318 2 12.3118ZM5 12.3118L10 17.3118L19 8.31177L17.59 6.89177L10 14.4818L6.41 10.9018L5 12.3118Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_7041_5337)" />
    </svg>
  );
}

export default CheckIcon;
