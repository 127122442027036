import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { isUpsellOffer } from 'helpers/offerHelper';
import { getUserRegisterationStep } from 'helpers/userHelper';
import { User } from 'types/user';

import { LoadingAnimation } from 'components/common';
import useUpsell from './useUpsell';

type Props = {
  children: React.ReactNode;
};

function EnsureUserReadyWrapper({ children }: Props) {
  const { t } = useTranslation();
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const { isLoading, upsellList } = useUpsell();

  const getRedirectUrl = () => {
    if (isEmpty(user)) {
      return null;
    }
    if (isUpsellOffer(upsellList)) {
      return null;
    }
    const stepPath = getUserRegisterationStep(user);
    if (stepPath) {
      return stepPath;
    }

    return null;
  };

  if (process.env.NODE_ENV === 'production' && (window as any).dataLayer) {
    (window as any).dataLayer.push({ 'App Language': t(`locale`) });
  }

  if (isLoading) {
    return <LoadingAnimation />;
  }

  const redirectUrl = getRedirectUrl();
  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return children;
}

export default EnsureUserReadyWrapper;
