/* eslint-disable import/no-extraneous-dependencies */
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import rootReducer from 'reducers';
// eslint-disable-next-line import/no-cycle
import rootEpic from 'epics';
import thunk from 'redux-thunk';
import { configureFlashMessages } from 'redux-flash-messages';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import initialState from 'reducers/initialState';

const middlewares = [];
const epicMiddleware = createEpicMiddleware();
let enchancer;

middlewares.push(thunk);
middlewares.push(epicMiddleware);

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  middlewares.push(require('redux-immutable-state-invariant').default());

  // https://github.com/zalmoxisus/redux-devtools-extension#usage
  enchancer = composeWithDevTools(applyMiddleware(...middlewares));
} else {
  enchancer = compose(applyMiddleware(...middlewares));
}

const reducers = combineReducers({
  rootReducer,
});

const store = createStore(reducers, { rootReducer: initialState }, enchancer);

// Config
epicMiddleware.run(rootEpic);
configureFlashMessages({
  dispatch: store.dispatch,
});

export default store;
