import React from 'react';

function Timer() {
  return (
    <svg width={48} height={48} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <g fillRule="nonzero">
          <path
            d="M22.771 11.242a.964.964 0 0 1-.568-.169.521.521 0 0 1-.237-.415V8.562c-.024-.166.05-.333.202-.457a.955.955 0 0 1 .599-.197c.228 0 .445.072.598.197.153.124.226.29.202.457v2.096a.52.52 0 0 1-.233.413.96.96 0 0 1-.563.17zm2.285 0c-.44 0-.8-.26-.804-.584V8.562c-.024-.166.049-.333.202-.457a.955.955 0 0 1 .598-.197c.228 0 .446.072.598.197.153.124.226.29.202.457v2.096a.52.52 0 0 1-.233.413.962.962 0 0 1-.563.17zm11.328 6.472a.805.805 0 0 1-.558-1.372l1.564-1.563-.915-.868-1.554 1.545a.798.798 0 0 1-1.133-1.125l2.12-2.121a.804.804 0 0 1 1.134 0l2.048 2.002a.805.805 0 0 1 0 1.134l-2.139 2.13a.796.796 0 0 1-.567.238zm-24.94.008a.813.813 0 0 1-.567-.237l-2.14-2.14a.805.805 0 0 1 0-1.133l2.003-2.003a.804.804 0 0 1 1.133 0l2.112 2.122a.796.796 0 0 1-1.124 1.124l-1.555-1.518-.914.869 1.637 1.545a.804.804 0 0 1-.585 1.371z"
            fill="#E9C46A"
          />
          <path
            d="M23.914 43.999a16.907 16.907 0 0 1-16.59-20.196 16.905 16.905 0 0 1 33.486 3.292A16.924 16.924 0 0 1 23.914 44v-.001zm0-32.2a15.31 15.31 0 0 0-14.147 9.443A15.302 15.302 0 0 0 20.92 42.114a15.303 15.303 0 0 0 13.805-25.833 15.304 15.304 0 0 0-10.812-4.483z"
            fill="#2A9D8F"
          />
          <path
            d="M23.914 40.89A13.795 13.795 0 1 1 37.71 27.094a13.815 13.815 0 0 1-13.797 13.797zm0-25.992A12.196 12.196 0 1 0 36.11 27.094a12.214 12.214 0 0 0-12.196-12.196z"
            fill="#2A9D8F"
          />
          <path
            d="M23.914 29.343a2.249 2.249 0 1 1 0-4.497 2.249 2.249 0 0 1 0 4.497zm0-2.898a.65.65 0 1 0 0 1.298.65.65 0 0 0 0-1.298z"
            fill="#E9C46A"
          />
          <path
            d="M23.914 16.827a.804.804 0 0 1-.805-.795v-1.07a.805.805 0 0 1 1.6 0v1.07a.794.794 0 0 1-.795.795zm0 23.195a.805.805 0 0 1-.805-.795v-1.061a.804.804 0 1 1 1.6 0v1.06a.794.794 0 0 1-.795.796zM12.842 27.899h-1.06a.805.805 0 0 1 0-1.6h1.06a.805.805 0 1 1 0 1.6zm23.204 0h-1.06a.806.806 0 1 1 0-1.6h1.06a.805.805 0 0 1 0 1.6zm-4.306-7.826a.804.804 0 0 1-.567-1.372l.75-.75a.805.805 0 0 1 1.134 1.134l-.75.75a.814.814 0 0 1-.567.238zM15.338 36.474a.805.805 0 0 1-.567-1.371l.75-.75a.805.805 0 0 1 1.134 1.134l-.75.75a.814.814 0 0 1-.567.237zm.75-16.402a.814.814 0 0 1-.567-.237l-.75-.75a.806.806 0 1 1 1.134-1.134l.75.75a.805.805 0 0 1-.567 1.371zM32.49 36.474a.814.814 0 0 1-.568-.238l-.75-.75a.805.805 0 0 1 1.135-1.133l.75.75a.805.805 0 0 1-.568 1.371zM19.68 17.668a.795.795 0 0 1-.74-.494l-.411-.978a.814.814 0 0 1 .438-1.051.805.805 0 0 1 1.043.439l.411.978a.813.813 0 0 1-.439 1.033.909.909 0 0 1-.301.073zm8.879 21.43a.795.795 0 0 1-.741-.494l-.411-.978a.796.796 0 0 1 .439-1.042.787.787 0 0 1 1.042.43l.411.978a.814.814 0 0 1-.439 1.051.922.922 0 0 1-.301.055zm-15.855-6.565a.795.795 0 0 1-.301-1.535l.978-.412a.796.796 0 0 1 1.042.439.786.786 0 0 1-.43 1.042l-.977.412a.913.913 0 0 1-.311.054zm21.431-8.877a.796.796 0 0 1-.302-1.49l.978-.411a.814.814 0 0 1 1.052.438.805.805 0 0 1-.439 1.043l-.978.41a.913.913 0 0 1-.311.01zm-5.988-5.988a.91.91 0 0 1-.301-.073.813.813 0 0 1-.44-1.051l.412-.978a.795.795 0 0 1 1.042-.44c.408.173.604.64.439 1.052l-.411.978a.795.795 0 0 1-.74.512zm-8.877 21.43a.922.922 0 0 1-.303-.055.814.814 0 0 1-.438-1.051l.411-.979a.786.786 0 0 1 1.042-.43.797.797 0 0 1 .439 1.043l-.411.997a.805.805 0 0 1-.74.475zm-5.577-15.442a.911.911 0 0 1-.311-.055l-.978-.411a.805.805 0 0 1-.44-1.024.814.814 0 0 1 1.052-.439l.979.411a.796.796 0 0 1-.302 1.536v-.018zm21.43 8.877a.913.913 0 0 1-.31-.054l-.98-.412a.786.786 0 0 1-.429-1.042.794.794 0 0 1 1.042-.439l.979.412a.795.795 0 0 1-.302 1.535z"
            fill="#2A9D8F"
          />
          <path
            d="M31.74 27.899h-6.4a.805.805 0 1 1 0-1.6h6.4a.805.805 0 0 1 0 1.6zM27.324 8.992h-6.82a1.207 1.207 0 0 1-1.161-1.207V5.207A1.208 1.208 0 0 1 20.55 4h6.82a1.207 1.207 0 0 1 1.207 1.207v2.578a1.207 1.207 0 0 1-1.253 1.207zm-6.4-1.6h6.034V5.563h-6.06l.026 1.829z"
            fill="#E9C46A"
          />
        </g>
      </g>
    </svg>
  );
}

export default Timer;
