import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { User } from 'types/user';
import browserHistory from 'helpers/history';

import { getUserData } from 'actions/programActions';
import useInfiniteTimer from 'hooks/useInfiniteTimer';

import { LoadingAnimation } from 'components/common';

import s from './ExpressPlanTimer.module.scss';

type TimerBlockProps = {
  time: number;
};

const TimerBlock = ({ time }: TimerBlockProps) => (
  <div className={s.timerBlock}>
    <p className={s.timerValue}>{time}</p>
  </div>
);

function ExpressPlanTimer() {
  const dispatch = useDispatch();
  const { t } = useTranslation('expressplan');
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const timerValue = user.express_plan_delivery_timer?.time_left as number;
  const { seconds } = useInfiniteTimer({
    timer: timerValue as number,
    stopAtEnd: true,
  });
  const currentHours = Math.floor(seconds / 3600);
  const currentMinutes: number = Math.floor(seconds / 60);
  const currentSeconds: number = seconds - currentMinutes * 60;
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [isTimerDone, setIsTimerDone] = useState(false);

  const loadUserData = async () => {
    await dispatch(getUserData());
    setIsUserDataLoading(false);
  };

  useEffect(() => {
    // Always fetch latest data from BE - because of the DB timestamp
    loadUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isUserDataLoading) return <LoadingAnimation />;

  if (!isTimerDone && seconds <= 1) {
    loadUserData();
    browserHistory.pushLater('/program?express-plan-done');
    setIsTimerDone(true);
    return null;
  }

  return (
    <div className={s.container}>
      <p className={s.timerTitle}>{t('express_plan_program.timer.title')}</p>
      <div className={s.timerContainer}>
        <TimerBlock time={currentHours} />
        <div className={s.seperator}>:</div>
        <TimerBlock time={currentMinutes} />
        <div className={s.seperator}>:</div>
        <TimerBlock time={currentSeconds} />
      </div>
    </div>
  );
}

export default ExpressPlanTimer;
