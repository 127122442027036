import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { UnitSystem, User } from 'types/user';
import { Offer } from 'types/offer';
import { Subscription } from 'types/subscription';
import { FileExtension, getDynamicImage } from 'helpers/imageHelper';
import {
  getStandardOfferPrice,
  getUnsubscribeOfferPrice,
} from 'helpers/offerHelper';

import { SubmitButton, LoadingAnimation } from 'components/common';

import s from './WorkoutsUnsubscribeModal.module.scss';

const Loading = () => (
  <div className={s.container}>
    <div className={s.modal}>
      <div className={s.loading}>
        <LoadingAnimation />
      </div>
    </div>
  </div>
);

type Props = {
  user: User;
  subscription: Subscription | undefined;
  offer: Offer | null;
  onClaimDiscount: Function;
  isDiscountLoading?: boolean;
  onCancelSubscription: Function;
};

// The forwardRef is a requieremnt of mui Modal
const WorkoutsUnsubscribeModal = React.forwardRef(
  (
    {
      user,
      subscription,
      offer,
      onClaimDiscount,
      isDiscountLoading,
      onCancelSubscription,
    }: Props,
    ref
  ) => {
    // Hardcoded as per product requirement
    const discount = '70%';
    const { t } = useTranslation('workouts');
    const transformationImage = getDynamicImage(
      `./workouts/unsubscribe/${user.gender}/transformation`,
      FileExtension.gif
    );

    // Offer is fetched in the parent component for a faster UX
    if (!subscription || !offer) return <Loading />;

    const goalWeight =
      user.unit_system === UnitSystem.si
        ? `${user.goal_weight}${t('unsubscribe.modal.weight_si')}`
        : `${user.goal_weight}${t('unsubscribe.modal.weight_imperial')}`;

    const standardPriceData = getStandardOfferPrice(offer);
    const offerPriceData = getUnsubscribeOfferPrice(offer);
    const standardPrice = standardPriceData?.display_price;
    const offerPrice = offerPriceData?.display_price;

    const handleClaimDiscount = () => {
      onClaimDiscount(offerPriceData);
    };

    const handleCancelSubsciption = () => {
      onCancelSubscription();
    };

    return (
      <div className={s.container}>
        <div className={s.modal}>
          <div className={s.title}>{t('unsubscribe.modal.title')}</div>

          <div className={s.info}>
            <Trans
              i18nKey="workouts:unsubscribe.modal.info"
              values={{
                standardPrice,
                goalWeight,
              }}
              components={[<span className={s.bold} />]}
            />
          </div>

          <div className={s.cta}>
            <div className={s.transformation}>
              <img
                className={s.image}
                src={transformationImage}
                alt="transformation"
                loading="lazy"
              />
              <div className={s.transformationShadow} />
            </div>

            <div className={s.ctaTitle}>
              <div className={s.ctaTitleAccent}>
                <Trans
                  i18nKey="workouts:unsubscribe.modal.pricing_title_1"
                  values={{
                    discount,
                  }}
                />
              </div>
              {t('unsubscribe.modal.pricing_title_2')}
            </div>
            <div className={s.ctaPricing}>
              <Trans
                i18nKey="workouts:unsubscribe.modal.pricing"
                values={{
                  standardPrice,
                  offerPrice,
                }}
                components={[
                  <span className={s.standardPrice} />,
                  <span className={s.offerPrice} />,
                ]}
              />
            </div>
            <div className={s.ctaSubText}>
              {t('unsubscribe.modal.sub_text')}
            </div>
          </div>

          <div className={s.buttons}>
            <SubmitButton
              className={s.submitButton}
              type="large compact"
              label={t('unsubscribe.modal.claim_discount_label')}
              isLoading={isDiscountLoading}
              onClick={handleClaimDiscount}
            />

            <SubmitButton
              className={s.submitButton}
              type="text-large compact"
              variant="outlined"
              label={t('unsubscribe.modal.cancel_label')}
              onClick={handleCancelSubsciption}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default WorkoutsUnsubscribeModal;
