import React from 'react';

import { SVG } from 'types/svg';

function Recipe(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.77 20.52V.76A.77.77 0 0 0 20 0H5.26a2 2 0 0 0-2 2v18.52A3.48 3.48 0 0 0 6.71 24H20a.77.77 0 0 0 .77-.77v-2.7zM4.76 2a.5.5 0 0 1 .5-.5h14V17H6.7a3.17 3.17 0 0 0-.61.07H5.9a1.87 1.87 0 0 0-.42.13l-.2.07a2.94 2.94 0 0 0-.46.25h-.08zm2 20.44a2 2 0 0 1-1.95-1.95 2 2 0 0 1 2-1.94h12.42v1.17H7.78a.77.77 0 0 0-.76.77.76.76 0 0 0 .76.76h11.45v1.2zM8.8 9.22a1.61 1.61 0 0 0 .49-.07l1.28 1.28-3 3a.77.77 0 0 0 0 1.09.77.77 0 0 0 1.08 0l3-3 3 3a.77.77 0 0 0 1.09-1.09l-3-3 1.2-1.2.1.1a2.14 2.14 0 0 0 1.51.63 2 2 0 0 0 1.41-.57L18 8.3a.77.77 0 0 0 0-1.09.76.76 0 0 0-1.08 0l-1.1 1.1a.54.54 0 0 1-.75-.05l-.1-.1 1.45-1.45a.76.76 0 1 0-1.08-1.08l-1.42 1.45-.1-.1a.54.54 0 0 1-.06-.75l1.1-1.1A.77.77 0 1 0 13.78 4l-1.1 1.1a2.06 2.06 0 0 0 .06 2.91l.1.1-1.2 1.23-1.27-1.27a2.8 2.8 0 0 0-.9-2.58C8.4 4.44 7 4.22 6.2 5s-.56 2.2.5 3.26a3 3 0 0 0 2.08.96zM7.3 6.08a.22.22 0 0 1 .1 0 1.56 1.56 0 0 1 1 .52c.5.52.55 1 .5 1.1a1.32 1.32 0 0 1-1.1-.5c-.5-.54-.56-1.04-.5-1.12z" />
    </svg>
  );
}

export default Recipe;
