import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { User } from 'types/user';
import { isWorkoutsAllowed } from 'helpers/workoutsHelper';

type Props = {
  children: React.ReactNode;
};

function EnsureWorkoutsEnabled({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);

  if (!isWorkoutsAllowed(user)) return <Redirect to="/program" />;

  return <>{children}</>;
}

export default EnsureWorkoutsEnabled;
