import React from 'react';

function FastingFire() {
  return (
    <svg width={13} height={16} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.601 4.549c-.434.4-.935.868-1.436 1.602C.698 6.886-.37 8.855.131 11.193c.4 1.902 1.769 3.638 3.538 4.44.334.167.701.267 1.069.367h.066c.167 0 .3-.1.334-.267.034-.167-.067-.367-.234-.4-.333-.067-1.402-.435-1.836-1.37-.467-1.034.034-2.27.635-3.037a2.88 2.88 0 0 1 .667-.635c.2-.167.434-.334.701-.634.601-.701.969-1.502 1.069-2.437.233.4.367.801.4 1.168 0 .3-.033.601-.133.935-.1.467-.2 1.035-.234 1.903 0 .434 0 1.135.568 1.369.467.166 1.068-.034 1.402-.334.1-.1.167-.2.233-.334.1.167.2.3.268.467.434 1.035-.067 1.937-.3 2.27a.35.35 0 0 0 .033.434c.133.1.3.134.434.034 1.335-1.002 2.303-2.204 2.87-3.539.869-2.103.635-4.373-.7-6.543C8.777 1.61 4.404.343 3.135.009c-.133-.034-.267.033-.367.133a.362.362 0 0 0 0 .368c.7.934.968 1.769.834 2.537-.066.667-.467 1.035-1.001 1.502z"
        fill="#29ABE2"
      />
    </svg>
  );
}

export default FastingFire;
