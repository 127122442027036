import * as React from 'react';

const FinishFasting = () => (
  <svg
    width={104}
    height={104}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="d" d="M0 0h250v250H0z" />
      <path id="f" d="M0 .462h250V212H0z" />
      <path id="h" d="M0 .385h250V135H0z" />
      <path id="j" d="M0 .308h250V58H0z" />
      <path id="l" d="M0 0h250v57.693H0z" />
      <path id="n" d="M0 0h250v134.616H0z" />
      <path id="p" d="M0 0h250v211.538H0z" />
      <path id="r" d="M0 0h250v250H0z" />
      <path id="t" d="M0 0h173.077v250H0z" />
      <path id="v" d="M0 0h96.154v250H0z" />
      <path id="x" d="M.77 0h38.46v250H.77z" />
      <path id="z" d="M.847 0H97v250H.847z" />
      <path id="B" d="M.923 0H174v250H.923z" />
      <path id="D" d="M0 0h250v250H0z" />
      <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="a">
        <stop stopColor="#03CEA4" offset="0%" />
        <stop stopColor="#29ABE2" offset="100%" />
      </linearGradient>
      <circle id="b" cx={52} cy={52} r={52} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="url(#a)" cx={52} cy={52} r={52} />
      <mask id="c" fill="#fff">
        <use xlinkHref="#b" />
      </mask>
      <g opacity={0.3} mask="url(#c)">
        <path
          d="M-198-198 52 52C.607-13.599-69.906-96.932-159.539-198h-38.46zm76.923 0C-48.804-102.649 8.89-19.315 52 52 17.8-23.992-27.073-107.325-82.616-198h-38.46zm76.923 0C-4.843-110.894 27.208-27.56 52 52 36.978-29.968 17.748-113.302-5.693-198h-38.46zm76.924 0A7689.187 7689.187 0 0 1 52 52a7689.187 7689.187 0 0 1 19.231-250H32.77zm76.923 0C86.253-113.302 67.022-29.968 52 52c24.792-79.56 56.843-162.894 96.153-250h-38.461zm76.922 0C131.075-107.325 86.203-23.992 52 52c43.111-71.315 100.803-154.649 173.077-250h-38.462z"
          fill="#FFF"
        />
        <g transform="translate(52 -198)">
          <mask id="e" fill="#fff">
            <use xlinkHref="#d" />
          </mask>
          <path
            d="M250 0h-38.46C121.905 101.068 51.392 184.401 0 250L250 0z"
            fill="#FFF"
            mask="url(#e)"
          />
        </g>
        <g transform="translate(52 -160)">
          <mask id="g" fill="#fff">
            <use xlinkHref="#f" />
          </mask>
          <path
            d="M250 38.923V.463C148.932 90.093 65.6 160.606 0 212c71.315-43.112 154.648-100.804 250-173.077"
            fill="#FFF"
            mask="url(#g)"
          />
        </g>
        <g transform="translate(52 -83)">
          <mask id="i" fill="#fff">
            <use xlinkHref="#h" />
          </mask>
          <path
            d="M250 .385C159.325 55.925 75.992 100.799 0 135c79.56-24.791 162.894-56.844 250-96.154V.385z"
            fill="#FFF"
            mask="url(#i)"
          />
        </g>
        <g transform="translate(52 -6)">
          <mask id="k" fill="#fff">
            <use xlinkHref="#j" />
          </mask>
          <path
            d="M250 .308C165.302 23.748 81.968 42.978 0 58a7686.592 7686.592 0 0 0 250-19.231V.308z"
            fill="#FFF"
            mask="url(#k)"
          />
        </g>
        <g transform="translate(52 52)">
          <mask id="m" fill="#fff">
            <use xlinkHref="#l" />
          </mask>
          <path
            d="M250 19.23A7686.582 7686.582 0 0 0 0 0c81.968 15.022 165.302 34.252 250 57.693V19.23z"
            fill="#FFF"
            mask="url(#m)"
          />
        </g>
        <g transform="translate(52 52)">
          <mask id="o" fill="#fff">
            <use xlinkHref="#n" />
          </mask>
          <path
            d="M250 96.154C162.894 56.843 79.56 24.793 0 0c75.992 34.2 159.325 79.072 250 134.615V96.154z"
            fill="#FFF"
            mask="url(#o)"
          />
        </g>
        <g transform="translate(52 52)">
          <mask id="q" fill="#fff">
            <use xlinkHref="#p" />
          </mask>
          <path
            d="M250 173.077C154.648 100.804 71.315 43.111 0 0c65.6 51.392 148.932 121.906 250 211.538v-38.46z"
            fill="#FFF"
            mask="url(#q)"
          />
        </g>
        <g transform="translate(52 52)">
          <mask id="s" fill="#fff">
            <use xlinkHref="#r" />
          </mask>
          <path
            d="M250 250 0 0c51.393 65.6 121.906 148.932 211.54 250H250z"
            fill="#FFF"
            mask="url(#s)"
          />
        </g>
        <g transform="translate(52 52)">
          <mask id="u" fill="#fff">
            <use xlinkHref="#t" />
          </mask>
          <path
            d="M173.077 250C100.804 154.648 43.111 71.314 0 0c34.201 75.991 79.073 159.325 134.615 250h38.462z"
            fill="#FFF"
            mask="url(#u)"
          />
        </g>
        <g transform="translate(52 52)">
          <mask id="w" fill="#fff">
            <use xlinkHref="#v" />
          </mask>
          <path
            d="M96.154 250C56.844 162.894 24.792 79.56 0 0c15.022 81.968 34.252 165.302 57.693 250h38.461z"
            fill="#FFF"
            mask="url(#w)"
          />
        </g>
        <g transform="translate(32 52)">
          <mask id="y" fill="#fff">
            <use xlinkHref="#x" />
          </mask>
          <path
            d="M39.231 250A7689.403 7689.403 0 0 1 20.001 0 7689.403 7689.403 0 0 1 .768 250H39.23z"
            fill="#FFF"
            mask="url(#y)"
          />
        </g>
        <g transform="translate(-45 52)">
          <mask id="A" fill="#fff">
            <use xlinkHref="#z" />
          </mask>
          <path
            d="M39.308 250C62.748 165.302 81.978 81.968 97 0 72.208 79.56 40.157 162.894.847 250h38.46z"
            fill="#FFF"
            mask="url(#A)"
          />
        </g>
        <g transform="translate(-122 52)">
          <mask id="C" fill="#fff">
            <use xlinkHref="#B" />
          </mask>
          <path
            d="M39.385 250C94.927 159.325 139.8 75.991 174 0 130.888 71.314 73.197 154.648.923 250h38.462z"
            fill="#FFF"
            mask="url(#C)"
          />
        </g>
        <g transform="translate(-198 52)">
          <mask id="E" fill="#fff">
            <use xlinkHref="#D" />
          </mask>
          <path
            d="M38.462 250C128.094 148.932 198.608 65.6 250 0L0 250h38.46z"
            fill="#FFF"
            mask="url(#E)"
          />
        </g>
        <path
          d="M-198 263.538C-96.932 173.906-13.599 103.392 52 52c-71.315 43.111-154.648 100.804-250 173.077v38.461zm0-76.923C-107.325 131.072-23.992 86.203 52 52c-79.56 24.793-162.894 56.844-250 96.154v38.462zm0-76.922C-113.302 86.253-29.968 67.022 52 52a7686.582 7686.582 0 0 0-250 19.23v38.462zm0-76.924a7683.783 7683.783 0 0 0 250 19.23c-81.968-15.02-165.302-34.25-250-57.69v38.46z"
          fill="#FFF"
        />
        <path
          d="M-198-44.154C-110.894-4.843-27.56 27.208 52 52-23.992 17.8-107.325-27.073-198-82.616v38.462zm0-76.923C-102.648-48.804-19.315 8.888 52 52-13.599.607-96.932-69.906-198-159.538v38.461z"
          fill="#FFF"
        />
      </g>
      <g fill="#FFF" fillRule="nonzero">
        <path d="M31.91 52.359h5.342l.596 1.103c.375.64.673 1.181.872 1.534 1.103 2.02 4.9 10.064 5.903 12.569.817 2.063 1.104 6.72.74 9.788-.22 2.14-3.134 5.936-4.249 7.217v-.001a2.208 2.208 0 0 0-.541 2.339 2.134 2.134 0 0 0 2.14 1.103h18.539a1.999 1.999 0 0 0 1.6-3.498c-1.103-1.28-4.028-5.075-4.248-7.216-.31-3.068-.078-7.725.739-9.788 1.015-2.515 4.778-10.56 5.881-12.601l.872-1.534.596-1.104h5.341A7.978 7.978 0 0 0 80 44.304v-5.198a7.978 7.978 0 0 0-7.967-7.967h-1.104v-3.83.001A3.31 3.31 0 0 0 67.62 24H36.38a3.31 3.31 0 0 0-3.31 3.31v3.83h-1.103v-.001A7.978 7.978 0 0 0 24 39.106v5.286a7.978 7.978 0 0 0 7.911 7.967zm38.986-9.777v-9.236H72a5.76 5.76 0 0 1 5.76 5.76v5.286a5.762 5.762 0 0 1-5.76 5.76h-4.05c1.611-2.968 2.892-5.75 2.947-7.57zM55.912 84.05h-7.856a1.103 1.103 0 1 1 0-2.207h7.857a1.103 1.103 0 1 1 0 2.207zm-7.272-4.414c0-.61.495-1.103 1.104-1.103h4.447a1.104 1.104 0 0 1 0 2.206h-4.414a1.103 1.103 0 0 1-1.103-1.158l-.034.055zm-5.694-40.805c.15-.478.603-.795 1.104-.773h5.307l1.524-4.844a1.103 1.103 0 0 1 2.096 0l1.633 4.845h5.308a1.104 1.104 0 0 1 .629 2.008l-4.15 2.979 1.623 4.81c.154.45.003.949-.375 1.237a1.104 1.104 0 0 1-1.302 0l-4.359-3.013-4.303 3.024a1.104 1.104 0 0 1-1.302 0 1.104 1.104 0 0 1-.376-1.236l1.567-4.822-4.259-2.98a1.104 1.104 0 0 1-.364-1.235zm-16.795.276a5.762 5.762 0 0 1 5.76-5.76h1.104v9.235c0 1.821 1.335 4.602 2.946 7.57h-4.05a5.76 5.76 0 0 1-5.76-5.76v-5.285z" />
        <path d="M32.34 62.731a1.104 1.104 0 0 0 1.104-1.103v-.585h.585a1.103 1.103 0 1 0 0-2.207h-.585v-.618a1.104 1.104 0 0 0-2.207 0v.585h-.584a1.104 1.104 0 0 0 0 2.207h.584v.584a1.104 1.104 0 0 0 1.104 1.137zm4.492 12.336a1.104 1.104 0 0 0 1.103-1.103v-.585h.585a1.104 1.104 0 0 0 0-2.207h-.585v-.585a1.104 1.104 0 0 0-2.207 0v.585h-.585a1.103 1.103 0 1 0 0 2.207h.585v.585a1.104 1.104 0 0 0 1.104 1.103zm-.562-7.282a1.667 1.667 0 1 0-1.678-1.678 1.677 1.677 0 0 0 1.677 1.678zm0-2.207a.574.574 0 1 1 0 1.103.574.574 0 1 1 0-1.103zm-2.24 14.554a1.677 1.677 0 1 0 0-3.355 1.677 1.677 0 0 0 0 3.355zm0-2.207a.574.574 0 0 1 .563.574.574.574 0 1 1-1.104 0 .573.573 0 0 1 .54-.618v.044zm-6.169-8.44a1.677 1.677 0 1 0 3.354 0 1.677 1.677 0 0 0-3.354 0zm2.207 0a.574.574 0 0 1-.573.563.575.575 0 0 1-.504-1.01.574.574 0 0 1 .504-.094.573.573 0 0 1 .617.54h-.044zm42.097-7.857v-.585h.585a1.103 1.103 0 1 0 0-2.207h-.585v-.618a1.104 1.104 0 0 0-2.207 0v.585h-.585a1.104 1.104 0 0 0 0 2.207h.585v.584a1.103 1.103 0 1 0 2.207 0v.034zM66.57 75.067a1.104 1.104 0 0 0 1.104-1.103v-.585h.585a1.104 1.104 0 0 0 0-2.207h-.585v-.585a1.104 1.104 0 0 0-2.207 0v.585h-.573a1.103 1.103 0 1 0 0 2.207h.584v.585c0 .605.487 1.098 1.093 1.103zm.563-7.282a1.667 1.667 0 1 0-1.677-1.678 1.678 1.678 0 0 0 1.677 1.678zm0-2.207a.574.574 0 1 1 0 1.103.574.574 0 1 1 0-1.103zm2.24 14.554a1.677 1.677 0 1 0 .023-3.354 1.677 1.677 0 0 0-.023 3.354zm0-2.207a.573.573 0 1 1 0 1.146.573.573 0 0 1 0-1.146zm4.491-6.775a1.666 1.666 0 1 0-1.666-1.666c.006.918.748 1.66 1.666 1.667zm0-2.206a.574.574 0 1 1-.563.573.573.573 0 0 1 .563-.606v.033z" />
      </g>
    </g>
  </svg>
);

export default FinishFasting;
