/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import { getCurrentLocale } from 'i18n';
import PropTypes from 'prop-types';

import {
  isFastingBlockAvailable,
  isFastingDateAvailable,
} from 'helpers/fastingHelper';
import settings from 'helpers/settings';
import { isWorkoutsAllowed } from 'helpers/workoutsHelper';
import { freePlanMeals } from 'helpers/programHelper';
import { isMonacofit, getStyle } from 'helpers/themeHelper';

import { TestimonialItem } from 'components/program/_partials';
import ProgramItem from './ProgramItem';
import Workouts from './Workouts';

import base from './ProgramItemContainer.module.scss';
import diet from './ProgramItemContainer_diet.module.scss';
import keto from './ProgramItemContainer_keto.module.scss';
import ketogo from './ProgramItemContainer_ketogo.module.scss';
import fasting from './ProgramItemContainer_fasting.module.scss';
import healthgenom from './ProgramItemContainer_healthgenom.module.scss';

const s = getStyle(base, diet, keto, ketogo, fasting, healthgenom);

export default class ProgramItemContainer extends PureComponent {
  static propTypes = {
    day: PropTypes.object,
    user: PropTypes.object,
    openDiaryModal: PropTypes.func,
  };

  getDayMeals = () => {
    const { day } = this.props;
    let dayMeals = [];
    if (day.day_meals.length) dayMeals = day.day_meals;
    else dayMeals = freePlanMeals(day.id);
    return dayMeals;
  };

  getBreakfast = (dayMeals) =>
    dayMeals.find((item) => item.meal_type === 'breakfast');

  getTrick = (dayMeals) => dayMeals.find((item) => item.meal_type === 'trick');

  getMealElements = (dayMeals) => {
    const { user, day } = this.props;

    return dayMeals.map((meal) => {
      if (['breakfast', 'trick'].includes(meal.meal_type)) {
        return null;
      }

      return (
        <ProgramItem
          type={meal.meal_type}
          key={`day_meal_${meal.id}`}
          status="active"
          data={meal}
          link="meal"
          user={user}
          dayId={day.id}
        />
      );
    });
  };

  render = () => {
    const { day, openDiaryModal, user } = this.props;
    const dayMeals = this.getDayMeals();
    const breakfast = this.getBreakfast(dayMeals);
    const trick = this.getTrick(dayMeals);

    return (
      <div className={`program_page__container ${s.container}`}>
        {isFastingBlockAvailable(user) && isFastingDateAvailable(day.date) && (
          <ProgramItem
            type="fasting"
            key={`fasting_${day.id}`}
            status="active"
            link="fasting"
          />
        )}
        {settings.SHOW_DIARY_CARD && (
          <ProgramItem
            type="diary"
            status="active"
            data={day.diary_entry}
            link="none"
            onClick={openDiaryModal}
            key={`day_${day.id}`}
            dayId={day.id}
          />
        )}
        {breakfast && (
          <ProgramItem
            type={breakfast.meal_type}
            key={`breakfast_${breakfast.id}`}
            status="active"
            data={breakfast}
            link="meal"
            user={user}
            dayId={day.id}
          />
        )}
        {isWorkoutsAllowed(user) && <Workouts day={day} user={user} />}
        {isMonacofit() &&
          day.day_workouts.map((day_workout) => (
            <ProgramItem
              type="exercise"
              key={`day_workout_${day_workout.id}`}
              status="active"
              data={day_workout}
              link="workout"
              user={user}
              dayId={day.id}
            />
          ))}
        {this.getMealElements(dayMeals)}
        {trick && (
          <ProgramItem
            type={trick.meal_type}
            key={`trick_${trick.id}`}
            status="active"
            data={trick}
            link="meal"
            user={user}
            dayId={day.id}
          />
        )}
        {user.testimonial_available &&
        settings.HAS_TESTIMONIAL &&
        ['lv', 'lt'].includes(getCurrentLocale()) ? (
          <TestimonialItem />
        ) : null}
      </div>
    );
  };
}
