import settings from 'helpers/settings';

export const apiHost = () => settings.API_HOST;

export const assetUrl = (url) => {
  if (!url) {
    return '';
  }
  return url.startsWith('http') ? url : `${apiHost()}${url}`;
};

// Fetch data for request
export const fetchData = (request) =>
  fetch(request)
    .then((response) => {
      if (!response.ok) {
        throw response.json();
      }
      return response.json();
    })
    .catch((error) => error);

// Request Headers with JWT token
export const requestHeadersWithToken = (headers) => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.access_token,
    'App-Version': process.env.REACT_APP_VERSION,
  };

  return { ...defaultHeaders, ...headers };
};

// Standart request Header
export const requestHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'App-Version': process.env.REACT_APP_VERSION,
});

export const videoHeaders = () => ({
  'Access-Control-Allow-Origin': '*',
});

export const removeEmptyStrings = (obj) => {
  const newObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value === '') {
      return;
    }
    newObj[key] = value;
  });
  return newObj;
};
