import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (state = initialState.isLoading, action) => {
  switch (action.type) {
    case types.LOG_IN:
    case types.PASSWORD_RENEW:
    case types.NEW_PASSWORD_SET:
    case types.DIRECT_LOG_IN:
    case types.PAGE.LOADING:
      return true;

    case types.LOG_IN.SUCCESS:
    case types.LOG_IN.ERROR:
    case types.PASSWORD_RENEW_SUCCESS:
    case types.PASSWORD_RENEW_ERROR:
    case types.NEW_PASSWORD_SET_SUCCESS:
    case types.NEW_PASSWORD_SET_ERROR:
    case types.DIRECT_LOG_IN_SUCCESS:
    case types.DIRECT_LOG_IN_ERROR:
    case types.PAGE.ACTION:
      return false;

    default:
      return state;
  }
};
