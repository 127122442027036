import Bugsnag, { Event } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import settings from 'helpers/settings';
import { User } from 'types/user';

const APP_VERSION =
  process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA ?? process.env.REACT_APP_VERSION;

export const bugsnagClient = Bugsnag.start({
  apiKey: settings.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  appVersion: APP_VERSION,
  releaseStage: process.env.REACT_APP_ENVIRONMENT,
  onError: (event: Event) => {
    const { errorClass, errorMessage } = event.errors[0];

    if (
      errorClass === 'ChunkLoadError' ||
      errorMessage.includes('Loading CSS chunk')
    ) {
      window.location.reload();
      return false;
    }

    return true;
  },
});

export const updateBugsnagUser = (user?: User) =>
  bugsnagClient.setUser(user ? user.id : undefined);
