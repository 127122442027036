import i18n from 'i18n';
import { addError } from 'redux-flash-messages';

import { api } from 'helpers/api';
import { FastingCycle } from 'types/fasting';

export const storeFastingCycle = async (fastingCycle: FastingCycle) => {
  try {
    return await api(`api/v1/users/fasting-cycle`, {
      method: 'POST',
      body: fastingCycle,
    });
  } catch (e) {
    addError({ text: i18n.t('error.500_error') });
    throw e;
  }
};

export const retrieveFastingData = async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    return await api('api/v1/users/fasting', {
      method: 'GET',
    });
  } catch (e) {
    throw e;
  }
};

export const addFastingStart = async () => {
  try {
    return await api('api/v1/users/fasting', {
      method: 'POST',
      body: { type: 'fasting' },
    });
  } catch (e) {
    addError({ text: i18n.t('error.500_error') });
    throw e;
  }
};

export const addEatingStart = async () => {
  try {
    return await api('api/v1/users/fasting', {
      method: 'POST',
      body: { type: 'eating' },
    });
  } catch (e) {
    addError({ text: i18n.t('error.500_error') });
    throw e;
  }
};
