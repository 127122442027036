/* eslint-disable camelcase */
import { differenceInCalendarDays, parse } from 'date-fns';
import * as types from 'actions/actionTypes';
import {
  getUserProfileData,
  getUserCurrentDayData,
  getUserDayDate,
  createDiaryEntry,
  getCalendarData,
  getClientLocale,
} from 'api/ProgramAPI';
import ResponseHandler from 'actions/ResponseHandler';
import { addError } from 'redux-flash-messages';
import { isLoading, isNotLoading } from 'actions/loadingActions';
import {
  hasValidationMessage,
  noValidationMessage,
} from 'actions/validationMessageActions';
import { isFutureRange } from 'helpers/timeHelper';
import { addDaysFormat, subDaysFormat } from 'helpers/dateHelper';
import isEmpty from 'lodash/isEmpty';
import { ThunkActionType } from 'store/types';
import { isPrimaryTheme } from 'helpers/themeHelper';
import { ModalType } from 'types/modals';
import i18n, { getCurrentLocale } from 'i18n';
import settings from 'helpers/settings';
import { addModal } from './modalActions';
// eslint-disable-next-line import/no-cycle
import { setUser } from './userActions';

type PayloadAny = any;

export const diaryEntryCreateBeginAction = () => ({
  type: types.DIARY_ENTRY_CREATE.ACTION,
});

export const diaryEntryCreateSuccessAction = (diary: PayloadAny) => ({
  type: types.DIARY_ENTRY_CREATE.SUCCESS,
  diary,
});

export const diaryEntryCreateErrorAction = (error: PayloadAny) => ({
  type: types.DIARY_ENTRY_CREATE.ERROR,
  error,
});

export const loadCalendarBeginAction = () => ({
  type: types.CALENDAR_LOAD.ACTION,
});

export const loadCalendarSuccessAction = (calendar: PayloadAny) => ({
  type: types.CALENDAR_LOAD.SUCCESS,
  calendar,
});

export const loadCalendarErrorAction = (error: PayloadAny) => ({
  type: types.CALENDAR_LOAD.ERROR,
  error,
});

export const noPastDataCalendar = () => ({ type: types.CALENDAR_NO_PAST_DATA });

export const noFutureDateCalendar = () => ({
  type: types.CALENDAR_NO_FUTURE_DATA,
});

export const morePastDataCalendar = (calendar: PayloadAny) => ({
  type: types.CALENDAR_MORE_PAST_DATA,
  calendar,
});

export const moreFutureDataCalendar = (calendar: PayloadAny) => ({
  type: types.CALENDAR_MORE_FUTURE_DATA,
  calendar,
});

export const getCalendarDataAction =
  (paramsArg: PayloadAny = { user: { day_count: 7 } }): ThunkActionType =>
  (dispatch) => {
    const params = { ...paramsArg };
    const { start_date, end_date } = params.user;

    // Contain dates in +/- 14 days.
    if (start_date && end_date) {
      const todaysDate = new Date();

      const passedEndDate = parse(end_date, 'dd.MM.yyyy', new Date());
      const passedStartDate = parse(start_date, 'dd.MM.yyyy', new Date());

      const todayEndDaysDiff = differenceInCalendarDays(
        todaysDate,
        passedEndDate
      );
      const todayStartDaysDiff = differenceInCalendarDays(
        todaysDate,
        passedStartDate
      );

      if (todayEndDaysDiff <= -14) {
        params.user.end_date = addDaysFormat(todaysDate, 14, 'dd.MM.yyyy');
      } else if (todayStartDaysDiff >= 14) {
        params.user.start_date = subDaysFormat(todaysDate, 13, 'dd.MM.yyyy');
      }
    }

    dispatch(loadCalendarBeginAction());
    dispatch(isLoading());

    return getCalendarData(params)
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) {
          throw resHandler.getFlashMsgText();
        } else {
          const normalizedResponse = resHandler.getNormalizedResponse();
          if (start_date && end_date) {
            if (isEmpty(normalizedResponse.days)) {
              if (isFutureRange(start_date, end_date)) {
                dispatch(noFutureDateCalendar());
              } else {
                dispatch(noPastDataCalendar());
              }
            } else if (isFutureRange(start_date, end_date)) {
              dispatch(moreFutureDataCalendar(normalizedResponse));
            } else {
              dispatch(morePastDataCalendar(normalizedResponse));
            }
          } else {
            dispatch(loadCalendarSuccessAction(normalizedResponse));
          }
          dispatch(isNotLoading());
        }
      })
      .catch((error) => {
        dispatch(loadCalendarErrorAction(error));
        dispatch(isNotLoading());
      });
  };

export const loadUserBeginAction = () => ({
  type: types.USER_LOAD.ACTION,
});

export const loadUserSuccessAction = (user: PayloadAny) => ({
  type: types.USER_LOAD.SUCCESS,
  user,
});

export const loadUserErrorAction = (error: PayloadAny) => ({
  type: types.USER_LOAD.ERROR,
  error,
});

export const getUserData = (): ThunkActionType => (dispatch) => {
  dispatch(loadUserBeginAction());
  return getUserProfileData()
    .then((response) => {
      const resHandler = new ResponseHandler(response);
      if (resHandler.hasError()) {
        throw resHandler.getFlashMsgText();
      } else {
        dispatch(setLocale(resHandler.getNormalizedResponse().locale));
        dispatch(loadUserSuccessAction(resHandler.getNormalizedResponse()));
      }
    })
    .catch((error) => dispatch(loadUserErrorAction(error)));
};

export const programActions = {
  getCalendarDataAction,
  getUserData,
};

export const createDiaryEntryAction =
  (entry: PayloadAny, dayId: number): ThunkActionType =>
  (dispatch) => {
    dispatch(diaryEntryCreateBeginAction());
    return (
      createDiaryEntry(entry, dayId)
        // eslint-disable-next-line consistent-return
        .then((response) => {
          const resHandler = new ResponseHandler(response);
          if (resHandler.hasError()) {
            if (resHandler.hasValidationError())
              dispatch(hasValidationMessage(resHandler.getValidationErrors()));
            else throw resHandler.getFlashMsgText();
          } else {
            dispatch(noValidationMessage());
            dispatch(diaryEntryCreateSuccessAction(response.diaryEntry));
            dispatch(programActions.getCalendarDataAction());
            if (isPrimaryTheme()) {
              dispatch(getUserData()); // TODO: replace with GET /statistics, when available
            }

            if (
              response.user?.program_update?.available &&
              settings.SHOW_PLAN_UPDATE
            ) {
              dispatch(
                setUser({ program_update: response.user.program_update })
              );
              return dispatch(addModal({ modal: ModalType.planUpdate }));
            }

            if (
              response.user?.testimonial_available &&
              settings.HAS_TESTIMONIAL &&
              ['lv', 'lt'].includes(getCurrentLocale())
            ) {
              dispatch(addModal({ modal: ModalType.testimonial }));
            }
          }
        })
        .catch((error) => {
          addError({ text: error });
          dispatch(diaryEntryCreateErrorAction(error));
        })
    );
  };

export const loadDayBeginAction = () => ({
  type: types.DAY_LOAD.ACTION,
});

export const loadDaySuccessAction = (day: PayloadAny) => ({
  type: types.DAY_LOAD.SUCCESS,
  day,
});

export const loadDayErrorAction = (error: PayloadAny) => ({
  type: types.DAY_LOAD.ERROR,
  error,
});

export const getUserCurrentDayDataAction =
  (): ThunkActionType => (dispatch) => {
    dispatch(loadDayBeginAction());
    return getUserCurrentDayData()
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) {
          throw resHandler.getFlashMsgText();
        } else {
          dispatch(loadDaySuccessAction(resHandler.getNormalizedResponse()));
        }
      })
      .catch((error) => dispatch(loadDayErrorAction(error)));
  };

export const getUserDayData =
  (dayId: number): ThunkActionType =>
  (dispatch) => {
    dispatch(loadDayBeginAction());
    return getUserDayDate(dayId)
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) {
          addError({ text: resHandler.getFlashMsgText() });
          throw resHandler.getFlashMsgText();
        } else {
          dispatch(loadDaySuccessAction(resHandler.getNormalizedResponse()));
        }
      })
      .catch((error) => dispatch(loadDayErrorAction(error)));
  };

export const clearDay = () => ({
  type: types.DAY_CLEAR,
});

export const setLocaleAction = (locale: string) => ({
  type: types.SET_LOCALE.ACTION,
  payload: locale,
});

export const setLocaleSuccess = (locale: string) => ({
  type: types.SET_LOCALE.SUCCESS,
  payload: locale,
});

export const setLocale =
  (locale: string): ThunkActionType =>
  (dispatch) => {
    const languageOnly = locale.substring(0, 2);

    dispatch(setLocaleAction(languageOnly));
    return (
      i18n
        .changeLanguage(languageOnly)
        .then(() => dispatch(setLocaleSuccess(languageOnly)))
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err))
    );
  };

export const getClientLocaleAction = (): ThunkActionType => (dispatch) =>
  getClientLocale().then((response) => dispatch(setLocale(response.locale)));
