import React from 'react';

import { SVG } from 'types/svg';

function Phone(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.86 23.76a5.11 5.11 0 0 1-3.53-1.41A37.27 37.27 0 0 1 6.72 17a27.25 27.25 0 0 1-5.33-7.6 5.1 5.1 0 0 1 .11-7.11l.14-.14c1.65-1.6 3.52-3.41 7.08.14a5.13 5.13 0 0 1 .06 7.15 6.77 6.77 0 0 1-.8.67 31.66 31.66 0 0 0 2.53 3.14 30.33 30.33 0 0 0 3.13 2.52 5.25 5.25 0 0 1 .61-.73 5.15 5.15 0 0 1 7.22 0c1.69 1.69 2.53 2.53 2.53 3.61s-.83 1.91-2.47 3.56l-.06.05a5.05 5.05 0 0 1-3.61 1.5zM5 1.79c-.79 0-1.43.62-2.31 1.47l-.08.08A3.57 3.57 0 0 0 1.54 5.9a3.52 3.52 0 0 0 1 2.52.82.82 0 0 1 .16.25A26 26 0 0 0 7.81 16a37.08 37.08 0 0 0 7.34 5 .8.8 0 0 1 .19.13 3.64 3.64 0 0 0 5 0l.05-.05c1.22-1.22 2-2 2-2.47s-.87-1.31-2.08-2.52a3.59 3.59 0 0 0-5 0 3.67 3.67 0 0 0-.78 1.12.79.79 0 0 1-.5.44.76.76 0 0 1-.65-.12 35.41 35.41 0 0 1-4-3.19 38.51 38.51 0 0 1-3.2-4 .75.75 0 0 1-.11-.65.77.77 0 0 1 .43-.5 3.57 3.57 0 0 0 1.13-5.81C6.49 2.24 5.7 1.79 5 1.79z" />
    </svg>
  );
}

export default Phone;
