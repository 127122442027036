import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getStyle } from 'helpers/themeHelper';

import base from './ProgramItemSubHeader.module.scss';
import diet from './ProgramItemSubHeader_diet.module.scss';

const s = getStyle(base, diet);

class ProgramItemSubHeader extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.object,
  };

  render = () => {
    const { t, type, data } = this.props;

    switch (type) {
      case 'diary':
        return (
          <p
            className={`program_page__container__item__info__sub ${s.container}`}
          >
            {t('items.type.diary.weight')}&nbsp;
            {data && data.weight !== null ? data.weight : '--'}
            {t('units.kg')}&emsp;&emsp;
            {t('items.type.diary.energy')}&nbsp;
            {data !== null ? data.energy_percent : '--'}%
          </p>
        );

      case 'exercise':
        return (
          <p
            className={`program_page__container__item__info__sub ${s.container}`}
          >
            {t('items.type.diary.weight')}&nbsp;
            {data !== null ? data.weight : '--'}
            {t('units.kg')}&emsp;&emsp;
            {t('items.type.diary.energy')}&nbsp;
            {data !== null ? data.energy_percent : '--'}%
          </p>
        );

      default:
        return (
          <p
            className={`program_page__container__item__info__sub ${s.container}`}
          >
            {data.title}
          </p>
        );
    }
  };
}

export default withTranslation()(ProgramItemSubHeader);
