import * as t from 'actions/actionTypes';

import initialState from 'reducers/initialState';

export default (
  state = initialState.globalData,
  action: t.GlobalDataActionTypes
) => {
  switch (action.type) {
    case t.GLOBAL_DATA_LOAD:
      return { ...state, isLoading: true };
    case t.GLOBAL_DATA_SUCCESS_UNSUBSCRIBE_REASONS:
      return {
        isLoading: false,
        hasError: false,
        globalData: {
          unsubscribeReasons: action.unsubscribeReasons,
        },
      };
    case t.GLOBAL_DATA_ERROR:
      return { ...state, isLoading: false, hasError: true };
    case t.LOG_OUT.SUCCESS:
      return initialState.accessItems;
    default:
      return state;
  }
};
