import React from 'react';

import { SVG } from 'types/svg';

function CardSaveConfirmationMonacofit(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={56} height={56} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#60BD78" cx={28} cy={28} r={28} />
        <path
          d="M10.112 20.771a.841.841 0 0 1-1.543-.668 21.073 21.073 0 0 1 8.32-9.571.84.84 0 0 1 .876 1.435 19.388 19.388 0 0 0-7.653 8.804zm1.719 18.24a.84.84 0 0 0-1.542.3.842.842 0 0 0 .15.64c.51.757 1.07 1.478 1.674 2.16a.84.84 0 1 0 1.257-1.117 19.446 19.446 0 0 1-1.54-1.986v.003zm28.117 3.984a19.094 19.094 0 0 1-23.36.896.841.841 0 0 0-.978 1.368 20.774 20.774 0 0 0 25.414-.977.84.84 0 1 0-1.078-1.288l.002.001zm7.978-19.54a.84.84 0 0 0-1.635.393 19.08 19.08 0 0 1 .528 4.472.84.84 0 0 0 1.681 0c0-1.639-.192-3.271-.574-4.865zM32.272 8.019a.84.84 0 0 0-.369 1.64 19.205 19.205 0 0 1 12.422 9.225.84.84 0 1 0 1.461-.832A20.898 20.898 0 0 0 32.272 8.018zm12.87 20.301a17.436 17.436 0 0 1-14.198 17.147 17.439 17.439 0 0 1-19.478-10.78.808.808 0 0 1-.06-.162 17.44 17.44 0 0 1 23.129-22.261A17.439 17.439 0 0 1 45.143 28.32zM24.011 43.636l2.65-2.658a.613.613 0 0 0 .002-.884L17.573 31a.624.624 0 0 0-.887 0l-3.493 3.502a15.82 15.82 0 0 0 10.818 9.133zM21.44 27.87a5.405 5.405 0 0 1-1.215 3.41l6.371 6.372a5.32 5.32 0 0 1 1.022-1.13c.47-.282.99-.47 1.53-.557a5.428 5.428 0 0 0 2.913-1.359l.672-.794c1.125-1.32 3.006-3.53 4.1-4.938a3.553 3.553 0 0 0 .332-3.845l-3.092-5.814a1.357 1.357 0 0 0-2.148-.33L25.19 25.44a.841.841 0 0 1-1.413-.456l-.772-4.351a1.345 1.345 0 0 0-1.536-1.104l-.721.11c.221 1.971.692 6.464.692 8.23zm22.016.45a15.768 15.768 0 1 0-30.89 4.433l2.931-2.938a2.321 2.321 0 0 1 3.261 0l.27.27c.476-.64.732-1.416.73-2.214 0-1.765-.528-6.686-.69-8.153a1.563 1.563 0 0 1 1.33-1.723l.822-.124a3.02 3.02 0 0 1 3.447 2.473l.494 2.789 5.595-5.448a3.035 3.035 0 0 1 4.807.742l3.093 5.814a5.24 5.24 0 0 1-.49 5.669c-1.116 1.435-3.013 3.663-4.147 4.994l-.672.79a6.81 6.81 0 0 1-3.799 1.906 5.48 5.48 0 0 0-.924.274c-.318.29-.592.622-.816.99l.046.045a2.292 2.292 0 0 1 0 3.265L26.03 44a15.95 15.95 0 0 0 1.664.09 15.786 15.786 0 0 0 15.767-15.771h-.006z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default CardSaveConfirmationMonacofit;
