import { api } from 'helpers/api';
import i18n from 'i18n';
import {
  GenomPlusSurveyAnswersType,
  GenomPlusSurveyDataType,
} from 'pages/GenomPlusSurvey/types';
import { addError } from 'redux-flash-messages';

export const genomPlusSurveyApiGet = async () => {
  try {
    const response: {
      data: GenomPlusSurveyDataType;
    } = await api('api/v1/users/surveys/genom', {
      method: 'GET',
    });

    return response?.data;
  } catch (error) {
    addError({ text: i18n.t('error.500_error') });
    throw error;
  }
};

export const genomPlusSurveyApiPost = async (
  answers: GenomPlusSurveyAnswersType
) => {
  try {
    const response: {
      data: GenomPlusSurveyDataType;
    } = await api('api/v1/users/surveys/genom', {
      method: 'POST',
      body: {
        answers,
      },
    });

    return response?.data;
  } catch (error) {
    addError({ text: i18n.t('error.500_error') });
    throw error;
  }
};
