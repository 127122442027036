import * as types from 'actions/actionTypes';

type PayloadAny = any;

export const hasValidationMessage = (validationMessage: PayloadAny) => {
  window.scrollTo(0, 0);
  return { type: types.VALIDATION_MSG_HAS, validationMessage };
};

export const noValidationMessage = () => ({ type: types.VALIDATION_MSG_NONE });
