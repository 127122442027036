import { useState, useEffect } from 'react';

import { Timer } from 'types/fasting';

type Props = {
  timer: Timer;
};

function useFastingTimer({ timer }: Props) {
  const [activeSecondsInFasting, setActiveSecondsInFasting] = useState(
    timer.time_in_the_cycle
  );

  useEffect(() => {
    let timerValue: any;
    if (timer) {
      timerValue = setInterval(() => {
        setActiveSecondsInFasting(
          (prevActiveSecondsInFasting) => prevActiveSecondsInFasting + 1
        );
      }, 1000);
    }

    return () => {
      clearInterval(timerValue);
    };
  }, [timer]);

  return { activeSecondsInFasting };
}

export default useFastingTimer;
