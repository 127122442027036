import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { User } from 'types/user';
import { UnsubscribeCommondataData } from 'types/unsubscribe';
import { getUserActiveProSubscription } from 'helpers/userHelper';

import { getUnsubscribeData } from 'actions/subscriptionActions';

import { LoadingAnimation } from 'components/common';

type Props = {
  children: React.ReactNode;
};

function EnsureUnsubscribeCommondataLoaded({ children }: Props) {
  const dispatch = useDispatch();
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const unsubscribeCommondata = useSelector<any, UnsubscribeCommondataData>(
    (store) => store.rootReducer.unsubscribeCommondata
  );
  const activeSubscription = getUserActiveProSubscription(user);
  const [unsubscribeCommondataLoaded, setUnsubscribeCommondataLoaded] =
    useState(false);

  useEffect(() => {
    if (!activeSubscription?.id) {
      return;
    }
    dispatch(getUnsubscribeData(activeSubscription.id));
    setUnsubscribeCommondataLoaded(true);
  }, [activeSubscription, dispatch]);

  if (!activeSubscription?.id) {
    return <Redirect to="/program" />;
  }
  if (!unsubscribeCommondataLoaded || unsubscribeCommondata.isLoading) {
    return <LoadingAnimation />;
  }
  if (unsubscribeCommondata.hasError) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
}

export default EnsureUnsubscribeCommondataLoaded;
