/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getLocaleDirection } from 'i18n';
import Timer from 'easytimer.js';

import { FileExtension } from 'helpers/imageHelper';

import { ResponsiveImage } from 'components/common';

import s from './WorkoutsHeader.module.scss';

type Props = {
  workoutPhase: string;
  currentCount: number;
  totalCount: number;
  globalTimer: Timer;
  onClick: () => void;
};

function WorkoutsHeader({
  workoutPhase,
  currentCount,
  totalCount,
  globalTimer,
  onClick,
}: Props) {
  const { t } = useTranslation('workouts');
  const progressCount = currentCount + 1;

  return (
    <div className={s.headerContainer} dir={getLocaleDirection()}>
      <div className={s.leftSide}>
        <p className={s.title}>
          {t(`workouts.inprogress.phase.${workoutPhase}`)} {progressCount}/
          {totalCount}
        </p>
        <p className={s.time}>
          {globalTimer.getTimeValues().toString(['minutes', 'seconds'])}
        </p>
      </div>
      <div className={s.rightSide}>
        <button
          type="button"
          className={`${s.buttonNormalize} ${s.exitBtn}`}
          onClick={onClick}
        >
          <ResponsiveImage
            imagePath="./workouts/cross-exit"
            imageExtension={FileExtension.png}
            altText="exit"
            style={s.exitImage}
          />
        </button>
      </div>
    </div>
  );
}

export default WorkoutsHeader;
