import React from 'react';

import { SVG } from 'types/svg';

function ChatBubble(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M16.59 21.75a.78.78 0 0 1-.55-.23l-4.36-4.36C5.1 17.06 0 13.82 0 9.7s5.27-7.45 12-7.45S24 5.52 24 9.7c0 2.86-2.59 5.44-6.64 6.68V21a.77.77 0 0 1-.48.72.72.72 0 0 1-.29.03zM12 3.8C6.33 3.8 1.55 6.5 1.55 9.7S6.33 15.61 12 15.61a.81.81 0 0 1 .55.23l3.26 3.27V15.8a.78.78 0 0 1 .58-.75c3.68-1 6.06-3.08 6.06-5.35 0-3.2-4.78-5.9-10.45-5.9z" />
    </svg>
  );
}

export default ChatBubble;
