import React from 'react';

import { SVG } from 'types/svg';

function LoadingFasting(props: SVG) {
  return (
    <svg
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0z"
          id="prefix__f12db72c-8553-4a51-8d12-70fefe37867e"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__a339595f-8f55-4142-88a8-f6f2e57ce9af" fill="#fff">
          <use xlinkHref="#prefix__f12db72c-8553-4a51-8d12-70fefe37867e" />
        </mask>
        <path
          d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0z"
          fill="#03CEA4"
          mask="url(#prefix__a339595f-8f55-4142-88a8-f6f2e57ce9af)"
        />
        <path
          d="M8.247 16.787a3.674 3.674 0 0 1-1.654-1.095c-.445-.512-.731-1.13-.858-1.852-.128-.722-.07-1.4.173-2.034a3.663 3.663 0 0 1 1.18-1.593c.543-.429 1.19-.71 1.94-.842a4.323 4.323 0 0 1 1.773.031 3.49 3.49 0 0 1 1.455.732l-.907 1.191c-.593-.483-1.254-.66-1.984-.53a2.46 2.46 0 0 0-1.156.509c-.32.26-.546.585-.682.975a2.47 2.47 0 0 0-.084 1.26c.08.452.25.837.51 1.157.26.319.586.546.975.681a2.47 2.47 0 0 0 1.26.084c.73-.128 1.29-.524 1.681-1.187l1.26.808a3.43 3.43 0 0 1-1.12 1.197 4.344 4.344 0 0 1-1.661.636c-.744.131-1.444.089-2.101-.128m6.644-6.924.34 1.927 3.375-.595.24 1.354-3.377.595.47 2.657-1.689.298-1.285-7.293 5.51-.972.24 1.355zm-3.994-6.696.364.684a.178.178 0 0 0 .188.092l.763-.135c.17-.03.28.175.16.3l-.539.557a.18.18 0 0 0-.03.208l.365.683a.178.178 0 0 1-.236.245l-.696-.34a.178.178 0 0 0-.207.037l-.538.557a.178.178 0 0 1-.305-.149l.108-.767a.18.18 0 0 0-.099-.185l-.696-.34a.178.178 0 0 1 .048-.336l.762-.134a.18.18 0 0 0 .146-.151l.108-.767a.178.178 0 0 1 .334-.06m2.601 15.994.364.684a.178.178 0 0 0 .188.091l.763-.134c.17-.03.279.175.159.3l-.538.557a.18.18 0 0 0-.03.207l.365.684a.178.178 0 0 1-.236.244l-.696-.339a.178.178 0 0 0-.207.037l-.538.556a.178.178 0 0 1-.305-.148l.108-.767a.18.18 0 0 0-.099-.186l-.696-.339a.178.178 0 0 1 .048-.336l.762-.134a.18.18 0 0 0 .146-.151l.108-.767a.178.178 0 0 1 .334-.06"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default LoadingFasting;
