import merge from 'lodash/merge';
import forIn from 'lodash/forIn';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import auth from 'helpers/authenticator';
import browserHistory from 'helpers/history';
import i18n from '../i18n';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponseAny = any;

class ResponseHandler {
  response: ResponseAny;

  constructor(response: ResponseAny) {
    this.response = response;
    this.checkIfHasAccess();
  }

  checkIfHasAccess() {
    if (this.response?.error?.status === 401) {
      auth.logOut();
      browserHistory.replaceLater('/');
    }
  }

  // Checks if response contains error or errors
  hasError() {
    if ('error' in this.response || 'errors' in this.response) {
      return true;
    }
    return false;
  }

  hasValidationError() {
    return (
      isArray(this.response.errors) && this.response.errors[0].status === 499
    );
  }

  // Returns an array with errors
  getFlashMsgText() {
    let errorDetail;
    if (Array.isArray(this.response.errors)) {
      errorDetail = this.response.errors[0].detail;
    } else {
      errorDetail = this.response.error.detail;
    }

    if (!errorDetail) {
      return i18n.t('error.500_error');
    }

    return errorDetail;
  }

  getValidationErrors() {
    const hash: ResponseAny = {};
    this.response.errors.forEach((error: ResponseAny) => {
      hash[error.source] = error.detail;
    });
    return hash;
  }

  // Returns body
  getResponseBody(json = this.response) {
    if (json.data && json.data.id)
      return merge(
        { id: json.data.id, type: json.data.type },
        json.data.attributes
      );
    if (json.data) return json.data;
    if (json.id && json.attributes)
      return merge({ id: json.id }, json.attributes);
    if (json.attributes) return json.attributes;
    return json;
  }

  // Normalizes object level
  normalizeLevel(object: ResponseAny) {
    const objectCopy: ResponseAny = {};

    forIn(object, (value, key) => {
      if (isArray(value)) {
        objectCopy[key] = [];
        if (!!value[0] && isObject(value[0])) {
          value.forEach((item) => {
            objectCopy[key].push(
              this.normalizeLevel(this.getResponseBody(item))
            );
          });
        } else {
          objectCopy[key] = value;
        }
      } else if (isObject(value)) {
        objectCopy[key] = this.normalizeLevel(this.getResponseBody(value));
      } else {
        objectCopy[key] = value;
      }
    });

    return objectCopy;
  }

  // Returns normalized object
  getNormalizedResponse(object = this.response) {
    const respBody = this.getResponseBody(object);
    return this.normalizeLevel(respBody);
  }
}

export default ResponseHandler;
