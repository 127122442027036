import { useState, useEffect } from 'react';
import { composeTheme } from '@css-modules-theme/core';
import settings from './settings';

export type ThemeState =
  | 'monacofit'
  | 'diet'
  | 'keto'
  | 'fasting'
  | 'ketogo'
  | 'healthgenom';

type CssModule = {
  readonly [key: string]: string;
};

type StylesParams = {
  base: CssModule;
  diet?: CssModule;
  keto?: CssModule;
  fasting?: CssModule;
  ketogo?: CssModule;
  healthgenom?: CssModule;
};

const listeners: React.Dispatch<React.SetStateAction<ThemeState>>[] = [];
let state: ThemeState = settings.THEME as ThemeState;

if (state) {
  document.body.classList.add(state);
}

export const getTheme = (): ThemeState => state;

// Used to get the theme-specific images sub-folder.
// Some imges may be common - put hem just into the root
// of the parent folder and call this without args.
export const getThemeImageFolder = (theme?: ThemeState) =>
  theme ? `${theme}/` : '';

export const setTheme = (newState: ThemeState) => {
  document.body.classList.remove(state);
  document.body.classList.add(newState);
  state = newState;
  listeners.forEach((listener) => {
    listener(state);
  });
};

const useTheme = ({
  base,
  diet,
  keto,
  fasting,
  ketogo,
  healthgenom,
}: StylesParams): [CssModule, ThemeState] => {
  const newListener = useState<ThemeState>(state)[1];
  useEffect(() => {
    listeners.push(newListener);
  }, [newListener]);

  const theme = {
    monacofit: {
      theme: base,
    },
    diet: {
      theme: diet,
    },
    keto: {
      theme: keto,
    },
    fasting: {
      theme: fasting,
    },
    ketogo: {
      theme: ketogo,
    },
    healthgenom: {
      theme: healthgenom,
    },
  };

  let styles = base;

  const currentTheme = theme[state];
  if (currentTheme?.theme) {
    styles = composeTheme([theme.monacofit, currentTheme as any]);
  }

  return [styles, state];
};

export default useTheme;
