import { UnsubscribeReasons } from 'types/globalData';

export enum UNSUBSCRIBE_REASONS {
  timing = 'timing_is_not_right',
  expectations = 'did_not_meet_my_needs_and_expectations',
  highPrice = 'price_is_too_high',
  difficultFollow = 'difficult_to_follow_weight_loss_planning',
  foundAlternative = 'found_an_alternative',
  longer = 'no_longer_want_to_lose_weight',
  reachedGoal = 'reached_my_goal',
  other = 'other',
}

const ALLOWED_UNSUBSCRIBE_REASONS: UnsubscribeReasons =
  Object.values(UNSUBSCRIBE_REASONS);

// eslint-disable-next-line import/prefer-default-export
export const getSortedUnsubscribeReasons = (
  unsubscribeReasons: UnsubscribeReasons
) => {
  const sortedArray = unsubscribeReasons.filter((reason) =>
    ALLOWED_UNSUBSCRIBE_REASONS.includes(reason)
  );

  return sortedArray;
};
