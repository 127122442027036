import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { User } from 'types/user';
import { isCollagenAvailable } from 'helpers/collagenHelper';

type Props = {
  children: React.ReactNode;
};

function EnsureCollagenAllowed({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);

  if (!isCollagenAvailable(user)) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
}

export default EnsureCollagenAllowed;
