import antioxidants from './antioxidants.svg';
import blood from './blood.svg';
import bones from './bones.svg';
import brain from './brain.svg';
import stomach from './stomach.svg';
import energy from './energy.svg';
import eyes from './eyes.svg';
import heart from './heart.svg';
import immunity from './immunity.svg';
import age from './age.svg';
import metabolism from './metabolism.svg';
import muscles from './muscles.svg';
import satiety from './satiety.svg';
import skin from './skin.svg';
import sleep from './sleep.svg';
import teeth from './teeth.svg';
import feel from './feel.svg';

export default {
  antioxidants,
  blood,
  bones,
  brain,
  stomach,
  energy,
  eyes,
  heart,
  immunity,
  age,
  metabolism,
  muscles,
  satiety,
  skin,
  sleep,
  teeth,
  feel,
};
