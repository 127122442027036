import { hoursToSeconds, intervalToDuration } from 'date-fns';

import {
  FastingBodyStatus,
  FastingTimerFormat,
  FastingTimerType,
  Timer,
} from 'types/fasting';
import { User } from 'types/user';
import { isUserFree } from 'helpers/userHelper';
import { isTodayISO } from './dateHelper';
import settings from './settings';

export const isFastingBlockAvailable = (user: User): boolean => {
  if (!settings.SHOW_FASTING) {
    return false;
  }
  if (isUserFree(user)) {
    return false;
  }

  return true;
};

export const isFastingDateAvailable = (date: string) => {
  if (!isTodayISO(date)) {
    return false;
  }

  return true;
};

export const isFastingGoalReached = (
  fastingCycleHours: number,
  secondsInFasting: number
): boolean => secondsInFasting >= hoursToSeconds(fastingCycleHours);

/**
 * This function returns human readable current time in fasting cycle
 *
 *
 * @param hours Cycles hours
 * @param seconds Time in fasting cycle in seconds
 * @param format Returning string format. full - "14h 13m 12s" | medium - "14h 13m" . Default - full
 * @returns String of current time in fasting cycle. For example - "14h 13m 12s"
 */
export const getFastedForTimeValue = (
  hours: number,
  seconds: number,
  format = FastingTimerFormat.full
) => {
  const durations = intervalToDuration({ start: hours, end: seconds * 1000 });
  let currentHours = 0;
  let currentMinutes = 0;
  let currentSecons = 0;

  if ((durations.days as number) > 0) {
    currentHours = (durations.days as number) * 24;
  }
  if (durations.hours) {
    currentHours += durations.hours;
  }
  if (durations.minutes) {
    currentMinutes = durations.minutes;
  }
  if (durations.seconds) {
    currentSecons = durations.seconds;
  }

  if (format === FastingTimerFormat.medium) {
    return `${currentHours}h ${currentMinutes}m`;
  }

  return `${currentHours}h ${currentMinutes}m ${currentSecons}s`;
};

/**
 * This function returns human readable remaining time for fasting
 *
 *
 * @param hours Cycles hours
 * @param seconds Timers time in cycle seconds
 * @param format Returning string format. full - "14h 13m 12s" | medium - "14h 13m" . Default - full
 * @returns String of remaining time. For example - "14h 13m 12s"
 */
export const getFastedRemainingTime = (
  hours: number,
  seconds: number,
  format = FastingTimerFormat.full
) => {
  const fastingCycleInSeconds = hoursToSeconds(hours);
  const durations = intervalToDuration({
    start: fastingCycleInSeconds * 1000,
    end: seconds * 1000,
  });
  let currentHours = 0;
  let currentMinutes = 0;
  let currentSecons = 0;

  if (durations.hours) {
    currentHours += durations.hours;
  }
  if (durations.minutes) {
    currentMinutes = durations.minutes;
  }
  if (durations.seconds) {
    currentSecons = durations.seconds;
  }

  if (format === FastingTimerFormat.medium) {
    return `${currentHours}h ${currentMinutes}m`;
  }

  return `${currentHours}h ${currentMinutes}m ${currentSecons}s`;
};

export const fastingBodyStatusParagraphs = (bodyStatus: FastingBodyStatus) => {
  const paragraphList: { [key: string]: string[] } = {
    sugar_rises: ['text_1', 'text_2', 'text_3', 'text_4'],
    sugar_drops: ['text_1', 'text_2', 'text_3'],
    sugar_settles: ['text_1', 'text_2'],
    fat_burn: ['text_1', 'text_2'],
    ketosis: ['text_1', 'text_2', 'text_3', 'text_4'],
  };

  return paragraphList[bodyStatus];
};

export const getCurrentBodyStatus = (progress: number) => {
  if (progress >= 95) {
    return FastingBodyStatus.ketosis;
  }
  if (progress >= 70) {
    return FastingBodyStatus.fatBurn;
  }
  if (progress >= 45) {
    return FastingBodyStatus.sugarSettle;
  }
  if (progress >= 20) {
    return FastingBodyStatus.sugarDrop;
  }

  return FastingBodyStatus.sugarRise;
};

export const isEatingPhase = (timer: Timer) =>
  timer.type === FastingTimerType.eating;
