import React from 'react';

import { SVG } from 'types/svg';

function ScrollDownHealthgenom(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zM6.689 8.087a.938.938 0 0 0-1.326 1.326L9.34 13.39a.938.938 0 0 0 1.338-.011l3.966-3.966a.938.938 0 0 0-1.326-1.326l-3.315 3.314z"
        fill="#FFCA36"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default ScrollDownHealthgenom;
