import { useEffect, useState } from 'react';

function useCache() {
  const [isLoadedFromCache, setIsLoadedFromCache] = useState(false);

  useEffect(() => {
    const testCache = (event: any) => {
      setIsLoadedFromCache(false); // Needed for init, otherwise below setIsLoadedFromCache(true) will be true and cached

      if (event.persisted) {
        setIsLoadedFromCache(true);
      }
    };

    window.addEventListener('pageshow', testCache);

    return () => window.removeEventListener('pageshow', testCache);
  }, []);

  return { isLoadedFromCache };
}

export default useCache;
