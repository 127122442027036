import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (state = initialState.workout, action) => {
  switch (action.type) {
    case types.WORKOUT_LOAD.SUCCESS:
    case types.WORKOUT_COMPLETE.SUCCESS:
      return { ...action.workout };

    case types.LOG_OUT.SUCCESS:
    case types.WORKOUT_CLEAR:
      return initialState.workout;

    default:
      return state;
  }
};
