import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CopyIcon from './CopyIcon';

import s from './CopyText.module.scss';

type CopyFn = (text: string) => Promise<boolean>;

const CopyText = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => {
  const { t } = useTranslation();
  const [copiedText, setCopiedText] = useState<string | null>(null);

  useEffect(() => {
    if (!copiedText) return;

    const timer = setTimeout(() => setCopiedText(null), 3000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [copiedText]);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      // eslint-disable-next-line no-console
      console.warn('Clipboard not supported');
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  };

  return (
    <>
      <div className={`${s.container} ${className}`}>
        <div className={s.text}>{text}</div>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button className={s.button} type="button" onClick={() => copy(text)}>
          <CopyIcon />
        </button>
      </div>

      <div className={`${s.alert} ${copiedText ? s.alertActive : ''}`}>
        {t('link_copied')}
      </div>
    </>
  );
};

export default CopyText;
