import React from 'react';

function UnsubscribeV6QuoteHealthgenom() {
  return (
    <svg
      width={104}
      height={104}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="prefix__6424674b-f6b1-4d82-9cad-23697aac677c"
        >
          <stop stopColor="#FFE066" offset="0%" />
          <stop stopColor="#D88D28" offset="100%" />
        </linearGradient>
        <path
          id="prefix__174329d2-f945-4cdc-8165-387e198d89a4"
          d="M0 0h44.037v64H0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          fill="url(#prefix__6424674b-f6b1-4d82-9cad-23697aac677c)"
          cx={52}
          cy={52}
          r={52}
        />
        <g transform="translate(26 21)">
          <mask id="prefix__d37d3bdd-3a0b-4836-b318-de5921a776f6" fill="#fff">
            <use xlinkHref="#prefix__174329d2-f945-4cdc-8165-387e198d89a4" />
          </mask>
          <path
            d="M22.197 63.923c2.209-.117 4.227-1.283 5.482-2.93 1.477-1.939 2.247-4.202 2.943-6.496.455-1.502.785-3.034 1.128-4.563.083-.372.262-.632.665-.688.405-.056.56.213.7.517.434.94.87 1.88 1.265 2.837.242.586.198 1.01-.346 1.452-1.19.967-1.486 2.41-.874 3.81.224.51.464 1.016.752 1.494 1.037 1.72 2.414 3.041 4.424 3.524 2.875.69 4.864-.953 5.096-3.42.173-1.841.155-3.66-.129-5.472-.59-3.767-1.672-7.404-2.991-10.975-.222-.6-.445-1.195-.421-1.85.057-1.57-.107-3.125-.3-4.682-.66-5.334-1.935-10.493-4.265-15.359-.231-.482-.216-.885.03-1.35 1.988-3.754 4.022-7.484 5.99-11.248.92-1.758 2.332-3.354 2.634-5.34.063-.42.088-.86 0-1.275C43.742.784 42.828.06 41.59.003c-1.244-.057-1.956.708-2.585 1.676a345.063 345.063 0 0 1-4.558 6.81c-1.842 2.691-3.638 5.418-5.732 7.927-1.487 1.783-3.359 2.715-5.717 2.549-.797-.057-1.575-.22-2.348-.407-5.843-1.412-11.684-2.834-17.529-4.238-1.46-.35-2.817.553-3.082 2.003-.218 1.195.491 2.337 1.797 2.82 6.078 2.248 12.158 4.49 18.242 6.718.462.17.716.415.886.893 1.272 3.584 1.88 7.28 1.963 11.073.045 2.031-.222 4.097.633 6.048.07.158.031.368.026.553-.104 3.426-.364 6.843-1.127 10.188-.575 2.522-.487 2.539-3.289 2.18-2.173-.277-4.174-1.148-6.182-1.98-1.59-.658-3.254-.032-3.973 1.452-.718 1.48-.176 3.21 1.314 4.037a35.55 35.55 0 0 0 5.27 2.384c1.26.452 2.475.95 3.78 1.234.799.174 2.818 0 2.818 0"
            fill="#FFF"
            mask="url(#prefix__d37d3bdd-3a0b-4836-b318-de5921a776f6)"
          />
        </g>
        <path
          d="M47.97 38.293c4.725-.023 8.448-3.784 8.446-8.53-.003-4.56-3.793-8.31-8.402-8.315-4.658-.005-8.43 3.73-8.438 8.356-.007 4.688 3.773 8.511 8.395 8.489"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default UnsubscribeV6QuoteHealthgenom;
