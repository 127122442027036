import React from 'react';

import { SVG } from 'types/svg';

function Logout(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M15.91 11.9a.76.76 0 0 0-.76.76v5.43H12.3V5.14a.75.75 0 0 0-.25-.56.57.57 0 0 0-.18-.12L6 1.53h9.14V7a.77.77 0 0 0 1.53 0V.76a.76.76 0 0 0-.76-.76H2.68a.77.77 0 0 0-.26.07.76.76 0 0 0-.23.24S2 .53 2 .59v18.26a.77.77 0 0 0 .42.69l8.76 4.38a.84.84 0 0 0 .34.08.8.8 0 0 0 .4-.11.77.77 0 0 0 .37-.65v-3.62h3.61a.77.77 0 0 0 .77-.77v-6.19a.76.76 0 0 0-.76-.76zM3.53 18.38V2l7.24 3.61V22z" />
      <path d="M21.81 10.29a.76.76 0 0 0 .12-.19.72.72 0 0 0 .07-.29.76.76 0 0 0-.22-.54l-1.72-1.71a.76.76 0 0 0-1.08 0 .78.78 0 0 0 0 1.08l.41.4h-5.73a.77.77 0 0 0-.76.77.76.76 0 0 0 .76.76h5.73L19 11a.77.77 0 0 0 .54 1.31.76.76 0 0 0 .54-.23l1.71-1.7z" />
    </svg>
  );
}

export default Logout;
