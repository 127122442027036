/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTimer from 'easytimer-react-hook';
import { getLocaleDirection } from 'i18n';
import Timer from 'easytimer.js';

import { ExpandedExercise, InternalWorkoutState } from 'types/workouts';

import { FileExtension } from 'helpers/imageHelper';

import { ResponsiveImage } from 'components/common';

import { doneExercise, skipExercise } from 'actions/workoutsActions';

import s from './WorkoutActionToolbar.module.scss';

type Props = {
  currentExercise: ExpandedExercise;
  isFinished: boolean;
  globalTimer: Timer;
  pauseUnpauseExercise: () => void;
};

function WorkoutActionToolbar({
  currentExercise,
  globalTimer,
  pauseUnpauseExercise,
}: Props) {
  const workout = useSelector<any, any>((store) => store.rootReducer.workout);
  const workouts = useSelector<any, InternalWorkoutState>(
    (store) => store.rootReducer.workouts
  );
  const dispatch = useDispatch();
  const { repetitions } = currentExercise;
  const isRepetitions = !!(repetitions && repetitions > 0);
  const [timer] = useTimer(); // This is timer for backround counting seconds doing the reps

  useEffect(() => {
    timer.start();

    return () => timer.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentExercise]);

  const getImagename = () => {
    if (isRepetitions) return 'done';

    return 'pause';
  };

  const imageName = getImagename();

  const handleMainClick = () => {
    // Handle repetitions state for exercise
    if (isRepetitions) {
      dispatch(
        doneExercise(
          workouts,
          workout,
          globalTimer.getTotalTimeValues().seconds,
          timer.getTotalTimeValues().toString(['seconds'])
        )
      );
      return;
    }

    pauseUnpauseExercise();
  };

  const handleSkipClick = () => {
    dispatch(
      skipExercise(workouts, workout, globalTimer.getTotalTimeValues().seconds)
    );
  };

  return (
    <div className={s.container} dir={getLocaleDirection()}>
      <div className={s.buttonContainer}>{/* This is for void space */}</div>
      <div className={s.buttonContainer}>
        <button
          type="button"
          className={`${s.buttonNormalize} ${s.mainButton}`}
          onClick={handleMainClick}
        >
          <ResponsiveImage
            imagePath={`./workouts/${imageName}`}
            imageExtension={FileExtension.png}
            altText={imageName}
            style={s.buttonImage}
          />
        </button>
      </div>
      <div className={s.buttonContainer}>
        <button
          type="button"
          className={`${s.buttonNormalize} ${s.skipButton}`}
          onClick={handleSkipClick}
        >
          <ResponsiveImage
            imagePath="./workouts/skip"
            imageExtension={FileExtension.png}
            altText="skip-workout"
            style={s.buttonImage}
          />
        </button>
      </div>
    </div>
  );
}

export default WorkoutActionToolbar;
