import React from 'react';

import { SVG } from 'types/svg';

function Random(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.37 21.15a.87.87 0 0 0-.16-.26l-1.8-1.78a.77.77 0 0 0-1.09 1.09l.46.46a8.41 8.41 0 0 1-8-8.66 8.42 8.42 0 0 1 8-8.67l-.46.46a.78.78 0 0 0 0 1.1.79.79 0 0 0 1.09 0l1.8-1.8a.72.72 0 0 0 .16-.25.76.76 0 0 0 0-.59.82.82 0 0 0-.16-.26L21.42.22a.77.77 0 0 0-1.09 0 .78.78 0 0 0 0 1.1l.47.47A9.65 9.65 0 0 0 12 8a9.67 9.67 0 0 0-8.87-6.22h-1.8a.78.78 0 0 0 0 1.55h1.8c4.46 0 8.1 3.9 8.1 8.67s-3.64 8.67-8.1 8.67h-1.8a.77.77 0 0 0 0 1.54h1.8A9.66 9.66 0 0 0 12 16a9.65 9.65 0 0 0 8.8 6.21l-.47.47a.77.77 0 0 0 0 1.09.76.76 0 0 0 1.09 0L23.2 22a.82.82 0 0 0 .16-.25.76.76 0 0 0 0-.6z" />
    </svg>
  );
}

export default Random;
