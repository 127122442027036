import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { getCurrentTheme, themes } from 'helpers/themeHelper';
import { initHeap } from 'helpers/heapHelper';
import settings from 'helpers/settings';

const themeColors = {
  diet: {
    tileColor: '#1f66cf',
    themeColor: '#ffffff',
    pinned: '#1f66cf',
  },
  keto: {
    tileColor: '#264653',
    themeColor: '#264653',
    pinned: '#2a9d8f',
  },
  ketogo: {
    tileColor: '#264653',
    themeColor: '#264653',
    pinned: '#2a9d8f',
  },
  fasting: {
    tileColor: '#0c1b33',
    themeColor: '#ffffff',
    pinned: '#03cea4',
  },
  healthgenom: {
    tileColor: '#ffffff',
    themeColor: '#ffffff',
    pinned: '#d88d28',
  },
};

const Headings = ({ locale }) => {
  const { t } = useTranslation('domains');
  let url = `${process.env.PUBLIC_URL}/favicon`;
  const currentTheme = getCurrentTheme();

  const domain = t('domains:domain');

  const loadHeap = () => {
    if (window.heap) {
      return;
    }

    initHeap();
  };

  switch (currentTheme) {
    case themes.diet:
    case themes.keto:
    case themes.ketogo:
    case themes.fasting:
    case themes.healthgenom: {
      url += `/${currentTheme}`;
      const colors = themeColors[currentTheme];

      return (
        <Helmet>
          <html lang={locale} />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${url}/apple-touch-icon.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${url}/favicon-32x32.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${url}/favicon-16x16.png`}
          />
          <link rel="manifest" href={`${url}/site.webmanifest`} />
          <link
            rel="mask-icon"
            href={`${url}/safari-pinned-tab.svg`}
            color={colors.pinned}
          />
          <meta name="msapplication-TileColor" content={colors.tileColor} />
          <meta name="theme-color" content={colors.themeColor} />
          <title>{domain.app_name}</title>
          {settings.HEAP_APP_ID && loadHeap()}
        </Helmet>
      );
    }
    case themes.monacofit:
    default:
      url += `/${currentTheme}`;
      return (
        <Helmet>
          <html lang={locale} />
          <link rel="icon" href={`${url}/favicon.ico`} />
          <meta
            name="description"
            content="MonacoFit.com ir rezultatīva notievēšanas un muskuļu masas palielināšanas programma, kas sagatavo Tev pielāgotus vingrojumus un uztura plānu."
          />
          <meta
            name="keywords"
            content="MonacoFit, Paula Freimane, rezultatīvākā notievēšana, muskuļu masas palielināšana"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${url}/favicon-32x32.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${url}/favicon-16x16.png`}
          />
          <link rel="manifest" href={`${url}/site.webmanifest`} />
          <link
            rel="mask-icon"
            href={`${url}/safari-pinned-tab.svg`}
            color="#9b0416"
          />
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content="#221a17"
          />
          <link rel="manifest" href={`${url}/site.webmanifest`} />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${url}/apple-touch-icon.png`}
          />
          <meta name="msapplication-TileColor" content="#ea1010" />
          <meta name="theme-color" content="#ffffff" />
          <title>{domain.app_name}</title>
        </Helmet>
      );
  }
};

export default Headings;
