import React from 'react';

import s from './WorkoutProgressBar.module.scss';

type Props = {
  currentCount: number;
  totalCount: number;
};

type PillProps = {
  isActive: boolean;
};

const Pill = ({ isActive }: PillProps) => (
  <div className={`${s.pill} ${isActive && s.activePill}`}>
    {/* Div cant be self enclosed */}
  </div>
);

function WorkoutProgressBar({ currentCount, totalCount }: Props) {
  const progressPills = [];

  for (let i = 0; i < totalCount; i++) {
    progressPills.push(
      <Pill key={i} isActive={i <= currentCount} /> // Cause workouts->currentCount initially is 0
    );
  }

  return <div className={s.progressContainer}>{progressPills}</div>;
}

export default WorkoutProgressBar;
