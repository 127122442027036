import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { User } from 'types/user';
import { isGenomPlusAllowed } from 'helpers/genomPlusHelper';

type Props = {
  children: React.ReactNode;
};

function EnsureGenomPlusEnabled({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);

  if (!isGenomPlusAllowed(user)) return <Redirect to="/program" />;

  return <>{children}</>;
}

export default EnsureGenomPlusEnabled;
