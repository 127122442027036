import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import SvgIcon from 'components/common/SvgIcon';
import { User, UserStatistics } from 'types/user';
import { getStyle, isMaterialUI } from 'helpers/themeHelper';
import { hasAppleSubscription } from 'helpers/userHelper';
import settings from 'helpers/settings';
import ContactMailIcon from '@material-ui/icons/ContactMail';

import base from './OfferBanner_base.module.scss';
import diet from './OfferBanner.module.scss';
import keto from './OfferBanner_keto.module.scss';
import ketogo from './OfferBanner_ketogo.module.scss';
import fasting from './OfferBanner_fasting.module.scss';
import healthgenom from './OfferBanner_healthgenom.module.scss';

const s = getStyle(base, diet, keto, ketogo, fasting, healthgenom);

type Props = {
  user: User;
};

type UserWeightValues = {
  weightToLose: string | number;
  weightUnit: string;
};

const getUserWeightValues = (stats: UserStatistics, t: TFunction) => {
  const weightValues: UserWeightValues = {
    weightToLose: '',
    weightUnit: t('units.weight_si'),
  };

  if (stats.unit_system === 'imperial') {
    weightValues.weightUnit = t('units.weight_imperial');
  }

  if (stats.lose > 0) {
    if (stats.unit_system === 'imperial') {
      weightValues.weightToLose = stats.lose_in_lbs;
    } else {
      weightValues.weightToLose = stats.lose;
    }
  }

  return weightValues;
};

export const getOfferBannerUrl = (user: User) => {
  const { offer_banner_type, goal } = user;
  if (!offer_banner_type) {
    return null;
  }

  switch (settings.THEME) {
    case 'diet':
      return `/offer/sub_expired?utm_source=appv7.stockholmdiet.com&utm_medium=upper_ribbon&utm_campaign=${offer_banner_type}`;
    case 'keto':
      return `/offer/sub_expired?utm_source=app.certifiedketodiet.com&utm_medium=upper_ribbon&utm_campaign=${offer_banner_type}`;
    case 'ketogo':
      return `/offer/sub_expired?utm_source=my.ketogo.app&utm_medium=upper_ribbon&utm_campaign=${offer_banner_type}`;
    case 'fasting':
      return `/offer/sub_expired?utm_source=app.certifiedfasting.com&utm_medium=upper_ribbon&utm_campaign=${offer_banner_type}`;
    case 'healthgenom':
      return `/offer/sub_expired?utm_source=app.healthgenom.com&utm_medium=upper_ribbon&utm_campaign=${offer_banner_type}`;
    case 'monacofit':
      return `/offer/${goal}_universal_pro?utm_source=app.monacofit.com&utm_medium=upper_ribbon&utm_campaign=${offer_banner_type}`;
    default:
      return null;
  }
};

const OfferBanner: React.FC<Props> = ({ user }) => {
  const { goal, stats } = user;
  const { t } = useTranslation(['offer']);
  const bannerUrl: string | null = getOfferBannerUrl(user);

  if (!bannerUrl) {
    return null;
  }
  if (hasAppleSubscription(user)) {
    return null;
  }

  if (isMaterialUI()) {
    const { weightToLose, weightUnit } = getUserWeightValues(
      stats as UserStatistics,
      t
    );

    return (
      <div className={s.wrapper} id="offerbanner">
        <Link to={bannerUrl} className={s.link}>
          <div className={s.container}>
            <ContactMailIcon className={s.icon} />
            {t('offer:offer_banner.title', {
              weight_to_lose: weightToLose,
              unit: weightUnit,
            })}
          </div>
        </Link>
      </div>
    );
  }

  return (
    <div className="offer_banner" id="offerbanner">
      <Link className="offer_page__link" to={bannerUrl}>
        <div className="offer_banner__container">
          <SvgIcon filename="BannerMail" />
          <div className="offer_banner__container__text">
            {t(`offer:offer_banner.${goal}`)}
            {' - '}
            <span className="offer_banner__container__text__link">
              {' '}
              {t('offer:offer_banner.click_here')}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default OfferBanner;
