import React from 'react';

import { SVG } from 'types/svg';

function EmailHealthgenom(props: SVG) {
  return (
    <svg
      width={20}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          d="M18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 4-8 5-8-5V2l8 5 8-5v2z"
          id="prefix__790510e1-8aaa-4344-b746-3e9dddf6c4e4"
        />
      </defs>
      <use
        fill="#2884AA"
        xlinkHref="#prefix__790510e1-8aaa-4344-b746-3e9dddf6c4e4"
      />
    </svg>
  );
}

export default EmailHealthgenom;
