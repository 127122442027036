/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import useTimer from 'easytimer-react-hook';

import { ExpandedExercise } from 'types/workouts';

import s from './WorkoutsMedia.module.scss';

type Props = {
  expandedExercise: ExpandedExercise;
  isCountdown: boolean;
  currentCount: number;
  triggerDoneCountdown: () => void;
};

type VideoMediaProps = {
  videoUrl: string;
};

const VideoMedia = ({ videoUrl }: VideoMediaProps) => {
  useEffect(() => {
    // Neccessary do to the fact that the video tag does not update the source
    function changeSource(url: string) {
      const videoTag = document.getElementById('video') as HTMLVideoElement;
      videoTag.src = url;
      videoTag.play();
    }
    changeSource(videoUrl);
  }, [videoUrl]);

  return (
    <video id="video" className={s.video} autoPlay loop muted playsInline>
      <source src={videoUrl} type="video/mp4" />
    </video>
  );
};

type ImageMediaProps = {
  imageUrl: string;
};

const ImageMedia = ({ imageUrl }: ImageMediaProps) => (
  <img className={s.image} src={imageUrl} alt="exercise" />
);

type TimerOverlayProps = {
  isCountdown: boolean;
  currentCount: number;
  triggerDoneCountdown: () => void;
};

const TimerOverlay = ({
  isCountdown,
  currentCount,
  triggerDoneCountdown,
}: TimerOverlayProps) => {
  const [timer, isTargetAchieved] = useTimer({
    updateWhenTargetAchieved: true,
  });

  useEffect(() => {
    timer.start({
      countdown: true,
      startValues: { seconds: 3 },
      target: { seconds: 0 },
    });

    return () => timer.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTargetAchieved) {
      triggerDoneCountdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTargetAchieved]);

  useEffect(() => {
    if (isCountdown) {
      timer.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCount]);

  return (
    <div className={s.timerOverlay}>
      <div className={s.timerOverlayText}>{timer.getTimeValues().seconds}</div>
    </div>
  );
};

function WorkoutsMedia({
  expandedExercise,
  isCountdown,
  currentCount,
  triggerDoneCountdown,
}: Props) {
  const renderMedia = () => {
    if (!expandedExercise) return null;

    const { video_url, image_url } = expandedExercise;

    if (video_url) {
      return <VideoMedia videoUrl={video_url} />;
    }

    return <ImageMedia imageUrl={image_url} />;
  };

  return (
    <div className={s.mediaContainer}>
      {renderMedia()}
      {isCountdown && (
        <TimerOverlay
          isCountdown={isCountdown}
          currentCount={currentCount}
          triggerDoneCountdown={triggerDoneCountdown}
        />
      )}
    </div>
  );
}

export default WorkoutsMedia;
