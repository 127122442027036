import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { User } from 'types/user';
import { getUserActiveProSubscription } from 'helpers/userHelper';
import { FileExtension } from 'helpers/imageHelper';
import {
  formatISODate,
  getDifferenceInDaysFromToday,
} from 'helpers/dateHelper';
import { getTheme, getThemeImageFolder } from 'helpers/themeHook';

import { SubmitButton, ResponsiveImage } from 'components/common';

import s from './PausedProgramSubscription.module.scss';

function PausedProgramSubscription() {
  const currentTheme = getTheme();
  const history = useHistory();
  const { t } = useTranslation('pausesubscription');
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const activeSubscription = getUserActiveProSubscription(user);
  const untilDate = formatISODate(
    activeSubscription.paused_till as string,
    'dd-LLL-yyyy'
  ).toUpperCase();
  const dayCount = Math.abs(
    getDifferenceInDaysFromToday(activeSubscription.paused_till as string)
  );

  const handleClick = () => {
    history.replace('/user/account/edit?pause-subscription-trigger');
  };

  return (
    <div className={s.container}>
      <ResponsiveImage
        imagePath={`./pausesubscription/program/${getThemeImageFolder(currentTheme)}paused-sub-bg`}
        imageExtension={FileExtension.png}
        altText="pause-programpage-bg"
        style={s.pausedBackground}
      />
      <div className={s.wrapper}>
        <ResponsiveImage
          imagePath={`./pausesubscription/modal/${getThemeImageFolder(currentTheme)}pause-button`}
          imageExtension={FileExtension.png}
          altText="pause"
          style={s.pauseImage}
        />
        <h3 className={s.title}>
          {t('paused_subscription_program.title', { pause_until: untilDate })}
        </h3>
        <p className={s.subTitle}>
          {t('paused_subscription_program.sub_title', {
            day_count_left: dayCount,
          })}
        </p>
        <p className={s.question}>{t('paused_subscription_program.text')}</p>
        <div className={s.buttonWrapper}>
          <SubmitButton
            type="large"
            label={t('paused_subscription_program.button')}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}

export default PausedProgramSubscription;
