import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ExpandedExercise,
  InprogressWorkoutState,
  InternalWorkoutState,
} from 'types/workouts';

import { Button } from '@material-ui/core';

import s from './WorkoutsAbandonedModal.module.scss';

type Props = {
  isOpen: boolean;
  handleClose: (buttonTag: 'resume' | 'restart' | 'quit') => void;
};

function WorkoutsAbandonedModal({ isOpen, handleClose }: Props) {
  const { t } = useTranslation('workouts');
  const workouts = useSelector<any, InternalWorkoutState>(
    (store) => store.rootReducer.workouts
  );
  const inprogressWorkout =
    workouts.inprogressWorkoutState as InprogressWorkoutState;
  const { workoutPhase } = inprogressWorkout;
  const { currentCount, currentExercise, totalCount } =
    inprogressWorkout[workoutPhase];
  const exercise = currentExercise as ExpandedExercise;
  const isRepExercise = !!(exercise.repetitions && exercise.repetitions > 0);
  const isMainExercise = workoutPhase === 'exercise';

  if (!isOpen) return null;

  const donePercentage = Math.round((currentCount * 100) / totalCount);
  const exercisesToGo = totalCount - currentCount;

  const handleRestart = () => {
    handleClose('restart');
  };

  const handleResume = () => {
    handleClose('resume');
  };

  const handleQuit = () => {
    handleClose('quit');
  };

  return (
    <div className={s.container}>
      <div className={s.content}>
        <p className={s.title}>{t('workouts.abandon_modal.title')}</p>
        {isMainExercise && (
          <>
            <p className={s.donePercentage}>
              {t('workouts.abandon_modal.percentage', { donePercentage })}
            </p>
            <p className={s.exercisesToGo}>
              {t('workouts.abandon_modal.exercises', { exercisesToGo })}
            </p>
          </>
        )}
        <div className={s.buttonContainer}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={s.resumeButton}
            onClick={handleResume}
          >
            {t('workouts.abandon_modal.buttons.resume')}
          </Button>
          {!isRepExercise && (
            <Button
              type="button"
              color="secondary"
              variant="outlined"
              className={s.restartButton}
              onClick={handleRestart}
            >
              {t('workouts.abandon_modal.buttons.restart')}
            </Button>
          )}
          <button
            type="button"
            className={`${s.buttonNormalize} ${s.quitButton}`}
            onClick={handleQuit}
          >
            {t('workouts.abandon_modal.buttons.quit')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default WorkoutsAbandonedModal;
