const getDynamicImageFromContext = require.context('../assets/images', true);

export enum FileExtension {
  jpg = 'jpg',
  png = 'png',
  svg = 'svg',
  webp = 'webp',
  gif = 'gif',
}

export type ImageBundle = {
  base: string;
  medium: string;
  large: string;
};

export const getDynamicImage = (
  path: string,
  fileExtension = FileExtension.jpg
) => getDynamicImageFromContext(`${path}.${fileExtension}`).default;

export const getDynamicImageBundle = (
  path: string,
  fileExtension = FileExtension.jpg
): ImageBundle => ({
  base: getDynamicImageFromContext(`${path}.${fileExtension}`).default,
  medium: getDynamicImageFromContext(`${path}@2x.${fileExtension}`).default,
  large: getDynamicImageFromContext(`${path}@3x.${fileExtension}`).default,
});
