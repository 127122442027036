/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';

import { isMaterialUI } from 'helpers/themeHelper';
import useTheme from 'helpers/themeHook';
import { SVGFillColors } from 'types/svg';

import SvgIcon from 'components/common/SvgIcon';
import Button from '@material-ui/core/Button';

import base from './styles/LinkButton.module.scss';
import diet from './styles/LinkButton_diet.module.scss';
import keto from './styles/LinkButton_keto.module.scss';
import ketogo from './styles/LinkButton_ketogo.module.scss';
import fasting from './styles/LinkButton_fasting.module.scss';
import healthgenom from './styles/LinkButton_healthgenom.module.scss';

const LinkButton = (props) => {
  const [s] = useTheme({ base, diet, keto, ketogo, fasting, healthgenom });

  const getLinkClass = () => {
    const { type, position, className, disabled } = props;

    let linkClass = `btn__inline-link ${s.link}`;
    if (type === 'green')
      linkClass = `form__submit_block__button large link ${s.blocky}`;
    if (position) linkClass += ` ${s[position]}`;
    if (className) linkClass += ` ${className}`;
    if (disabled) linkClass += s.disabledLink;

    return linkClass;
  };

  const getLinkText = () => {
    const { link_text, svgIcon } = props;
    if (!svgIcon) {
      return link_text;
    }

    let icon;
    if (typeof svgIcon !== 'string') {
      icon = svgIcon;
    } else {
      icon = (
        <SvgIcon
          filename={svgIcon}
          fill={SVGFillColors.blue}
          className="btn__inline-link__img"
        />
      );
    }

    return (
      <div>
        {icon}
        {link_text}
      </div>
    );
  };

  const {
    link,
    type,
    link_text,
    svgIcon,
    noBackground,
    classes = ['noDecoration'],
    onClick,
    disabled,
    compactBtn,
    ...restProps
  } = props;

  if (isMaterialUI()) {
    let variant;
    switch (type) {
      case 'green':
        variant = 'contained';
        break;
      case 'text':
        variant = 'text';
        break;
      default:
        variant = 'outlined';
    }

    if (noBackground) {
      variant = 'text';
    }

    const classNames = classes.map((name) => s[name]).join(' ');

    return (
      <Link
        disabled={disabled}
        to={{ pathname: link }}
        onClick={onClick}
        className={`${classNames} ${disabled && s.disabledLink} ${
          compactBtn && s.compactBtn
        }`}
        tabIndex={-1}
      >
        <Button
          disabled={disabled}
          variant={variant}
          color="secondary"
          className={getLinkClass()}
          startIcon={svgIcon}
          fullWidth
        >
          {link_text}
        </Button>
      </Link>
    );
  }

  if (link.startsWith('http') || link.startsWith('tel:')) {
    return (
      <a href={link} className={getLinkClass()}>
        {getLinkText()}
      </a>
    );
  }

  return (
    <Link
      to={{ pathname: link, state: restProps }}
      onClick={onClick}
      className={getLinkClass()}
    >
      {getLinkText()}
    </Link>
  );
};

export default LinkButton;
