import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';
import concat from 'lodash/concat';

export default (state = initialState.calendar, action) => {
  let calendarState;
  let mergedDays;
  switch (action.type) {
    case types.CALENDAR_LOAD.SUCCESS:
      return action.calendar;

    case types.CALENDAR_MORE_PAST_DATA:
      calendarState = { ...state };
      mergedDays = concat(action.calendar.days, calendarState.days);
      calendarState.days = mergedDays;
      return calendarState;

    case types.CALENDAR_MORE_FUTURE_DATA:
      calendarState = { ...state };
      mergedDays = concat(calendarState.days, action.calendar.days);
      calendarState.days = mergedDays;
      return calendarState;

    case types.LOG_OUT.SUCCESS:
      return initialState.calendar;

    default:
      return state;
  }
};
