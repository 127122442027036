// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';

type HistoryAny = any;

const history: HistoryAny = createBrowserHistory();
history.pushLater = (...args: HistoryAny) =>
  setImmediate(() => history.push(...args));
history.replaceLater = (...args: HistoryAny) =>
  setImmediate(() => history.replace(...args));
export default history;

export const closeOpenedWindow = () => {
  if (history.length === 1) {
    return window.close();
  }

  return history.goBack();
};
