import { getCurrentLocale } from 'i18n';
import { User, UserStatistics } from 'types/user';

import settings from './settings';
import { isImperial } from './unsubscribeV8Helper';

export const isExpressPlanEnabled = () => settings.EXPRESS_PLAN_ENABLED;

export const isPurchasableExpressPlan = (user: User) =>
  user.express_plan_delivery_timer?.skippable;

export const isExpressPlanAvailable = (user: User) => {
  if (!isExpressPlanEnabled()) return false;
  if (!user.express_plan_delivery_timer?.enabled) return false;

  return true;
};

// Start: this has been taken from V3 pipe
export const getNormalizeHeight = (user: User) => {
  const { height, height_feet, height_inches } = user;

  if (isImperial(user)) {
    return height_feet * 30.48 + height_inches * 2.54;
  }

  return height;
};

export const getIdealWeight = (user: User) =>
  Math.round((getNormalizeHeight(user) ** 2 * 21.1) / 10000);

const KGS_IN_LBS = 0.453592;

const kgToLbs = (kg: number) => kg / KGS_IN_LBS;

export const formattedIdealWeeight = (user: User) => {
  const idealWeight = getIdealWeight(user);

  if (isImperial(user)) return `${Math.round(kgToLbs(idealWeight))} lbs`;

  return `${idealWeight}kg`;
};

export const getMonthV3 = (idx: number) => {
  const objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(idx - 1);

  const locale = getCurrentLocale();
  const month = objDate.toLocaleString(locale, { month: 'short' });

  return month;
};

export const getReachDate = (user: User) => {
  const { current, goal, current_in_lbs, goal_in_lbs } =
    user.stats as UserStatistics;

  const weight = !isImperial(user)
    ? current - goal
    : current_in_lbs - goal_in_lbs;

  let months = 0;

  if (isImperial(user)) {
    months = Math.round(weight / kgToLbs(4));
  } else {
    months = Math.round(weight / 4);
  }

  const date = new Date();
  date.setMonth(date.getMonth() + months + 1);

  return date;
};
// End: this has been taken from V3 pipe
