import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ErrorMessage, SuccessMessage } from 'components/common';

export default class FlashMessageList extends PureComponent {
  static propTypes = {
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      })
    ),
  };

  getFlashMessages = () => {
    const { messages } = this.props;

    return messages.map((message) => {
      if (typeof message.text !== 'string') {
        return null;
      }

      switch (message.type) {
        case 'ERROR':
          return <ErrorMessage key={message.id} message={message} />;
        case 'SURVEY_ERROR':
        case 'MEAL_TYPE_ERROR':
        case 'INVALID_PASSWORD_TOKEN':
        case 'PASSWORD_RESET_FAIL':
        case 'TOS_DECLINE':
          return null;
        default:
          return <SuccessMessage key={message.id} message={message} />;
      }
    });
  };

  render = () => {
    const { messages } = this.props;

    return messages ? (
      <div className="flash_message">{this.getFlashMessages()}</div>
    ) : null;
  };
}
