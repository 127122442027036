import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, Slider, createTheme } from '@material-ui/core';
import {
  materialUiTheme,
  getMaterialUIColor,
  isMaterialUI,
} from 'helpers/themeHelper';
import { FileExtension, getDynamicImage } from 'helpers/imageHelper';

import s from './styles/SliderInput.module.scss';

const sliderTheme = createTheme({
  ...materialUiTheme(),
  direction: 'rtl',
});

export default class SliderInput extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    img: PropTypes.string.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    current: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
  };

  componentDidMount = () => this.changePercentPosition();

  onRangeChange = (event) => {
    const { onChange } = this.props;

    onChange(event);
    this.changePercentPosition();
  };

  changePercentPosition = () => {
    if (isMaterialUI()) {
      return;
    }

    let newPosition;
    const width = this.sliderInputRange.offsetWidth - 30;
    const newPoint =
      (this.sliderInputRange.value - this.sliderInputRange.min) /
      (this.sliderInputRange.max - this.sliderInputRange.min);

    if (newPoint < 0) newPosition = 0;
    else if (newPoint > 1) newPosition = width;
    else newPosition = width * (1 - newPoint);

    this.sliderPercent.style.left = `${newPosition}px`;
  };

  render = () => {
    const { label, img, min, max, current, type, name, onChange } = this.props;

    if (isMaterialUI()) {
      return (
        <div>
          <div className={s.label}>{label}</div>
          <img
            className={s.image}
            src={getDynamicImage(`./${img}`, FileExtension.png)}
            alt=""
          />
          <div className={s.sliderContainer}>
            <ThemeProvider theme={sliderTheme}>
              <Slider
                className={s.slider}
                size="big"
                color={getMaterialUIColor()}
                min={min}
                max={max}
                track={false}
                value={current}
                onChange={(_, value) => onChange({ target: { name, value } })}
                valueLabelDisplay="on"
                valueLabelFormat={(percent) => `${percent}%`}
              />
            </ThemeProvider>
          </div>
        </div>
      );
    }

    return (
      <div className="slider_input">
        <label htmlFor={name} className="slider_input--label">
          {label}
        </label>
        <img
          className="slider_input__img"
          src={getDynamicImage(`./${img}`, FileExtension.png)}
          alt=""
        />
        <div id="slider_input__slider">
          <span
            className={`slider_input__slider--percent ${type}`}
            ref={(el) => {
              this.sliderPercent = el;
            }}
          >
            {current}%
          </span>
          <input
            name={name}
            type="range"
            min={min}
            max={max}
            defaultValue={current}
            className="slider_input__slider--input"
            ref={(el) => {
              this.sliderInputRange = el;
            }}
            onChange={this.onRangeChange}
          />
        </div>
      </div>
    );
  };
}
