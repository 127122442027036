import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';
import { ModalActions } from 'actions/modalActions';

export default (
  state = initialState.modals,
  action: ReturnType<ModalActions>
) => {
  switch (action.type) {
    case types.MODAL_ADD:
      return [...state, action.payload];
    case types.MODAL_REMOVE:
      return state.filter((modal) => modal.modal !== action.payload.modal);
    default:
      return state;
  }
};
