import React from 'react';

function UnsubscribeV6InvestmentMonacofit() {
  return (
    <svg width={56} height={56} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle fill="#60BD78" cx={28} cy={28} r={28} />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M45.646 20.234l-3.13-5.737V10h-1.161v4.793l3.271 5.997c.14.255.213.544.213.835v4.49a1.306 1.306 0 01-2.593.213l-.947-5.683-3.552-1.776-1.079-2.698-1.078.432 1.244 3.108 3.416 1.708.85 5.1c.14.836.687 1.504 1.416 1.834v4.292a1.162 1.162 0 01-2.322 0v-7.548h-1.162v8.709a1.162 1.162 0 01-2.322 0v-4.471a5.462 5.462 0 001.161-3.376 5.514 5.514 0 00-8.13-4.845v-2.97a5.21 5.21 0 01.879-2.898l1.445-2.167V10h-1.162v2.727l-1.25 1.875a6.366 6.366 0 00-1.072 3.543v3.819c-.037.034-.076.065-.112.1l-.469.47-.47-.47a5.472 5.472 0 00-3.894-1.612 5.48 5.48 0 00-3.468 1.234 2.316 2.316 0 00-2.039-1.234 2.318 2.318 0 00-2.184 1.56 2.307 2.307 0 00-1.3-.4 2.325 2.325 0 00-2.322 2.323v4.08A2.463 2.463 0 0010 30.467v4.49c0 .483.123.964.354 1.39l3.13 5.737V46h1.161v-4.213l-3.271-5.996a1.746 1.746 0 01-.213-.835v-4.49a1.306 1.306 0 012.593-.214l.947 5.684 3.552 1.775 1.079 2.699 1.078-.432-1.244-3.108-3.416-1.708-.85-5.1a2.45 2.45 0 00-1.416-1.835v-4.292a1.162 1.162 0 012.322 0v7.549h1.162v-8.709c0-.641.52-1.162 1.161-1.162a1.16 1.16 0 011.145.998 5.473 5.473 0 00.016 6.724v2.149h1.162v-.921l4.164 4.164a4.77 4.77 0 001.642 1.073v2.636a5.208 5.208 0 01-.878 2.899L23.935 43.5V46h1.162v-2.147l1.25-1.875a6.366 6.366 0 001.072-3.543V36.09a4.753 4.753 0 003.965-1.363l.974-.974a2.315 2.315 0 002.03 1.215c.48 0 .928-.148 1.3-.4a2.318 2.318 0 002.183 1.561 2.318 2.318 0 002.184-1.56c.37.251.818.399 1.3.399a2.325 2.325 0 002.322-2.323v-4.079A2.463 2.463 0 0046 26.114v-4.49c0-.484-.123-.965-.354-1.39zM30.563 33.906A3.601 3.601 0 0128 34.968a3.601 3.601 0 01-2.563-1.062l-4.874-4.874a4.32 4.32 0 01-1.273-3.073 4.351 4.351 0 014.346-4.346 4.32 4.32 0 013.074 1.273l1.29 1.29 1.29-1.29a4.32 4.32 0 013.074-1.273 4.351 4.351 0 014.346 4.346 4.32 4.32 0 01-1.273 3.073l-4.874 4.874zm3.824-.1a1.162 1.162 0 01-1.14-.943l2.301-2.3v2.082c0 .64-.52 1.161-1.16 1.161z" />
          <path d="M22.206 27.39a2.026 2.026 0 011.43-3.455v-1.16a3.189 3.189 0 00-3.184 3.184c0 .85.33 1.65.932 2.252l3.26 3.26.82-.822-3.258-3.258zm3.228 4.871l.821-.821.824.824-.82.821zm-.337-16.455h1.161v1.161h-1.161zm0 2.323h1.161v1.161h-1.161zm1.161-1.161h1.161v1.161h-1.161zm-2.323 0h1.161v1.161h-1.161zm5.807 20.322h1.161v1.161h-1.161zm0 2.323h1.161v1.161h-1.161zm1.161-1.161h1.161v1.161h-1.161zm-2.322 0h1.161v1.161h-1.161zm2.903-14.517h1.161v1.161h-1.161zm0 2.323h1.161v1.161h-1.161zm1.161-1.161h1.161v1.161h-1.161zm-2.322 0h1.161v1.161h-1.161z" />
        </g>
      </g>
    </svg>
  );
}

export default UnsubscribeV6InvestmentMonacofit;
