import React from 'react';

import { SVG } from 'types/svg';

function Goal(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.9 2.18a.74.74 0 0 0-.69-.46h-1v-1a.75.75 0 0 0-.47-.69.72.72 0 0 0-.74.19l-1.92 1.87a.76.76 0 0 0-.21.53v1.5l-.95.94A10.86 10.86 0 1 0 19 6.12l1-1 1.43-.07a.7.7 0 0 0 .49-.22L23.74 3a.75.75 0 0 0 .16-.82zm-3.75 11a9.33 9.33 0 1 1-3.28-7.08l-2.22 2.23A6.11 6.11 0 0 0 10.84 7 6.19 6.19 0 1 0 17 13.2a6.11 6.11 0 0 0-1.32-3.81l2.21-2.21a9.21 9.21 0 0 1 2.26 6.02zm-4.61 0a4.7 4.7 0 1 1-4.7-4.7 4.64 4.64 0 0 1 2.74.9l-2.21 2.21a1.84 1.84 0 0 0-.53-.09 1.68 1.68 0 1 0 1.68 1.68 1.55 1.55 0 0 0-.09-.53l2.21-2.21a4.64 4.64 0 0 1 .9 2.76zM21 3.61h-.65v-.68l.38-.38a.75.75 0 0 0 .67.66z" />
    </svg>
  );
}

export default Goal;
