import { useSelector } from 'react-redux';
import { User } from 'types/user';
import { isChatAccessEnabled as checkChatAccessEnabled } from 'helpers/userHelper';
import settings from 'helpers/settings';
import { isKeto, isKetoGo } from 'helpers/themeHelper';
import { InitialState } from 'reducers/initialState';

function useChat(): {
  isChatAccessEnabled: boolean;
  isChatPaywall: boolean;
  isChatImitation: boolean;
  isChatImitationSeen: boolean;
} {
  const user = useSelector<{ rootReducer: InitialState }, User | null>(
    (store) => store.rootReducer.user
  );
  const chatImitationSeen = useSelector<
    { rootReducer: InitialState },
    boolean | null
  >((store) => store.rootReducer.chat.chatImitationSeen);

  const isChatAccessEnabled: boolean = checkChatAccessEnabled(user);
  const isChatPaywall: boolean = settings.CHAT_PAYWALL_ENABLED;

  // According to requirements, chat imitation is rendered only ONCE
  // for CKD and KG users who haven't purchased chat during upsell offer
  const isThemeMatch = isKetoGo() || isKeto();

  const getIsChatImitation = () => {
    if (isChatAccessEnabled) return false;
    if (!isChatPaywall) return false;
    if (!isThemeMatch) return false;

    return true;
  };

  const isChatImitation = getIsChatImitation();
  const isChatImitationSeen = !!chatImitationSeen;

  return {
    isChatAccessEnabled,
    isChatPaywall,
    isChatImitation,
    isChatImitationSeen,
  };
}

export default useChat;
