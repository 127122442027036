import React from 'react';

import { SVG } from 'types/svg';

function PercentHealthgenom(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M9.429 11.558c.302-.376.525-.81.654-1.274a5.736 5.736 0 0 0 .21-1.592 7.773 7.773 0 0 0-.166-1.632 3.766 3.766 0 0 0-.59-1.38 3.023 3.023 0 0 0-1.09-.94 3.649 3.649 0 0 0-1.672-.346c-.56-.014-1.117.1-1.627.334a3.187 3.187 0 0 0-1.143.917c-.305.4-.53.855-.66 1.34a6.06 6.06 0 0 0-.21 1.62 6.98 6.98 0 0 0 .177 1.604c.107.474.312.92.603 1.31.291.376.67.674 1.105.867a3.963 3.963 0 0 0 1.655.312 4.004 4.004 0 0 0 1.62-.302c.44-.185.828-.472 1.134-.838zm-2.096-.918a.915.915 0 0 1-.643.205.93.93 0 0 1-.643-.203 1.266 1.266 0 0 1-.346-.54 2.64 2.64 0 0 1-.13-.721c-.017-.27-.025-.512-.025-.724 0-.23.01-.492.03-.79.016-.27.071-.538.164-.794a1.56 1.56 0 0 1 .386-.595.9.9 0 0 1 .657-.221.744.744 0 0 1 .586.232c.161.186.279.406.344.643.08.265.13.538.148.814.02.288.031.535.031.737 0 .202-.01.445-.03.715-.019.243-.07.483-.155.712a1.317 1.317 0 0 1-.372.53h-.002zm13.365 3.26a3.54 3.54 0 0 0-.592-1.34 3.021 3.021 0 0 0-1.091-.91 3.748 3.748 0 0 0-1.672-.333 3.815 3.815 0 0 0-1.623.322 3.16 3.16 0 0 0-1.142.884 3.63 3.63 0 0 0-.665 1.307 5.841 5.841 0 0 0-.212 1.6 7.043 7.043 0 0 0 .179 1.614c.108.482.312.937.6 1.338a2.9 2.9 0 0 0 1.106.9 3.857 3.857 0 0 0 1.656.323 3.94 3.94 0 0 0 1.622-.31A3.005 3.005 0 0 0 20 18.417c.3-.388.522-.83.653-1.304.144-.521.215-1.06.21-1.602a7.542 7.542 0 0 0-.165-1.61zm-2.8 3.647a1.038 1.038 0 0 1-1.285 0 1.403 1.403 0 0 1-.35-.582 2.903 2.903 0 0 1-.136-.765 11.096 11.096 0 0 1 .01-1.522 2.63 2.63 0 0 1 .164-.752c.08-.212.21-.401.378-.553a.967.967 0 0 1 .66-.204.756.756 0 0 1 .588.225c.157.17.275.374.345.595a3.3 3.3 0 0 1 .146.761c.02.271.03.514.03.715 0 .2-.01.465-.03.737-.018.258-.07.512-.155.756a1.466 1.466 0 0 1-.364.589zM15.347 4.23l-8.66 15.54h2.151l8.616-15.54z"
          fill="#2884AA"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default PercentHealthgenom;
