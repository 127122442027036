import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { isUserFree } from 'helpers/userHelper';
import { assetUrl } from 'helpers/requestHelpers';
import { getStyle, isMaterialUI } from 'helpers/themeHelper';
import { FileExtension, getDynamicImage } from 'helpers/imageHelper';
import { SVGFillColors } from 'types/svg';

import SvgIcon from 'components/common/SvgIcon';
import ProgramItemHeader from './ProgramItemHeader';
import ProgramItemSubHeader from './ProgramItemSubHeader';
import Meal from './Meal/Meal';
import Fasting from './Fasting/Fasting';

import base from './ProgramItem.module.scss';

const s = getStyle(base);

export default class ProgramItem extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    data: PropTypes.object,
    onClick: PropTypes.func,
    link: PropTypes.string,
    dayId: PropTypes.number,
  };

  componentDidUpdate = (prevProps) => {
    const { data, type } = this.props;

    if (!data) {
      return;
    }

    if (
      !prevProps.data ||
      prevProps.data.state !== data.state ||
      prevProps.data.energy_percent !== data.energy_percent
    ) {
      document.getElementById(`prog-item-${type}`).classList.add('updated');
    }
  };

  itemPreview = () => {
    const { data, type } = this.props;
    if (type !== 'diary' && data.state === 'draft') {
      return (
        <div className="program_page__container__item__meal_img">
          <img src={assetUrl(data.image_url)} alt="" />
        </div>
      );
    }
    return null;
  };

  hasNullWeight = (data) => data && data.weight === null;

  itemIcon = () => {
    const { data, type } = this.props;
    const className = 'program_page__container__item__img_block__img';
    let filename = '';
    let fillColor = SVGFillColors.green;

    switch (type) {
      case 'diary':
        filename = 'Diary';
        break;

      case 'exercise':
        filename = 'Exercise';
        break;

      default:
        filename = 'ProgramMeal';
    }

    if (!data || this.hasNullWeight(data)) {
      return (
        <SvgIcon
          filename={filename}
          className={className}
          fill={SVGFillColors.orange}
        />
      );
    }

    if (data.state && (data.state === 'draft' || data.state === 'skipped')) {
      fillColor = SVGFillColors.orange;
    }

    return (
      <SvgIcon filename={filename} className={className} fill={fillColor} />
    );
  };

  diaryItem = () => {
    const { type, onClick, data, status } = this.props;
    return (
      <div
        className={`program_page__container__item ${s.container}`}
        id={`prog-item-${type}`}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex={0}
      >
        <div className="program_page__container__item__img_block">
          {this.itemIcon()}
        </div>
        <div className="program_page__container__item__info">
          <ProgramItemHeader type={type} status={status} data={data} />
          {status === 'active' && (
            <ProgramItemSubHeader type={type} data={data} />
          )}
        </div>
        {data && data.state !== 'draft' && !this.hasNullWeight(data) && (
          <div className="program_page__container__item__checked">
            <SvgIcon
              filename="Check"
              fill={SVGFillColors.green}
              className="program_page__container__item__checked__icon"
            />
          </div>
        )}
      </div>
    );
  };

  workoutItem = () => {
    const { link, data, type, user, status, dayId } = this.props;
    return (
      <Link
        className="program_page__link"
        to={`/program/${dayId}/${link}/${data.id}`}
      >
        <div
          className={`program_page__container__item ${s.container}`}
          id={`prog-item-${type}`}
        >
          <div className="program_page__container__item__img_block">
            {this.itemIcon()}
          </div>
          <div className="program_page__container__item__info">
            <ProgramItemHeader
              type={type}
              status={status}
              data={data}
              freeUser={isUserFree(user)}
              goal={user.goal}
            />
          </div>
          {['eaten', 'completed'].includes(data.state) && (
            <div className="program_page__container__item__checked">
              <SvgIcon
                filename="Check"
                fill={SVGFillColors.green}
                className="program_page__container__item__checked__icon"
              />
            </div>
          )}
          {status === 'disabled' && (
            <img
              className="program_page__container__item__cover"
              src={getDynamicImage(`./locked/${type}`, FileExtension.png)}
              alt=""
            />
          )}
          {this.itemPreview()}
        </div>
      </Link>
    );
  };

  mealItem = () => {
    const { user, type, data, link, status, dayId } = this.props;

    if (isMaterialUI()) {
      return <Meal user={user} data={data} link={link} dayId={dayId} />;
    }

    if (isUserFree(user) || !data.code) {
      return (
        <Link
          className="program_page__link"
          to={
            isUserFree(user)
              ? `/offer/${user.goal}_clicked_on_premium_meals/${type}?utm_source=app.monacofit.com&utm_medium=${type}_meal&utm_campaign=${type}_helps_lose_weight_4_times_faster`
              : '/program'
          }
        >
          <div
            className={`program_page__container__item ${s.container}`}
            id={`prog-item-${type}`}
          >
            <div className="program_page__container__item__img_block">
              {this.itemIcon()}
            </div>
            <div className="program_page__container__item__info">
              <ProgramItemHeader
                type={type}
                status={status}
                data={data}
                freeUser={isUserFree(user)}
              />
            </div>
            <img
              className="program_page__container__item__cover"
              src={getDynamicImage(`./locked/${type}`, FileExtension.png)}
              alt=""
            />
          </div>
        </Link>
      );
    }

    return (
      <Link
        className="program_page__link"
        to={`/program/${dayId}/${link}/${data.id}`}
      >
        <div
          className={`program_page__container__item ${s.container}`}
          id={`prog-item-${type}`}
        >
          <div className="program_page__container__item__img_block">
            {this.itemIcon()}
          </div>
          <div className="program_page__container__item__info">
            <ProgramItemHeader type={type} status={status} data={data} />
          </div>
          {['eaten', 'completed'].includes(data.state) && (
            <div className="program_page__container__item__checked">
              <SvgIcon
                filename="Check"
                fill={SVGFillColors.green}
                className="program_page__container__item__checked__icon"
              />
            </div>
          )}
          {this.itemPreview()}
        </div>
      </Link>
    );
  };

  render = () => {
    const { type } = this.props;
    if (type === 'fasting') return <Fasting />;
    if (type === 'diary') return this.diaryItem();
    if (type === 'exercise') return this.workoutItem();
    return this.mealItem();
  };
}
