import React from 'react';

function EatingCheckmark() {
  return (
    <svg width={100} height={99} xmlns="http://www.w3.org/2000/svg">
      <path
        d="m45.674 62.757-4.333 9.08-6.759-12.712C27.996 46.742 27.65 46.412 24.01 48.724c-2.08 1.32-7.972 4.788-12.825 7.594C6.16 59.29 2 61.932 2 62.262c0 .33 3.813 2.807 8.319 5.613 4.68 2.807 13.865 10.567 20.624 17.171l12.305 11.887 6.585-9.906C63.178 67.545 83.455 46.082 94.2 40.634c4.333-2.147 4.506-2.642 2.773-7.43-1.04-3.137-1.386-10.401-.866-18.161C96.8 7.779 96.973 2 96.8 2c-.173 0-4.68 3.137-9.879 7.1C73.23 19.5 55.552 42.45 45.674 62.756z"
        fill="#03CEA4"
        stroke="#FAFAFA"
        strokeWidth={4}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EatingCheckmark;
