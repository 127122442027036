import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Radio,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
} from '@material-ui/core';

import { getMaterialUIColor } from 'helpers/themeHelper';
import { SubmitButton } from 'components/common';
import GenomPlusSurveyContext from './GenomPlusSurveyContext';
import {
  GenomPlusSurveyAnswersType,
  GenomPlusSurveyQuestionKeyType,
  GenomPlusSurveyUiContextType,
} from './types';

import s from './GenomPlusSurvey.module.scss';

const questionsUiConfig: {
  [key in GenomPlusSurveyQuestionKeyType]?: { anwerOptions: string[] };
} = {
  sleepy_after_eating: {
    anwerOptions: ['every_day', 'few_times_a_week', 'once_a_week', 'never'],
  },
  skin_breakouts: {
    anwerOptions: [
      'couple_of_times',
      'once_a_month',
      'once_every_2_3_months',
      'less_than_every_3_months',
      'never',
    ],
  },
  how_long_fall_asleep: {
    anwerOptions: [
      'couple_of_minutes',
      '5_15_minutes',
      '15_30_minutes',
      'more_than_30_minutes',
    ],
  },
  dizziness: {
    anwerOptions: [
      'every_day',
      '2_3_times_week',
      'once_every_week',
      'once_every_2_3_weeks',
      'once_every_month',
      'once_every_2_3_months',
      'less_than_every_3_months',
      'never_experience_dizziness',
    ],
  },
  sudden_heart_palpitations: {
    anwerOptions: ['yes', 'no'],
  },
  added_salt_every_food: {
    anwerOptions: ['yes', 'no'],
  },
  swollen_legs: {
    anwerOptions: ['yes', 'no'],
  },
  doctor_told_lose_weight: {
    anwerOptions: ['yes', 'no'],
  },
  bruise_easily: {
    anwerOptions: ['yes', 'no'],
  },
  wake_up_thristy: {
    anwerOptions: ['yes', 'no'],
  },
  high_blood_pressure: {
    anwerOptions: ['yes', 'no'],
  },
};

function getExistingAnswers({
  questions,
  answers,
}: {
  questions?: GenomPlusSurveyQuestionKeyType[];
  answers: GenomPlusSurveyAnswersType;
}): GenomPlusSurveyAnswersType {
  if (!questions) return {};

  return questions.reduce<GenomPlusSurveyAnswersType>((acc, question) => {
    acc[question] = answers[question];
    return acc;
  }, {});
}

function GenomPlusSurveyQuestion() {
  const { t } = useTranslation('genomplus');
  const { answers, steps, handleStepComplete, activeStepIndex } =
    useContext<GenomPlusSurveyUiContextType>(GenomPlusSurveyContext);

  // Support multiple questions per screen for future
  const { questions } = steps[activeStepIndex];
  const [selectedAnswers, setSelectedAnswers] =
    useState<GenomPlusSurveyAnswersType>(() =>
      getExistingAnswers({ questions, answers })
    );

  const handleChange = (
    question: GenomPlusSurveyQuestionKeyType,
    answer: string
  ) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [question]: answer,
    });
  };

  const submitQuestion = () => {
    handleStepComplete(selectedAnswers);
  };

  return (
    <>
      <div className={s.stepContent}>
        {questions?.map((question) => (
          <div className={s.questionBlock} key={question}>
            <div className={s.title}>
              {t(`survey.questions.${question}.title`)}
            </div>
            <List>
              {questionsUiConfig[question]?.anwerOptions.map((answer) => (
                <React.Fragment key={answer}>
                  <ListItem
                    button
                    selected={selectedAnswers[question] === answer}
                    onClick={() => handleChange(question, answer)}
                    classes={{ selected: s.selected }}
                  >
                    <ListItemText>
                      {t(`survey.questions.${question}.${answer}`)}
                    </ListItemText>

                    <ListItemSecondaryAction>
                      <Radio
                        edge="end"
                        checked={selectedAnswers[question] === answer}
                        tabIndex={-1}
                        color={getMaterialUIColor()}
                        onClick={() => handleChange(question, answer)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </React.Fragment>
              ))}
            </List>
          </div>
        ))}
      </div>

      <div className={s.stepSubmit}>
        <SubmitButton
          className={s.stepSubmitButton}
          label={t('survey.next')}
          type="large"
          onClick={submitQuestion}
          disabled={Object.values(selectedAnswers).some(
            (answer) => answer === null
          )}
        />
      </div>
    </>
  );
}

export default GenomPlusSurveyQuestion;
