import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isEatingPhase } from 'helpers/fastingHelper';
import { FastingData } from 'types/fasting';

import browserHistory from 'helpers/history';

import { LoadingAnimation } from 'components/common';
import FastingNonStartedHeader from './FastingNonStartedHeader';
import FastingHeader from './FastingHeader';
import FastingTimerBody from './FastingTimerBody';
import FastingBody from './FastingBody';
import EatingHeader from './EatingHeader';
import EatingTimerBody from './EatingTimerBody';

import s from './Fasting.module.scss';

function Fasting() {
  const fasting = useSelector<any, FastingData>(
    (store) => store.rootReducer.fasting
  );

  if (!fasting || fasting.isLoading) {
    return <LoadingAnimation />;
  }

  const getLinkToURL = () => {
    if (!timer) {
      return '/program';
    }
    if (isEatingPhase(timer)) {
      return '/eating-timer';
    }

    return '/fasting-timer';
  };

  if (!fasting.fasting) {
    return browserHistory.pushLater('/choose-fasting-cycle');
  }

  const {
    fasting: { cycle, timer },
  } = fasting;

  const renderContent = () => {
    if (!timer) {
      return (
        <>
          <FastingNonStartedHeader cycle={cycle} />
          <FastingBody cycle={cycle} />
        </>
      );
    }

    if (isEatingPhase(timer)) {
      return (
        <>
          <EatingHeader cycle={cycle} />
          <EatingTimerBody cycle={cycle} timer={timer} />
        </>
      );
    }

    return (
      <>
        <FastingHeader cycle={cycle} timer={timer} />
        <FastingTimerBody cycle={cycle} timer={timer} />
      </>
    );
  };

  return (
    <Link to={getLinkToURL()} className={s.link}>
      <div className={s.container}>{renderContent()}</div>
    </Link>
  );
}

export default Fasting;
