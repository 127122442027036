import React from 'react';

import { Offer } from 'types/offer';
import { User } from 'types/user';
import { MetaData, PrimerInitEventType } from 'types/primer';

import InlineCheckout from './InlineCheckout';
import ExternalCheckout from './ExternalCheckout';

type Props = {
  user: User;
  offer: Offer;
  metaData: MetaData;
  primerEventType:
    | PrimerInitEventType.chosePaymode
    | PrimerInitEventType.offerRetried;
  isLoading: boolean;
  disabled: boolean;
  currentPaymode: string;
  duplicateButton?: boolean;
  onClick: (scrollFlag?: boolean) => void;
  onClose: () => void;
};

function PrimerCheckout({
  user,
  offer,
  metaData,
  primerEventType,
  isLoading,
  disabled,
  currentPaymode,
  duplicateButton,
  onClick,
  onClose,
}: Props) {
  if (offer.inline_checkout) {
    return (
      <InlineCheckout
        user={user}
        offer={offer}
        metaData={metaData}
        primerEventType={primerEventType}
        isLoading={isLoading}
        disabled={disabled}
        currentPaymode={currentPaymode}
        duplicateButton={duplicateButton}
        onClick={onClick}
        onClose={onClose}
      />
    );
  }

  return (
    <ExternalCheckout
      user={user}
      offer={offer}
      metaData={metaData}
      primerEventType={primerEventType}
      isLoading={isLoading}
      disabled={disabled}
      currentPaymode={currentPaymode}
      duplicateButton={duplicateButton}
      onClick={onClick}
      onClose={onClose}
    />
  );
}

export default PrimerCheckout;
