import React from 'react';

import { SVG } from 'types/svg';

function Weight(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M21.39 0H2.61A2.61 2.61 0 0 0 0 2.61v18.78A2.61 2.61 0 0 0 2.61 24h18.78A2.61 2.61 0 0 0 24 21.39V2.61A2.61 2.61 0 0 0 21.39 0zm1 21.39a1 1 0 0 1-1 1H2.61a1 1 0 0 1-1-1V2.61a1 1 0 0 1 1-1h18.78a1 1 0 0 1 1 1z" />
      <path d="M12 3a7 7 0 0 0-6.07 3.44.79.79 0 0 0 .11 1l3.12 3.12a.81.81 0 0 0 .57.23h.14a.83.83 0 0 0 .59-.45 1.69 1.69 0 0 1 3.08 0 .83.83 0 0 0 .59.45.84.84 0 0 0 .71-.22L18 7.43a.79.79 0 0 0 .11-1A7.05 7.05 0 0 0 12 3zm2.38 5.7a3.33 3.33 0 0 0-2.38-1 2.5 2.5 0 0 0-.47 0l-.91-1.62a.8.8 0 0 0-1.4.79L10 8.38a2.79 2.79 0 0 0-.42.35l-2-2a5.51 5.51 0 0 1 8.7 0zM13.86 18.87h-3.72a.8.8 0 0 0 0 1.6h3.72a.8.8 0 1 0 0-1.6z" />
    </svg>
  );
}

export default Weight;
