import React from 'react';

import { SVG } from 'types/svg';

function Meal(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M16.9 22.5H7.1a.76.76 0 0 0 0 1.51h9.82a.76.76 0 0 0 0-1.51zm4.7-10.64h-.88a4.34 4.34 0 0 0-6.22-5.69L13 4.52a1.29 1.29 0 0 0 .77-1.18 1.28 1.28 0 0 0-2.56 0 1.44 1.44 0 0 0 .07.36 1.18 1.18 0 0 0-.58-.16 1.28 1.28 0 1 0 0 2.56 1.25 1.25 0 0 0 1.1-.67l1.6 1.78a4.65 4.65 0 0 0-.66 1.34 8 8 0 0 0-3.47-.72c-3.4 0-5.75 1.6-5.75 3.9v.12H2.4a.76.76 0 0 0-.75.75 8.82 8.82 0 0 0 .91 3.31c1 2.25 2.7 4.6 4.15 5.44a.69.69 0 0 0 .37.1h9.82a.69.69 0 0 0 .37-.1c2.22-1.3 5.06-6.2 5.06-8.77a.76.76 0 0 0-.75-.73zm-4.7-4.92a2.85 2.85 0 0 1 1.95 4.92H15v-.12a3 3 0 0 0-.08-.66 1.11 1.11 0 0 0-.07-.22 2.83 2.83 0 0 0-.14-.41l-.13-.24-.22-.35-.2-.23a1.83 1.83 0 0 0-.13-.16 2.87 2.87 0 0 1 2.87-2.53zm-7.63 2.4a5.52 5.52 0 0 1 3.53 1 1.83 1.83 0 0 1 .71 1.39v.12H5v-.12c0-1.37 1.82-2.4 4.28-2.4zm-4.78 4h16.25a9.67 9.67 0 0 1-.5 1.53H3.75a9.67 9.67 0 0 1-.5-1.53zM16.7 20H7.3a11.09 11.09 0 0 1-2.83-3.59h15A11.09 11.09 0 0 1 16.69 20zM2.1 8.42a.76.76 0 0 0 1.51 0 2.56 2.56 0 0 1 .58-1.9c.83-1.24-.13-2.84-.9-3.4a.75.75 0 0 0-.87 1.23c.26.2.76 1 .52 1.33a4 4 0 0 0-.84 2.74zm3.8-3a.75.75 0 0 0 1.5 0 2.57 2.57 0 0 1 .6-1.9C8.8 2.3 7.85.7 7.08.14A.76.76 0 0 0 6 .31a.74.74 0 0 0 .2 1.05c.26.2.76 1 .52 1.34a4 4 0 0 0-.83 2.73z" />
    </svg>
  );
}

export default Meal;
