import { apiHost, requestHeadersWithToken } from 'helpers/requestHelpers';
import { ajax } from 'rxjs/ajax';
import { pluck } from 'rxjs/operators';

export const loadOldMessages = (messageId) => {
  let url = `${apiHost()}/api/v1/users/chat/message-list`;
  if (messageId) {
    url += `?oldestDisplayedMessageId=${messageId}`;
  }
  return ajax.getJSON(url, requestHeadersWithToken());
};

export const sendMessage = (payload, socketId) =>
  ajax
    .post(
      `${apiHost()}/api/v1/users/chat/send-message`,
      { message: payload },
      requestHeadersWithToken({ 'X-Socket-ID': socketId })
    )
    .pipe(pluck('response'));
