import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@material-ui/core';
import { SvgIcon } from 'components/common';

import { GenomPlusSurveyUiContextType } from './types';
import GenomPlusSurveyContext from './GenomPlusSurveyContext';

import s from './GenomPlusSurvey.module.scss';

function GenomPlusSurveyStep({ stepIndex }: { stepIndex: number }) {
  const { t } = useTranslation('genomplus');
  const { steps, activeStepIndex, setActiveStepIndex, handleStepBack } =
    useContext<GenomPlusSurveyUiContextType>(GenomPlusSurveyContext);

  useEffect(() => {
    if (stepIndex === activeStepIndex) return;

    setActiveStepIndex(stepIndex);
  }, [stepIndex, activeStepIndex, setActiveStepIndex]);

  if (!stepIndex && stepIndex !== 0) return null;

  const isBackAvailable = activeStepIndex + 1 < steps.length;
  const stepsTotal = steps.length;
  const progress =
    activeStepIndex && Math.floor(((activeStepIndex + 1) * 100) / stepsTotal);
  const progressValue = `${progress}%`;
  const progressLabel = t('survey.progress_completed', { progressValue });

  const StepComponent = steps[stepIndex].component;

  return (
    <div className={s.surveyStep}>
      {isBackAvailable && (
        <button
          type="button"
          className={s.back}
          onClick={() => handleStepBack()}
        >
          <SvgIcon filename="BackChevron" />
          <span>{t('survey.back')}</span>
        </button>
      )}

      <div className={s.progressLabel}>{progressLabel}</div>

      <div className={s.progressBar}>
        <LinearProgress variant="determinate" value={progress} />
      </div>

      <div className={s.stepContentContainer}>
        {stepIndex === activeStepIndex && <StepComponent />}
      </div>
    </div>
  );
}

export default GenomPlusSurveyStep;
