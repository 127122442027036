import browserHistory from 'helpers/history';

export default class Auth {
  static loggedIn() {
    return !!localStorage.access_token;
  }

  static logIn(accessToken) {
    localStorage.setItem('access_token', accessToken);
    browserHistory.pushLater('/program');
  }

  static logOut() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('countdown');
    if (localStorage.current_user_id)
      localStorage.removeItem('current_user_id');
    browserHistory.pushLater('/');
  }
}
