import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';
import findIndex from 'lodash/findIndex';

export default (state = initialState.messages, action) => {
  let updatableElement = -1;
  if (action.payload && action.payload.uuid)
    updatableElement = findIndex(state, (m) => m.uuid === action.payload.uuid);

  switch (action.type) {
    case types.CHAT_ADD_MESSAGE.ACTION:
      return [
        ...state,
        {
          uuid: action.payload.uuid,
          admin_name: null,
          message_type: 'chat',
          raw_content: action.payload.text,
          content: action.payload.text,
          should_show_translation_bubble: true,
          unsaved: true,
        },
      ];

    case types.CHAT_ADD_MESSAGE.SUCCESS:
      if (updatableElement === -1) return [...state, action.payload];

      return [
        ...state.slice(0, updatableElement),
        action.payload,
        ...state.slice(updatableElement + 1),
      ];

    case types.CHAT_ADD_MESSAGE.ERROR:
      return [
        ...state.slice(0, updatableElement),
        ...state.slice(updatableElement + 1),
      ];

    case types.CHAT_GET_MESSAGES.SUCCESS:
      return [...action.payload, ...state];

    case types.CHAT_INITIAL_MESSAGES.SUCCESS:
      return [...state, ...action.payload.items];

    case types.CHAT_OLD_MESSAGES.SUCCESS:
      return [...action.payload.items, ...state];

    case types.CHAT_SEND_MESSAGE.SUCCESS:
      return [...state, action.payload];
    case types.LOG_OUT.SUCCESS:
    case types.CHAT_SOCKET_DISCONNECT.SUCCESS:
      return initialState.messages;

    default:
      return state;
  }
};
