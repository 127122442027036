import React from 'react';

function ContextualTricksHealthgenom() {
  return (
    <svg width={104} height={104} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="prefix__d383bf4f-41dd-4025-b7a6-56f5c20ca130"
        >
          <stop stopColor="#FFE066" offset="0%" />
          <stop stopColor="#D88D28" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          fill="url(#prefix__d383bf4f-41dd-4025-b7a6-56f5c20ca130)"
          cx={52}
          cy={52}
          r={52}
        />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M71.697 52.742c1.245-4.05 1.03-8.459-.656-12.643-1.724-4.282-4.855-7.975-8.374-9.88-1.133-.613-2.485.313-2.322 1.592.362 2.843.402 5.71-1.485 7.714-4.015-4.891-.744-14.308-.709-14.408.444-1.242-.738-2.45-1.99-2.033-3.934 1.311-7.192 3.916-9.174 7.333-1.956 3.374-2.633 7.49-2.004 12.045-1.507-1.154-2.593-2.848-3.24-5.063-.781-2.675-.613-5.13-.61-5.151.117-1.525-1.781-2.314-2.778-1.154-.09.106-2.249 2.642-3.853 6.819-1.861 4.847-2.21 9.93-1.033 14.83h38.228zm4.139 3.164H28.164A3.164 3.164 0 0 0 25 59.07v14.766A3.164 3.164 0 0 0 28.164 77h47.672A3.164 3.164 0 0 0 79 73.836V59.07a3.164 3.164 0 0 0-3.164-3.164zM40.012 72.641a1.314 1.314 0 0 1-1.864.034l-3.814-3.68v2.731a1.318 1.318 0 0 1-2.637 0V61.179a1.318 1.318 0 0 1 2.637 0v2.346l3.463-3.183a1.318 1.318 0 1 1 1.784 1.94l-4.305 3.958 4.703 4.537c.524.506.539 1.34.033 1.864zm11.593-1.557a5.551 5.551 0 0 1-.561.58c-1.014.903-2.318 1.38-3.771 1.38a6.6 6.6 0 0 1-6.592-6.591 6.6 6.6 0 0 1 6.592-6.592c1.32 0 2.596.39 3.686 1.127a1.318 1.318 0 1 1-1.476 2.184 3.932 3.932 0 0 0-2.21-.674 3.96 3.96 0 0 0-3.955 3.955 3.96 3.96 0 0 0 3.955 3.955c.807 0 1.486-.24 2.017-.714.104-.092.203-.194.295-.304a1.318 1.318 0 1 1 2.02 1.694zM63.3 72.96a1.316 1.316 0 0 1-1.7-.768l-.655-1.737h-4.543l-.662 1.74a1.319 1.319 0 0 1-2.464-.938l3.96-10.399.011-.029c.24-.587.806-.967 1.44-.968h.002a1.552 1.552 0 0 1 1.455 1l3.924 10.4a1.319 1.319 0 0 1-.768 1.699zm7.692.076c-1.198.006-2.018.01-2.582.01-1.176 0-1.248-.013-1.342-.03a1.318 1.318 0 0 1-1.095-1.299V61.18a1.318 1.318 0 0 1 2.636 0v9.229a540.07 540.07 0 0 0 2.368-.01h.007a1.318 1.318 0 0 1 .008 2.638z" />
          <path d="M57.407 67.818h2.543l-1.266-3.355z" />
        </g>
      </g>
    </svg>
  );
}

export default ContextualTricksHealthgenom;
