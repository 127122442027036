import React from 'react';

import { SVG } from 'types/svg';

function PasswordHealthgenom(props: SVG) {
  return (
    <svg
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z"
          id="prefix__4944ec92-76f7-467a-948a-b3990785b98a"
        />
      </defs>
      <use
        fill="#2884AA"
        xlinkHref="#prefix__4944ec92-76f7-467a-948a-b3990785b98a"
      />
    </svg>
  );
}

export default PasswordHealthgenom;
