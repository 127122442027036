/* eslint-disable camelcase */
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { addError, addSuccess } from 'redux-flash-messages';
import { USER_CREATE } from 'actions/actionTypes';
import { createUser } from 'api/UserAPI';
import i18n from 'i18n';

import ResponseHandler from 'actions/ResponseHandler';
import { catchedErrorText } from 'helpers/epicHelper';
import { logInErrorAction, logInSuccessAction } from 'actions/sessionActions';
import auth from 'helpers/authenticator';

// creates user and logs it in the system
// eslint-disable-next-line import/prefer-default-export
export const createUserEpic = (action$) =>
  action$.ofType(USER_CREATE).pipe(
    switchMap(({ user }) =>
      createUser(user).pipe(
        map((response) => new ResponseHandler(response)),
        map((resHandler) => {
          const { access_token } = resHandler.response.data.attributes;

          // logs in user after successful create
          auth.logIn(access_token);
          addSuccess({ text: i18n.t('flash_msg.success_login') });

          return logInSuccessAction();
        }),
        catchError((err) => {
          addError({ text: catchedErrorText(err) });
          return of(logInErrorAction());
        })
      )
    )
  );
