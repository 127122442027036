import React from 'react';

import { SVG } from 'types/svg';

function PhoneHealthgenom(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} />
        <path
          d="M17.4 22C8.9 21.99 2.011 15.1 2 6.6A4.6 4.6 0 0 1 6.6 2c.258-.002.516.021.77.07.245.036.486.097.72.18a1 1 0 0 1 .65.75l1.37 6a1 1 0 0 1-.26.92c-.13.14-.14.15-1.37.79a9.91 9.91 0 0 0 4.87 4.89c.65-1.24.66-1.25.8-1.38a1 1 0 0 1 .92-.26l6 1.37a1 1 0 0 1 .72.65 4.34 4.34 0 0 1 .25 1.49A4.6 4.6 0 0 1 17.4 22z"
          fill="#2884AA"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default PhoneHealthgenom;
