import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import browserHistory from 'helpers/history';
import { connect } from 'react-redux';

class EnsureLoggedInWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  componentDidMount = () => {
    this.redirectToRoot();
  };

  componentDidUpdate() {
    this.redirectToRoot();
  }

  redirectToRoot = () => {
    if (!localStorage.access_token) {
      browserHistory.pushLater('/');
    }
  };

  render = () => {
    const { children } = this.props;

    return localStorage.access_token ? children : <Redirect to="/" />;
  };
}

const mapStateToProps = (state) => ({
  user: state.rootReducer.user,
});

export default connect(mapStateToProps)(EnsureLoggedInWrapper);
