export type KetoChocolateBarsAddressData = {
  first_name: string;
  last_name: string;
  address: string;
  address2: string;
  zip: string;
  city: string;
  country: string;
  phone_number: string;
  google_place_id: string;
  email: string;
  country_code: string;
  phone_prefix: string;
  [key: string]: string;
};

export type KetoChocolateBars = {
  confirmationData: KetoChocolateBarsAddressData | null;
};

export type FormFields = {
  name: string;
  label: string;
  inputType: string;
  required: boolean;
  customStyle?: string;
};

export type RowVariants = 'full' | 'sideBySide';

export enum RowVariantsEnum {
  full = 'full',
  sideBySide = 'sideBySide',
}

export type RowFields = {
  variant: RowVariants;
  fields: FormFields[];
};

export type FormData = {
  [key: string]: string;
};

export type DynamicAdddresFormData = {
  formData: FormData;
  rowData: RowFields[];
};

export type DynamicContextualData = {
  offer_type: string;
  source: string;
  utms: { utm_source: string; utm_medium: string; utm_campaign: string };
};
