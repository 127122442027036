import React from 'react';

import { FileExtension, getDynamicImageBundle } from 'helpers/imageHelper';

type Props = {
  imagePath: string;
  imageExtension: FileExtension;
  altText: string;
  style?: string;
  id?: string;
};

function ResponsiveImage({
  imagePath,
  imageExtension,
  altText,
  style,
  id,
}: Props) {
  const imageBundle = getDynamicImageBundle(imagePath, imageExtension);

  return (
    <img
      id={id}
      className={style}
      src={imageBundle.base}
      srcSet={`
              ${imageBundle.medium} 2x,
              ${imageBundle.large} 3x`}
      alt={altText}
    />
  );
}

export default ResponsiveImage;
