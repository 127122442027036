import React from 'react';

function PaymentModalClose() {
  return (
    <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.391.609a2.079 2.079 0 010 2.94L12.1 8.84l5.291 5.291a2.079 2.079 0 01-2.94 2.94L9.16 11.78l-5.291 5.291a2.079 2.079 0 01-2.94-2.94L6.22 8.84.929 3.549a2.079 2.079 0 012.94-2.94L9.16 5.9 14.451.609a2.079 2.079 0 012.94 0z"
        fill="#DCE2E5"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default PaymentModalClose;
