import { Subscription } from './subscription';
import { ModalSteps } from './quiz';
import { PhysicalProductType } from './physicalProducts';

export enum ModalType {
  planUpdate = 'planUpdate',
  testimonial = 'testimonial',
  cookieSettings = 'cookieSettings',
  premiumWarning = 'premiumWarning',
  unsubscribed = 'unsubscribed',
  unsubscribev6 = 'unsubscribev6',
  unsubscribev3 = 'unsubscribev3',
  unsubscribedv7 = 'unsubscribedv7',
  unsubscribedv8 = 'unsubscribedv8',
  subscriptionPriceChanged = 'subscriptionPriceChanged',
  cardSaveConfirmation = 'cardSaveConfirmation',
  startFasting = 'startFasting',
  stopFasting = 'stopFasting',
  finishFasting = 'finishFasting',
  stopEating = 'stopEating',
  collagenSubCanceled = 'collagenSubCanceled',
  physicalProductUnsubscribed = 'physicalProductUnsubscribed',
  contextualOffer = 'contextualOffer',
  pausedSubscription = 'pausedSubscription',
  unpauseSubscription = 'unpauseSubscription',
  welcomeBackPauseSubscription = 'welcomeBackPauseSubscription',
  preunsubscribeShoppinglist = 'preunsubscribeShoppinglist',
  preunsubscribeChat = 'preunsubscribeChat',
  startCancellationFee = 'startCancellationFee',
  cancellationFee = 'cancellationFee',
  modalOffer = 'modalOffer',
  customModal = 'customModal',
  ketoChocolateBars = 'ketoChocolateBars',
}

export type Modals = {
  modal: ModalType;
  subscription?: Subscription;
  unsubModalType?: ModalSteps;
  physicalProductType?: PhysicalProductType;
  [key: string]: any;
};

export enum UnsubscribeV3LinkPaths {
  chat = '/chat',
  program = '/program',
}
