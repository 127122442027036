import { CheckoutStyle } from '@primer-io/checkout-web';

// eslint-disable-next-line import/prefer-default-export
export const CHECKOUT_STYLE: CheckoutStyle = {
  stylesheets: [
    {
      href: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap',
    },
  ],
  loadingScreen: {
    color: '#2a9d8f',
  },
  formSpacings: {
    betweenInputs: '5px',
    betweenLabelAndInput: '8px',
  },
  inputLabel: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '14px',
    color: '#404040',
  },
  input: {
    base: {
      background: '#fff',
      fontFamily: "'Source Sans Pro', sans-serif",
      letterSpacing: '0.2px',
      placeholder: {
        color: 'rgba(64, 64, 64, 0.4)',
      },
      focus: {
        background: '#dff8f5',
        borderStyle: 'solid',
        borderColor: '#464646',
        borderWidth: '1px',
        color: '#464646',
      },
    },
  },
  submitButton: {
    base: {
      color: '#fff',
      background: 'linear-gradient(262deg, #54ccc2, #2a9d8f)',
      fontSize: '20px',
      fontWeight: '500',
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
      hover: {
        background: 'red',
      },
    },
    loading: {
      background: '#ced5da',
    },
    disabled: {
      background: '#ced5da',
    },
  },
  paymentMethodButton: {
    marginTop: '24px',
  },
  processingIndicator: {
    color: '#2a9d8f',
  },
  separator: {
    color: '#2a9d8f',
  },
};
