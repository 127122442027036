export type AccessItemsData = {
  isLoading: boolean;
  hasError: boolean;
  accessItems: AccessItems;
};

export type AccessItems = {
  [key: string]: Item | null;
};

export type AccessCurrency = {
  name: string;
  symbol: string;
  conversion_ratio: number;
};

export type Item = {
  amount: number;
  created_at: string;
  currency: AccessCurrency;
  frequency: number;
  payment_id: number;
  subscription_id: number;
  type: string;
  subscription_amount: string;
  last_payment_amount: number;
};

export enum AccessName {
  chat = 'chat',
  collagen = 'collagen',
  fatBurner = 'fat_burner',
  mealSwitch = 'meal_switch',
  premiumUpsell = 'premium_upsell',
  proSubscription = 'pro_subscription',
  shoppingList = 'shopping_list',
  tricks = 'tricks',
  acvGummies = 'acv_gummies',
  genom = 'genom',
  genomBloodTest = 'genom_blood_test',
  genomSalivaTest = 'genom_saliva_test',
  genomMicroTest = 'genom_micro_test',
  ketoChocolateBars = 'keto_chocolate_bars',
}

export enum AccessType {
  subscription = 'subscription',
  payment = 'payment',
  free = 'free',
}

export type FreeAccessToUserData = {
  isLoading: boolean;
  hasError: boolean;
};
