import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (state = initialState.meal, action) => {
  switch (action.type) {
    case types.MEAL_LOAD.SUCCESS:
      return { ...action.meal };

    case types.LOG_OUT.SUCCESS:
      return initialState.meal;

    case types.MEAL_CLEAR:
      return {};

    default:
      return state;
  }
};
