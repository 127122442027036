/* eslint-disable import/prefer-default-export */
import {
  apiHost,
  requestHeadersWithToken,
  fetchData,
} from 'helpers/requestHelpers';

export const auditEvent = (requestBody) => {
  const request = new Request(`${apiHost()}/api/v1/audit_event/`, {
    method: 'POST',
    headers: requestHeadersWithToken(),
    body: JSON.stringify(requestBody),
  });
  return fetchData(request);
};
