/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import browserHistory from 'helpers/history';
import { isTodayISO } from 'helpers/dateHelper';
import { getUserActiveProSubscription, isUserFree } from 'helpers/userHelper';
import { modalBackgroundToggle } from 'helpers/programHelper';
import { getUserCurrentDayDataAction } from 'actions/programActions';
import { addModal } from 'actions/modalActions';
import { getFasting } from 'actions/fastingActions';
import { getStyle, isPrimaryTheme } from 'helpers/themeHelper';
import { isParameterInQueryString } from 'helpers/quizHelper';
import { isFastingBlockAvailable } from 'helpers/fastingHelper';
import { isExpressPlanAvailable } from 'helpers/expressPlanHelper';
import {
  isSubscriptionPaused,
  isWelcomeBackPausedScreenNeeded,
} from 'helpers/subscriptionHelper';
import settings from 'helpers/settings';

import { ModalSteps } from 'types/quiz';
import { ModalType } from 'types/modals';
import { UNSUBSCRIBEV8_LOG_NEEDED } from 'types/unsubscribeV8';

import { LoadingAnimation } from 'components/common';
import { Calendar, Statistics } from 'components/program/_partials';
import {
  DiaryCompletedModal,
  DiaryModal,
  DiaryCanNotBeAccessedModal,
} from 'components/program/modals';
import SurveySuccessModal from 'components/user/modals/SurveySuccessModal';
import PausedProgramSubscription from 'components/subscription/PausedProgramSubscription';
import ProgramItemContainer from './_partials/ProgramItemContainer';
import ExpressPlan from './ExpressPlan';

import base from './ProgramPage.module.scss';
import diet from './ProgramPage_diet.module.scss';

const s = getStyle(base, diet);

class ProgramPage extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    day: PropTypes.object,
    messages: PropTypes.array,
  };

  constructor(props) {
    super(props);
    const { user, day } = props;
    const { diary_entry } = day;

    const shouldShowSurveySuccessModal =
      props.messages.length !== 0 &&
      props.messages[0].type === 'SURVEY_CREATED';

    const shouldDisplayDiaryModal =
      !isUserFree(user) &&
      (!this.isWeightNull(day) || this.hasNotOpened(diary_entry)) &&
      !shouldShowSurveySuccessModal;

    this.state = {
      // auto-displays diary modal for premium users on initial render and page reload via redux store param opened
      diaryModalVisible: shouldDisplayDiaryModal,
      diaryCompletedModalVisible: false,
      surveySuccessModalVisible: shouldShowSurveySuccessModal,
      diaryCanNotBeAccessedModalVisible: false,
    };
  }

  componentDidMount() {
    const { day, getUserCurrentDayDataAction, addModal, user, getFasting } =
      this.props;

    if (!day.isLoading && isEmpty(day)) {
      getUserCurrentDayDataAction();
    }
    if (isFastingBlockAvailable(user)) {
      getFasting();
    }
    if (isParameterInQueryString('unsubscribev6-success-1')) {
      addModal({
        modal: ModalType.unsubscribev6,
        unsubModalType: ModalSteps.quote,
      });
    }
    // Paused subscription welcome back modal
    if (isWelcomeBackPausedScreenNeeded(user)) {
      addModal({
        modal: ModalType.welcomeBackPauseSubscription,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { day, user } = this.props;
    const { diaryModalVisible } = this.state;

    if (prevProps.day !== day) {
      if (!day.isLoading) {
        if (day.diary_entry !== null && diaryModalVisible) {
          // closes diary modal after submit button click
          if (!isPrimaryTheme()) {
            this.toggleModal({ diaryModalVisible: false });
          }

          if (isUserFree(user) && !user.user_is_a_newbie) {
            browserHistory.pushLater(`/offer/${user.goal}_diary_followup`);
          }
        }
      }
    }
  }

  hasNotOpened = (diaryEntry) =>
    diaryEntry
      ? !Object.prototype.hasOwnProperty.call(diaryEntry, 'opened')
      : false;

  isWeightNull = (day) => day.diary_entry && day.diary_entry.weight === null;

  toggleModal = (value) => {
    modalBackgroundToggle(value);
    this.setState(value);
  };

  programModals = () => {
    let surveyModal = null;
    const { day } = this.props;
    const {
      diaryModalVisible,
      diaryCompletedModalVisible,
      surveySuccessModalVisible,
      diaryCanNotBeAccessedModalVisible,
    } = this.state;
    const isUnsubscribeV8LogNeeded = isParameterInQueryString(
      UNSUBSCRIBEV8_LOG_NEEDED
    );
    const isExpressPlanDone = isParameterInQueryString('express-plan-done');

    if (isExpressPlanDone) {
      return (
        <SurveySuccessModal
          status
          closeModal={() => {
            this.toggleModal({ surveySuccessModalVisible: false });
            browserHistory.replace('/program');
          }}
        />
      );
    }
    if (!isTodayISO(day.date)) {
      surveyModal = (
        <DiaryCanNotBeAccessedModal
          status={diaryCanNotBeAccessedModalVisible}
          closeModal={() =>
            this.toggleModal({ diaryCanNotBeAccessedModalVisible: false })
          }
        />
      );
    } else if (day.diary_entry === null || this.isWeightNull(day)) {
      surveyModal = (
        <DiaryModal
          status={diaryModalVisible}
          closeDiaryModal={() =>
            this.toggleModal({
              diaryModalVisible: false,
            })
          }
        />
      );
    } else if (isUnsubscribeV8LogNeeded) {
      surveyModal = (
        <DiaryModal
          status={diaryModalVisible}
          closeDiaryModal={() => {
            browserHistory.replace('/program');
            this.toggleModal({
              diaryModalVisible: false,
            });
          }}
        />
      );
    } else {
      surveyModal = (
        <DiaryCompletedModal
          status={diaryCompletedModalVisible}
          closeModal={() =>
            this.toggleModal({ diaryCompletedModalVisible: false })
          }
        />
      );
    }

    return (
      <div>
        <SurveySuccessModal
          status={surveySuccessModalVisible}
          closeModal={() =>
            this.toggleModal({ surveySuccessModalVisible: false })
          }
        />
        {surveyModal}
      </div>
    );
  };

  render = () => {
    let diaryEntryState;
    const { day, user } = this.props;

    if (isExpressPlanAvailable(user)) {
      return <ExpressPlan />;
    }

    const activeSubscription = getUserActiveProSubscription(user);
    if (isSubscriptionPaused(activeSubscription)) {
      return <PausedProgramSubscription />;
    }

    if (!isTodayISO(day.date)) {
      diaryEntryState = { diaryCanNotBeAccessedModalVisible: true };
    } else if (day.diary_entry === null || this.isWeightNull(day)) {
      diaryEntryState = { diaryModalVisible: true };
    } else {
      diaryEntryState = { diaryCompletedModalVisible: true };
    }

    return (
      <div className={`program_page ${s.container}`}>
        <div className={s.gradient} />
        {this.programModals()}
        {!isEmpty(day) && <Calendar />}
        {settings.SHOW_STATISTICS && <Statistics stats={user.stats} />}
        {isEmpty(day) || day.isLoading ? (
          <LoadingAnimation />
        ) : (
          <ProgramItemContainer
            day={day}
            user={user}
            openDiaryModal={() => this.toggleModal(diaryEntryState)}
          />
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  user: state.rootReducer.user,
  day: state.rootReducer.day,
  messages: state.rootReducer.flashMessage.messages,
});

const mapDispatchToProps = {
  getUserCurrentDayDataAction,
  addModal,
  getFasting,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramPage);
