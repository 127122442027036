import React from 'react';

function PremiumHealthgenom() {
  return (
    <svg
      width={25}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M22 6.4 20.493 20H3.505L2 6.4l5.443 7.189 4.719-6.8 4.719 6.8L22 6.4zm-10 6.549-1.75 2.395L12 17.738l1.75-2.394L12 12.949zM17.713 2a3.535 3.535 0 0 0 3.536 3.536 3.539 3.539 0 0 0-3.536 3.54 3.541 3.541 0 0 0-3.54-3.54A3.538 3.538 0 0 0 17.713 2zM6.741 5.041a1.947 1.947 0 0 0 1.947 1.947 1.948 1.948 0 0 0-1.947 1.949 1.95 1.95 0 0 0-1.949-1.949 1.948 1.948 0 0 0 1.949-1.947zM3.505 21h16.988v1H3.505z"
          id="prefix__4379ff63-5f5a-44e4-a152-6d81eaf3aade"
        />
      </defs>
      <g transform="translate(.5)" fill="none" fillRule="evenodd">
        <mask id="prefix__22a0b52a-ed43-4b2e-93c1-cd29e5084267" fill="#fff">
          <use xlinkHref="#prefix__4379ff63-5f5a-44e4-a152-6d81eaf3aade" />
        </mask>
        <g
          mask="url(#prefix__22a0b52a-ed43-4b2e-93c1-cd29e5084267)"
          fill="#FFF"
          fillOpacity={0.54}
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
}

export default PremiumHealthgenom;
