import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { convertToMin } from 'helpers/timeHelper';
import { mealIcon } from 'helpers/programHelper';
import { getStyle, isPrimaryTheme } from 'helpers/themeHelper';
import { MealIconSize } from 'types/program';
import { SVGFillColors } from 'types/svg';

import SvgIcon from 'components/common/SvgIcon';

import base from './SinglePageHeader.module.scss';
import diet from './SinglePageHeader_diet.module.scss';
import keto from './SinglePageHeader_keto.module.scss';
import ketogo from './SinglePageHeader_ketogo.module.scss';
import fasting from './SinglePageHeader_fasting.module.scss';
import healthgenom from './SinglePageHeader_healthgenom.module.scss';

const s = getStyle(base, diet, keto, ketogo, fasting, healthgenom);

class SinglePageHeader extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
  };

  getHeaderIcon = () => {
    const { type, data } = this.props;

    if (type === 'exercise') {
      if (data.visibility === 'normal') {
        return (
          <div>
            <span className="single_page__header__type__number">
              {data.sequence}
            </span>
          </div>
        );
      }
      if (['rest', 'rest_long'].includes(data.code)) {
        return <SvgIcon filename="Rest" fill={SVGFillColors.orange} />;
      }

      if (data.preview) {
        return (
          <SvgIcon filename="WorkoutPreview" fill={SVGFillColors.orange} />
        );
      }
    }
    const className = 'single_page__header__type__img';
    const filename = ['exercise', 'workout'].includes(type)
      ? 'Exercise'
      : 'ProgramMeal';

    if (isPrimaryTheme()) {
      return (
        <img
          className={s.icon}
          src={mealIcon(data.meal_type, MealIconSize.large)}
          alt={data.title}
        />
      );
    }

    return (
      <SvgIcon
        filename={filename}
        className={className}
        fill={SVGFillColors.orange}
      />
    );
  };

  getHeaderTitle = () => {
    const { data, type, t } = this.props;
    let headerTitle = data.title;

    if (isPrimaryTheme()) {
      headerTitle = t(`items.type.${data.meal_type}.header`);
      if (data.time && data.time > 0) {
        headerTitle += ` (${convertToMin(data.time)})`;
      }
    }

    if (type === 'exercise') {
      let headerSpan;
      if (data.time) {
        headerSpan = (
          <span className="single_page__header__context__title__count">
            {convertToMin(data.time)}
          </span>
        );
      } else if (data.repetitions) {
        headerSpan = (
          <span className="single_page__header__context__title__count">
            {data.repetitions}x
          </span>
        );
      }
      return (
        <h3 className="single_page__header__context__title">
          {headerSpan} {headerTitle}
        </h3>
      );
    }
    return (
      <h3 className={`single_page__header__context__title ${s.title}`}>
        {headerTitle}
      </h3>
    );
  };

  getContext = () => {
    const {
      data: { time, title },
      type,
    } = this.props;

    if (isPrimaryTheme()) {
      return title;
    }

    if (time && type !== 'exercise') {
      return (
        <span className="single_page__header__context__info__time">
          <SvgIcon
            className="single_page__header__context__info__time__img"
            filename="Time"
            fill={SVGFillColors.green}
          />
          <span
            className={`single_page__header__context__info__time__text ${s.time}`}
          >
            {convertToMin(time)}
          </span>
        </span>
      );
    }

    return null;
  };

  render = () => (
    <div className={`single_page__header ${s.container}`}>
      <div className="single_page__header__type">{this.getHeaderIcon()}</div>
      <div className={`single_page__header__context ${s.header}`}>
        {this.getHeaderTitle()}
        <div className={`single_page__header__context__info ${s.context}`}>
          {this.getContext()}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(SinglePageHeader);
