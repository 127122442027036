export enum FastingTimerFormat {
  full = 'full',
  medium = 'medium',
}

export type FastingCycle = {
  fasting: number;
  eating: number;
};

export const FASTING_CYCLE_HOURS: { [key: string]: FastingCycle } = {
  beginner: {
    fasting: 13,
    eating: 11,
  },
  popular: {
    fasting: 16,
    eating: 8,
  },
  intermediate: {
    fasting: 18,
    eating: 6,
  },
};

export type FastingData = {
  isLoading: boolean;
  hasError: boolean;
  fasting: Fasting;
};

export type Fasting = {
  cycle: Cycle;
  timer: Timer;
};

export type Cycle = {
  id: number;
  user_id: number;
  fasting: number;
  eating: number;
  state: string;
};

export type Timer = {
  id: number;
  user_id: number;
  type: string;
  start_time: string;
  end_time: string;
  time_in_the_cycle: number;
};

export enum FastingBodyStatus {
  sugarRise = 'sugar_rises',
  sugarDrop = 'sugar_drops',
  sugarSettle = 'sugar_settles',
  fatBurn = 'fat_burn',
  ketosis = 'ketosis',
}

export enum FastingTimerType {
  fasting = 'fasting',
  eating = 'eating',
}
