import React from 'react';

import { SVG } from 'types/svg';

function Percent(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.16 9.87a4.94 4.94 0 1 0-4.93-4.94 5 5 0 0 0 4.93 4.94zm0-8.2A3.27 3.27 0 1 1 3.9 4.93a3.27 3.27 0 0 1 3.26-3.26zM16.84 14.13a4.94 4.94 0 1 0 4.93 4.94 4.94 4.94 0 0 0-4.93-4.94zm0 8.2a3.27 3.27 0 1 1 3.26-3.26 3.28 3.28 0 0 1-3.26 3.26zM23.76.24a.86.86 0 0 0-1.19 0L.24 22.57a.86.86 0 0 0 0 1.19.86.86 0 0 0 1.19 0L23.76 1.43a.86.86 0 0 0 0-1.19z" />
    </svg>
  );
}

export default Percent;
