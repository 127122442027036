export type UnsubscribeLoggerStartSessionPayload = {
  version: string;
  subscription_id: number;
};

export type UnsubscribeStartSessionResponse = {
  id: number;
  version: string;
};

export type UnsubscribePushSessionPayload = {
  step: string;
  action: string;
  comment?: string;
  reason?: string;
};

export type UnsubscribeLogActionProps = {
  flowId: number;
  flowPayload: UnsubscribePushSessionPayload;
};

export enum UNUSBSCRIBE_FLOW_VERSIONS {
  'v6.0' = 'v6.0', // Represents old flow: v3->v6->v4 || v3->v4
  'v8.1' = 'v8.1', // Represents new flow: v3->v6->v8(recap)->v4 || v3->v8(recap)->v4
  'v8.2' = 'v8.2', // Represents new flow: v3->v6->v8(recap)->v8(free-offer)->v4 || v3->v8(recap)->v8(free-offer)->v4
  'v8.3' = 'v8.3', // Represents new flow: v3->pausesubscription->v6->v8(recap)->v4 || unpausesubscription->v3->v6->v8(recap)->v4
}

export enum UNUSBSCRIBE_FLOW_ACTIONS {
  exit = 'exit',
  continue = 'continue',
  convinced = 'convinced',
  submit = 'submit',
  open = 'open',
  update_plan = 'update_plan',
  unsubscribe = 'unsubscribe',
  answer = 'answer',
  pause = 'pause',
  unpause = 'unpause',
  keepPause = 'keep-pause',
  charge = 'charge',
}

export const UNSUBSCRIBE_V3_STEP = 'unsubscribev3';
export const UNSUBSCRIBE_V3_COMMENTS = {
  close: 'close',
  openChat: 'open_chat',
};

export const UNSUBSCRIBE_LOCKED_MODAL = 'lockedmodal';

export const UNSUBSCRIBE_V8_EXIT = {
  backBtn: 'back_to_app',
  keepBtn: 'keep_losing_weight',
};

export const UNSUBSCRIBE_V8_LINK_BUTTON: { [key: string]: string } = {
  weight_card_2: 'log_weight',
  weight_card_3: 'adjust_plan',
  meals_card_2: 'see_meals',
  meals_card_3: 'adjust_recipes',
  premium_card_1: 'open_facts',
  premium_card_2: 'open_tricks',
  shoppinglist_card_1: 'add_shoppinglist',
};

export const UNSUBSCRIBE_V8_FREE_OFFER_COMMENTS: { [key: string]: string } = {
  firstAcceptButton: 'claimed_offer_b1',
  secondAcceptButton: 'claimed_offer_b2',
};

export const PAUSE_SUBSCRIPTION_STEP = 'pause-subscription-page';
export const UNPAUSE_SUBSCRIPTION_STEP = 'unpause-subscription-page';
export const CANCELLATION_FEE_START = 'start-early-cancellation';
export const CANCELLATION_FEE = 'cancellation-fee-final-warning';
export const CANCELLATION_FEE_MODAL_SUCCESS = 'cancellation-fee-paid';
export const CANCELLATION_FEE_MODAL_FAIL = 'cancellation-fee-unpaid';
