import React from 'react';

import { SVG } from 'types/svg';

function EmailFasting(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="24" height="24" {...props}>
      <defs>
        <path
          d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z"
          id="prefix__e5128cb5-aed2-4d4d-b096-1fae37bcc562"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="none" d="M0 0h24v24H0z" />
        <use
          fill="#03cea4"
          fillRule="nonzero"
          xlinkHref="#prefix__e5128cb5-aed2-4d4d-b096-1fae37bcc562"
        />
      </g>
    </svg>
  );
}

export default EmailFasting;
