import * as t from 'actions/actionTypes';
import { FastingActionTypes } from 'actions/actionTypes';
import initialState from 'reducers/initialState';
import { FastingData } from 'types/fasting';

export default (state = initialState.fasting, action: FastingActionTypes) => {
  switch (action.type) {
    case t.FASTING_LOAD:
    case t.START_FASTING_LOAD:
    case t.START_EATING_LOAD:
      return { ...state, isLoading: true };
    case t.FASTING_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        fasting: action.fasting,
      };
    case t.START_FASTING_SUCCESS:
    case t.START_EATING_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        fasting: { ...(state as FastingData).fasting, timer: action.timer },
      };
    case t.FASTING_CYCLE_STORE:
      return {
        isLoading: false,
        hasError: false,
        fasting: { ...(state as FastingData).fasting, cycle: action.cycle },
      };
    case t.FASTING_ERROR:
    case t.START_FASTING_ERROR:
    case t.START_EATING_ERROR:
      return { ...state, isLoading: false, hasError: true };
    case t.LOG_OUT.SUCCESS:
      return initialState.fasting;
    default:
      return state;
  }
};
