import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import { getStyle, isMaterialUI, isPrimaryTheme } from 'helpers/themeHelper';
import { SVGFillColors } from 'types/svg';

import SvgIcon from 'components/common/SvgIcon';
import { Button as MuiButton } from '@material-ui/core';

import base from './styles/Button.module.scss';
import diet from './styles/Button_diet.module.scss';
import keto from './styles/Button_keto.module.scss';
import ketogo from './styles/Button_ketogo.module.scss';
import fasting from './styles/Button_fasting.module.scss';
import healthgenom from './styles/Button_healthgenom.module.scss';

const s = getStyle(base, diet, keto, ketogo, fasting, healthgenom);

class Button extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    buttonStyle: PropTypes.string,
    labelText: PropTypes.string,
    iconColor: PropTypes.string,
    className: PropTypes.string,
    svgIcon: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
  };

  static defaultProps = {
    iconColor: 'blue',
    className: 'btn__default_blue icon',
  };

  getButtonLabel = () => {
    const { t, type, label, labelText, iconColor, className, svgIcon } =
      this.props;

    const domain = t('domains:domain');

    if (type === 'image-text') {
      return (
        <div>
          {!isPrimaryTheme() && (
            <SvgIcon
              filename={svgIcon}
              fill={SVGFillColors[iconColor]}
              className={className}
            />
          )}
          {t(`user.profile.${label}`, domain)}
        </div>
      );
    }

    if (!isEmpty(labelText)) {
      return labelText;
    }

    return t(`globals.${label}`);
  };

  render = () => {
    const {
      buttonStyle,
      onClick,
      position,
      muiVariant = 'outlined',
      href,
      target,
    } = this.props;

    if (isMaterialUI()) {
      const getButtonClassNameForTheme = () => {
        if (buttonStyle === 'ketoButton') {
          return s.ketoButton;
        }
        if (buttonStyle === 'fastingButton') {
          return s.fastingButton;
        }

        return null;
      };

      const linkProps = {
        ...(href && { href }),
        ...(target && { target }),
      };

      return (
        <MuiButton
          fullWidth={!position}
          variant={muiVariant}
          className={
            getButtonClassNameForTheme() ||
            s[position] ||
            s[muiVariant] ||
            s.button
          }
          onClick={onClick}
          color="secondary"
          {...linkProps}
        >
          {this.getButtonLabel()}
        </MuiButton>
      );
    }

    return (
      <button
        className={`btn__default ${buttonStyle} ${s.button}`}
        onClick={onClick}
        type="button"
      >
        {this.getButtonLabel()}
      </button>
    );
  };
}

export default withTranslation('domains')(Button);
