import { PurchaseItem } from './offer';

export type PrimerSession = {
  url: string;
  invoice: PrimerInvoice;
};

export type Test = {
  sss: string;
};

export type PrimerPayment = {
  action_required: boolean;
  client_token: string;
  invoice: PrimerInvoice;
};

export type PrimerInvoice = {
  card: PrimerCard;
  cart_id: number;
  cart_type: string;
  chance: boolean;
  coloring_id: number;
  completed_at: string;
  created_by: number;
  custom_data: any[];
  ga_cid: string;
  id: number;
  identifier: string;
  instantly_charged: boolean;
  ip: string;
  mid: null;
  offer_id: number;
  payment_result_message_id: number;
  paymode: CardPaymode;
  paypal_account_id: number;
  paypal_order_id: number;
  provider_result: any;
  purchase_item: PurchaseItem;
  session_identifier: string;
  session_time_left: number;
  status: number;
  stripe_subscription_id: number;
  user_id: number;
};

export type PrimerCard = {
  id: number;
  last_digits: string;
  paymode: CardPaymode;
  tos_accepted_at: string;
};

export type CardPaymode = {
  id: number;
  name: string;
};

export type MetaData = {
  origin_host: string;
  chance: boolean;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  purchase_source?: string;
  shipping_address_id?: string;
  failed_payment_path?: string;
  ga_session_id?: number;
  offer_price_id?: number;
};

export enum PrimerInitEventType {
  chosePaymode = 'offer_chose_paymode',
  offerRetried = 'offer_retried',
}
