import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from 'components/common';

import { GenomPlusSurveyUiContextType } from './types';
import GenomPlusSurveyContext from './GenomPlusSurveyContext';

import s from './GenomPlusSurvey.module.scss';

const CompletedArt = () => (
  <svg
    width="148"
    height="148"
    viewBox="0 0 148 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="73.9996" cy="74" r="47.36" fill="#2A9D8F" />
    <g clipPath="url(#clip0_3585_3895)">
      <path
        d="M89.0818 62.9717C90.0178 63.8871 90.0178 65.3736 89.0818 66.289L69.9123 85.0356C68.9763 85.951 67.4562 85.951 66.5202 85.0356L56.9354 75.6623C55.9994 74.7469 55.9994 73.2604 56.9354 72.345C57.8714 71.4297 59.3915 71.4297 60.3275 72.345L68.22 80.0561L85.6972 62.9717C86.6332 62.0563 88.1533 62.0563 89.0893 62.9717H89.0818Z"
        fill="white"
      />
    </g>
    <path
      d="M73.0133 0.986666C73.0133 0.441745 73.455 0 74 0C74.5449 0 74.9866 0.441746 74.9866 0.986666V17.76C74.9866 18.3049 74.5449 18.7467 74 18.7467C73.455 18.7467 73.0133 18.3049 73.0133 17.76V0.986666Z"
      fill="#B2DBD8"
    />
    <path
      d="M73.0133 130.24C73.0133 129.695 73.455 129.253 74 129.253C74.5449 129.253 74.9866 129.695 74.9866 130.24V147.013C74.9866 147.558 74.5449 148 74 148C73.455 148 73.0133 147.558 73.0133 147.013V130.24Z"
      fill="#B2DBD8"
    />
    <path
      d="M0.986666 74.9866C0.441745 74.9866 0 74.5449 0 74C0 73.455 0.441746 73.0133 0.986666 73.0133H17.76C18.3049 73.0133 18.7467 73.455 18.7467 74C18.7467 74.5449 18.3049 74.9866 17.76 74.9866H0.986666Z"
      fill="#B2DBD8"
    />
    <path
      d="M130.24 74.9866C129.695 74.9866 129.253 74.5449 129.253 74C129.253 73.455 129.695 73.0133 130.24 73.0133H147.013C147.558 73.0133 148 73.455 148 74C148 74.5449 147.558 74.9866 147.013 74.9866H130.24Z"
      fill="#B2DBD8"
    />
    <path
      d="M21.6741 23.0694C21.2888 22.6841 21.2888 22.0594 21.6741 21.6741C22.0594 21.2887 22.6841 21.2887 23.0695 21.6741L34.93 33.5346C35.3153 33.9199 35.3153 34.5446 34.93 34.93C34.5447 35.3153 33.9199 35.3153 33.5346 34.93L21.6741 23.0694Z"
      fill="#B2DBD8"
    />
    <path
      d="M113.07 114.465C112.685 114.08 112.685 113.455 113.07 113.07C113.455 112.685 114.08 112.685 114.465 113.07L126.326 124.93C126.711 125.316 126.711 125.941 126.326 126.326C125.941 126.711 125.316 126.711 124.93 126.326L113.07 114.465Z"
      fill="#B2DBD8"
    />
    <path
      d="M23.0695 126.326C22.6841 126.711 22.0594 126.711 21.6741 126.326C21.2888 125.941 21.2888 125.316 21.6741 124.93L33.5346 113.07C33.9199 112.685 34.5447 112.685 34.93 113.07C35.3153 113.455 35.3153 114.08 34.93 114.465L23.0695 126.326Z"
      fill="#B2DBD8"
    />
    <path
      d="M114.465 34.93C114.08 35.3153 113.455 35.3153 113.07 34.93C112.685 34.5446 112.685 33.9199 113.07 33.5346L124.93 21.6741C125.316 21.2887 125.941 21.2887 126.326 21.6741C126.711 22.0594 126.711 22.6841 126.326 23.0694L114.465 34.93Z"
      fill="#B2DBD8"
    />
    <defs>
      <clipPath id="clip0_3585_3895">
        <rect
          width="33.5467"
          height="37.4933"
          fill="white"
          transform="translate(56.2393 55.2533)"
        />
      </clipPath>
    </defs>
  </svg>
);

function GenomPlusSurveyCompleted() {
  const { t } = useTranslation('genomplus');
  const { handleStepComplete } = useContext<GenomPlusSurveyUiContextType>(
    GenomPlusSurveyContext
  );

  return (
    <>
      <div className={`${s.stepContent} ${s.contentCompleted}`}>
        <div className={s.completedArt}>
          <CompletedArt />
        </div>

        <div className={s.title}>{t(`survey.completed.title`)}</div>

        <div className={s.subtitle}>{t(`survey.completed.subtitle`)}</div>
      </div>

      <div className={s.stepSubmit}>
        <SubmitButton
          className={s.stepSubmitButton}
          label={t('survey.completed.got_it')}
          type="large"
          onClick={() => handleStepComplete()}
        />
      </div>
    </>
  );
}

export default GenomPlusSurveyCompleted;
