import React from 'react';

import { SVG } from 'types/svg';

function Nutrition(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M7.66 3.9A2.28 2.28 0 0 0 8 2.82V2.2A2.22 2.22 0 0 0 5.72 0h-1a2.22 2.22 0 0 0-2.17 2.2v.62a2.17 2.17 0 0 0 .29 1.07A4.12 4.12 0 0 0 .39 7.64v15.5a.76.76 0 0 0 .76.76h8.2a.77.77 0 0 0 .77-.76V7.64a4.12 4.12 0 0 0-2.45-3.75zM4.08 2.2a.69.69 0 0 1 .69-.69h1a.69.69 0 0 1 .7.69v.62a.69.69 0 0 1-.69.69h-1a.69.69 0 0 1-.69-.69zM4.56 5h1.38a2.63 2.63 0 0 1 2.64 2.64v3.6a4 4 0 0 1-1.73-1 3.61 3.61 0 0 0-2.4-1.1 4.48 4.48 0 0 0-2.53.76V7.64A2.63 2.63 0 0 1 4.56 5zM1.92 22.4v-9.86c0-1 1.13-1.88 2.53-1.88.5 0 .84.26 1.4.74a5.1 5.1 0 0 0 2.72 1.37v9.63zm18.55-8.98a2.84 2.84 0 0 0-2.32 0l-.37.1c-.3-2.65-1.9-3.08-2.88-3.08a.76.76 0 0 0-.77.76.75.75 0 0 0 .77.75c.36 0 1.14 0 1.35 1.57l-.33-.07a3.11 3.11 0 0 0-2.48.07 5.63 5.63 0 0 0 1.33 10.39 1.83 1.83 0 0 0 .53.07 6.53 6.53 0 0 0 1.06-.13 4.77 4.77 0 0 1 .71-.1 5.51 5.51 0 0 1 .75.07 4.92 4.92 0 0 0 1.88 0 5.45 5.45 0 0 0 3.91-5.45 5.66 5.66 0 0 0-3.14-4.95zm-1.23 9a4.17 4.17 0 0 1-1.25 0 6.28 6.28 0 0 0-1.9.05 3.35 3.35 0 0 1-.92.1 4.1 4.1 0 0 1-3.12-4.12 4.14 4.14 0 0 1 2.17-3.52.75.75 0 0 1 .37-.08 4.25 4.25 0 0 1 .95.17 6.35 6.35 0 0 0 1.53.25 5.4 5.4 0 0 0 1.52-.29c.56-.17.92-.27 1.15-.14a4.14 4.14 0 0 1 2.34 3.61 3.94 3.94 0 0 1-2.84 3.92z" />
    </svg>
  );
}

export default Nutrition;
