import React from 'react';

import { SVG } from 'types/svg';

function FlagDiet(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <defs>
        <path
          id="m4zrh7dima"
          d="M14.4 6L14 4 5 4 5 21 7 21 7 14 12.6 14 13 16 20 16 20 6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-16.000000, -1030.000000) translate(16.000000, 1030.000000)">
            <mask id="ftwha8b4ab" fill="#fff">
              <use xlinkHref="#m4zrh7dima" />
            </mask>
            <g fill="#000" fillOpacity="0.54" mask="url(#ftwha8b4ab)">
              <path d="M0 0H24V24H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FlagDiet;
