import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (state = initialState.allMessagesLoaded, action) => {
  switch (action.type) {
    case types.CHAT_ALL_MESSAGES_LOADED:
      return true;

    case types.CHAT_INITIAL_MESSAGES.SUCCESS:
    case types.CHAT_OLD_MESSAGES.SUCCESS:
      return !action.payload.loadMore;

    case types.LOG_OUT.SUCCESS:
      return initialState.allMessagesLoaded;

    default:
      return state;
  }
};
