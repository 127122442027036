import i18n from 'i18n';
import { addError } from 'redux-flash-messages';

import { UnsubscribeReasons } from 'types/globalData';
import { ThunkActionType } from 'store/types';
import * as t from 'actions/actionTypes';

import { getUnsubscribeReasons } from 'api/globalDataAPI';

export const globalDataLoad = () => ({
  type: t.GLOBAL_DATA_LOAD,
});

export const globalDataUnsubscribeReasonsSuccess = (
  unsubscribeReasons: UnsubscribeReasons
) => ({
  type: t.GLOBAL_DATA_SUCCESS_UNSUBSCRIBE_REASONS,
  unsubscribeReasons,
});

export const globalDataError = () => ({
  type: t.GLOBAL_DATA_ERROR,
});

export const retrieveUnsubscribeReasons = (): ThunkActionType => (dispatch) => {
  dispatch(globalDataLoad());
  return getUnsubscribeReasons()
    .then((response) => {
      if (!response) throw Error();

      dispatch(globalDataUnsubscribeReasonsSuccess(response));
    })
    .catch(() => {
      const text = i18n.t('error.500_error');
      addError({ text });
      dispatch(globalDataError());
    });
};
