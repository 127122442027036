export enum UpsellTypes {
  shoppingList = 'shopping_list',
  fatBurner = 'fat_burner',
  pro = 'pro_subscription',
  premium = 'premium_upsell',
  collagen = 'collagen',
  premiumShoppingListBundle = 'premium_shopping_list_bundle',
  chat = 'chat',
  acvGummies = 'acv_gummies',
  genom = 'genom',
  workouts = 'workouts',
  ketoChocolateBars = 'keto_chocolate_bars',
}

export enum UpsellOfferTypes {
  registration = 'registration',
  mealSurvey = 'meal_survey',
  universal = 'universal',
  lockedChat = 'locked_chat',
  universalChat = 'universal_chat',
  profileGenom = 'profile_genom',
  profile = 'profile',
}

export type ChatUpsellOfferType =
  | 'registration_chat'
  | 'email_chat'
  | 'locked_chat'
  | 'universal_chat'
  | 'locked_chat_imitation_flow'
  | 'universal_chat_imitation_flow'
  | 'locked_chat_regular_flow'
  | 'universal_chat_regular_flow';

export enum UpsellUtms {
  utmSource = 'utm_source',
  utmMedium = 'utm_medium',
  utmCampaign = 'utm_campaign',
}

export type Upsell = {
  id: number;
  type: UpsellTypes;
};

export type UpsellList = {
  [key: number]: Upsell;
};

export type UpsellLocationState = {
  // List of origins from where redirect has happened.
  // Needed to determine required types of analytical data
  // which differ for the same pages depending on the user flow
  origin: 'locked_chat_imitation' | 'locked_chat';
};

export type UpsellOfferUtmsList = {
  [key in UpsellOfferTypes]?: {
    [key in UpsellUtms]: string;
  };
};

export type UpsellOffersUtmsList = {
  [key in UpsellTypes]: UpsellOfferUtmsList;
};
