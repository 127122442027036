import React from 'react';

function PaymentPaydoo() {
  return (
    <svg
      width={67}
      height={20}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="80.125%"
          y1="50%"
          x2="34.453%"
          y2="93.068%"
          id="prefix__51c09040-7e4b-4a5a-a84a-10e6db205ad8"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop offset="100%" />
        </linearGradient>
        <path
          d="M49.995 7.16c-1.334 0-2.41 1.27-2.41 2.835 0 1.575 1.076 2.846 2.41 2.846s2.42-1.271 2.42-2.846c0-1.564-1.086-2.835-2.42-2.835zm0-2.311a5.15 5.15 0 0 1 5.157 5.146 5.159 5.159 0 0 1-5.157 5.157 5.15 5.15 0 0 1-5.146-5.157 5.141 5.141 0 0 1 5.146-5.146zm11.515 2.31c-1.334 0-2.419 1.272-2.419 2.836 0 1.575 1.085 2.846 2.42 2.846 1.333 0 2.418-1.271 2.418-2.846 0-1.564-1.085-2.835-2.419-2.835zm0-2.31a5.15 5.15 0 0 1 5.157 5.146 5.159 5.159 0 0 1-5.157 5.157 5.15 5.15 0 0 1-5.146-5.157 5.141 5.141 0 0 1 5.146-5.146zm-23.025 2.48c-1.331 0-2.418 1.244-2.418 2.775 0 1.541 1.087 2.786 2.418 2.786 1.33 0 2.418-1.245 2.418-2.786 0-1.53-1.087-2.775-2.418-2.775zm0-2.262c.945 0 1.829.247 2.58.682V1.185c0-.652.55-1.185 1.22-1.185h1.32v9.59c.021.169.031.346.031.514 0 2.786-2.306 5.048-5.151 5.048-2.845 0-5.151-2.262-5.151-5.048 0-2.785 2.306-5.037 5.15-5.037zM5.095 7.113c-1.351 0-2.45 1.247-2.45 2.78 0 1.542 1.099 2.788 2.449 2.788 1.35 0 2.449-1.246 2.449-2.789 0-1.532-1.099-2.779-2.449-2.779zm0-2.264c2.88 0 5.208 2.254 5.208 5.043 0 2.79-2.327 5.054-5.209 5.054a5.319 5.319 0 0 1-2.57-.652v4.519A1.2 1.2 0 0 1 1.315 20H.005V9.675c0-.23 0-.48-.004-.697a1.109 1.109 0 0 1 .032-.305c.57-2.199 2.618-3.824 5.061-3.824zm11.036 2.31c-1.35 0-2.45 1.272-2.45 2.836 0 1.575 1.1 2.846 2.45 2.846 1.351 0 2.44-1.271 2.44-2.846 0-1.564-1.089-2.835-2.44-2.835zm5.081 7.69h-1.864a.623.623 0 0 1-.585-.404 5.184 5.184 0 0 1-2.631.706c-2.883 0-5.222-2.31-5.222-5.156 0-2.845 2.339-5.146 5.222-5.146.968 0 1.875.261 2.651.716a.638.638 0 0 1 .565-.363h1.865v9.647zm2.55-9.394h1.466c.47 0 .912.362 1.109.911l1.514 4.147a.464.464 0 0 1-.012.347l-1.067 2.38a.152.152 0 0 1-.285-.01L23.65 5.651c-.037-.108.01-.196.113-.196zm7.656 0h1.727a.19.19 0 0 1 .173.266l-6.085 14.002c-.105.241-.09.277-.566.277h-.476c-.297-.17-.744-.521-1.342-1.056l5.629-12.87a1.03 1.03 0 0 1 .94-.62z"
          id="prefix__43698a27-cc92-4a18-9d92-52d3e0d2e400"
        />
      </defs>
      <g fill="none" fillRule="evenodd" opacity={0.38}>
        <path
          d="M49.995 7.16c-1.334 0-2.41 1.27-2.41 2.835 0 1.575 1.076 2.846 2.41 2.846s2.42-1.271 2.42-2.846c0-1.564-1.086-2.835-2.42-2.835zm0-2.311a5.15 5.15 0 0 1 5.157 5.146 5.159 5.159 0 0 1-5.157 5.157 5.15 5.15 0 0 1-5.146-5.157 5.141 5.141 0 0 1 5.146-5.146zm11.515 2.31c-1.334 0-2.419 1.272-2.419 2.836 0 1.575 1.085 2.846 2.42 2.846 1.333 0 2.418-1.271 2.418-2.846 0-1.564-1.085-2.835-2.419-2.835zm0-2.31a5.15 5.15 0 0 1 5.157 5.146 5.159 5.159 0 0 1-5.157 5.157 5.15 5.15 0 0 1-5.146-5.157 5.141 5.141 0 0 1 5.146-5.146zm-23.025 2.48c-1.331 0-2.418 1.244-2.418 2.775 0 1.541 1.087 2.786 2.418 2.786 1.33 0 2.418-1.245 2.418-2.786 0-1.53-1.087-2.775-2.418-2.775zm0-2.262c.945 0 1.829.247 2.58.682V1.185c0-.652.55-1.185 1.22-1.185h1.32v9.59c.021.169.031.346.031.514 0 2.786-2.306 5.048-5.151 5.048-2.845 0-5.151-2.262-5.151-5.048 0-2.785 2.306-5.037 5.15-5.037zM5.095 7.113c-1.351 0-2.45 1.247-2.45 2.78 0 1.542 1.099 2.788 2.449 2.788 1.35 0 2.449-1.246 2.449-2.789 0-1.532-1.099-2.779-2.449-2.779zm0-2.264c2.88 0 5.208 2.254 5.208 5.043 0 2.79-2.327 5.054-5.209 5.054a5.319 5.319 0 0 1-2.57-.652v4.519A1.2 1.2 0 0 1 1.315 20H.005V9.675c0-.23 0-.48-.004-.697a1.109 1.109 0 0 1 .032-.305c.57-2.199 2.618-3.824 5.061-3.824zm11.036 2.31c-1.35 0-2.45 1.272-2.45 2.836 0 1.575 1.1 2.846 2.45 2.846 1.351 0 2.44-1.271 2.44-2.846 0-1.564-1.089-2.835-2.44-2.835zm5.081 7.69h-1.864a.623.623 0 0 1-.585-.404 5.184 5.184 0 0 1-2.631.706c-2.883 0-5.222-2.31-5.222-5.156 0-2.845 2.339-5.146 5.222-5.146.968 0 1.875.261 2.651.716a.638.638 0 0 1 .565-.363h1.865v9.647zm2.55-9.394h1.466c.47 0 .912.362 1.109.911l1.514 4.147a.464.464 0 0 1-.012.347l-1.067 2.38a.152.152 0 0 1-.285-.01L23.65 5.651c-.037-.108.01-.196.113-.196zm7.656 0h1.727a.19.19 0 0 1 .173.266l-6.085 14.002a.456.456 0 0 1-.621.23l-1.763-1.01 5.629-12.87c.165-.375.533-.618.94-.618z"
          fill="#000"
          fillRule="nonzero"
        />
        <mask id="prefix__99fe9486-ab19-4148-bfcb-ba4fab30ea19" fill="#fff">
          <use xlinkHref="#prefix__43698a27-cc92-4a18-9d92-52d3e0d2e400" />
        </mask>
        <path
          d="M25.784 12.9c.8 0 1.601.62 2.075 1.494L30.73 19c.131.236-.686.981-.962.979L27.143 20c-.18-.002-1.346.155-1.429 0l-2.641-6.782c-.099-.175-.017-.318.163-.318h2.548z"
          fill="url(#prefix__51c09040-7e4b-4a5a-a84a-10e6db205ad8)"
          fillRule="nonzero"
          opacity={0.3}
          mask="url(#prefix__99fe9486-ab19-4148-bfcb-ba4fab30ea19)"
        />
        <path
          d="M24.081 15.714c.494 0 .988.376 1.28.905l1.773 2.788c.081.143-.423.595-.594.593h-1.725a.302.302 0 0 1-.262-.154l-2.146-3.94c-.06-.105-.01-.192.1-.192h1.574z"
          fill="#000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default PaymentPaydoo;
