import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getCurrentLocale, getLocaleDirection } from 'i18n';
import { Modal, TextField, InputAdornment } from '@material-ui/core';
import { getMonthDate } from 'helpers/timeHelper';
import { getCurrentFormattedDate } from 'helpers/dateHelper';
import { getCurrentTheme, getStyle, isHealthgenom } from 'helpers/themeHelper';
import browserHistory from 'helpers/history';
import { createDiaryEntryAction } from 'actions/programActions';
import { User } from 'types/user';
import { getCurrentDateFnsLocale } from 'helpers/localeHelper';
import { SubmitButton } from 'components/common';

import diet from './DiaryUnskippable.module.scss';
import keto from './DiaryUnskippable_keto.module.scss';
import ketogo from './DiaryUnskippable_ketogo.module.scss';
import fasting from './DiaryUnskippable_fasting.module.scss';
import healthgenom from './DiaryUnskippable_healthgenom.module.scss';

const s = getStyle({}, diet, keto, ketogo, fasting, healthgenom);

type Props = {
  user: User;
  day: any;
};

type FormData = {
  weight: string;
};

const DiaryUnskippable: React.FC<Props> = ({ user, day }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locale = getCurrentLocale();
  const theme = getCurrentTheme();
  const { handleSubmit, register, errors } = useForm<FormData>();

  const onSubmit = (values: FormData) => {
    const userData = {
      weight: parseInt(values.weight, 10),
      unit_system: user.unit_system,
    };
    browserHistory.replace('/program');

    dispatch(createDiaryEntryAction({ diary_entry: userData }, day.id));
  };

  const addInputLabelProps = () => {
    if (!isHealthgenom()) return { classes: {} };

    return {
      classes: { focused: s.focusedLabel },
    };
  };

  const usesSiSystem = user.unit_system === 'si';
  const dayNameDateFormat = ['lt', 'ru'].includes(locale) ? 'EEEEE' : 'cccc';

  const getCustomDateClass = () => {
    if (theme === 'keto' || theme === 'ketogo') {
      const longerLocaleDate = ['uk'];
      if (longerLocaleDate.includes(locale)) {
        return s.dateLonger;
      }
    }

    return '';
  };

  return (
    <Modal open>
      <form
        className={s.container}
        onSubmit={handleSubmit(onSubmit)}
        dir={getLocaleDirection()}
      >
        <div className={s.calendar}>
          <span className={s.dayName}>
            {getCurrentFormattedDate(
              dayNameDateFormat,
              getCurrentDateFnsLocale()
            )}
          </span>
          <span className={`${s.date} ${getCustomDateClass()}`}>
            {getMonthDate(day.date)}
          </span>
          <div className={s.events}>
            <span className={s.event} />
            <span className={s.event} />
            <span className={s.event} />
            <span className={s.event} />
            <span className={s.event} />
          </div>
        </div>
        <p className={s.text}>
          {t('modals.diary.step_1.intro', { name: user.first_name })}
        </p>
        <h2 className={s.question}>{t('modals.diary.step_1.title')}</h2>
        <div className={s.inputs}>
          <TextField
            variant="outlined"
            type="number"
            name="weight"
            className={s.input}
            label={t('modals.diary.step_1.weight')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t(`units.weight_${user.unit_system}`)}
                </InputAdornment>
              ),
            }}
            InputLabelProps={addInputLabelProps()}
            error={!!errors.weight}
            inputProps={{
              required: true,
              inputMode: 'numeric',
              pattern: '[0-9]*',
              min: usesSiSystem ? 30 : 66,
              max: usesSiSystem ? 300 : 660,
            }}
            inputRef={register}
          />
          <div className={s.submitBtn}>
            <SubmitButton
              disabled={!!errors.weight}
              label={t('globals.next')}
              type="large"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default DiaryUnskippable;
