import React from 'react';

function Chat() {
  return (
    <svg
      width={16}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M13.333 1.333H2.667c-.734 0-1.327.6-1.327 1.334v12L4 12h9.333c.734 0 1.334-.6 1.334-1.333v-8c0-.734-.6-1.334-1.334-1.334zM4 6h8v1.333H4V6zm5.333 3.333H4V8h5.333v1.333zm2.667-4H4V4h8v1.333z"
          id="a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <use fill="#2A9D8F" fillRule="nonzero" xlinkHref="#a" />
      </g>
    </svg>
  );
}

export default Chat;
