/* eslint-disable react/self-closing-comp */
import React from 'react';

import { FileExtension } from 'helpers/imageHelper';

import { ResponsiveImage } from 'components/common';

import s from './ExpressPlanCarouselItem.module.scss';

type Props = {
  title: string;
  firstText: string;
  secondText?: string | React.ReactNode;
  imagePath: string;
  altText: string;
  itemIndex: number;
  activeItemIndex: number;
  onClick?: () => void;
};

function ExpressPlanCarouselItem({
  title,
  firstText,
  secondText,
  imagePath,
  altText,
  itemIndex,
  activeItemIndex,
  onClick,
}: Props) {
  const isActiveItem = itemIndex === activeItemIndex;

  return (
    <button
      type="button"
      className={`${s.carouselItem} ${isActiveItem && s.activeItem}`}
      onClick={onClick}
    >
      <ResponsiveImage
        imagePath={imagePath}
        imageExtension={FileExtension.png}
        altText={altText}
        style={`${s.image} ${isActiveItem && s.activeImage}`}
      />
      <p className={`${s.title} ${isActiveItem && s.activeTitle}`}>{title}</p>
      <p className={`${s.text} ${isActiveItem && s.activeText}`}>{firstText}</p>
      {itemIndex !== 5 && (
        <p className={`${s.text} ${isActiveItem && s.activeText}`}>
          {secondText}
        </p>
      )}
      {!isActiveItem && <div className={s.gradient}></div>}
    </button>
  );
}

export default ExpressPlanCarouselItem;
