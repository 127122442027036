import React from 'react';

function UnsubscribeV6MealSurveyMonacofit() {
  return (
    <svg width={56} height={56} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle fill="#60BD78" cx={28} cy={28} r={28} />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M23.69 32.405a.625.625 0 001.12-.56 2.933 2.933 0 00-2.435-1.595 2.933 2.933 0 00-2.434 1.595.625.625 0 001.116.563 1.705 1.705 0 011.318-.908 1.703 1.703 0 011.316.905zm9.935-2.155a2.933 2.933 0 00-2.434 1.595.625.625 0 001.116.563 1.705 1.705 0 011.318-.908 1.703 1.703 0 011.318.908.625.625 0 001.116-.563 2.933 2.933 0 00-2.434-1.595z" />
          <path d="M35.548 20.25h-.052c-2.975.02-5.71 1.83-7.496 4.9-1.786-3.07-4.521-4.88-7.496-4.9h-.052a9.123 9.123 0 00-6.333 2.736 10.155 10.155 0 00-2.994 7.264c0 9.234 15.892 18.289 16.569 18.67.19.107.422.107.612 0 .677-.381 16.57-9.436 16.57-18.67a10.155 10.155 0 00-2.995-7.264 9.12 9.12 0 00-6.333-2.736zM28 47.652c-2.215-1.309-15.625-9.559-15.625-17.402A8.913 8.913 0 0115 23.873a7.865 7.865 0 015.452-2.373h.044c2.815.018 5.408 1.987 6.938 5.264a.625.625 0 001.132 0c1.53-3.277 4.123-5.246 6.938-5.264h.044A7.865 7.865 0 0141 23.873a8.913 8.913 0 012.625 6.377c0 7.843-13.41 16.093-15.625 17.402z" />
          <path d="M18.345 23.44c-.167.085-4.095 2.089-4.095 5.56a.625.625 0 101.25 0c0-2.695 3.371-4.424 3.405-4.442a.625.625 0 00-.56-1.117zm-4.095 7.435h1.25v1.25h-1.25zm19.818 6.435a.625.625 0 00-.884-.002c-.023.023-2.364 2.317-5.184 2.317-2.82 0-5.16-2.294-5.184-2.317a.625.625 0 00-.883.884c.11.11 2.722 2.683 6.067 2.683a6.9 6.9 0 001.412-.154l.465.93a2.302 2.302 0 004.118-2.059l-.436-.872a.585.585 0 00-.036-.046c.324-.265.516-.454.544-.482a.624.624 0 000-.883zm-1.191 2.841a1.052 1.052 0 01-1.882.94l-.364-.727c.135-.052.268-.105.397-.16l.163.326a.625.625 0 101.118-.56l-.167-.335c.127-.075.248-.15.364-.225l.37.741zM43 10.125C43 8.402 41.879 7 40.5 7S38 8.402 38 10.125a3.075 3.075 0 001.875 3.014v4.486a.625.625 0 101.25 0v-4.486A3.075 3.075 0 0043 10.125zM40.5 12c-.677 0-1.25-.859-1.25-1.875s.573-1.875 1.25-1.875c.678 0 1.25.859 1.25 1.875S41.178 12 40.5 12zm-6.875.625v5a.625.625 0 101.25 0v-5a1.877 1.877 0 001.875-1.875V7.625a.625.625 0 10-1.25 0v3.125c0 .345-.28.625-.625.625v-3.75a.625.625 0 10-1.25 0v3.75A.625.625 0 0133 10.75V7.625a.625.625 0 10-1.25 0v3.125a1.877 1.877 0 001.875 1.875zM15.125 14a.625.625 0 00.625-.625v-1.25a.625.625 0 00-1.25 0v1.25a.625.625 0 00.625.625zm.625 3.125v-1.25a.625.625 0 10-1.25 0v1.25a.625.625 0 101.25 0zm.625-1.875h1.25a.625.625 0 100-1.25h-1.25a.625.625 0 000 1.25zm-2.5-1.25h-1.25a.625.625 0 100 1.25h1.25a.625.625 0 100-1.25zm10 3.75a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zm0-2.5a.625.625 0 110 1.25.625.625 0 010-1.25z" />
        </g>
      </g>
    </svg>
  );
}

export default UnsubscribeV6MealSurveyMonacofit;
