import isEmpty from 'lodash/isEmpty';
import { PaymentType } from 'types/offer';
import { UserState } from 'types/user';
import settings from 'helpers/settings';
import { isKeto, isKetoGo, isHealthgenom } from 'helpers/themeHelper';
import i18n, { getCurrentLocale } from '../i18n';
import { SubscriptionPlatform } from '../types/subscription';

export const isUserFree = (user) =>
  user.access && !user.access.includes('pro_subscription');

export const shouldSeeMealSurvey = (user) =>
  !isUserFree(user) && user.has_active_meal_survey;

export const userBodyPartFocus = (bodyParts) => {
  if (isEmpty(bodyParts)) {
    return {
      belly: '0',
      bottom: '0',
      chest: '0',
      hands_and_shoulders: '0',
      thighs: '0',
      shins: '0',
    };
  }
  return { ...bodyParts };
};

export const selectedBodyPartCount = (bodyParts) => {
  let count = 0;
  Object.values(bodyParts).forEach((bodyPart) => {
    if (Number(bodyPart) === 1) count += 1;
  });
  return count;
};

export const problemZoneString = (bodyPartsFocus) => {
  if (!bodyPartsFocus) return '';

  let result = '';
  Object.keys(bodyPartsFocus).forEach((key) => {
    if (bodyPartsFocus[key] === '1') {
      result += `${i18n.t(`user.profile.body_parts_focus.${key}`)}, `;
    }
  });
  return result.slice(0, -2); // Cutting off comma and whitespace
};

export const productString = (products) => {
  let returnString = '';
  products.forEach((product) => {
    returnString += `${product.title}, `;
  });
  if (returnString === '') {
    return '-';
  }
  return returnString.slice(0, -2);
};

export const recipeString = (recipes) => {
  let returnString = '';
  recipes.forEach((recipe) => {
    returnString += `${i18n.t(`user.plan.recipes_value.${recipe}`)}, `;
  });
  return returnString.slice(0, -2);
};

export const mealString = (meals) => {
  let returnString = '';
  meals.forEach((meal) => {
    returnString += `${i18n.t(`user.plan.meals_value.${meal}`)}, `;
  });
  return returnString.slice(0, -2);
};

export const hasGotPurchases = (user) => user.tickets?.length > 0;

export const hasAppleSubscription = (user) =>
  user?.subscription?.platform === SubscriptionPlatform.ios;

export const getUserRegisterationStep = (user) => {
  const {
    entered_password,
    country,
    state,
    body_parts_focus,
    fat_percentage,
    phone_number,
  } = user;

  if (!entered_password) {
    return '/registration/new_password';
  }
  if (!country) {
    return '/registration/country';
  }
  if (state === UserState.draft || !body_parts_focus || !fat_percentage) {
    return '/registration';
  }
  if (!phone_number) {
    return '/registration/phone';
  }

  return false;
};

export const userHasFinishedRegisteration = (user) => {
  let finishedFlag = true;

  const stepPath = getUserRegisterationStep(user);
  if (stepPath) {
    finishedFlag = false;
  }
  if (!isUserFree(user)) {
    if (!user.has_active_meal_survey) {
      finishedFlag = false;
    }
  }

  return finishedFlag;
};

export const mealSurveyNotFinished = (user) =>
  !isUserFree(user) && !user.has_active_meal_survey;

export const hideNavigation = (user) => {
  const { pathname } = window.location;
  // -recap- : for unsubscribeV8 Recap flow hide
  const includedPaths = [
    '-recap-',
    '/email_unsubscribe',
    '/workouts',
    '/keto-chocolate-bars-address',
  ];
  const includeOffers = [
    '/registration_keto_chocolate_bars',
    '/universal_keto_chocolate_bars',
    '/profile_keto_chocolate_bars',
    'keto-chocoalte-bars-rejected-offer',
  ];
  const excludedPaths = ['/chat'];

  if (includeOffers.some((path) => pathname.includes(path))) {
    return true;
  }
  if (includedPaths.some((path) => pathname.includes(path))) {
    return true;
  }
  if (mealSurveyNotFinished(user)) {
    if (excludedPaths.includes(pathname)) {
      return false;
    }

    return true;
  }

  return false;
};

export const userHasPremiumSubscription = (user) => {
  const premiumSubscription = user.subscriptions.find(
    (subscription) => subscription.item_type === 'premium_upsell'
  );

  if (!premiumSubscription) {
    return false;
  }

  return true;
};

export const userHasActivePremiumSubscription = (user) => {
  const premiumSubscription = user.subscriptions.find(
    (subscription) =>
      subscription.item_type === 'premium_upsell' &&
      subscription.status === 'active'
  );

  if (!premiumSubscription) {
    return false;
  }

  return true;
};

export const getUserActiveProSubscription = (user) =>
  user.subscriptions.find(
    (subscription) =>
      subscription.item_type === 'pro_subscription' &&
      subscription.status === 'active'
  );

export const userHasActiveProSubscription = (user) => {
  const proSubscription = user.subscriptions.find(
    (subscription) =>
      subscription.item_type === 'pro_subscription' &&
      subscription.status === 'active'
  );

  if (!proSubscription) {
    return false;
  }

  return true;
};

const userOnlyHasPremiumSubscription = (user) =>
  !userHasActiveProSubscription(user) && userHasActivePremiumSubscription(user);

export const userCanSeeSubscriptionInformation = (user) => {
  if (hasAppleSubscription(user)) {
    return false;
  }
  if (userOnlyHasPremiumSubscription(user)) {
    return false;
  }

  return true;
};

const userHasPremiumGift = (user) => {
  const premiumGift = user.tickets.find(
    (ticket) =>
      ticket.item.type === 'premium_upsell' &&
      ticket.purchase_item.paying_type === PaymentType.lifetime
  );

  if (!premiumGift) {
    return false;
  }

  return true;
};

const userHasInactivePremiumSubscription = (user) => {
  const inactivePremium = user.subscriptions.find(
    (subscription) =>
      subscription.item_type === 'premium_upsell' &&
      subscription.status === 'unsubscribed'
  );

  if (!inactivePremium) {
    return false;
  }

  return true;
};

const userHasBoughtOneTimePurchasePremium = (user) =>
  user.access.includes('premium_upsell') && !userHasPremiumSubscription(user);

const userHasPremiumGiftAndInactivePremiumSubscription = (user) =>
  user.access.includes('premium_upsell') &&
  userHasPremiumGift(user) &&
  userHasInactivePremiumSubscription(user);

const KETO_ALLOWED_PREMIUM_LOCALES = [
  'en',
  'et',
  'lt',
  'es',
  'fr',
  'de',
  'cs',
  'ro',
  'el',
  'hu',
  'hr',
  'pl',
  'pt',
  'bg',
  'sk',
  'ru',
  'nl',
  'da',
  'it',
  'ka',
  'sr',
  'uk',
  'no',
  'sv',
  'fi',
  'he',
];

export const isLocaleRestrictedInKetoPremium = () =>
  (isKeto() || isKetoGo() || isHealthgenom()) &&
  !KETO_ALLOWED_PREMIUM_LOCALES.includes(getCurrentLocale());

export const showPremiumSubscriptionBlock = (user) => {
  if (!settings.SHOW_PREMIUM_SUBSCRIPTION) {
    return false;
  }
  if (hasAppleSubscription(user)) {
    return false;
  }
  if (isLocaleRestrictedInKetoPremium()) {
    return false;
  }
  if (userHasBoughtOneTimePurchasePremium(user)) {
    return false;
  }
  if (userHasPremiumGiftAndInactivePremiumSubscription(user)) {
    return false;
  }

  return true;
};

export const getActivePremiumSubscription = (user) =>
  user.subscriptions.find(
    (subscription) =>
      subscription.item_type === 'premium_upsell' &&
      subscription.status === 'active'
  );

export const userHasShoppingListAccess = (user) =>
  user.access && user.access.includes('shopping_list');

export const userHasPremium = (user) => user.access.includes('premium_upsell');

export const showPremium = (user) => {
  if (hasAppleSubscription(user)) {
    return false;
  }
  if (!settings.SHOW_PREMIUM) {
    return false;
  }
  if (isLocaleRestrictedInKetoPremium()) {
    return false;
  }

  return true;
};

// TODO: Remove this after CKD fix
export const brokenCKDUserIDs = [
  4294, 5833, 8354, 10038, 14425, 17658, 18711, 23141, 26772, 28086, 31250,
  33697, 34202, 34425, 35685, 36119, 37539, 38066, 39908, 44634, 46028, 48368,
  51643, 52025, 52546, 52901, 53416, 53599, 53875, 54018, 54852, 55065, 55108,
  55753, 56343, 57299, 57452, 57566, 58114, 58148, 58339, 58620, 58663, 58708,
  58746, 60021, 60286, 60469, 60483, 60581, 60663, 61148, 61203, 61315, 61564,
  61698, 61837, 61929, 62305, 62554, 64959, 66185, 68734, 71318, 72012, 72548,
  73029, 74314, 74416, 76323, 76817, 77233, 77367, 79879, 81220, 81854, 84008,
  84190, 84929, 85235, 88049, 88232, 88391, 89254, 91646, 93908, 97322, 97436,
  98801, 99391, 100004, 100027, 100127, 100451, 102520, 106548, 107886, 109004,
  109133, 109375, 110925, 111875, 112150, 112987, 114102, 114779, 115044,
  115871, 115999, 116029, 116102, 116170, 116346, 116448, 116499, 116534,
  116636, 116827, 116995, 117620, 117807, 117868, 118606, 118635, 118905,
  119046, 119745, 120704, 120774, 120811, 120971, 121040, 121068, 121185,
  121233, 121242, 121432, 121621, 121859, 121960, 122204, 122443, 122489,
  122500, 122517, 122841, 123235, 123398, 123614, 123631, 123692, 123825,
  124096, 124154, 124161, 124958, 125192, 125292, 125361, 125400, 125407,
  125451, 125695, 125765, 126511, 126603, 126812, 126819, 127126, 127387,
  127758, 127773, 128110, 128199, 128301, 128479, 128482, 128557, 128599,
  128643, 128676, 128827, 128830, 129360, 129403, 129659, 129876, 129945,
  130046, 130216, 130322, 130374, 130584, 130907, 131007, 131238, 131362,
  131455, 131494, 136651, 136691, 139536, 143643, 144852, 148290, 150802,
  151734, 153064, 153196, 153535, 154896, 155316, 156151, 157777, 164072,
  168176, 172669, 175764, 179677, 182895, 185442, 192778, 195079, 197546,
  201647, 201989, 203852, 207264, 211822, 213885, 216664, 220369, 220878,
  222420, 226707, 229252, 229458, 237190, 237323, 237835, 239172, 242579,
  243618, 244414, 246362, 246437, 247290, 247559, 247595, 247649, 248123,
  251096, 254901, 255258, 257430, 258840, 259422, 262401, 262676, 263173,
  263364, 263622, 264027, 264654, 264689, 264796, 265002, 265125, 265235,
  265436, 265598, 265678, 265946, 266058, 266643, 267274, 267545, 267577,
  267586, 267613, 267718, 267731, 268012, 268118, 269012, 269283, 269335,
  269412, 269580, 269903, 269912, 269972, 270127, 270247, 270251, 270256,
  271258, 271599, 271992, 272731, 273168, 273488, 273661, 273869, 273875,
  273880, 273914, 273939, 273988, 274158, 274174, 274224, 274248, 274309,
  274757, 275106, 275149, 275275, 275352, 275907, 276010, 276049, 276124,
  276152, 276182, 276429, 276545, 276553, 276973, 277327, 277835, 278326,
  278502, 278569, 278625, 278823, 279809, 279860, 280221, 280402, 280415,
  280793, 280852, 281032, 281075, 281147, 281165, 281258, 285369, 288105,
  290877, 302846, 303703, 308343, 312073, 313963, 316041, 317177, 318094,
  335847, 336943, 338379, 341924, 343107, 345981, 346390, 347360, 349931,
  350846, 351751, 352479, 357295, 358690, 360528, 360546, 361734, 367410,
  368872, 369348, 371919, 374009, 375918, 376221, 376810, 376977, 377564,
  384587, 388690, 388848, 388923, 391678, 398614, 403719, 410707, 411394,
  411572, 411603, 411970, 413725, 414888, 415425, 416204, 428640, 432726,
  435378, 435382, 435891, 438331, 440234, 442125, 444386, 447511, 448934,
  450274, 451508, 452286, 453526, 453707, 455919, 456578, 457301, 457885,
  459356, 460034, 460185, 462108, 464388, 466357, 468139, 470121, 471254,
  474206, 474391, 477579, 480316, 481777, 482102, 484233, 486053, 487404,
  488119, 488244, 489757, 494797, 495781, 498841, 501631, 502912, 504771,
  505275, 506698, 509258, 511025, 513575, 513740, 515101, 517510, 517641,
  517858, 520961, 521661, 525306, 525757, 526386, 527386, 528311, 528540,
  531425, 532702, 537004, 538378, 538444, 540258, 540497, 540770, 541010,
  541336, 541362, 541500, 541649, 541728, 542073, 542099, 542115, 542357,
  542675, 542809, 543091, 543302, 544075, 544658, 545544, 545599, 545651,
  545730, 545755, 545758, 545975, 546190, 546375, 546528, 546670, 546672,
  546686, 546782, 547550, 547781, 547929, 548085, 548446, 548681, 548830,
  548918, 548966, 549123, 549217, 549429, 549491, 549575, 549865, 549938,
  549954, 550176, 550415, 550432, 550623, 550718, 550908, 551290, 551334,
  552028, 552183, 552402, 552872, 553141, 553301, 553465, 553513, 553627,
  553705, 553792, 553969, 553980, 554151, 554498, 555367, 555508, 555554,
  555579, 555769, 556417, 556782, 556853, 557049, 557079, 557319, 557323,
  557556, 557914, 557987, 558061, 558222, 558529, 559588, 559872, 560070,
  560171, 560939, 560957, 561034, 561156, 561420, 561605, 561837, 562436,
  563495, 563968, 563983, 564230, 564241, 564339, 564514, 566108, 566357,
  566545, 566603, 567108, 567178, 567226, 567439, 567455, 567498, 567570,
  567739, 568060, 568180, 568243, 568544, 568777, 568780, 569050, 569168,
  569205, 569337, 569602, 569701, 569785, 569883, 570016, 570129, 570140,
  570653, 570831, 570835, 571912, 576936, 578131, 585701, 589073, 590569,
  591872, 601893, 602713, 602770, 604164, 604188, 607494, 607628, 613819,
  620551, 621032, 622339, 623740, 624500, 625237, 626393, 628185, 639960,
  641118, 644781, 647796, 648600, 651723, 653686, 656211, 659012, 662332,
  663927, 664906, 665362, 666220, 667634, 674344, 674565, 678585, 678814,
  681336, 681853, 689012, 696877, 698716, 707672, 714136, 714687, 716246,
  717366, 721938, 722169, 726579, 727334, 727801, 729695, 730846, 732695,
  733813, 736775, 739953, 740554, 742629, 742996, 747831, 753438, 757244,
  757489, 758422, 759317, 760533, 760659, 762874, 764014, 766261, 770548,
  772297, 775429, 776652, 777934, 778204, 778689, 779170, 779205, 781673,
  781765, 784369, 784699, 786167, 786250, 788160, 788745, 790111, 791558,
  792404, 792539, 795701, 796389, 796632, 797656, 800873, 801562, 801673,
  804714, 805794, 809654, 809891, 810756, 813153, 814215, 814250, 814754,
  815801, 817479, 818398, 820126, 820612, 820874, 822079, 823378, 824063,
  825333, 826778, 827142, 827295, 829365, 831419, 831795, 832890, 836167,
  836428, 836755, 837029, 838381, 839215, 840664, 840967, 840997, 841292,
  842756, 842806, 843222, 843467, 843984, 846692, 847366, 847666, 847970,
  848116, 853527, 853825, 854065, 855379, 855779, 856276, 856302, 856487,
  856565, 857198, 857220, 857255, 857580, 857694, 858638, 858963, 859719,
  860435, 861400, 862306, 862390, 862402, 862711, 863191, 863320, 863468,
  863494, 863707, 864108, 864306, 864654, 864725, 864810, 865045, 865232,
  865297, 865835, 865954, 866135, 866512, 866769, 866969, 867226, 868130,
  868228, 868276, 868346, 868439, 868463, 868788, 869359, 869535, 869589,
  869743, 869818, 869911, 870003, 870029, 870064, 870068, 870300, 870575,
  870912, 871301, 871411, 871648, 871673, 871723, 871790, 872221, 872372,
  872463, 872596, 872725, 872734, 872831, 872910, 872998, 873060, 873065,
  873178, 873207, 873219, 873248, 873373, 873418, 873624, 873633, 873759,
  873781, 874341, 874374, 874544, 874854, 874971, 875009, 875648, 875874,
  875914, 875940, 876012, 876187, 876507, 876845, 876880, 876901, 877115,
  877324, 877376, 877518, 877711, 877808, 878494, 878844, 878921, 878970,
  879093, 879127, 879181, 879277, 879567, 879609, 879710, 879752, 880155,
  880222, 881111, 881190, 881219, 881526, 881628, 881958, 882217, 882230,
  882271, 882395, 882433, 882464, 882732, 882863, 882922, 883638, 883810,
  884139, 884186, 884335, 884757, 885052, 885348, 886192, 886530, 886609,
  886722, 886792, 887190, 887354, 887522, 887744, 887778, 887939, 888453,
  888587, 889518, 889847, 890221, 890251, 890329, 890596, 891088, 891419,
  891538, 891642, 891810, 891842, 891876, 891928, 892002, 892322, 892400,
  892661, 892687, 892778, 893139, 893297, 893340, 893367, 893505, 893602,
  893644, 893747, 893937, 894379, 894419, 894469, 894923, 895067, 895086,
  895182, 895248, 895346, 895372, 895437, 895587, 895628, 895677, 895703,
  896256, 896693, 896775, 897405, 897982, 898489, 898610, 898623, 898648,
  899415, 899470, 899784, 899832, 900007, 900928, 901019, 901161, 901399,
  901781, 902111, 902493, 902726, 902852, 903186, 903349, 903484, 903594,
  903769, 903776, 903950, 904000, 904003, 904155, 904161, 904552, 904564,
  904594, 904755, 904847, 904860, 904879, 905162, 905515, 905517, 906003,
  906115, 906125, 906252, 906375, 906470, 906564, 906798, 906940, 906980,
  907307, 907411, 907801, 907828, 907996, 908016, 908042, 908337, 908517,
  908683, 908771, 908940, 909361, 909645, 910685, 910726, 910772, 911066,
  911731, 912018, 912206, 912531, 912598, 912621, 912713, 913496, 913802,
  913880, 913912, 913926, 914068, 914314, 914405, 914602, 914603, 914661,
  914758, 914844, 914860, 914887, 914957, 915048, 915107, 915180, 915428,
  915569, 915726, 916052, 916280, 916594, 916720, 917240, 917405, 917444,
  917575, 917720, 918155, 918213, 918319, 918343, 918502, 918539, 918864,
  918948, 919001, 919043, 919183, 919192, 919376, 919391, 919416, 919424,
  919523, 919770, 919779, 919845, 919923, 919945, 920216, 921151, 921187,
  921429, 921882, 921923, 922033, 922083, 922110, 922285, 922430, 922680,
  922981, 923121, 923265, 923462, 923735, 923789, 923829, 923835, 923924,
  924090, 924120, 924181, 924208, 924295, 924721, 925083, 925088, 925106,
  925154, 925182, 925207, 925331, 925427, 925512, 925721, 925869, 925890,
  926368, 926615, 926643, 926690, 926728, 926829, 926971, 927150, 927663,
  927831, 927917, 928131, 928218, 928281, 928291, 928313, 928347, 928444,
  928551, 928633, 929318, 929571, 929646, 929721, 930105, 930239, 938413,
  940628, 943092, 946262, 948699, 955327, 955377, 958925, 960959, 961575,
  963192, 964302, 966102, 968853, 968905, 970008, 972800, 978967, 982601,
  993324, 994178, 994277, 994531, 994564, 998607, 1001374, 1003341, 1003559,
  1005178, 1006762, 1006829, 1007591, 1010306, 1011291, 1012284, 1014816,
  1015512, 1016816, 1017714, 1018567, 1020161, 1020317, 1023413, 1023485,
  1027268, 1036143, 1037094, 1041289, 1045173, 1046884, 1048726, 1048805,
  1051744, 1052102, 1054364, 1057514, 1058232, 1059986, 1060696, 1060726,
  1063052, 1065657, 1065934, 1066563, 1069734, 1072898, 1076603, 1076995,
  1078810, 1079116, 1081934, 1082459, 1089579, 1095406, 1103222, 1104721,
  1106976, 1107250, 1111807, 1115666, 1117339, 1118104, 1118823, 1118834,
  1121903, 1121981, 1123279, 1123596, 1124605, 1127712, 1128318, 1132240,
  1132692, 1132863, 1135616, 1136701, 1138122, 1140399, 1144535, 1145227,
  1146161, 1147489, 1150095, 1150683, 1151247, 1154407, 1154953, 1158845,
  1159252, 1159274, 1159320, 1160086, 1160556, 1161423, 1161798, 1163296,
  1164631, 1165403, 1166943, 1167893, 1169126, 1174244, 1174333, 1175618,
  1175636, 1176616, 1178144, 1181519, 1183063, 1185338, 1188452, 1188629,
  1190489, 1190512, 1193626, 1195579, 1196241, 1198370, 1198933, 1199014,
  1199237, 1201625, 1203285, 1205432, 1207416, 1207831, 1208398, 1208482,
  1208590, 1216630, 1216885, 1217354, 1219592, 1221466, 1221618, 1222744,
  1224747, 1225173, 1229437, 1229764, 1236756, 1237124, 1238033, 1239701,
  1239907, 1240149, 1240697, 1244267, 1244661, 1245924, 1247957, 1248020,
  1248914, 1254425, 1254535, 1256749, 1261752, 1263523, 1264691, 1264864,
  1265482, 1265754, 1267847, 1269622, 1270147, 1270453, 1271364, 1272123,
  1278617, 1280427, 1280748, 1282124, 1282689, 1282854, 1283547, 1284480,
  1284821, 1284863, 1285542, 1285654, 1285699, 1285872, 1286304, 1287424,
  1289216, 1292026, 1292148, 1292315, 1292729, 1292889, 1293576, 1295893,
  1296590, 1297576, 1297656, 1298185, 1298538, 1301073, 1301495, 1302750,
  1303489, 1303773, 1305199, 1306334, 1306758, 1307249, 1308503, 1309585,
  1310382, 1310887, 1311340, 1312184, 1312861, 1314511, 1316862, 1317387,
  1318975, 1319045, 1322820, 1323967, 1324701, 1325122, 1325828, 1325873,
  1326520, 1326835, 1327066, 1327342, 1327380, 1327408, 1327543, 1327577,
  1327585, 1327586, 1327667, 1327672, 1327804, 1328104, 1328112, 1328602,
  1328633, 1328996, 1329445, 1329459, 1329691, 1329881, 1330153, 1330473,
  1330955, 1331282, 1331589, 1331984, 1332261, 1332872, 1333376, 1333420,
  1333551, 1333640, 1334090, 1334361, 1334426, 1334440, 1334484, 1334565,
  1334654, 1334765, 1334790, 1334858, 1334963, 1335018, 1335023, 1335263,
  1335546, 1335712, 1335804, 1336275, 1336286, 1336307, 1336320, 1336666,
  1336881, 1336911, 1336929, 1336946, 1337010, 1337022, 1337135, 1337143,
  1337227, 1337235, 1337395, 1337438, 1337572, 1337917, 1338254, 1338426,
  1338603, 1338795, 1339167, 1339264, 1339405, 1339494, 1339558, 1339661,
  1339890, 1339941, 1340090, 1340102, 1340417, 1340560, 1340798, 1340846,
  1341048, 1341096, 1341268, 1341319, 1341599, 1341613, 1341916, 1342115,
  1342278, 1342332, 1342525, 1342703, 1343054, 1343246, 1343370, 1343566,
  1344603, 1344746, 1344881, 1344930, 1345310, 1345317, 1345370, 1345395,
  1345609, 1345703, 1345888, 1345924, 1346049, 1346147, 1346184, 1346244,
  1346333, 1346335, 1346346, 1346381, 1346391, 1346432, 1346631, 1346710,
  1347031, 1347072, 1347079, 1347262, 1347272, 1347709, 1347789, 1347834,
  1347920, 1348466, 1348485, 1348496, 1348503, 1348651, 1348706, 1348720,
  1348974, 1348977, 1349086, 1349098, 1349726, 1349893, 1349921, 1349937,
  1350023, 1350033, 1350078, 1350142, 1350210, 1350313, 1350409, 1350492,
  1350552, 1350686, 1350990, 1351113, 1351144, 1351236, 1351402, 1351476,
  1351541, 1351864, 1352108, 1352274, 1352368, 1352447, 1352703, 1352853,
  1353199, 1353257, 1353356, 1353608, 1353611, 1353749, 1353793, 1354316,
  1354334, 1354702, 1355114, 1355140, 1355146, 1355279, 1355376, 1355396,
  1355533, 1355548, 1355553, 1355747, 1355780, 1355995, 1356027, 1356049,
  1356090, 1356110, 1356310, 1356575, 1356700, 1356756, 1356775, 1356819,
  1356940, 1357346, 1357635, 1358065, 1358570, 1358914, 1358940, 1359297,
  1359429, 1359592, 1359701, 1360067, 1360546, 1360585, 1360878, 1360891,
  1360965, 1361034, 1361257, 1361309, 1361354, 1361358, 1361368, 1361848,
  1361883, 1361998, 1362108, 1362361, 1362425, 1362466, 1362673, 1362689,
  1363053, 1363178, 1363270, 1363429, 1363692, 1363709, 1363713, 1363790,
  1363818, 1363922, 1363924, 1364115, 1364140, 1364240, 1364463, 1364551,
  1364649, 1364678, 1364723, 1365068, 1365225, 1365291, 1366108, 1366126,
  1366501, 1366507, 1366545, 1366585, 1366776, 1367330, 1367539, 1367571,
  1367897, 1368042, 1368055, 1368299, 1368518, 1368609, 1369069, 1369256,
  1369462, 1373501, 1375186, 1375598, 1377267, 1385760, 1388950, 1389365,
  1400464, 1401560, 1404260,
];

export const isBrokenCKDUser = (user) => {
  const arrOfUserIDsAsStrings = brokenCKDUserIDs.map((userId) =>
    userId.toString()
  );

  if (!arrOfUserIDsAsStrings.includes(user.id.toString())) {
    return false;
  }

  return true;
};

export const isChatAccessEnabled = (user) =>
  user.access && user.access.includes('chat');

export const getActiveShoppinglistSubscription = (user) =>
  user.subscriptions.find(
    (subscription) =>
      subscription.item_type === 'shopping_list' &&
      subscription.status === 'active'
  );

export const getActiveChatSubscription = (user) =>
  user.subscriptions.find(
    (subscription) =>
      subscription.item_type === 'chat' && subscription.status === 'active'
  );

export const getActiveWorkoutsSubscription = (user) =>
  user.subscriptions.find(
    (subscription) =>
      subscription.item_type === 'workouts' && subscription.status === 'active'
  );

export const getUserCustomField = (user, fieldName) => {
  let value = user.custom_fields.find(
    (item) => item.field === fieldName
  )?.value;

  if (value === undefined) return null;

  // Booleans come as strings from API
  const isBoolean = value === 'true' || value === 'false';
  value = isBoolean ? JSON.parse(value) : value;

  return value;
};

export const userHasGenom = (user) => user.access.includes('genom');

export const getActiveShoppingListSubscription = (user) =>
  user.subscriptions.find(
    (subscription) =>
      subscription.item_type === 'shopping_list' &&
      subscription.status === 'active'
  );

export const userHasUnsubscribedShoppinglist = (user) =>
  user.subscriptions.find(
    (subscription) =>
      subscription.item_type === 'shopping_list' &&
      subscription.status === 'unsubscribed'
  );

export const getActiveSubscriptionByItemType = (user, itemType) =>
  user.subscriptions.find(
    (subscription) =>
      subscription.item_type === itemType && subscription.status === 'active'
  );

export const getUnsubscribedSubscriptionByItemType = (user, itemType) =>
  user.subscriptions.find(
    (subscription) =>
      subscription.item_type === itemType &&
      subscription.status === 'unsubscribed'
  );

export const isAccessAvailable = (user, itemType) =>
  user.access.includes(itemType);
