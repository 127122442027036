import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { User } from 'types/user';
import { getCurrentTheme } from 'helpers/themeHelper';
import useChat from 'hooks/useChat';
import { getUpsellOfferUtms } from 'helpers/offerHelper';
import {
  UpsellLocationState,
  UpsellOfferTypes,
  UpsellTypes,
} from 'types/upsell';
import { TFunction } from 'i18next';

type Props = {
  children: React.ReactNode;
};

type OfferUrls = {
  [key: string]: string;
};

const getUniversalChatUpsellLocation = (t: TFunction) => {
  const utms = getUpsellOfferUtms(
    UpsellTypes.chat,
    UpsellOfferTypes.universalChat,
    t
  );

  return `/offer/universal_chat?${qs.stringify(utms)}`;
};

const getLockedChatUpsellLocation = (t: TFunction) => {
  const utms = getUpsellOfferUtms(
    UpsellTypes.chat,
    UpsellOfferTypes.lockedChat,
    t
  );
  const locationState: UpsellLocationState = {
    origin: 'locked_chat',
  };

  return {
    pathname: `/offer/locked_chat?${qs.stringify(utms)}`,
    state: locationState,
  };
};

function EnsureChatAvailability({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const theme = getCurrentTheme();
  const { t } = useTranslation();

  const {
    isChatAccessEnabled,
    isChatPaywall,
    isChatImitation,
    isChatImitationSeen,
  } = useChat();

  if (isChatAccessEnabled) {
    return <>{children}</>;
  }

  if (isChatPaywall) {
    // Default original chat upsell flow without chat imitation
    if (!isChatImitation) {
      return <Redirect to={getUniversalChatUpsellLocation(t)} />;
    }

    // Show chat imitation just once, under the original chat route
    if (!isChatImitationSeen) {
      return <>{children}</>;
    }

    // Locked chat upsell flow after chat imitation
    return <Redirect to={getLockedChatUpsellLocation(t)} />;
  }

  const offerUrl: OfferUrls = {
    monacofit: `/offer/${user.goal}_consultant?utm_source=app.monacofit.com&utm_medium=consultant_section&utm_campaign=${user.goal}_4_times_faster_with_consultations`,
    diet: '/offer/sub_expired?utm_source=appv7.stockholmdiet.com&utm_medium=consultant_section&utm_campaign=sub_expired',
    keto: '/offer/sub_expired?utm_source=app.certifiedketodiet.com&utm_medium=consultant_section&utm_campaign=sub_expired',
    ketogo:
      '/offer/sub_expired?utm_source=my.ketogo.app&utm_medium=consultant_section&utm_campaign=sub_expired',
    fasting:
      '/offer/sub_expired?utm_source=app.certifiedfasting.com&utm_medium=consultant_section&utm_campaign=sub_expired',
    healthgenom:
      '/offer/sub_expired?utm_source=app.healthgenom.com&utm_medium=consultant_section&utm_campaign=sub_expired',
  };

  return <Redirect to={offerUrl[theme]} />;
}

export default EnsureChatAvailability;
