export const setTimerInBrowserStorage = (
  timerValue: number,
  isTemporaryTimer: boolean
): void =>
  isTemporaryTimer
    ? sessionStorage.setItem('countdown', timerValue.toString())
    : localStorage.setItem('countdown', timerValue.toString());

export const getTimerValueFromBrowserStorage = (isTemporaryTimer: boolean) =>
  isTemporaryTimer
    ? sessionStorage.getItem('countdown')
    : localStorage.getItem('countdown');

export const getTimerStartingValue = (
  defaultStartValue: number,
  isTemporaryTimer: boolean
): number => {
  if (!defaultStartValue) {
    return 0;
  }

  const timerValue = getTimerValueFromBrowserStorage(isTemporaryTimer);
  if (timerValue) {
    return parseInt(timerValue, 10);
  }

  return defaultStartValue;
};

export const formatTimePart = (timePart: number): string =>
  timePart.toString().padStart(2, '0');

export const convertSecondsToHoursMinutesSecond = (
  currentSeconds: number
): string => {
  const minutes: number = Math.floor(currentSeconds / 60);
  const seconds: number = currentSeconds - minutes * 60;

  const timeString = `${formatTimePart(minutes)}:${formatTimePart(seconds)}`;

  return timeString;
};
