import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import browserHistory from 'helpers/history';

export default class EnsureLoggedOutWrapper extends React.PureComponent {
  static propTypes = {
    children: PropTypes.array,
  };

  componentDidMount = () => {
    if (localStorage.access_token) {
      browserHistory.replaceLater('/program');
    }
  };

  render = () => {
    const { children } = this.props;
    return !localStorage.access_token ? children : <Redirect to="/program" />;
  };
}
