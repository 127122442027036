import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  callbackFunction: () => void;
};

function useHistoryBackBlock({ callbackFunction }: Props) {
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        callbackFunction();
        return false;
      }

      return unblock();
    });

    return () => {
      unblock();
    };
  }, [history, callbackFunction]);
}

export default useHistoryBackBlock;
