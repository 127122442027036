import React from 'react';

export default function FreeDelivery() {
  return (
    <svg width={48} height={48} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <path
          d="M11.87 39.215a5.19 5.19 0 1 1 4.34-8.036.794.794 0 0 1-1.328.873 3.603 3.603 0 1 0 .59 1.975.794.794 0 1 1 1.587 0 5.195 5.195 0 0 1-5.19 5.188z"
          fill="#2A9D8F"
          fillRule="nonzero"
        />
        <path
          d="M3.175 26.302a.794.794 0 0 1-.794-.794v-6.746c0-.439.355-.794.794-.794h3.65a.794.794 0 0 1 0 1.587H3.969v5.953a.794.794 0 0 1-.793.794z"
          fill="#E9C46A"
          fillRule="nonzero"
        />
        <path
          d="M5.783 22.928H3.175a.794.794 0 0 1 0-1.588h2.608a.794.794 0 0 1 0 1.588zm7.462 3.374a.794.794 0 0 1-.539-.212l-2.315-2.142v1.558a.794.794 0 1 1-1.587 0V18.76c0-.438.355-.794.793-.794h1.96a2.481 2.481 0 0 1 .066 4.962l2.161 1.995a.794.794 0 0 1-.539 1.376v.003zm-2.857-4.962h1.167a.894.894 0 1 0 0-1.787h-1.167v1.787zm9.28 4.962h-3.651a.794.794 0 0 1-.794-.794v-6.746c0-.439.355-.794.794-.794h3.65a.794.794 0 1 1 0 1.587H16.81v5.16h2.858a.794.794 0 1 1 0 1.587z"
          fill="#E9C46A"
          fillRule="nonzero"
        />
        <path
          d="M18.581 22.928H16.02a.794.794 0 0 1 0-1.588h2.561a.794.794 0 1 1 0 1.588zm7.508 3.374h-3.647a.794.794 0 0 1-.793-.794v-6.746c0-.439.355-.794.793-.794h3.647a.794.794 0 1 1 0 1.587h-2.853v5.16h2.853a.794.794 0 1 1 0 1.587z"
          fill="#E9C46A"
          fillRule="nonzero"
        />
        <path
          d="M25.004 22.928h-2.562a.794.794 0 0 1 0-1.588h2.562a.794.794 0 1 1 0 1.588z"
          fill="#E9C46A"
          fillRule="nonzero"
        />
        <path
          d="M33.214 34.878H16.268a.794.794 0 1 1 0-1.587h16.946a.794.794 0 1 1 0 1.587zm-25.74 0H4.76a.794.794 0 0 1-.794-.793v-1.628h-.76a.794.794 0 0 1-.794-.793v-2.508a.794.794 0 1 1 1.587 0v1.714h.762c.439 0 .794.355.794.794v1.627h1.92a.794.794 0 0 1 0 1.587zm38.145 0h-3.611a.794.794 0 1 1 0-1.587h3.61a.794.794 0 0 0 .795-.794V22.86l-5.211-7.82H29.507a.794.794 0 0 1-.794-.793v-2.659H.793a.794.794 0 1 1 0-1.587h28.714c.438 0 .794.355.794.794v2.659h11.326c.265 0 .513.132.66.353l5.58 8.373c.087.13.133.284.133.44v9.882a2.381 2.381 0 0 1-2.381 2.377z"
          fill="#2A9D8F"
          fillRule="nonzero"
        />
        <path
          d="M17.05 15.443h-2.033a.794.794 0 1 1 0-1.587h2.033a.794.794 0 0 1 0 1.587zm26.982 8.18h-8.945a.794.794 0 0 1-.794-.793v-6.564c0-.438.355-.794.794-.794h7.889a.794.794 0 1 1 0 1.588H35.88v4.976h8.152a.794.794 0 0 1 0 1.588zm.481 5.824a.794.794 0 0 1-.793-.794v-1.42c0-.439.355-.794.793-.794h2.693a.794.794 0 1 1 0 1.587h-1.899v.627a.794.794 0 0 1-.794.794zm-6.903 9.768a5.16 5.16 0 0 1-4.413-2.46.794.794 0 1 1 1.35-.837 3.603 3.603 0 1 0-.538-1.894.794.794 0 1 1-1.588 0 5.19 5.19 0 1 1 5.19 5.19l-.001.001zm-25.737-3.998a1.19 1.19 0 0 1-1.167-1.42c.014-.08.035-.156.064-.23.032-.07.069-.136.11-.2a.84.84 0 0 1 .151-.182 1.084 1.084 0 0 1 .381-.262c.072-.023.151-.047.223-.063.391-.077.796.045 1.08.325a.808.808 0 0 1 .142.183.975.975 0 0 1 .111.198c.031.075.055.152.072.23a1.19 1.19 0 0 1-1.167 1.42v.001z"
          fill="#2A9D8F"
          fillRule="nonzero"
        />
        <path
          d="M37.611 35.217c-.08 0-.16-.008-.238-.024a1.231 1.231 0 0 1-.223-.071 1.215 1.215 0 0 1-.205-.103 1.517 1.517 0 0 1-.175-.151 1.129 1.129 0 0 1-.325-.611 1.089 1.089 0 0 1 0-.46c.016-.076.037-.15.063-.223.032-.07.07-.14.11-.206a.868.868 0 0 1 .152-.183c.054-.054.113-.105.175-.15.07-.04.134-.08.205-.112.072-.031.152-.047.223-.063.154-.032.314-.032.468 0 .075.016.15.037.223.063.07.033.14.07.205.111.065.046.126.097.184.151a.808.808 0 0 1 .143.183.994.994 0 0 1 .11.206c.128.288.128.617 0 .905a1.372 1.372 0 0 1-.253.389 1.611 1.611 0 0 1-.184.15.997.997 0 0 1-.205.104 1.181 1.181 0 0 1-.452.095zm-8.178-3.054H18.581a.794.794 0 1 1 0-1.587H28.64V17.854a.794.794 0 1 1 1.587 0v13.515a.794.794 0 0 1-.794.794zm-17.218-16.72H2.15a.794.794 0 1 1 0-1.587h10.064a.794.794 0 1 1 0 1.587zM7.828 29.95H.794a.794.794 0 1 1 0-1.587h7.034a.794.794 0 0 1 0 1.587z"
          fill="#2A9D8F"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
