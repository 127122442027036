import { getCurrentLocale } from 'i18n';
import { allCountries } from 'country-telephone-data';

import { User } from 'types/user';
import { ShippingAddress, Subscription } from 'types/subscription';
import {
  AllowedCountries,
  CountryCodeOption,
  PhysicalProductType,
} from 'types/physicalProducts';
import settings from './settings';
import { ALL_COUNTRIES } from './countryHelper';
import { hasAppleSubscription } from './userHelper';
import { retrieveEnvVariableArray } from './genomPlusHelper';

export const initialEditableFieldsState = {
  streetName: false,
  city: false,
  state: false,
  postalCode: false,
};

export const initialFormDataGAFields = {
  streetName: '',
  apartmentNumber: '',
  city: '',
  state: '',
  postalCode: '',
};

export const ALLOWED_COUNTRIES: AllowedCountries = [
  {
    value: 'US',
    emoji: '🇺🇸',
    name: 'United States',
  },
  {
    value: 'CA',
    emoji: '🇨🇦',
    name: 'Canada',
  },
  {
    value: 'DK',
    emoji: '🇩🇰',
    name: 'Denmark',
  },
  {
    value: 'FI',
    emoji: '🇫🇮',
    name: 'Finland',
  },
  {
    value: 'FR',
    emoji: '🇫🇷',
    name: 'France',
  },
  {
    value: 'DE',
    emoji: '🇩🇪',
    name: 'Germany',
  },
  {
    value: 'GR',
    emoji: '🇬🇷',
    name: 'Greece',
  },
  {
    value: 'IT',
    emoji: '🇮🇹',
    name: 'Italy',
  },
  {
    value: 'NL',
    emoji: '🇳🇱',
    name: 'Netherlands',
  },
  {
    value: 'NO',
    emoji: '🇳🇴',
    name: 'Norway',
  },
  {
    value: 'PT',
    emoji: '🇵🇹',
    name: 'Portugal',
  },
];

const isLocaleAllowedForCollagen = (): boolean => {
  const allowedLocales = ['es', 'fr', 'hu', 'ro', 'de'];

  return !!allowedLocales.includes(getCurrentLocale());
};

const isLocaleAllowedForFatburner = (): boolean => {
  const allowedLocales = [
    'en',
    'nl',
    'da',
    'fi',
    'fr',
    'de',
    'el',
    'it',
    'no',
    'pt',
  ];

  return !!allowedLocales.includes(getCurrentLocale());
};

/**
 *
 * Used to check wether physical product is allowed for certain locales
 *
 * @param  {PhysicalProductType} itemType Physical product item type
 * @return {Boolean} True | False
 */
export const isLocaleAllowedForProduct = (
  productType: PhysicalProductType
): boolean => {
  switch (productType) {
    case PhysicalProductType.collagen:
      return isLocaleAllowedForCollagen();
    case PhysicalProductType.fatBurner:
      return isLocaleAllowedForFatburner();
    default:
      return false;
  }
};

const isUserCountryAllowed = (country: string) => {
  const allowedCountry = ALLOWED_COUNTRIES.find(
    (allCountry) => allCountry.value === country
  );

  return !!allowedCountry;
};

export const setInitialUserPhonePrefix = (
  user: User,
  productSubscription?: Subscription
) => {
  if (productSubscription?.shipping_address?.phone_prefix) {
    return getCountryPhoneCodeByPhonePrefix(
      productSubscription?.shipping_address?.phone_prefix
    ).value;
  }
  if (user.country) {
    return user.country;
  }

  return '';
};

export const setInitialUserCountry = (
  shippingAddress: ShippingAddress | null,
  user: User
) => {
  if (
    shippingAddress?.country &&
    isUserCountryAllowed(shippingAddress.country)
  ) {
    return shippingAddress.country;
  }
  if (isUserCountryAllowed(user.country)) {
    return user.country;
  }

  return '';
};

export const getAllCountryPhoneCodes = () =>
  allCountries
    .map(({ iso2, dialCode, name }) => {
      const country = iso2.toUpperCase();

      return {
        key: dialCode,
        value: country,
        label: `+${dialCode} (${country})`,
        name,
      };
    })
    .sort((a: any, b: any) => a.name.localeCompare(b.name));

export const getCountryPhoneCodeByCountry = (currentCountry: string) => {
  const countryCodes = getAllCountryPhoneCodes();

  return countryCodes.find(
    (country) => country.value === currentCountry
  ) as CountryCodeOption;
};

export const getCountryPhoneCodeByPhonePrefix = (phonePrefix: string) => {
  const countryCodes = getAllCountryPhoneCodes();

  return countryCodes.find(
    (country) => country.key === phonePrefix
  ) as CountryCodeOption;
};

export const setInitialPhysicalProductForm = (
  physicalSubscription: Subscription
) => {
  if (!physicalSubscription)
    return {
      firstName: '',
      lastName: '',
      streetName: '',
      apartmentNumber: '',
      city: '',
      state: '',
      postalCode: '',
      phoneNumber: '',
    };

  return {
    firstName: physicalSubscription?.shipping_address?.first_name
      ? physicalSubscription.shipping_address.first_name
      : '',
    lastName: physicalSubscription?.shipping_address?.last_name
      ? physicalSubscription.shipping_address.last_name
      : '',
    streetName: physicalSubscription?.shipping_address?.address
      ? physicalSubscription.shipping_address.address
      : '',
    apartmentNumber: physicalSubscription?.shipping_address?.address2
      ? physicalSubscription.shipping_address.address2
      : '',
    city: physicalSubscription?.shipping_address?.city
      ? physicalSubscription.shipping_address.city
      : '',
    state: physicalSubscription?.shipping_address?.state
      ? physicalSubscription.shipping_address.state
      : '',
    postalCode: physicalSubscription?.shipping_address?.zip
      ? physicalSubscription.shipping_address.zip
      : '',
    phoneNumber: physicalSubscription?.shipping_address?.phone_number
      ? physicalSubscription.shipping_address.phone_number
      : '',
  };
};

/**
 * Check wether user has active Subscription on physical product
 *
 * @param  {User} user User object
 * @param  {PhysicalProductType} itemType Physical product item type
 * @return {Boolean} Return boolean based on wether user has subscription
 */
export const isPhysicalProductSubscriptionActive = (
  user: User,
  itemType: PhysicalProductType
) => {
  if (!isPhysicalProductAccessAvailable(user, itemType)) return false;

  const activeSubscription = getActiveSubscription(user, itemType);
  if (!activeSubscription) return false;

  return true;
};

/**
 * Check wether user has Unsubscribed physical product
 *
 * @param  {User} user User object
 * @param  {PhysicalProductType} itemType Physical product item type
 * @return {Boolean} Return boolean based on wether user has subscription
 */
export const isPhysicalProductSubscriptionUnsubscribed = (
  user: User,
  itemType: PhysicalProductType
) => {
  if (!isPhysicalProductAccessAvailable(user, itemType)) return false;

  const unsubscribedSubscription = getLatestUnsubscribedSubscription(
    user,
    itemType
  );
  if (!unsubscribedSubscription) return false;

  return true;
};

/**
 * Check wether user has access for PhysicalItem
 *
 * @param  {User} user User object
 * @param  {PhysicalProductType} itemType Physical product item type
 * @return {Boolean} Return boolean based on wether user has access
 */
export const isPhysicalProductAccessAvailable = (
  user: User,
  itemType: PhysicalProductType
) => user.access.includes(itemType);

/**
 * Returns an active subscription based on users subscription and passed itemType
 *
 * Used by for physical product retrieval
 *
 * @param  {User} user User object
 * @param  {PhysicalProductType} itemType Physical product item type
 * @return {Subscription} Active subscription of the user
 */
export const getActiveSubscription = (
  user: User,
  itemType: PhysicalProductType
) =>
  user.subscriptions.find(
    (subscription) =>
      subscription.item_type === itemType && subscription.status === 'active'
  );

/**
 * Returns an latest unsubscribbed subscription based on users subscription and passed itemType
 *
 * Used by for physical product retrieval
 *
 * @param  {User} user User object
 * @param  {PhysicalProductType} itemType Physical product item type
 * @return {Subscription} Latest subscription with type "unsubscribed"
 */
export const getLatestUnsubscribedSubscription = (
  user: User,
  itemType: PhysicalProductType
) => {
  const unsubscribedSubscriptions = user.subscriptions.filter(
    (subscription) =>
      subscription.status === 'unsubscribed' &&
      subscription.item_type === itemType
  );

  if (!unsubscribedSubscriptions) return null;

  const newestUnsubscribeSubscription = unsubscribedSubscriptions
    .sort(
      (unsubA, unsubB) =>
        Date.parse(unsubA.unsubscribed_at as string) -
        Date.parse(unsubB.unsubscribed_at as string)
    )
    .reverse();

  return newestUnsubscribeSubscription[0];
};

/**
 * Retrieves a "Subscription | null"
 * Subscription meaning it can be active (firstly) or latest unsubscribed (secondly), if user has it.
 *
 * Used by for physical product retrieval
 *
 * @param  {User} user User object
 * @param  {PhysicalProductType} itemType Physical product item type
 * @return {Subscription | null} Latest subscription with type "unsubscribed"
 */
export const getPhysicalProductSubscription = (
  user: User,
  itemType: PhysicalProductType
): Subscription | null => {
  const activeSubscription = getActiveSubscription(user, itemType);
  if (isPhysicalProductAccessAvailable(user, itemType) && activeSubscription)
    return activeSubscription;

  const unsubscribedSubscription = getLatestUnsubscribedSubscription(
    user,
    itemType
  );
  if (unsubscribedSubscription) return unsubscribedSubscription;

  return null;
};

export const getPhysicalProductFullAddress = (
  shipping_address: ShippingAddress
) => {
  const {
    address,
    address2,
    city,
    country,
    zip,
    first_name,
    last_name,
    phone_number,
    phone_prefix,
  } = shipping_address;
  const combinedAddress = `${address}${address2 && `-${address2}`}`;
  let countryFullName = ALLOWED_COUNTRIES.find(
    (currCountry) => currCountry.value === country
  )?.name;
  // TODO: Remove after CY has 0 subs. Patch for Cyprus country
  if (country === 'CY') {
    countryFullName = 'Cyprus';
  }
  if (country === 'CA') {
    countryFullName = 'Canada';
  }

  const fullZipCode = `${country}-${zip}`;

  return `${combinedAddress}, ${city}, ${fullZipCode}, ${countryFullName}, ${first_name} ${last_name} ${phone_prefix}${phone_number}`;
};

export const isAcvGummiesEnabled = () => settings.SHOW_ACV_GUMMIES;

export const getCountryListForAcvGummies = () => {
  const restrictedAcvGummiesCountries = ['RU', 'BY', 'UA'];

  return ALL_COUNTRIES.filter(
    (country) => restrictedAcvGummiesCountries.indexOf(country.value) === -1
  );
};

export const setInitialUserCountryForAcvGummies = (
  shippingAddress: ShippingAddress | null,
  user: User
) => {
  const isAllowedCountry = (country: string) => {
    const allowedCountries = getCountryListForAcvGummies();

    const allowedCountry = allowedCountries.find(
      (allCountry) => allCountry.value === country
    );

    return !!allowedCountry;
  };

  if (shippingAddress?.country && isAllowedCountry(shippingAddress.country)) {
    return shippingAddress?.country;
  }
  if (isAllowedCountry(user.country)) {
    return user.country;
  }

  return '';
};

export const getAcvGummiesFullAddress = (shipping_address: ShippingAddress) => {
  const {
    address,
    address2,
    city,
    country,
    zip,
    first_name,
    last_name,
    phone_number,
    phone_prefix,
  } = shipping_address;
  const combinedAddress = `${address}${address2 && `-${address2}`}`;
  const acvCountries = getCountryListForAcvGummies();
  const countryName = acvCountries.find(
    (currCountry) => currCountry.value === country
  )?.name;

  const fullZipCode = `${country}-${zip}`;

  return `${combinedAddress}, ${city}, ${fullZipCode}, ${countryName}, ${first_name} ${last_name} ${phone_prefix}${phone_number}`;
};

const ACV_RESTRICTED_LOCALES: string[] = ['uk'];

export const isAcvGummiesBlockAvailable = (user: User) => {
  if (!settings.SHOW_ACV_GUMMIES) {
    return false;
  }
  if (hasAppleSubscription(user)) {
    return false;
  }
  if (ACV_RESTRICTED_LOCALES.includes(getCurrentLocale())) {
    return false;
  }

  return true;
};

const ACV_GUMMIES_OFFER_TRANS_KEY: { [key: string]: string } = {
  AcvGummiesA: 'offer_a',
  AcvGummiesB: 'offer_b',
};

export const getAcvGummiesOfferTransKey = (templateName: string): string =>
  ACV_GUMMIES_OFFER_TRANS_KEY[templateName];

export const getLyteBarsCategories = (): string[] | null =>
  retrieveEnvVariableArray(settings.LYTE_BARS_CATEGORIES);

export const isLyteBarsAllowed = (user: User): boolean => {
  const { category } = user;
  const allowedCategories = getLyteBarsCategories();

  if (!allowedCategories) return false;
  if (!allowedCategories.includes(category.toString())) return false;

  return true;
};
