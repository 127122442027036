import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { User } from 'types/user';
import { PhysicalProductType } from 'types/physicalProducts';
import {
  isPhysicalProductAccessAvailable,
  isPhysicalProductSubscriptionActive,
  isPhysicalProductSubscriptionUnsubscribed,
} from 'helpers/physicalProductHelper';

type Props = {
  children: React.ReactNode;
};

function EnsureFatburnerActive({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);

  if (!isPhysicalProductAccessAvailable(user, PhysicalProductType.fatBurner)) {
    return <Redirect to="/program" />;
  }

  if (
    isPhysicalProductSubscriptionActive(user, PhysicalProductType.fatBurner)
  ) {
    return <>{children}</>;
  }

  if (
    isPhysicalProductSubscriptionUnsubscribed(
      user,
      PhysicalProductType.fatBurner
    )
  ) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
}

export default EnsureFatburnerActive;
