import React from 'react';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { User } from 'types/user';
import { UpsellTypes, UpsellOfferTypes } from 'types/upsell';
import settings from 'helpers/settings';
import { isGenomPlusAllowed } from 'helpers/genomPlusHelper';
import {
  getUserRegisterationStep,
  hasAppleSubscription,
  showPremium,
} from 'helpers/userHelper';
import {
  isUpsellOfferShoppingList,
  isUpsellOfferFatBurner,
  isPremiumUpsellOffer,
  isUpsellOfferCollagen,
  getUpsellOfferUtms,
  isPremiumShoppingListBundleOffer,
  isUpsellOfferChat,
  isUpsellOfferAcvGummies,
  isUpsellOfferGenom,
  isUpsellOfferWorkouts,
  isUpsellOfferKetoChocolateBars,
} from 'helpers/offerHelper';
import { isWorkoutsAllowed } from 'helpers/workoutsHelper';
import { isKetoChocolateBarsAllowed } from 'helpers/ketoChocolateBarsHelper';

import { LoadingAnimation } from 'components/common';
import useUpsell from './useUpsell';

type Props = {
  children: React.ReactNode;
};

function EnsureUserSeenUpsellOffer({ children }: Props) {
  const { t } = useTranslation();
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const registrationStep = getUserRegisterationStep(user);
  const { isLoading, upsellList } = useUpsell();

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (hasAppleSubscription(user)) {
    return <>{children}</>;
  }

  if (isPremiumUpsellOffer(upsellList) && showPremium(user)) {
    if (registrationStep) {
      return (
        <Redirect
          to={`/offer/registration_premium_upsell?${qs.stringify(
            getUpsellOfferUtms(
              UpsellTypes.premium,
              UpsellOfferTypes.registration,
              t
            )
          )}`}
        />
      );
    }

    return (
      <Redirect
        to={`/offer/universal_premium_upsell?${qs.stringify(
          getUpsellOfferUtms(UpsellTypes.premium, UpsellOfferTypes.universal, t)
        )}`}
      />
    );
  }
  if (isUpsellOfferShoppingList(upsellList)) {
    if (registrationStep) {
      return (
        <Redirect
          to={`/offer/registration_shopping_list?${qs.stringify(
            getUpsellOfferUtms(
              UpsellTypes.shoppingList,
              UpsellOfferTypes.registration,
              t
            )
          )}`}
        />
      );
    }

    return (
      <Redirect
        to={`/offer/universal_shopping_list?${qs.stringify(
          getUpsellOfferUtms(
            UpsellTypes.shoppingList,
            UpsellOfferTypes.universal,
            t
          )
        )}`}
      />
    );
  }
  if (isPremiumShoppingListBundleOffer(upsellList)) {
    if (registrationStep) {
      return (
        <Redirect
          to={`/offer/registration_premium_shopping_list_bundle?${qs.stringify(
            getUpsellOfferUtms(
              UpsellTypes.premiumShoppingListBundle,
              UpsellOfferTypes.registration,
              t
            )
          )}`}
        />
      );
    }

    return (
      <Redirect
        to={`/offer/universal_premium_shopping_list_bundle?${qs.stringify(
          getUpsellOfferUtms(
            UpsellTypes.premiumShoppingListBundle,
            UpsellOfferTypes.universal,
            t
          )
        )}`}
      />
    );
  }
  if (isWorkoutsAllowed(user) && isUpsellOfferWorkouts(upsellList)) {
    if (registrationStep) {
      return (
        <Redirect
          to={`/offer/registration_workouts?${qs.stringify(
            getUpsellOfferUtms(
              UpsellTypes.workouts,
              UpsellOfferTypes.registration,
              t
            )
          )}`}
        />
      );
    }

    return (
      <Redirect
        to={`/offer/universal_workouts?${qs.stringify(
          getUpsellOfferUtms(
            UpsellTypes.workouts,
            UpsellOfferTypes.universal,
            t
          )
        )}`}
      />
    );
  }
  if (
    isKetoChocolateBarsAllowed(user) &&
    isUpsellOfferKetoChocolateBars(upsellList)
  ) {
    if (registrationStep) {
      return (
        <Redirect
          to={`/offer/registration_keto_chocolate_bars?${qs.stringify(
            getUpsellOfferUtms(
              UpsellTypes.ketoChocolateBars,
              UpsellOfferTypes.registration,
              t
            )
          )}`}
        />
      );
    }

    return (
      <Redirect
        to={`/offer/universal_keto_chocolate_bars?${qs.stringify(
          getUpsellOfferUtms(
            UpsellTypes.ketoChocolateBars,
            UpsellOfferTypes.universal,
            t
          )
        )}`}
      />
    );
  }
  if (settings.SHOW_COLLAGEN && isUpsellOfferCollagen(upsellList)) {
    if (registrationStep) {
      return (
        <Redirect
          to={`/offer/registration_collagen?${qs.stringify(
            getUpsellOfferUtms(
              UpsellTypes.collagen,
              UpsellOfferTypes.registration,
              t
            )
          )}`}
        />
      );
    }

    return (
      <Redirect
        to={`/offer/universal_collagen?${qs.stringify(
          getUpsellOfferUtms(
            UpsellTypes.collagen,
            UpsellOfferTypes.universal,
            t
          )
        )}`}
      />
    );
  }
  if (settings.CHAT_PAYWALL_ENABLED && isUpsellOfferChat(upsellList)) {
    if (registrationStep) {
      return (
        <Redirect
          to={`/offer/registration_chat?${qs.stringify(
            getUpsellOfferUtms(
              UpsellTypes.chat,
              UpsellOfferTypes.registration,
              t
            )
          )}`}
        />
      );
    }

    return (
      <Redirect
        to={`/offer/universal_chat?${qs.stringify(
          getUpsellOfferUtms(UpsellTypes.chat, UpsellOfferTypes.universal, t)
        )}`}
      />
    );
  }
  if (settings.SHOW_FAT_BURNER && isUpsellOfferFatBurner(upsellList)) {
    if (registrationStep) {
      return (
        <Redirect
          to={`/offer/registration_fat_burner?${qs.stringify(
            getUpsellOfferUtms(
              UpsellTypes.fatBurner,
              UpsellOfferTypes.registration,
              t
            )
          )}`}
        />
      );
    }

    return (
      <Redirect
        to={`/offer/universal_fat_burner?${qs.stringify(
          getUpsellOfferUtms(
            UpsellTypes.fatBurner,
            UpsellOfferTypes.universal,
            t
          )
        )}`}
      />
    );
  }
  if (settings.SHOW_ACV_GUMMIES && isUpsellOfferAcvGummies(upsellList)) {
    if (registrationStep) {
      return (
        <Redirect
          to={`/offer/registration_acv_gummies?${qs.stringify(
            getUpsellOfferUtms(
              UpsellTypes.acvGummies,
              UpsellOfferTypes.registration,
              t
            )
          )}`}
        />
      );
    }

    return (
      <Redirect
        to={`/offer/universal_acv_gummies?${qs.stringify(
          getUpsellOfferUtms(
            UpsellTypes.acvGummies,
            UpsellOfferTypes.universal,
            t
          )
        )}`}
      />
    );
  }
  if (isGenomPlusAllowed(user) && isUpsellOfferGenom(upsellList)) {
    if (registrationStep) {
      return (
        <Redirect
          to={`/offer/registration_genom?${qs.stringify(
            getUpsellOfferUtms(
              UpsellTypes.genom,
              UpsellOfferTypes.registration,
              t
            )
          )}`}
        />
      );
    }

    return (
      <Redirect
        to={`/offer/universal_genom?${qs.stringify(
          getUpsellOfferUtms(UpsellTypes.genom, UpsellOfferTypes.universal, t)
        )}`}
      />
    );
  }

  return <>{children}</>;
}

export default EnsureUserSeenUpsellOffer;
