// POSSIBLE-REFACTOR: move all this page content to .i18n file

let dateFnsCurrentLocale: Locale | undefined;

export const getCurrentDateFnsLocale = () => dateFnsCurrentLocale;
export const clearCurrentDateFnsLocale = () => {
  dateFnsCurrentLocale = undefined;
};

const getRealLocale = (locale: string) => {
  switch (locale) {
    case 'gr':
      return 'el';
    case 'en':
      return 'en-GB';
    case 'sr':
      return 'sr-Latn';
    case 'no':
      return 'nn';
    default:
      return locale;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const changeLocale = async (locale: string) => {
  const realLocale = getRealLocale(locale);
  const importedLocale = await import(`date-fns/locale/${realLocale}`);

  dateFnsCurrentLocale = importedLocale.default ?? undefined;
};
