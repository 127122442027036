import { User } from 'types/user';
import settings from './settings';

export const retrieveEnvVariableArray = (
  envArrayString: string
): string[] | null => {
  const splitArray = envArrayString.split(',');

  if (splitArray.length < 1 || splitArray[0] === '') return null;

  return splitArray;
};

export const getGenomPlusAllowedCategories = (): string[] | null =>
  retrieveEnvVariableArray(settings.GENOM_PLUS_CATEGORIES);

export const isGenomPlusAllowed = (user: User) => {
  const { category } = user;
  const allowedCategories = getGenomPlusAllowedCategories();

  if (!allowedCategories) {
    return false;
  }
  if (!allowedCategories.includes(category.toString())) {
    return false;
  }

  return true;
};
