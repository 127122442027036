import { isTodayISO } from 'helpers/dateHelper';
import { getUserCustomField } from 'helpers/userHelper';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { User } from 'types/user';

type Props = {
  children: React.ReactNode;
};

function EnsureGenomPlusPopupSeen({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const shouldSeePopupDate = getUserCustomField(user, 'genom_popup_show_at');
  const seenPopupDate = getUserCustomField(user, 'genom_popup_seen');

  if (isTodayISO(shouldSeePopupDate) && !seenPopupDate) {
    return <Redirect to="/genomplus/update-complete" />;
  }

  return <>{children}</>;
}

export default EnsureGenomPlusPopupSeen;
