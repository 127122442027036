import * as t from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (
  state = initialState.ketoChocolateBars,
  action: t.KetoChocolateBarsActionTypes
) => {
  switch (action.type) {
    case t.STORE_KETO_CHOCOLATE_BARS_CONFIRMATION_ADDRESS_FIELDS:
      return { ...state, confirmationData: action.payload };
    case t.RESET_KETO_CHOCOLATE_BARS_CONFIRMATION_ADDRESS_FIELDS:
      return { ...state, confirmationData: null };
    default:
      return state;
  }
};
