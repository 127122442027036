import * as React from 'react';

import { SVG } from 'types/svg';

function PremiumCrown(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={26}
      height={26}
      viewBox="0 0 26 26"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          id="prefix__0a596c7a-ee9f-4a4e-90d4-fd574f33d06e"
          d="M.049 0H5.09v5.042H.049z"
        />
      </defs>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <path
          fill="#FFD217"
          d="M15.478 13.132l-3.363-4.846-3.362 4.846-3.878-5.124L5.947 17.7h12.105l1.073-9.692-3.647 5.124zm-4.725 1.25L12 12.676l1.247 1.707L12 16.088l-1.247-1.705zm-4.806 4.743h12.104v-.713H5.947z"
        />
        <path
          fill="#FFD217"
          d="M9.64 8.429c-.766 0-1.387.622-1.387 1.389 0-.767-.622-1.389-1.388-1.389.766 0 1.388-.62 1.388-1.387 0 .766.621 1.387 1.387 1.387"
        />
        <g transform="translate(13.5 4.875)">
          <mask id="prefix__1e856c03-5a58-49f1-b40c-8c75c626a9fc" fill="#fff">
            <use xlinkHref="#prefix__0a596c7a-ee9f-4a4e-90d4-fd574f33d06e" />
          </mask>
          <path
            fill="#FFD217"
            d="M5.09 2.52a2.521 2.521 0 00-2.52 2.522A2.524 2.524 0 00.05 2.519 2.52 2.52 0 002.57 0a2.52 2.52 0 002.52 2.52"
            mask="url(#prefix__1e856c03-5a58-49f1-b40c-8c75c626a9fc)"
          />
        </g>
        <circle cx={12} cy={12} r={12} stroke="#FFD217" />
      </g>
    </svg>
  );
}

export default PremiumCrown;
