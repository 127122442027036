import { useState, useEffect } from 'react';

import settings from 'helpers/settings';

function useGooglePlaces() {
  const [isPlacesLoaded, setIsPlacesLoaded] = useState(false);

  useEffect(() => {
    const existingScript = document.getElementById('google-maps-script');

    if (existingScript) {
      setIsPlacesLoaded(true);
      return undefined; // return undefined to avoid running the rest of the hook and eslint disabled lines
    }

    const script = document.createElement('script');
    script.id = 'google-maps-script';

    const loadGooglePlaces = () => {
      try {
        script.src = `https://maps.googleapis.com/maps/api/js?key=${settings.GOOGLE_PLACES_API_KEY}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => setIsPlacesLoaded(true);
        script.onerror = () => setIsPlacesLoaded(false);

        document.head.appendChild(script);
      } catch (e) {
        setIsPlacesLoaded(false);
      }
    };

    loadGooglePlaces();

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return isPlacesLoaded;
}

export default useGooglePlaces;
