import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { getStyle, isKeto, isKetoGo } from 'helpers/themeHelper';
import {
  GENOM_PLUS_OFFER_TYPES,
  SHOPPINGLIST_BUNDLE_OFFER_TYPES,
  getGenomPlusOfferUtms,
  getPremiumShoppingListBundleUtms,
  getQueryParams,
  isPrimerPaymode,
} from 'helpers/offerHelper';
import { getPaymentUrl } from 'helpers/paymentHelper';
import { createAuditEvent } from 'helpers/auditEventHelper';

import useCache from 'hooks/useCache';

import { User } from 'types/user';
import { Offer } from 'types/offer';
import { UTMS } from 'types/program';
import { PaymentUrl } from 'types/payment';
import { PrimerInitEventType } from 'types/primer';

import PaymodeBtn from 'components/common/PaymodeBtn';
import PaymodeButton from 'components/Buttons/PaymodeButton';

import { getGA4SessionId } from 'helpers/gaHelper';
import PaymodesFooter from '../PaymodesFooter';
import PrimerCheckout from '../PrimerCheckout';

import base from './PaymodeModal.module.scss';
import diet from './PaymodeModal_diet.module.scss';

const s = getStyle(base, diet);

type Props = {
  user: User;
  offer: Offer;
  chance?: boolean;
  status?: boolean;
  purchaseSource?: string;
  selectedMultiOffer?: Offer;
  shippingId?: number;
  utms?: UTMS;
  customStyle?: string;
  selectedOfferPriceId?: number;
  overrideUtms?: UTMS;
  closeModal: () => void;
};
declare global {
  interface Window {
    gtag: any;
  }
}

type UtmsFn = (
  offerType: string,
  templateName: string
) => { utm_source: string; utm_medium: string; utm_campaign: string };

function PaymodeModal({
  status,
  user,
  offer,
  chance,
  purchaseSource,
  selectedMultiOffer,
  shippingId,
  utms,
  customStyle,
  selectedOfferPriceId,
  overrideUtms,
  closeModal,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPaymode, setLoadingPaymode] = useState('');
  const { t } = useTranslation('');
  const acceptedOffer = !selectedMultiOffer ? offer : selectedMultiOffer;
  const { paymodes } = acceptedOffer;
  const queryString = getQueryParams();
  let metaData = {
    origin_host: window.location.origin,
    chance: chance || false,
    purchase_source: purchaseSource,
    shipping_address_id: shippingId?.toString() || undefined,
    utm_source: utms?.utm_source || (queryString.utm_source as string),
    utm_medium: utms?.utm_medium || (queryString.utm_medium as string),
    utm_campaign: utms?.utm_campaign || (queryString.utm_campaign as string),
    utm_term: utms?.utm_term || (queryString.utm_term as string),
    utm_content: utms?.utm_content || (queryString.utm_content as string),
    ga_session_id: getGA4SessionId(),
    offer_price_id: selectedOfferPriceId,
  };

  if (!isEmpty(overrideUtms)) {
    metaData = {
      ...metaData,
      ...overrideUtms,
    };
  }

  // This is needed due to different template tests for offers
  const overrideUtmsBasedonOfferType = (
    offerTypes: string[],
    utmsFn: UtmsFn
  ) => {
    if (!offerTypes.includes(acceptedOffer.offer_type)) return;
    const { utm_source, utm_medium, utm_campaign } = utmsFn(
      acceptedOffer.offer_type,
      acceptedOffer.template_name
    );
    metaData.utm_source = utm_source;
    metaData.utm_medium = utm_medium;
    metaData.utm_campaign = utm_campaign;
  };

  overrideUtmsBasedonOfferType(
    SHOPPINGLIST_BUNDLE_OFFER_TYPES,
    getPremiumShoppingListBundleUtms
  );

  overrideUtmsBasedonOfferType(GENOM_PLUS_OFFER_TYPES, getGenomPlusOfferUtms);

  // PremiumUpsellV2: according to product requirements -
  // use the same UTMs namings but with the added suffix `_V2`
  if (acceptedOffer.template_name === 'PremiumUpsellV2') {
    metaData.utm_campaign = `${metaData.utm_campaign}_V2`;
  }

  const { isLoadedFromCache } = useCache();

  // If the page is loaded from cache then set loading state to false - this is used for back/forward buttons
  useEffect(() => {
    if (isLoadedFromCache) {
      setIsLoading(false);
    }
  }, [isLoadedFromCache]);

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      const escapeChar = 27;

      if (e.keyCode === escapeChar) {
        closeModal();
      }
    };

    if (status) {
      createAuditEvent({
        source: user,
        target: offer,
        eventType: 'offer_choose_paymode_seen',
        description: null,
      });
    }

    document.addEventListener('keydown', onKeydown);

    return () => document.removeEventListener('keydown', onKeydown);
  }, [closeModal, offer, user, status]);

  const submitPayment = (paymode: string) => {
    setIsLoading(true);
    setLoadingPaymode(paymode);

    createAuditEvent({
      source: user,
      target: offer,
      eventType: 'offer_chose_paymode',
      description: paymode,
    });

    if (window.gtag) {
      window.gtag('event', 'Choose paymode', {
        event_category: 'Payments',
        event_label: paymode,
      });
    }

    const urlParams: PaymentUrl = {
      paymode,
      userId: user.id,
      offerId: acceptedOffer.id,
      queryParams: metaData,
    };

    window.location.assign(getPaymentUrl(urlParams));
  };

  const renderPaymode = () => (
    <>
      {paymodes.map((paymode, index) => {
        if (isPrimerPaymode(paymode as string)) {
          return (
            <PrimerCheckout
              key={paymode}
              user={user}
              offer={acceptedOffer}
              metaData={metaData}
              primerEventType={PrimerInitEventType.chosePaymode}
              disabled={isLoading}
              isLoading={isLoading && loadingPaymode === paymode}
              currentPaymode={loadingPaymode}
              onClick={() => {
                setIsLoading(true);
                setLoadingPaymode(paymode as string);
              }}
              onClose={() => {
                setIsLoading(false);
                setLoadingPaymode('');
              }}
            />
          );
        }

        if (isKeto() || isKetoGo()) {
          return (
            <PaymodeButton
              key={paymode}
              label={t(`paymodes.${paymode}`)}
              paymode={paymode as string}
              disabled={isLoading}
              isLoading={isLoading && loadingPaymode === paymode}
              onClick={() => submitPayment(paymode as string)}
            />
          );
        }

        return (
          <PaymodeBtn
            key={paymode}
            onClick={() => submitPayment(paymode as string)}
            label={paymode}
            index={index}
            size={paymodes.length}
            disabled={isLoading}
          />
        );
      })}
    </>
  );

  if (!status) {
    return null;
  }

  return (
    <div className={`modal open-${status} ${customStyle && s[customStyle]}`}>
      <div className="modal__body">
        <div
          className="modal__body__close_btn"
          onClick={closeModal}
          onKeyPress={closeModal}
          role="button"
          tabIndex={0}
          aria-label={t('globals.close')}
        />
        <div className="modal__body__head">
          <h3 className={s.title}>{t('modals.paymode.title')}</h3>
        </div>
        <div className="modal__body__content">
          <div style={{ clear: 'both' }} />
          {renderPaymode()}
          <PaymodesFooter paymodes={acceptedOffer.paymodes} />
        </div>
      </div>
    </div>
  );
}

export default PaymodeModal;
