import React from 'react';

import { isUnsubscribeV6FlowAvailable } from 'helpers/quizHelper';
import browserHistory from 'helpers/history';
import { useParams } from 'react-router-dom';

type Props = {
  children: JSX.Element;
};

function EnsureUnsubscribeV6Available({ children }: Props) {
  const { step } = useParams<{ step: string }>();

  if (!isUnsubscribeV6FlowAvailable(step)) {
    browserHistory.replace('/');

    return null;
  }

  return <>{children}</>;
}

export default EnsureUnsubscribeV6Available;
