/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocaleDirection } from 'i18n';

import { Benefits, ExerciseType } from 'types/days';
import { ExpandedExercise } from 'types/workouts';
import { convertToMin } from 'helpers/timeHelper';

import Benefit from '../WorkoutBenefit';
import WorkoutButton from '../WorkoutButton';

import s from './WorkoutPreview.module.scss';

type Props = {
  isOpen: boolean;
  workoutBenefits: Benefits[];
  exercise: ExerciseType | ExpandedExercise | null;
  handleClose: () => void;
};

const TIME_OUT_DURATION = 180;

function WorkoutPreview({
  isOpen,
  workoutBenefits,
  exercise,
  handleClose,
}: Props) {
  const { t } = useTranslation('workouts');
  const [isAnimation, setIsAnimation] = useState(false);
  const [dragStart, setDragStart] = useState(false);
  const [initialY, setInitialY] = useState<number | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const isMobileDevice = window.innerWidth < 480;

  useEffect(() => {
    setIsAnimation(false);
  }, [isOpen]);

  // Close modal on escape key press
  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      const escapeChar = 27;
      if (e.keyCode === escapeChar) {
        setIsAnimation(true);
        setTimeout(() => {
          handleClose();
        }, TIME_OUT_DURATION);
      }
    };
    document.addEventListener('keydown', onKeydown);

    return () => document.removeEventListener('keydown', onKeydown);
  }, [handleClose]);

  if (!isOpen) return null;
  if (!exercise) return null;

  const {
    title,
    duration,
    repetitions,
    adjusted_repetitions,
    description,
    adjusted_duration,
    video_url,
  } = exercise;
  let periodicity = duration
    ? `${convertToMin(duration, true)}`
    : `${repetitions} ${t('workouts.values.repetitions')}`;
  if (adjusted_repetitions || adjusted_duration) {
    periodicity = adjusted_duration
      ? `${convertToMin(adjusted_duration, true)}`
      : `${adjusted_repetitions} ${t('workouts.values.repetitions')}`;
  }

  const closeModal = () => {
    setIsAnimation(true);
    // Needed for animation
    setTimeout(() => {
      handleClose();
    }, 180);
  };

  const handleClick = () => {
    closeModal();
  };

  // Start: Mobile notch handling
  const handleMobileNotch = (pageY: number) => {
    if (!initialY) return;
    if (initialY < pageY) {
      closeModal();
      return;
    }

    (modalRef.current as HTMLDivElement).style.setProperty(
      'transform',
      `translateY(0%)`,
      'important'
    );
  };

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const { pageY } = e.changedTouches[0];
    setDragStart(true);
    setInitialY(pageY);
  };

  const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!initialY) return;
    const { pageY } = e.changedTouches[0];
    handleMobileNotch(pageY);
  };

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!dragStart) return;
    if (e.changedTouches.length > 1) e.preventDefault();
    const { pageY } = e.touches[0];

    (modalRef.current as HTMLDivElement).style.setProperty(
      'transform',
      `translateY(+${pageY - (initialY as number)}px)`,
      'important'
    );
  };
  // End: Mobile notch handling

  return (
    <div className={s.wrapper} dir={getLocaleDirection()}>
      <div
        className={`${s.container} ${isAnimation && s.modalClosed}`}
        ref={modalRef}
      >
        {isMobileDevice && (
          <div
            className={s.mobileHandleContainer}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove}
            role="presentation"
          >
            <div className={s.mobileHandle}>
              {/* Cause self-enclosed div cannot be */}
            </div>
          </div>
        )}
        <div className={s.content}>
          <p className={s.title}>{title}</p>
          <div className={s.benefitsWrapper}>
            {workoutBenefits.map((benefit: Benefits) => (
              <Benefit key={benefit} benefit={benefit} />
            ))}
          </div>
          <video className={s.heroVideo} autoPlay loop muted playsInline>
            <source src={video_url as string} type="video/mp4" />
          </video>
          <p className={s.titleSmall}>
            {t('workouts.preview.duration')}{' '}
            <span className={s.light}>{periodicity}</span>
          </p>
          <p className={s.titleSmall}>{t('workouts.preview.description')}</p>
          <p className={s.description}>{description}</p>
        </div>
        <WorkoutButton
          label={t('workouts.preview.button')}
          onClick={handleClick}
        />
      </div>
    </div>
  );
}

export default WorkoutPreview;
