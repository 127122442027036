import React from 'react';

import { SVG } from 'types/svg';

function BannerMail(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 36 31" {...props}>
      <defs>
        <style>{'.prefix__cls-1{fill:#fff}'}</style>
      </defs>
      <g id="prefix__Mail_icon" data-name="Mail icon">
        <path
          className="prefix__cls-1"
          d="M19.22 19.9l-5.43 3.4a1 1 0 0 1-1 0L1 16.26a2 2 0 0 1-1-1.72V12a1 1 0 0 1 1-1h13a12 12 0 0 0 5.22 8.9z"
        />
        <path
          className="prefix__cls-1"
          d="M26 22v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V18.3a.5.5 0 0 1 .75-.43l12.14 7.28a.76.76 0 0 0 .39.1.83.83 0 0 0 .4-.11l7-4.38A12 12 0 0 0 26 22z"
        />
        <circle cx={26} cy={10} r={8} fill="#ee472c" />
        <path
          className="prefix__cls-1"
          d="M26 2a8 8 0 1 1-8 8 8 8 0 0 1 8-8m0-2a10 10 0 1 0 10 10A10 10 0 0 0 26 0z"
        />
        <path
          className="prefix__cls-1"
          d="M23.28 7.23a4.6 4.6 0 0 0 .84-.08 2.22 2.22 0 0 0 .74-.24 1.71 1.71 0 0 0 .56-.43 1.28 1.28 0 0 0 .31-.65h1.11v8.4h-1.5V8.31h-2.06z"
        />
      </g>
    </svg>
  );
}

export default BannerMail;
