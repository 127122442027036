import React from 'react';
import { Redirect } from 'react-router-dom';

import { isAcvGummiesEnabled } from 'helpers/physicalProductHelper';

type Props = {
  children: React.ReactNode;
};

function EnsureAcvGummiesAllowed({ children }: Props) {
  if (!isAcvGummiesEnabled()) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
}

export default EnsureAcvGummiesAllowed;
