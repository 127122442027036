import { TFunction } from 'i18next';
import { getCurrentLocale } from 'i18n';
import { allCountries } from 'country-telephone-data';

import settings from 'helpers/settings';
import { CollagenTemplate } from 'types/offer';
import { User, UserStatistics } from 'types/user';
import { ShippingAddress, Subscription } from 'types/subscription';
import { hasAppleSubscription } from './userHelper';

export const getAge = (user: User) => {
  if (user.age > 30) {
    return 30;
  }
  if (user.age < 25) {
    return user.age;
  }

  return 25;
};

export const getWaterAmount = (user: User, t: TFunction, template: string) => {
  if (user.unit_system === 'si') {
    return t(
      `collagen:offer.${CollagenTemplate[template]}.water_measurements.metric`
    );
  }

  return t(
    `collagen:offer.${CollagenTemplate[template]}.water_measurements.imperial`
  );
};

const getUserUnitSystemTranslationsValue = (user: User, t: TFunction) => {
  if (user.unit_system === 'si') {
    return t('app:units.kg');
  }

  return t('app:units.lb');
};

export const getUserWeight = (user: User, t: TFunction) => {
  const { stats } = user;

  if ((stats as UserStatistics).lose < 1) {
    return getUserUnitSystemTranslationsValue(user, t);
  }

  return user.unit_system === 'si'
    ? `${user.stats?.lose} ${getUserUnitSystemTranslationsValue(user, t)}`
    : `${user.stats?.lose_in_lbs} ${getUserUnitSystemTranslationsValue(
        user,
        t
      )}`;
};

type AllowedCountries = {
  value: string;
  emoji: string;
  name: string;
}[];

export type AddressComponentsTypes = {
  long_name: string;
  short_name: string;
  types: string[];
}[];

export const ALLOWED_COUNTRIES: AllowedCountries = [
  {
    value: 'AT',
    emoji: '🇦🇹',
    name: 'Austria',
  },
  {
    value: 'BE',
    emoji: '🇧🇪',
    name: 'Belgium',
  },
  {
    value: 'BG',
    emoji: '🇧🇬',
    name: 'Bulgaria',
  },
  {
    value: 'HR',
    emoji: '🇭🇷',
    name: 'Croatia',
  },
  {
    value: 'CY',
    emoji: '🇨🇾',
    name: 'Cyprus',
  },
  {
    value: 'CZ',
    emoji: '🇨🇿',
    name: 'Czechia',
  },
  {
    value: 'DK',
    emoji: '🇩🇰',
    name: 'Denmark',
  },
  {
    value: 'EE',
    emoji: '🇪🇪',
    name: 'Estonia',
  },
  {
    value: 'FI',
    emoji: '🇫🇮',
    name: 'Finland',
  },
  {
    value: 'FR',
    emoji: '🇫🇷',
    name: 'France',
  },
  {
    value: 'DE',
    emoji: '🇩🇪',
    name: 'Germany',
  },
  {
    value: 'GR',
    emoji: '🇬🇷',
    name: 'Greece',
  },
  {
    value: 'HU',
    emoji: '🇭🇺',
    name: 'Hungary',
  },
  {
    value: 'IE',
    emoji: '🇮🇪',
    name: 'Ireland',
  },
  {
    value: 'IT',
    emoji: '🇮🇹',
    name: 'Italy',
  },
  {
    value: 'LV',
    emoji: '🇱🇻',
    name: 'Latvia',
  },
  {
    value: 'LT',
    emoji: '🇱🇹',
    name: 'Lithuania',
  },
  {
    value: 'LU',
    emoji: '🇱🇺',
    name: 'Luxembourg',
  },
  {
    value: 'MT',
    emoji: '🇲🇹',
    name: 'Malta',
  },
  {
    value: 'NL',
    emoji: '🇳🇱',
    name: 'Netherlands',
  },
  {
    value: 'PL',
    emoji: '🇵🇱',
    name: 'Poland',
  },
  {
    value: 'PT',
    emoji: '🇵🇹',
    name: 'Portugal',
  },
  {
    value: 'RO',
    emoji: '🇷🇴',
    name: 'Romania',
  },
  {
    value: 'SK',
    emoji: '🇸🇰',
    name: 'Slovakia',
  },
  {
    value: 'SI',
    emoji: '🇸🇮',
    name: 'Slovenia',
  },
  {
    value: 'ES',
    emoji: '🇪🇸',
    name: 'Spain',
  },
  {
    value: 'SE',
    emoji: '🇸🇪',
    name: 'Sweden',
  },
  {
    value: 'US',
    emoji: '🇺🇸',
    name: 'United States',
  },
  {
    value: 'MX',
    emoji: '🇲🇽',
    name: 'Mexico',
  },
  {
    value: 'CA',
    emoji: '🇨🇦',
    name: 'Canada',
  },
];

const isUserCountryAllowed = (country: string) => {
  const allowedCountry = ALLOWED_COUNTRIES.find(
    (allCountry) => allCountry.value === country
  );

  if (!allowedCountry) {
    return false;
  }

  return true;
};

export const setInitialUserCountry = (
  shippingAddress: ShippingAddress | null,
  user: User
) => {
  if (
    shippingAddress?.country &&
    isUserCountryAllowed(shippingAddress.country)
  ) {
    return shippingAddress.country;
  }
  if (isUserCountryAllowed(user.country)) {
    return user.country;
  }

  return '';
};

export const getAllCountryPhoneCodes = () =>
  allCountries
    .map(({ iso2, dialCode, name }) => {
      const country = iso2.toUpperCase();

      return {
        key: dialCode,
        value: country,
        label: `+${dialCode}(${country})`,
      };
    })
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

type CountryCodeOption = {
  key: string;
  value: string;
  label: string;
};

export const getCountryPhoneCodeByCountry = (currentCountry: string) => {
  const countryCodes = getAllCountryPhoneCodes();

  return countryCodes.find(
    (country) => country.value === currentCountry
  ) as CountryCodeOption;
};

export const getCountryPhoneCodeByPhonePrefix = (phonePrefix: string) => {
  const countryCodes = getAllCountryPhoneCodes();

  return countryCodes.find(
    (country) => country.key === phonePrefix
  ) as CountryCodeOption;
};

export const isCollagenAvailable = (user: User): boolean => {
  if (!settings.SHOW_COLLAGEN) {
    return false;
  }
  if (hasAppleSubscription(user)) {
    return false;
  }

  return true;
};

export const getActiveCollagenSubscription = (user: User) =>
  user.subscriptions.find(
    (subscription) =>
      subscription.item_type === 'collagen' && subscription.status === 'active'
  );

export const getLatestUnsubscribedCollagenSubscription = (user: User) => {
  const unsubscribedSubscriptions = user.subscriptions.filter(
    (subscription) =>
      subscription.status === 'unsubscribed' &&
      subscription.item_type === 'collagen'
  );

  if (!unsubscribedSubscriptions) return null;

  const newestUnsubscribeSubscription = unsubscribedSubscriptions
    .sort(
      (unsubA, unsubB) =>
        Date.parse(unsubA.unsubscribed_at as string) -
        Date.parse(unsubB.unsubscribed_at as string)
    )
    .reverse();

  return newestUnsubscribeSubscription[0];
};

export const isCollagenSubscriptionActive = (user: User): boolean => {
  if (!isCollagenActive(user)) return false;

  const activeSubscription = getActiveCollagenSubscription(user);
  if (!activeSubscription) return false;

  return true;
};

export const isCollagenSubscriptionUnsubscribed = (user: User): boolean => {
  if (!isCollagenActive(user)) return false;

  const unsubscribedSubscription =
    getLatestUnsubscribedCollagenSubscription(user);
  if (!unsubscribedSubscription) return false;

  return true;
};

export const isCollagenActive = (user: User) =>
  user.access.includes('collagen');

export const getCollagenSubscription = (user: User): Subscription | null => {
  const activeSubscription = getActiveCollagenSubscription(user);
  if (isCollagenActive(user) && activeSubscription) return activeSubscription;

  const unsubscribedSubscription =
    getLatestUnsubscribedCollagenSubscription(user);
  if (unsubscribedSubscription) return unsubscribedSubscription;

  return null;
};

export const isCollagenPauseAvailable = () => settings.PAUSE_COLLAGEN;

export const initialEditableFieldsState = {
  streetName: false,
  city: false,
  state: false,
  postalCode: false,
};

export const initialFormDataGAFields = {
  streetName: '',
  apartmentNumber: '',
  city: '',
  state: '',
  postalCode: '',
};

export const PAUSABLE_WEEKS = [
  {
    value: 1,
    name: '1 Week',
  },
  {
    value: 2,
    name: '2 Week',
  },
  {
    value: 3,
    name: '3 Week',
  },
  {
    value: 4,
    name: '4 Week',
  },
  {
    value: 5,
    name: '5 Week',
  },
  {
    value: 6,
    name: '6 Week',
  },
];

export const setInitialCollagenFormData = (
  collagenSubscription: Subscription
) => {
  if (!collagenSubscription)
    return {
      firstName: '',
      lastName: '',
      streetName: '',
      apartmentNumber: '',
      city: '',
      state: '',
      postalCode: '',
      phoneNumber: '',
    };

  return {
    firstName: collagenSubscription?.shipping_address?.first_name
      ? collagenSubscription.shipping_address.first_name
      : '',
    lastName: collagenSubscription?.shipping_address?.last_name
      ? collagenSubscription.shipping_address.last_name
      : '',
    streetName: collagenSubscription?.shipping_address?.address
      ? collagenSubscription.shipping_address.address
      : '',
    apartmentNumber: collagenSubscription?.shipping_address?.address2
      ? collagenSubscription.shipping_address.address2
      : '',
    city: collagenSubscription?.shipping_address?.city
      ? collagenSubscription.shipping_address.city
      : '',
    state: collagenSubscription?.shipping_address?.state
      ? collagenSubscription.shipping_address.state
      : '',
    postalCode: collagenSubscription?.shipping_address?.zip
      ? collagenSubscription.shipping_address.zip
      : '',
    phoneNumber: collagenSubscription?.shipping_address?.phone_number
      ? collagenSubscription.shipping_address.phone_number
      : '',
  };
};

export const getCollagenFullAddress = (shipping_address: ShippingAddress) => {
  const {
    address,
    address2,
    city,
    country,
    zip,
    first_name,
    last_name,
    phone_number,
    phone_prefix,
  } = shipping_address;
  const combinedAddress = `${address}${address2 && `-${address2}`}`;
  const countryFullName = ALLOWED_COUNTRIES.find(
    (currCountry) => currCountry.value === country
  )?.name;
  const fullZipCode = `${country}-${zip}`;

  return `${combinedAddress}, ${city}, ${fullZipCode}, ${countryFullName}, ${first_name} ${last_name} ${phone_prefix}${phone_number}`;
};

export const isLocaleAllowedForCollagen = (): boolean => {
  const allowedLocales = ['es', 'fr', 'hu', 'ro', 'de'];
  if (!allowedLocales.includes(getCurrentLocale())) return false;

  return true;
};
