export type SVG = {
  fill?: string;
  className?: string;
  onClick?: () => void;
};

export type SvgIconProps = {
  filename: string | JSX.Element;
  fill?: string;
  className?: string;
  onClick?: () => void;
};

export enum SVGFillColors {
  green = '#60bd78',
  white = '#ffffff',
  blue = '#0081ff',
  gray = '#737980',
  lightGray = '#b8babc',
  orange = '#fdbe13',
  black = '#000000',
  gold = '#e7b43e',
  silver = '#a6a6a6',
  goldDark = '#d2a439',
}
