import React, { useRef, useEffect } from 'react';
import { Spinner } from 'components/common';
import { useOnScreen } from './CalendarHook';

import s from './Loader.module.scss';

const Loader = ({ onVisible, isLoading }) => {
  const loaderRef = useRef();
  const isVisible = useOnScreen(loaderRef, '70px');

  useEffect(() => {
    if (isVisible && !isLoading) {
      onVisible();
    }
    // eslint-disable-next-line
  }, [isVisible]);

  if (!isLoading) {
    return <div className={s.wrapper} ref={loaderRef} />;
  }

  return (
    <div className={s.wrapper}>
      <Spinner />
    </div>
  );
};

export default Loader;
