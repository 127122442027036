import React from 'react';

function FastingCheckmark() {
  return (
    <svg width={100} height={99} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          x1="100%"
          y1="1.105%"
          x2="0%"
          y2="98.895%"
          id="prefix__f47944cf-98f6-4e6c-a480-6e9b725872f4"
        >
          <stop stopColor="#03CEA4" offset="0%" />
          <stop stopColor="#29ABE2" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M364.921 282.1c-13.691 10.4-31.369 33.35-41.247 53.657l-4.333 9.08-6.759-12.712c-6.586-12.383-6.932-12.713-10.572-10.401-2.08 1.32-7.972 4.788-12.825 7.594-5.026 2.972-9.185 5.614-9.185 5.944 0 .33 3.813 2.807 8.319 5.613 4.68 2.807 13.865 10.567 20.624 17.171l12.305 11.887 6.585-9.906c13.345-19.482 33.622-40.945 44.367-46.393 4.333-2.147 4.506-2.642 2.773-7.43-1.04-3.137-1.386-10.401-.866-18.161.693-7.264.866-13.043.693-13.043-.173 0-4.68 3.137-9.879 7.1"
        transform="translate(-278 -273)"
        fill="url(#prefix__f47944cf-98f6-4e6c-a480-6e9b725872f4)"
        stroke="#FAFAFA"
        strokeWidth={4}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FastingCheckmark;
