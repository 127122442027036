/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Timer from 'easytimer.js';

import { FileExtension } from 'helpers/imageHelper';
import {
  ExpandedExercise,
  InprogressWorkoutState,
  InternalWorkoutState,
} from 'types/workouts';

import { doneExercise, skipWorkoutPhase } from 'actions/workoutsActions';
import useExerciseTimer from 'hooks/workouts/useExerciseTimer';

import { ResponsiveImage } from 'components/common';
import WorkoutActionToolbar from '../WorkoutActionToolbar';

import s from './WorkoutsFooter.module.scss';

type CountdownProps = {
  exerciseTimer: Timer;
  isCountdownTimer: boolean;
  startTimer: () => void;
  stopTimer: () => void;
  continuesExercise: () => void;
};

const Countdown = ({
  exerciseTimer,
  isCountdownTimer,
  startTimer,
  stopTimer,
  continuesExercise,
}: CountdownProps) => {
  useEffect(() => {
    // Start only when countdown timer has gone
    if (isCountdownTimer) {
      startTimer();
    } // Fake start, otherwise 00:30 intial value cant be added
    if (!isCountdownTimer) {
      continuesExercise();
    } // Start the timer when countdown has gone

    return () => {
      stopTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountdownTimer]);

  return <>{exerciseTimer.getTimeValues().toString(['minutes', 'seconds'])}</>;
};

type Props = {
  exercise: ExpandedExercise;
  globalTimer: Timer;
  isModalsOpen: boolean;
  isCountdownTimer: boolean;
  isOverlayOpen: boolean;
  handlePreview: () => void;
  handleWorkoutPause: () => void;
};

function WorkoutsFooter({
  exercise,
  globalTimer,
  isModalsOpen,
  isCountdownTimer,
  isOverlayOpen,
  handlePreview,
  handleWorkoutPause,
}: Props) {
  const { t } = useTranslation('workouts');
  const dispatch = useDispatch();
  const workout = useSelector<any, any>((store) => store.rootReducer.workout);
  const workouts = useSelector<any, InternalWorkoutState>(
    (store) => store.rootReducer.workouts
  );
  const inprogressWorkout =
    workouts.inprogressWorkoutState as InprogressWorkoutState;
  const { title, repetitions } = exercise;
  const isRepetitions = !!(repetitions && repetitions > 0);
  const { workoutPhase } = inprogressWorkout;
  const isSkippable = workoutPhase !== 'exercise';
  const {
    isTargetAchieved,
    exerciseTimer,
    startExercise,
    pauseExercise,
    continuesExercise,
    handlePauseExercise,
    stopExercise,
  } = useExerciseTimer({ exercise });

  const retrieveGlobalWorkoutSeconds = () =>
    globalTimer.getTotalTimeValues().seconds;

  // For handling done exercise flow
  useEffect(() => {
    if (isTargetAchieved) {
      dispatch(doneExercise(workouts, workout, retrieveGlobalWorkoutSeconds()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTargetAchieved]);

  // For handling abandoned modals flow
  useEffect(() => {
    pauseExercise(isModalsOpen, isCountdownTimer, isOverlayOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalsOpen, isCountdownTimer, isOverlayOpen]);

  const pauseUnpauseExercise = () => {
    handlePauseExercise();
    handleWorkoutPause();
  };

  const handleSkip = () => {
    dispatch(
      skipWorkoutPhase(workouts, workout, retrieveGlobalWorkoutSeconds())
    );
  };

  return (
    <div className={s.footerContainer}>
      <div className={s.content}>
        <div className={s.titleContainer}>
          <p className={s.title}>{title}</p>
          <button
            className={`${s.infoButton} ${s.buttonNormalize}`}
            type="button"
            onClick={handlePreview}
          >
            <ResponsiveImage
              imagePath="./workouts/info-mark"
              imageExtension={FileExtension.png}
              altText="info-mark"
              style={s.infoMark}
            />
          </button>
        </div>
        <p className={s.periodiocityTitle}>
          {isRepetitions ? (
            repetitions
          ) : (
            <Countdown
              exerciseTimer={exerciseTimer}
              isCountdownTimer={isCountdownTimer}
              startTimer={startExercise}
              stopTimer={stopExercise}
              continuesExercise={continuesExercise}
            />
          )}
        </p>

        {isRepetitions && (
          <p className={s.repsSubtitile}>{t('workouts.values.repetitions')}</p>
        )}
        {isSkippable && (
          <button
            type="button"
            className={`${s.buttonNormalize} ${s.skipButton}`}
            onClick={handleSkip}
          >
            {t(`workouts.inprogress.skip.${workoutPhase}`)}
          </button>
        )}
      </div>
      <WorkoutActionToolbar
        currentExercise={exercise}
        isFinished={isTargetAchieved}
        globalTimer={globalTimer}
        pauseUnpauseExercise={pauseUnpauseExercise}
      />
    </div>
  );
}

export default WorkoutsFooter;
