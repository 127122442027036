import { addError } from 'redux-flash-messages';
import { getUserAccessAPI } from 'api/accessApi';

import { ThunkActionType } from 'store/types';
import { AccessItems } from 'types/access';
import * as t from 'actions/actionTypes';

export const accessItemsLoad = () => ({
  type: t.ACCESS_ITEMS_LOAD,
});

export const accessItemsSuccess = (accessItems: AccessItems) => ({
  type: t.ACCESS_ITEMS_SUCCESS,
  accessItems,
});

export const accessItemsError = () => ({
  type: t.ACCESS_ITEMS_ERROR,
});

export const getAccessItems = (): ThunkActionType => (dispatch) => {
  dispatch(accessItemsLoad());
  return getUserAccessAPI()
    .then((response) => {
      if (!response) throw Error();

      dispatch(accessItemsSuccess(response));
    })
    .catch((error) => {
      addError({ text: error });
      dispatch(accessItemsError());
    });
};
