import React from 'react';

import { SVG } from 'types/svg';

function LogoHealthgenom(props: SVG) {
  return (
    <svg
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          id="prefix__c878c84e-df83-46a3-89b7-ca606744ce23"
          d="M0 0h16v16H0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle fill="#2884AA" cx={12} cy={12} r={12} />
        <g transform="translate(4 4)">
          <mask id="prefix__0157aa11-e72b-447a-a272-0d1eb7e8383c" fill="#fff">
            <use xlinkHref="#prefix__c878c84e-df83-46a3-89b7-ca606744ce23" />
          </mask>
          <path
            d="m11.678 7.524-.686 1.174-1.426-4.494-1.552 6.014-1.441-7.06-2.125 4.366H2.82v.953h2.223l1.15-2.363 1.801 7.585 1.603-6.246 1.135 3.578 1.492-2.554h2.806A7.05 7.05 0 0 1 8 15.047 7.055 7.055 0 0 1 .953 8 7.055 7.055 0 0 1 8 .953a7 7 0 0 1 5.603 2.772l.037.049h1.184l-.464-.627A7.946 7.946 0 0 0 8 0C3.59 0 0 3.589 0 8s3.589 8 8 8a8.003 8.003 0 0 0 8-8v-.476h-4.322z"
            fill="#FFF"
            mask="url(#prefix__0157aa11-e72b-447a-a272-0d1eb7e8383c)"
          />
        </g>
      </g>
    </svg>
  );
}

export default LogoHealthgenom;
