import React from 'react';

import { SVG } from 'types/svg';

function SendHealthgenom(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.062 2.938a9.958 9.958 0 0 0-14.124 0A9.958 9.958 0 0 0 0 10c0 5.52 4.48 10 10 10a9.958 9.958 0 0 0 7.062-2.938 9.958 9.958 0 0 0 0-14.124zm-1.483 7.151c-.03.148-.149.237-.267.297-1.543.771-3.116 1.543-4.66 2.344L6.38 14.866c-.149.06-.297.12-.445.06-.297-.09-.416-.386-.327-.683.208-.534.386-1.068.594-1.602a26.15 26.15 0 0 0 .593-1.632c.03-.06.06-.089.149-.119 1.75-.296 3.53-.593 5.281-.89.09 0 .178-.03.238-.03v-.029c-.267-.03-.505-.089-.772-.119a143.987 143.987 0 0 0-3.471-.563c-.445-.06-.86-.149-1.306-.208-.09 0-.119-.03-.149-.119A104.362 104.362 0 0 0 5.55 5.698c-.09-.208-.06-.386.089-.534.178-.178.356-.208.593-.149.06.03.09.03.149.06 2.937 1.483 5.875 2.967 8.813 4.421.326.118.445.326.386.593z"
        fill="#FFCA36"
      />
    </svg>
  );
}

export default SendHealthgenom;
