import React from 'react';

function UnsubscribeV6QuoteMonacofit() {
  return (
    <svg width={56} height={56} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle fill="#60BD78" cx={28} cy={28} r={28} />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M40.61 32.513a2.766 2.766 0 00-2.127-.106 2.77 2.77 0 00-1.13.754 2.781 2.781 0 00-.874-.648 2.766 2.766 0 00-2.128-.106 2.765 2.765 0 00-1.576 1.421l-1.857 3.675a2.14 2.14 0 01-.103-.658v-4.258a.696.696 0 00.013-.13V26.57c4.072-.357 7.276-3.786 7.276-7.949v-4.77a2.42 2.42 0 00-2.416-2.417 2.42 2.42 0 00-2.417 2.417v4.77a3.15 3.15 0 01-3.147 3.146h-.912a4.445 4.445 0 001.202-3.043v-.463a4.468 4.468 0 00-4.463-4.463 4.468 4.468 0 00-4.464 4.463v.463c0 1.176.458 2.245 1.203 3.043h-.913a3.15 3.15 0 01-3.147-3.146v-4.77a2.42 2.42 0 00-2.416-2.417 2.42 2.42 0 00-2.417 2.417v4.77c0 4.177 3.226 7.615 7.318 7.953v5.884l.001.022v4.366c0 .195.007.393.022.586a.703.703 0 001.402-.106 6.352 6.352 0 01-.018-.48V33.16h6.887v3.685a3.564 3.564 0 003.56 3.56 3.577 3.577 0 003.214-2.027l1.983-3.923.008-.016a1.37 1.37 0 01.782-.708 1.38 1.38 0 011.712 1.88l-1.983 3.922-.008.016a6.344 6.344 0 01-5.707 3.612 6.323 6.323 0 01-6.316-6.316v-.365a.703.703 0 10-1.406 0v.365a7.718 7.718 0 003.271 6.307 6.236 6.236 0 01-3.6-1.357.703.703 0 10-.873 1.101 7.636 7.636 0 004.792 1.671 7.65 7.65 0 002.056-.283c.66.184 1.357.283 2.076.283a7.756 7.756 0 006.974-4.408l1.983-3.923.008-.016a2.788 2.788 0 00-1.324-3.707zm-17.716-13.79v-.463a3.06 3.06 0 013.057-3.057 3.06 3.06 0 013.057 3.057v.463a3.061 3.061 0 01-2.77 3.043h-.575a3.06 3.06 0 01-2.77-3.043zm-7.69-.103v-4.77a1.011 1.011 0 012.02 0v4.77a4.558 4.558 0 004.553 4.553h3.838a4.383 4.383 0 00.671 0h3.838a4.558 4.558 0 004.553-4.553v-4.77a1.011 1.011 0 012.021 0v4.77a6.581 6.581 0 01-6.573 6.574.703.703 0 00-.703.703v5.857h-6.9v-5.857a.703.703 0 00-.704-.703h-.041a6.581 6.581 0 01-6.574-6.574zm19.72 19.131l-.008.016A2.163 2.163 0 0132.969 39c-.399 0-.773-.11-1.094-.3.064-.105.124-.212.176-.322l1.983-3.923.008-.016a1.37 1.37 0 01.782-.708 1.37 1.37 0 011.053.052c.316.15.557.408.686.726l-1.64 3.242z" />
          <path d="M23.268 39.82a.704.704 0 00-1.235.674.703.703 0 101.243-.66l-.008-.013zm-9.752-.851a.703.703 0 00-.704.703v.971a.703.703 0 101.407 0v-.971a.703.703 0 00-.703-.703zm0 4.654a.703.703 0 00-.704.703v.97a.703.703 0 101.407 0v-.97a.703.703 0 00-.703-.703zm2.812-1.842h-.971a.703.703 0 100 1.407h.971a.703.703 0 100-1.407zm-4.654 0h-.97a.703.703 0 100 1.407h.97a.703.703 0 100-1.407zM42.484 10a.703.703 0 00-.703.703v.971a.703.703 0 101.407 0v-.97a.703.703 0 00-.704-.704zm0 4.654a.703.703 0 00-.703.703v.971a.703.703 0 101.407 0v-.971a.703.703 0 00-.704-.703zm2.813-1.841h-.971a.703.703 0 100 1.406h.97a.703.703 0 100-1.406zm-5.625 1.406h.971a.703.703 0 100-1.406h-.971a.703.703 0 100 1.406z" />
        </g>
      </g>
    </svg>
  );
}

export default UnsubscribeV6QuoteMonacofit;
