import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@material-ui/core';
import { getLocaleDirection } from 'i18n';

import { User } from 'types/user';
import { ModalType } from 'types/modals';
import { Subscription } from 'types/subscription';
import { FileExtension } from 'helpers/imageHelper';
import { isUnsubscribePriceSubscription } from 'helpers/subscriptionHelper';
import { useOfferById } from 'hooks';
import { OfferPrice } from 'types/offer';
import { addModal, removeModal } from 'actions/modalActions';
import { getUserData } from 'actions/programActions';
import { setSubscriptionPrice } from 'actions/subscriptionActions';
import { getActiveWorkoutsSubscription } from 'helpers/userHelper';

import { ReactComponent as QuestionIcon } from 'assets/images/workouts/unsubscribe/question.svg';
import { ReactComponent as WarningIcon } from 'assets/images/workouts/unsubscribe/warning.svg';
import { ReactComponent as WarningPointIcon } from 'assets/images/workouts/unsubscribe/warning-point.svg';
import { SubmitButton, ResponsiveImage } from 'components/common';
import WorkoutsUnsubscribeModal from './WorkoutsUnsubscribeModal';
import WorkoutsOfferSuccess from './WorkoutsOfferSuccess';

import s from './WorkoutsUnsubscribePage.module.scss';

function WorkoutsUnsubscribePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('workouts');
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const [isModalOpened, setModalOpened] = useState(false);
  const [isDiscountLoading, setDiscountLoading] = useState(false);

  const subscription = getActiveWorkoutsSubscription(user);

  const { offer } = useOfferById({ id: subscription?.offer_id || 0 });

  const handleKeepWorkouts = () => {
    history.replace('/program');
  };

  const handleCancelSubscription = () => {
    const isAlreadyDiscounted =
      subscription && isUnsubscribePriceSubscription(subscription);

    if (isModalOpened || isAlreadyDiscounted) {
      // Just redirect to the reason page, as there we still have
      // an option to keep the subscription
      history.replace('/user/workouts/unsubscribe/reason');
      return;
    }

    setModalOpened(true);
  };

  const handleClaimDiscount = async (offerPriceData: OfferPrice) => {
    setDiscountLoading(true);

    const isSetSubscriptionPriceSuccess = await setSubscriptionPrice(
      (subscription as Subscription).id,
      offerPriceData?.id
    );

    if (isSetSubscriptionPriceSuccess) {
      dispatch(getUserData());

      const handleClose = () => {
        dispatch(removeModal({ modal: ModalType.customModal }));
        history.replace('/program');
      };

      dispatch(
        addModal({
          modal: ModalType.customModal,
          customModalNode: (
            <WorkoutsOfferSuccess
              price={offerPriceData?.display_price}
              handleClose={handleClose}
            />
          ),
        })
      );
    }

    setDiscountLoading(false);
  };

  return (
    <>
      <Modal open={isModalOpened} style={{ zIndex: 9999 }}>
        <WorkoutsUnsubscribeModal
          user={user}
          subscription={subscription}
          offer={offer}
          onClaimDiscount={handleClaimDiscount}
          isDiscountLoading={isDiscountLoading}
          onCancelSubscription={handleCancelSubscription}
        />
      </Modal>

      <div className={s.container} dir={getLocaleDirection()}>
        <div className={`${s.content} ${isModalOpened ? s.isModalOpened : ''}`}>
          <div className={s.art}>
            <div className={s.artPositioned}>
              <div className={s.after}>
                <ResponsiveImage
                  imagePath={`./workouts/unsubscribe/${user.gender}/after`}
                  imageExtension={FileExtension.png}
                  altText="before"
                  style={s.image}
                />
              </div>
              <div className={s.question}>
                <QuestionIcon className="s.benefitImage" />
              </div>
              <div className={s.before}>
                <ResponsiveImage
                  imagePath={`./workouts/unsubscribe/${user.gender}/before`}
                  imageExtension={FileExtension.png}
                  altText="before"
                  style={s.image}
                />
              </div>
            </div>
          </div>

          <div className={s.areYouSure}>
            <div className={s.title}>{t('unsubscribe.title')}</div>
            <div className={s.subtitle}>{t('unsubscribe.subtitle')}</div>

            <div className={s.buttons}>
              <SubmitButton
                className={s.submitButton}
                type="large compact"
                label={t('unsubscribe.keep_label')}
                onClick={handleKeepWorkouts}
              />
            </div>
          </div>

          <div className={s.warning}>
            <div className={s.warningContent}>
              <div className={s.warningIcon}>
                <WarningIcon />
              </div>

              <div className={s.title}>{t('unsubscribe.warning_title')}</div>

              <div className={s.warningPoint}>
                <div className={s.warningPointIcon}>
                  <WarningPointIcon />
                </div>
                {t('unsubscribe.warning_p1')}
              </div>

              <div className={s.warningPoint}>
                <div className={s.warningPointIcon}>
                  <WarningPointIcon />
                </div>
                {t('unsubscribe.warning_p2')}
              </div>

              <div className={s.warningPoint}>
                <div className={s.warningPointIcon}>
                  <WarningPointIcon />
                </div>
                {t('unsubscribe.warning_p3')}
              </div>
            </div>

            <div className={s.buttons}>
              <SubmitButton
                className={s.submitButton}
                type="large compact"
                label={t('unsubscribe.keep_label')}
                onClick={handleKeepWorkouts}
              />

              <SubmitButton
                className={s.submitButton}
                type="text-large compact"
                variant="outlined"
                label={t('unsubscribe.cancel_label')}
                onClick={handleCancelSubscription}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkoutsUnsubscribePage;
