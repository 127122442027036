import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class LoadingProgress extends PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    max: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
  };

  render = () => {
    const { text, current, max } = this.props;

    return (
      <div className="loading_progress">
        <p className="loading_progress__text">{text}</p>
        <div className="loading_progress__bar">
          <div
            className="loading_progress__bar--fill"
            style={{ width: `${(current / max) * 100}%` }}
          />
        </div>
      </div>
    );
  };
}
