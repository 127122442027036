import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { RetinaImage } from 'components/common';

import { ReactComponent as PayPal } from 'assets/images/offers/paymodes/paypal_recurring_diet.svg';
import { ReactComponent as Fdl } from 'assets/images/offers/paymodes/first_data_diet.svg';
import { ReactComponent as Seb } from 'assets/images/offers/paymodes/seb.svg';
import Swedbank from 'assets/images/offers/paymodes/swedbank.png';
import Swedbank2x from 'assets/images/offers/paymodes/swedbank@2x.png';
import Swedbank3x from 'assets/images/offers/paymodes/swedbank@3x.png';

import s from './PaymodeButton.module.scss';

const BUTTON_IMAGES: { [key: string]: React.ReactNode } = {
  paypal_recurring: <PayPal />,
  first_data: <Fdl />,
  swedbank: <RetinaImage images={[Swedbank, Swedbank2x, Swedbank3x]} />,
  seb: <Seb />,
  stripe: <Fdl />,
  paydoo: <Fdl />,
  primer: <Fdl />,
};

type Props = {
  label: string;
  paymode: string;
  disabled: boolean;
  isLoading: boolean;
  onClick: () => void;
};

function PaymodeButton({
  label,
  paymode,
  disabled,
  isLoading,
  onClick,
}: Props) {
  const getClassName = () => {
    if (isLoading) {
      return s.loadingButton;
    }
    if (disabled) {
      return s.disabledButton;
    }

    return s.defaultButton;
  };

  return (
    <div className={s.wrapper}>
      <button
        type="button"
        onClick={() => onClick()}
        className={getClassName()}
        disabled={disabled}
      >
        {isLoading ? (
          <CircularProgress size={25} color="primary" />
        ) : (
          <>
            <span className={s.imageContainer}>{BUTTON_IMAGES[paymode]}</span>
            {label}
          </>
        )}
      </button>
    </div>
  );
}

export default PaymodeButton;
