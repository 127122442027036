/* eslint-disable import/prefer-default-export */
type AllCountries = {
  value: string;
  emoji: string;
  name: string;
}[];

/**
 * Array of objects with all countries in the world
 *
 * Object inside look like this:
 * @param {value} value "Alpha-2-code" meaning 2 char describing country
 * @param {emoji} emoji Flag emoji of that country
 * @param {name} name Country name in English
 */
export const ALL_COUNTRIES: AllCountries = [
  { value: 'AF', emoji: '🇦🇫', name: 'Afghanistan' },
  { value: 'AX', emoji: '🇦🇽', name: 'Åland Islands' },
  { value: 'AL', emoji: '🇦🇱', name: 'Albania' },
  { value: 'DZ', emoji: '🇩🇿', name: 'Algeria' },
  { value: 'AS', emoji: '🇦🇸', name: 'American Samoa' },
  { value: 'AD', emoji: '🇦🇩', name: 'Andorra' },
  { value: 'AO', emoji: '🇦🇴', name: 'Angola' },
  { value: 'AI', emoji: '🇦🇮', name: 'Anguilla' },
  { value: 'AQ', emoji: '🇦🇶', name: 'Antarctica' },
  { value: 'AG', emoji: '🇦🇬', name: 'Antigua & Barbuda' },
  { value: 'AR', emoji: '🇦🇷', name: 'Argentina' },
  { value: 'AM', emoji: '🇦🇲', name: 'Armenia' },
  { value: 'AW', emoji: '🇦🇼', name: 'Aruba' },
  { value: 'AU', emoji: '🇦🇺', name: 'Australia' },
  { value: 'AT', emoji: '🇦🇹', name: 'Austria' },
  { value: 'AZ', emoji: '🇦🇿', name: 'Azerbaijan' },
  { value: 'BS', emoji: '🇧🇸', name: 'Bahamas' },
  { value: 'BH', emoji: '🇧🇭', name: 'Bahrain' },
  { value: 'BD', emoji: '🇧🇩', name: 'Bangladesh' },
  { value: 'BB', emoji: '🇧🇧', name: 'Barbados' },
  { value: 'BY', emoji: '🇧🇾', name: 'Belarus' },
  { value: 'BE', emoji: '🇧🇪', name: 'Belgium' },
  { value: 'BZ', emoji: '🇧🇿', name: 'Belize' },
  { value: 'BJ', emoji: '🇧🇯', name: 'Benin' },
  { value: 'BM', emoji: '🇧🇲', name: 'Bermuda' },
  { value: 'BT', emoji: '🇧🇹', name: 'Bhutan' },
  { value: 'BO', emoji: '🇧🇴', name: 'Bolivia' },
  { value: 'BA', emoji: '🇧🇦', name: 'Bosnia & Herzegovina' },
  { value: 'BW', emoji: '🇧🇼', name: 'Botswana' },
  { value: 'BV', emoji: '🇧🇻', name: 'Bouvet Island' },
  { value: 'BR', emoji: '🇧🇷', name: 'Brazil' },
  { value: 'IO', emoji: '🇮🇴', name: 'British Indian Ocean Territory' },
  { value: 'VG', emoji: '🇻🇬', name: 'British Virgin Islands' },
  { value: 'BN', emoji: '🇧🇳', name: 'Brunei' },
  { value: 'BG', emoji: '🇧🇬', name: 'Bulgaria' },
  { value: 'BF', emoji: '🇧🇫', name: 'Burkina Faso' },
  { value: 'BI', emoji: '🇧🇮', name: 'Burundi' },
  { value: 'KH', emoji: '🇰🇭', name: 'Cambodia' },
  { value: 'CM', emoji: '🇨🇲', name: 'Cameroon' },
  { value: 'CA', emoji: '🇨🇦', name: 'Canada' },
  { value: 'CV', emoji: '🇨🇻', name: 'Cape Verde' },
  { value: 'BQ', emoji: '🇧🇶', name: 'Caribbean Netherlands' },
  { value: 'KY', emoji: '🇰🇾', name: 'Cayman Islands' },
  { value: 'CF', emoji: '🇨🇫', name: 'Central African Republic' },
  { value: 'TD', emoji: '🇹🇩', name: 'Chad' },
  { value: 'CL', emoji: '🇨🇱', name: 'Chile' },
  { value: 'CN', emoji: '🇨🇳', name: 'China' },
  { value: 'CX', emoji: '🇨🇽', name: 'Christmas Island' },
  { value: 'CC', emoji: '🇨🇨', name: 'Cocos (Keeling) Islands' },
  { value: 'CO', emoji: '🇨🇴', name: 'Colombia' },
  { value: 'KM', emoji: '🇰🇲', name: 'Comoros' },
  { value: 'CG', emoji: '🇨🇬', name: 'Congo - Brazzaville' },
  { value: 'CD', emoji: '🇨🇩', name: 'Congo - Kinshasa' },
  { value: 'CK', emoji: '🇨🇰', name: 'Cook Islands' },
  { value: 'CR', emoji: '🇨🇷', name: 'Costa Rica' },
  { value: 'CI', emoji: '🇨🇮', name: "Côte d'Ivoire" },
  { value: 'HR', emoji: '🇭🇷', name: 'Croatia' },
  { value: 'CU', emoji: '🇨🇺', name: 'Cuba' },
  { value: 'CW', emoji: '🇨🇼', name: 'Curaçao' },
  { value: 'CY', emoji: '🇨🇾', name: 'Cyprus' },
  { value: 'CZ', emoji: '🇨🇿', name: 'Czechia' },
  { value: 'DK', emoji: '🇩🇰', name: 'Denmark' },
  { value: 'DJ', emoji: '🇩🇯', name: 'Djibouti' },
  { value: 'DM', emoji: '🇩🇲', name: 'Dominica' },
  { value: 'DO', emoji: '🇩🇴', name: 'Dominican Republic' },
  { value: 'EC', emoji: '🇪🇨', name: 'Ecuador' },
  { value: 'EG', emoji: '🇪🇬', name: 'Egypt' },
  { value: 'SV', emoji: '🇸🇻', name: 'El Salvador' },
  { value: 'GQ', emoji: '🇬🇶', name: 'Equatorial Guinea' },
  { value: 'ER', emoji: '🇪🇷', name: 'Eritrea' },
  { value: 'EE', emoji: '🇪🇪', name: 'Estonia' },
  { value: 'SZ', emoji: '🇸🇿', name: 'Eswatini' },
  { value: 'ET', emoji: '🇪🇹', name: 'Ethiopia' },
  { value: 'FK', emoji: '🇫🇰', name: 'Falkland Islands' },
  { value: 'FO', emoji: '🇫🇴', name: 'Faroe Islands' },
  { value: 'FJ', emoji: '🇫🇯', name: 'Fiji' },
  { value: 'FI', emoji: '🇫🇮', name: 'Finland' },
  { value: 'FR', emoji: '🇫🇷', name: 'France' },
  { value: 'GF', emoji: '🇬🇫', name: 'French Guiana' },
  { value: 'PF', emoji: '🇵🇫', name: 'French Polynesia' },
  { value: 'TF', emoji: '🇹🇫', name: 'French Southern Territories' },
  { value: 'GA', emoji: '🇬🇦', name: 'Gabon' },
  { value: 'GM', emoji: '🇬🇲', name: 'Gambia' },
  { value: 'GE', emoji: '🇬🇪', name: 'Georgia' },
  { value: 'DE', emoji: '🇩🇪', name: 'Germany' },
  { value: 'GH', emoji: '🇬🇭', name: 'Ghana' },
  { value: 'GI', emoji: '🇬🇮', name: 'Gibraltar' },
  { value: 'GR', emoji: '🇬🇷', name: 'Greece' },
  { value: 'GL', emoji: '🇬🇱', name: 'Greenland' },
  { value: 'GD', emoji: '🇬🇩', name: 'Grenada' },
  { value: 'GP', emoji: '🇬🇵', name: 'Guadeloupe' },
  { value: 'GU', emoji: '🇬🇺', name: 'Guam' },
  { value: 'GT', emoji: '🇬🇹', name: 'Guatemala' },
  { value: 'GG', emoji: '🇬🇬', name: 'Guernsey' },
  { value: 'GN', emoji: '🇬🇳', name: 'Guinea' },
  { value: 'GW', emoji: '🇬🇼', name: 'Guinea-Bissau' },
  { value: 'GY', emoji: '🇬🇾', name: 'Guyana' },
  { value: 'HT', emoji: '🇭🇹', name: 'Haiti' },
  { value: 'HM', emoji: '🇭🇲', name: 'Heard & McDonald Islands' },
  { value: 'HN', emoji: '🇭🇳', name: 'Honduras' },
  { value: 'HK', emoji: '🇭🇰', name: 'Hong Kong SAR China' },
  { value: 'HU', emoji: '🇭🇺', name: 'Hungary' },
  { value: 'IS', emoji: '🇮🇸', name: 'Iceland' },
  { value: 'IN', emoji: '🇮🇳', name: 'India' },
  { value: 'ID', emoji: '🇮🇩', name: 'Indonesia' },
  { value: 'IR', emoji: '🇮🇷', name: 'Iran' },
  { value: 'IQ', emoji: '🇮🇶', name: 'Iraq' },
  { value: 'IE', emoji: '🇮🇪', name: 'Ireland' },
  { value: 'IM', emoji: '🇮🇲', name: 'Isle of Man' },
  { value: 'IL', emoji: '🇮🇱', name: 'Israel' },
  { value: 'IT', emoji: '🇮🇹', name: 'Italy' },
  { value: 'JM', emoji: '🇯🇲', name: 'Jamaica' },
  { value: 'JP', emoji: '🇯🇵', name: 'Japan' },
  { value: 'JE', emoji: '🇯🇪', name: 'Jersey' },
  { value: 'JO', emoji: '🇯🇴', name: 'Jordan' },
  { value: 'KZ', emoji: '🇰🇿', name: 'Kazakhstan' },
  { value: 'KE', emoji: '🇰🇪', name: 'Kenya' },
  { value: 'KI', emoji: '🇰🇮', name: 'Kiribati' },
  { value: 'KW', emoji: '🇰🇼', name: 'Kuwait' },
  { value: 'KG', emoji: '🇰🇬', name: 'Kyrgyzstan' },
  { value: 'LA', emoji: '🇱🇦', name: 'Laos' },
  { value: 'LV', emoji: '🇱🇻', name: 'Latvia' },
  { value: 'LB', emoji: '🇱🇧', name: 'Lebanon' },
  { value: 'LS', emoji: '🇱🇸', name: 'Lesotho' },
  { value: 'LR', emoji: '🇱🇷', name: 'Liberia' },
  { value: 'LY', emoji: '🇱🇾', name: 'Libya' },
  { value: 'LI', emoji: '🇱🇮', name: 'Liechtenstein' },
  { value: 'LT', emoji: '🇱🇹', name: 'Lithuania' },
  { value: 'LU', emoji: '🇱🇺', name: 'Luxembourg' },
  { value: 'MO', emoji: '🇲🇴', name: 'Macao SAR China' },
  { value: 'MG', emoji: '🇲🇬', name: 'Madagascar' },
  { value: 'MW', emoji: '🇲🇼', name: 'Malawi' },
  { value: 'MY', emoji: '🇲🇾', name: 'Malaysia' },
  { value: 'MV', emoji: '🇲🇻', name: 'Maldives' },
  { value: 'ML', emoji: '🇲🇱', name: 'Mali' },
  { value: 'MT', emoji: '🇲🇹', name: 'Malta' },
  { value: 'MH', emoji: '🇲🇭', name: 'Marshall Islands' },
  { value: 'MQ', emoji: '🇲🇶', name: 'Martinique' },
  { value: 'MR', emoji: '🇲🇷', name: 'Mauritania' },
  { value: 'MU', emoji: '🇲🇺', name: 'Mauritius' },
  { value: 'YT', emoji: '🇾🇹', name: 'Mayotte' },
  { value: 'MX', emoji: '🇲🇽', name: 'Mexico' },
  { value: 'FM', emoji: '🇫🇲', name: 'Micronesia' },
  { value: 'MD', emoji: '🇲🇩', name: 'Moldova' },
  { value: 'MC', emoji: '🇲🇨', name: 'Monaco' },
  { value: 'MN', emoji: '🇲🇳', name: 'Mongolia' },
  { value: 'ME', emoji: '🇲🇪', name: 'Montenegro' },
  { value: 'MS', emoji: '🇲🇸', name: 'Montserrat' },
  { value: 'MA', emoji: '🇲🇦', name: 'Morocco' },
  { value: 'MZ', emoji: '🇲🇿', name: 'Mozambique' },
  { value: 'MM', emoji: '🇲🇲', name: 'Myanmar (Burma)' },
  { value: 'NA', emoji: '🇳🇦', name: 'Namibia' },
  { value: 'NR', emoji: '🇳🇷', name: 'Nauru' },
  { value: 'NP', emoji: '🇳🇵', name: 'Nepal' },
  { value: 'NL', emoji: '🇳🇱', name: 'Netherlands' },
  { value: 'NC', emoji: '🇳🇨', name: 'New Caledonia' },
  { value: 'NZ', emoji: '🇳🇿', name: 'New Zealand' },
  { value: 'NI', emoji: '🇳🇮', name: 'Nicaragua' },
  { value: 'NE', emoji: '🇳🇪', name: 'Niger' },
  { value: 'NG', emoji: '🇳🇬', name: 'Nigeria' },
  { value: 'NU', emoji: '🇳🇺', name: 'Niue' },
  { value: 'NF', emoji: '🇳🇫', name: 'Norfolk Island' },
  { value: 'KP', emoji: '🇰🇵', name: 'North Korea' },
  { value: 'MK', emoji: '🇲🇰', name: 'North Macedonia' },
  { value: 'MP', emoji: '🇲🇵', name: 'Northern Mariana Islands' },
  { value: 'NO', emoji: '🇳🇴', name: 'Norway' },
  { value: 'OM', emoji: '🇴🇲', name: 'Oman' },
  { value: 'PK', emoji: '🇵🇰', name: 'Pakistan' },
  { value: 'PW', emoji: '🇵🇼', name: 'Palau' },
  { value: 'PS', emoji: '🇵🇸', name: 'Palestinian Territories' },
  { value: 'PA', emoji: '🇵🇦', name: 'Panama' },
  { value: 'PG', emoji: '🇵🇬', name: 'Papua New Guinea' },
  { value: 'PY', emoji: '🇵🇾', name: 'Paraguay' },
  { value: 'PE', emoji: '🇵🇪', name: 'Peru' },
  { value: 'PH', emoji: '🇵🇭', name: 'Philippines' },
  { value: 'PN', emoji: '🇵🇳', name: 'Pitcairn Islands' },
  { value: 'PL', emoji: '🇵🇱', name: 'Poland' },
  { value: 'PT', emoji: '🇵🇹', name: 'Portugal' },
  { value: 'PR', emoji: '🇵🇷', name: 'Puerto Rico' },
  { value: 'QA', emoji: '🇶🇦', name: 'Qatar' },
  { value: 'RE', emoji: '🇷🇪', name: 'Réunion' },
  { value: 'RO', emoji: '🇷🇴', name: 'Romania' },
  { value: 'RU', emoji: '🇷🇺', name: 'Russia' },
  { value: 'RW', emoji: '🇷🇼', name: 'Rwanda' },
  { value: 'WS', emoji: '🇼🇸', name: 'Samoa' },
  { value: 'SM', emoji: '🇸🇲', name: 'San Marino' },
  { value: 'ST', emoji: '🇸🇹', name: 'São Tomé & Príncipe' },
  { value: 'SA', emoji: '🇸🇦', name: 'Saudi Arabia' },
  { value: 'SN', emoji: '🇸🇳', name: 'Senegal' },
  { value: 'RS', emoji: '🇷🇸', name: 'Serbia' },
  { value: 'SC', emoji: '🇸🇨', name: 'Seychelles' },
  { value: 'SL', emoji: '🇸🇱', name: 'Sierra Leone' },
  { value: 'SG', emoji: '🇸🇬', name: 'Singapore' },
  { value: 'SX', emoji: '🇸🇽', name: 'Sint Maarten' },
  { value: 'SK', emoji: '🇸🇰', name: 'Slovakia' },
  { value: 'SI', emoji: '🇸🇮', name: 'Slovenia' },
  { value: 'SB', emoji: '🇸🇧', name: 'Solomon Islands' },
  { value: 'SO', emoji: '🇸🇴', name: 'Somalia' },
  { value: 'ZA', emoji: '🇿🇦', name: 'South Africa' },
  {
    value: 'GS',
    emoji: '🇬🇸',
    name: 'South Georgia & South Sandwich Islands',
  },
  { value: 'KR', emoji: '🇰🇷', name: 'South Korea' },
  { value: 'SS', emoji: '🇸🇸', name: 'South Sudan' },
  { value: 'ES', emoji: '🇪🇸', name: 'Spain' },
  { value: 'LK', emoji: '🇱🇰', name: 'Sri Lanka' },
  { value: 'BL', emoji: '🇧🇱', name: 'St. Barthélemy' },
  { value: 'SH', emoji: '🇸🇭', name: 'St. Helena' },
  { value: 'KN', emoji: '🇰🇳', name: 'St. Kitts & Nevis' },
  { value: 'LC', emoji: '🇱🇨', name: 'St. Lucia' },
  { value: 'MF', emoji: '🇲🇫', name: 'St. Martin' },
  { value: 'PM', emoji: '🇵🇲', name: 'St. Pierre & Miquelon' },
  { value: 'VC', emoji: '🇻🇨', name: 'St. Vincent & Grenadines' },
  { value: 'SD', emoji: '🇸🇩', name: 'Sudan' },
  { value: 'SR', emoji: '🇸🇷', name: 'Suriname' },
  { value: 'SJ', emoji: '🇸🇯', name: 'Svalbard & Jan Mayen' },
  { value: 'SE', emoji: '🇸🇪', name: 'Sweden' },
  { value: 'CH', emoji: '🇨🇭', name: 'Switzerland' },
  { value: 'SY', emoji: '🇸🇾', name: 'Syria' },
  { value: 'TW', emoji: '🇹🇼', name: 'Taiwan' },
  { value: 'TJ', emoji: '🇹🇯', name: 'Tajikistan' },
  { value: 'TZ', emoji: '🇹🇿', name: 'Tanzania' },
  { value: 'TH', emoji: '🇹🇭', name: 'Thailand' },
  { value: 'TL', emoji: '🇹🇱', name: 'Timor-Leste' },
  { value: 'TG', emoji: '🇹🇬', name: 'Togo' },
  { value: 'TK', emoji: '🇹🇰', name: 'Tokelau' },
  { value: 'TO', emoji: '🇹🇴', name: 'Tonga' },
  { value: 'TT', emoji: '🇹🇹', name: 'Trinidad & Tobago' },
  { value: 'TN', emoji: '🇹🇳', name: 'Tunisia' },
  { value: 'TR', emoji: '🇹🇷', name: 'Turkey' },
  { value: 'TM', emoji: '🇹🇲', name: 'Turkmenistan' },
  { value: 'TC', emoji: '🇹🇨', name: 'Turks & Caicos Islands' },
  { value: 'TV', emoji: '🇹🇻', name: 'Tuvalu' },
  { value: 'UM', emoji: '🇺🇲', name: 'U.S. Outlying Islands' },
  { value: 'VI', emoji: '🇻🇮', name: 'U.S. Virgin Islands' },
  { value: 'UG', emoji: '🇺🇬', name: 'Uganda' },
  { value: 'UA', emoji: '🇺🇦', name: 'Ukraine' },
  { value: 'AE', emoji: '🇦🇪', name: 'United Arab Emirates' },
  { value: 'GB', emoji: '🇬🇧', name: 'United Kingdom' },
  { value: 'US', emoji: '🇺🇸', name: 'United States' },
  { value: 'UY', emoji: '🇺🇾', name: 'Uruguay' },
  { value: 'UZ', emoji: '🇺🇿', name: 'Uzbekistan' },
  { value: 'VU', emoji: '🇻🇺', name: 'Vanuatu' },
  { value: 'VA', emoji: '🇻🇦', name: 'Vatican City' },
  { value: 'VE', emoji: '🇻🇪', name: 'Venezuela' },
  { value: 'VN', emoji: '🇻🇳', name: 'Vietnam' },
  { value: 'WF', emoji: '🇼🇫', name: 'Wallis & Futuna' },
  { value: 'EH', emoji: '🇪🇭', name: 'Western Sahara' },
  { value: 'YE', emoji: '🇾🇪', name: 'Yemen' },
  { value: 'ZM', emoji: '🇿🇲', name: 'Zambia' },
  { value: 'ZW', emoji: '🇿🇼', name: 'Zimbabwe' },
];
