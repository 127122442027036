import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { User } from 'types/user';
import {
  isPhysicalProductAccessAvailable,
  isPhysicalProductSubscriptionActive,
} from 'helpers/physicalProductHelper';
import { PhysicalProductType } from 'types/physicalProducts';

type Props = {
  children: React.ReactNode;
};

function EnsureAcvGummiesActive({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const isAccessForbidden = !isPhysicalProductAccessAvailable(
    user,
    PhysicalProductType.acvGummies
  );
  const isSubscriptionInactive = !isPhysicalProductSubscriptionActive(
    user,
    PhysicalProductType.acvGummies
  );

  if (isAccessForbidden || isSubscriptionInactive) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
}

export default EnsureAcvGummiesActive;
