import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';

import useTheme from 'helpers/themeHook';
import { FileExtension } from 'helpers/imageHelper';
import { isHealthgenom, isMaterialUI } from 'helpers/themeHelper';
import { SVGFillColors } from 'types/svg';

import SvgIcon from 'components/common/SvgIcon';
import ResponsiveImage from './ResponsiveImage';

import base from './styles/TextInput.module.scss';
import diet from './styles/TextInput_diet.module.scss';
import keto from './styles/TextInput_keto.module.scss';
import ketogo from './styles/TextInput_ketogo.module.scss';
import fasting from './styles/TextInput_fasting.module.scss';
import healthgenom from './styles/TextInput_healthgenom.module.scss';

type Props = {
  name: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: number | string;
  type: string;
  svgIcon?: string;
  error?: boolean | string;
  materialError?: boolean;
  inputType?: string;
  valueUnit?: string;
  minLength?: number;
  maxLength?: number;
  onKeyPress?: (e: any) => void;
  disabled?: boolean;
  required?: boolean;
  autoComplete?: string;
  mini?: boolean;
  pattern?: string;
  min?: number;
  max?: number;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  defaultValue?: string;
  customClass?: string;
};

const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      error,
      materialError,
      label,
      inputType,
      mini,
      svgIcon,
      type,
      name,
      placeholder,
      value,
      onChange,
      minLength,
      maxLength,
      onKeyPress,
      disabled,
      valueUnit,
      pattern,
      required,
      autoComplete,
      min,
      max,
      inputMode,
      defaultValue,
      customClass,
    },
    ref
  ) => {
    const [s] = useTheme({ base, diet, keto, ketogo, fasting, healthgenom });

    const getWrapperClass = () => {
      let wrapperClass = 'form';
      if (error) wrapperClass += ' has-error';
      if (label) wrapperClass += ' with-label';
      if (inputType) wrapperClass += ` ${inputType}`;
      return wrapperClass;
    };

    const getContainerClass = () => {
      let containerClass = 'form__input text';
      if (mini) containerClass += ' mini';
      return containerClass;
    };

    const getInputLabel = () => {
      if (label)
        return (
          <label htmlFor={name} className={s.label}>
            {label}
          </label>
        );

      return null;
    };

    const getValidationMessage = () => {
      if (typeof error === 'string') {
        return <span className={`validation_error ${s.error}`}>{error}</span>;
      }

      return null;
    };

    const addInputLabelProps = () => {
      if (!isHealthgenom()) return { classes: {} };

      return {
        classes: { focused: s.focusedLabel },
      };
    };

    const renderEndAdornment = () => {
      if (valueUnit) {
        return <InputAdornment position="end">{valueUnit}</InputAdornment>;
      }
      if (materialError) {
        return (
          <ResponsiveImage
            imagePath="./error-input-pic"
            imageExtension={FileExtension.png}
            altText="error-indicator"
            style={s.errorIcon}
          />
        );
      }

      return null;
    };

    if (isMaterialUI()) {
      return (
        <TextField
          variant="outlined"
          type={type}
          name={name}
          className={[
            s.input,
            materialError && s.error,
            inputType && s[inputType],
            customClass && s[customClass],
          ].join(' ')}
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          disabled={disabled}
          required={required}
          InputProps={{
            endAdornment: renderEndAdornment(),
          }}
          InputLabelProps={addInputLabelProps()}
          inputProps={{
            min,
            max,
            required,
            inputMode,
            minLength,
            maxLength,
            pattern,
            autoComplete,
            defaultValue,
          }}
          helperText={getValidationMessage()}
          inputRef={ref}
        />
      );
    }

    return (
      <div className={getWrapperClass()}>
        {getInputLabel()}
        <div className={getContainerClass()}>
          {svgIcon && (
            <SvgIcon
              filename={svgIcon}
              fill={SVGFillColors.gray}
              className="form__input__input_img"
            />
          )}
          <input
            type={type}
            name={name}
            className={[s.input, inputType && s[inputType]].join(' ')}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            minLength={minLength}
            maxLength={maxLength}
            onKeyPress={onKeyPress}
            disabled={disabled}
            pattern={pattern}
            required={required}
            autoComplete={autoComplete}
            ref={ref}
          />
          {valueUnit && <span className={s.unit}>{valueUnit}</span>}
        </div>
        {getValidationMessage()}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
