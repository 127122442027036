export type QueryParams = {
  stepId: string;
  [key: string]: string;
};

export const UNSUBSCRIBEV8_LOG_NEEDED = 'unsubv8-log-needed';

export enum UnsubscribeV8RecapSteps {
  profile_female = '5-recap-1-profile-f',
  profile_male = '5-recap-1-profile-m',
  weight_female = '6-recap-2-weight-f',
  weight_male = '6-recap-2-weight-m',
  meals_female = '7-recap-3-meals-f',
  meals_male = '7-recap-3-meals-m',
  premium_female = '8-recap-4-premium-f',
  premium_male = '8-recap-4-premium-m',
  shoppinglist_female = '9-recap-5-shoppinglist-f',
  shoppinglist_male = '9-recap-5-shoppinglist-m',
  investment_female = '10-recap-6-investment-f',
  investment_male = '10-recap-6-investment-m',
  results = '11-recap-7-results',
}

export enum UnsubscribeV8FreeOfferSteps {
  bundle = '12-free-offer-bundle',
  premium = '12-free-offer-premium',
  shoppingList = '12-free-offer-shoppinglist',
}

export const FreeOfferNames = {
  [UnsubscribeV8FreeOfferSteps.bundle]: 'bundle',
  [UnsubscribeV8FreeOfferSteps.premium]: 'premium',
  [UnsubscribeV8FreeOfferSteps.shoppingList]: 'shoppinglist',
};

export enum FreeOfferItems {
  shoppinglist = 'shoppinglist',
  premium = 'premium',
}

type StringObject = {
  [key: string]: string;
};

export const UnsubscribeV8RecapStepName: StringObject = {
  '5-recap-1-profile-f': 'profile_female',
  '5-recap-1-profile-m': 'profile_male',
  '6-recap-2-weight-f': 'weight_female',
  '6-recap-2-weight-m': 'weight_male',
  '7-recap-3-meals-f': 'meals_female',
  '7-recap-3-meals-m': 'meals_male',
  '8-recap-4-premium-f': 'premium_female',
  '8-recap-4-premium-m': 'premium_male',
  '9-recap-5-shoppinglist-f': 'shoppinglist_female',
  '9-recap-5-shoppinglist-m': 'shoppinglist_male',
  '10-recap-6-investment-f': 'investment_female',
  '10-recap-6-investment-m': 'investment_male',
  '11-recap-7-results': 'results',
};

export const UnsubscribeV8RecapLinks: StringObject = {
  '5-recap-1-profile-f': '/unsubscribev8/6-recap-2-weight-f',
  '5-recap-1-profile-m': '/unsubscribev8/6-recap-2-weight-m',
  '6-recap-2-weight-f': '/unsubscribev8/7-recap-3-meals-f',
  '6-recap-2-weight-m': '/unsubscribev8/7-recap-3-meals-m',
  '7-recap-3-meals-f': '/unsubscribev8/8-recap-4-premium-f',
  '7-recap-3-meals-m': '/unsubscribev8/8-recap-4-premium-m',
  '8-recap-4-premium-f': '/unsubscribev8/9-recap-5-shoppinglist-f',
  '8-recap-4-premium-m': '/unsubscribev8/9-recap-5-shoppinglist-m',
  '9-recap-5-shoppinglist-f': '/unsubscribev8/10-recap-6-investment-f',
  '9-recap-5-shoppinglist-m': '/unsubscribev8/10-recap-6-investment-m',
  '10-recap-6-investment-f': '/unsubscribev8/11-recap-7-results',
  '10-recap-6-investment-m': '/unsubscribev8/11-recap-7-results',
  '11-recap-7-results': '/unsubscribe/unsubscribev4-step-1',
};

export type BodyElement = {
  type: string;
  transKey: string;
};

type UnsubscribeV8RecapBodyElementsTypes = {
  [key: string]: {
    elements: BodyElement[];
  };
};

export const UnsubscribeV8RecapBodyElements: UnsubscribeV8RecapBodyElementsTypes =
  {
    profile_female: {
      elements: [{ type: 'journey', transKey: 'journey' }],
    },
    profile_male: {
      elements: [{ type: 'journey', transKey: 'journey' }],
    },
    weight_female: {
      elements: [
        { type: 'basic', transKey: 'weight_card_1' },
        { type: 'basic_with_link', transKey: 'weight_card_2' },
      ],
    },
    weight_male: {
      elements: [
        { type: 'basic', transKey: 'weight_card_1' },
        { type: 'basic_with_link', transKey: 'weight_card_2' },
      ],
    },
    meals_female: {
      elements: [
        { type: 'basic', transKey: 'meals_card_1' },
        { type: 'basic_with_link', transKey: 'meals_card_2' },
      ],
    },
    meals_male: {
      elements: [
        { type: 'basic', transKey: 'meals_card_1' },
        { type: 'basic_with_link', transKey: 'meals_card_2' },
      ],
    },
    premium_female: {
      elements: [
        { type: 'basic_with_link', transKey: 'premium_card_1' },
        { type: 'basic_with_link', transKey: 'premium_card_2' },
      ],
    },
    premium_male: {
      elements: [
        { type: 'basic_with_link', transKey: 'premium_card_1' },
        { type: 'basic_with_link', transKey: 'premium_card_2' },
      ],
    },
    shoppinglist_female: {
      elements: [{ type: 'basic_with_link', transKey: 'shoppinglist_card_1' }],
    },
    shoppinglist_male: {
      elements: [{ type: 'basic_with_link', transKey: 'shoppinglist_card_1' }],
    },
    investment_female: {
      elements: [
        { type: 'basic', transKey: 'investment_card_1' },
        { type: 'basic', transKey: 'investment_card_2' },
        { type: 'basic', transKey: 'investment_card_3' },
        { type: 'basic', transKey: 'investment_card_4' },
      ],
    },
    investment_male: {
      elements: [
        { type: 'basic', transKey: 'investment_card_1' },
        { type: 'basic', transKey: 'investment_card_2' },
        { type: 'basic', transKey: 'investment_card_3' },
        { type: 'basic', transKey: 'investment_card_4' },
      ],
    },
  };

export enum FlowTypes {
  recap = 'recap',
  freeOffer = 'freeOffer',
  none = 'none',
}

export const UnsubscribeV8Steps = {
  recap: UnsubscribeV8RecapSteps,
  freeOffer: UnsubscribeV8FreeOfferSteps,
};

export const DEFAULT_FREE_EURO_AMOUNTS: { [key: string]: number } = {
  chat: 39.95,
  collagen: 39.95,
  fat_burner: 39.95,
  meal_switch: 39.95,
  premium_upsell: 69.95,
  pro_subscription: 59.95,
  shopping_list: 39.95,
  tricks: 39.95,
};

export const ITEM_LIST_TEXT_KEYS = ['text', 'text2', 'text3'];

export type FreeOfferPricing = {
  premium: string;
  shoppinglist: string;
};

export enum FREE_OFFER_BUTTON_TYPES {
  firstAcceptButton = 'firstAcceptButton',
  secondAcceptButton = 'secondAcceptButton',
}

export enum UNSUBSCRIBEV8_MODAL_TYPES {
  success = 'success',
  locked = 'locked',
}
