import React from 'react';

import { SVG } from 'types/svg';

function TrickDiet(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={10} height={10} viewBox="0 0 10 10" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect width={10} height={10} fill="#FFD445" rx={2} />
        <path
          fill="#1D70B4"
          d="M5.146 2.102l.721 2.135c.02.06.079.101.146.101h2.334c.148 0 .21.183.09.267l-1.89 1.319a.145.145 0 00-.055.165l.721 2.134c.046.136-.115.249-.236.165L5.09 7.068a.16.16 0 00-.18 0l-1.888 1.32c-.12.084-.282-.029-.236-.165l.722-2.134a.146.146 0 00-.056-.165l-1.889-1.32c-.12-.083-.058-.266.09-.266h2.334c.067 0 .126-.04.146-.101l.722-2.135c.045-.136.245-.136.29 0"
        />
      </g>
    </svg>
  );
}

export default TrickDiet;
