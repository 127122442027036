import React from 'react';
import { useTranslation } from 'react-i18next';

import { Cycle } from 'types/fasting';

import Utensils from 'assets/images/program/fasting/utensils.svg';
import SmallFire from 'assets/images/program/fasting/small-fire.svg';
import SmallUtensils from 'assets/images/program/fasting/small-utensils.svg';
import Satiety from 'assets/images/program/benefits/satiety.svg';
import Feel from 'assets/images/program/benefits/feel.svg';

import s from './EatingHeader.module.scss';

type Props = {
  cycle: Cycle;
};

function EatingHeader({ cycle }: Props) {
  const { t } = useTranslation();

  return (
    <div className={s.header}>
      <div className={s.headerInformation}>
        <img className={s.icon} src={Utensils} alt="eating-utensils" />
        <div className={s.text}>
          <p className={s.info}>
            <img src={SmallFire} alt="small-flame" />
            <span className={s.fastingSmall}>{cycle.fasting}h</span>
            <img src={SmallUtensils} alt="small-utensils" />
            <span className={s.fastingSmall}>{cycle.eating}h</span>
          </p>
          <h3 className={s.title}>{t('items.type.eating.title')}</h3>
        </div>
      </div>
      <div className={s.benefitContainer}>
        <span className={s.benefit}>
          <img src={Satiety} alt="satiety" /> {t('eating.benefits.satiety')}
          <img className={s.wellness} src={Feel} alt="wellness" />{' '}
          {t('eating.benefits.wellness')}
        </span>
      </div>
    </div>
  );
}

export default EatingHeader;
