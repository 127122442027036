import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { User } from 'types/user';
import { ModalType } from 'types/modals';
import { MealItem, MealIconSize } from 'types/program';
import { convertToMin } from 'helpers/timeHelper';
import { mealIcon } from 'helpers/programHelper';
import { FileExtension } from 'helpers/imageHelper';
import { isUserFree } from 'helpers/userHelper';
import { isLyteBarsAllowed } from 'helpers/physicalProductHelper';
import { isKetoChocolateOverlayNeeded } from 'helpers/ketoChocolateBarsHelper';
import browserHistory from 'helpers/history';
import useTheme from 'helpers/themeHook';
import {
  CONTEXTUAL_OFFER_TYPE,
  userShouldSeeContextualOffer,
} from 'helpers/paywallHelper';

import { addModal } from 'actions/modalActions';

import { ResponsiveImage } from 'components/common';
import LockIcon from '@material-ui/icons/Lock';
import benefitImages from 'assets/images/program/benefits';
import DoneCheckmark from 'assets/images/program/item/done-checkmark.svg';
import DoneCheckmarkKeto from 'assets/images/program/item/keto/done-checkmarkKeto.svg';
import DoneCheckmarkFasting from 'assets/images/program/item/fasting/done-checkmarkFasting.svg';
import DoneCheckmarkHealthgenom from 'assets/images/program/item/healthgenom/done-checkmarkHealthgenom.svg';

import base from './Meal_base.module.scss';
import diet from './Meal.module.scss';
import keto from './Meal_keto.module.scss';
import ketogo from './Meal_ketogo.module.scss';
import fasting from './Meal_fasting.module.scss';
import healthgenom from './Meal_healthgenom.module.scss';

export type Props = {
  user: User;
  data: MealItem;
  link: string;
  dayId: number;
};

type IconList = {
  [key: string]: string;
};

const Meal: React.FC<Props> = ({
  user,
  data: { title, meal_type, benefits, image_url, state, id, cooking_time },
  link,
  dayId,
}) => {
  const isLyteBarsEnabled = isLyteBarsAllowed(user);
  const barsTransKey = isLyteBarsEnabled ? 'lytebars' : 'ketochocolatebarsv3';
  const barsImagePath = isLyteBarsEnabled
    ? `./keto-chocolate-bars/KetoChocolateBarsV3/breakfast-overlay-lyte`
    : './keto-chocolate-bars/KetoChocolateBarsV3/breakfast-overlay';
  const { t } = useTranslation(['app', barsTransKey]);
  const dispatch = useDispatch();
  const [s, currentTheme] = useTheme({
    base,
    diet,
    keto,
    ketogo,
    fasting,
    healthgenom,
  });
  const icons: IconList = {
    diet: DoneCheckmark,
    keto: DoneCheckmarkKeto,
    ketogo: DoneCheckmarkKeto,
    fasting: DoneCheckmarkFasting,
    healthgenom: DoneCheckmarkHealthgenom,
  };
  const themeIcon = icons[currentTheme];
  const isProgramChocolateOverlay =
    isKetoChocolateOverlayNeeded(user) && meal_type === 'breakfast';

  const renderBenefits = () => {
    if (!benefits) {
      return null;
    }

    return (
      <div className={s.benefits}>
        {benefits.map((benefit, i) => (
          <span key={benefit} className={s.benefit}>
            <img
              className={s.benefitIcon}
              src={benefitImages[benefit]}
              alt=""
            />
            <span>{t(`benefits.${benefit}`)}</span>
          </span>
        ))}
      </div>
    );
  };

  const renderCookingTime = () => {
    if (!cooking_time || cooking_time <= 0) {
      return null;
    }

    return `(${convertToMin(cooking_time)})`;
  };

  const handleClick = () => {
    // Open KetoChocolateBars modal
    if (isProgramChocolateOverlay) {
      dispatch(
        addModal({
          modal: ModalType.ketoChocolateBars,
          forwardUrl: `/program/${dayId}/${link}/${id}`,
        })
      );
      return;
    }

    // Open ContextualOffer modal
    if (
      meal_type === 'trick' &&
      userShouldSeeContextualOffer(user, CONTEXTUAL_OFFER_TYPE.tricks)
    ) {
      dispatch(
        addModal({
          modal: ModalType.contextualOffer,
          contextualType: CONTEXTUAL_OFFER_TYPE.tricks,
          forwardUrl: `/program/${dayId}/${link}/${id}`,
        })
      );
      return;
    }

    // Redirect user to an Offer or Meal
    const getRedirectUrl = () => {
      if (isUserFree(user)) {
        const urls = {
          monacofit: `/offer/${user.goal}_clicked_on_premium_meals/${meal_type}?utm_source=app.monacofit.com&utm_medium=${meal_type}_meal&utm_campaign=${meal_type}_helps_lose_weight_4_times_faster`,
          diet: `/offer/sub_expired?utm_source=appv7.stockholmdiet.com&utm_medium=${meal_type}_meal&utm_campaign=sub_expired`,
          keto: `/offer/sub_expired?utm_source=app.certifiedketodiet.com&utm_medium=${meal_type}_meal&utm_campaign=sub_expired`,
          ketogo: `/offer/sub_expired?utm_source=my.ketogo.app&utm_medium=${meal_type}_meal&utm_campaign=sub_expired`,
          fasting: `/offer/sub_expired?utm_source=app.certifiedfasting.com&utm_medium=${meal_type}_meal&utm_campaign=sub_expired`,
          healthgenom: `/offer/sub_expired?utm_source=app.healthgenom.com&utm_medium=${meal_type}_meal&utm_campaign=sub_expired`,
        };

        return urls[currentTheme];
      }

      return `/program/${dayId}/${link}/${id}`;
    };

    browserHistory.pushLater(getRedirectUrl());
  };

  return (
    <div className={isUserFree(user) ? s.opacityContainer : s.container}>
      <button type="button" onClick={handleClick} className={s.newLinkButton}>
        <div className={s.header}>
          {isUserFree(user) ? (
            <div className={s.counter}>
              <LockIcon />
            </div>
          ) : (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              className={s.icon}
              src={mealIcon(meal_type, MealIconSize.large)}
            />
          )}
          <div className={s.text}>
            <p className={s.info}>
              {t(`items.type.${meal_type}.header`)} {renderCookingTime()}
            </p>
            <h3 className={s.title}>
              {title}
              {isProgramChocolateOverlay &&
                t(`${barsTransKey}:program.breakfast_suffix`)}
            </h3>
          </div>
        </div>
        {renderBenefits()}
        <div className={s.imageContainer}>
          <img className={s.image} src={image_url} alt={title} />
          {isProgramChocolateOverlay && (
            <ResponsiveImage
              imagePath={barsImagePath}
              imageExtension={FileExtension.png}
              altText="breakfast-overlay"
              style={s.breakfastOverlay}
            />
          )}
          {isProgramChocolateOverlay && (
            <p className={s.exclamation}>
              {t(`${barsTransKey}:program.bubble`)}
            </p>
          )}
          {state === 'eaten' && (
            <img src={themeIcon} className={s.checkmark} alt="" />
          )}
        </div>
      </button>
    </div>
  );
};

export default Meal;
