/* eslint-disable camelcase */
import { of, concat } from 'rxjs';
import { switchMap, map, flatMap, catchError } from 'rxjs/operators';
import * as types from 'actions/actionTypes';
import * as sessionActions from 'actions/sessionActions';
import { getUserData } from 'actions/programActions';
import ResponseHandler from 'actions/ResponseHandler';
import {
  addSuccess,
  addError,
  addFlashMessageOfType,
} from 'redux-flash-messages';
import {
  logIn,
  fbLogIn,
  fbMerge,
  logOut,
  renewPassword,
  setNewPassword,
  validateResetToken,
  directLogIn,
} from 'api/SessionAPI';
import { fbConnect } from 'api/UserAPI';
import auth from 'helpers/authenticator';
import history from 'helpers/history';
import { catchedErrorText } from 'helpers/epicHelper';
import { identifyUser } from 'helpers/heapHelper';
import i18n from 'i18n';
import qs from 'qs';

const showSuccessMessage = () => {
  if (i18n.isInitialized) {
    return addSuccess({ text: i18n.t('flash_msg.success_login') });
  }
  return i18n.on('initialized', () =>
    addSuccess({ text: i18n.t('flash_msg.success_login') })
  );
};

export const logInEpic = (action$) =>
  action$.ofType(types.LOG_IN).pipe(
    switchMap(({ payload }) =>
      logIn(payload).pipe(
        map((response) => new ResponseHandler(response)),
        map((resHandler) => {
          const redirectUrl = resHandler.response.data.redirect_url;

          if (redirectUrl) {
            window.location.replace(redirectUrl);
            return sessionActions.loginRedirect();
          }

          const {
            attributes: { access_token, user_id },
          } = resHandler.response.data;

          if (window.heap) {
            identifyUser(user_id);
          }

          auth.logIn(access_token);

          setTimeout(() => {
            showSuccessMessage();
          }, 200);

          return sessionActions.logInSuccessAction();
        }),
        catchError((err) => {
          addError({ text: catchedErrorText(err) });
          return of(sessionActions.logInErrorAction());
        })
      )
    )
  );

export const fbLogInEpic = (action$) =>
  action$.ofType(types.FACEBOOK_LOG_IN).pipe(
    switchMap(({ payload }) =>
      fbLogIn(payload).pipe(
        map((response) => new ResponseHandler(response)),
        map((resHandler) => {
          const {
            attributes: { access_token, user_id },
          } = resHandler.response.data;

          if (window.heap) {
            identifyUser(user_id);
          }

          auth.logIn(access_token);
          showSuccessMessage();

          return sessionActions.logInSuccessAction();
        }),
        catchError((err) => {
          const { status } = err;

          if (status === 404) {
            // User Facebook profile not found
            const {
              facebook_profile: { data },
              error: { title },
            } = err.response;
            return of(
              sessionActions.showMergeModal({
                facebookProfile: data,
                errorTitle: title,
              })
            );
          }

          addError({ text: catchedErrorText(err) });
          return of(sessionActions.logInErrorAction());
        })
      )
    )
  );

export const fbMergeEpic = (action$) =>
  action$.ofType(types.FACEBOOK_EMAIL_MERGE).pipe(
    switchMap(({ payload }) =>
      fbMerge(payload).pipe(
        map((response) => new ResponseHandler(response)),
        flatMap((resHandler) => {
          const {
            user: { access_token, user_id },
          } = resHandler.response;

          if (window.heap) {
            identifyUser(user_id);
          }

          auth.logIn(access_token);
          showSuccessMessage();

          return concat(
            of(sessionActions.logInSuccessAction()),
            of(sessionActions.fbMergeSuccess())
          );
        }),
        catchError((err) => {
          const { status } = err;

          if (status === 422) {
            // Password mismatch error handling
            addError({ text: catchedErrorText(err) });
            return of(sessionActions.fbMergePasswordMismatch());
          }

          addError({ text: catchedErrorText(err) });
          return of(sessionActions.logInErrorAction());
        })
      )
    )
  );

export const fbConnectEpic = (action$) =>
  action$.ofType(types.FACEBOOK_CONNECT).pipe(
    switchMap(({ payload }) =>
      fbConnect(payload).pipe(
        map((response) => new ResponseHandler(response)),
        map(() => getUserData()),
        catchError((err) => {
          addError({ text: catchedErrorText(err) });
          return of(sessionActions.logInErrorAction());
        })
      )
    )
  );

export const logOutEpic = (action$) =>
  action$.ofType(types.LOG_OUT).pipe(
    switchMap(() =>
      logOut().pipe(
        map(() => {
          auth.logOut();
          return sessionActions.logOutSuccessAction();
        }),
        catchError((err) => {
          addError({ text: catchedErrorText(err) });
          return of(sessionActions.logOutErrorAction());
        })
      )
    )
  );

export const renewPasswordEpic = (action$) =>
  action$.ofType(types.PASSWORD_RENEW).pipe(
    switchMap(({ payload }) =>
      renewPassword(payload).pipe(
        map((response) => new ResponseHandler(response)),
        map(() => {
          history.pushLater('/renew_password/success');
          return sessionActions.renewPasswordSuccessAction();
        }),
        catchError((err) => {
          addError({ text: catchedErrorText(err) });
          return of(sessionActions.renewPasswordErrorAction());
        })
      )
    )
  );

// TODO: create new epic here for user creation
// backend '/register' -> auth.logIn(token) -> success action -> userLoad

export const setNewPasswordEpic = (action$) =>
  action$.ofType(types.NEW_PASSWORD_SET).pipe(
    switchMap(({ payload }) =>
      setNewPassword(payload).pipe(
        map((response) => new ResponseHandler(response)),
        map((resHandler) => {
          const {
            error,
            data: {
              attributes: { access_token, user_id },
            },
          } = resHandler.response;

          // Can remove this, when BE is fixed
          if (error) {
            const err = { response: resHandler.response };
            throw err;
          }

          if (window.heap) {
            identifyUser(user_id);
          }

          auth.logIn(access_token);
          showSuccessMessage();
          return sessionActions.setNewPasswordSuccessAction();
        }),
        catchError((err) => {
          const resHandler = new ResponseHandler(err.response);
          addFlashMessageOfType(
            'PASSWORD_RESET_FAIL',
            100,
            resHandler.getFlashMsgText()
          );
          return of(sessionActions.setNewPasswordErrorAction());
        })
      )
    )
  );

export const validateResetTokenEpic = (action$) =>
  action$.ofType(types.RESET_TOKEN_VALIDATE).pipe(
    switchMap(({ payload }) =>
      validateResetToken(payload).pipe(
        map((response) => new ResponseHandler(response)),
        map(() => sessionActions.validateResetTokenSuccessAction()),
        catchError((err) => {
          const resHandler = new ResponseHandler(err.response);
          addFlashMessageOfType(
            'INVALID_PASSWORD_TOKEN',
            100,
            resHandler.getFlashMsgText()
          );
          return of(sessionActions.validateResetTokenErrorAction());
        })
      )
    )
  );

const prepareStoragesForDirectLogin = (response) => {
  auth.logIn(response.access_token);
  localStorage.setItem('current_user_id', response.id);
};

export const directLogInEpic = (action$) =>
  action$.ofType(types.DIRECT_LOG_IN).pipe(
    switchMap(
      ({ payload, params: { offer_id, utm_params, auto_chargeable } }) =>
        directLogIn(payload).pipe(
          map((response) => new ResponseHandler(response)),
          map((resHandler) => {
            const normalizedResponse = resHandler.getNormalizedResponse();
            prepareStoragesForDirectLogin(normalizedResponse);

            if (window.heap) {
              identifyUser(normalizedResponse.id);
            }

            setTimeout(() => {
              showSuccessMessage();
            }, 200);

            const setQueryParameters = () => {
              const queryParams = {
                utm_campaign: utm_params.utm_campaign || '',
                utm_content: utm_params.utm_content || '',
                utm_medium: utm_params.utm_medium || '',
                utm_source: utm_params.utm_source || '',
                utm_term: utm_params.utm_term || '',
              };

              if (auto_chargeable) {
                queryParams.charge = 1;
              }

              return `?${qs.stringify(queryParams)}`;
            };

            if (offer_id)
              history.replaceLater(
                `/offer/direct/${offer_id}${setQueryParameters()}`
              );
            else history.replaceLater('/program');
            return sessionActions.directLogInSuccessAction();
          }),
          catchError((err) => {
            addError({ text: catchedErrorText(err) });
            auth.logOut();
            return of(sessionActions.directLogInErrorAction());
          })
        )
    )
  );
