import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isPrimaryTheme } from 'helpers/themeHelper';
import { FileExtension, getDynamicImageBundle } from 'helpers/imageHelper';

import { Button } from '@material-ui/core';
import { RetinaImage } from 'components/common';

import { ReactComponent as PayPal } from 'assets/images/offers/paymodes/paypal_recurring_diet.svg';
import { ReactComponent as Fdl } from 'assets/images/offers/paymodes/first_data_diet.svg';
import { ReactComponent as Seb } from 'assets/images/offers/paymodes/seb.svg';
import Swedbank from 'assets/images/offers/paymodes/swedbank.png';
import Swedbank2x from 'assets/images/offers/paymodes/swedbank@2x.png';
import Swedbank3x from 'assets/images/offers/paymodes/swedbank@3x.png';

import s from './styles/PaymodeBtn.module.scss';

class PaymodeBtn extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    context: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.number,
    index: PropTypes.number,
    translationKey: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    translationKey: 'paymodes',
  };

  getBtnWrapperClass = () => {
    const { size, index } = this.props;
    let btnWrapper = 'paymode-block';
    if (size % 2 !== 0 && size === index + 1) {
      btnWrapper += ' last';
    }
    return btnWrapper;
  };

  render = () => {
    const { t, onClick, label, context, translationKey, disabled } = this.props;

    if (isPrimaryTheme()) {
      const images = {
        paypal_recurring: <PayPal />,
        first_data: <Fdl />,
        swedbank: <RetinaImage images={[Swedbank, Swedbank2x, Swedbank3x]} />,
        seb: <Seb />,
        stripe: <Fdl />,
        paydoo: <Fdl />,
        primer: <Fdl />,
      };

      return (
        <Button
          disabled={disabled}
          className={s.button}
          classes={{ label: s.label }}
          onClick={onClick}
          variant="outlined"
          fullWidth
        >
          <div className={s.imageContainer}>{images[label]}</div>
          {t(`${translationKey}.${label}`, { context })}
        </Button>
      );
    }

    const paymentImage = getDynamicImageBundle(
      `./offers/paymodes/${label}`,
      FileExtension.png
    );

    return (
      <div className={this.getBtnWrapperClass()}>
        <button
          type="button"
          disabled={disabled}
          className="paymode-btn"
          onClick={onClick}
        >
          <img
            className="paymode-btn__img"
            src={paymentImage.base}
            srcSet={`
            ${paymentImage.medium} 2x, 
            ${paymentImage.large} 3x`}
            alt={`paymode-${label}`}
          />
          <span className="paymode-btn__label">
            {t(`${translationKey}.${label}`)}
          </span>
        </button>
      </div>
    );
  };
}

export default withTranslation()(PaymodeBtn);
