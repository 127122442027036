import React from 'react';

import { SVG } from 'types/svg';

function LoadingDiet(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={17} height={22} viewBox="0 0 17 22" {...props}>
      <g fill="none">
        <path
          fill="#005EBD"
          d="M11.218 10.782V5.72H5.062v5.062H0v6.156h5.062V22h6.156v-5.062h5.062v-6.156z"
        />
        <path
          fill="#FFDF46"
          d="M9.925 2.554L8.199.134l-1.726 2.42L2.64 0l2.394 4.84h6.211L13.64 0z"
        />
      </g>
    </svg>
  );
}

export default LoadingDiet;
