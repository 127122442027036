import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import browserHistory from 'helpers/history';
import { InternalWorkoutState } from 'types/workouts';

import { getWorkoutDataAction } from 'actions/workoutActions';

type Props = {
  children: React.ReactNode;
};

const RedirectUserToProgram = () => {
  browserHistory.replace('/program');
  return null;
};

function EnsureWorkoutsLoaded({ children }: Props) {
  const dispatch = useDispatch();
  const workout = useSelector<any, any>((store) => store.rootReducer.workout);
  const internalWorkouts = useSelector<any, InternalWorkoutState>(
    (store) => store.rootReducer.workouts
  );

  if (!internalWorkouts) {
    RedirectUserToProgram();
    return null;
  }

  const { dayId, workoutId } = internalWorkouts;
  if (!dayId || !workoutId) {
    RedirectUserToProgram();
    return null;
  }

  // This shouldn't happen, but just in case.
  if (workout?.message === 'Server Error' || workout?.error) {
    RedirectUserToProgram();
    return null;
  }

  // Fetch new data only when there is no workout or the workout is not the same.
  if (_.isEmpty(workout) || workout.id !== workoutId) {
    dispatch(getWorkoutDataAction(dayId, workoutId));
    return null;
  }

  return <>{children}</>;
}

export default EnsureWorkoutsLoaded;
