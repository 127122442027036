import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { getStyle, isMaterialUI } from 'helpers/themeHelper';
import { hideNavigation, showPremium } from 'helpers/userHelper';
import { getSvgThemeFileName } from 'helpers/svgHelper';
import { SVGFillColors } from 'types/svg';

import SvgIcon from 'components/common/SvgIcon';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import ChatIcon from '@material-ui/icons/Chat';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import base from './styles/Navbar.module.scss';
import diet from './styles/Navbar_diet.module.scss';
import keto from './styles/Navbar_keto.module.scss';
import ketogo from './styles/Navbar_ketogo.module.scss';
import fasting from './styles/Navbar_fasting.module.scss';
import healthgenom from './styles/Navbar_healthgenom.module.scss';

const s = getStyle(base, diet, keto, ketogo, fasting, healthgenom);
class Navbar extends PureComponent {
  static propTypes = {
    active: PropTypes.string,
    isLoading: PropTypes.bool,
    user: PropTypes.object,
  };

  getSvgFillColor = (active) =>
    active ? SVGFillColors.green : SVGFillColors.white;

  renderIcon = (filename, active) => (
    <SvgIcon
      filename={filename}
      fill={this.getSvgFillColor(active)}
      className="fit__nav__container__menu__btn__link__img"
    />
  );

  navButtons = () => {
    const { t, active, isLoading, location, user } = this.props;

    const disabledPaths = ['/sub_expired', '/sub_failed'];

    const disabled = isLoading || disabledPaths.includes(location.pathname);

    return (
      <ul className="fit__nav__container__menu">
        <li className="fit__nav__container__menu__btn">
          <Link
            to="/program"
            className={`fit__nav__container__menu__btn__link ${s.link} ${
              active === 'day' && s.active
            } ${disabled && 'disabled'}`}
          >
            {this.renderIcon('Day', active === 'day')}
            <span className="fit__nav__container__menu__btn__link__txt">
              {t('navbar.day')}
            </span>
          </Link>
        </li>
        {showPremium(user) && (
          <li className="fit__nav__container__menu__btn">
            <Link
              className={`fit__nav__container__menu__btn__link ${s.link} ${
                active === 'premium' && s.active
              } ${disabled && 'disabled'}`}
              to="/premium"
            >
              {this.renderIcon('Premium', active === 'premium')}
              <span className="fit__nav__container__menu__btn__link__txt">
                {t('navbar.premium')}
              </span>
            </Link>
          </li>
        )}
        <li className="fit__nav__container__menu__btn">
          <Link
            className={`fit__nav__container__menu__btn__link ${s.link} ${
              active === 'chat' && s.active
            } ${disabled && 'disabled'}`}
            to="/chat"
          >
            {this.renderIcon('ChatBubble', active === 'chat')}
            <span className="fit__nav__container__menu__btn__link__txt">
              {t('navbar.consultant')}
            </span>
          </Link>
        </li>
        <li className="fit__nav__container__menu__btn">
          <Link
            className={`fit__nav__container__menu__btn__link ${s.link} ${
              active === 'shopping-list' && s.active
            } ${disabled && 'disabled'}`}
            to="/program/shopping-list"
          >
            {this.renderIcon('ShoppingBasket', active === 'shopping-list')}
            <span className="fit__nav__container__menu__btn__link__txt">
              {t('navbar.shopping_list')}
            </span>
          </Link>
        </li>
        <li className="fit__nav__container__menu__btn">
          <Link
            className={`fit__nav__container__menu__btn__link ${s.link} ${
              active === 'profile' && s.active
            } ${disabled && 'disabled'}`}
            to="/user"
          >
            {this.renderIcon('User', active === 'profile')}
            <span className="fit__nav__container__menu__btn__link__txt">
              {t('navbar.profile')}
            </span>
          </Link>
        </li>
      </ul>
    );
  };

  renderPremiumIcon = (active) => {
    if (active === 'premium') {
      return <SvgIcon filename={getSvgThemeFileName('PremiumActive')} />;
    }

    return <SvgIcon filename={getSvgThemeFileName('Premium')} />;
  };

  render = () => {
    const { active, user } = this.props;

    if (!user) {
      return null;
    }
    if (user && hideNavigation(user)) {
      return null;
    }

    if (isMaterialUI()) {
      return (
        <div className={`fit__nav ${s.background}`}>
          <BottomNavigation value={active} className={s.wrapper}>
            <BottomNavigationAction
              icon={<TodayIcon />}
              component={Link}
              to="/program"
              value="day"
              classes={{
                root: s.root,
                selected: s.selected,
                iconOnly: s.button,
              }}
            />
            {showPremium(user) && (
              <BottomNavigationAction
                icon={this.renderPremiumIcon(active)}
                component={Link}
                to="/premium"
                value="premium"
                classes={{
                  root: s.root,
                  selected: s.selected,
                  iconOnly: s.button,
                }}
              />
            )}
            <BottomNavigationAction
              icon={<ChatIcon />}
              component={Link}
              to="/chat"
              value="chat"
              classes={{
                root: s.root,
                selected: s.selected,
                iconOnly: s.button,
              }}
            />
            <BottomNavigationAction
              icon={<ShoppingBasketIcon />}
              component={Link}
              to="/program/shopping-list"
              value="shopping-list"
              classes={{
                root: s.root,
                selected: s.selected,
                iconOnly: s.button,
              }}
            />
            <BottomNavigationAction
              icon={<AccountCircleIcon />}
              component={Link}
              to="/user"
              value="profile"
              classes={{
                root: s.root,
                selected: s.selected,
                iconOnly: s.button,
              }}
            />
          </BottomNavigation>
        </div>
      );
    }

    return (
      <nav className={`fit__nav ${s.wrapper}`}>
        <div className={`fit__nav__container ${s.container}`}>
          <Link to="/program">
            <SvgIcon
              filename={getSvgThemeFileName('SmallLogo')}
              className={`fit__nav__container__logo ${s.logo}`}
            />
          </Link>
          {this.navButtons()}
        </div>
      </nav>
    );
  };
}

export default withTranslation()(withRouter(Navbar));
