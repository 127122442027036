import React from 'react';

import { SVG } from 'types/svg';

function SnackDiet(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={10} height={10} viewBox="0 0 10 10" {...props}>
      <defs>
        <path
          id="prefix__9a5188a9-c463-4a0e-8940-e1fc967ffbe4"
          d="M0 .195h3.809v3.63H0z"
        />
        <path
          id="prefix__88162611-3a0e-4116-b827-6a96b48b3a0a"
          d="M.054.034H2.8V3.84H.054z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width={10} height={10} fill="#FFD445" rx={2} />
        <g transform="translate(2 5)">
          <mask id="prefix__2fc33124-6bd1-4433-a25c-37d6e9725a34" fill="#fff">
            <use xlinkHref="#prefix__9a5188a9-c463-4a0e-8940-e1fc967ffbe4" />
          </mask>
          <path
            fill="#1D70B4"
            d="M1.015 1.127c-.048.019-.117.109-.162.266-.058.201-.072.519.08.748a.149.149 0 01-.03.2.129.129 0 01-.08.027.133.133 0 01-.11-.06c-.209-.315-.198-.723-.118-1C.66 1.078.78.913.92.857c.07-.028.148.01.174.084a.148.148 0 01-.08.186m2.42-.581C3.23.336 2.772.052 1.94.448 1.135-.003.659.249.445.444.003.845-.128 1.701.135 2.477c.28.83.918 1.333 1.705 1.345l.098.003c.743 0 1.368-.454 1.684-1.23.308-.757.23-1.619-.188-2.05"
            mask="url(#prefix__2fc33124-6bd1-4433-a25c-37d6e9725a34)"
          />
        </g>
        <path
          fill="#1D70B4"
          d="M6.993 3.238c-.093-.314-.363-.577-.744-.722-.324-.122-.647-.129-.873-.026a5.514 5.514 0 00-.003-.34c-.003-.087-.063-.153-.135-.15-.072.004-.128.077-.125.164.052 1.368-.34 2.669-1.076 3.57-.05.061-.05.16.002.221.025.03.058.045.091.045a.121.121 0 00.093-.047c.573-.702.95-1.63 1.092-2.646.369.927.494 2.068.494 2.537 0 .086.058.156.13.156.072 0 .13-.07.13-.156 0-.541-.125-1.575-.445-2.474a1.306 1.306 0 00.717.216c.21 0 .414-.051.587-.156.059-.035.086-.118.065-.192"
        />
        <g transform="translate(5 5)">
          <mask id="prefix__8d46003a-d0e9-4ff1-8195-eeabe19c9e01" fill="#fff">
            <use xlinkHref="#prefix__88162611-3a0e-4116-b827-6a96b48b3a0a" />
          </mask>
          <path
            fill="#1D70B4"
            d="M2.08.158C1.833.015 1.318-.127.618.493.597.509.588.515.573.528c.368.546.414 1.402.096 2.182-.148.364-.36.666-.615.896.26.154.543.234.837.234.158 0 .318-.023.479-.068.769-.185 1.295-.818 1.409-1.694C2.884 1.26 2.59.452 2.08.158"
            mask="url(#prefix__8d46003a-d0e9-4ff1-8195-eeabe19c9e01)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SnackDiet;
