import React from 'react';

import { ReactComponent as CrossIcon } from 'assets/images/modals/cross.svg';

import s from './CommonModal.module.scss';

export type Props = {
  icon?: React.ReactNode;
  title: React.ReactNode | string;
  body: React.ReactNode | string;
  buttons?: React.ReactNode;
  onClose?: Function;
};

function CommonModal({ icon, title, body, buttons, onClose }: Props) {
  return (
    <div className={s.container}>
      <div className={s.modal}>
        {icon && <div className={s.icon}>{icon}</div>}

        {onClose && (
          <CrossIcon className={s.crossIcon} onClick={() => onClose()} />
        )}

        <div className={s.title}>{title}</div>

        <div className={s.body}>{body}</div>

        <div className={s.buttons}>{buttons}</div>
      </div>
    </div>
  );
}

export default CommonModal;
