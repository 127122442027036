import { getCurrentTheme } from './themeHelper';

const THEMES: { [key: string]: string } = {
  diet: 'Diet',
  keto: 'Keto',
  ketogo: 'Ketogo',
  monacofit: 'Monacofit',
  fasting: 'Fasting',
  healthgenom: 'Healthgenom',
};

// eslint-disable-next-line import/prefer-default-export
export const getSvgThemeFileName = (fileName: string) => {
  const themeString = THEMES[getCurrentTheme()];

  return `${fileName}${themeString}`;
};
