import qs from 'qs';
import {
  apiHost,
  requestHeadersWithToken,
  requestHeaders,
  fetchData,
} from 'helpers/requestHelpers';

export const getUserProfileData = () => {
  const request = new Request(`${apiHost()}/api/v1/users/profile`, {
    method: 'GET',
    headers: requestHeadersWithToken(),
  });
  return fetchData(request);
};

export const getUserCurrentDayData = () => {
  const request = new Request(`${apiHost()}/api/v1/days`, {
    method: 'GET',
    headers: requestHeadersWithToken(),
  });
  return fetchData(request);
};

export const getUserDayDate = (dayId) => {
  const request = new Request(`${apiHost()}/api/v1/days/${dayId}`, {
    method: 'GET',
    headers: requestHeadersWithToken(),
  });
  return fetchData(request);
};

export const createDiaryEntry = (entry, dayId) => {
  const request = new Request(`${apiHost()}/api/v1/days/${dayId}/diary_entry`, {
    method: 'POST',
    headers: requestHeadersWithToken(),
    body: JSON.stringify(entry),
  });
  return fetchData(request);
};

export const getCalendarData = (params) => {
  let requestUrl = `${apiHost()}/api/v1/users/calendar`;
  if (params) {
    requestUrl += `?${qs.stringify(params)}`;
  }

  const request = new Request(requestUrl, {
    method: 'GET',
    headers: requestHeadersWithToken(),
  });
  return fetchData(request);
};

export const getClientLocale = () => {
  const request = new Request(`${apiHost()}/api/v1/geo_locale`, {
    method: 'GET',
    headers: requestHeaders(),
  });
  return fetchData(request);
};

export const getCountries = () => {
  const request = new Request(`${apiHost()}/api/v1/countries`, {
    method: 'GET',
    headers: requestHeaders(),
  });
  return fetchData(request);
};

export const adjustProgram = (data) => {
  const request = new Request(`${apiHost()}/api/v1/adjust_program`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: requestHeadersWithToken(),
  });
  return fetchData(request);
};

export const changeGoal = (data) => {
  const request = new Request(`${apiHost()}/api/v1/change_goal`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: requestHeadersWithToken(),
  });
  return fetchData(request);
};
