import { UTMS } from './program';

export type OfferType =
  | Offer
  | PreUnsubV2Offer
  | SubExpiredOffer
  | PremiumUpsellOffer;

export enum OfferPriceType {
  standard = 'standard',
  chance = 'chance',
  unsubscribe = 'unsubscribe',
  timer = 'timer',
}

export enum PurchaseSource {
  shoppingList = '/program/shopping-list',
}

type DisplayData = {
  [key: string]: string;
};

type ExtraData = {
  [key: string]: string;
};

export type OfferPrice = {
  id: number;
  amount: number;
  amount_in_euro: number;
  display_data: DisplayData;
  display_price: string;
  payment_type: PaymentType;
  stripe_price_id: number;
  type: OfferPriceType;
  extra_data?: ExtraData;
};

export enum OFFER_MODE {
  single = 'single',
  attached = 'attached',
  multi = 'multi',
}

export type AttachedOffers = Offer[];

export type OfferAccessItem = {
  id: number;
  type: string;
};

export interface Offer {
  id: number;
  accept_button_display_text: string;
  categories: string[];
  chance_amount: number;
  chance_price_in_euro: number;
  chance_display_amount: string;
  chance_display_discount: string;
  display_amount: string;
  display_currency: string;
  display_old_price: string;
  display_discount: string;
  locale: string;
  offer_type: string;
  offer_items: OfferItem[];
  offer_prices: OfferPrice[];
  paymodes: [Paymodes?];
  reject_button_display_text: string;
  template_name: string;
  gender: number;
  error?: boolean;
  amount: number;
  original_chance_amount: number;
  instant_chargeable_confirmation: boolean;
  inline_checkout: boolean;
  mode: OFFER_MODE;
  default_selected: boolean;
  sequence: number;
  attached_offers: AttachedOffers;
  access_items: OfferAccessItem[];
}

export type OfferItem = {
  id: number;
  main: boolean;
  offer_id: number;
  offer_type: string;
  purchase_item: PurchaseItem;
};

export type PurchaseItem = {
  frequency?: number;
  id: number;
  item: PurchaseItemProduct;
  months: number;
  name: string;
  paying_type: PaymentType.subscription | PaymentType.one_time;
  title: string;
};

type PurchaseItemProduct = {
  id: number;
  paypal_product_id: string;
  type: string;
};

export enum PaymentType {
  subscription = 0,
  one_time = 1,
  lifetime = 2,
}

export enum Paymodes {
  app_store = 'app_store',
  first_data = 'first_data',
  stripe = 'stripe',
  paypal_recurring = 'paypal_recurring',
  paypal_express = 'paypal_express',
  paypal_classic = 'paypal_classic',
  dummy_subscriptions = 'dummy_subscriptions',
  dummy = 'dummy',
  swedbank_lt = 'swedbank_lt',
  swedbank_lv = 'swedbank_lv',
  swedbank_et = 'swedbank_et',
  seb_lv = 'seb_lv',
  seb_et = 'seb_et',
  paydoo = 'paydoo',
  primer = 'primer',
}

export interface PreUnsubV2Offer extends Offer {
  content: {
    title: string;
    p1: string;
    p2: string;
    accept: string;
    decline: string;
  };
}

export interface SubExpiredOffer extends Offer {
  content: {
    p1: string;
    button_paypal: string;
    button_fdl: string;
    payment_info: string;
    benefit_title: string;
    list_benefits: { [key: string]: string };
    loss_title: string;
    list_loss: { [key: string]: string };
    buy_now: string;
  };
}

export interface PremiumUpsellOffer extends Offer {
  content: {
    timer_value: string;
  };
}

export enum PremiumUpsellPerson {
  person1 = 'person_1',
  person2 = 'person_2',
  person3 = 'person_3',
}

export enum PremiumUpsellTitle {
  title1 = 'title_1',
  title2 = 'title_2',
  title3 = 'title_3',
}

export enum PremiumUpsellPrice {
  price1 = 'price_1',
  price2 = 'price_2',
  price3 = 'price_3',
}

export enum ShoppingListOfferCard {
  first = 'card1',
  second = 'card2',
}

export type ToggleModal = {
  paymodeModal: boolean;
};

export enum ImageResolution {
  small = '',
  medium = '@2x',
  large = '@3x',
}

export const CollagenTemplate: { [key: string]: string } = {
  CollagenV1: 'collagen_v1',
  CollagenV2: 'collagen_v2',
};

export type ChanceState = {
  chanceTaken: boolean;
};

export type Details = {
  [key: string]: string | number | boolean | null | undefined;
};

export type OfferActivityPayload = {
  source: string;
  action: string;
  details?: Details;
};

export enum OFFER_ACTIVITY_ACTION {
  view = 'view',
  click = 'click',
  purchase = 'purchase',
  decline = 'decline',
}

export type OfferPriceIdType = {
  offerPriceId: number | undefined;
};
export interface AcceptOfferParametersType {
  paymodeModal: ToggleModal;
  chanceTaken: ChanceState;
  offer?: Offer;
  offerPriceId?: OfferPriceIdType;
  overrideUtms?: UTMS;
}

export type PurchaseSourceType = {
  purchaseSource: string | undefined;
};
export interface ToggleModalParametersType extends AcceptOfferParametersType {
  purchaseSource: PurchaseSourceType;
}
