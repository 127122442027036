import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getStyle, getButtonStyleForTheme } from 'helpers/themeHelper';
import { Button, SuccessIcon } from 'components/common';

import base from './DiaryModal.module.scss';

const s = getStyle(base);

class DiaryCompletedModal extends PureComponent {
  static propTypes = {
    status: PropTypes.bool.isRequired,
    closeModal: PropTypes.func,
  };

  render = () => {
    const { t, status, closeModal } = this.props;

    return (
      <div className={`modal open-${status}`}>
        <div className={`modal__body ${s.container}`}>
          <div className="modal__body__head">
            <SuccessIcon />
          </div>
          <div className="modal__body__content">
            <h4 className={`modal__body__content__h4 ${s.title}`}>
              {t('modals.diary_done.title')}
            </h4>
          </div>
          <div className="modal__body__footer">
            <Button
              label="close"
              type="text"
              buttonStyle={getButtonStyleForTheme()}
              onClick={closeModal}
            />
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation()(DiaryCompletedModal);
