import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (state = initialState.isConnectedToCable, action) => {
  switch (action.type) {
    case types.CHAT_SUBSCRIBE.SUCCESS:
      return 'connected';

    case types.CHAT_DISCONNECTED:
      return 'disconnected';

    case types.LOG_OUT.SUCCESS:
      return initialState.isConnectedToCable;

    default:
      return state;
  }
};
