import React from 'react';
import { getStyle } from 'helpers/themeHelper';

import base from './styles/SuccessIcon.module.scss';
import diet from './styles/SuccessIcon_diet.module.scss';
import keto from './styles/SuccessIcon_keto.module.scss';
import ketogo from './styles/SuccessIcon_ketogo.module.scss';
import fasting from './styles/SuccessIcon_fasting.module.scss';
import healthgenom from './styles/SuccessIcon_healthgenom.module.scss';

const s = getStyle(base, diet, keto, ketogo, fasting, healthgenom);

const SuccessIcon = () => (
  <svg
    className={`checkmark ${s.checkmark}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 52"
  >
    <circle
      className={`checkmark__circle ${s.circle}`}
      cx={26}
      cy={26}
      r={25}
      fill="none"
    />
    <path
      className={`checkmark__check ${s.check}`}
      fill="none"
      d="M14.1 27.2l7.1 7.2 16.7-16.8"
    />
  </svg>
);

export default SuccessIcon;
