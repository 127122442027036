import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Checkbox,
} from '@material-ui/core';
import useTheme from 'helpers/themeHook';
import { isMaterialUI, getMaterialUIColor } from 'helpers/themeHelper';

import base from './styles/CheckBox.module.scss';
import diet from './styles/CheckBox_diet.module.scss';
import keto from './styles/CheckBox_keto.module.scss';
import ketogo from './styles/CheckBox_ketogo.module.scss';
import fasting from './styles/CheckBox_fasting.module.scss';
import healthgenom from './styles/CheckBox_healthgenom.module.scss';

type Props = {
  name: string;
  value: string;
  label: string;
  position?: string;
  error?: string;
  labelId?: string;
  checked?: boolean;
  type?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  description?: string;
  checkboxType?: string;
  hidden?: boolean;
  disabled?: boolean;
  id?: string;
  noDivider?: boolean;
};

const CheckBox: React.FC<Props> = ({
  position,
  type,
  error,
  checkboxType,
  value,
  checked,
  onChange,
  labelId = '',
  disabled,
  label,
  description,
  name,
  id,
  noDivider = false,
  hidden,
}) => {
  const [s] = useTheme({ base, diet, keto, ketogo, fasting, healthgenom });

  const getWrapperClass = () => {
    let wrapperClass = 'form';
    if (position) wrapperClass += ` ${position}`;
    if (type) wrapperClass += ` ${type}`;
    if (error && error.length > 0) wrapperClass += ' has-error';
    return wrapperClass;
  };

  const getCheckBoxClass = () => {
    let checkBoxClass = 'form__input checkbox';
    if (checkboxType) checkBoxClass += ` ${checkboxType}`;
    return checkBoxClass;
  };

  const generateRandomString = () =>
    labelId || Math.random().toString(36).slice(2);

  const randomString = generateRandomString();

  if (hidden) {
    return (
      <input
        type="hidden"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
    );
  }

  if (isMaterialUI()) {
    const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
      onChange({
        target: {
          type: 'checkbox',
          name,
          value,
          checked: !checked,
          id: id || randomString,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);

    return (
      <>
        <ListItem
          button
          selected={checked}
          onClick={onClick}
          classes={{ selected: s.selected, container: s.container }}
          disabled={disabled}
        >
          <ListItemSecondaryAction>
            <Checkbox
              id={id || randomString}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={onClick as any}
              edge="end"
              checked={checked}
              tabIndex={-1}
              disabled={disabled}
              color={getMaterialUIColor()}
            />
          </ListItemSecondaryAction>
          <ListItemText className={s.customSizeSpan}>
            {label}
            {description && ` (${description})`}
          </ListItemText>
        </ListItem>
        {!noDivider && (
          <Divider
            classes={{ root: s.container }}
            variant="fullWidth"
            component="li"
          />
        )}
      </>
    );
  }

  return (
    <div className={getWrapperClass()}>
      <div className={getCheckBoxClass()}>
        <input
          className={`form__input__checkbox ${s.input}`}
          type="checkbox"
          name={name}
          value={value}
          id={id || randomString}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <label
          className={`form__input__checkbox__label ${s.checkbox}`}
          htmlFor={id || randomString}
        >
          {label}
        </label>
        {description && (
          <label
            htmlFor={id || randomString}
            className="form__input__checkbox__label__desc"
          >
            {description}
          </label>
        )}
      </div>
    </div>
  );
};

export default CheckBox;
