import { getCurrentLocale } from 'i18n';
import { isPrimaryTheme, isMonacofit } from 'helpers/themeHelper';

function getDayNameDateFormat(): string {
  const locale = getCurrentLocale();

  if (isPrimaryTheme()) {
    if (['lt', 'ru', 'pt'].includes(locale)) {
      return 'EEEEEE';
    }

    return 'cccc';
  }

  if (isMonacofit()) {
    if (locale === 'et') {
      return 'ccccc';
    }
    if (locale === 'ru') {
      return 'cccccc';
    }
  }

  return 'ccc';
}

export default getDayNameDateFormat;
