import React from 'react';

import { getCurrentTheme } from 'helpers/themeHelper';
import { SVGFillColors } from 'types/svg';

import SvgIcon from 'components/common/SvgIcon';

import s from './Event.module.scss';

const Event = ({ name, status }) => {
  let filename = '';
  let fillColor = SVGFillColors.lightGray;

  switch (name) {
    case 'diary':
      filename = 'Diary';
      break;

    case 'workout':
      filename = 'Exercise';
      break;

    case 'trick':
      filename = 'Trick';
      break;

    case 'snack':
      filename = 'Snack';
      break;

    default:
      filename = 'ProgramMeal';
  }

  if (['completed', 'eaten'].includes(status)) {
    fillColor = SVGFillColors.green;
  }

  if (getCurrentTheme() === 'diet') {
    filename += 'Diet';
  }

  return (
    <span className={s.container}>
      <SvgIcon filename={filename} fill={fillColor} className={s.icon} />
    </span>
  );
};

export default Event;
