import { Benefits } from './days';

export interface WorkoutPhaseData {
  currentCount: number;
  totalCount: number;
  doneCount: number;
  currentWorkoutInSequence: number;
  currentExercise: ExpandedExercise | null;
}

export type DoneWorkout = { [key: number]: number };

export interface InternalWorkoutState {
  dayId: number | null;
  workoutId: number | null;
  inprogressWorkoutState: InprogressWorkoutState | null;
}

export interface InprogressWorkoutState {
  workoutPhase: WorkoutPhase;
  warmup: WorkoutPhaseData;
  exercise: WorkoutPhaseData;
  cooldown: WorkoutPhaseData;
  benefits: Benefits[];
  isCompleted: boolean;
  doneWorkoutData: DoneWorkout;
  workoutDuration?: number;
}

type ExerciseGroup = 'pre_workout' | 'main' | 'post_workout';
type ExerciseVisibility = 'normal' | 'ghost';
type ExerciseType = 'workout_exercises' | 'ghost';

export type ExpandedExercise = {
  code: string;
  description: string;
  duration: number | null;
  adjusted_duration: number | null;
  group: ExerciseGroup;
  id: number;
  repetitions: number | null;
  adjusted_repetitions: number | null;
  title: string;
  type: ExerciseType;
  video_url: string;
  image_url: string;
  visibility: ExerciseVisibility[];
};

export type WorkoutPhase = 'warmup' | 'exercise' | 'cooldown';

export type WorkoutsExercises = {
  pre_workout: ExpandedExercise[];
  main: ExpandedExercise[];
  post_workout: ExpandedExercise[];
};

const INITIAL_WORKOUT_PHASE_DATA: WorkoutPhaseData = {
  currentCount: 0,
  totalCount: 0,
  doneCount: 0,
  currentWorkoutInSequence: 0,
  currentExercise: null,
};

const INITIAL_WORKOUT_BENEFITS: Benefits[] = ['blood', 'age', 'brain'];

export const INITIAL_INPROGRESS_WORKOUT_STATE: InprogressWorkoutState = {
  workoutPhase: 'warmup',
  warmup: INITIAL_WORKOUT_PHASE_DATA,
  exercise: INITIAL_WORKOUT_PHASE_DATA,
  cooldown: INITIAL_WORKOUT_PHASE_DATA,
  benefits: INITIAL_WORKOUT_BENEFITS,
  isCompleted: false,
  doneWorkoutData: [],
};
