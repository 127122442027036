import React from 'react';

function CheckMark() {
  return (
    <svg width={48} height={48} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <g fillRule="nonzero">
          <path
            d="M5.589 29.991 4 30.573a21.314 21.314 0 0 0 10.274 11.633l.776-1.504a19.628 19.628 0 0 1-9.461-10.71zm9.616 12.667c.626.287 1.268.541 1.925.766l.55-1.6a19.874 19.874 0 0 1-1.772-.706l-.703 1.54z"
            fill="#E9C46A"
          />
          <path
            d="M43.445 9.906a2.471 2.471 0 0 0-1.677-.909 2.457 2.457 0 0 0-1.821.54l-2.183 1.747C34.3 7.294 29.345 5 24.035 5 13.977 5 5.793 13.183 5.793 23.242c0 10.059 8.184 18.242 18.242 18.242 10.058 0 18.242-8.183 18.242-18.242 0-2.734-.609-5.343-1.761-7.78l2.565-2.055a2.493 2.493 0 0 0 .364-3.501zm-2.86 13.336c0 9.126-7.425 16.55-16.55 16.55s-16.55-7.424-16.55-16.55c0-9.126 7.424-16.55 16.55-16.55 4.793 0 9.265 2.062 12.407 5.65l-3.772 3.017a11.55 11.55 0 0 0-8.635-3.835c-6.471 0-11.735 5.256-11.735 11.718s5.264 11.719 11.735 11.719c6.461 0 11.718-5.257 11.718-11.719 0-1.228-.189-2.409-.548-3.527l3.947-3.16a16.388 16.388 0 0 1 1.432 6.687zm-23.605.167 3.474 4.955a2.363 2.363 0 0 0 1.92.962c.543 0 1.066-.184 1.496-.533l9.878-7.91c.186.76.312 1.54.312 2.36 0 5.528-4.498 10.026-10.026 10.026-5.536 0-10.041-4.498-10.041-10.026s4.505-10.025 10.041-10.025a9.871 9.871 0 0 1 7.313 3.2l-8.481 6.785-1.878-2.748a2.493 2.493 0 0 0-4.461 1.126 2.46 2.46 0 0 0 .453 1.828zm25.038-11.32-19.21 15.386-.003.003a.686.686 0 0 1-.523.149.677.677 0 0 1-.456-.254l-3.474-4.956a.798.798 0 0 1 .647-1.273c.238 0 .47.104.613.294l2.886 4.227 18.512-14.81a.796.796 0 0 1 1.008 1.235z"
            fill="#2A9D8F"
          />
          <path
            fill="#E9C46A"
            d="m21.048 8.055 1.677-.234.37 2.652-1.675.235zm-9.864 6.562 1.02-1.352 2.138 1.612-1.02 1.353zm-2.57 9.936 2.652-.371.235 1.676-2.652.371zm5.443 10.521 1.613-2.138 1.352 1.02-1.613 2.138zm10.918.938 1.676-.235.372 2.653-1.676.235zm8.754-4.405 1.02-1.351 2.138 1.614-1.02 1.35zm2.841-10.98 2.652-.372.235 1.676-2.653.373zm-5.52-8.097 1.612-2.139 1.353 1.02-1.613 2.138z"
          />
        </g>
      </g>
    </svg>
  );
}

export default CheckMark;
