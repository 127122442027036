import React, { useEffect, useRef } from 'react';

import { Timer, Cycle } from 'types/fasting';

import { useFastingPercentage } from 'hooks';

import { SvgIcon } from 'components/common';

import LightningIcon from 'assets/images/program/fasting/fasting-card/default/lightning.svg';
import FlameIcon from 'assets/images/program/fasting/fasting-card/default/flame.svg';
import SkullIcon from 'assets/images/program/fasting/fasting-card/default/skull.svg';
import ShieldIcon from 'assets/images/program/fasting/fasting-card/default/shield.svg';
import HeartIconActive from 'assets/images/program/fasting/fasting-card/active/heart.svg';
import LightningIconActive from 'assets/images/program/fasting/fasting-card/active/lightning.svg';
import FlameIconActive from 'assets/images/program/fasting/fasting-card/active/flame.svg';
import SkullIconActive from 'assets/images/program/fasting/fasting-card/active/skull.svg';
import ShieldIconActive from 'assets/images/program/fasting/fasting-card/active/shield.svg';

import s from './HorseShoe.module.scss';

type Props = {
  cycle: Cycle;
  timer: Timer;
};

// Do not change this
const TIMER_START_VALUE = 452;
const TIMER_END_VALUE = 202;

function HorseShoe({ timer, cycle }: Props) {
  const { percentageProgress } = useFastingPercentage({
    cycle,
    timer,
  });

  const isLightningIconActive = percentageProgress >= 20;
  const isFlameIconActive = percentageProgress >= 45;
  const isSkullIconActive = percentageProgress >= 70;
  const isShieldIconActive = percentageProgress >= 95;
  const fastingGoalReached = percentageProgress >= 100;

  const circle = useRef(null);

  useEffect(() => {
    const timerValue = TIMER_START_VALUE - percentageProgress * 2.5;
    (circle.current as any).style.strokeDashoffset =
      timerValue <= TIMER_END_VALUE ? 202 : timerValue;
    (circle.current as any).style.transitionDuration = '0.5s';
  });

  return (
    <div className={s.container}>
      <div className={s.svgContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="130px"
          height="130px"
        >
          <defs>
            <linearGradient id="baseBar">
              <stop offset="0%" stopColor="#03cea4" />
              <stop offset="100%" stopColor="#29abe2" />
            </linearGradient>
          </defs>
          <circle cx="65" cy="65" r="50" strokeLinecap="round" />
        </svg>
        <img src={HeartIconActive} className={s.heart} alt="heart" />
        <img src={LightningIcon} className={s.lightning} alt="lightning" />
        <img src={FlameIcon} className={s.flame} alt="flame" />
        <img src={SkullIcon} className={s.skull} alt="skull" />
        <img src={ShieldIcon} className={s.shield} alt="shield" />
        {isLightningIconActive && (
          <img
            src={LightningIconActive}
            className={s.lightning}
            alt="lightning"
          />
        )}
        {isFlameIconActive && (
          <img src={FlameIconActive} className={s.flame} alt="flame" />
        )}
        {isSkullIconActive && (
          <img src={SkullIconActive} className={s.skull} alt="skull" />
        )}
        {isShieldIconActive && (
          <img src={ShieldIconActive} className={s.shield} alt="shield" />
        )}
      </div>
      <div className={s.percentage}>
        {fastingGoalReached ? (
          <SvgIcon filename="FastingCheckmark" />
        ) : (
          `${percentageProgress}%`
        )}
      </div>
      <div className={s.svgContainer}>
        <svg
          id="progressionBar"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="130px"
          height="130px"
        >
          <defs>
            <linearGradient id="progressBar">
              <stop offset="0%" stopColor="#03cea4" />
              <stop offset="100%" stopColor="#29abe2" />
            </linearGradient>
          </defs>
          <circle ref={circle} cx="65" cy="65" r="50" strokeLinecap="round" />
        </svg>
      </div>
    </div>
  );
}

export default HorseShoe;
