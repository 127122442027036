import React from 'react';
import { getLocaleDirection } from 'i18n';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { User } from 'types/user';
import { ModalType } from 'types/modals';
import { SVGFillColors } from 'types/svg';
import { Benefits, Day } from 'types/days';
import { INITIAL_INPROGRESS_WORKOUT_STATE } from 'types/workouts';
import {
  getDefaultVideoUrl,
  isWorkoutRestDay,
  isWorkoutsActive,
  retrieveWorkoutData,
  retrieveWorkoutDay,
} from 'helpers/workoutsHelper';

import { addModal } from 'actions/modalActions';
import { setWorkoutsState } from 'actions/workoutsActions';
import { getWorkoutDataAction } from 'actions/workoutActions';

import { SvgIcon } from 'components/common';
import DoneCheckmarkKeto from 'assets/images/program/item/keto/done-checkmarkKeto.svg';
import { WorkoutBenefit } from './components';

import s from './Workouts.module.scss';

type Props = {
  day: Day;
  user: User;
};

const FILE_NAME = 'ExerciseKeto';
const COMPLETED_STATE = 'completed';
const UNIVERSAL_OFFER = 'universal_workouts';

function Workouts({ day, user }: Props) {
  const history = useHistory();
  const { t } = useTranslation('workouts');
  const isWorkoutActive = isWorkoutsActive(user);
  const dispatch = useDispatch();
  const workoutData = retrieveWorkoutData(
    day,
    user,
    t('program.inactive.title')
  );
  // Possible that user has acces, but no data for that day
  if (!workoutData) return null;
  const { duration, benefits, title, state } = workoutData;
  const isRestDay = isWorkoutRestDay(day);

  const handleClick = async () => {
    // Cant access the next screen
    if (isRestDay) return;

    if (isWorkoutActive) {
      dispatch(
        setWorkoutsState({
          dayId: day.id,
          workoutId: day.day_workouts[0].id,
          inprogressWorkoutState: INITIAL_INPROGRESS_WORKOUT_STATE,
        })
      );

      await dispatch(getWorkoutDataAction(day.id, day.day_workouts[0].id)); // Get Workout data
      history.push('/workouts/start');
      return;
    }

    dispatch(
      addModal({ modal: ModalType.modalOffer, offerType: UNIVERSAL_OFFER })
    );
  };

  const renderMedia = () => {
    // Render static image for active workouts
    if (isWorkoutActive) {
      const workoutDay = retrieveWorkoutDay(day);
      if (isRestDay) {
        return (
          <div className={s.restDayContainer}>
            <img
              className={s.workoutThumbnail}
              src={workoutDay.image_url}
              alt={workoutDay.title}
            />
            <p className={s.restText}>{workoutDay.description}</p>
          </div>
        );
      }

      return (
        <img
          className={s.workoutThumbnail}
          src={workoutDay.image_url}
          alt={workoutDay.title}
        />
      );
    }

    // Render video for active workouts
    const videoSource = getDefaultVideoUrl(user);
    return (
      <div className={s.videoContainer}>
        <video className={s.workoutVideo} autoPlay loop muted playsInline>
          <source src={videoSource} type="video/mp4" />
        </video>
        <p className={s.videoDisclaimer}>
          <p className={s.linkText}>
            <Trans
              i18nKey="workouts:program.inactive.link_text"
              components={[<span className={s.accentedText} />]}
            />
          </p>
        </p>
      </div>
    );
  };

  const renderSubTitle = () => {
    if (isRestDay) {
      return t('program.active.rest_day');
    }

    return t('program.active.sub_title', { duration });
  };

  return (
    <button
      type="button"
      className={[
        s.buttonNormalize,
        s.container,
        isRestDay && s.noPointer,
      ].join(' ')}
      onClick={handleClick}
      dir={getLocaleDirection()}
    >
      <div className={s.topPart}>
        <div className={s.icon}>
          <SvgIcon
            filename={FILE_NAME}
            className={s.iconSvg}
            fill={SVGFillColors.green}
          />
        </div>
        <div className={s.titles}>
          <p className={s.smallTitle}>{renderSubTitle()}</p>
          <p className={s.bigTitle}>{title}</p>
        </div>
      </div>
      <div className={s.midPart}>
        {benefits.map((benefit: Benefits) => (
          <WorkoutBenefit key={benefit} benefit={benefit} />
        ))}
      </div>
      <div
        className={[s.botPart, !isWorkoutActive && s.botPartInactive].join(' ')}
      >
        {renderMedia()}
        {state === COMPLETED_STATE && (
          <img
            src={DoneCheckmarkKeto}
            className={s.workoutDone}
            alt="workout-done"
          />
        )}
      </div>
    </button>
  );
}

export default Workouts;
