import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SVGFillColors } from 'types/svg';

import SvgIcon from 'components/common/SvgIcon';

export default class DayEvent extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    status: PropTypes.string,
  };

  render = () => {
    const { name, status } = this.props;
    let filename = '';
    let fillColor = SVGFillColors.lightGray;

    switch (name) {
      case 'diary':
        filename = 'Diary';
        break;

      case 'workout':
        filename = 'Exercise';
        break;

      default:
        filename = 'ProgramMeal';
    }

    if (status !== undefined) {
      fillColor = SVGFillColors.green;
    }

    return (
      <span
        className="program_page__container__calendar__item__progress__item"
        key="diary"
      >
        <SvgIcon
          filename={filename}
          className="btn__inline-link__img"
          fill={fillColor}
        />
      </span>
    );
  };
}
