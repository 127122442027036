import settings from 'helpers/settings';
import auth from 'helpers/authenticator';
import { requestHeadersWithToken } from 'helpers/requestHelpers';
import browserHistory from 'helpers/history';

type Options = Omit<RequestInit, 'body'> & {
  body?: BodyInit | object;
};

// eslint-disable-next-line import/prefer-default-export
export const api = <T>(endpoint: string, options: Options): Promise<T> => {
  const { body, ...customConfig } = options;

  const config: RequestInit = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: requestHeadersWithToken(customConfig.headers),
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  return window
    .fetch(`${settings.API_HOST}/${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        auth.logOut();
        browserHistory.pushLater('/');
        return {};
      }

      if (response.status === 204) {
        return {};
      }

      let data;
      try {
        data = await response.json();
      } catch {
        data = {};
      }

      if (response.ok) {
        return data;
      }

      return Promise.reject(data);
    });
};
