import React from 'react';

import { SVG } from 'types/svg';

function Diary(props: SVG) {
  const { fill, ...rest } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 62 62" {...rest}>
      <defs>
        <style>{'.prefix__cls-2{fill:#fff}'}</style>
      </defs>
      <g id="diary-content">
        <circle cx={31} cy={31} r={31} fill={fill} />
        <path
          className="prefix__cls-2"
          d="M30.22 40.49H30a.78.78 0 0 1 0-1.56h.26v-7.15H30a.78.78 0 0 1 0-1.56h.26v-7.15H30a.78.78 0 0 1 0-1.56h.26v-7.93h-6.39V30a.78.78 0 0 1-.48.72.8.8 0 0 1-.3.06.78.78 0 0 1-.55-.23l-2.39-2.39-2.39 2.39a.79.79 0 0 1-.85.17.78.78 0 0 1-.49-.72V13.58h-.26A4.18 4.18 0 0 0 12 17.75v26.5a4.18 4.18 0 0 0 4.16 4.17h14.06zm-13 4.91h-.52a.78.78 0 1 1 0-1.56h.52a.78.78 0 1 1 0 1.56zm0-4.16h-.52a.78.78 0 1 1 0-1.56h.52a.78.78 0 0 1 0 1.56zm0-4.17h-.52a.78.78 0 1 1 0-1.56h.52a.78.78 0 0 1 0 1.56zm9.12 8.33h-6.28a.78.78 0 0 1 0-1.56h6.26a.78.78 0 0 1 0 1.56zm0-4.16h-6.28a.78.78 0 0 1 0-1.56h6.26a.78.78 0 0 1 0 1.56zm0-4.17h-6.28a.78.78 0 0 1 0-1.56h6.26a.78.78 0 0 1 0 1.56z"
        />
        <path
          className="prefix__cls-2"
          d="M20.7 26.47l1.61 1.61v-14.5H18v14.5l1.61-1.61a.78.78 0 0 1 1.09 0zM45.84 13.58H31.78v7.93H32a.78.78 0 0 1 0 1.56h-.26v7.15H32a.78.78 0 0 1 0 1.56h-.26v7.15H32a.78.78 0 0 1 0 1.56h-.26v7.93h14.1A4.18 4.18 0 0 0 50 44.25v-26.5a4.18 4.18 0 0 0-4.16-4.17zM36.07 45.4h-.52a.78.78 0 0 1 0-1.56h.52a.78.78 0 0 1 0 1.56zm0-4.16h-.52a.78.78 0 0 1 0-1.56h.52a.78.78 0 0 1 0 1.56zm0-4.17h-.52a.78.78 0 0 1 0-1.56h.52a.78.78 0 0 1 0 1.56zm0-4.16h-.52a.78.78 0 1 1 0-1.56h.52a.78.78 0 1 1 0 1.56zm0-4.17h-.52a.78.78 0 0 1 0-1.56h.52a.78.78 0 1 1 0 1.56zm0-4.16h-.52a.78.78 0 0 1 0-1.56h.52a.78.78 0 0 1 0 1.56zm9.12 20.82h-6.26a.78.78 0 0 1 0-1.56h6.26a.78.78 0 0 1 0 1.56zm0-4.16h-6.26a.78.78 0 0 1 0-1.56h6.26a.78.78 0 0 1 0 1.56zm0-4.17h-6.26a.78.78 0 0 1 0-1.56h6.26a.78.78 0 0 1 0 1.56zm0-4.16h-6.26a.78.78 0 1 1 0-1.56h6.26a.78.78 0 1 1 0 1.56zm0-4.17h-6.26a.78.78 0 0 1 0-1.56h6.26a.78.78 0 1 1 0 1.56zm0-4.16h-6.26a.78.78 0 1 1 0-1.56h6.26a.78.78 0 1 1 0 1.56zm0-4.16h-9.64a.79.79 0 0 1 0-1.57h9.64a.79.79 0 0 1 0 1.57z"
        />
      </g>
    </svg>
  );
}

export default Diary;
