import React from 'react';

function FastingCheckmarkBig() {
  return (
    <svg width={212} height={211} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          x1="100%"
          y1="1.105%"
          x2="0%"
          y2="98.895%"
          id="prefix__a2c7aea4-1f7c-433c-ba1b-867b22a154fc"
        >
          <stop stopColor="#03CEA4" offset="0%" />
          <stop stopColor="#29ABE2" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M180.458 15.086c-29.094 22.103-66.659 70.87-87.651 114.023L83.6 148.406 69.237 121.39C55.242 95.078 54.506 94.376 46.772 99.288c-4.42 2.807-16.941 10.174-27.253 16.139C8.839 121.742 0 127.355 0 128.057c0 .701 8.102 5.964 17.678 11.928 9.943 5.965 29.462 22.454 43.825 36.488l26.148 25.26 13.995-21.05c28.357-41.4 71.446-87.009 94.28-98.586 9.207-4.561 9.575-5.614 5.892-15.788-2.21-6.666-2.946-22.103-1.841-38.593C201.45 12.28 201.818 0 201.45 0c-.368 0-9.944 6.666-20.992 15.086"
        transform="translate(4 4.544)"
        fill="url(#prefix__a2c7aea4-1f7c-433c-ba1b-867b22a154fc)"
        stroke="#FFF"
        strokeWidth={8}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FastingCheckmarkBig;
