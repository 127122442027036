import { QuizTestimonial } from 'types/quiz';
import { User } from 'types/user';
import {
  UNSUBSCRIBE_ALLOWED_STEPS_V7,
  UNSUBSCRIBE_ALLOWED_USER_CATEGORIES,
} from 'types/unsubscribe';
import { Subscription } from 'types/subscription';
import { UNUSBSCRIBE_FLOW_VERSIONS } from 'types/unsubscribeFlowLogger';

import settings from './settings';
import { isUnsubscribeV8Enabled } from './unsubscribeV8Helper';
import {
  isSubscriptionPauseAllowed,
  isSubscriptionPaused,
} from './subscriptionHelper';

export const needToExcludeFromObjectionPage = (
  theme: string,
  person: string,
  gender: string
) =>
  theme === 'monacofit' &&
  person === QuizTestimonial.person3 &&
  gender === 'male';

export const isValidV7Step = (step: string): boolean =>
  UNSUBSCRIBE_ALLOWED_STEPS_V7.includes(step);

export const isUnsubscribeV7 = (userCategory: string): boolean =>
  settings.SHOW_UNSUBSCRIBEV7 &&
  UNSUBSCRIBE_ALLOWED_USER_CATEGORIES.includes(userCategory);

export const getUnsubscribeFlowVersion = (
  user: User,
  activeSubscription: Subscription
): string => {
  const { category } = user;

  if (isUnsubscribeV8Enabled(category)) {
    // Paused subscription unsubscribe flow test
    if (
      isSubscriptionPaused(activeSubscription) ||
      isSubscriptionPauseAllowed(user)
    ) {
      return UNUSBSCRIBE_FLOW_VERSIONS['v8.3'];
    }

    return UNUSBSCRIBE_FLOW_VERSIONS['v8.1'];
  }

  return UNUSBSCRIBE_FLOW_VERSIONS['v6.0'];
};
