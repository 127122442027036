import * as types from 'actions/actionTypes';

import {
  getExerciseGroupName,
  getNextWorkoutPhase,
  isLastPhaseExercise,
} from 'helpers/workoutsHelper';
import {
  ExpandedExercise,
  InprogressWorkoutState,
  InternalWorkoutState,
  WorkoutPhase,
  WorkoutPhaseData,
} from 'types/workouts';

import { ThunkActionType } from '../store/types';
import { submitWorkoutDataAction } from './workoutActions';

export const setWorkoutsState = (
  workouts: InternalWorkoutState
): types.WorkoutsStateActionType => ({
  type: types.SET_WORKOUTS_STATE,
  payload: workouts,
});

export const skipWorkoutPhase =
  (
    workouts: InternalWorkoutState,
    workout: any,
    workoutDuration: number
  ): ThunkActionType =>
  (dispatch) => {
    const inprogressWorkoutState =
      workouts.inprogressWorkoutState as InprogressWorkoutState;
    const { workoutPhase, benefits } = inprogressWorkoutState;
    const nextWorkoutPhase = getNextWorkoutPhase(workoutPhase);
    if (nextWorkoutPhase === 'done') {
      const exerciseTimes = {
        workout_exercise_times: inprogressWorkoutState.doneWorkoutData,
      };
      dispatch(
        submitWorkoutDataAction(
          workouts.dayId as number,
          workout.id,
          exerciseTimes
        )
      );
      dispatch(
        setWorkoutsState({
          ...workouts,
          inprogressWorkoutState: {
            ...inprogressWorkoutState,
            isCompleted: true,
            workoutDuration,
          },
        })
      );

      return;
    }

    const newWorkoutPhase = nextWorkoutPhase as WorkoutPhase;
    const newExerciseGroup = getExerciseGroupName(newWorkoutPhase);

    const getTotalCount = () => {
      if (newExerciseGroup === 'main') {
        const mainCount = workout.workout_exercises.main.filter(
          (exercise: any) => !exercise.visibility.includes('ghost')
        );

        return mainCount.length;
      }

      return workout.workout_exercises[newExerciseGroup].length;
    };

    dispatch(
      setWorkoutsState({
        ...workouts,
        inprogressWorkoutState: {
          ...inprogressWorkoutState,
          workoutPhase: newWorkoutPhase,
          [newWorkoutPhase]: {
            ...inprogressWorkoutState[newWorkoutPhase],
            currentCount: 0,
            totalCount: getTotalCount(),
            currentExercise: workout.workout_exercises[newExerciseGroup][0],
          },
          benefits,
        },
      })
    );
  };

export const skipExercise =
  (
    workouts: InternalWorkoutState,
    workout: any,
    workoutDuration: number,
    isRest?: boolean
  ): ThunkActionType =>
  (dispatch) => {
    const inprogressWorkoutState =
      workouts.inprogressWorkoutState as InprogressWorkoutState;
    const { workoutPhase, benefits } = inprogressWorkoutState;
    const { currentCount, currentWorkoutInSequence } =
      inprogressWorkoutState[workoutPhase];
    const exerciseGroup = getExerciseGroupName(workoutPhase);
    const isLastExercise = isLastPhaseExercise(inprogressWorkoutState, workout);

    // Only display non-ghost exercises
    const getTotalCount = (newExerciseGroup: string) => {
      if (newExerciseGroup === 'main') {
        const mainCount = workout.workout_exercises.main.filter(
          (exercise: any) => !exercise.visibility.includes('ghost')
        );

        return mainCount.length;
      }

      return workout.workout_exercises[newExerciseGroup].length;
    };

    // Check wether to move to next workout phase
    if (isLastExercise) {
      const nextWorkoutPhase = getNextWorkoutPhase(workoutPhase);
      if (nextWorkoutPhase === 'done') {
        const exerciseTimes = {
          workout_exercise_times: inprogressWorkoutState.doneWorkoutData,
        };
        dispatch(
          submitWorkoutDataAction(
            workouts.dayId as number,
            workout.id,
            exerciseTimes
          )
        );
        dispatch(
          setWorkoutsState({
            ...workouts,
            inprogressWorkoutState: {
              ...inprogressWorkoutState,
              isCompleted: true,
              workoutDuration,
            },
          })
        );

        return;
      }

      const newWorkoutPhase = nextWorkoutPhase as WorkoutPhase;
      const newExerciseGroup = getExerciseGroupName(newWorkoutPhase);

      dispatch(
        setWorkoutsState({
          ...workouts,
          inprogressWorkoutState: {
            ...inprogressWorkoutState,
            workoutPhase: newWorkoutPhase,
            [newWorkoutPhase]: {
              ...inprogressWorkoutState[newWorkoutPhase],
              currentCount: 0,
              totalCount: getTotalCount(newExerciseGroup),
              currentExercise: workout.workout_exercises[newExerciseGroup][0],
            },
            benefits,
          },
        })
      );
      return;
    }

    if (isRest) {
      dispatch(
        setWorkoutsState({
          ...workouts,
          inprogressWorkoutState: {
            ...inprogressWorkoutState,
            [workoutPhase]: {
              ...inprogressWorkoutState[workoutPhase],
              currentWorkoutInSequence: currentWorkoutInSequence + 1,
              currentExercise:
                workout.workout_exercises[exerciseGroup][
                  currentWorkoutInSequence + 1
                ],
            },
            benefits,
          },
        })
      );

      return;
    }

    // Move to next exercise in same phase
    dispatch(
      setWorkoutsState({
        ...workouts,
        inprogressWorkoutState: {
          ...inprogressWorkoutState,
          [workoutPhase]: {
            ...inprogressWorkoutState[workoutPhase],
            currentCount: currentCount + 1,
            currentWorkoutInSequence: currentWorkoutInSequence + 1,
            currentExercise:
              workout.workout_exercises[exerciseGroup][
                currentWorkoutInSequence + 1
              ],
          },
          benefits,
        },
      })
    );
  };

export const doneExercise =
  (
    workouts: InternalWorkoutState,
    workout: any,
    workoutDuration: number,
    secondsInExercise?: string
  ): ThunkActionType =>
  (dispatch) => {
    const inprogressWorkoutState =
      workouts.inprogressWorkoutState as InprogressWorkoutState;
    const { workoutPhase, benefits } = inprogressWorkoutState;
    const { currentCount, currentWorkoutInSequence, doneCount } =
      inprogressWorkoutState[workoutPhase];
    const exerciseGroup = getExerciseGroupName(workoutPhase);
    const isLastExercise = isLastPhaseExercise(inprogressWorkoutState, workout);

    const getWorkoutData = (
      inprogressData: InprogressWorkoutState,
      workoutPhaseData: WorkoutPhaseData
    ) => {
      const { doneWorkoutData } = inprogressData;
      const { currentExercise } = workoutPhaseData;
      const exercise = currentExercise as ExpandedExercise;
      const { id, repetitions, duration } = exercise;
      const isRepetitions = !!(repetitions && repetitions > 0);
      const isAlreadyDone = Object.keys(doneWorkoutData).find(
        (key) => key === id.toString()
      );

      const getValue = () => {
        if (isAlreadyDone) {
          return isRepetitions
            ? doneWorkoutData[exercise.id] +
                parseInt(secondsInExercise as string, 10)
            : doneWorkoutData[exercise.id] + (duration as number);
        }

        return isRepetitions
          ? parseInt(secondsInExercise as string, 10)
          : (duration as number);
      };

      return { ...doneWorkoutData, [id]: getValue() };
    };

    // Check wether to move to next workout phase
    if (isLastExercise) {
      const nextWorkoutPhase = getNextWorkoutPhase(workoutPhase);
      if (nextWorkoutPhase === 'done') {
        const exerciseTimes = {
          workout_exercise_times: inprogressWorkoutState.doneWorkoutData,
        };
        dispatch(
          submitWorkoutDataAction(
            workouts.dayId as number,
            workout.id,
            exerciseTimes
          )
        );
        dispatch(
          setWorkoutsState({
            ...workouts,
            inprogressWorkoutState: {
              ...inprogressWorkoutState,
              [workoutPhase]: {
                ...inprogressWorkoutState[workoutPhase],
                doneCount: doneCount + 1,
              },
              isCompleted: true,
              doneWorkoutData: getWorkoutData(
                inprogressWorkoutState,
                inprogressWorkoutState[workoutPhase]
              ),
              workoutDuration,
            },
          })
        );

        return;
      }

      const newWorkoutPhase = nextWorkoutPhase as WorkoutPhase;
      const newExerciseGroup = getExerciseGroupName(newWorkoutPhase);

      const getTotalCount = () => {
        if (newExerciseGroup === 'main') {
          const mainCount = workout.workout_exercises.main.filter(
            (exercise: any) => !exercise.visibility.includes('ghost')
          );

          return mainCount.length;
        }

        return workout.workout_exercises[newExerciseGroup].length;
      };

      dispatch(
        setWorkoutsState({
          ...workouts,
          inprogressWorkoutState: {
            ...inprogressWorkoutState,
            [workoutPhase]: {
              ...inprogressWorkoutState[workoutPhase],
              doneCount: doneCount + 1,
            },
            workoutPhase: newWorkoutPhase,
            [newWorkoutPhase]: {
              ...inprogressWorkoutState[newWorkoutPhase],
              currentCount: 0,
              totalCount: getTotalCount(),
              currentExercise: workout.workout_exercises[newExerciseGroup][0],
            },
            benefits,
            doneWorkoutData: getWorkoutData(
              inprogressWorkoutState,
              inprogressWorkoutState[workoutPhase]
            ),
          },
        })
      );
      return;
    }

    // Move to next exercise in same phase
    dispatch(
      setWorkoutsState({
        ...workouts,
        inprogressWorkoutState: {
          ...inprogressWorkoutState,
          [workoutPhase]: {
            ...inprogressWorkoutState[workoutPhase],
            currentCount: currentCount + 1,
            doneCount: doneCount + 1,
            currentWorkoutInSequence: currentWorkoutInSequence + 1,
            currentExercise:
              workout.workout_exercises[exerciseGroup][
                currentWorkoutInSequence + 1
              ],
          },
          benefits,
          doneWorkoutData: getWorkoutData(
            inprogressWorkoutState,
            inprogressWorkoutState[workoutPhase]
          ),
        },
      })
    );
  };
