import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getStyle, getButtonStyleForTheme } from 'helpers/themeHelper';

import { Button, SuccessIcon } from 'components/common';

import base from './Modal.module.scss';
import diet from './Modal_diet.module.scss';
import keto from './Modal_keto.module.scss';
import ketogo from './Modal_ketogo.module.scss';
import fasting from './Modal_fasting.module.scss';
import healthgenom from './Modal_healthgenom.module.scss';

const s = getStyle(base, diet, keto, ketogo, fasting, healthgenom);

class SurveySuccessModal extends PureComponent {
  static propTypes = {
    status: PropTypes.bool.isRequired,
    closeModal: PropTypes.func,
  };

  render = () => {
    const { t, status, closeModal } = this.props;

    return (
      <div className={`modal open-${status}`}>
        <div className={`modal__body ${s.container}`}>
          <div className="modal__body__head">
            <SuccessIcon />
          </div>
          <div className="modal__body__content">
            <h4 className={s.content}>{t('modals.survey_created.title')}</h4>
          </div>
          <div className={`modal__body__footer ${s.buttons}`}>
            <Button
              label="ok"
              type="text"
              buttonStyle={getButtonStyleForTheme()}
              onClick={closeModal}
              muiVariant="contained"
            />
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation()(SurveySuccessModal);
