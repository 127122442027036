import React from 'react';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { User } from 'types/user';
import { UpsellTypes, UpsellOfferTypes } from 'types/upsell';
import settings from 'helpers/settings';
import { getUserRegisterationStep } from 'helpers/userHelper';
import {
  isUpsellOfferAnnualPro,
  getUpsellOfferUtms,
} from 'helpers/offerHelper';

import { LoadingAnimation } from 'components/common';
import useUpsell from './useUpsell';

type Props = {
  children: React.ReactNode;
};

function EnsureUserSeenAnnualProOffer({ children }: Props) {
  const { t } = useTranslation();
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const { isLoading, upsellList } = useUpsell();

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (settings.SHOW_ANNUAL_PRO && isUpsellOfferAnnualPro(upsellList)) {
    if (getUserRegisterationStep(user)) {
      return (
        <Redirect
          to={`/offer/registration_annual_pro?${qs.stringify(
            getUpsellOfferUtms(
              UpsellTypes.pro,
              UpsellOfferTypes.registration,
              t
            )
          )}`}
        />
      );
    }

    return (
      <Redirect
        to={`/offer/universal_annual_pro?${qs.stringify(
          getUpsellOfferUtms(UpsellTypes.pro, UpsellOfferTypes.universal, t)
        )}`}
      />
    );
  }

  return <>{children}</>;
}

export default EnsureUserSeenAnnualProOffer;
