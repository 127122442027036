import { ThunkActionType } from 'store/types';
import { saveUserCustomField } from 'api/UserAPI';
import { Nutrionists } from 'types/chat';
import * as types from './actionTypes';

type PayloadAny = any;

export const chatInitialMessagesAction = () => ({
  type: types.CHAT_INITIAL_MESSAGES.ACTION,
});

export const chatInitialMessagesSuccessAction = (payload: PayloadAny) => ({
  type: types.CHAT_INITIAL_MESSAGES.SUCCESS,
  payload,
});

export const chatInitialMessagesErrorAction = () => ({
  type: types.CHAT_INITIAL_MESSAGES.ERROR,
});

export const chatSocketConnectAction = () => ({
  type: types.CHAT_SOCKET_CONNECT.ACTION,
});

export const chatSocketConnectSuccessAction = () => ({
  type: types.CHAT_SOCKET_CONNECT.SUCCESS,
});

export const chatSocketConnectErrorAction = () => ({
  type: types.CHAT_SOCKET_CONNECT.ERROR,
});

export const chatSocketDisconnectAction = () => ({
  type: types.CHAT_SOCKET_DISCONNECT.ACTION,
});

export const chatSocketDisconnectSuccessAction = () => ({
  type: types.CHAT_SOCKET_DISCONNECT.SUCCESS,
});

export const chatSocketDisconnectErrorAction = () => ({
  type: types.CHAT_SOCKET_DISCONNECT.ERROR,
});

export const chatOldMessagesAction = (payload: PayloadAny) => ({
  type: types.CHAT_OLD_MESSAGES.ACTION,
  payload,
});

export const chatOldMessagesSuccessAction = (payload: PayloadAny) => ({
  type: types.CHAT_OLD_MESSAGES.SUCCESS,
  payload,
});

export const chatOldMessagesErrorAction = () => ({
  type: types.CHAT_OLD_MESSAGES.ERROR,
});

export const chatSendMessageAction = (payload: PayloadAny) => ({
  type: types.CHAT_SEND_MESSAGE.ACTION,
  payload,
});

export const chatSendMessageSuccessAction = (payload: PayloadAny) => ({
  type: types.CHAT_SEND_MESSAGE.SUCCESS,
  payload,
});

export const chatSendMessageErrorAction = () => ({
  type: types.CHAT_SEND_MESSAGE.ERROR,
});

export const chatSubscribeAction = () => ({
  type: types.CHAT_SUBSCRIBE.ACTION,
});

export const chatSubscribeSuccessAction = () => ({
  type: types.CHAT_SUBSCRIBE.SUCCESS,
});

export const chatDisconnectedAction = () => ({ type: types.CHAT_DISCONNECTED });

export const chatAddMessageAction = (payload: PayloadAny) => ({
  type: types.CHAT_ADD_MESSAGE.ACTION,
  payload,
});

export const chatAddMessageSuccessAction = (payload: PayloadAny) => ({
  type: types.CHAT_ADD_MESSAGE.SUCCESS,
  payload,
});

export const chatAddMessageErrorAction = (payload: PayloadAny) => ({
  type: types.CHAT_ADD_MESSAGE.ERROR,
  payload,
});

export const chatAddMessageSentAction = () => ({
  type: types.CHAT_ADD_MESSAGE.SENT,
});

export const chatGetMessagesSuccessAction = (payload: PayloadAny) => ({
  type: types.CHAT_GET_MESSAGES.SUCCESS,
  payload,
});

export const chatGetMessagesAction = (payload: PayloadAny) => ({
  type: types.CHAT_GET_MESSAGES.ACTION,
  payload,
});

export const chatAllMessagesLoadedAction = () => ({
  type: types.CHAT_ALL_MESSAGES_LOADED,
});

export const getCableUrlAction = () => ({ type: types.CABLE_GET_URL.ACTION });

export const getCableUrlSuccessAction = (payload: PayloadAny) => ({
  type: types.CABLE_GET_URL.SUCCESS,
  payload,
});

export const getCableUrlErrorAction = () => ({
  type: types.CABLE_GET_URL.ERROR,
});

export const chatImitationSeenSuccessAction = () => ({
  type: types.CHAT_IMITATION_SEEN.SUCCESS,
});

export const chatImitationSeenErrorAction = () => ({
  type: types.CHAT_IMITATION_SEEN.ERROR,
});

export const selectedChatNutrionistSuccessAction = (payload: PayloadAny) => ({
  type: types.SELECTED_CHAT_NUTRIONIST.SUCCESS,
  payload,
});

export const selectedChatNutrionistErrorAction = (payload: PayloadAny) => ({
  type: types.SELECTED_CHAT_NUTRIONIST.ERROR,
  payload,
});

export const setChatImitationSeen =
  (): ThunkActionType =>
  // eslint-disable-next-line consistent-return
  async (dispatch, getState) => {
    const {
      rootReducer: {
        chat: { chatImitationSeen },
      },
    } = getState();

    // eslint-disable-next-line consistent-return
    if (chatImitationSeen) return;

    try {
      const { data } = await saveUserCustomField({
        field: 'chat_imitation_seen',
        value: 'true',
      });

      dispatch(chatImitationSeenSuccessAction());

      // eslint-disable-next-line consistent-return
      return data;
    } catch (error) {
      dispatch(chatImitationSeenErrorAction());
    }
  };

export const setSelectedChatNutrionist =
  (nutrionist: Nutrionists): ThunkActionType =>
  // eslint-disable-next-line consistent-return
  async (dispatch) => {
    try {
      const { data } = await saveUserCustomField({
        field: 'selected_chat_nutritionist',
        value: nutrionist,
      });

      dispatch(selectedChatNutrionistSuccessAction(data.value));

      // eslint-disable-next-line consistent-return
      return data;
    } catch (error) {
      dispatch(selectedChatNutrionistErrorAction(error));
    }
  };
