import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  getAutomaticCardSavePaymentLink,
  getSavedCardPaymode,
  getStandardOfferPrice,
} from 'helpers/offerHelper';
import { getGA4SessionId } from 'helpers/gaHelper';
import { Offer, Paymodes } from 'types/offer';
import { ModalType } from 'types/modals';
import { User } from 'types/user';

import { getOfferByType } from 'api/OfferAPI';
import { addModal } from 'actions/modalActions';
import ResponseHandler from 'actions/ResponseHandler';
import { createPrimerSessionToken } from 'api/primerApi';

import { PaymodeModal } from 'components/Checkout';
import { LoadingAnimation, SubmitButton } from 'components/common';

import s from './ExpressPlanFooter.module.scss';

type Props = {
  user: User;
};

type OfferData = {
  isLoading: boolean;
  isError: boolean;
  offer: Offer | undefined;
};

const INITIAL_OFFER_DATA: OfferData = {
  isLoading: true,
  isError: false,
  offer: undefined,
};

type ButtonStatus = {
  isDisabled: boolean;
  isModalOpen: boolean;
};

const INITIAL_BUTTON_STATUS: ButtonStatus = {
  isDisabled: false,
  isModalOpen: false,
};

function ExpressPlanFooter({ user }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation('expressplan');
  const [buttonStatus, setButtonStatus] = useState(INITIAL_BUTTON_STATUS);
  const [offerData, setOfferData] = useState<OfferData>(INITIAL_OFFER_DATA);
  const UTMS = {
    utm_source: t('domains:domain.website'),
    utm_medium: 'universal_flow',
    utm_campaign: 'express_checkout',
  };

  useEffect(() => {
    const loadOffer = async () => {
      try {
        const respnse = await getOfferByType(
          user.locale,
          user.category,
          'universal_express_plan_delivery'
        );
        const offer = new ResponseHandler(respnse);

        if (!offer) {
          setOfferData({
            ...INITIAL_OFFER_DATA,
            isLoading: false,
            isError: true,
          });
          return;
        }

        setOfferData({
          ...INITIAL_OFFER_DATA,
          isLoading: false,
          offer: offer.getNormalizedResponse(),
        });
      } catch {
        setOfferData({
          ...INITIAL_OFFER_DATA,
          isLoading: false,
          isError: true,
        });
      }
    };

    loadOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePurchase = async () => {
    setButtonStatus({ ...buttonStatus, isDisabled: true });
    const { offer } = offerData;
    const queryParams = {
      chance: false,
      origin_host: window.location.origin,
      utm_source: UTMS.utm_source,
      utm_medium: UTMS.utm_medium,
      utm_campaign: UTMS.utm_campaign,
      ga_session_id: getGA4SessionId(),
    };
    const savedCardPaymode = getSavedCardPaymode(user, offer);

    if (savedCardPaymode) {
      if ((offer as Offer).instant_chargeable_confirmation) {
        setButtonStatus({ ...buttonStatus, isDisabled: false });
        dispatch(
          addModal({
            modal: ModalType.cardSaveConfirmation,
            offer,
            urlParams: {
              paymode: savedCardPaymode,
              userId: user.id,
              offerId: (offer as Offer).id,
              queryParams,
            },
          })
        );

        return;
      }

      if (savedCardPaymode === Paymodes.primer) {
        const cardsavePayment = await createPrimerSessionToken(
          parseInt(user.id, 10),
          (offer as Offer).id,
          queryParams
        );

        window.location.assign(cardsavePayment.url);
        return;
      }

      const cardSavePaymentLink = getAutomaticCardSavePaymentLink(
        user,
        offer as Offer,
        queryParams
      );

      window.location.assign(cardSavePaymentLink as string);
      return;
    }

    setButtonStatus({ ...buttonStatus, isModalOpen: true });
  };

  if (offerData.isLoading) return <LoadingAnimation />;
  if (offerData.isError) return null;

  const standardPrice = getStandardOfferPrice(offerData.offer as Offer);
  const price = standardPrice?.display_price;

  return (
    <>
      <PaymodeModal
        status={buttonStatus.isModalOpen}
        closeModal={() => {
          setButtonStatus(INITIAL_BUTTON_STATUS);
        }}
        user={user}
        offer={offerData.offer as Offer}
        utms={UTMS}
      />
      <div className={s.footer}>
        <SubmitButton
          type="large"
          label={t('express_plan_program.purchasable.accept', { price })}
          disabled={buttonStatus.isDisabled}
          onClick={handlePurchase}
        />
        <p className={s.footerText}>
          {t('express_plan_program.purchasable.footer_text')}
        </p>
      </div>
    </>
  );
}

export default ExpressPlanFooter;
