import { Modals } from 'types/modals';
import * as types from './actionTypes';

export const addModal = (modal: Modals) => ({
  type: types.MODAL_ADD,
  payload: modal,
});

export const removeModal = (modal: Modals) => ({
  type: types.MODAL_REMOVE,
  payload: modal,
});

export type ModalActions = typeof addModal | typeof removeModal;
