import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import WorkoutsBackButton from '../WorkoutsBackButton';

import s from './WorkoutsPreLeaveModal.module.scss';

type Props = {
  isOpen: boolean;
  handleExploring: () => void;
  handleQuit: () => void;
  handleResume: () => void;
};

function WorkoutsPreLeaveModal({
  isOpen,
  handleExploring,
  handleQuit,
  handleResume,
}: Props) {
  const { t } = useTranslation('workouts');
  const isMobileDevice = window.innerWidth < 480;

  if (!isOpen) return null;

  return (
    <div className={s.container}>
      {isMobileDevice && (
        <WorkoutsBackButton
          label={t('workouts.pre_leave_modal.resume')}
          customStyle={s.resumeMobile}
          onClick={handleResume}
        />
      )}
      <div className={s.content}>
        <div className={s.resumeButtonContainer}>
          {!isMobileDevice && (
            <WorkoutsBackButton
              label={t('workouts.pre_leave_modal.resume')}
              onClick={handleResume}
            />
          )}
        </div>
        <p className={s.title}>{t('workouts.pre_leave_modal.title')}</p>
        <p className={s.text}>{t('workouts.pre_leave_modal.text')}</p>
        <div className={s.buttonContainer}>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            className={s.button}
            onClick={handleExploring}
          >
            {t('workouts.pre_leave_modal.buttons.exploring')}
          </Button>
          <div className={s.divider} />
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            className={s.button}
            onClick={handleQuit}
          >
            {t('workouts.pre_leave_modal.buttons.quit')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default WorkoutsPreLeaveModal;
