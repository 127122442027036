import React, { PureComponent } from 'react';
import { detect } from 'detect-browser';
import { withTranslation } from 'react-i18next';
import { capitalize } from 'helpers/stringHelper';

const browser = detect();
const minBrowserVersions = {
  chrome: '49',
  edge: '14',
  firefox: '40',
  ie: '10',
  opera: '36',
  safari: '9',
};

class BrowserSupport extends PureComponent {
  state = {
    supportStatus: false,
  };

  componentDidMount = () =>
    this.setState({ supportStatus: this.checkIfSupported() });

  checkIfSupported = () => {
    if (
      !browser ||
      !Object.prototype.hasOwnProperty.call(
        minBrowserVersions,
        browser.name.toLowerCase()
      )
    ) {
      return null;
    }

    if (
      this.compareVersions(
        browser.version,
        minBrowserVersions[browser.name.toLowerCase()]
      ) !== -1
    ) {
      return null;
    }

    return `${capitalize(browser.name)} ${
      minBrowserVersions[browser.name.toLowerCase()]
    }`;
  };

  /**
   * @param {string} browserVersion
   * @param {string} minVersion
   * @returns {number}
   */
  compareVersions = (browserVersion, minVersion) => {
    const browserMajorVersion = String(browserVersion).split('.')[0];

    if (parseInt(browserMajorVersion, 10) > parseInt(minVersion, 10)) return 1;
    if (parseInt(browserMajorVersion, 10) < parseInt(minVersion, 10)) return -1;

    return 0;
  };

  render = () => {
    const { t } = this.props;
    const { supportStatus } = this.state;

    if (!supportStatus) {
      return null;
    }

    const domain = t('domains:domain');

    return (
      <div className="browser-sup">
        {t('browser_support.msg', {
          your_browser_name: capitalize(browser.name),
          your_browser_version: browser.version,
          supported_browser: supportStatus,
          app_name: domain.app_name,
        })}
      </div>
    );
  };
}

export default withTranslation('domains')(BrowserSupport);
