import { useEffect, useState } from 'react';

import { Offer } from 'types/offer';
import { bugsnagClient } from 'helpers/bugsnagHelper';

import { getOfferByType } from 'api/OfferAPI';
import ResponseHandler from 'actions/ResponseHandler';

type Props = {
  type: string;
  locale: string;
  category: number;
};

type ReturnDataType = {
  isLoading: boolean;
  isError: boolean;
  offer: Offer | undefined;
};

const INITIAL_OFFER_DATA: ReturnDataType = {
  isLoading: true,
  isError: false,
  offer: undefined,
};

function useOfferByType({ type, locale, category }: Props): ReturnDataType {
  const [returnData, setReturnData] =
    useState<ReturnDataType>(INITIAL_OFFER_DATA);

  useEffect(() => {
    const loadOffer = async () => {
      const errorStateHandler = () => {
        // Notify bugsnag about the error
        bugsnagClient.notify(
          new Error(
            `useOfferByType hook missing offer:\n 1.type - ${type}\n 2.locale - ${locale}\n 3.category - ${category}`
          )
        );
        // Return data as an error
        setReturnData({
          ...INITIAL_OFFER_DATA,
          isLoading: false,
          isError: true,
        });
      };

      try {
        const response = await getOfferByType(locale, category, type, '');
        // If 500 internal comes - response?.message === 'Server Error'
        if (
          !response ||
          response.error ||
          response?.message === 'Server Error'
        ) {
          errorStateHandler();
          return;
        }

        const data = new ResponseHandler(response);
        const normalizedOffer = data.getNormalizedResponse();
        setReturnData({
          ...INITIAL_OFFER_DATA,
          isLoading: false,
          offer: normalizedOffer,
        });
      } catch (error) {
        errorStateHandler();
      }
    };

    loadOffer();
  }, [type, category, locale]);

  return returnData;
}

export default useOfferByType;
