/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import browserHistory from 'helpers/history';
import { connect } from 'react-redux';

class EnsureUserHasPhoneNumberWrapper extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    children: PropTypes.object,
  };

  componentDidMount = () => this.checkIfUserHasPhoneNumber();

  componentDidUpdate = () => this.checkIfUserHasPhoneNumber();

  checkIfUserHasPhoneNumber = () => {
    const { user } = this.props;
    if (user.phone_number) {
      browserHistory.replaceLater('/program');
    }
  };

  render = () => {
    const {
      user: { phone_number },
      children,
    } = this.props;

    return !phone_number ? children : null;
  };
}

const mapStateToProps = (state) => ({
  user: state.rootReducer.user,
});

export default connect(mapStateToProps)(EnsureUserHasPhoneNumberWrapper);
