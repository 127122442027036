import {
  addDays,
  format,
  getTime,
  subDays,
  parseISO,
  toDate,
  parse,
  isToday,
  isFuture,
  differenceInCalendarMonths,
  isYesterday,
  differenceInDays,
  differenceInMonths,
  addMonths,
} from 'date-fns';
import { getCurrentDateFnsLocale } from './localeHelper';

export const DEFAULT_FORMAT = 'dd-LLL-yyyy';

export function formatISODate(
  ISODate: string,
  dateFormat = DEFAULT_FORMAT,
  locale?: Locale
) {
  return format(parseISO(ISODate), dateFormat, { locale });
}

export function formatTimestamp(
  timestamp: number,
  dateFormat = DEFAULT_FORMAT,
  locale?: Locale
) {
  return format(toDate(timestamp), dateFormat, { locale });
}

export function formatDateFromTo(
  dateString: string,
  formatFrom: string,
  formatTo: string
) {
  const parsedDate = parse(dateString, formatFrom, new Date());
  return format(parsedDate, formatTo);
}

export function getCurrentFormattedDate(
  dateFormat = DEFAULT_FORMAT,
  locale?: Locale
) {
  return format(new Date(), dateFormat, { locale });
}

export function getCurrentDateTimestamp() {
  return getTime(new Date());
}

export function getCurrentDateTimestampAddDays(days: number) {
  return getTime(addDays(new Date(), days));
}

export function addDaysFormat(
  date: Date,
  daysToAdd: number,
  dateFormat: string
) {
  return format(addDays(date, daysToAdd), dateFormat);
}

export function subDaysFormat(
  date: Date,
  daysToAdd: number,
  dateFormat: string
) {
  return format(subDays(date, daysToAdd), dateFormat);
}

export function isTodayISO(ISODate: string) {
  return isToday(parseISO(ISODate));
}

export function isFutureISO(ISODate: string) {
  return isFuture(parseISO(ISODate));
}

export function getCurrentDifferenceInCalendarMonthsISO(ISODate: string) {
  return differenceInCalendarMonths(new Date(), parseISO(ISODate));
}

export function getCurrentDiffInDaysISO(ISODate: string) {
  return differenceInDays(parseISO(ISODate), new Date());
}

export function getTimeForFasting(ISODate: string, locale?: Locale) {
  return format(parseISO(ISODate), 'HH:mm aaa', { locale });
}

export function getTimeForFastingToday(locale?: Locale) {
  return format(new Date(), 'HH:mm aaa', { locale });
}

export function getFinishedFastingDateTime(ISODate: string, locale?: Locale) {
  return format(parseISO(ISODate), 'dd-LLL, HH:mm aaa', { locale });
}

export function isYeterdayISO(ISODate: string) {
  return isYesterday(parseISO(ISODate));
}

export const getDifferenceInDaysFromToday = (dateFrom: string) => {
  const dayDiff = differenceInDays(new Date(), parseISO(dateFrom));

  return dayDiff;
};

export const getDifferenceInMonthsFromToday = (dateFrom: string) => {
  const dayDiff = differenceInMonths(new Date(), parseISO(dateFrom));

  return dayDiff;
};

export const getCurrentMonth = () => {
  const locale = getCurrentDateFnsLocale();
  return format(new Date(), 'MMM', { locale });
};

export const getMonthPlussedMonths = (plussedMonths: number) => {
  const locale = getCurrentDateFnsLocale();
  const addedMonths = addMonths(new Date(), plussedMonths);

  return format(addedMonths, 'MMM', { locale });
};

export function formatDateObjectToReadable(
  date: Date,
  dateFormat = DEFAULT_FORMAT,
  locale?: Locale
) {
  return format(date, dateFormat, { locale });
}
