import React from 'react';

import { SVG } from 'types/svg';

function LoadingKeto(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={29}
      height={22}
      viewBox="0 0 29 22"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__al"
          x1="9.113%"
          x2="88.642%"
          y1="93.774%"
          y2="8.849%"
        >
          <stop offset="0%" stopColor="#264653" />
          <stop offset="46%" stopColor="#2A9D8F" />
          <stop offset="100%" stopColor="#FFCC70" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          fill="url(#prefix__al)"
          d="M18.25 1.039l-4.89 6.352 6.006 13.472a.288.288 0 01.038.192.92.92 0 01-1 .809h-3.812c-.577 0-1.116-.539-1.309-1l-3.772-8.43-3.541 4.618v3.811a.937.937 0 01-.963 1H1.313a1.019 1.019 0 01-1-1V1.039a.997.997 0 01.28-.731.995.995 0 01.72-.308h3.695c.655 0 .963.462.963 1.039v7.545l5.85-7.545A2.125 2.125 0 0113.516 0h3.772c.655 0 .963.462.963 1.039z"
        />
        <circle cx={24.073} cy={9.155} r={4.902} fill="#264653" />
        <path
          fill="#FFF"
          d="M23.15 11.494a.595.595 0 01-.41-.163l-1.103-1.047a.596.596 0 01.24-1 .594.594 0 01.577.138l.648.615 2.546-3.01a.592.592 0 11.906.766l-2.951 3.491a.599.599 0 01-.421.21h-.033z"
        />
      </g>
    </svg>
  );
}

export default LoadingKeto;
