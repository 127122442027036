import React from 'react';

function UnsubscribeV6PremiumGiftFasting() {
  return (
    <svg
      width={104}
      height={104}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="prefix_17ed0412-513d-4c06-b956-0d1a83355271"
        >
          <stop stopColor="#03CEA4" offset="0%" />
          <stop stopColor="#29ABE2" offset="100%" />
        </linearGradient>
        <path
          id="prefix_2d1049cc-2a28-4f38-ab0a-e838f55dcfa1"
          d="M0 0h46.784v24.15H0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          fill="url(#prefix_17ed0412-513d-4c06-b956-0d1a83355271)"
          cx={52}
          cy={52}
          r={52}
        />
        <g transform="translate(33.782 55.864)">
          <mask id="prefix_becaee5b-3ff8-4f43-9d7a-4ca949ab10c9" fill="#fff">
            <use xlinkHref="#prefix_2d1049cc-2a28-4f38-ab0a-e838f55dcfa1" />
          </mask>
          <path
            d="M29.639 10.146c-1.698.423-3.288.926-4.918 1.187-.999.16-2.074.057-3.085-.11-2.711-.45-5.406-.995-8.107-1.506-.767-.145-1.53-.307-2.297-.447-.755-.137-1.383.25-1.522.917-.154.746.26 1.36 1.08 1.527 2.006.411 4.016.804 6.025 1.204 1.606.32 3.213.641 4.82.959 1.508.298 2.984.117 4.46-.258 3.948-1.006 7.933-1.881 11.841-3.023 1.964-.573 3.64-.214 5.337.64 1.313.66 2.515 1.477 3.511 2.65-.16.11-.272.214-.405.273-6.2 2.75-12.271 5.797-18.627 8.195-1.97.743-3.942 1.478-6.055 1.738-1.616.198-3.146-.14-4.655-.646-5.546-1.858-11.088-3.725-16.632-5.59-.127-.043-.25-.097-.41-.16C1.77 11.883 3.492 6.077 4.505 0c.796.172 1.568.334 2.338.505 5.615 1.248 11.236 2.47 16.839 3.772 1.102.256 2.193.717 3.18 1.277 1.732.981 2.562 2.596 2.777 4.592"
            fill="#FFF"
            mask="url(#prefix_becaee5b-3ff8-4f43-9d7a-4ca949ab10c9)"
          />
        </g>
        <path
          d="m21 62.581.001-10.95c0-1.131.516-1.588 1.635-1.512 3.234.22 6.46.516 9.604 1.345.971.256 1.91.663 2.827 1.08.787.359 1.012 1.026.895 1.896-.65 4.828-1.956 9.501-3.3 14.166-.511 1.778-1.07 3.543-1.622 5.31-.289.928-.646 1.178-1.626 1.18-2.284.002-4.568.003-6.852-.001-1.11-.002-1.56-.457-1.561-1.564v-10.95m57.465-.272c-1.067-.308-2.095-.67-3.152-.896-1.673-.359-3.333-.148-4.976.288-1.563.414-3.138.785-4.704 1.19-.29.074-.452.045-.598-.253-.792-1.616-2.065-2.76-3.61-3.643-.194-.11-.417-.387-.418-.587-.023-4.371-.017-8.742-.014-13.114 0-.074.022-.148.04-.253h17.432V62.31zM56.953 32.407l.074-.168c-.814-.805-1.596-1.648-2.452-2.406-1.129-.999-2.357-1.895-3.873-2.18-.644-.122-1.363-.079-2.004.074-.684.162-.878.78-.499 1.387.252.403.584.798.97 1.068 2.342 1.633 5.056 1.966 7.784 2.225m-8.906.088c-.514-.467-.962-.815-1.338-1.228-1.005-1.103-1.55-2.367-.989-3.857.554-1.473 1.764-2.122 3.244-2.336 1.857-.268 3.5.356 5.057 1.277 1.628.963 3.007 2.227 4.268 3.623a.833.833 0 0 1 .204.511c.009 3.942.004 7.884 0 11.826 0 .038-.016.076-.048.21h-.71c-5.856 0-11.712-.001-17.568-.004-1.26 0-1.676-.422-1.677-1.695 0-2.244-.002-4.488.001-6.732.001-1.136.46-1.59 1.601-1.592 2.46-.003 4.92-.002 7.379-.003h.576m14.469-.167c2.392-.106 4.729-.426 6.848-1.555.7-.373 1.343-.94 1.857-1.548.552-.654.289-1.384-.56-1.539-.72-.13-1.542-.15-2.23.066-2.508.789-4.25 2.633-5.915 4.576m-1.53 10.14v-2.95c.001-2.949-.002-5.897.012-8.845.001-.233.058-.529.205-.689 1.632-1.783 3.414-3.383 5.673-4.344 1.514-.643 3.071-.89 4.673-.322 2.352.836 3.169 3.255 1.739 5.301-.471.674-1.12 1.224-1.712 1.857.172.008.374.024.576.024 2.421.002 4.843 0 7.265.003 1.144 0 1.58.445 1.581 1.601.001 2.324.004 4.647 0 6.971-.003.926-.477 1.432-1.395 1.433-6.094.008-12.187.004-18.28.003-.076 0-.152-.019-.337-.043m-2.502 2.574v12.733l-17.439-3.877v-8.856h17.439z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default UnsubscribeV6PremiumGiftFasting;
