import React from 'react';

import { SVG } from 'types/svg';

function AgeHealthgenom(props: SVG) {
  return (
    <svg
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          d="M12 7a2 2 0 0 0 2-2c0-.38-.1-.73-.29-1.03L12 1l-1.71 2.97c-.19.3-.29.65-.29 1.03 0 1.1.9 2 2 2zm4.6 9.99-1.07-1.07-1.08 1.07c-1.3 1.3-3.58 1.31-4.89 0l-1.07-1.07-1.09 1.07C6.75 17.64 5.88 18 4.96 18c-.73 0-1.4-.23-1.96-.61V22c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-4.61c-.56.38-1.23.61-1.96.61-.92 0-1.79-.36-2.44-1.01zM18 10h-5V8h-2v2H6c-1.66 0-3 1.34-3 3v1.54c0 1.08.88 1.96 1.96 1.96.52 0 1.02-.2 1.38-.57l2.14-2.13 2.13 2.13c.74.74 2.03.74 2.77 0l2.14-2.13 2.13 2.13c.37.37.86.57 1.38.57 1.08 0 1.96-.88 1.96-1.96V13c.01-1.66-1.33-3-2.99-3z"
          id="prefix__1392296e-c1ca-4c02-ac6e-15374d9afb97"
        />
      </defs>
      <use
        fill="#2884AA"
        xlinkHref="#prefix__1392296e-c1ca-4c02-ac6e-15374d9afb97"
      />
    </svg>
  );
}

export default AgeHealthgenom;
