import React from 'react';

import { FileExtension } from 'helpers/imageHelper';

import { Button, CircularProgress } from '@material-ui/core';
import { ResponsiveImage } from 'components/common';

import s from './WorkoutButton.module.scss';

type Props = {
  label: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  imageName?: string;
  isTransparent?: boolean;
  onClick: () => void;
};

function WorkoutButton({
  label,
  onClick,
  isLoading,
  isDisabled,
  imageName,
  isTransparent,
}: Props) {
  const renderLabel = () => {
    if (imageName) {
      return (
        <>
          <ResponsiveImage
            imagePath={`./workouts/${imageName}`}
            imageExtension={FileExtension.png}
            altText={imageName}
            style={s.buttonImage}
          />
          {label}
        </>
      );
    }

    return label;
  };

  return (
    <div className={`${s.workoutButton} ${isTransparent && s.transparentBg}`}>
      <Button
        type="button"
        color="secondary"
        variant="contained"
        className={s.submitButton}
        disabled={isDisabled}
        onClick={onClick}
      >
        {isLoading ? (
          <CircularProgress size={25} className={s.spinner} />
        ) : (
          renderLabel()
        )}
      </Button>
    </div>
  );
}

export default WorkoutButton;
