import React from 'react';

import { SVG } from 'types/svg';

function Lock(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 11.56a2.94 2.94 0 0 0-1.87 5.21v1.46a1.88 1.88 0 0 0 3.75 0v-1.46A2.94 2.94 0 0 0 12 11.56zm.68 4.1a.79.79 0 0 0-.41.69v1.88a.28.28 0 0 1-.27.27.28.28 0 0 1-.27-.27v-1.88a.79.79 0 0 0-.4-.69 1.36 1.36 0 0 1-.67-1.17 1.35 1.35 0 0 1 2.7 0 1.35 1.35 0 0 1-.69 1.17z" />
      <path d="M20.45 7.66h-2V6.43a6.44 6.44 0 0 0-12.88 0v1.23h-2a1.9 1.9 0 0 0-1.9 1.9V22.1A1.9 1.9 0 0 0 3.55 24h16.9a1.9 1.9 0 0 0 1.9-1.9V9.56a1.9 1.9 0 0 0-1.9-1.9zM7.16 6.43A4.83 4.83 0 0 1 15.42 3a4.79 4.79 0 0 1 1.42 3.4v1.25H7.16zM20.75 22.1a.31.31 0 0 1-.3.3H3.55a.31.31 0 0 1-.3-.3V9.56a.31.31 0 0 1 .3-.3h16.9a.31.31 0 0 1 .3.3z" />
    </svg>
  );
}

export default Lock;
