/**
 * Enums that are same as user -> access
 */
export enum PhysicalProductType {
  fatBurner = 'fat_burner',
  collagen = 'collagen',
  acvGummies = 'acv_gummies',
}
// TODO-Gummies: Talk with BE about the acces_item name

export const PHYSICAL_PRODUCT_T_KEY: { [key: string]: string } = {
  fat_burner: 'fatburner',
  collagen: 'collagen',
  acv_gummies: 'acvgummies',
};

export type AddressComponentsTypes = {
  long_name: string;
  short_name: string;
  types: string[];
}[];

export type CountryCodeOption = {
  key: string;
  value: string;
  label: string;
};

export type AllowedCountries = {
  value: string;
  emoji: string;
  name: string;
}[];
