import React, { ReactNode, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import s from './Carousel.module.scss';

type Props = {
  children: ReactNode;
  // Ability to render custom controls
  renderControl?: (isActive: boolean) => ReactNode;
};

// The generic swipeable carousel, which accepts
// any children elements served as slide items.
// Also it allows to render custom controls
// (usually they're clickable dots which switch the slides).
function Carousel({ children, renderControl }: Props) {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const slideLeft = () => {
    if (activeIndex + 1 >= React.Children.count(children)) return;

    setActiveIndex(activeIndex + 1);
  };

  const slideRight = () => {
    if (activeIndex <= 0) return;

    setActiveIndex(activeIndex - 1);
  };

  const handleControlClick = (index: number) => {
    setActiveIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: slideLeft,
    onSwipedRight: slideRight,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...handlers} className={s.container}>
      <div
        className={s.slidesContainer}
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => (
          <div className={s.slide}>{child}</div>
        ))}
      </div>

      <div className={s.controls}>
        {React.Children.map(children, (child, index) => (
          <button
            className={s.controlBtnWrapper}
            type="button"
            onClick={() => handleControlClick(index)}
          >
            {renderControl ? (
              <>{renderControl(index === activeIndex)}</>
            ) : (
              <div
                className={
                  index === activeIndex
                    ? s.defaultControlActive
                    : s.defaultControl
                }
              />
            )}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
