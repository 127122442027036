import React from 'react';

import { SVG } from 'types/svg';

function CallLog(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path
        fillRule="nonzero"
        d="M11.6 14.667C5.933 14.659 1.34 10.067 1.333 4.4A3.067 3.067 0 014.4 1.333c.172-.001.344.015.513.047.164.024.325.064.48.12a.667.667 0 01.434.5l.913 4a.666.666 0 01-.173.613c-.087.094-.094.1-.914.527A6.616 6.616 0 008.9 10.4c.433-.827.44-.833.533-.92a.668.668 0 01.614-.173l4 .913a.668.668 0 01.48.433 2.928 2.928 0 01.166.994 3.066 3.066 0 01-3.093 3.02z"
      />
    </svg>
  );
}

export default CallLog;
