import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useTimer from 'easytimer-react-hook';

import {
  ExpandedExercise,
  InprogressWorkoutState,
  InternalWorkoutState,
} from 'types/workouts';
import { convertToMin } from 'helpers/timeHelper';

import WorkoutButton from '../WorkoutButton';

import s from './WorkoutFirstOverlay.module.scss';

type Props = {
  isOpen: boolean;
  workout: ExpandedExercise;
  totalCount: number;
  handleClose: () => void;
};

const TIMER_SECONDS = 15;

function WorkoutFirstOverlay({
  isOpen,
  workout,
  totalCount,
  handleClose,
}: Props) {
  const { t } = useTranslation('workouts');
  const workouts = useSelector<any, InternalWorkoutState>(
    (store) => store.rootReducer.workouts
  );
  const inprogressWorkout =
    workouts.inprogressWorkoutState as InprogressWorkoutState;
  const { workoutPhase } = inprogressWorkout;
  const [timer, isTargetAchieved] = useTimer({
    updateWhenTargetAchieved: true,
  });

  useEffect(() => {
    if (isOpen) {
      timer.start({
        countdown: true,
        startValues: { seconds: TIMER_SECONDS },
        target: { seconds: 0 },
      });
    }

    return () => timer.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isTargetAchieved) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTargetAchieved]);

  if (!isOpen) return null;
  const { repetitions, duration } = workout;
  const isRepetitions = !!(repetitions && repetitions > 0);
  const periodicity = isRepetitions ? repetitions : convertToMin(duration);

  return (
    <div className={s.container}>
      <div className={s.middleContent}>
        <p className={s.title}>{t('workouts.overlay.title')}</p>
        <p className={s.countdown}>{timer.getTimeValues().seconds}</p>
        <p className={s.progress}>
          {t(`workouts.overlay.phase.${workoutPhase}`, {
            exerciseCount: totalCount,
          })}
        </p>
        <p className={s.exercise}>{workout.title}</p>
        <p className={s.duration}>
          {periodicity} {isRepetitions && t('workouts.values.repetitions')}
        </p>
        <div className={s.buttonContiner}>
          <WorkoutButton
            label={t('workouts.overlay.button')}
            isTransparent
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
}

export default WorkoutFirstOverlay;
