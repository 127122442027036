import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';

import { MetaData, PrimerInitEventType } from 'types/primer';
import { ModalType } from 'types/modals';
import { Offer, Paymodes } from 'types/offer';
import { User } from 'types/user';

import { getSavedCardPaymode } from 'helpers/offerHelper';
import { createAuditEvent } from 'helpers/auditEventHelper';

import { usePrimerCheckout } from 'hooks';
import { createPrimerSessionToken } from 'api/primerApi';
import { addModal } from 'actions/modalActions';

import { ReactComponent as PaymentIcon } from 'assets/images/offers/paymodes/first_data_diet.svg';

import s from '../PrimerCheckout.module.scss';

type Props = {
  user: User;
  offer: Offer;
  metaData: MetaData;
  primerEventType:
    | PrimerInitEventType.chosePaymode
    | PrimerInitEventType.offerRetried;
  isLoading: boolean;
  disabled: boolean;
  currentPaymode: string;
  duplicateButton?: boolean;
  onClick: (scrollFlag?: boolean) => void;
  onClose: () => void;
};

function InlineCheckout({
  user,
  offer,
  metaData,
  primerEventType,
  isLoading,
  disabled,
  currentPaymode,
  duplicateButton,
  onClick,
  onClose,
}: Props) {
  const { t } = useTranslation('');
  const [isHidden, setIsHidden] = useState(
    primerEventType === PrimerInitEventType.offerRetried
  );
  const dispatch = useDispatch();
  const cardSavePaymode = getSavedCardPaymode(user, offer);
  const restrictInit =
    currentPaymode !== '' && currentPaymode !== Paymodes.primer;
  usePrimerCheckout({
    user,
    offer,
    metaData,
    primerEventType,
    cardSavePaymode,
    restrictInit,
    duplicateButton,
  });

  const handleClick = async () => {
    if (primerEventType === PrimerInitEventType.offerRetried) {
      setIsHidden(!isHidden);
    }
    if (duplicateButton && !cardSavePaymode) {
      onClick(true);

      return;
    }
    if (!cardSavePaymode || disabled) {
      return;
    }

    onClick();
    if (offer.instant_chargeable_confirmation) {
      dispatch(
        addModal({
          modal: ModalType.cardSaveConfirmation,
          offer,
          urlParams: {
            paymode: Paymodes.primer,
            userId: user.id,
            offerId: offer.id,
            queryParams: metaData,
          },
          onClose: () => onClose(),
        })
      );

      return;
    }

    createAuditEvent({
      source: user,
      target: offer,
      eventType: primerEventType,
      description: Paymodes.primer,
    });

    if (window.gtag) {
      window.gtag('event', 'Choose paymode', {
        event_category: 'Payments',
        event_label: Paymodes.primer,
      });
    }

    const cardsavePayment = await createPrimerSessionToken(
      parseInt(user.id, 10),
      offer.id,
      metaData
    );

    window.location.assign(cardsavePayment.url);
  };

  const getButtonClass = () => {
    if (isLoading) {
      return s.loadingPrimerButton;
    }
    if (disabled) {
      return s.disabledPrimerButton;
    }
    if (duplicateButton) {
      return s.primerButton;
    }
    if (primerEventType === PrimerInitEventType.offerRetried) {
      return s.primerButton;
    }
    if (!cardSavePaymode) {
      return s.disabledActivePrimerButton;
    }

    return s.primerButton;
  };

  const renderPrimerCheckout = () => {
    if (cardSavePaymode) {
      return null;
    }
    if (restrictInit) {
      return null;
    }

    return (
      <div
        className={
          isHidden || duplicateButton
            ? s.primerContainerHidden
            : s.primerContainer
        }
      >
        <div id="checkout-container" />
      </div>
    );
  };

  return (
    <div className={s.wrapper}>
      <button
        type="button"
        onClick={() => handleClick()}
        className={getButtonClass()}
      >
        {isLoading ? (
          <CircularProgress size={25} color="primary" />
        ) : (
          <>
            <span className={s.imageContainer}>
              <PaymentIcon />
            </span>
            {t('paymodes.primer')}
          </>
        )}
      </button>
      {renderPrimerCheckout()}
    </div>
  );
}

export default InlineCheckout;
