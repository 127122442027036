import React from 'react';
import { Redirect } from 'react-router-dom';

import settings from 'helpers/settings';

type Props = {
  children: React.ReactNode;
};

function EnsureFatBurnerAllowed({ children }: Props) {
  if (!settings.SHOW_FAT_BURNER) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
}

export default EnsureFatBurnerAllowed;
