import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (state = initialState.shoppingList, action) => {
  switch (action.type) {
    case types.SHOPPING_LIST_LOAD.SUCCESS:
    case types.SHOPPING_LIST_CREATE.SUCCESS:
      return action.payload;

    case types.LOG_OUT.SUCCESS:
      return initialState.shoppingList;

    default:
      return state;
  }
};
