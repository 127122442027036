import React from 'react';

import { SVG } from 'types/svg';

function NotAllowed(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.5 20.45a12 12 0 0 0-16.95-17 12 12 0 0 0 17 17zm2-8.45a10.41 10.41 0 0 1-2.55 6.82l-1.48-1.48.47-6.7a.71.71 0 0 0 0-.2.29.29 0 0 0 0-.09L19 9a1.63 1.63 0 0 0-1.61-1.66h-2.8L13.34 5.2a.78.78 0 0 0-1.06-.2.77.77 0 0 0-.28 1l1.1 1.84A1.75 1.75 0 0 0 12.66 9l.1 1.37a.43.43 0 0 0 0 .05v.07a3.49 3.49 0 0 0-1.76-.6L5.17 4.1A10.45 10.45 0 0 1 22.46 12zm-5-2.33h-3.26V9c0-.08 0-.12.07-.12h3.14a.12.12 0 0 1 .08.06zm-6 2.93H6.67a2 2 0 0 1 1.81-1.2h1.83zm3 .82l-.16-2.2h3L17 15.9zm1.57 3.75h-1.3l-.1-1.4zM1.54 12a10.4 10.4 0 0 1 2.55-6.83l4.7 4.7h-.3A3.5 3.5 0 0 0 5 13.36a.76.76 0 0 0 .76.77H13l.05.75h-7.3a.76.76 0 0 0-.76.76v2.3a.77.77 0 0 0 .76.77h7.7a.72.72 0 0 0 .32-.08.65.65 0 0 0 .31.08h3.56l1.2 1.2A10.44 10.44 0 0 1 1.54 12zm11.12 4.4v.76H6.5v-.76z" />
    </svg>
  );
}

export default NotAllowed;
