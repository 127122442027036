import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { User } from 'types/user';
import { QueryParams } from 'types/unsubscribeV8';
import {
  isUnsubscribeV8Enabled,
  isValidUnsubscribeV8Step,
} from 'helpers/unsubscribeV8Helper';

type Props = {
  children: React.ReactNode;
};

const EnsureUnsubscribeV8Enabled = ({ children }: Props) => {
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const { stepId } = useParams<QueryParams>();

  if (!isUnsubscribeV8Enabled(user.category)) {
    return <Redirect to="/program" />;
  }
  if (!isValidUnsubscribeV8Step(stepId)) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
};

export default EnsureUnsubscribeV8Enabled;
