import React from 'react';
import { SVG } from 'types/svg';

function PremiumActiveKeto(props: SVG) {
  return (
    <svg
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          d="M20.493 21v1H3.505v-1h16.988zM22 6.4L20.493 20H3.505L2 6.4l5.443 7.189 4.719-6.8 4.719 6.8L22 6.4zm-10 6.549l-1.75 2.395L12 17.738l1.75-2.394L12 12.949zM17.713 2a3.535 3.535 0 003.536 3.536 3.539 3.539 0 00-3.536 3.54 3.541 3.541 0 00-3.54-3.54A3.538 3.538 0 0017.713 2zM6.741 5.041a1.947 1.947 0 001.947 1.947 1.948 1.948 0 00-1.947 1.949 1.95 1.95 0 00-1.949-1.949 1.948 1.948 0 001.949-1.947z"
          id="prefix__7641332f-ea03-4dea-a938-957314ca2723"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__f5bcc35a-c430-41a0-a596-7ecf66ed8bd0" fill="#fff">
          <use xlinkHref="#prefix__7641332f-ea03-4dea-a938-957314ca2723" />
        </mask>
        <g
          mask="url(#prefix__f5bcc35a-c430-41a0-a596-7ecf66ed8bd0)"
          fill="#FFF"
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
}

export default PremiumActiveKeto;
