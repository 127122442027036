import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (state = initialState.session, action) => {
  switch (action.type) {
    case types.LOG_IN.SUCCESS:
    case types.DIRECT_LOG_IN_SUCCESS:
    case types.USER_LOAD.SUCCESS:
    case types.LOG_OUT.SUCCESS:
    case types.NEW_PASSWORD_SET_SUCCESS:
      return { ...state, status: !!localStorage.access_token };

    case types.PASSWORD_RENEW_SUCCESS:
      return state;

    case types.LOG_IN:
      return { ...state, status: initialState.session.status };

    case types.SHOW_MERGE_MODAL: {
      const { facebookProfile, errorTitle } = action.payload;
      return {
        ...state,
        showMergeModal: true,
        mergeErrorTitle: errorTitle,
        facebookProfile,
      };
    }

    case types.HIDE_MERGE_MODAL:
      return { ...state, showMergeModal: false };

    case types.FACEBOOK_EMAIL_MERGE.ERROR: {
      return { ...state, incorrectPassword: true };
    }

    case types.FACEBOOK_EMAIL_MERGE.SUCCESS: {
      return initialState.session;
    }

    default:
      return state;
  }
};
