import React from 'react';

function UnsubscribeV6InvestmentFasting() {
  return (
    <svg width={104} height={104} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="prefix__8bcf653d-56fc-46a0-be50-b7b4b7c79c40"
        >
          <stop stopColor="#03CEA4" offset="0%" />
          <stop stopColor="#29ABE2" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          fill="url(#prefix__8bcf653d-56fc-46a0-be50-b7b4b7c79c40)"
          cx={52}
          cy={52}
          r={52}
        />
        <path
          d="m45.908 65.495-7.99-7.32a2.131 2.131 0 0 0-1.342-.487c-.488 0-.915.244-1.281.61-.55.61-.61 1.524-.183 2.256l.244.366a37.676 37.676 0 0 0 4.391 5.49l1.525 1.586c.244.244.366.548.305.853 0 .305-.122.61-.366.854s-.549.366-.854.305c-.305 0-.61-.122-.854-.366L38.1 68.118a51.01 51.01 0 0 1-4.818-6.039l-.122-.183a4.402 4.402 0 0 1 .305-5.184l.366-.366-3.294-6.222c-.366-.732-1.159-1.159-1.952-1.159-.61 0-1.159.305-1.585.732-.366.488-.55 1.037-.488 1.647l2.195 16.713a5.918 5.918 0 0 0 2.196 3.964l6.404 5.307c.366.305.671.793.732 1.28l.488 3.721c.061.366.366.61.671.61h9.515c.427 0 .732-.366.671-.793l-1.83-13.54c-.183-1.22-.732-2.318-1.646-3.111zM76.892 49.82c-.366-.489-.976-.733-1.585-.733-.793-.06-1.586.427-1.952 1.16l-3.294 6.16.366.366c1.28 1.464 1.403 3.537.305 5.184l-.183.244a41.858 41.858 0 0 1-4.818 6.039l-1.403 1.524a1.204 1.204 0 0 1-.854.366c-.305 0-.61-.122-.854-.305a1.204 1.204 0 0 1-.366-.854c0-.305.122-.61.305-.853l1.525-1.586a48.101 48.101 0 0 0 4.391-5.49l.244-.366c.488-.67.366-1.586-.183-2.256a1.717 1.717 0 0 0-1.28-.61c-.489 0-.977.122-1.342.488l-7.99 7.319c-.855.793-1.465 1.89-1.647 3.05l-1.83 13.54c-.061.427.244.793.67.793h9.516c.366 0 .67-.244.67-.61l.489-3.72c.06-.489.304-.976.731-1.281l6.405-5.185a6.056 6.056 0 0 0 2.196-3.964l2.195-16.713c.122-.67-.06-1.22-.427-1.708zm-3.72-9.76c2.988-11.04-5.917-16.102-12.504-14.882-6.587 1.22-8.722 7.685-8.722 7.685s-2.135-6.465-8.722-7.685C36.637 23.958 27.73 29.02 30.78 40.06c3.233 12.077 13.785 12.321 19.945 22.02.61.914 1.89.914 2.5 0 6.1-9.699 16.652-9.943 19.946-22.02z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default UnsubscribeV6InvestmentFasting;
