import * as types from 'actions/actionTypes';
import { getUserCustomField } from 'helpers/userHelper';
import initialState from 'reducers/initialState';

export default (state = initialState.chat, action) => {
  switch (action.type) {
    case types.CABLE_GET_URL.SUCCESS:
      return {
        ...state,
        cableEndpoint: action.payload,
      };
    case types.CHAT_INITIAL_MESSAGES.ACTION:
      return {
        ...state,
        isLoadingInitial: true,
      };
    case types.CHAT_INITIAL_MESSAGES.SUCCESS:
    case types.CHAT_INITIAL_MESSAGES.ERROR:
      return {
        ...state,
        isLoadingInitial: false,
      };
    case types.CHAT_SOCKET_CONNECT.SUCCESS:
      return {
        ...state,
        isConnectedToSocket: 'connected',
      };
    case types.CHAT_SOCKET_CONNECT.ERROR:
      return {
        ...state,
        isConnectedToSocket: 'disconnected',
      };
    case types.USER_LOAD.SUCCESS: {
      // Update chat state according to user custom data
      const chatImitationSeen = getUserCustomField(
        action.user,
        'chat_imitation_seen'
      );
      const selectedChatNutritionist = getUserCustomField(
        action.user,
        'selected_chat_nutritionist'
      );

      return {
        ...state,
        chatImitationSeen,
        selectedChatNutritionist,
      };
    }
    case types.CHAT_IMITATION_SEEN.SUCCESS:
      return {
        ...state,
        chatImitationSeen: true,
      };
    case types.CHAT_IMITATION_SEEN.ERROR:
      return {
        ...state,
        chatImitationSeen: null,
      };
    case types.SELECTED_CHAT_NUTRIONIST.SUCCESS:
      return {
        ...state,
        selectedChatNutritionist: action.payload,
      };
    case types.SELECTED_CHAT_NUTRIONIST.ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
};
