import React from 'react';

function UnsubscribeV6PremiumGiftMonacofit() {
  return (
    <svg width={56} height={56} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle fill="#60BD78" cx={28} cy={28} r={28} />
        <path
          d="M41.178 31.492l-3.053 1.852v-8.097h.985a.633.633 0 00.609-.61v-3.68c0-1.434-1.197-2.592-2.632-2.592h-2.468c.099-.362.174-.66.174-.999v-.008c0-1.802-1.49-3.267-3.29-3.267H31.5a3.312 3.312 0 00-2.263.916 3.333 3.333 0 00-1.027-2.07c-.648-.604-1.528-.937-2.48-.937-1.95 0-3.536 1.708-3.536 3.802.003.934.325 1.838.913 2.563h-2.565c-1.435 0-2.627 1.158-2.627 2.593v3.678a.63.63 0 00.604.611h.7v7.418c-.29.167-.661.36-.98.576l-.08-1.124a.573.573 0 00-.616-.534l-4.958.379a.579.579 0 00-.533.622l.83 10.88a.58.58 0 00.623.534l4.959-.378a.58.58 0 00.533-.622l-.042-.548 1.236-.837a2.264 2.264 0 011.66-.359l7.895 1.355a8.32 8.32 0 005.11-1.11.587.587 0 00.066-.047l10.373-8.351a.58.58 0 00.11-.788 3.118 3.118 0 00-4.226-.821zM38.56 20.958v3.13h-9.273v-4.564h7.8c.796 0 1.473.638 1.473 1.434zM31.5 15.25h.003c1.162 0 2.13.947 2.13 2.108v.004a2.21 2.21 0 01-.26 1.003h-4.086v-.804c0-.072.051-.155.056-.242.063-1.121 1.043-2.069 2.156-2.069zm-8.148.547c0-1.455 1.078-2.638 2.388-2.638 1.399 0 2.388.945 2.388 2.298v2.908h-3.023v-.007c-1.015-.297-1.753-1.376-1.753-2.561zm-4.28 5.16c0-.795.673-1.433 1.47-1.433h7.586v4.564h-9.055v-3.13zm1.305 4.29h16.589v8.8l-.471.284a3.956 3.956 0 00-3.593-1.756l-4.523.346a4.84 4.84 0 01-2.125-.32l-.498-.19a8.139 8.139 0 00-5.38-.205v-6.959zM13.994 42.8l-.743-9.726 3.803-.29.742 9.725-3.802.29zm20.234-2.276a7.155 7.155 0 01-4.307.94l-7.874-1.35a3.419 3.419 0 00-2.507.54l-.687.466-.497-6.51a6.938 6.938 0 016.99-1.119l.484.19c.838.328 1.74.464 2.637.396l4.522-.345a2.798 2.798 0 012.892 1.998l-6.794.52a.58.58 0 10.088 1.155l7.432-.568a.58.58 0 00.534-.622 3.947 3.947 0 00-.153-.827l4.795-2.908.012-.008a1.962 1.962 0 012.288.117l-9.855 7.935z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default UnsubscribeV6PremiumGiftMonacofit;
