import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (state = initialState.day, action) => {
  switch (action.type) {
    case types.DAY_LOAD.ACTION:
      return { ...state, isLoading: true };
    case types.DAY_LOAD.SUCCESS:
      return { isLoading: false, ...action.day };

    case types.DIARY_ENTRY_CREATE.SUCCESS:
      return {
        ...state,
        diary_entry: {
          ...action.diary,
          opened: !action.diary.weight,
        },
      };

    case types.DAY_CLEAR:
    case types.LOG_OUT.SUCCESS:
      return initialState.day;

    default:
      return state;
  }
};
