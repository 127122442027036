import React from 'react';
import { useTranslation } from 'react-i18next';

import { Cycle } from 'types/fasting';

import FastingBurn from 'assets/images/program/fasting/fasting-burn.svg';
import SmallFire from 'assets/images/program/fasting/small-fire.svg';
import SmallUtensils from 'assets/images/program/fasting/small-utensils.svg';

import s from './FastingNonStartedHeader.module.scss';

type Props = {
  cycle: Cycle;
};

function FastingNonStartedHeader({ cycle }: Props) {
  const { t } = useTranslation();

  return (
    <div className={s.header}>
      <div className={s.headerInformation}>
        <img className={s.icon} src={FastingBurn} alt="fasting-burn" />
        <div className={s.text}>
          <p className={s.info}>
            <img src={SmallFire} alt="small-flame" />
            <span className={s.fastingSmall}>{cycle.fasting}h</span>
            <img src={SmallUtensils} alt="small-utensils" />
            <span className={s.fastingSmall}>{cycle.eating}h</span>
          </p>
          <h3 className={s.title}>{t('items.type.fasting.title')}</h3>
        </div>
      </div>
    </div>
  );
}

export default FastingNonStartedHeader;
