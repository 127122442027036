import { Card } from './payment';
// eslint-disable-next-line import/named
import { OfferPrice, OfferPriceType, PurchaseItem } from './offer';

export type ShippingAddress = {
  address: string;
  address2: string;
  city: string;
  country: string;
  created_at: string;
  first_name: string;
  id: number;
  last_name: string;
  phone_number: string;
  phone_prefix: string;
  state: string;
  updated_at: string;
  user_id: number;
  zip: string;
  email?: string;
  google_place_id?: string;
  county?: string;
  region?: string;
  province?: string;
};

export type YearlyCommitment = {
  is_enabled: boolean;
  offer_id: number | null;
  fee_amount: number;
  fee_currency_symbol: string | null;
  end_date: string | null;
};

export type Subscription = {
  id: number;
  type: string;
  amount: string;
  company_identifier: string | null;
  chance: boolean;
  discounted: boolean;
  display_currency: string;
  display_currency_symbol: string;
  next_charge: string;
  platform: number;
  provider: string;
  provider_human: string;
  status: string;
  tos_accepted_at: string | null;
  unsubscribed_at: string | null;
  user_id: number;
  card_last_digits?: string;
  card: Card | null;
  last_payment_at: string;
  unsubscribe_locked: boolean;
  unsubscribe_locked_till: string | null;
  item_type: string;
  offer_id: number;
  created_at: string;
  offer_price: OfferPrice;
  purchase_item?: PurchaseItem;
  shipping_address?: ShippingAddress;
  is_pausable: boolean;
  is_paused: boolean;
  paused_till: string | null;
  yearly_commitment: YearlyCommitment;
};

export const initialSubscription = {
  id: 0,
  type: '',
  amount: '',
  company_identifier: null,
  chance: false,
  discounted: false,
  display_currency: '',
  display_currency_symbol: '',
  next_charge: '',
  platform: 0,
  provider: '',
  provider_human: '',
  status: '',
  tos_accepted_at: null,
  unsubscribed_at: null,
  user_id: 0,
  card: null,
  last_payment_at: '',
  unsubscribe_locked: false,
  unsubscribe_locked_till: '',
  item_type: '',
  offer_id: 0,
  created_at: '',
  offer_price: {
    id: 0,
    amount: 0,
    amount_in_euro: 0,
    display_data: {},
    display_price: '',
    payment_type: 0,
    stripe_price_id: 0,
    type: OfferPriceType.standard,
  },
  is_pausable: false,
  is_paused: false,
  paused_till: null,
  yearly_commitment: {
    is_enabled: false,
    offer_id: null,
    fee_amount: 0,
    fee_currency_symbol: null,
    end_date: null,
  },
};

export enum SubscriptionPlatform {
  web = 0,
  ios = 1,
}

export const DEFAULT_MONTH_DIVIDER = 30;
