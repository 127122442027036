import React from 'react';

function BackChevron() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.0002 6L15.4102 7.41L10.8302 12L15.4102 16.59L14.0002 18L8.00016 12L14.0002 6Z"
        fill="currentColor"
      />
      <mask
        id="mask0_4219_1730"
        maskUnits="userSpaceOnUse"
        x="8"
        y="6"
        width="8"
        height="12"
      >
        <path
          d="M14.0002 6L15.4102 7.41L10.8302 12L15.4102 16.59L14.0002 18L8.00016 12L14.0002 6Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4219_1730)" />
    </svg>
  );
}

export default BackChevron;
