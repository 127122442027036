import React from 'react';
import { useSelector } from 'react-redux';

import { User } from 'types/user';
import useTheme from 'helpers/themeHook';
import { Day, Meal, Workout } from 'types/days';
import { mealIcon } from 'helpers/programHelper';
import { isWorkouts } from 'helpers/workoutsHelper';
import { MealType, MealIconSize } from 'types/program';

import Empty from 'assets/images/program/calendar/rectangle.svg';
import EmptyKeto from 'assets/images/program/calendar/keto/rectangleKeto.svg';
import EmptyFasting from 'assets/images/program/calendar/fasting/rectangleFasting.svg';
import EmptyHealthgenom from 'assets/images/program/calendar/healthgenom/rectangleHealthgenom.svg';
import WorkoutSmallIconKeto from 'assets/images/program/calendar/keto/workoutKeto.svg';
import Event from './Event';

import base from './Events.module.scss';
import diet from './Events_diet.module.scss';
import keto from './Events_keto.module.scss';
import ketogo from './Events_ketogo.module.scss';
import fasting from './Events_fasting.module.scss';
import healthgenom from './Events_healthgenom.module.scss';

type Props = {
  day: Day;
};

const EMPTY_ICONS: { [key: string]: any } = {
  diet: Empty,
  monacofit: Empty,
  keto: EmptyKeto,
  ketogo: EmptyKeto,
  fasting: EmptyFasting,
  healthgenom: EmptyHealthgenom,
};

const Events: React.FC<Props> = ({ day }) => {
  const [s, themes] = useTheme({
    base,
    diet,
    keto,
    ketogo,
    fasting,
    healthgenom,
  });
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const isWorkout = isWorkouts(user, day);
  const { diary_entry, day_meals, day_workouts } = day;

  const getItemContent = ({ state, meal_type }: Meal) => {
    if (state !== 'eaten') {
      return EMPTY_ICONS[themes];
    }

    return mealIcon(meal_type, MealIconSize.small);
  };

  // Currently only available for CKD
  const getWorkoutImage = (workout: Workout) => {
    if (workout?.state !== 'completed') {
      return EMPTY_ICONS[themes];
    }

    return WorkoutSmallIconKeto;
  };

  // SDv7, CKD, KG, FASTING, HEALTHGENOM
  const trick = day_meals.find((meal) => meal.meal_type === MealType.trick);
  if (trick) {
    return (
      <div className={`${s.container} ${isWorkout && s.workoutContainer}`}>
        {day_meals.map((item, i) => {
          if (item.meal_type === MealType.trick) {
            return null;
          }

          return (
            <img
              key={item.meal_type}
              src={getItemContent(item) as string}
              className={s.item}
              alt=""
            />
          );
        })}
        {trick && (
          <img alt="" className={s.item} src={getItemContent(trick) as any} />
        )}
        {isWorkout && (
          <img
            alt=""
            className={s.item}
            src={getWorkoutImage(day_workouts[0])}
          />
        )}
      </div>
    );
  }

  const breakfast = day_meals.find(
    (meal) => meal.meal_type === MealType.breakfast
  );

  // MF
  return (
    <div className={s.container}>
      {diary_entry?.has_entry && <Event name="diary" status="completed" />}
      {breakfast && <Event name="meal" status={breakfast.state} />}
      {day_workouts.map(({ state }) => (
        <Event name="workout" status={state} />
      ))}
      {day_meals
        .filter(
          (meal) =>
            meal.meal_type !== MealType.breakfast &&
            meal.meal_type !== MealType.snack
        )
        .map(({ state }) => (
          <Event name="meal" status={state} />
        ))}
    </div>
  );
};

export default Events;
