import React from 'react';

import { SVG } from 'types/svg';

function LoadingKetogo(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={26} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M.41 12c0-1.689.312-3.26.935-4.719a11.765 11.765 0 0 1 2.593-3.814C5.043 2.382 6.365 1.533 7.902.92 9.44.307 11.133 0 12.982 0c1.83 0 3.518.307 5.066.92 1.547.613 2.88 1.462 3.995 2.547a11.664 11.664 0 0 1 2.608 3.814c.623 1.458.934 3.03.934 4.719 0 1.689-.311 3.262-.934 4.719a11.654 11.654 0 0 1-2.608 3.814c-1.116 1.085-2.448 1.935-3.995 2.547-1.548.613-3.237.92-5.066.92-1.849 0-3.542-.307-5.08-.92-1.537-.612-2.86-1.462-3.964-2.547a11.755 11.755 0 0 1-2.593-3.814C.722 15.262.41 13.689.41 12"
          fill="#569A4F"
        />
        <path fill="#FFF" d="M10.205 7.359v9.282l8.197-4.64z" />
      </g>
    </svg>
  );
}

export default LoadingKetogo;
