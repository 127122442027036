import { combineEpics } from 'redux-observable';
import * as sessionEpics from 'epics/sessionEpics';
// eslint-disable-next-line import/no-cycle
import * as chatEpics from 'epics/chatEpics';
import * as userEpics from 'epics/userEpics';
import assign from 'lodash/assign';

const rootEpic = combineEpics(
  ...Object.values(assign({}, sessionEpics, chatEpics, userEpics))
);

export default rootEpic;
