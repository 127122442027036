export type UnsubscribeCommondataData = {
  isLoading: boolean;
  hasError: boolean;
  unsubscribeCommondata: UnsubscribeCommondata;
};

export type UnsubscribeCommonData = {
  chat_answers: number;
  meal_count: number;
};

export type UnsubscribeCommondata = {
  chat_answers: number;
  meal_count: number;
  chat_is_free: boolean;
  meals_eaten: number;
  tricks_shown: number;
  weight_entered: number;
};

export enum UnsubscribeV4ImageName {
  weightloss = 'weightloss',
  doctor = 'doctor',
  salad = 'salad',
}

/** ******************
 *  UNSUBSCRIBE V7   *
 ******************* */

// Todo: this logic should be moved to BE
export const UNSUBSCRIBE_ALLOWED_USER_CATEGORIES = [''];

export const UNSUBSCRIBE_ALLOWED_STEPS_V7 = ['1', '2', '3', '4a', '4b', '4c'];

type StepType = {
  [key: string]: string;
};

// Steps
export enum UnsubscribeStepKey {
  pre_unsubscribe_1 = 'pre_unsubscribe_1',
  pre_unsubscribe_2 = 'pre_unsubscribe_2',
  main_quiz = 'main_quiz',
  benefits_step_a = 'benefits_step_a',
  offer_step = 'offer_step',
  benefits_step_b = 'benefits_step_b',
}

export const UNSUBSCRIBE_STEPS_V7: StepType = {
  '1': UnsubscribeStepKey.pre_unsubscribe_1,
  '2': UnsubscribeStepKey.pre_unsubscribe_2,
  '3': UnsubscribeStepKey.main_quiz,
  '4a': UnsubscribeStepKey.benefits_step_a,
  '4b': UnsubscribeStepKey.offer_step,
  '4c': UnsubscribeStepKey.benefits_step_b,
};

// cancel anyway links fpr each steps
export const QUIZ_STEPS_V7_LINKS: StepType = {
  pre_unsubscribe_1: '/unsubscribe/unsubscribev7-2',
  pre_unsubscribe_2: '/unsubscribe/unsubscribev7-3',
  benefits_step_a: '/unsubscribe/unsubscribev7-success',
  benefits_step_b: '/unsubscribe/unsubscribev7-success',
  offer_step: '/unsubscribe/unsubscribev7-success',
};

// Quiz (step 3)
export const QUIZ_LINKS_V7: StepType = {
  timing_not_right: '/unsubscribe/unsubscribev7-4b',
  did_not_meet_my_needs: '/unsubscribe/unsubscribev7-4c',
  price_to_high: '/unsubscribe/unsubscribev7-4b',
  difficult_to_follow_plan: '/unsubscribe/unsubscribev7-4c',
  found_alternative: '/unsubscribe/unsubscribev7-4b',
  did_not_meet_my_expectations: '/unsubscribe/unsubscribev7-4a',
  no_longer_need_this: '/unsubscribe/unsubscribev7-4a',
  other: '/unsubscribe/unsubscribev7-4b',
};

// quiz questions
export const MAIN_QUIZ_QUESTIONS_V7: StepType = {
  timing_not_right: 'timing_not_right',
  did_not_meet_my_needs: 'did_not_meet_my_needs',
  price_to_high: 'price_to_high',
  difficult_to_follow_plan: 'difficult_to_follow_plan',
  found_alternative: 'found_alternative',
  did_not_meet_my_expectations: 'did_not_meet_my_expectations',
  no_longer_need_this: 'no_longer_need_this',
  other: 'other',
};
