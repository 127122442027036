import React from 'react';

function ProgramMeal(props: any) {
  const { fill, ...rest } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 62 62" {...rest}>
      <defs>
        <style>{'.prefix__cls-2{fill:#fff}'}</style>
      </defs>
      <g id="program-meal-content" data-name="BIG ICON 02">
        <circle cx={31} cy={31} r={31} fill={fill} />
        <path
          className="prefix__cls-2"
          d="M35.19 34.28l-2.81 3.17L40.2 47c1 1.2 2.51 1.46 4-.07s.85-2.74-.19-3.78zM30.57 29.66l-13.94-14c-.56-.57-.88-.32-1.12.12-2.17 4.09 4.09 12.66 9.87 18.45z"
        />
        <path
          className="prefix__cls-2"
          d="M50.64 19.72a.87.87 0 0 0-1.31 0l-6 6a.9.9 0 0 1-1.37 0 .9.9 0 0 1 0-1.37l6-6a.88.88 0 0 0 0-1.31.87.87 0 0 0-1.31 0l-6 6a.91.91 0 0 1-1.38 0 .91.91 0 0 1 0-1.38l6-6a.85.85 0 0 0 0-1.3.88.88 0 0 0-1.3 0l-7.83 6.57c-2.28 1.91-3 4.51-1.2 6.93L18 42.83c-1.4 1.25-1.34 2.73 0 4.11s2.86 1.44 4.11 0l15-16.92c2.42 1.83 5 1.07 6.92-1.2L50.64 21a.88.88 0 0 0 0-1.28z"
        />
      </g>
    </svg>
  );
}

export default ProgramMeal;
