import React, { useEffect, useRef } from 'react';

import { Timer, Cycle } from 'types/fasting';

import { useFastingPercentage } from 'hooks';

import { SvgIcon } from 'components/common';

import s from './HorseShoeEating.module.scss';

type Props = {
  cycle: Cycle;
  timer: Timer;
};

// Do not change this
const TIMER_START_VALUE = 452;
const TIMER_END_VALUE = 202;

function HorseShoeEating({ timer, cycle }: Props) {
  const { percentageProgress } = useFastingPercentage({
    cycle,
    timer,
  });
  const eatingGoalReached = percentageProgress >= 100;

  const circle = useRef(null);

  useEffect(() => {
    const timerValue = TIMER_START_VALUE - percentageProgress * 2.5;
    (circle.current as any).style.strokeDashoffset =
      timerValue <= TIMER_END_VALUE ? 202 : timerValue;
    (circle.current as any).style.transitionDuration = '0.5s';
  });

  return (
    <div className={s.container}>
      <div className={s.svgContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="130px"
          height="130px"
        >
          <defs>
            <linearGradient id="baseBar">
              <stop offset="0%" stopColor="#03cea4" />
              <stop offset="100%" stopColor="#03cea4" />
            </linearGradient>
          </defs>
          <circle
            className={s.restCircle}
            cx="65"
            cy="65"
            r="50"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <div
        className={`${s.percentage} ${eatingGoalReached && s.goalReachedSvg}`}
      >
        {eatingGoalReached ? (
          <SvgIcon filename="EatingCheckmark" />
        ) : (
          `${percentageProgress}%`
        )}
      </div>
      <div className={s.svgContainer}>
        <svg
          id="progressionBar"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="130px"
          height="130px"
        >
          <defs>
            <linearGradient id="progressBar">
              <stop offset="0%" stopColor="#03cea4" />
              <stop offset="100%" stopColor="#03cea4" />
            </linearGradient>
          </defs>
          <circle
            className={s.progressCircle}
            ref={circle}
            cx="65"
            cy="65"
            r="50"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
}

export default HorseShoeEating;
