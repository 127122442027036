import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { convertToMin } from 'helpers/timeHelper';

class TimerButton extends PureComponent {
  static propTypes = {
    currentTime: PropTypes.number.isRequired,
    maxTime: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.string,
  };

  timerLabel = () => {
    const { t, currentTime, maxTime, label } = this.props;

    if (currentTime < 0) {
      switch (currentTime) {
        case -5:
        case -4:
          return t('workout.exercise.starts_in');

        case -3:
          return (
            <div className="form__timer__label__pop" key="first">
              3s
            </div>
          );

        case -2:
          return (
            <div className="form__timer__label__pop" key="second">
              2s
            </div>
          );

        case -1:
          return (
            <div className="form__timer__label__pop" key="third">
              1s
            </div>
          );

        default:
          return null;
      }
    } else if (currentTime < maxTime) {
      if (this.formFill)
        this.formFill.style.width = `${(currentTime / maxTime) * 100}%`;
      return `${convertToMin(currentTime)} / ${convertToMin(maxTime)}`;
    } else {
      if (this.formFill) this.formFill.style.width = `100%`;
      return label;
    }
  };

  getWrapperClass = () => {
    const { type } = this.props;
    let wrapperClass = 'form__timer';
    if (type) wrapperClass += ` ${type}`;
    return wrapperClass;
  };

  render = () => {
    const { currentTime, onClick } = this.props;

    return (
      <div className={this.getWrapperClass()}>
        <div className="form__timer__bg">
          <button
            type="button"
            className="form__timer__label"
            onClick={currentTime >= 0 ? onClick : () => null}
          >
            {this.timerLabel()}
          </button>
          <div
            className="form__timer__fill"
            ref={(el) => {
              this.formFill = el;
            }}
          />
        </div>
      </div>
    );
  };
}

export default withTranslation()(TimerButton);
