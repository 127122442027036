import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useTheme from 'helpers/themeHook';
import { useDays } from './CalendarHook';

import CalendarItem from './CalendarItem';
import Loader from './Loader';

import base from './Calendar.module.scss';
import diet from './Calendar_diet.module.scss';
import keto from './Calendar_keto.module.scss';
import ketogo from './Calendar_ketogo.module.scss';
import fasting from './Calendar_fasting.module.scss';
import healthgenom from './Calendar_healthgenom.module.scss';

const Calendar = () => {
  const [s] = useTheme({ base, diet, keto, ketogo, fasting, healthgenom });
  const listRef = useRef();
  const selectedRef = useRef();
  const [
    days,
    scrollList,
    loadPrevious,
    isLoadingPrevious,
    loadNext,
    isLoadingNext,
  ] = useDays(listRef, selectedRef);
  const reachedFirstDay = useSelector(
    (state) => state.rootReducer.reachedFirstDay
  );
  const reachedLastDay = useSelector(
    (state) => state.rootReducer.reachedLastDay
  );
  const currentDayId = Number(
    useSelector((state) => state.rootReducer.day?.id)
  );
  const [buttonVisibility, setButtonVisibility] = useState({
    left: true,
    right: true,
  });

  const checkButtonVisibilty = () => {
    const newVisibility = {};
    newVisibility.left = !(listRef.current?.scrollLeft <= 91);
    newVisibility.right = !(
      listRef.current?.scrollLeft >=
      listRef.current?.scrollWidth - 460
    );

    setButtonVisibility(newVisibility);
  };

  const leftButton = () => {
    if (!buttonVisibility.left) {
      return null;
    }

    return (
      <button
        className={s.button}
        onClick={() => scrollList('left').then(() => checkButtonVisibilty())}
        type="button"
      >
        <svg
          className={s.icon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 551 1024"
        >
          <path d="M445.44 38.183L-2.53 512l447.97 473.817 85.857-81.173-409.6-433.23v81.172l409.6-433.23L445.44 38.18z" />
        </svg>
      </button>
    );
  };

  const rightButton = () => {
    if (!buttonVisibility.right) {
      return null;
    }

    return (
      <button
        type="button"
        className={s.button}
        onClick={() =>
          scrollList('right').then(() => {
            checkButtonVisibilty();
          })
        }
      >
        <svg
          className={s.icon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 551 1024"
        >
          <path d="M105.56 985.817L553.53 512 105.56 38.183l-85.857 81.173 409.6 433.23v-81.172l-409.6 433.23 85.856 81.174z" />
        </svg>
      </button>
    );
  };

  return (
    <div className={s.wrapper} dir="ltr">
      <div className={s.buttonWrapper}>{leftButton()}</div>
      <div className={s.overlay}>
        <ul className={s.container} ref={listRef}>
          {!reachedFirstDay ? (
            <Loader onVisible={loadPrevious} isLoading={isLoadingPrevious} />
          ) : (
            <div className={s.spacer} />
          )}
          {days.map((day) => (
            <CalendarItem
              id={day.id}
              key={day.id}
              day={day}
              isSelected={currentDayId === day.id}
              selectedRef={selectedRef}
            />
          ))}
          {!reachedLastDay ? (
            <Loader onVisible={loadNext} isLoading={isLoadingNext} />
          ) : (
            <div className={s.spacer} />
          )}
        </ul>
      </div>
      <div className={s.buttonWrapper}>{rightButton()}</div>
    </div>
  );
};

export default Calendar;
