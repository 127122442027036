import React from 'react';

import { SVG } from 'types/svg';

function WeightHealthgenom(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} />
        <path
          d="M11.723.204c2.329 0 4.52.738 6.308 2.125l.28.224h2.604c.868 0 1.532.664 1.532 1.532v18.383c0 .868-.664 1.532-1.532 1.532H2.532A1.505 1.505 0 0 1 1 22.468V4.085c0-.868.664-1.532 1.532-1.532h2.604C6.923 1.073 9.17.255 11.52.204h.204zm-8.68 21.243a.51.51 0 1 0 0 1.021.51.51 0 0 0 0-1.021zm17.361 0a.51.51 0 1 0 0 1.021.51.51 0 0 0 0-1.021zm-8.732-18.69a8.292 8.292 0 0 0-6.74 3.473l3.32 2.604 2.677-.04a1.01 1.01 0 0 1 .314-1.514.441.441 0 0 1-.02-.131V4.596a.5.5 0 1 1 1 0v2.553a.5.5 0 0 1-.018.134 1.006 1.006 0 0 1 .332 1.487l2.557-.038 3.268-2.655c-1.532-1.992-3.932-3.32-6.69-3.32z"
          fill="#2884AA"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default WeightHealthgenom;
