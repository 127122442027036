import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { isFastingBlockAvailable } from 'helpers/fastingHelper';

import { User } from 'types/user';

type Props = {
  children: React.ReactNode;
};

function EnsureFastingAllowed({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);

  if (!isFastingBlockAvailable(user)) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}

export default EnsureFastingAllowed;
