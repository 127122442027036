import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { convertToMin } from 'helpers/timeHelper';
import { withTranslation } from 'react-i18next';
import { getStyle } from 'helpers/themeHelper';

import base from './ProgramItemHeader.module.scss';

const s = getStyle(base);

class ProgramItemHeader extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    data: PropTypes.object,
    freeUser: PropTypes.bool,
    goal: PropTypes.string,
  };

  getTimed = () => {
    const { type, data } = this.props;
    if (type !== 'diary' && data.cooking_time)
      return <span> ({convertToMin(data.cooking_time)})</span>;
    return null;
  };

  getTitle = () => {
    const { t, type, freeUser, goal } = this.props;

    if (type === 'exercise') {
      if (freeUser) return t(`items.type.${type}.header.free`);
      return t(`items.type.${type}.header.${goal}`);
    }
    return t(`items.type.${type}.header`);
  };

  render = () => {
    const { status, data } = this.props;

    return (
      <p className={`program_page__container__item__info__header ${s.header}`}>
        {this.getTitle()}
        {status === 'active' && (
          <span className="program_page__container__item__info__header__time">
            {this.getTimed()}
          </span>
        )}
        {data && (
          <span className="program_page__container__item__info__header__title">
            {data.title}
          </span>
        )}
      </p>
    );
  };
}

export default withTranslation()(ProgramItemHeader);
