import React from 'react';

import s from './ButtonGroup.module.scss';

type Props = {
  fixed: boolean;
};

const ButtonGroup: React.FC<Props> = ({ fixed, children }) => (
  <div className={fixed ? s.fixed : ''}>{children}</div>
);

export default ButtonGroup;
