import React from 'react';

import { SVG } from 'types/svg';

function PercentKeto(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M9.429 11.558c.302-.376.525-.81.654-1.274a5.671 5.671 0 00.21-1.592 7.72 7.72 0 00-.166-1.632 3.76 3.76 0 00-.59-1.38 3.013 3.013 0 00-1.09-.94 3.65 3.65 0 00-1.673-.346c-.56-.014-1.117.1-1.627.334a3.197 3.197 0 00-1.143.917c-.305.4-.53.855-.66 1.34a6.07 6.07 0 00-.21 1.62 6.98 6.98 0 00.177 1.604c.107.474.312.92.603 1.31.291.376.67.674 1.105.867a3.964 3.964 0 001.655.312 4 4 0 001.62-.302c.44-.185.828-.472 1.134-.838zm-2.096-.918a.913.913 0 01-.643.205.93.93 0 01-.643-.203 1.26 1.26 0 01-.346-.54 2.68 2.68 0 01-.13-.721c-.017-.27-.025-.512-.025-.724 0-.23.01-.492.03-.79.016-.27.071-.538.164-.794.081-.225.213-.429.386-.595a.896.896 0 01.657-.221.746.746 0 01.586.232c.161.186.279.406.344.643.08.265.13.538.148.814.02.288.031.535.031.737 0 .202-.01.445-.03.715-.019.243-.07.483-.155.712a1.319 1.319 0 01-.372.53h-.002zm13.365 3.26a3.542 3.542 0 00-.592-1.34 3.033 3.033 0 00-1.091-.91 3.744 3.744 0 00-1.672-.333 3.807 3.807 0 00-1.623.322 3.148 3.148 0 00-1.142.884 3.64 3.64 0 00-.665 1.307 5.842 5.842 0 00-.212 1.6 7.012 7.012 0 00.179 1.614c.108.482.312.937.6 1.338a2.89 2.89 0 001.106.9 3.848 3.848 0 001.656.323 3.923 3.923 0 001.622-.31A3.01 3.01 0 0020 18.417c.3-.388.522-.83.653-1.304.144-.521.215-1.06.21-1.602a7.509 7.509 0 00-.165-1.61zm-2.8 3.647a1.038 1.038 0 01-1.285 0 1.42 1.42 0 01-.35-.582 2.88 2.88 0 01-.136-.765 11.423 11.423 0 01.01-1.522c.018-.257.073-.51.164-.752.08-.212.21-.401.378-.553a.963.963 0 01.66-.204.753.753 0 01.588.225c.157.17.275.374.345.595.078.247.128.503.146.761.02.271.03.514.03.715 0 .2-.01.465-.03.737-.018.258-.07.512-.155.756a1.486 1.486 0 01-.364.589zM15.347 4.23l-8.66 15.54h2.151l8.616-15.54z"
          fill="#264653"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default PercentKeto;
