import * as types from 'actions/actionTypes';
import {
  updateUserData,
  updateUserPhoneNumber,
  updatePassword,
  newPassword,
  acceptMedicalAgreement,
  acceptTOS,
  updateUserCountry,
  updateUserCid,
  updateEmail,
} from 'api/UserAPI';
import ResponseHandler from 'actions/ResponseHandler';
import { isLoading, isNotLoading } from 'actions/loadingActions';
import {
  hasValidationMessage,
  noValidationMessage,
} from 'actions/validationMessageActions';
import {
  addError,
  addSuccess,
  addFlashMessageOfType,
} from 'redux-flash-messages';
import { isUserFree } from 'helpers/userHelper';
import browserHistory from 'helpers/history';
import { api } from 'helpers/api';
import { removeEmptyStrings } from 'helpers/requestHelpers';
// eslint-disable-next-line import/no-cycle
import {
  getUserCurrentDayDataAction,
  getCalendarDataAction,
  clearDay,
} from 'actions/programActions';
import { ThunkActionType } from 'store/types';
import { User, UserStatistics } from 'types/user';
import i18n from '../i18n';

type PayloadAny = any;

export const updateUserBeginAction = () => ({
  type: types.USER_UPDATE.ACTION,
});

export const storeCid = (cid: string) => ({
  type: types.STORE_CLIENT_ID,
  cid,
});

export const updateUserSuccessAction = (user: PayloadAny) => ({
  type: types.USER_UPDATE.SUCCESS,
  user,
});

export const updateUserErrorAction = (error: PayloadAny) => ({
  type: types.USER_UPDATE.ERROR,
  error,
});

export const setUser = (payload: Partial<User>) => ({
  type: types.USER_SET,
  payload,
});

export const createUserAction = (user: PayloadAny) => ({
  type: types.USER_CREATE,
  user,
});

export const storeClientId =
  (cid: string): ThunkActionType =>
  (dispatch, getState) => {
    if (!cid) {
      return;
    }

    const {
      rootReducer: { user },
    } = getState();
    // If user is logged in, sends client ID to back-end
    if (user && user.id) {
      updateUserCid(cid);
    }

    dispatch(storeCid(cid));
  };

// actually update user action instead of register
export const registerUser =
  (userData: PayloadAny): ThunkActionType =>
  (dispatch) => {
    dispatch(updateUserBeginAction());
    dispatch(isLoading());

    return updateUserData({ user: userData })
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) {
          if (resHandler.hasValidationError())
            dispatch(hasValidationMessage(resHandler.getValidationErrors()));
          else throw resHandler.getFlashMsgText();
        } else {
          const user = resHandler.getNormalizedResponse();
          dispatch(noValidationMessage());
          Promise.resolve(dispatch(updateUserSuccessAction(user))).then(() => {
            Promise.all([dispatch(medicalAgreementAccept())]).then(() => {
              if (isUserFree(user)) {
                Promise.all([
                  dispatch(getUserCurrentDayDataAction()),
                  dispatch(getCalendarDataAction()),
                  dispatch(isNotLoading()),
                ]).then(() => {
                  browserHistory.replaceLater(
                    `/offer/${user.goal}_universal_pro?utm_source=app.monacofit.com&utm_medium=enters_app&utm_campaign=glad_to_see_you_get_results_4_times_faster`
                  );
                });
              } else {
                browserHistory.replaceLater('/program');
              }
            });
          });
        }
        dispatch(isNotLoading());
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(updateUserErrorAction(error));
        dispatch(isNotLoading());
      });
  };

export const updateUserDataAction =
  (user: PayloadAny, redirect: string): ThunkActionType =>
  (dispatch) => {
    dispatch(updateUserBeginAction());
    dispatch(isLoading());
    return updateUserData({ user: removeEmptyStrings(user) })
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) {
          if (resHandler.hasValidationError())
            dispatch(hasValidationMessage(resHandler.getValidationErrors()));
          else throw resHandler.getFlashMsgText();
        } else {
          dispatch(noValidationMessage());
          dispatch(updateUserSuccessAction(resHandler.getNormalizedResponse()));
          dispatch(clearDay());
        }
        dispatch(isNotLoading());
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(updateUserErrorAction(error));
        dispatch(isNotLoading());
      });
  };

export const updateUserPhoneNumberAction =
  (user: PayloadAny): ThunkActionType =>
  (dispatch) => {
    dispatch(updateUserBeginAction());
    dispatch(isLoading());

    return updateUserPhoneNumber({ user })
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) {
          if (resHandler.hasValidationError())
            dispatch(hasValidationMessage(resHandler.getValidationErrors()));
          else throw resHandler.getFlashMsgText();
        } else {
          dispatch(noValidationMessage());
          dispatch(updateUserSuccessAction(resHandler.getNormalizedResponse()));
        }
        dispatch(isNotLoading());
        browserHistory.pushLater(`/`);
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(updateUserErrorAction(error));
        dispatch(isNotLoading());
      });
  };

export const updateUserCountryAction =
  (user: PayloadAny): ThunkActionType =>
  (dispatch) => {
    // Izsauc updateUserBeginAction, lai pateiktu, ka tiek sākts process
    dispatch(updateUserBeginAction());
    // izsauc isloading, lai parādītu, ka lapa pagaidām lādējās
    dispatch(isLoading());
    // notiek API pieprasījums
    return updateUserCountry({ user })
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) {
          if (resHandler.hasValidationError())
            // palaiž erroru un ieliek error message state un this.props
            dispatch(hasValidationMessage(resHandler.getValidationErrors()));
          else throw resHandler.getFlashMsgText();
        } else {
          // js viss kārtībā, dzēš no state validācijas kļūdas
          dispatch(noValidationMessage());
          // paziņo, ka viss noticis veiksmīgi
          dispatch(updateUserSuccessAction(resHandler.getNormalizedResponse()));
          // redirektē uz attiecīgo lapu
          browserHistory.pushLater(`/`);
        }
        // pabeidzis visus actionus
        dispatch(isNotLoading());
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(updateUserErrorAction(error));
        dispatch(isNotLoading());
      });
  };

export const updatePasswordBeginAction = () => ({
  type: types.USER_PASSWORD_UPDATE.ACTION,
});

export const updatePasswordSuccessAction = (user: PayloadAny) => ({
  type: types.USER_PASSWORD_UPDATE.SUCCESS,
  user,
});

export const updatePasswordErrorAction = (error: PayloadAny) => ({
  type: types.USER_PASSWORD_UPDATE.ERROR,
  error,
});

export const updatePasswordAction =
  (user: PayloadAny): ThunkActionType =>
  async (dispatch) => {
    dispatch(updatePasswordBeginAction());
    dispatch(isLoading());
    try {
      const response = await updatePassword(user);
      addSuccess({ text: i18n.t('flash_msg.success_password_change') });
      dispatch(updatePasswordSuccessAction(response));
      dispatch(isNotLoading());
      browserHistory.pushLater('/user');
    } catch (err: any) {
      const {
        error: { title = i18n.t('error.500_error') },
      } = err;
      addError({ text: title });

      dispatch(updatePasswordErrorAction(err));
      dispatch(isNotLoading());
    }
  };

export const updateEmailBeginAction = () => ({
  type: types.USER_EMAIL_UPDATE.ACTION,
});

export const updateEmailSuccessAction = (user: PayloadAny) => ({
  type: types.USER_EMAIL_UPDATE.SUCCESS,
  user,
});

export const updateEmailErrorAction = (error: PayloadAny) => ({
  type: types.USER_EMAIL_UPDATE.ERROR,
  error,
});

export const updateEmailAction =
  (email: string): ThunkActionType =>
  async (dispatch) => {
    dispatch(isLoading());
    dispatch(updateEmailBeginAction());
    try {
      const response = await updateEmail(email);
      dispatch(updateEmailSuccessAction(response));
      dispatch(setUser({ email }));
      dispatch(isNotLoading());
      browserHistory.pushLater('/user');
    } catch (err) {
      addError({ text: i18n.t('error.500_error') });
      dispatch(updateEmailErrorAction(err));
      dispatch(isNotLoading());
    }
  };

export const updateNewPasswordBeginAction = () => ({
  type: types.USER_PASSWORD_NEW.ACTION,
});

export const updateNewPasswordSuccessAction = (user: PayloadAny) => ({
  type: types.USER_PASSWORD_NEW.SUCCESS,
  user,
});

export const updateNewPasswordErrorAction = (error: PayloadAny) => ({
  type: types.USER_PASSWORD_NEW.ERROR,
  error,
});

export const newPasswordAction =
  (user: PayloadAny): ThunkActionType =>
  (dispatch) => {
    dispatch(updateNewPasswordBeginAction());
    dispatch(isLoading());
    return newPassword({ user })
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) {
          if (resHandler.hasValidationError())
            dispatch(hasValidationMessage(resHandler.getValidationErrors()));
          else throw resHandler.getFlashMsgText();
        } else {
          Promise.all([
            dispatch(
              updateNewPasswordSuccessAction(resHandler.getNormalizedResponse())
            ),
          ]).then(() => {
            browserHistory.pushLater('/program');
          });
        }
        dispatch(isNotLoading());
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(updateNewPasswordErrorAction(error));
        dispatch(isNotLoading());
      });
  };

export const medicalAgreementAction = () => ({
  type: types.USER_MEDICAL_AGREEMENT.ACTION,
});

export const medicalAgreementSuccess = () => ({
  type: types.USER_MEDICAL_AGREEMENT.SUCCESS,
});

export const medicalAgreementError = (error: PayloadAny) => ({
  type: types.USER_MEDICAL_AGREEMENT.ERROR,
  error,
});

export const medicalAgreementAccept = (): ThunkActionType => (dispatch) => {
  dispatch(medicalAgreementAction());
  dispatch(isLoading());

  return acceptMedicalAgreement()
    .then(() => {
      dispatch(medicalAgreementSuccess());
      dispatch(setUser({ medical_agreement_accepted: true }));
      dispatch(isNotLoading());
    })
    .catch((error) => {
      addError({ text: error });
      dispatch(medicalAgreementError(error));
      dispatch(isNotLoading());
    });
};

export const acceptTOSBeginAction = () => ({
  type: types.TOS_ACCEPT.ACTION,
});

export const acceptTOSSuccessAction = (user: PayloadAny) => ({
  type: types.TOS_ACCEPT.SUCCESS,
  user,
});

export const acceptTOSErrorAction = (error: PayloadAny) => ({
  type: types.TOS_ACCEPT.ERROR,
  error,
});

export const acceptTOSAction =
  (subscriptionId: number, choice: string): ThunkActionType =>
  (dispatch) => {
    dispatch(acceptTOSBeginAction());
    dispatch(isLoading());
    return acceptTOS(subscriptionId, choice)
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) throw resHandler.getFlashMsgText();
        else {
          Promise.all([
            dispatch(
              acceptTOSSuccessAction(resHandler.getNormalizedResponse())
            ),
          ]).then(() => {
            if (choice === 'decline') {
              addFlashMessageOfType('TOS_DECLINE', 1000, '');
            }
            browserHistory.pushLater('/');
          });
        }
        dispatch(isNotLoading());
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(acceptTOSErrorAction(error));
        dispatch(isNotLoading());
      });
  };

export const getUserStatisticsAction = async () => {
  try {
    return await api<UserStatistics>('api/v1/users/weight-statistics', {
      method: 'GET',
    });
  } catch (e) {
    const text = i18n.t('error.500_error');
    addError({ text });
    return false;
  }
};
