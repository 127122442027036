import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { User } from 'types/user';

const EnsureUserHasSetPasswordWrapper: React.FC = ({ children }) => {
  const { entered_password } = useSelector<any, User>(
    (store) => store.rootReducer.user
  );

  if (entered_password) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
};

export default EnsureUserHasSetPasswordWrapper;
