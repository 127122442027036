import React from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { addModal } from 'actions/modalActions';
import {
  getFastedForTimeValue,
  getFastedRemainingTime,
  isFastingGoalReached,
} from 'helpers/fastingHelper';
import { ModalType } from 'types/modals';
import { Cycle, FastingTimerFormat, Timer } from 'types/fasting';

import { useFastingTimer } from 'hooks';

import { HorseShoe } from 'components/ProgressBar';
import { SubmitButton, SvgIcon } from 'components/common';

import s from './FastingTimerBody.module.scss';

type Props = {
  cycle: Cycle;
  timer: Timer;
};

function FastingTimerBody({ cycle, timer }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fastingGoalReached = isFastingGoalReached(
    cycle.fasting,
    timer.time_in_the_cycle
  );
  const { activeSecondsInFasting } = useFastingTimer({ timer });
  const humanReadibleTimer = getFastedForTimeValue(0, activeSecondsInFasting);

  const getTitle = () => {
    if (fastingGoalReached) {
      return t('items.type.fasting.done_timer.title');
    }

    return t('items.type.fasting.in_fasting_timer.title');
  };

  const getText = () => {
    if (fastingGoalReached) {
      return (
        <Trans i18nKey="items.type.fasting.done_timer.text">
          <SvgIcon filename="FastingCup" />
          <span className={s.congratz}>Congratulations!</span>
          You hit your {{ goal_time: `${cycle.fasting}h` }} goal!
        </Trans>
      );
    }

    return (
      <Trans i18nKey="items.type.fasting.in_fasting_timer.text">
        Remaining{' '}
        <span className={s.semiBold}>
          {{
            remaining_time: getFastedRemainingTime(
              cycle.fasting,
              activeSecondsInFasting,
              FastingTimerFormat.medium
            ),
          }}
        </span>
      </Trans>
    );
  };

  const getButtonText = () => {
    if (fastingGoalReached) {
      return t('items.type.fasting.done_timer.button');
    }

    return t('items.type.fasting.in_fasting_timer.button');
  };

  const handleButton = (e: any) => {
    e.preventDefault();

    if (fastingGoalReached) {
      dispatch(
        addModal({
          modal: ModalType.finishFasting,
          progressTime: getFastedForTimeValue(
            0,
            activeSecondsInFasting,
            FastingTimerFormat.medium
          ),
          timer,
        })
      );
      return;
    }

    dispatch(
      addModal({
        modal: ModalType.stopFasting,
        progressTime: getFastedForTimeValue(
          0,
          activeSecondsInFasting,
          FastingTimerFormat.medium
        ),
        remainingTime: getFastedRemainingTime(
          cycle.fasting,
          activeSecondsInFasting,
          FastingTimerFormat.medium
        ),
      })
    );
  };

  return (
    <div
      className={`${s.cardContainer} ${fastingGoalReached && s.doneFasting}`}
    >
      <div className={s.cardWrapper}>
        <div className={s.leftSide}>
          <span className={s.cardTitle}>{getTitle()}</span>
          <p className={s.cardTimer}>{humanReadibleTimer}</p>
          <p className={s.cardText}>{getText()}</p>
        </div>
        <div className={s.rightSide}>
          <HorseShoe cycle={cycle} timer={timer} />
        </div>
      </div>
      <div className={s.button}>
        <SubmitButton
          label={getButtonText()}
          type="large"
          onClick={(e) => handleButton(e)}
        />
      </div>
    </div>
  );
}

export default FastingTimerBody;
