import {
  apiHost,
  requestHeaders,
  requestHeadersWithToken,
} from 'helpers/requestHelpers';
import { ajax } from 'rxjs/ajax';
import { pluck } from 'rxjs/operators';

export const logIn = (payload) =>
  ajax
    .post(`${apiHost()}/api/v1/login`, payload, requestHeaders())
    .pipe(pluck('response'));

export const fbLogIn = (payload) =>
  ajax
    .post(`${apiHost()}/api/v1/facebook_login`, payload, requestHeaders())
    .pipe(pluck('response'));

export const fbMerge = (payload) =>
  ajax
    .post(
      `${apiHost()}/api/v1/facebook_merge_and_login`,
      payload,
      requestHeaders()
    )
    .pipe(pluck('response'));

export const logOut = () =>
  ajax
    .post(`${apiHost()}/api/v1/logout`, null, requestHeadersWithToken())
    .pipe(pluck('response'));

export const renewPassword = (payload) =>
  ajax
    .post(
      `${apiHost()}/api/v1/users/request_password_reset`,
      payload,
      requestHeaders()
    )
    .pipe(pluck('response'));

export const validateResetToken = (payload) =>
  ajax
    .post(
      `${apiHost()}/api/v1/users/validate_reset_password`,
      payload,
      requestHeaders()
    )
    .pipe(pluck('response'));

export const setNewPassword = (payload) =>
  ajax
    .post(`${apiHost()}/api/v1/users/reset_password`, payload, requestHeaders())
    .pipe(pluck('response'));

export const directLogIn = (payload) =>
  ajax
    .post(`${apiHost()}/api/v1/direct_login`, payload, requestHeaders())
    .pipe(pluck('response'));

export const getCableEndpoint = () =>
  ajax.getJSON(`${apiHost()}/api/v1/cable_endpoint`, requestHeadersWithToken());
