import i18n from 'i18n';
import { addError } from 'redux-flash-messages';

import { bugsnagClient } from 'helpers/bugsnagHelper';
import { Offer, OfferActivityPayload } from 'types/offer';
import { api } from 'helpers/api';
import {
  apiHost,
  requestHeadersWithToken,
  fetchData,
} from 'helpers/requestHelpers';

import ResponseHandler from 'actions/ResponseHandler';

export const getOfferById = (offerId: number) =>
  api(`api/v1/offers/${offerId}`, {
    method: 'GET',
  });

export const getOfferByType = (
  locale: string,
  category: number,
  offerType: string,
  mealType = ''
) => {
  const request = new Request(
    `${apiHost()}/api/v1/offers/${locale}/${category}/${offerType}?meal_type=${mealType}`,
    {
      method: 'GET',
      headers: requestHeadersWithToken(),
    }
  );
  return fetchData(request);
};

export const getOfferByIdLegacy = async (
  offerId: number
): Promise<Offer | null> => {
  try {
    const response = await api(`api/v1/offers/${offerId}`, {
      method: 'GET',
    });
    const resHandler = new ResponseHandler(response);

    return resHandler.getNormalizedResponse();
  } catch (e) {
    const text = i18n.t('error.500_error');
    addError({ text });
    return null;
  }
};

// TODO: Move it to UserAPI when it's written in TS
export const getUserOfferAds = async () => {
  try {
    const response = await api('api/v1/users/user-offer-ads', {
      method: 'GET',
    });
    const resHandler = new ResponseHandler(response);

    return resHandler.getNormalizedResponse();
  } catch {
    return {};
  }
};

export const logOfferActivity = async (
  offerId: number,
  payload: OfferActivityPayload
) => {
  try {
    await api(`api/v1/offers/${offerId}/activities`, {
      method: 'POST',
      body: payload,
    });

    return true;
  } catch (error: any) {
    // Just notify Bugsnag about the error without interuption for the user
    bugsnagClient.notify(
      new Error(
        `logOfferAction error:\n 1.offerId - ${offerId}\n 2.action - ${payload.action}\n 3.error status - ${error?.error?.status}`
      )
    );

    return false;
  }
};
