import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paymodes } from 'types/offer';
import useTheme from 'helpers/themeHook';

import { SvgIcon } from 'components/common';

import base from './PaymodesFooter.module.scss';
import diet from './PaymodesFooter_diet.module.scss';
import keto from './PaymodesFooter_keto.module.scss';
import ketogo from './PaymodesFooter_ketogo.module.scss';
import healthgenom from './PaymodesFooter_healthgenom.module.scss';

type Props = {
  paymodes: [Paymodes?];
  primerFlag?: boolean; // TODO: Remove this flag after full integration
};

type ValidPaymodes = {
  [key in Paymodes]?: string;
};

const PAYMODES: ValidPaymodes = {
  first_data: 'PaymentCard',
  stripe: 'PaymentStripe',
  paydoo: 'PaymentPaydoo',
  paypal_recurring: 'PaymentPaypal',
  paypal_express: 'PaymentPaypal',
  paypal_classic: 'PaymentPaypal',
  primer: 'PaymentPrimer',
};

const PaymodesFooter: React.FC<Props> = ({ paymodes, primerFlag }) => {
  const { t } = useTranslation();
  const [s] = useTheme({ base, diet, keto, ketogo, healthgenom });
  const logos = paymodes.filter((p) =>
    Object.keys(PAYMODES).includes(p as Paymodes)
  );

  // TODO: Remove this flag after full integration
  const renderLogos = () => {
    if (primerFlag) {
      return (
        <>
          <SvgIcon
            key="primer"
            filename={PAYMODES[Paymodes.primer] as string}
          />
          {logos.includes(Paymodes.paypal_recurring) && (
            <SvgIcon
              key="paypal_recurring"
              filename={PAYMODES[Paymodes.paypal_recurring] as string}
            />
          )}
        </>
      );
    }

    return (
      <>
        {logos.map((logo) => (
          <SvgIcon key={logo} filename={PAYMODES[logo as Paymodes] as string} />
        ))}
      </>
    );
  };

  return (
    <footer className={s.container}>
      <h4 className={s.title}>{t('modals.paymode.footer')}</h4>
      <p className={s.images}>{renderLogos()}</p>
    </footer>
  );
};

export default PaymodesFooter;
