import React from 'react';

import { SVG } from 'types/svg';

function PasswordDiet(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          id="prefix__5dee2012-6dbf-4056-bf95-0af9ba34b909"
          d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__d92756fa-31b3-4757-9bc5-edf4aeaa3ce0" fill="#fff">
          <use xlinkHref="#prefix__5dee2012-6dbf-4056-bf95-0af9ba34b909" />
        </mask>
        <g
          fill="#000"
          fillOpacity={0.54}
          mask="url(#prefix__d92756fa-31b3-4757-9bc5-edf4aeaa3ce0)"
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
}

export default PasswordDiet;
