import React from 'react';

import { SVG } from 'types/svg';

function Globe(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M22.28 18.19a12 12 0 0 0 0-12.38.79.79 0 0 0-.18-.29 12 12 0 0 0-8-5.33A12.47 12.47 0 0 0 12 0a11.27 11.27 0 0 0-2.12.19 12 12 0 0 0 0 23.62A11.48 11.48 0 0 0 12 24a11.3 11.3 0 0 0 2.12-.19 11.93 11.93 0 0 0 8-5.33.7.7 0 0 0 .16-.29zM1.53 12.75h4a21.16 21.16 0 0 0 .6 4.45H2.87a10.45 10.45 0 0 1-1.34-4.45zm1.34-5.94h3.22a21.16 21.16 0 0 0-.6 4.44h-4a10.37 10.37 0 0 1 1.38-4.44zm19.6 4.44h-4a21.07 21.07 0 0 0-.6-4.44h3.22a10.37 10.37 0 0 1 1.38 4.44zm-5.46 0h-4.26V6.81h3.6a19.06 19.06 0 0 1 .65 4.44zm-4.26-5.94V1.62c1.23.43 2.3 1.79 3.06 3.68zm-1.52-3.68v3.68H8.15c.77-1.89 1.85-3.25 3.08-3.68zm0 5.18v4.44H7a19.59 19.59 0 0 1 .67-4.44zM7 12.75h4.27v4.45H7.65A19.77 19.77 0 0 1 7 12.75zm4.27 5.94v3.69c-1.23-.43-2.3-1.79-3.06-3.68zm1.52 3.68v-3.68h3.09c-.8 1.89-1.88 3.25-3.11 3.68zm0-5.17v-4.45H17a19.23 19.23 0 0 1-.66 4.45zm5.76-4.45h4a10.45 10.45 0 0 1-1.34 4.45h-3.3a21.16 21.16 0 0 0 .6-4.45zm1.6-7.44h-2.7a11.3 11.3 0 0 0-1.61-3.08 10.51 10.51 0 0 1 4.27 3.08zM8.16 2.23a11.3 11.3 0 0 0-1.61 3.08H3.89a10.51 10.51 0 0 1 4.27-3.08zM3.9 18.69h2.65a11.3 11.3 0 0 0 1.61 3.08 10.24 10.24 0 0 1-4.26-3.08zm11.94 3.08a11.3 11.3 0 0 0 1.61-3.08h2.65a10.24 10.24 0 0 1-4.26 3.08z" />
    </svg>
  );
}

export default Globe;
