import * as types from 'actions/actionTypes';

type tempActionAny = any;

export default (
  state = { locale: 'en', isLoading: false },
  action: tempActionAny
) => {
  const { payload } = action;

  switch (action.type) {
    case types.SET_LOCALE.ACTION:
      return { ...state, isLoading: true };
    case types.SET_LOCALE.SUCCESS:
      return { isLoading: false, locale: payload };
    default:
      return state;
  }
};
