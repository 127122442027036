import { api } from 'helpers/api';
import { UnsubscribeReasons } from 'types/globalData';

import ResponseHandler from 'actions/ResponseHandler';

// eslint-disable-next-line import/prefer-default-export
export const getUnsubscribeReasons =
  async (): Promise<UnsubscribeReasons | null> => {
    try {
      const response = await api('api/v1/common-data', {
        method: 'GET',
      });
      const resHandler = new ResponseHandler(response);
      const normalizedResponse = resHandler.getNormalizedResponse();

      if (!normalizedResponse.terminate_reasons.user) throw Error();

      return normalizedResponse.terminate_reasons.user;
    } catch {
      return null;
    }
  };
