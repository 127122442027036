import { useState, useEffect } from 'react';
import { hoursToSeconds } from 'date-fns';

import { isEatingPhase } from 'helpers/fastingHelper';
import { Cycle, Timer } from 'types/fasting';

type Props = {
  cycle: Cycle;
  timer: Timer;
};

function useFastingPercentage({ cycle, timer }: Props) {
  const getStartingPercentageValue = () => {
    if (!timer) {
      return 0;
    }

    const alreadyFastingInSeconds = timer.time_in_the_cycle;

    return Math.round((alreadyFastingInSeconds * 100) / fastingCycleInSeconds);
  };

  const fastingCycleInSeconds = hoursToSeconds(
    isEatingPhase(timer) ? cycle.eating : cycle.fasting
  );
  const [percentageProgress, setPercentageProgress] = useState(
    getStartingPercentageValue()
  );
  const percentEqualTo = fastingCycleInSeconds / 100;
  // eslint-disable-next-line
  const [progress, setProgress] = useState(percentEqualTo);

  useEffect(() => {
    let progressCount: any;
    if (timer) {
      progressCount = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress + 1 === percentEqualTo) {
            setPercentageProgress(
              (prevPercentageProgress) => prevPercentageProgress + 1
            );
          }

          return prevProgress >= percentEqualTo ? 0 : prevProgress + 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(progressCount);
    };
  }, [percentEqualTo, timer]);

  return { percentageProgress };
}

export default useFastingPercentage;
