import React from 'react';

const RetinaImage = ({ images, alt = '', ...restProps }) => (
  <img
    src={images[0]}
    srcSet={`${images[0]} 1x, ${images[1]} 2x, ${images[2]} 3x`}
    alt={alt}
    {...restProps}
  />
);

export default RetinaImage;
