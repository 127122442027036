import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalDataStatus } from 'types/globalData';

import { LoadingAnimation } from 'components/common';

import { retrieveUnsubscribeReasons } from 'actions/globalDataActions';

type Props = {
  children: React.ReactNode;
};

function EnsureUnsubscribeReasonsLoaded({ children }: Props) {
  const dispatch = useDispatch();
  const globalDataStatus = useSelector<any, GlobalDataStatus>(
    (store) => store.rootReducer.globalData
  );

  useEffect(() => {
    if (!globalDataStatus.globalData.unsubscribeReasons)
      dispatch(retrieveUnsubscribeReasons());
  }, [globalDataStatus.globalData.unsubscribeReasons, dispatch]);

  if (
    globalDataStatus.isLoading ||
    !globalDataStatus.globalData.unsubscribeReasons
  ) {
    return <LoadingAnimation />;
  }
  if (globalDataStatus.hasError) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
}

export default EnsureUnsubscribeReasonsLoaded;
