/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { getLocaleDirection } from 'i18n';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Timer from 'easytimer.js';

import { User } from 'types/user';
import { ExpandedExercise, InternalWorkoutState } from 'types/workouts';
import { convertSecondsToHoursMinutesSecond } from 'helpers/timerHelper';
import {
  getCurrentExercise,
  getCurrentWorkoutPhaseData,
  getNextExercise,
  getPeriodicity,
} from 'helpers/workoutsHelper';

import { skipExercise } from 'actions/workoutsActions';
import useInfiniteTimer from 'hooks/useInfiniteTimer';

import { Button } from '@material-ui/core';

import s from './WorkoutRest.module.scss';

const ADDITION_SECONDS = 15;

type Props = {
  globalTimer: Timer;
};

function WorkoutRest({ globalTimer }: Props) {
  const { t } = useTranslation('workouts');
  const dispatch = useDispatch();
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const workout = useSelector<any, any>((store) => store.rootReducer.workout);
  const workouts = useSelector<any, InternalWorkoutState>(
    (store) => store.rootReducer.workouts
  );
  const currentExercise = getCurrentExercise(workouts) as ExpandedExercise;
  const nextExercise = getNextExercise(workouts, workout);
  const currentWorkoutPhaseData = getCurrentWorkoutPhaseData(workouts);
  const { seconds, addSeconds } = useInfiniteTimer({
    timer: currentExercise.duration as number,
    stopAtEnd: true,
  });
  const periodicity = getPeriodicity(
    nextExercise,
    t('workouts.values.repetitions')
  );
  const videoUrl = nextExercise.video_url;
  const isBannerVisible = user.offer_banner_type !== null;

  useEffect(() => {
    // Neccessary do to the fact that the video tag does not update the source
    function changeSource(url: string) {
      const videoTag = document.getElementById('video') as HTMLVideoElement;
      videoTag.src = url;
      videoTag.play();
    }
    changeSource(videoUrl);
  }, [videoUrl]);

  useEffect(() => {
    if (seconds === 0) {
      onSkip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  const onAddSeconds = () => {
    addSeconds(ADDITION_SECONDS);
  };

  const onSkip = () => {
    dispatch(
      skipExercise(
        workouts,
        workout,
        globalTimer.getTotalTimeValues().seconds,
        true
      )
    );
  };

  return (
    <div className={s.container} dir={getLocaleDirection()}>
      <div className={`${s.topContent} ${isBannerVisible && s.bannerVisible}`}>
        <p className={s.title}>{t('workouts.rest.title')}</p>
        <p className={s.countdown}>
          {convertSecondsToHoursMinutesSecond(seconds)}
        </p>
        <div className={s.buttons}>
          <Button
            type="button"
            color="secondary"
            variant="outlined"
            className={s.addSecondButton}
            onClick={onAddSeconds}
          >
            {t('workouts.rest.button.add_seconds', {
              seconds: ADDITION_SECONDS,
            })}
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={s.skipButton}
            onClick={onSkip}
          >
            {t('workouts.rest.button.skip_rest')}
          </Button>
        </div>
      </div>
      <div className={s.bottomContent}>
        <div className={s.progressBlock}>
          <p className={s.progressText}>
            {t('workouts.rest.progress', {
              next: (currentWorkoutPhaseData?.currentCount as number) + 1,
              total: currentWorkoutPhaseData?.totalCount,
            })}
          </p>
        </div>
        <div className={s.infoBlock}>
          <div className={s.titleBlock}>
            <p className={s.exercisetitle}>{nextExercise.title}</p>
          </div>
          <div className={s.secondBlock}>
            <p className={s.duration}>{periodicity}</p>
          </div>
        </div>
        <video
          id="video"
          className={s.restVideo}
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={nextExercise.video_url} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default WorkoutRest;
