import React from 'react';

import { SVG } from 'types/svg';

function Breastfeed(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M21.85 9.46a10.18 10.18 0 0 0-9.59-7.62H12a10.2 10.2 0 0 0-9.86 7.62 2.58 2.58 0 0 0 0 5.08 10.18 10.18 0 0 0 19.71 0 2.58 2.58 0 0 0 0-5.08zm-.5 3.61a.75.75 0 0 0-.84.6 8.68 8.68 0 0 1-17 0 .82.82 0 0 0-.8-.61h-.14A1.07 1.07 0 0 1 1.5 12a1.09 1.09 0 0 1 1.14-1.08.74.74 0 0 0 .84-.6 8.69 8.69 0 0 1 6.88-6.83 5.11 5.11 0 0 0-.41.73 3.29 3.29 0 0 0-.06 3.07.75.75 0 0 0 .62.32.72.72 0 0 0 .42-.13.75.75 0 0 0 .2-1 2.1 2.1 0 0 1 .19-1.61c.06-.12.12-.21.18-.32a4.5 4.5 0 0 0 .33.75 3.27 3.27 0 0 0 2.41 1.9.75.75 0 0 0 0-1.5c-.2 0-.74-.35-1.17-1.12a2.8 2.8 0 0 1-.37-1.12h.06a8.67 8.67 0 0 1 7.68 7 .83.83 0 0 0 .91.6 1.08 1.08 0 0 1 1.15.94 1.09 1.09 0 0 1-1.15 1.07z" />
      <path d="M10.37 10.69a.75.75 0 0 0-.24-1 .73.73 0 0 0-1 .23 1.19 1.19 0 0 1-.64.15 1.6 1.6 0 0 1-.63-.12.74.74 0 0 0-1-.25.75.75 0 0 0-.25 1 2.2 2.2 0 0 0 1.91.84 2.2 2.2 0 0 0 1.85-.85zM17.2 9.64a.74.74 0 0 0-1 .24 1.12 1.12 0 0 1-.65.15 1.58 1.58 0 0 1-.62-.12.75.75 0 0 0-1-.25.74.74 0 0 0-.25 1 2.15 2.15 0 0 0 1.9.85 2.2 2.2 0 0 0 1.91-.84.78.78 0 0 0-.29-1.03zM14.22 15.5a.76.76 0 0 0-1 .15 3.43 3.43 0 0 1-2.52 0 .75.75 0 0 0-1.21.88c.51.83 2.45.86 2.47.86s2 0 2.47-.86a.76.76 0 0 0-.21-1.03z" />
    </svg>
  );
}

export default Breastfeed;
