import i18n from 'i18n';
import { addError } from 'redux-flash-messages';

import { api } from 'helpers/api';
import ResponseHandler from 'actions/ResponseHandler';
import { AccessItems } from 'types/access';

// eslint-disable-next-line import/prefer-default-export
export const getUserAccessAPI = async (): Promise<AccessItems | null> => {
  try {
    const response = await api('api/v1/users/profile/access', {
      method: 'GET',
    });
    const resHandler = new ResponseHandler(response);

    return resHandler.getNormalizedResponse();
  } catch (e) {
    const text = i18n.t('error.500_error');
    addError({ text });
    return null;
  }
};
