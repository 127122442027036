import * as t from 'actions/actionTypes';
import { UnsubscribeCommondataActionTypes } from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (
  state = initialState.fasting,
  action: UnsubscribeCommondataActionTypes
) => {
  switch (action.type) {
    case t.UNSUBSCRIBE_COMMONDATA_LOAD:
      return { ...state, isLoading: true };
    case t.UNSUBSCRIBE_COMMONDATA_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        unsubscribeCommondata: action.unsubscribeCommondata,
      };
    case t.UNSUBSCRIBE_COMMONDATA_ERROR:
      return { ...state, isLoading: false, hasError: true };
    case t.LOG_OUT.SUCCESS:
      return initialState.unsubscribeCommondata;
    default:
      return state;
  }
};
