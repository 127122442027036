import React from 'react';

import { SVG } from 'types/svg';

function ProblemZones(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M18.73 12.24c0 .32.06.66.1 1s.08.71.13 1.09h1.49c0-.38.08-.75.12-1.09s.07-.69.1-1l.06-1V3.81h-2.1v7.46zM21 18.12a1.5 1.5 0 0 0-.8-.79 1.39 1.39 0 0 0-.57-.12 1.45 1.45 0 0 0-.59.12 1.4 1.4 0 0 0-.47.32 1.27 1.27 0 0 0-.32.47 1.43 1.43 0 0 0-.11.58 1.47 1.47 0 0 0 .11.59 1.36 1.36 0 0 0 .32.47 1.4 1.4 0 0 0 .47.32 1.63 1.63 0 0 0 .59.11 1.47 1.47 0 0 0 1-.43 1.7 1.7 0 0 0 .32-.47 1.48 1.48 0 0 0 .12-.59 1.43 1.43 0 0 0-.07-.58zM11.33 6.48a3.69 3.69 0 1 0-4.77 0 4.13 4.13 0 0 0-.49.32h-.06a4.83 4.83 0 0 0-1.9 3.82v5.76a.77.77 0 0 0 .78.77h.91v6a.78.78 0 0 0 .78.78h4.78a.78.78 0 0 0 .77-.78v-6H13a.77.77 0 0 0 .78-.77v-5.7a4.83 4.83 0 0 0-2.45-4.2zm.92 4.2V12l-4.4-4.4a3.27 3.27 0 0 1 4.4 3.1zm-5.44-7A2.14 2.14 0 1 1 9 5.83a2.14 2.14 0 0 1-2.19-2.14zM5.65 13l2.7 2.7h-2.7zm4.91 9.49H7.33v-5.28h3.23zm.78-6.79h-.79l-4.9-4.9v-.08a3.25 3.25 0 0 1 .88-2.23l5.72 5.72v1.49z" />
    </svg>
  );
}

export default ProblemZones;
