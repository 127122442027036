import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createDiaryEntryAction } from 'actions/programActions';
import { withTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import settings from 'helpers/settings';
import { getStyle } from 'helpers/themeHelper';

import { SubmitButton, Button, TextInput } from 'components/common';
import DiaryUnskippable from './DiaryUnskippable';

import base from './DiaryModal.module.scss';
import diet from './DiaryModal_diet.module.scss';

const s = getStyle(base, diet);

const STEP_WEIGHT = 1;
const STEP_ENERGY = 2;

class DiaryModal extends Component {
  static propTypes = {
    status: PropTypes.bool,
    closeDiaryModal: PropTypes.func,
    createDiaryEntryAction: PropTypes.func.isRequired,
    day: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    validationMessage: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalStep: STEP_WEIGHT,
      saveBtnPressed: false,
      user: {
        weight: null,
        energy_percent: '55',
        unit_system: props.user.unit_system,
      },
    };
  }

  componentDidUpdate(_, prevState) {
    const { modalStep, saveBtnPressed } = this.state;
    const { modalStep: prevModalStep, saveBtnPressed: prevSaveBtnPressed } =
      prevState;

    if (prevModalStep !== modalStep || prevSaveBtnPressed !== saveBtnPressed) {
      if (saveBtnPressed && modalStep === STEP_ENERGY) {
        this.setWeightStep();
      }
    }
  }

  setWeightStep() {
    this.setState({
      saveBtnPressed: false,
      modalStep: STEP_WEIGHT,
    });
  }

  onChange = (event) => {
    const field = event.target.name;
    const { user } = this.state;
    const { value } = event.target;
    if (isEmpty(value)) {
      user[field] = null;
    } else {
      user[field] = value;
    }
    return this.setState({ user });
  };

  onRangeChange = (event) => {
    this.onChange(event);
    let newPosition;
    const slider = event.target;
    const bubble = document.getElementById('energy_slider');
    const width = 160;
    const newPoint = (slider.value - slider.min) / (slider.max - slider.min);

    if (newPoint < 0) newPosition = 0;
    else if (newPoint > 1) newPosition = width;
    else newPosition = width * newPoint;

    bubble.style.bottom = `${newPosition + 55}px`;
  };

  onSave = (event, skip) => {
    const { createDiaryEntryAction, day, closeDiaryModal } = this.props;
    const { modalStep, user } = this.state;

    event.preventDefault();

    if (modalStep === STEP_WEIGHT) {
      const parsedWeight = parseInt(user.weight, 10);
      if (Number.isNaN(parsedWeight) && !skip) {
        return;
      }
      this.setState({
        modalStep: STEP_ENERGY,
        user: { ...user, weight: parsedWeight || null },
      });
    } else {
      this.setState({ saveBtnPressed: true });
      closeDiaryModal();
      createDiaryEntryAction({ diary_entry: user }, day.id);
    }
  };

  modalFirstStep = () => {
    const { t, validationMessage, user } = this.props;
    const {
      user: { weight },
    } = this.state;

    return (
      <div className={`modal__body ${s.container}`}>
        <div className="modal__body__head">
          <h4 className={`modal__body__head__title ${s.title}`}>
            {t('modals.diary.step_1.title')}
          </h4>
        </div>
        <form onSubmit={this.onSave}>
          <div className="modal__body__content">
            <TextInput
              name="weight"
              placeholder={t('modals.diary.step_1.weight')}
              type="text"
              svgIcon="Weight"
              onChange={this.onChange}
              value={weight}
              label={t('modals.diary.step_1.weight')}
              error={validationMessage['diary_entry.weight']}
              valueUnit={t(`units.weight_${user.unit_system}`)}
              pattern="[0-9]*"
              maxLength={3}
            />
          </div>
          <div className="modal__body__footer">
            <SubmitButton label={t('modals.diary.submit')} type="large" />
            <Button
              label="skip"
              type="text"
              buttonStyle="blue"
              onClick={(e) => this.onSave(e, true)}
            />
          </div>
        </form>
      </div>
    );
  };

  modalSecondStep = () => {
    const { t } = this.props;
    const {
      user: { energy_percent },
    } = this.state;

    return (
      <div className={`modal__body ${s.container}`}>
        <div className="modal__body__head">
          <h4 className={`modal__body__head__title ${s.title}`}>
            {t('modals.diary.step_2.title')}
          </h4>
        </div>
        <div className="modal__body__content">
          <div className="modal__diary__desc">
            <span className={s.value}>100%</span>
            <span className={s.description}>
              {t('modals.diary.step_2.max')}
            </span>
          </div>
          <input
            type="range"
            min={10}
            name="energy_percent"
            max={100}
            defaultValue={energy_percent}
            className={`form__slider ${s.input}`}
            onChange={this.onRangeChange}
          />
          <span className={s.slider} id="energy_slider">
            <div className={s.arrow} />
            {energy_percent}%
          </span>
          <div className="modal__diary__desc">
            <span className={s.value}>10%</span>
            <span className={s.description}>
              {t('modals.diary.step_2.min')}
            </span>
          </div>
        </div>
        <div className="modal__body__footer">
          <SubmitButton
            label={t('globals.next')}
            type="large"
            onClick={this.onSave}
          />
        </div>
      </div>
    );
  };

  render = () => {
    const { status, user, day } = this.props;
    const { modalStep } = this.state;

    if (settings.HAS_DIARY_UNSKIPABLE) {
      return <DiaryUnskippable user={user} day={day} />;
    }

    if (modalStep === STEP_WEIGHT) {
      return (
        <div className={`modal open-${status}`}>{this.modalFirstStep()}</div>
      );
    }

    return (
      <div className={`modal open-${status}`}>{this.modalSecondStep()}</div>
    );
  };
}

const mapStateToProps = (state) => ({
  day: state.rootReducer.day,
  user: state.rootReducer.user,
  validationMessage: state.rootReducer.validationMessage,
});

const mapDispatchToProps = {
  createDiaryEntryAction,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DiaryModal)
);
