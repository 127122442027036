import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (state = initialState.mealTags, action) => {
  switch (action.type) {
    case types.MEAL_SURVEY_TAG_LIST_LOAD.SUCCESS:
      return action.mealTags;

    case types.LOG_OUT.SUCCESS:
      return initialState.mealTags;

    default:
      return state;
  }
};
