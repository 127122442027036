import ResponseHandler from 'actions/ResponseHandler';
import i18n from '../i18n';

// eslint-disable-next-line import/prefer-default-export
export const catchedErrorText = (err) => {
  if (err.response) {
    const resHandler = new ResponseHandler(err.response);
    return resHandler.getFlashMsgText();
  }
  return i18n.t('error.500_error');
};
