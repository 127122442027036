import { composeTheme } from '@css-modules-theme/core';
import settings from 'helpers/settings';

export const themes = {
  monacofit: 'monacofit',
  diet: 'diet',
  keto: 'keto',
  ketogo: 'ketogo',
  fasting: 'fasting',
  healthgenom: 'healthgenom',
};

export const getCurrentTheme = () => {
  switch (settings.THEME) {
    case 'diet':
      return themes.diet;
    case 'keto':
      return themes.keto;
    case 'ketogo':
      return themes.ketogo;
    case 'fasting':
      return themes.fasting;
    case 'healthgenom':
      return themes.healthgenom;
    case 'monacofit':
    default:
      return themes.monacofit;
  }
};

export const materialUiTheme = (theme = settings.THEME) => {
  switch (theme) {
    case themes.diet:
      return {
        typography: {
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        palette: {
          primary: {
            main: '#6DAEDB',
          },
          secondary: {
            main: '#FFC90D',
          },
        },
      };
    case themes.keto:
      return {
        typography: {
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        palette: {
          primary: {
            main: '#2a9d8f',
          },
          secondary: {
            main: '#bbbbbb',
          },
        },
      };
    case themes.ketogo:
      return {
        typography: {
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        palette: {
          primary: {
            main: '#2a9d8f',
          },
          secondary: {
            main: '#bbbbbb',
          },
        },
      };
    case themes.fasting:
      return {
        typography: {
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        palette: {
          primary: {
            main: '#29abe2',
          },
          secondary: {
            main: '#03cea4',
          },
        },
      };
    case themes.healthgenom:
      return {
        typography: {
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        palette: {
          primary: {
            main: '#ffca36',
          },
          secondary: {
            main: '#ffca36',
          },
        },
      };
    case themes.monacofit:
    default:
      return {};
  }
};

export const isMonacofit = () => getCurrentTheme() === themes.monacofit;
export const isDiet = () => getCurrentTheme() === themes.diet;
export const isKeto = () => getCurrentTheme() === themes.keto;
export const isKetoGo = () => getCurrentTheme() === themes.ketogo;
export const isFasting = () => getCurrentTheme() === themes.fasting;
export const isHealthgenom = () => getCurrentTheme() === themes.healthgenom;

export const getStyle = (
  monacofitStyles,
  dietStyles = {},
  ketoStyles = {},
  ketoGoStyles = {},
  fastingStyles = {},
  healthgenomStyles = {}
) => {
  if (getCurrentTheme() === themes.monacofit) {
    return monacofitStyles;
  }

  const theme = {
    [themes.monacofit]: {
      theme: monacofitStyles,
    },
    [themes.diet]: {
      theme: dietStyles,
    },
    [themes.keto]: {
      theme: ketoStyles,
    },
    [themes.ketogo]: {
      theme: ketoGoStyles,
    },
    [themes.fasting]: {
      theme: fastingStyles,
    },
    [themes.healthgenom]: {
      theme: healthgenomStyles,
    },
  };

  const styles = composeTheme([theme.monacofit, theme[getCurrentTheme()]]);

  return styles;
};

const PRIMARY_THEMES = ['diet', 'keto', 'ketogo', 'fasting', 'healthgenom'];

export const isPrimaryTheme = () => PRIMARY_THEMES.includes(getCurrentTheme());

export const isMaterialUI = () => {
  if (!settings.HAS_MATERIAL_UI) {
    return false;
  }

  return true;
};

export const getButtonStyleForTheme = () => {
  if (isKeto() || isKetoGo() || isHealthgenom()) {
    return 'ketoButton';
  }
  if (isFasting()) {
    return 'fastingButton';
  }

  return 'blue';
};

export const getMaterialUIColor = () => {
  if (isKeto() || isKetoGo() || isHealthgenom()) {
    return 'primary';
  }
  if (isFasting()) {
    return 'primary';
  }

  return 'secondary';
};
