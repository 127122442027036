import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { parse } from 'date-fns';

import { addDaysFormat, subDaysFormat } from 'helpers/dateHelper';
import { getCalendarDataAction } from 'actions/programActions';

const FORMAT_FROM = 'yyyy-LL-dd';
const FORMAT_TO = 'dd.LL.yyyy';

const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

export const useOnScreen = (ref, rootMargin = '0px') => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  const debouncedIntersecting = useDebounce(isIntersecting, 500);

  useEffect(() => {
    let observer;
    let localRef;

    async function startObserving() {
      if (!('IntersectionObserver' in window)) {
        await import('intersection-observer');
      }

      observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          setIntersecting(entry.isIntersecting);
        },
        {
          rootMargin,
        }
      );

      localRef = ref.current;
      if (localRef) {
        observer.observe(localRef);
      }
    }

    startObserving();

    return () => {
      if (observer && localRef) {
        observer.unobserve(localRef);
      }
    };
  }, [ref, rootMargin]);

  return debouncedIntersecting;
};

export const useDays = (listRef, selectedRef) => {
  const days = useSelector((state) => state.rootReducer.calendar.days);
  const currentDayId = useSelector((state) => state.rootReducer.day?.id);
  const previousDays = useRef([]);
  const [isLoadingPrevious, setIsLoadingPrevious] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [initalScroll, setInitialScroll] = useState(false);
  const dispatch = useDispatch();

  // set to selected day or today
  useEffect(() => {
    async function smoothScrollPolyfill() {
      const supportsNativeSmoothScroll =
        'scrollBehavior' in document.documentElement.style;
      if (!supportsNativeSmoothScroll) {
        const smoothscroll = await import('smoothscroll-polyfill');
        smoothscroll.polyfill();
      }

      if (selectedRef.current && !initalScroll) {
        selectedRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'center',
        });
        setInitialScroll(true);
      }
    }

    smoothScrollPolyfill();
    // eslint-disable-next-line
  }, [currentDayId]);

  useEffect(() => {
    if (isLoadingPrevious) {
      listRef.current.scrollTo({
        left:
          listRef.current.scrollLeft +
          92 * (days.length - previousDays.current.length),
        behavior: 'auto',
      });
      setIsLoadingPrevious(false);
    } else if (isLoadingNext) {
      setIsLoadingNext(false);
    }
    previousDays.current = days;
    // eslint-disable-next-line
  }, [days]);

  const scrollList = (direction) => {
    const list = listRef.current;
    let scrollAmount = list.scrollLeft;
    if (direction === 'left') {
      scrollAmount -= 92;
    } else {
      scrollAmount += 92;
    }

    return new Promise((resolve) => {
      let same = 0;
      let lastXPos = null;
      list.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      });

      // eslint-disable-next-line consistent-return
      const checkCurrentPosition = () => {
        const newPos = list.scrollLeft;

        if (newPos === lastXPos) {
          // eslint-disable-next-line no-plusplus
          if (same++ > 2) {
            return resolve(); // we've come to an halt
          }
        } else {
          same = 0;
          lastXPos = newPos; // remember our current position
        }
        // check again next painting frame
        requestAnimationFrame(checkCurrentPosition);
      };

      requestAnimationFrame(checkCurrentPosition);
    });
  };

  const loadPrevious = () => {
    if (isLoadingPrevious || !currentDayId) {
      return;
    }

    setIsLoadingPrevious(true);

    const startDayDate = parse(days[0].date, FORMAT_FROM, new Date());

    dispatch(
      getCalendarDataAction({
        user: {
          start_date: subDaysFormat(startDayDate, 3, FORMAT_TO),
          end_date: subDaysFormat(startDayDate, 1, FORMAT_TO),
        },
      })
    ).then(() => setIsLoadingPrevious(false));
  };

  const loadNext = () => {
    if (isLoadingNext || !currentDayId) {
      return;
    }

    setIsLoadingNext(true);

    const lastDay = days[days.length - 1].date;
    const lastDayDate = parse(lastDay, FORMAT_FROM, new Date());

    dispatch(
      getCalendarDataAction({
        user: {
          start_date: addDaysFormat(lastDayDate, 1, FORMAT_TO),
          end_date: addDaysFormat(lastDayDate, 3, FORMAT_TO),
        },
      })
    ).then(() => setIsLoadingNext(false));
  };

  return [
    days,
    scrollList,
    loadPrevious,
    isLoadingPrevious,
    loadNext,
    isLoadingNext,
  ];
};
