import qs from 'qs';
import { TFunction } from 'i18next';

import { getCurrentTheme } from 'helpers/themeHelper';
import { isPaypalProvider } from 'helpers/subscriptionHelper';
import { User } from 'types/user';
import {
  OfferItem,
  PaymentType,
  OfferPriceType,
  Offer,
  Paymodes,
  OFFER_MODE,
  OfferPrice,
} from 'types/offer';
import {
  UpsellList,
  UpsellTypes,
  UpsellOfferTypes,
  UpsellOffersUtmsList,
} from 'types/upsell';
import { Frequency, PaymentUrl, PaymentUrlQueryParams } from 'types/payment';

import { getOfferBannerUrl } from '../components/OfferBanner/OfferBanner';
import { getPaymentUrl, isPrimerCardSavedForUser } from './paymentHelper';

export const shouldShowLoginOffer = (user: User) => {
  if (user.show_login_offer !== true) {
    return false;
  }
  const lastSeenLoginOffer = sessionStorage.getItem('login_offer_seen');
  if (!lastSeenLoginOffer) {
    return true;
  }
  const seenMillisecondsAgo =
    new Date().getTime() - Date.parse(lastSeenLoginOffer);
  return seenMillisecondsAgo > 72000 * 1000;
};

export const markLoginOfferAsSeen = () => {
  sessionStorage.setItem('login_offer_seen', new Date().toString());
};

export const getUnsubscribeOfferLink = (user: User) =>
  getOfferBannerUrl(user) || '';

export const offerPremiumSubscription = (purchaseItems: string[]): boolean =>
  purchaseItems.includes('premium_subscription');

export const getPremiumPriceTranslationKey = (
  priceKey: string,
  premiumSubscription: boolean
): string =>
  premiumSubscription
    ? `offer_price.subscription.${priceKey}`
    : `offer_price.${priceKey}`;

export const getSavedCardPaymode = (user: User, offer: any) => {
  // TODO: After full primer integration replace this if with nothing
  if (isPrimerCardSavedForUser(user)) {
    return Paymodes.primer;
  }

  const { cards } = user;
  const { paymodes } = offer;

  if (!cards) {
    return '';
  }

  const savedCardPaymode = cards.find((card) =>
    paymodes.includes(card.paymode.name)
  );

  if (!savedCardPaymode) {
    return '';
  }

  return savedCardPaymode.paymode.name;
};

export const getUserSubscriptionPaymodeText = (
  t: TFunction,
  subscription: any
) => {
  const isPaypalSub = isPaypalProvider(subscription);
  const paymodeText = isPaypalSub ? t('paymodes.paypal') : t('paymodes.cards');

  return paymodeText;
};

export const isPremiumSubscription = (offerItems: OfferItem[]) => {
  const subscriptionPurchaseItem = offerItems.find(
    (item) =>
      item.main && item.purchase_item.paying_type === PaymentType.subscription
  );

  if (!subscriptionPurchaseItem) {
    return false;
  }

  return true;
};

export const getUnsubscribeOfferPrice = (offer: Offer) =>
  offer.offer_prices.find((price) => price.type === OfferPriceType.unsubscribe);

export const getStandardOfferPrice = (offer: Offer) =>
  offer.offer_prices.find((price) => price.type === OfferPriceType.standard);

export const getChanceOfferPrice = (offer: Offer) =>
  offer.offer_prices.find((price) => price.type === OfferPriceType.chance);

export const isUpsellOfferShoppingList = (upsellList: UpsellList): any =>
  Object.keys(upsellList).find(
    (upsell) =>
      upsellList[parseInt(upsell, 10)].type === UpsellTypes.shoppingList
  );

export const isUpsellOfferFatBurner = (upsellList: UpsellList): any =>
  Object.keys(upsellList).find(
    (upsell) => upsellList[parseInt(upsell, 10)].type === UpsellTypes.fatBurner
  );

export const isUpsellOfferAnnualPro = (upsellList: UpsellList) =>
  Object.keys(upsellList).find(
    (upsell) => upsellList[parseInt(upsell, 10)].type === UpsellTypes.pro
  );

export const isPremiumUpsellOffer = (upsellList: UpsellList) =>
  Object.keys(upsellList).find(
    (upsell) => upsellList[parseInt(upsell, 10)].type === UpsellTypes.premium
  );

export const isUpsellOfferCollagen = (upsellList: UpsellList) =>
  Object.keys(upsellList).find(
    (upsell) => upsellList[parseInt(upsell, 10)].type === UpsellTypes.collagen
  );

export const isPremiumShoppingListBundleOffer = (upsellList: UpsellList) =>
  Object.keys(upsellList).find(
    (upsell) =>
      upsellList[parseInt(upsell, 10)].type ===
      UpsellTypes.premiumShoppingListBundle
  );

export const isUpsellOfferChat = (upsellList: UpsellList) =>
  Object.keys(upsellList).find(
    (upsell) => upsellList[parseInt(upsell, 10)].type === UpsellTypes.chat
  );

export const isUpsellOfferAcvGummies = (upsellList: UpsellList) =>
  Object.keys(upsellList).find(
    (upsell) => upsellList[parseInt(upsell, 10)].type === UpsellTypes.acvGummies
  );

export const isUpsellOfferGenom = (upsellList: UpsellList) =>
  Object.keys(upsellList).find(
    (upsell) => upsellList[parseInt(upsell, 10)].type === UpsellTypes.genom
  );

export const isUpsellOfferWorkouts = (upsellList: UpsellList) =>
  Object.keys(upsellList).find(
    (upsell) => upsellList[parseInt(upsell, 10)].type === UpsellTypes.workouts
  );

export const isUpsellOfferKetoChocolateBars = (upsellList: UpsellList) =>
  Object.keys(upsellList).find(
    (upsell) =>
      upsellList[parseInt(upsell, 10)].type === UpsellTypes.ketoChocolateBars
  );

export const isUpsellOffer = (upsellList: UpsellList) => {
  if (isUpsellOfferShoppingList(upsellList)) {
    return true;
  }
  if (isUpsellOfferFatBurner(upsellList)) {
    return true;
  }
  if (isUpsellOfferAnnualPro(upsellList)) {
    return true;
  }
  if (isPremiumUpsellOffer(upsellList)) {
    return true;
  }
  if (isUpsellOfferCollagen(upsellList)) {
    return true;
  }
  if (isPremiumShoppingListBundleOffer(upsellList)) {
    return true;
  }
  if (isUpsellOfferChat(upsellList)) {
    return true;
  }
  if (isUpsellOfferAcvGummies(upsellList)) {
    return true;
  }
  if (isUpsellOfferGenom(upsellList)) {
    return true;
  }
  if (isUpsellOfferWorkouts(upsellList)) {
    return true;
  }

  return false;
};

export const getUpsellOfferUtms = (
  upsellType: UpsellTypes,
  offerType: UpsellOfferTypes,
  t: TFunction
) => {
  const UPSELL_UTMS: UpsellOffersUtmsList = {
    fat_burner: {
      universal: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'fatburner',
      },
      registration: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'registration_flow',
        utm_campaign: 'fatburner',
      },
    },
    shopping_list: {
      universal: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'save_time_with_shopping_list',
      },
      registration: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'registration_flow',
        utm_campaign: 'save_time_with_shopping_list',
      },
    },
    pro_subscription: {
      universal: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'universal_annual_save_half',
      },
      registration: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'registration_flow',
        utm_campaign: 'register_annual_save_half',
      },
    },
    premium_upsell: {
      universal: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'premium_app_section',
        utm_campaign: 'upgrade_plan_to_premium',
      },
      registration: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'registration_flow',
        utm_campaign: 'lose_weight_easier_with_premium',
      },
    },
    collagen: {
      universal: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'collagen',
      },
      registration: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'registration_flow',
        utm_campaign: 'collagen',
      },
    },
    premium_shopping_list_bundle: {
      universal: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'premium_shopping_list_bundle',
      },
      registration: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'registration_flow',
        utm_campaign: 'premium_shopping_list_bundle',
      },
    },
    chat: {
      universal: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'personal_weight_loss_coach',
      },
      registration: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'registration_flow',
        utm_campaign: 'personal_weight_loss_coach',
      },
      meal_survey: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'locked_flow',
        utm_campaign: 'meal_survey_chat',
      },
      // Chat imitation upsell flow
      locked_chat: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'chat_section',
        utm_campaign: 'personal_weight_loss_coach',
      },
      // Chat default upsell flow
      universal_chat: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'chat_section',
      },
    },
    acv_gummies: {
      universal: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'apple_cider_vinegar_gummies',
      },
      registration: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'registration_flow',
        utm_campaign: 'apple_cider_vinegar_gummies',
      },
    },
    genom: {
      universal: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'genomplus_upsell',
      },
      registration: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'registration_flow',
        utm_campaign: 'genomplus_upsell',
      },
      profile_genom: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'profile_genom_section',
      },
    },
    workouts: {
      registration: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'registration_flow',
        utm_campaign: 'improve_plan_with_workouts_registration',
      },
      universal: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'full_offer',
      },
      profile: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'profile_flow',
        utm_campaign: 'workouts_in_profile',
      },
    },
    keto_chocolate_bars: {
      registration: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'registration_flow',
        utm_campaign: 'keto_chocolate',
      },
      universal: {
        utm_source: t('domains:domain.website'),
        utm_medium: 'universal_flow',
        utm_campaign: 'keto_chocolate',
      },
    },
  };

  return UPSELL_UTMS[upsellType]?.[offerType];
};

export const getShoppingListInlineOfferLink = (t: TFunction) => {
  const queryParams = {
    utm_source: t('domains:domain.website'),
    utm_medium: 'shopping_list_section',
    utm_campaign: 'create_shopping_list',
    purchase_source: '/program/shopping-list',
  };

  return `/offer/universal_shopping_list?${qs.stringify(queryParams)}`;
};

export const getAutomaticCardSavePaymentLink = (
  user: User,
  offer: Offer,
  queryParams: PaymentUrlQueryParams
) => {
  const savedCardPaymode = getSavedCardPaymode(user, offer);

  if (!savedCardPaymode) {
    return null;
  }
  if (!offer.id) {
    return null;
  }

  const urlParams: PaymentUrl = {
    paymode: savedCardPaymode,
    userId: user.id,
    offerId: offer.id,
    queryParams,
  };

  return getPaymentUrl(urlParams);
};

/** @deprecated TODO: switch to queryHelper */
export const getQueryParams = () =>
  qs.parse(window.location.search, { ignoreQueryPrefix: true });

export const isPrimerPaymode = (paymode: string): boolean =>
  paymode === Paymodes.primer;

/**
 * Build amount string with currency
 * @param amount {String}
 * @param currency {String}
 * @param reverse {Boolean}
 * @return {String}
 */
export const currency = (
  amount: string | number,
  currency: string,
  reverse?: boolean
) => {
  if (typeof amount === 'number') {
    return reverse
      ? `${currency}${amount.toString()}`
      : `${amount.toString()} ${currency}`;
  }
  return reverse ? `${currency}${amount}` : `${amount} ${currency}`;
};

/**
 * Temporary solution
 * TODO: move all prices per day from BE response
 * @param price
 */
export const calculatePricePerDay = (price: number) => {
  const amount = price / 30;
  return Math.ceil(amount * 100) / 100;
};

export const getAppWebsite = () => {
  const appWebsite: { [key: string]: string } = {
    monacofit: 'app.monacofit.com',
    diet: 'appv7.stockholmdiet.com',
    keto: 'app.certifiedketodiet.com',
    ketogo: 'my.ketogo.app',
    fasting: 'app.certifiedfasting.com',
    healthgenom: 'app.healthgenom.com',
  };

  return appWebsite[getCurrentTheme()];
};

type PremiumShoppinglistBundleUtms = {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
};

type OfferType = {
  [key: string]: PremiumShoppinglistBundleUtms;
};

export const getPremiumShoppingListBundleUtms = (
  offerType: string,
  templateName: string
) => {
  const webSite = getAppWebsite();
  const premiumShoppinglistUtms: {
    [key: string]: OfferType;
  } = {
    registration_premium_shopping_list_bundle: {
      PremiumShoppingListBundleV1: {
        utm_source: webSite,
        utm_medium: 'registration_flow',
        utm_campaign: 'premium_shopping_list_bundle_v1',
      },
      PremiumShoppingListBundleV2: {
        utm_source: webSite,
        utm_medium: 'registration_flow',
        utm_campaign: 'premium_shopping_list_bundle_v2',
      },
    },
    universal_premium_shopping_list_bundle: {
      PremiumShoppingListBundleV1: {
        utm_source: webSite,
        utm_medium: 'universal_flow',
        utm_campaign: 'premium_shopping_list_bundle_v1',
      },
      PremiumShoppingListBundleV2: {
        utm_source: webSite,
        utm_medium: 'universal_flow ',
        utm_campaign: 'premium_shopping_list_bundle_v2',
      },
    },
  };

  return premiumShoppinglistUtms[offerType][templateName];
};

export const isMultiOffer = (offer: Offer): boolean => {
  if (offer.mode !== OFFER_MODE.multi) return false;
  if (offer.attached_offers.length < 1) return false;

  return true;
};

export const getFrequencyInMonths = (subFrequency: number) => {
  if (subFrequency === Frequency.threeMonth) {
    return 3;
  }
  if (subFrequency === Frequency.sixMonth) {
    return 6;
  }
  if (subFrequency === Frequency.year) {
    return 12;
  }

  return 1;
};

export const getAcvGummiesOfferUtms = (
  offerType: string,
  templateName: string
) => {
  const webSite = getAppWebsite();
  const acvGummiesUtms: {
    [key: string]: OfferType;
  } = {
    registration_acv_gummies: {
      AcvGummiesA: {
        utm_source: webSite,
        utm_medium: 'registration_flow',
        utm_campaign: 'CF_Apple_Cider_Vinegar_Gummies_V1',
      },
      AcvGummiesB: {
        utm_source: webSite,
        utm_medium: 'registration_flow',
        utm_campaign: 'CF_Apple_Cider_Vinegar_Gummies_V2',
      },
    },
    universal_acv_gummies: {
      AcvGummiesA: {
        utm_source: webSite,
        utm_medium: 'universal_flow',
        utm_campaign: 'CF_Apple_Cider_Vinegar_Gummies_V1',
      },
      AcvGummiesB: {
        utm_source: webSite,
        utm_medium: 'universal_flow ',
        utm_campaign: 'CF_Apple_Cider_Vinegar_Gummies_V2',
      },
    },
    profile_acv_gummies: {
      AcvGummiesA: {
        utm_source: webSite,
        utm_medium: 'user_profile_section',
        utm_campaign: 'CF_Apple_Cider_Vinegar_Gummies_V1',
      },
      AcvGummiesB: {
        utm_source: webSite,
        utm_medium: 'user_profile_section ',
        utm_campaign: 'CF_Apple_Cider_Vinegar_Gummies_V2',
      },
    },
  };

  return acvGummiesUtms[offerType][templateName];
};

export const getGenomPlusOfferUtms = (
  offerType: string,
  templateName: string
) => {
  const webSite = getAppWebsite();
  const utms: {
    [key: string]: OfferType;
  } = {
    registration_genom: {
      GenomPlus: {
        utm_source: webSite,
        utm_medium: 'registration_flow',
        utm_campaign: 'genomplus_upsell_v1',
      },
      GenomPlusV2: {
        utm_source: webSite,
        utm_medium: 'registration_flow',
        utm_campaign: 'genomplus_upsell_v2',
      },
    },
    universal_genom: {
      GenomPlus: {
        utm_source: webSite,
        utm_medium: 'universal_flow',
        utm_campaign: 'genomplus_upsell_v1',
      },
      GenomPlusV2: {
        utm_source: webSite,
        utm_medium: 'universal_flow',
        utm_campaign: 'genomplus_upsell_v2',
      },
    },
    profile_genom: {
      GenomPlus: {
        utm_source: webSite,
        utm_medium: 'universal_flow',
        utm_campaign: 'profile_genom_section_v1',
      },
      GenomPlusV2: {
        utm_source: webSite,
        utm_medium: 'universal_flow',
        utm_campaign: 'profile_genom_section_v2',
      },
    },
  };

  return utms[offerType][templateName];
};

export const GENOM_PLUS_OFFER_TYPES = [
  'registration_genom',
  'universal_genom',
  'profile_genom',
];

export const SHOPPINGLIST_BUNDLE_OFFER_TYPES = [
  'registration_premium_shopping_list_bundle',
  'universal_premium_shopping_list_bundle',
];

export const isOfferSubscription = (offerOrice: OfferPrice): boolean =>
  offerOrice.payment_type === PaymentType.subscription;

export const getTimerOfferPrice = (offer: Offer): OfferPrice | undefined =>
  offer.offer_prices.find((price) => price.type === OfferPriceType.timer);

export const getTimerValueFromOfferPrice = (
  timerPrice: OfferPrice | undefined
): string => {
  if (!timerPrice || !timerPrice?.extra_data?.timer_value) return '';

  return timerPrice.extra_data.timer_value;
};
