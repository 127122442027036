import React from 'react';

function CollagenCalendar() {
  return (
    <svg
      width={48}
      height={48}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="a" d="M0 0h37.19v36H0z" />
        <path id="c" d="M0 36h37.19V0H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <g transform="translate(5.4 6)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            d="M4.019 4.88h2.05v1.762c0 .78.615 1.394 1.394 1.394h3.075c.78 0 1.394-.615 1.394-1.394V4.88h13.203v1.763c0 .78.615 1.394 1.394 1.394h3.116c.78 0 1.394-.614 1.394-1.394V4.88h2.05c1.353 0 2.46 1.107 2.46 2.42v2.54H1.6V7.298c0-1.312 1.108-2.419 2.42-2.419zm3.69 1.516h2.624V1.64H7.71v4.756zm19.107 0h2.624V1.64h-2.624v4.757zm2.83 27.595v-4.592h5.33l-5.33 4.592zM1.6 31.94V11.48h33.95v16.277h-6.028c-.82 0-1.517.656-1.517 1.517v5.085H4.02c-1.312 0-2.42-1.107-2.42-2.42zM4.019 36H29.48c.082 0 .164 0 .246-.041h.041c.041 0 .082-.04.082-.04h.041c.041 0 .041-.042.082-.042h.041l6.889-5.987v-.04l.123-.123c.04-.041.04-.083.082-.123v-.041c.04-.123.082-.205.082-.328V7.298c0-2.255-1.845-4.059-4.06-4.059h-2.05V1.394A1.379 1.379 0 0 0 29.687 0h-3.075c-.779 0-1.394.615-1.394 1.394v1.845H11.973V1.394C11.973.614 11.358 0 10.58 0H7.504C6.725 0 6.11.615 6.11 1.394v1.845H4.06A4.042 4.042 0 0 0 0 7.299v24.6C-.04 34.156 1.806 36 4.02 36z"
            fill="#2A9D8F"
            mask="url(#b)"
          />
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            d="M6.971 24.11H9.35v-2.38H6.971v2.378zm3.198-4.019H6.151a.822.822 0 0 0-.82.82v4.018c0 .451.37.82.82.82h4.018c.451 0 .82-.369.82-.82v-4.018c0-.45-.369-.82-.82-.82zm-3.198-3.157H9.35v-2.378H6.971v2.378zm3.198-4.018H6.151a.822.822 0 0 0-.82.82v4.018c0 .45.37.82.82.82h4.018c.451 0 .82-.37.82-.82v-4.018a.822.822 0 0 0-.82-.82zM6.971 31.244H9.35v-2.379H6.971v2.379zm3.198-4.019H6.151a.822.822 0 0 0-.82.82v4.019c0 .45.37.82.82.82h4.018c.451 0 .82-.37.82-.82v-4.019a.822.822 0 0 0-.82-.82zm3.773-3.115h2.378v-2.38h-2.378v2.378zm3.198-4.019h-4.018a.822.822 0 0 0-.82.82v4.018c0 .451.369.82.82.82h4.018c.45 0 .82-.369.82-.82v-4.018c0-.45-.37-.82-.82-.82zm-3.198-3.157h2.378v-2.378h-2.378v2.378zm3.198-4.018h-4.018a.822.822 0 0 0-.82.82v4.018c0 .45.369.82.82.82h4.018c.45 0 .82-.37.82-.82v-4.018c0-.451-.37-.82-.82-.82zm-3.198 18.328h2.378v-2.379h-2.378v2.379zm3.198-4.019h-4.018a.822.822 0 0 0-.82.82v4.019c0 .45.369.82.82.82h4.018c.45 0 .82-.37.82-.82v-4.019a.822.822 0 0 0-.82-.82zm3.731-3.115h2.378v-2.38h-2.378v2.378zm3.198-4.019h-4.018a.822.822 0 0 0-.82.82v4.018c0 .451.369.82.82.82h4.018c.451 0 .82-.369.82-.82v-4.018c0-.45-.369-.82-.82-.82zm-3.198-3.157h2.378v-2.378h-2.378v2.378zm3.198-4.018h-4.018a.822.822 0 0 0-.82.82v4.018c0 .45.369.82.82.82h4.018c.451 0 .82-.37.82-.82v-4.018a.822.822 0 0 0-.82-.82z"
            fill="#2A9D8F"
            mask="url(#d)"
          />
          <path
            d="M20.871 31.244h2.378v-2.379h-2.378v2.379zm3.198-4.019h-4.018a.822.822 0 0 0-.82.82v4.019c0 .45.369.82.82.82h4.018c.451 0 .82-.37.82-.82v-4.019a.822.822 0 0 0-.82-.82z"
            fill="#E9C46A"
            mask="url(#d)"
          />
          <path
            d="M27.8 24.11h2.378v-2.38H27.8v2.378zm3.2 1.64c.45 0 .82-.37.82-.82v-4.02a.822.822 0 0 0-.82-.82h-4.02a.822.822 0 0 0-.82.82v4.018c0 .451.37.82.82.82H31zm-3.2-8.816h2.378v-2.378H27.8v2.378zm-.82 1.64h4.018c.451 0 .82-.369.82-.82v-4.018a.822.822 0 0 0-.82-.82H26.98a.822.822 0 0 0-.82.82v4.018c0 .451.37.82.82.82z"
            fill="#2A9D8F"
            mask="url(#d)"
          />
          <path fill="#E9C46A" mask="url(#d)" d="M1.6 11.48h33.95V9.84H1.6z" />
        </g>
      </g>
    </svg>
  );
}

export default CollagenCalendar;
