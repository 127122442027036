import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TFunction } from 'i18next';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  Divider,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  TextField,
} from '@material-ui/core';

import { User } from 'types/user';
import { Subscription } from 'types/subscription';
import browserHistory from 'helpers/history';
import { getMaterialUIColor } from 'helpers/themeHelper';
import { getActiveWorkoutsSubscription } from 'helpers/userHelper';
import { postUnsubscribeReason } from 'api/SubscriptionAPI';
import { getUserData } from 'actions/programActions';
import { addModal, removeModal } from 'actions/modalActions';
import { unsubscribeUserAction } from 'actions/subscriptionActions';
import {
  UNSUBSCRIBE_REASONS,
  getSortedUnsubscribeReasons,
} from 'helpers/globalDataHelper';
import { GlobalDataStatus, UnsubscribeReasons } from 'types/globalData';
import { ModalType } from 'types/modals';

import { SubmitButton } from 'components/common';
import WorkoutsUnsubscribeCompleted from './WorkoutsUnsubscribeCompleted';

import s from './WorkoutsUnsubscribeReasonPage.module.scss';

type CommentProps = {
  comment: string;
  isDisabled: boolean;
  t: TFunction;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Comment = ({ comment, isDisabled, t, onChange }: CommentProps) => {
  const maxLength = 250;

  return (
    <div className={s.commentWrapper}>
      <TextField
        multiline
        variant="outlined"
        label={t(`unsubscribe.reason.other_title`)}
        placeholder={t(`unsubscribe.reason.other_placeholder`)}
        autoFocus
        InputProps={{
          classes: { focused: s.focusedInput },
        }}
        InputLabelProps={{ classes: { focused: s.focusedLegend } }}
        value={comment}
        className={s.comment}
        inputProps={{ maxLength }}
        disabled={isDisabled}
        onChange={onChange}
      />
      <span
        className={`${s.commentLength} ${
          comment.length === maxLength && s.maxCapacity
        }`}
      >
        {t(`unsubscribe.reason.other_capacity`, {
          comment_length: comment.length,
          max_length: maxLength,
        })}
      </span>
    </div>
  );
};

type ReasonProps = {
  selectedReason: string;
  reasonName: string;
  disabled: boolean;
  t: TFunction;
  handleSelect: (reasonName: string) => void;
};

const Reason = ({
  selectedReason,
  reasonName,
  disabled,
  t,
  handleSelect,
}: ReasonProps) => {
  const color = getMaterialUIColor();
  const isSelected = selectedReason === reasonName;

  const onClick = () => {
    handleSelect(reasonName);
  };

  return (
    <>
      <ListItem
        button
        disabled={disabled}
        selected={isSelected}
        className={`${s.listItem} ${isSelected && s.selectedListItem}`}
        onClick={onClick}
      >
        <ListItemText className={s.itemText}>
          {t(`unsubscribe.reason.${reasonName}`)}
        </ListItemText>
        <ListItemSecondaryAction>
          <Radio
            className={s.radioButton}
            edge="end"
            tabIndex={-1}
            disabled={disabled}
            checked={isSelected}
            color={color}
            onClick={onClick}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </>
  );
};

function WorkoutsUnsubscribeReasonPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('workouts');
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const {
    globalData: { unsubscribeReasons },
  } = useSelector<any, GlobalDataStatus>(
    (store) => store.rootReducer.globalData
  );
  const sortedReasons = getSortedUnsubscribeReasons(
    unsubscribeReasons as UnsubscribeReasons
  );
  const [selectedReason, setSelectedReason] = useState(sortedReasons[0]);
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isOtherReason = selectedReason === UNSUBSCRIBE_REASONS.other;

  const handleKeepWorkouts = () => {
    history.replace('/program');
  };

  const handleCancel = async () => {
    setIsLoading(true);

    const apiPayload: { reasons: string[]; comment?: string } = {
      reasons: [selectedReason],
    };

    if (isOtherReason) apiPayload.comment = comment;

    const subscription = getActiveWorkoutsSubscription(user);

    await dispatch(unsubscribeUserAction((subscription as Subscription).id));

    await postUnsubscribeReason((subscription as Subscription).id, {
      reasons: [selectedReason],
      apiPayload,
    });

    dispatch(getUserData());

    browserHistory.replaceLater('/program');

    const handleClose = () => {
      dispatch(removeModal({ modal: ModalType.customModal }));
    };

    dispatch(
      addModal({
        modal: ModalType.customModal,
        customModalNode: (
          <WorkoutsUnsubscribeCompleted handleClose={handleClose} />
        ),
      })
    );
  };

  const isSubmitForbidden = isLoading || (isOtherReason && comment.length < 30);

  return (
    <div className={s.container}>
      <div className={s.title}>{t('unsubscribe.reason.title')}</div>
      <div className={s.subtitle}>
        <Trans
          i18nKey="workouts:unsubscribe.reason.subtitle"
          components={[<span className={s.bold} />]}
        />
      </div>

      <List>
        {sortedReasons.map((reason) => (
          <Reason
            key={reason}
            selectedReason={selectedReason}
            reasonName={reason}
            disabled={isLoading}
            t={t}
            handleSelect={setSelectedReason}
          />
        ))}
      </List>

      {isOtherReason && (
        <Comment
          comment={comment}
          isDisabled={isLoading}
          t={t}
          onChange={(e) => setComment(e.target.value)}
        />
      )}

      <div className={s.buttons}>
        <SubmitButton
          className={s.submitButton}
          type="large compact"
          label={t('unsubscribe.reason.keep_label')}
          onClick={handleKeepWorkouts}
        />

        <SubmitButton
          className={s.submitButton}
          type="text-large compact"
          variant="outlined"
          label={t('unsubscribe.reason.cancel_label')}
          onClick={handleCancel}
          isLoading={isLoading}
          disabled={isSubmitForbidden}
        />
      </div>
    </div>
  );
}

export default WorkoutsUnsubscribeReasonPage;
