import React from 'react';

import { SVG } from 'types/svg';

function PhoneDiet(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} />
        <path
          fill="#000"
          fillOpacity={0.54}
          fillRule="nonzero"
          d="M17.4 22C8.9 21.99 2.011 15.1 2 6.6A4.6 4.6 0 016.6 2c.258-.002.516.021.77.07.245.036.486.097.72.18.334.117.581.403.65.75l1.37 6c.075.33-.023.677-.26.92-.13.14-.14.15-1.37.79a9.913 9.913 0 004.87 4.89c.65-1.24.66-1.25.8-1.38.243-.237.59-.335.92-.26l6 1.37c.336.078.608.324.72.65.084.237.148.482.19.73.04.251.06.505.06.76A4.6 4.6 0 0117.4 22z"
        />
      </g>
    </svg>
  );
}

export default PhoneDiet;
