import React from 'react';

import { SVG } from 'types/svg';

function LogoDiet(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} fill="#000" fillOpacity={0.54} />
        <path
          d="M10.109 8.5v3.109H7v3.781h3.109v3.11h3.782v-3.11H17v-3.781h-3.109V8.5zm2.973-2.299l-1.046-1.375-1.046 1.375L8.667 4.75l1.45 2.75h3.765l1.451-2.75z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default LogoDiet;
