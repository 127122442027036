import { isHealthgenom, isKeto, isKetoGo } from 'helpers/themeHelper';
import { TFunction } from 'i18next';
import { getCurrentLocale } from 'i18n';

import { User } from 'types/user';
import settings from 'helpers/settings';

export enum CONTEXTUAL_OFFER_TYPE {
  tricks = 'tricks',
  mealSwitch = 'meal_switch',
  nutrients = 'nutrients',
}

export type ContextualOfferType = {};

export const userHasTricksAccess = (user: User) =>
  user.access && user.access.includes('tricks');

export const userHasMealchangeAccess = (user: User) =>
  user.access && user.access.includes('meal_switch');

export const isPaywallEnabled = () => settings.PAYWALL_ENABLED;

export const userShouldSeeContextualOffer = (
  user: User,
  contextualOfferType: CONTEXTUAL_OFFER_TYPE
) => {
  if (!isPaywallEnabled()) return false;
  // CKD/KG: Doesn't have premium so we allow users to have it
  if (isKeto() || isKetoGo() || isHealthgenom()) {
    if (isPaywallEnabled() && getCurrentLocale() === 'lv') return false;
  }
  if (user.access.includes(contextualOfferType)) return false;

  return true;
};

export const getPaywallUtms = (
  contextualType: CONTEXTUAL_OFFER_TYPE,
  t: TFunction
) => {
  const UTMS = {
    tricks: {
      utm_source: t('domains:domain.website'),
      utm_medium: 'premium_app_section',
      utm_campaign: 'program-open-tricks',
    },
    meal_switch: {
      utm_source: t('domains:domain.website'),
      utm_medium: 'premium_app_section',
      utm_campaign: 'program-open-meal-change',
    },
    nutrients: {
      utm_source: t('domains:domain.website'),
      utm_medium: 'premium_app_section',
      utm_campaign: 'program-open-nutrition-values',
    },
  };

  return UTMS[contextualType];
};

export const getMealTranslations = (
  contextualType: CONTEXTUAL_OFFER_TYPE,
  mealType: string | undefined,
  t: TFunction
) => {
  if (!mealType) {
    return '';
  }
  if (mealType === 'snack') {
    return `${t('items.type.snack.header')}`;
  }

  return `${t(`contextualoffer:${contextualType}.meal_type.${mealType}`)}`;
};
