import React from 'react';

import { SVG } from 'types/svg';

function Calendar(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.25 3.06H18.9V1.8a1.79 1.79 0 1 0-3.57 0v1.27H8.68V1.8a1.79 1.79 0 1 0-3.57 0v1.27H3.75A2.73 2.73 0 0 0 1 5.79v15.48A2.73 2.73 0 0 0 3.75 24h16.5A2.73 2.73 0 0 0 23 21.27V5.8a2.73 2.73 0 0 0-2.75-2.73zm-3.4-1.27a.26.26 0 0 1 .26-.26.26.26 0 0 1 .25.26v2c0 .28-.5.28-.5 0zm-10.2 0a.26.26 0 0 1 .25-.26.26.26 0 0 1 .26.26v2c0 .28-.5.28-.5 0zm-2.9 2.8H5.3a1.77 1.77 0 0 0 1.6 1 1.79 1.79 0 0 0 1.61-1h7a1.79 1.79 0 0 0 1.61 1 1.77 1.77 0 0 0 1.6-1h1.54a1.21 1.21 0 0 1 1.2 1.19v.34H2.55V5.8a1.21 1.21 0 0 1 1.2-1.19zm16.5 17.87H3.75a1.22 1.22 0 0 1-1.11-.75 1.93 1.93 0 0 0 .34.11 1.27 1.27 0 0 0 .18.06 2.6 2.6 0 0 0 .56.06h16.5a2.6 2.6 0 0 0 .56-.06 1.27 1.27 0 0 0 .18-.06 1.93 1.93 0 0 0 .34-.11 1.22 1.22 0 0 1-1.05.75zm0-2H3.75a1.22 1.22 0 0 1-1.2-1.2V7.66h18.9v11.57a1.22 1.22 0 0 1-1.2 1.2z" />
      <circle cx={5.87} cy={9.96} r={1.28} />
      <circle cx={9.96} cy={9.96} r={1.28} />
      <circle cx={14.04} cy={9.96} r={1.28} />
      <circle cx={5.87} cy={14.04} r={1.28} />
      <circle cx={9.96} cy={14.04} r={1.28} />
      <circle cx={14.04} cy={14.04} r={1.28} />
      <circle cx={5.87} cy={18.13} r={1.28} />
      <circle cx={9.96} cy={18.13} r={1.28} />
      <circle cx={14.04} cy={18.13} r={1.28} />
      <circle cx={18.13} cy={9.96} r={1.28} />
      <circle cx={18.13} cy={14.04} r={1.28} />
      <circle cx={18.13} cy={18.13} r={1.28} />
    </svg>
  );
}

export default Calendar;
