import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (state = initialState.offer, action) => {
  switch (action.type) {
    case types.OFFER_LOAD.SUCCESS:
      return {
        data: action.offer,
        loadError: false,
      };

    case types.OFFER_CLEAR.SUCCESS:
      return initialState.offer;

    case types.LOG_OUT.SUCCESS:
      return initialState.offer;

    case types.OFFER_LOAD.ERROR:
      return { ...state, loadError: true };

    default:
      return state;
  }
};
