import React from 'react';

import videoSource from 'assets/video/fitness.mp4';

const BgVideo = () => (
  <div className="bg__video">
    <video muted loop autoPlay playsInline id="bgvid">
      <source src={videoSource} type="video/mp4" />
    </video>
  </div>
);

export default BgVideo;
