import React from 'react';

import s from './styles/Spinner.module.scss';

const Spinner = () => (
  <div className={s.spinner}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Spinner;
