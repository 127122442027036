import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { ReactComponent as Icon } from 'assets/images/modals/unsubscribe/heart.svg';
import { SubmitButton } from 'components/common';
import CommonModal from 'components/common/CommonModal';

import s from './WorkoutsOfferSuccess.module.scss';

const WorkoutsOfferSuccess = ({
  price,
  handleClose,
}: {
  price: string;
  handleClose: Function;
}) => {
  const { t } = useTranslation('workouts');

  const icon = <Icon />;

  const title = t('unsubscribe.offer_success.title');

  const body = (
    <Trans
      i18nKey="workouts:unsubscribe.offer_success.info"
      values={{
        price,
      }}
    />
  );

  const buttons = (
    <div className={s.buttonsWrapper}>
      <SubmitButton
        className={s.submitButton}
        type="large"
        label={t('unsubscribe.offer_success.submit')}
        onClick={() => handleClose()}
      />
    </div>
  );

  return (
    <CommonModal
      icon={icon}
      title={title}
      body={body}
      buttons={buttons}
      onClose={handleClose}
    />
  );
};

export default WorkoutsOfferSuccess;
