import React from 'react';
import { Redirect } from 'react-router-dom';
import { getCurrentTheme } from 'helpers/themeHelper';

class EnsureTheme extends React.Component {
  render() {
    const { theme, children } = this.props;

    if (theme.includes(getCurrentTheme())) {
      return children;
    }

    return <Redirect to="/program" />;
  }
}

export default EnsureTheme;
