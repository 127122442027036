import React from 'react';

import { SVG } from 'types/svg';

function PersonDiet(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} />
        <path
          fill="#000"
          fillOpacity={0.54}
          fillRule="nonzero"
          d="M16.636 4.364C16.636 2.028 14.61 0 12.273 0 9.937 0 7.909 2.028 7.909 4.364s2.028 4.363 4.364 4.363 4.363-2.027 4.363-4.363zm-4.363 5.818C8.45 10.182 5 14.305 5 19.43c0 5.124 14.545 5.124 14.545 0 0-5.125-3.45-9.248-7.272-9.248z"
        />
      </g>
    </svg>
  );
}

export default PersonDiet;
