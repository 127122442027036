import React from 'react';

import { SVG } from 'types/svg';

function WeightDiet(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} />
        <path
          fill="#000"
          fillOpacity={0.54}
          fillRule="nonzero"
          d="M11.723.204c2.329 0 4.52.738 6.308 2.125l.28.224h2.604c.82 0 1.457.592 1.526 1.39l.006.142v18.383c0 .82-.592 1.458-1.39 1.526l-.142.006H2.532c-.82 0-1.458-.592-1.526-1.39L1 22.469V4.085c0-.82.592-1.457 1.39-1.526l.142-.006h2.604A10.297 10.297 0 0111.15.22l.37-.015v.003l.204-.003zm-8.68 21.243a.51.51 0 10-.001 1.02.51.51 0 00.001-1.02zm17.361 0a.51.51 0 100 1.02.51.51 0 000-1.02zm-8.732-18.69a8.293 8.293 0 00-6.74 3.473l3.32 2.604 2.677-.04a1.01 1.01 0 01.314-1.513l-.015-.064-.005-.068V4.596a.5.5 0 01.992-.09l.008.09v2.553a.498.498 0 01-.018.134 1.005 1.005 0 01.332 1.487l2.557-.038 3.268-2.655c-1.532-1.992-3.932-3.32-6.69-3.32z"
        />
      </g>
    </svg>
  );
}

export default WeightDiet;
