import React from 'react';

function FastingUtensils() {
  return (
    <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg">
      <g fill="#03CEA4">
        <path d="m3.8 8.95 3.304-2.925-5.73-5.73a.58.58 0 0 0-.96.2 5.793 5.793 0 0 0 1.18 6.017L3.8 8.95zm6.186-.068-1.82 2.05 3.269 4.286c.64.857 1.887.958 2.646.201a1.741 1.741 0 0 0 0-2.453L9.986 8.882z" />
        <path d="M15.833 3.269a.591.591 0 0 0-.842 0l-3.202 3.193a.316.316 0 0 1-.438 0l-.27-.27a.314.314 0 0 1 0-.436l3.202-3.193a.587.587 0 0 0 0-.84.591.591 0 0 0-.843 0l-3.202 3.193a.316.316 0 0 1-.438 0l-.27-.27a.314.314 0 0 1 0-.436l3.203-3.193a.587.587 0 0 0 0-.84.591.591 0 0 0-.843 0L8.166 3.923a1.397 1.397 0 0 0 0 1.966l.219.219-7.82 6.907a1.684 1.684 0 0 0-.067 2.454l.034.034c.69.689 1.82.655 2.46-.068l6.926-7.798.22.219a1.405 1.405 0 0 0 1.971 0l3.742-3.73a.66.66 0 0 0-.018-.858z" />
      </g>
    </svg>
  );
}

export default FastingUtensils;
