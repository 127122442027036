import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton, CopyText } from 'components/common';
import { GenomPlusSurveyUiContextType } from './types';
import GenomPlusSurveyContext from './GenomPlusSurveyContext';

import s from './GenomPlusSurvey.module.scss';

function GenomPlusSurveyResults() {
  const { t } = useTranslation('genomplus');
  const { handleSurveyComplete } = useContext<GenomPlusSurveyUiContextType>(
    GenomPlusSurveyContext
  );
  const [isSaving, setSaving] = useState<boolean>(false);

  const completeSurvey = async () => {
    setSaving(true);

    try {
      await handleSurveyComplete();
    } catch (error) {
      setSaving(false);
    }
  };

  return (
    <>
      <div className={s.stepContent}>
        <div className={s.title}>{t(`survey.results.title`)}</div>

        <div className={s.subtitle}>{t(`survey.results.subtitle`)}</div>

        <div className={s.text}>{t(`survey.results.send_msg`)}</div>

        <div className={s.email}>
          <CopyText text={t(`email`)} />
        </div>
      </div>

      <div className={s.stepSubmit}>
        <SubmitButton
          className={s.stepSubmitButton}
          label={t('survey.next')}
          type="large"
          onClick={() => completeSurvey()}
          isLoading={isSaving}
        />
      </div>
    </>
  );
}

export default GenomPlusSurveyResults;
