/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import useTimer from 'easytimer-react-hook';
import Timer from 'easytimer.js';

import { ExpandedExercise } from 'types/workouts';

type Props = {
  exercise: ExpandedExercise;
};

type ReturnParams = {
  isTargetAchieved: boolean;
  exerciseTimer: Timer;
  startExercise: () => void;
  continuesExercise: () => void;
  pauseExercise: (
    isModalsOpen: boolean,
    isCountdownTimer: boolean,
    isOverlayOpen: boolean
  ) => void;
  handlePauseExercise: () => void;
  stopExercise: () => void;
};

export default function useExerciseTimer({ exercise }: Props): ReturnParams {
  const { duration } = exercise;
  const [isPaused, setPaused] = useState(false);
  const [isStarted, setStarted] = useState(false);
  const [timer, isTargetAchieved] = useTimer({
    updateWhenTargetAchieved: true,
  });

  const startExercise = () => {
    timer.start({
      countdown: true,
      startValues: { seconds: duration as number },
      target: { seconds: 0 },
    });
    timer.pause();
    setStarted(true);
  };

  const continuesExercise = () => {
    timer.start({
      countdown: true,
      startValues: { seconds: duration as number },
      target: { seconds: 0 },
    });
  };

  const stopExercise = () => {
    timer.stop();
    setPaused(false);
  };

  const handlePauseExercise = () => {
    if (isPaused) {
      timer.start();
      setPaused(false);
    } else {
      timer.pause();
      setPaused(true);
    }
  };

  const pauseExercise = (
    isModalsOpen: boolean,
    isCountdownTimer: boolean,
    isOverlayOpen: boolean
  ) => {
    if (!isStarted) return;

    if (isModalsOpen || isCountdownTimer || isOverlayOpen) {
      timer.pause();
    } else {
      timer.start();
    }
  };

  return {
    isTargetAchieved,
    exerciseTimer: timer,
    startExercise,
    pauseExercise,
    continuesExercise,
    handlePauseExercise,
    stopExercise,
  };
}
