import * as types from 'actions/actionTypes';

type PayloadAny = any;

export const logInAction = (payload: PayloadAny) => ({
  type: types.LOG_IN,
  payload,
});

export const logInSuccessAction = (payload: PayloadAny) => ({
  type: types.LOG_IN.SUCCESS,
  payload,
});

export const logInErrorAction = () => ({ type: types.LOG_IN.ERROR });

export const logOutSuccessAction = () => ({ type: types.LOG_OUT.SUCCESS });

export const logOutAction = () => ({
  type: types.LOG_OUT,
});

export const logOutErrorAction = () => ({ type: types.LOG_OUT.ERROR });

export const fbLogInAction = (payload: PayloadAny) => ({
  type: types.FACEBOOK_LOG_IN,
  payload,
});

export const loginRedirect = () => ({ type: types.LOGIN_REDIRECT });

export const showMergeModal = (payload: PayloadAny) => ({
  type: types.SHOW_MERGE_MODAL,
  payload,
});

export const hideMergeModal = () => ({
  type: types.HIDE_MERGE_MODAL,
});

export const fbEmailMerge = (payload: PayloadAny) => ({
  type: types.FACEBOOK_EMAIL_MERGE,
  payload,
});

export const fbMergePasswordMismatch = () => ({
  type: types.FACEBOOK_EMAIL_MERGE.ERROR,
});

export const fbMergeSuccess = () => ({
  type: types.FACEBOOK_EMAIL_MERGE.SUCCESS,
});

export const fbConnectAction = (payload: PayloadAny) => ({
  type: types.FACEBOOK_CONNECT,
  payload,
});

export const renewPasswordAction = (payload: PayloadAny) => ({
  type: types.PASSWORD_RENEW,
  payload,
});

export const renewPasswordSuccessAction = () => ({
  type: types.PASSWORD_RENEW_SUCCESS,
});

export const renewPasswordErrorAction = () => ({
  type: types.PASSWORD_RENEW_ERROR,
});

export const setNewPasswordAction = (payload: PayloadAny) => ({
  type: types.NEW_PASSWORD_SET,
  payload,
});

export const setNewPasswordSuccessAction = () => ({
  type: types.NEW_PASSWORD_SET_SUCCESS,
});

export const setNewPasswordErrorAction = () => ({
  type: types.NEW_PASSWORD_SET_ERROR,
});

export const validateResetTokenAction = (payload: PayloadAny) => ({
  type: types.RESET_TOKEN_VALIDATE,
  payload,
});

export function validateResetTokenSuccessAction() {
  return {
    type: types.RESET_TOKEN_VALIDATE_SUCCESS,
  };
}

export function validateResetTokenErrorAction() {
  return {
    type: types.RESET_TOKEN_VALIDATE_ERROR,
  };
}

export const directLogInAction = (
  payload: PayloadAny,
  offer_id: number,
  utm_params: PayloadAny,
  auto_chargeable: boolean
) => ({
  type: types.DIRECT_LOG_IN,
  payload,
  params: { offer_id, utm_params, auto_chargeable },
});

export const directLogInSuccessAction = () => ({
  type: types.DIRECT_LOG_IN_SUCCESS,
});

export const directLogInErrorAction = () => ({
  type: types.DIRECT_LOG_IN_ERROR,
});
