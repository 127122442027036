import React from 'react';

import { SVG } from 'types/svg';

function ShoppingBasket(props: SVG) {
  const { fill, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <g fill="none" fillRule="evenodd" opacity={0.87}>
        <path fill="none" d="M0 0h16v16H0z" />
        <path
          d="M9.6 1.333a.545.545 0 00-.473.282.54.54 0 00.03.55l2.956 4.508H3.888l2.956-4.507a.543.543 0 00.012-.579.543.543 0 00-.523-.248.542.542 0 00-.378.244l-3.34 5.09H.475a.555.555 0 00-.466.633c.464 2.508.95 4.97 1.422 7.46a.552.552 0 00.523.434h12.093a.55.55 0 00.522-.433l.545-2.842.005-.05.873-4.569c.058-.306-.21-.632-.522-.633h-2.085l-3.34-5.09a.542.542 0 00-.444-.25zM1.177 7.74H3.91v1.243H1.415l-.24-1.243zm3.801 0h2.49v1.243h-2.49V7.74zm3.557 0h2.49v1.243h-2.49V7.74zm3.557 0h2.734l-.24 1.243h-2.494V7.74zM1.615 10.05H3.91v1.243H1.854l-.24-1.244zm3.362 0h2.49v1.243h-2.49v-1.244zm3.557 0h2.49v1.243h-2.49v-1.244zm3.557 0h2.295l-.24 1.243h-2.055v-1.244zM2.054 12.357H3.91v1.776H2.393l-.34-1.776zm2.923 0h2.49v1.776h-2.49v-1.776zm3.557 0h2.49v1.776h-2.49v-1.776zm3.557 0h1.856l-.34 1.776h-1.516v-1.776z"
          fill={fill}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default ShoppingBasket;
