import * as types from 'actions/actionTypes';
import initialState from 'reducers/initialState';
import { updateBugsnagUser } from 'helpers/bugsnagHelper';

export default (state = initialState.user, action) => {
  switch (action.type) {
    case types.USER_LOAD.SUCCESS:
      updateBugsnagUser({ id: action?.user?.id });
      return { ...action.user };
    case types.UNSUBSCRIBE.SUCCESS:
    case types.MARK_AS_CHANCE.SUCCESS:
    case types.TOS_ACCEPT.SUCCESS:
    case types.USER_UPDATE.SUCCESS:
      return { ...action.user };
    case types.USER_PASSWORD_NEW.SUCCESS:
    case types.USER_EMAIL_UPDATE.SUCCESS:
      return { ...state, ...action.user };

    case types.LOG_OUT.SUCCESS:
      updateBugsnagUser(null);
      return initialState.user;

    case types.STORE_CLIENT_ID: {
      return {
        ...state,
        cid: action.cid,
      };
    }

    case types.USER_SET: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};
