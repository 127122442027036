import React from 'react';

import { FileExtension } from 'helpers/imageHelper';
import { ResponsiveImage } from 'components/common';

import s from './WorkoutsBackButton.module.scss';

type Props = {
  label: string;
  customStyle?: string;
  onClick: () => void;
};

function WorkoutsBackButton({ label, customStyle, onClick }: Props) {
  return (
    <button
      type="button"
      className={`${s.buttonNormalize} ${s.backButton} ${customStyle && customStyle}`}
      onClick={onClick}
    >
      <ResponsiveImage
        imagePath="./workouts/back"
        imageExtension={FileExtension.png}
        altText="back"
        style={s.backImage}
      />
      <span className={s.backLabel}>{label}</span>
    </button>
  );
}

export default WorkoutsBackButton;
