import { useEffect } from 'react';
import { getCurrentLocale } from 'i18n';
import { Primer, UniversalCheckoutOptions } from '@primer-io/checkout-web';
import '@primer-io/checkout-web/dist/Checkout.css';

import { User } from 'types/user';
import { Offer, Paymodes } from 'types/offer';
import { MetaData, PrimerInitEventType, PrimerSession } from 'types/primer';
import { CHECKOUT_STYLE } from 'helpers/primerHelper';
import { createAuditEvent } from 'helpers/auditEventHelper';

import { createPrimerSessionToken } from 'api/primerApi';

type Props = {
  user: User;
  offer: Offer;
  metaData: MetaData;
  primerEventType:
    | PrimerInitEventType.chosePaymode
    | PrimerInitEventType.offerRetried;
  cardSavePaymode: string;
  restrictInit: boolean;
  duplicateButton?: boolean;
};

function usePrimerCheckout({
  user,
  offer,
  metaData,
  primerEventType,
  cardSavePaymode,
  restrictInit,
  duplicateButton,
}: Props) {
  useEffect(() => {
    if (restrictInit || cardSavePaymode || duplicateButton) {
      return;
    }

    const initPrimer = async () => {
      const clientSession: PrimerSession = await createPrimerSessionToken(
        parseInt(user.id, 10),
        offer.id,
        metaData
      );

      const checkoutOptions: UniversalCheckoutOptions = {
        container: '#checkout-container',
        paymentHandling: 'AUTO',
        locale: getCurrentLocale() as any, // Typecast only because the BE and FE primer code isn't up-to-date. BE supports more languages
        style: CHECKOUT_STYLE,
        vault: { visible: false },
        onCheckoutComplete: ({ payment }): void => {
          createAuditEvent({
            source: user,
            target: offer,
            eventType: primerEventType,
            description: Paymodes.primer,
          });

          if (window.gtag) {
            window.gtag('event', 'Choose paymode', {
              event_category: 'Payments',
              event_label: Paymodes.primer,
            });
          }

          window.location.assign(
            `${window.location.origin}/postpayment?invoice_id=${clientSession.invoice.id}&identifier=${payment.id}`
          );
        },
        onCheckoutFail: (error, { payment }, handler) => {
          const paymentIdentifier = payment ? payment.id : '';

          createAuditEvent({
            source: user,
            target: offer,
            eventType: primerEventType,
            description: Paymodes.primer,
          });

          if (window.gtag) {
            window.gtag('event', 'Choose paymode', {
              event_category: 'Payments',
              event_label: Paymodes.primer,
            });
          }

          window.location.assign(
            `${window.location.origin}/postpayment?invoice_id=${clientSession.invoice.id}&identifier=${paymentIdentifier}`
          );
        },
      };

      await Primer.showUniversalCheckout(
        clientSession.invoice.session_identifier,
        checkoutOptions
      );
    };

    initPrimer();
  }, [
    user,
    offer,
    metaData,
    primerEventType,
    cardSavePaymode,
    restrictInit,
    duplicateButton,
  ]);

  return true;
}

export default usePrimerCheckout;
