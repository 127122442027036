import { auditEvent } from 'api/AuditEventAPI';

// eslint-disable-next-line import/prefer-default-export
export const createAuditEvent = (payload) =>
  auditEvent({
    audit_event: {
      trackable_source_id: payload.source.id,
      trackable_source_type: payload.source.type,
      trackable_target_id: payload.target ? payload.target.id : null,
      trackable_target_type: payload.target ? payload.target.type : null,
      event_type: payload.eventType,
      description: payload.description || null,
    },
  });
