import React from 'react';

import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';

type Props = {
  label: string;
  selectedOption: string;
  options: any;
  onChange: (e: any) => void;
  required: boolean;
};

type Option = {
  [key: string]: any;
};

function DropDown({
  label,
  options,
  selectedOption,
  onChange,
  required,
}: Props) {
  return (
    <div>
      <FormControl required={required} variant="outlined" fullWidth>
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
          labelId="select-label"
          label={label}
          value={selectedOption}
          onChange={(e) => onChange({ value: e.target.value })}
        >
          {(options as Option[]).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropDown;
