/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/self-closing-comp */
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { User } from 'types/user';
import { getLocaleDirection } from 'i18n';
import { getUserGoalWeight } from 'helpers/unsubscribeV8Helper';
import {
  formattedIdealWeeight,
  getMonthV3,
  getReachDate,
} from 'helpers/expressPlanHelper';

import ExpressPlanCarouselItem from './ExpressPlanCarouselItem';

import s from './ExpressPlanCarousel.module.scss';

const CAROUSEL_ITEMS = [
  'item_1',
  'item_2',
  'item_3',
  'item_4',
  'item_5',
  'item_6',
];

type Props = {
  user: User;
};

const CARD_WIDTH_ON_X_AXIS_DESKTOP = 35.8;
const CARD_WIDTH_ON_X_AXIS_MOBILE = 36.8;
const FIRST_FAKE_CARD_INDEX = -1;
const LAST_FAKE_CARD_INDEX = 9999;

function ExpressPlanCarousel({ user }: Props) {
  const { t } = useTranslation('expressplan');
  const carouselWrap = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [dragStart, setDragStart] = useState(false);
  const [initialX, setInitialX] = useState(null);
  const name = user.first_name;
  const goalWeight = getUserGoalWeight(user);
  const idealWeight = formattedIdealWeeight(user);
  const date = getReachDate(user);
  const reachDate = `${getMonthV3(
    date.getMonth()
  ).toUpperCase()}-${date.getFullYear()}`;
  const isRtlEnabled = getLocaleDirection() === 'rtl';
  const sign = isRtlEnabled ? '+' : '-';

  const getLinkComponent = (item: string) => {
    const linkDestination: { [key: string]: string } = {
      item_1: '',
      item_2: '/user',
      item_3: '',
      item_4: '/chat',
      item_5: '/premium',
      item_6: '',
    };

    return <Link to={linkDestination[item]} className={s.link} />;
  };

  const nextSlide = (nextSlideIndex: number) => {
    if (nextSlideIndex === CAROUSEL_ITEMS.length) {
      setActiveIndex(0);
      return;
    }

    setActiveIndex((prevValue) => prevValue + 1);
  };

  const prevSlide = (prevSlideIndex: number) => {
    if (prevSlideIndex < 0) {
      setActiveIndex(CAROUSEL_ITEMS.length - 1);
      return;
    }

    setActiveIndex((prevValue) => prevValue - 1);
  };

  const getSlideXCoordinates = () => {
    const isMobileDevice = window.innerWidth < 480;
    if (isMobileDevice) return sign + activeIndex * CARD_WIDTH_ON_X_AXIS_MOBILE;

    return sign + activeIndex * CARD_WIDTH_ON_X_AXIS_DESKTOP;
  };

  const handleCarouselSwipeOnLeave = (pageX: number) => {
    const scrollDirection = pageX > 180 ? 'right' : 'left';
    if (scrollDirection === 'right') {
      nextSlide(activeIndex + 1);
      setDragStart(false);

      return;
    }

    prevSlide(activeIndex - 1);
    setDragStart(false);
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    const { pageX } = e;
    handleCarouselSwipeOnLeave(pageX);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!dragStart) return;
    e.preventDefault();
    const { pageX } = e;

    (carouselWrap.current as HTMLDivElement).style.transform =
      `translateX(${sign}${pageX}px)`;
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!dragStart) return;
    if (e.changedTouches.length > 1) e.preventDefault();

    const { pageX } = e.touches[0];

    (carouselWrap.current as HTMLDivElement).style.transform =
      `translateX(-${pageX}px)`;
  };

  const onTouchEnd = () => {
    if (!initialX) return;
    handleCarouselSwipeOnLeave(initialX);
  };

  const onTouchStart = (e: any) => {
    const isLinkElement = e.target.tagName.toLowerCase() === 'a';
    const { pageX } = e.changedTouches[0];

    if (isLinkElement) {
      e.target.click();
      return;
    }

    setDragStart(true);
    setInitialX(pageX);
  };

  return (
    <div className={s.container}>
      <div className={s.carouselContaiener}>
        <div className={s.leftGradient}></div>
        <div className={s.carouselWrap}>
          <div
            ref={carouselWrap}
            className={s.innerWrap}
            style={{ transform: `translateX(${getSlideXCoordinates()}%)` }}
            onMouseDown={() => setDragStart(true)}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseLeave}
            onMouseLeave={() => setDragStart(false)}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchMove={handleTouchMove}
            role="presentation"
          >
            <ExpressPlanCarouselItem
              title={t(`express_plan_program.carousel_items.item_6.title`, {
                name,
              })}
              firstText={t(`express_plan_program.carousel_items.item_6.text`, {
                goalWeight,
                idealWeight,
                reachDate,
              })}
              imagePath="./express-plan/item_6"
              altText="carousel-first"
              itemIndex={FIRST_FAKE_CARD_INDEX}
              activeItemIndex={activeIndex}
              onClick={() => setActiveIndex(5)}
            />
            {CAROUSEL_ITEMS.map((item, index) => (
              <ExpressPlanCarouselItem
                key={item}
                title={t(`express_plan_program.carousel_items.${item}.title`, {
                  name,
                })}
                firstText={t(
                  `express_plan_program.carousel_items.${item}.text`,
                  {
                    goalWeight,
                    idealWeight,
                    reachDate,
                  }
                )}
                secondText={
                  <Trans
                    i18nKey={`expressplan:express_plan_program.carousel_items.${item}.text_2`}
                    components={[getLinkComponent(item)]}
                  />
                }
                imagePath={`./express-plan/${item}`}
                altText={`carousel-${item}`}
                itemIndex={index}
                activeItemIndex={activeIndex}
                onClick={() => setActiveIndex(index)}
              />
            ))}
            <ExpressPlanCarouselItem
              title={t(`express_plan_program.carousel_items.item_1.title`, {
                name,
              })}
              firstText={t(`express_plan_program.carousel_items.item_1.text`, {
                goalWeight,
                idealWeight,
                reachDate,
              })}
              secondText={
                <Trans
                  i18nKey="expressplan:express_plan_program.carousel_items.item_1.text_2"
                  components={[getLinkComponent('item_1')]}
                />
              }
              imagePath="./express-plan/item_1"
              altText="carousel-item_1"
              itemIndex={LAST_FAKE_CARD_INDEX}
              activeItemIndex={activeIndex}
              onClick={() => setActiveIndex(0)}
            />
          </div>
          <div className={s.rightGradient}></div>
        </div>
      </div>
      <div className={s.indicatorWrapper}>
        {CAROUSEL_ITEMS.map((indicatorItem, index) => (
          <button
            key={indicatorItem}
            type="button"
            className={`${s.indicator} ${
              activeIndex === index && s.activeIndicator
            }`}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default ExpressPlanCarousel;
