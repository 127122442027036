import TagManager from 'react-gtm-module';
import settings from 'helpers/settings';

const initializeGoogleTagManager = () => {
  const tagManagerArgs = {
    gtmId: settings.GTM_ID,
    auth: settings.GTM_AUTH,
    preview: settings.GTM_PREVIEW,
  };

  TagManager.initialize(tagManagerArgs);
};

export default initializeGoogleTagManager;
