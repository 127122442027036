import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { getStyle, isMaterialUI } from 'helpers/themeHelper';
import { SVGFillColors } from 'types/svg';

import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import SvgIcon from 'components/common/SvgIcon';

import base from './styles/MultiTextInput.module.scss';
import diet from './styles/MultiTextInput_diet.module.scss';
import keto from './styles/MultiTextInput_keto.module.scss';
import ketogo from './styles/MultiTextInput_ketogo.module.scss';
import fasting from './styles/MultiTextInput_fasting.module.scss';
import healthgenom from './styles/MultiTextInput_healthgenom.module.scss';

const s = getStyle(base, diet, keto, ketogo, fasting, healthgenom);

export default class MultiTextInput extends PureComponent {
  static propTypes = {
    name1: PropTypes.string.isRequired,
    name2: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    placeholder1: PropTypes.string.isRequired,
    placeholder2: PropTypes.string.isRequired,
    value1: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    value2: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    type: PropTypes.string,
    svgIcon: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    inputType: PropTypes.string,
    onKeyPress: PropTypes.func,
    maxLength: PropTypes.number,
  };

  static defaultProps = {
    type: 'text',
    value1: '',
    value2: '',
  };

  getWrapperClass = () => {
    const { error, label, inputType } = this.props;
    let wrapperClass = 'form';
    if (error && error.length > 0) wrapperClass += ' has-error';
    if (label) wrapperClass += ' with-label';
    if (inputType) wrapperClass += ` ${inputType}`;
    return wrapperClass;
  };

  getInputLabel = () => {
    const { label } = this.props;
    if (label)
      return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className={s.label}>{label}</label>
      );
    return null;
  };

  getValidationMessage = () => {
    const { error } = this.props;
    if (typeof error === 'string')
      return <span className="validation_error">{error}</span>;
    return null;
  };

  render = () => {
    const {
      svgIcon,
      type,
      value1,
      value2,
      name1,
      name2,
      placeholder1,
      placeholder2,
      onChange,
      onKeyPress,
      maxLength,
      label,
      min1,
      max1,
      min2,
      max2,
      pattern,
      required,
      inputMode,
    } = this.props;

    if (isMaterialUI()) {
      return (
        <>
          <FormControl variant="outlined">
            {label && <InputLabel id={`${name1}_label`}>{label}</InputLabel>}
            <OutlinedInput
              inputType={type}
              name={name1}
              value={value1}
              onChange={onChange}
              endAdornment={
                <InputAdornment position="end">{placeholder1}</InputAdornment>
              }
              label={label}
              labelId={`${name1}_label`}
              onKeyPress={onKeyPress}
              maxLength={maxLength}
              inputProps={{
                type,
                min: min1,
                max: max1,
                pattern,
                required,
                inputMode,
              }}
            />
          </FormControl>
          <FormControl variant="outlined">
            <OutlinedInput
              inputType={type}
              name={name2}
              value={value2}
              onChange={onChange}
              endAdornment={
                <InputAdornment position="end">{placeholder2}</InputAdornment>
              }
              labelWidth={0}
              onKeyPress={onKeyPress}
              maxLength={maxLength}
              inputProps={{
                type,
                min: min2,
                max: max2,
                pattern,
                required,
                inputMode,
              }}
            />
          </FormControl>
        </>
      );
    }

    return (
      <div className={this.getWrapperClass()}>
        {this.getInputLabel()}
        <div className="form__input text">
          {svgIcon && (
            <SvgIcon
              filename={svgIcon}
              fill={SVGFillColors.gray}
              className="form__input__input_img"
            />
          )}
          <input
            type={type}
            name={name1}
            className={`form__input__text default first ${s.input}`}
            placeholder={placeholder1}
            value={value1}
            onChange={onChange}
            onKeyPress={onKeyPress}
            maxLength={maxLength}
          />
          <input
            type={type}
            name={name2}
            className={`form__input__text default second ${s.input}`}
            placeholder={placeholder2}
            value={value2}
            onChange={onChange}
            onKeyPress={onKeyPress}
            maxLength={maxLength}
          />
        </div>
        {this.getValidationMessage()}
      </div>
    );
  };
}
