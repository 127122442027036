import { getCurrentLocale } from 'i18n';
import { intlFormat } from 'date-fns';

import { User } from 'types/user';
import { Subscription } from 'types/subscription';
import {
  ALLOWED_STEPS,
  ModalSteps,
  MinUserLoseWeight,
  QuizLinks,
} from 'types/quiz';
import settings from 'helpers/settings';
import { userHasPremium, isLocaleRestrictedInKetoPremium } from './userHelper';
import { isUnsubscribeLocked } from './subscriptionHelper';

export const isValidStep = (step: string): boolean =>
  ALLOWED_STEPS.includes(step);

export const getCurrentDayName = () =>
  intlFormat(new Date(), { weekday: 'long' }, { locale: 'en' }).toLowerCase();

export const isUnsubscribeV6AvailableForLocale = () => {
  if (!settings.SHOW_UNSUBSCRIBEV6) {
    return false;
  }

  const disallowedLocales = ['lv', 'et', 'lt'];
  const currentLocale = getCurrentLocale();

  if (disallowedLocales.includes(currentLocale)) {
    return false;
  }

  return true;
};

export const getRedirectURL = (modalStep: ModalSteps, modal = true) => {
  switch (modalStep) {
    case ModalSteps.quote:
      return modal ? '/program?unsubscribev6-success-1' : '/program';
    case ModalSteps.meal_survey:
      return modal
        ? '/user/meal_survey/edit?unsubscribev6-success-2'
        : '/user/meal_survey/edit';
    case ModalSteps.premium_upsell:
      return modal ? '/premium?unsubscribev6-success-3a' : '/premium';
    case ModalSteps.lock:
      return modal
        ? '/user/account/edit?unsubscribev6-rejection-premium'
        : '/program';
    case ModalSteps.investment:
      return modal ? '/premium?unsubscribev6-success-3b' : '/premium';
    default:
      return '/';
  }
};

export const isParameterInQueryString = (queryString: string) =>
  window.location.search.includes(queryString);

export const getUserWeightTolose = (
  minWeight: MinUserLoseWeight,
  userWeight: number
) => Math.max(minWeight, userWeight);

export const getUsersThirdStepLink = (user: User) => {
  const { subscription } = user;

  if (!settings.SHOW_PREMIUM) {
    return '/unsubscribe/unsubscribev4-step-1';
  }

  if (isLocaleRestrictedInKetoPremium()) {
    return '/unsubscribe/unsubscribev4-step-1';
  }

  if (
    userHasPremium(user) &&
    !isUnsubscribeLocked(subscription as Subscription)
  ) {
    return QuizLinks.time_investment;
  }

  return QuizLinks.tried_premium_upsell;
};

export const isUnsubscribeV6FlowAvailable = (step: string) => {
  if (!settings.SHOW_UNSUBSCRIBEV6) {
    return false;
  }
  if (!isValidStep(step)) {
    return false;
  }
  if (!isUnsubscribeV6AvailableForLocale()) {
    return false;
  }

  return true;
};
