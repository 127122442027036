import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserStatistics } from 'types/user';

import s from './Statistics.module.scss';

type Props = {
  stats: UserStatistics;
};

const Statistics: React.FC<Props> = ({
  stats,
  stats: { unit_system: unitSystem },
}) => {
  const { t } = useTranslation('stats');

  const getWeight = (amount: keyof UserStatistics) => {
    const value =
      stats[
        unitSystem === 'si'
          ? amount
          : (`${amount}_in_lbs` as keyof UserStatistics)
      ];
    return (
      <>
        {value}
        <span className={s.unit}>
          {t('units.weight', { context: unitSystem })}
        </span>
      </>
    );
  };

  return (
    <section className={s.container}>
      <div className={s.item}>
        <div className={s.itemContent}>
          <span className={s.weight}>{getWeight('lose')}</span>
          <span className={s.label}>{t('stats:lose')}</span>
        </div>
      </div>
      {stats.lost > 0 && (
        <div className={s.item}>
          <div className={s.itemContent}>
            <span className={s.weight}>{getWeight('lost')}</span>
            <span className={s.label}>{t('stats:lost')}</span>
          </div>
        </div>
      )}
      <div className={s.item}>
        <div className={s.itemContent}>
          <span className={s.weight}>{getWeight('goal')}</span>
          <span className={s.label}>{t('stats:goal')}</span>
        </div>
      </div>
      <div className={s.item}>
        <div className={s.itemContent}>
          <span className={s.weight}>{getWeight('current')}</span>
          <span className={s.label}>{t('stats:current')}</span>
        </div>
      </div>
    </section>
  );
};

export default Statistics;
