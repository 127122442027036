import React from 'react';
import useTheme from 'helpers/themeHook';

import base from './styles/FlashMessage.module.scss';
import diet from './styles/FlashMessage_diet.module.scss';
import keto from './styles/FlashMessage_keto.module.scss';
import ketogo from './styles/FlashMessage_ketogo.module.scss';
import fasting from './styles/FlashMessage_fasting.module.scss';
import healthgenom from './styles/FlashMessage_healthgenom.module.scss';

type Props = {
  message: {
    text: string;
  };
};

const SuccessMessage: React.FC<Props> = ({ message: { text } }) => {
  const [s] = useTheme({ base, diet, keto, ketogo, fasting, healthgenom });

  return <div className={`flash_message__msg ${s.success}`}>{text}</div>;
};

export default SuccessMessage;
