import React from 'react';
import PropTypes from 'prop-types';
import browserHistory from 'helpers/history';
import { connect } from 'react-redux';
import { logOutAction } from 'actions/sessionActions';
import i18n, { getCurrentLocale } from 'i18n';

class EnsureCleanStoreIfNeeded extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    logOutAction: PropTypes.func.isRequired,
    children: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const userLocale = props.user?.locale;

    if (userLocale && userLocale !== getCurrentLocale()) {
      i18n.changeLanguage(userLocale);
    }
  }

  render() {
    const { user, logOutAction, children } = this.props;
    if (
      localStorage.current_user_id &&
      user.id &&
      user.id !== localStorage.current_user_id
    ) {
      localStorage.removeItem('current_user_id');
      logOutAction();
      browserHistory.replaceLater('/program');
      return window.location.reload();
    }
    return children;
  }
}

const mapStateToProps = (state) => ({
  user: state.rootReducer.user,
});

const mapDispatchToProps = {
  logOutAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnsureCleanStoreIfNeeded);
