import React from 'react';

import { SVG } from 'types/svg';

function LogoKetogo(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zM8.795 7.359v9.282l8.197-4.64-8.197-4.642z"
          fill="#264653"
        />
      </g>
    </svg>
  );
}

export default LogoKetogo;
