import React from 'react';

import { SVG } from 'types/svg';

function Premium(props: SVG) {
  const { fill, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <defs>
        <path
          id="premium__a"
          d="M14.794 15.2v.8H1.204v-.8h13.59zM16 3.52L14.794 14.4H1.204L0 3.52l4.354 5.751 3.776-5.44 3.775 5.44L16 3.52zM8 8.76l-1.4 1.915L8 12.59l1.4-1.915L8 8.76zM12.57 0c0 .75.298 1.47.829 2 .53.531 1.25.829 2 .829a2.831 2.831 0 00-2.829 2.832 2.832 2.832 0 00-2.832-2.832A2.834 2.834 0 0012.57 0zM3.793 2.433A1.555 1.555 0 005.35 3.99a1.556 1.556 0 00-1.557 1.56 1.56 1.56 0 00-1.56-1.56 1.56 1.56 0 001.56-1.557z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="premium__b" fill={fill}>
          <use xlinkHref="#premium__a" />
        </mask>
        <g fill={fill} mask="url(#premium__b)">
          <path d="M-1.6-1.6h19.2v19.2H-1.6z" />
        </g>
      </g>
    </svg>
  );
}

export default Premium;
