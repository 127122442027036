import React from 'react';

function ContextualNutrientsHealthgenom() {
  return (
    <svg width={104} height={104} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="prefix__4045a2ba-4e20-4d00-97bd-83d307ac7bc6"
        >
          <stop stopColor="#FFE066" offset="0%" />
          <stop stopColor="#D88D28" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          fill="url(#prefix__4045a2ba-4e20-4d00-97bd-83d307ac7bc6)"
          cx={52}
          cy={52}
          r={52}
        />
        <g fill="#FFF" fillRule="nonzero">
          <path d="m35.588 71.75-4.706 4.706h-4.744v2.069h5.172c.275 0 .538-.11.732-.304l5.02-5.02-1.474-1.451zm32.947-36.229 4.583-4.583h4.744V28.87H72.69c-.275 0-.538.11-.732.303l-5.247 5.249 1.824 1.1zM22 76.456h2.069v2.069H22zM79.931 28.87H82v2.068h-2.069zM62.345 63.007v-3.103a1.034 1.034 0 0 1 1.609-.86l4.793 3.195 1.706-3.803a25.54 25.54 0 0 0 2.237-10.398 14.427 14.427 0 0 0-1.525-6.445A11.69 11.69 0 0 0 67.057 37l-5.015 5.016-1.463-1.463 4.56-4.56a11.74 11.74 0 0 0-11.863 1.66L52 38.676v-1.458a16.52 16.52 0 0 1 4.87-11.755L55.406 24a18.575 18.575 0 0 0-5.476 13.218v1.458l-1.277-1.024a11.822 11.822 0 0 0-7.35-2.576 11.69 11.69 0 0 0-9.749 5.173h10.1a3.104 3.104 0 0 1 3.104 3.103v12.414a3.104 3.104 0 0 1-3.104 3.103h-2.069v3.104a1.035 1.035 0 0 1-1.609.86l-5.942-3.964h-.358l4.408 9.817c.262.568.591 1.101.98 1.59l4.896-4.897 1.463 1.463-4.885 4.885a7.863 7.863 0 0 0 7.275 1.222l4.828-1.612a1 1 0 0 1 .653 0l4.831 1.614c.342.113.692.202 1.046.266V66.11a3.104 3.104 0 0 1 3.104-3.104h2.069z" />
          <path d="m32.92 56.974 4.597 3.065v-2.204c0-.571.463-1.035 1.035-1.035h3.103c.571 0 1.035-.463 1.035-1.034V43.352c0-.571-.464-1.034-1.035-1.034h-18.62c-.572 0-1.035.463-1.035 1.034v12.414c0 .571.463 1.034 1.034 1.034h9.31c.205 0 .405.061.575.174zm-2.644-12.587H40.62v2.069H30.276v-2.07zm-4.138 0h2.069v2.069h-2.07v-2.07zm-2.069 4.138h16.552v2.068H24.069v-2.068zm0 4.137h12.414v2.07H24.069v-2.07zm54.828 12.414h-9.31c-.205 0-.405-.06-.575-.174l-4.598-3.065v2.205c0 .571-.463 1.034-1.035 1.034h-3.103c-.571 0-1.035.463-1.035 1.035v12.414c0 .57.464 1.034 1.035 1.034h18.62c.572 0 1.035-.463 1.035-1.034V66.11c0-.572-.463-1.035-1.034-1.035zm-15.518 2.07h2.07v2.068h-2.07v-2.069zM73.724 77.49H61.31v-2.069h12.414v2.069zm4.138-4.138H61.31v-2.069h16.552v2.07zm0-4.138H67.517v-2.069h10.345v2.07zM50.37 31.9a8.296 8.296 0 0 0-7.094-7.095A8.296 8.296 0 0 0 50.37 31.9z" />
        </g>
      </g>
    </svg>
  );
}

export default ContextualNutrientsHealthgenom;
