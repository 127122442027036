import * as types from 'actions/actionTypes';
import {
  getWorkoutData,
  markWorkoutAsCompleted,
  markWorkoutAsDraft,
  submitWorkoutData,
  adjustWorkoutDifficulty,
} from 'api/WorkoutAPI';
import ResponseHandler from 'actions/ResponseHandler';
import {
  getUserCurrentDayDataAction,
  getCalendarDataAction,
} from 'actions/programActions';
import browserHistory from 'helpers/history';
import { addError } from 'redux-flash-messages';
import { hasGhostExercise, hasNoExercise } from 'helpers/programHelper';
import { isLoading, isNotLoading } from 'actions/loadingActions';
import { ThunkActionType } from 'store/types';

type PayloadAny = any;

export const loadWorkoutBeginAction = () => ({
  type: types.WORKOUT_LOAD.ACTION,
});

export const loadWorkoutSuccessAction = (workout: PayloadAny) => ({
  type: types.WORKOUT_LOAD.SUCCESS,
  workout,
});

export const loadWorkoutErrorAction = (error: PayloadAny) => ({
  type: types.WORKOUT_LOAD.ERROR,
  error,
});

export const clearWorkout = () => ({ type: types.WORKOUT_CLEAR });

export const getWorkoutDataAction =
  (dayId: number, workoutId: number): ThunkActionType =>
  (dispatch) => {
    dispatch(loadWorkoutBeginAction());
    dispatch(clearWorkout());
    return getWorkoutData(dayId, workoutId)
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) throw resHandler.getFlashMsgText();
        else
          dispatch(
            loadWorkoutSuccessAction(resHandler.getNormalizedResponse())
          );
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(loadWorkoutErrorAction(error));
      });
  };

export const markWorkoutAsCompleteBeginAction = () => ({
  type: types.WORKOUT_COMPLETE.ACTION,
});

export const markWorkoutAsCompleteSuccessAction = (
  workout: PayloadAny,
  noRedirect?: boolean
) => {
  if (hasGhostExercise(workout) || hasNoExercise(workout)) {
    browserHistory.pushLater('/program');
  } else if (!noRedirect) {
    browserHistory.pushLater(`/program/workout/progress/${workout.id}`);
  }
  return { type: types.WORKOUT_COMPLETE.SUCCESS, workout };
};

export const markWorkoutAsCompleteErrAction = (error: PayloadAny) => ({
  type: types.WORKOUT_COMPLETE.ERROR,
  error,
});

type MarkWorkoutAsCompleteParams = {
  dayId: number;
  workoutId: number;
  noRedirect?: boolean;
};

export const markWorkoutAsCompletedAction =
  ({
    dayId,
    workoutId,
    noRedirect,
  }: MarkWorkoutAsCompleteParams): ThunkActionType =>
  (dispatch) => {
    dispatch(markWorkoutAsCompleteBeginAction());
    dispatch(isLoading());
    return markWorkoutAsCompleted(dayId, workoutId)
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) throw resHandler.getFlashMsgText();
        else {
          Promise.resolve(
            dispatch(
              markWorkoutAsCompleteSuccessAction(
                resHandler.getNormalizedResponse(),
                noRedirect
              )
            )
          ).then(() => {
            dispatch(getCalendarDataAction());
            dispatch(getUserCurrentDayDataAction());
          });
        }
        dispatch(isNotLoading());
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(markWorkoutAsCompleteErrAction(error));
        dispatch(isNotLoading());
      });
  };

export const markWorkoutAsDraftBeginAction = () => ({
  type: types.WORKOUT_DRAFT.ACTION,
});

export const markWorkoutAsDraftSuccessAction = (workout: PayloadAny) => ({
  type: types.WORKOUT_DRAFT.SUCCESS,
  workout,
});

export const markWorkoutAsDraftErrorAction = (error: PayloadAny) => ({
  type: types.WORKOUT_DRAFT.ERROR,
  error,
});

export const markWorkoutAsDraftAction =
  (dayId: number, workoutId: number): ThunkActionType =>
  (dispatch) => {
    dispatch(markWorkoutAsDraftBeginAction());
    dispatch(isLoading());
    return markWorkoutAsDraft(dayId, workoutId)
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) throw resHandler.getFlashMsgText();
        else {
          Promise.resolve(
            dispatch(
              markWorkoutAsDraftSuccessAction(
                resHandler.getNormalizedResponse()
              )
            )
          ).then(() => {
            Promise.all([
              dispatch(getUserCurrentDayDataAction()),
              dispatch(getCalendarDataAction()),
            ]).then(() => {
              browserHistory.pushLater(
                `/program/${dayId}/workout/${workoutId}`
              );
            });
          });
        }
        dispatch(isNotLoading());
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(markWorkoutAsDraftErrorAction(error));
        dispatch(isNotLoading());
      });
  };

export const workoutDataSubmitBeginAction = () => ({
  type: types.WORKOUT_DATA_SUBMIT.ACTION,
});

export const workoutDataSubmitSuccessAction = () => ({
  type: types.WORKOUT_DATA_SUBMIT.SUCCESS,
});

export const workoutDataSubmitErrorAction = (error: PayloadAny) => ({
  type: types.WORKOUT_DATA_SUBMIT.ERROR,
  error,
});

export const submitWorkoutDataAction =
  (dayId: number, workoutId: number, workout: PayloadAny): ThunkActionType =>
  (dispatch) => {
    dispatch(workoutDataSubmitBeginAction());
    return submitWorkoutData(dayId, workoutId, { workout })
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) throw resHandler.getFlashMsgText();
        else {
          dispatch(workoutDataSubmitSuccessAction());
          dispatch(
            loadWorkoutSuccessAction(resHandler.getNormalizedResponse())
          );
        }
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(workoutDataSubmitErrorAction(error));
      });
  };

export const adjustWorkoutDifficultyAction =
  (dayId: number, workoutId: number, adjustment: PayloadAny): ThunkActionType =>
  () =>
    adjustWorkoutDifficulty(dayId, workoutId, adjustment)
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) {
          addError({ text: resHandler.getFlashMsgText() });
        }
      })
      .catch((error) => error);

export const discardWorkout =
  (dayId: number, workoutId: number): ThunkActionType =>
  (dispatch) => {
    dispatch(markWorkoutAsDraftBeginAction());
    dispatch(isLoading());
    return markWorkoutAsDraft(dayId, workoutId)
      .then((response) => {
        const resHandler = new ResponseHandler(response);
        if (resHandler.hasError()) throw resHandler.getFlashMsgText();
        else {
          Promise.resolve(
            dispatch(
              markWorkoutAsDraftSuccessAction(
                resHandler.getNormalizedResponse()
              )
            )
          ).then(() => {
            Promise.all([
              dispatch(getUserCurrentDayDataAction()),
              dispatch(getCalendarDataAction()),
            ]);
          });
        }
        dispatch(isNotLoading());
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(markWorkoutAsDraftErrorAction(error));
        dispatch(isNotLoading());
      });
  };
