import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { User } from 'types/user';
import { ItemType } from 'types/payment';

import { getActiveSubscriptionByItemType } from 'helpers/userHelper';
import { isKetoChocolateBarsAllowed } from 'helpers/ketoChocolateBarsHelper';

type Props = {
  children: React.ReactNode;
};

function EnsureKetoChocolateBarsAllowed({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const activeSubscription = getActiveSubscriptionByItemType(
    user,
    ItemType.ketoChocolateBars
  );

  if (!isKetoChocolateBarsAllowed(user)) {
    return <Redirect to="/program" />;
  }
  if (!activeSubscription) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
}

export default EnsureKetoChocolateBarsAllowed;
