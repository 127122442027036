import { useState, useEffect } from 'react';

type Props = {
  timer: number;
  stopAtEnd?: boolean;
};

function useInfiniteTimer({ timer, stopAtEnd }: Props) {
  const [seconds, setSeconds] = useState(timer);

  const addSeconds = (secondsToAdd: number) => {
    setSeconds((prevSecond) => prevSecond + secondsToAdd);
  };

  useEffect(() => {
    let timerValue: any;

    const stopTimer = () => {
      clearInterval(timerValue);
    };

    // Run timer until 0 seconds
    if (timer && seconds > 0) {
      timerValue = setInterval(() => {
        setSeconds((prevSecond) => prevSecond - 1);
      }, 1000);
    }

    if (seconds === 0) {
      // Stop timer if stopAtEnd is true
      if (stopAtEnd) {
        stopTimer();
        return;
      }

      // Reset timer
      setSeconds(timer);
    }

    // Remove interval
    // eslint-disable-next-line consistent-return
    return () => {
      stopTimer();
    };
  }, [timer, seconds, stopAtEnd]);

  return { seconds, addSeconds };
}

export default useInfiniteTimer;
