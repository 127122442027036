import React from 'react';

function UnsubscribeV6MealSurveyHealthgenom() {
  return (
    <svg
      width={104}
      height={104}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="prefix__102d1bb0-9255-4132-a478-dc514f95e35e"
        >
          <stop stopColor="#FFE066" offset="0%" />
          <stop stopColor="#D88D28" offset="100%" />
        </linearGradient>
        <path
          id="prefix__56d68507-35d1-44e0-a96e-67f22e715816"
          d="M0 0h60v60H0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          fill="url(#prefix__102d1bb0-9255-4132-a478-dc514f95e35e)"
          cx={52}
          cy={52}
          r={52}
        />
        <g transform="translate(22 22)">
          <mask id="prefix__c7cb3396-e06a-4cba-a809-97bb07fab1c1" fill="#fff">
            <use xlinkHref="#prefix__56d68507-35d1-44e0-a96e-67f22e715816" />
          </mask>
          <path
            d="M45.625 25.625a1.889 1.889 0 0 1-1.875-1.875c0-1.375-1.125-2.5-2.5-2.5a2.507 2.507 0 0 0-2.5 2.5c0 1.025-.85 1.875-1.875 1.875A1.889 1.889 0 0 1 35 23.75c0-3.45 2.8-6.25 6.25-6.25s6.25 2.8 6.25 6.25c0 1.025-.85 1.875-1.875 1.875m1.575 12.4c-3.7 5.75-9.875 9.25-16.575 9.45-.2.025-.425.025-.625.025-3.6 0-7.075-.95-10.1-2.725a2.732 2.732 0 0 1-.375-.2c-2.65-1.6-4.975-3.8-6.725-6.55-.55-.85-.3-2.025.55-2.575a1.867 1.867 0 0 1 2.6.55c.225.35.475.7.725 1.025.625.85 1.325 1.625 2.075 2.325v-2.475c0-3.8 3.075-6.875 6.875-6.875 1.85 0 3.575.725 4.875 2.05 1.275 1.25 2 2.975 2 4.825v6.675c4.275-.65 8.15-2.975 10.825-6.525.25-.325.5-.675.725-1.025a1.868 1.868 0 0 1 2.6-.55c.85.55 1.1 1.725.55 2.575M18.75 17.5c3.45 0 6.25 2.8 6.25 6.25 0 1.025-.85 1.875-1.875 1.875a1.889 1.889 0 0 1-1.875-1.875c0-1.375-1.125-2.5-2.5-2.5a2.507 2.507 0 0 0-2.5 2.5c0 1.025-.85 1.875-1.875 1.875A1.889 1.889 0 0 1 12.5 23.75c0-3.45 2.8-6.25 6.25-6.25M30 0C13.45 0 0 13.45 0 30s13.45 30 30 30 30-13.45 30-30S46.55 0 30 0"
            fill="#FFF"
            mask="url(#prefix__c7cb3396-e06a-4cba-a809-97bb07fab1c1)"
          />
        </g>
        <path
          d="M47.625 55.249a.82.82 0 0 0-.82.82v8.588a.82.82 0 1 0 1.64 0V56.07a.82.82 0 0 0-.82-.82"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default UnsubscribeV6MealSurveyHealthgenom;
