import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import { saveGenomPlusSurvey } from 'actions/genomPlusSurvey';

import {
  GenomPlusSurveyAnswersType,
  GenomPlusSurveyUiContextType,
  GenomPlusSurveyUiStepType,
} from './types';

import GenomPlusSurveyStep from './GenomPlusSurveyStep';
import GenomPlusSurveyContext from './GenomPlusSurveyContext';
import GenomPlusSurveyQuestion from './GenomPlusSurveyQuestion';
import GenomPlusSurveyResults from './GenomPlusSurveyResults';
import GenomPlusSurveyCompleted from './GenomPlusSurveyCompleted';

const steps: GenomPlusSurveyUiStepType[] = [
  {
    urlSlug: 'q1',
    component: GenomPlusSurveyQuestion,
    questions: ['sleepy_after_eating'],
  },
  {
    urlSlug: 'q2',
    component: GenomPlusSurveyQuestion,
    questions: ['skin_breakouts'],
  },
  {
    urlSlug: 'q3',
    component: GenomPlusSurveyQuestion,
    questions: ['how_long_fall_asleep'],
  },
  {
    urlSlug: 'q4',
    component: GenomPlusSurveyQuestion,
    questions: ['dizziness'],
  },
  {
    urlSlug: 'q5',
    component: GenomPlusSurveyQuestion,
    questions: ['sudden_heart_palpitations'],
  },
  {
    urlSlug: 'q6',
    component: GenomPlusSurveyQuestion,
    questions: ['added_salt_every_food'],
  },
  {
    urlSlug: 'q7',
    component: GenomPlusSurveyQuestion,
    questions: ['swollen_legs'],
  },
  {
    urlSlug: 'q8',
    component: GenomPlusSurveyQuestion,
    questions: ['doctor_told_lose_weight'],
  },
  {
    urlSlug: 'q9',
    component: GenomPlusSurveyQuestion,
    questions: ['bruise_easily'],
  },
  {
    urlSlug: 'q10',
    component: GenomPlusSurveyQuestion,
    questions: ['wake_up_thristy'],
  },
  {
    urlSlug: 'q11',
    component: GenomPlusSurveyQuestion,
    questions: ['high_blood_pressure'],
  },
  {
    urlSlug: 'send-results',
    component: GenomPlusSurveyResults,
  },
  {
    urlSlug: 'completed',
    component: GenomPlusSurveyCompleted,
  },
];

const defaultAnswers = {
  sleepy_after_eating: null,
  skin_breakouts: null,
  how_long_fall_asleep: null,
  dizziness: null,
  sudden_heart_palpitations: null,
  added_salt_every_food: null,
  swollen_legs: null,
  doctor_told_lose_weight: null,
  bruise_easily: null,
  wake_up_thristy: null,
  high_blood_pressure: null,
};

function GenomPlusSurvey() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [answers, setAnswers] =
    useState<GenomPlusSurveyAnswersType>(defaultAnswers);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

  // Redirect to the first step, if previous anwers are missing
  useEffect(() => {
    if (activeStepIndex === 0) return;

    const prevSteps = steps.slice(0, activeStepIndex);
    const isPrevAnswerMissing = prevSteps.some((step) =>
      step.questions?.some((question) => answers[question] === null)
    );

    if (isPrevAnswerMissing) history.push(steps[0].urlSlug);
  }, [activeStepIndex, answers, history]);

  const handleStepBack = () => {
    if (activeStepIndex === 0) {
      history.goBack();
      return;
    }

    const prevStep = steps[activeStepIndex - 1];

    history.push(prevStep.urlSlug);
  };

  const handleStepComplete = (updatedAnswers?: GenomPlusSurveyAnswersType) => {
    if (updatedAnswers) {
      setAnswers({
        ...answers,
        ...updatedAnswers,
      });
    }

    const nextStep = steps[activeStepIndex + 1];

    if (nextStep) {
      history.push(nextStep.urlSlug);
    } else {
      history.push('/program');
    }
  };

  const handleSurveyComplete = async () => {
    // Loading and error states are handled locally within a
    // responsible component, for better UX and performance
    await dispatch(saveGenomPlusSurvey(answers));
    handleStepComplete();
  };

  const state: GenomPlusSurveyUiContextType = {
    answers,
    steps,
    activeStepIndex,
    setActiveStepIndex,
    handleStepBack,
    handleStepComplete,
    handleSurveyComplete,
  };

  return (
    <GenomPlusSurveyContext.Provider value={state}>
      <Switch>
        {steps.map((step, index) => (
          <Route exact path={`${path}/${step.urlSlug}`} key={step.urlSlug}>
            <GenomPlusSurveyStep stepIndex={index} />
          </Route>
        ))}
        <Route path="*">
          <Redirect to={`${path}/${steps[0].urlSlug}`} />
        </Route>
      </Switch>
    </GenomPlusSurveyContext.Provider>
  );
}

export default GenomPlusSurvey;
