import React from 'react';
import { useTranslation } from 'react-i18next';

import { Benefits } from 'types/days';

import BenefitSVGs from 'assets/images/program/benefits';

import s from './WorkoutBenefit.module.scss';

type Props = {
  benefit: Benefits;
};

function WorkoutBenefit({ benefit }: Props) {
  const { t } = useTranslation();

  if (!benefit || !BenefitSVGs[benefit]) return null;

  return (
    <div className={s.benefit}>
      <img className={s.benefitSvg} src={BenefitSVGs[benefit]} alt={benefit} />
      <span className={s.benefitText}>{t(`benefits.${benefit}`)}</span>
    </div>
  );
}

export default WorkoutBenefit;
