import { PurchaseItem } from './offer';

export type Invoice = {
  amount: number;
  amount_in_euro: number;
  card?: Card;
  chance: boolean;
  completed_at: string;
  currency_id: number;
  currency_name: string;
  id: number;
  item_type: ItemType;
  offer_id: number;
  payment_type: number;
  paymode_id: number;
  paymode_name: string;
  retry: boolean;
  status: string;
  type: string;
  user_id: number;
  custom_data: CustomData;
  purchase_item: PurchaseItem;
};

type CustomData = {
  [key: string]: string;
};

export type Card = {
  id: number;
  last_digits: string;
  tos_accepted_at: string;
};

export type PaymentUrl = {
  paymode: string;
  userId: string;
  offerId: number;
  queryParams: PaymentUrlQueryParams;
};

export type PaymentUrlQueryParams = {
  origin_host: string;
  chance: boolean;
  failed_payment_path?: string;
  purchase_source?: string;
  shipping_address_id?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  ga_session_id?: number;
};

export enum PaymentType {
  recurring = 0,
  single = 1,
}

export enum ItemType {
  pro = 'pro_subscription',
  premiumUpsell = 'premium_upsell',
  shoppingList = 'shopping_list',
  fatBurner = 'fat_burner',
  chat = 'chat',
  acvGummies = 'acv_gummies',
  collagen = 'collagen',
  mealSwitch = 'meal_switch',
  tricks = 'tricks',
  genom = 'genom',
  genomBloodTest = 'genom_blood_test',
  genomSalivaTest = 'genom_saliva_test',
  genomMicroTest = 'genom_micro_test',
  expressPlanDelivery = 'express_plan_delivery',
  earlyCancellationFee = 'early_cancellation_fee',
  workouts = 'workouts',
  ketoChocolateBars = 'keto_chocolate_bars',
}

export enum Frequency {
  month = 30,
  threeMonth = 90,
  sixMonth = 180,
  year = 365,
}
