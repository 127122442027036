import React from 'react';

import { SVG } from 'types/svg';

function PaymentFailedEmoji(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={56} height={56} xmlns="http://www.w3.org/2000/svg" {...props}>
      <text
        transform="translate(-698 -64)"
        fillRule="evenodd"
        fillOpacity={0.87}
        fontFamily="AppleColorEmoji, Apple Color Emoji"
        fontSize={44}
      >
        <tspan x={698} y={105}>
          {'\uD83D\uDE2F'}
        </tspan>
      </text>
    </svg>
  );
}

export default PaymentFailedEmoji;
