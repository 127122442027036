import React from 'react';

import { SVG } from 'types/svg';

function FlagFasting(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          id="prefix__a507e96d-2d46-4d7b-981e-356e08bfb3bf"
          d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="none" d="M0 0h24v24H0z" />
        <use
          fill="#03CEA4"
          fillRule="nonzero"
          xlinkHref="#prefix__a507e96d-2d46-4d7b-981e-356e08bfb3bf"
        />
      </g>
    </svg>
  );
}

export default FlagFasting;
