import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { User } from 'types/user';

import browserHistory from 'helpers/history';
import { isUnsubscribeV7, isValidV7Step } from 'helpers/unsubscribeHelper';
import { getUserActiveProSubscription } from '../../helpers/userHelper';

type Props = {
  children: JSX.Element;
};

const EnsureUnsubscribeV7Available = ({ children }: Props) => {
  const { step } = useParams<{ step: string }>();
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const isActiveProSubscription = getUserActiveProSubscription(user);
  if (
    !isValidV7Step(step) &&
    !isUnsubscribeV7(user.category.toString()) &&
    !isActiveProSubscription
  ) {
    browserHistory.replace('/');
    return null;
  }

  return <>{children}</>;
};

export default EnsureUnsubscribeV7Available;
