import React from 'react';

import { SVG } from 'types/svg';

function Email(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.22 3.92H.78A.78.78 0 0 0 0 4.7v14.6a.78.78 0 0 0 .78.78h22.44a.78.78 0 0 0 .78-.78V4.7a.78.78 0 0 0-.78-.78zm-2.63 1.56L12 11.07 3.42 5.48zm-19 13V6.13l10 6.52a.78.78 0 0 0 .86 0l10-6.51v12.38z" />
    </svg>
  );
}

export default Email;
