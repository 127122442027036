import * as React from 'react';

const ExerciseKeto = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} fill="none">
    <rect width={44} height={44} fill="#2A9D8F" rx={4} />
    <path
      fill="#FFCC70"
      d="M20.768 14.897a2.8 2.8 0 0 1-3.309-2.165l-.191-.911a2.8 2.8 0 0 1 5.483-1.13l.192.91a2.79 2.79 0 0 1-2.175 3.296Z"
    />
    <path
      fill="#FFCC70"
      d="M34.133 23.894a1.43 1.43 0 0 1-1.37 1.427l-9.165.382a.736.736 0 0 0-.706.7l-.37 7.769a1.43 1.43 0 0 1-1.434 1.361h-.008a1.43 1.43 0 0 1-1.426-1.429v-10.24a4.764 4.764 0 0 0-.107-1.059l-.5-2.668a1.374 1.374 0 0 0-.584-.878l-8.07-5.421a1.176 1.176 0 0 1-.337-1.62 1.176 1.176 0 0 1 1.616-.335l4.755 2.981a3.298 3.298 0 0 0 2.405.434l3.849-.798a3.584 3.584 0 0 0 2.298-1.25l3.55-4.255a1.169 1.169 0 0 1 1.956 1.278l-5.825 7.69a1.374 1.374 0 0 0-.192 1.035l.624 2.74a.933.933 0 0 0 .913.729h6.698a1.43 1.43 0 0 1 1.43 1.427Z"
    />
  </svg>
);
export default ExerciseKeto;
