import React from 'react';
import { Trans } from 'react-i18next';
import i18n from 'i18n';

import { getSvgThemeFileName } from 'helpers/svgHelper';
import useTheme from 'helpers/themeHook';
import { isFasting, isKetoGo, isMaterialUI } from 'helpers/themeHelper';

import { CircularProgress } from '@material-ui/core';
import SvgIcon from 'components/common/SvgIcon';

import diet from './styles/LoadingAnimation_diet.module.scss';
import keto from './styles/LoadingAnimation_keto.module.scss';
import ketogo from './styles/LoadingAnimation_ketogo.module.scss';
import fasting from './styles/LoadingAnimation_fasting.module.scss';
import healthgenom from './styles/LoadingAnimation_healthgenom.module.scss';

type Props = {
  showText?: boolean;
};

const LoadingAnimation: React.FC<Props> = ({ showText = true }) => {
  const [s] = useTheme({
    base: {},
    diet,
    keto,
    ketogo,
    fasting,
    healthgenom,
  });

  if (isMaterialUI()) {
    const getProgressBarColor = () => {
      if (isFasting()) {
        return 'secondary';
      }
      if (isKetoGo()) {
        return undefined;
      }

      return 'primary';
    };

    return (
      <>
        <div className={s.container}>
          <SvgIcon
            filename={getSvgThemeFileName('Loading')}
            className={s.logo}
          />
          <CircularProgress
            className={`${isKetoGo() && s.progressColor}`}
            color={getProgressBarColor()}
            size={56}
          />
        </div>
        {showText && i18n.isInitialized && (
          <p className={s.text}>
            <Trans i18nKey="globals.loading" />
          </p>
        )}
      </>
    );
  }

  return (
    <div className="dumbbell_block" data-testid="loading-animation">
      <div className="dumbbell">
        <div className="handle" />
        <div className="weight1 left" />
        <div className="weight1 right" />
        <div className="weight2 left" />
        <div className="weight2 right" />
      </div>
    </div>
  );
};
export default LoadingAnimation;
