import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import browserHistory from 'helpers/history';
import { connect } from 'react-redux';
import { isUserFree, mealSurveyNotFinished } from 'helpers/userHelper';

class EnsureUserDoNotNeedMealSurveyWrapper extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    children: PropTypes.array,
  };

  componentDidMount = () => this.checkIfUserNeedsMealSurvey();

  checkIfUserNeedsMealSurvey = () => {
    const { user } = this.props;

    if (mealSurveyNotFinished(user)) {
      browserHistory.replaceLater('/meal_survey');
    }
  };

  render = () => {
    const { user, children } = this.props;
    return isUserFree(user) || user.has_active_meal_survey ? (
      children
    ) : (
      <Redirect to="/meal_survey" />
    );
  };
}

const mapStateToProps = (state) => ({
  user: state.rootReducer.user,
});

export default connect(mapStateToProps)(EnsureUserDoNotNeedMealSurveyWrapper);
