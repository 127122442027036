import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';

import { User } from 'types/user';
import { Offer, Paymodes } from 'types/offer';
import { ModalType } from 'types/modals';
import { MetaData, PrimerInitEventType, PrimerSession } from 'types/primer';
import {
  isDiet,
  isFasting,
  isHealthgenom,
  isMonacofit,
} from 'helpers/themeHelper';
import { getSavedCardPaymode } from 'helpers/offerHelper';
import { createAuditEvent } from 'helpers/auditEventHelper';

import { addModal } from 'actions/modalActions';
import { createPrimerSessionToken } from 'api/primerApi';

import PaymodeBtn from 'components/common/PaymodeBtn';
import { ReactComponent as PaymentIcon } from 'assets/images/offers/paymodes/first_data_diet.svg';

import s from '../PrimerCheckout.module.scss';

type Props = {
  user: User;
  offer: Offer;
  metaData: MetaData;
  primerEventType:
    | PrimerInitEventType.chosePaymode
    | PrimerInitEventType.offerRetried;
  isLoading: boolean;
  disabled: boolean;
  currentPaymode: string;
  duplicateButton?: boolean;
  onClick: (scrollFlag?: boolean) => void;
  onClose: () => void;
};

function ExternalCheckout({
  user,
  offer,
  metaData,
  primerEventType,
  isLoading,
  disabled,
  currentPaymode,
  onClick,
  onClose,
}: Props) {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const cardSavePaymode = getSavedCardPaymode(user, offer);

  const handleClick = async () => {
    if (disabled) {
      return;
    }

    onClick();

    if (cardSavePaymode) {
      if (offer.instant_chargeable_confirmation) {
        dispatch(
          addModal({
            modal: ModalType.cardSaveConfirmation,
            offer,
            urlParams: {
              paymode: Paymodes.primer,
              userId: user.id,
              offerId: offer.id,
              queryParams: metaData,
            },
            onClose: () => onClose(),
          })
        );

        return;
      }
    }

    createAuditEvent({
      source: user,
      target: offer,
      eventType: primerEventType,
      description: Paymodes.primer,
    });

    if (window.gtag) {
      window.gtag('event', 'Choose paymode', {
        event_category: 'Payments',
        event_label: Paymodes.primer,
      });
    }

    const clientSession: PrimerSession = await createPrimerSessionToken(
      parseInt(user.id, 10),
      offer.id,
      metaData
    );

    window.location.assign(clientSession.url);
  };

  const getButtonClass = () => {
    if (isLoading) {
      return s.loadingPrimerButton;
    }
    if (disabled) {
      return s.disabledPrimerButton;
    }

    return s.primerButton;
  };

  if (isDiet() || isFasting() || isMonacofit() || isHealthgenom()) {
    return (
      <PaymodeBtn
        onClick={() => handleClick()}
        label="primer"
        size={offer.paymodes.length}
        disabled={disabled}
      />
    );
  }

  return (
    <div className={s.wrapper}>
      <button
        type="button"
        onClick={() => handleClick()}
        className={getButtonClass()}
      >
        {isLoading ? (
          <CircularProgress size={25} color="primary" />
        ) : (
          <>
            <span className={s.imageContainer}>
              <PaymentIcon />
            </span>
            {t('paymodes.primer')}
          </>
        )}
      </button>
    </div>
  );
}

export default ExternalCheckout;
