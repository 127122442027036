import i18n from '../i18n';
import { formatISODate } from './dateHelper';

export const getHumanDate = (date) => {
  const year = formatISODate(date, 'yyyy');
  const month = i18n.t(`date.m.${formatISODate(date, 'MMM')}`);
  const day = formatISODate(date, 'dd');

  return `${year}-${month}-${day}`;
};

export const getMonthDate = (date) => {
  const month = i18n.t(`date.m.${formatISODate(date, 'MMM')}`);
  const day = formatISODate(date, 'dd');

  return `${day}-${month}`;
};

export const convertToMin = (time, isRoundEnabled) => {
  const sec = time % 60;
  const min = (time - sec) / 60;

  const minLabel = i18n.t('units.min');
  const secLabel = i18n.t('units.sec');

  if (sec && min) {
    // Always round up
    if (isRoundEnabled) {
      return `${min + 1}${minLabel}`;
    }
    return `${min}${minLabel} ${sec}${secLabel}`;
  }

  if (sec && !min) {
    return `${sec}${secLabel}`;
  }

  if (!sec && min) {
    return `${min}${minLabel}`;
  }

  return `0${secLabel}`;
};

export const isFutureRange = (startDate, endDate) => {
  // new Date is built for USA date format :(
  const now = Date.now();
  const [s_day, s_month, s_year] = startDate.split('.');
  const [e_day, e_month, e_year] = endDate.split('.');
  const start = new Date(s_year, s_month - 1, s_day);
  const end = new Date(e_year, e_month - 1, e_day);
  if (start.getTime() > now || end.getTime() > now) return true;
  return false;
};
