import * as t from 'actions/actionTypes';

import initialState from 'reducers/initialState';

export default (
  state = initialState.accessItems,
  action: t.AccessItemsActionTypes
) => {
  switch (action.type) {
    case t.ACCESS_ITEMS_LOAD:
      return { ...state, isLoading: true };
    case t.ACCESS_ITEMS_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        accessItems: action.accessItems,
      };
    case t.ACCESS_ITEMS_ERROR:
      return { ...state, isLoading: false, hasError: true };
    case t.LOG_OUT.SUCCESS:
      return initialState.accessItems;
    default:
      return state;
  }
};
