import { User } from 'types/user';
import { ItemType } from 'types/payment';
import {
  ExpandedExercise,
  InprogressWorkoutState,
  InternalWorkoutState,
  WorkoutPhase,
} from 'types/workouts';
import { Benefits, Day, WorkoutState } from 'types/days';
import { retrieveEnvVariableArray } from './genomPlusHelper';
import { convertToMin } from './timeHelper';
import {
  getActiveSubscriptionByItemType,
  getUnsubscribedSubscriptionByItemType,
  isAccessAvailable,
} from './userHelper';

import settings from './settings';

export const getWorkoutsCategories = (): string[] | null =>
  retrieveEnvVariableArray(settings.WORKOUTS_CATEGORIES);

export const isWorkoutsAllowed = (user: User): boolean => {
  const { category } = user;
  const allowedCategories = getWorkoutsCategories();

  if (!allowedCategories) return false;
  if (!allowedCategories.includes(category.toString())) return false;

  return true;
};

export const isWorkoutsActive = (user: User): boolean =>
  isAccessAvailable(user, ItemType.workouts);

export const showWorkoutsBlock = (user: User): boolean => {
  const isWorkoutAccess = isWorkoutsActive(user);
  const activeSubscription = getActiveSubscriptionByItemType(
    user,
    ItemType.workouts
  );

  // Display for active subscription
  if (isWorkoutAccess && activeSubscription) return true;

  // Dispaly for users that are available to subscribe
  const isAllowed = isWorkoutsAllowed(user);
  if (isAllowed) {
    if (!isWorkoutAccess) return true;

    const unsubscribedSubscription = getUnsubscribedSubscriptionByItemType(
      user,
      ItemType.workouts
    );
    if (unsubscribedSubscription) return true;
  }

  return false;
};

export const hasDayWorkout = (day: Day): boolean => {
  const { day_workouts } = day;
  if (!day_workouts) return false;

  return day_workouts.length > 0;
};

export const isWorkouts = (user: User, day: Day): boolean => {
  const isAllowed = isWorkoutsAllowed(user);
  if (!isAllowed) return false;

  const isWorkout = hasDayWorkout(day);
  if (!isWorkout) return false;

  return true;
};

export const retrieveWorkoutDay = (day: Day) => {
  const { day_workouts } = day;
  return day_workouts[0];
};

type WorkoutData = {
  duration: string;
  benefits: Benefits[];
  title: string;
  state: WorkoutState;
};

export const retrieveWorkoutData = (
  day: Day,
  user: User,
  defaultText: string
): WorkoutData | null => {
  const defaultData: WorkoutData = {
    duration: convertToMin(1800),
    benefits: ['energy', 'metabolism', 'muscles'],
    title: defaultText,
    state: 'draft',
  };
  const isWorkoutActive = isWorkoutsActive(user);
  if (!isWorkoutActive) return defaultData;
  const workoutDay = retrieveWorkoutDay(day);
  if (!workoutDay) return null;

  const benefits = workoutDay.benefits || [];
  const workoutDuration = convertToMin(workoutDay.workout_time, true);
  return {
    duration: workoutDuration,
    benefits,
    title: workoutDay.title,
    state: workoutDay.state,
  };
};

export const getDefaultVideoUrl = (user: User) => {
  const genderVideos = {
    male: 'https://assets-certifiedfasting-staging.fra1.cdn.digitaloceanspaces.com/workouts/videos/cropped-male.mp4',
    female:
      'https://assets-certifiedfasting-staging.fra1.cdn.digitaloceanspaces.com/workouts/videos/cropped-female.mp4',
  };

  return genderVideos[user.gender];
};

type ExerciseGroupName = 'pre_workout' | 'main' | 'post_workout';

export const getExerciseGroupName = (
  workoutPhase: WorkoutPhase
): ExerciseGroupName => {
  const exerciseGroupNames: { [key: string]: ExerciseGroupName } = {
    warmup: 'pre_workout',
    exercise: 'main',
    cooldown: 'post_workout',
  };

  return exerciseGroupNames[workoutPhase];
};

// Only for cardio workouts, meaning that has no exercises and can be auto completed
export const isCompletableWorkout = (workout: any) =>
  workout.workout_exercises.main &&
  workout.workout_exercises.main.length === 1 &&
  workout.workout_exercises.main[0].visibility[0] === 'ghost';

export const isLastPhaseExercise = (
  inprogressWorkout: InprogressWorkoutState,
  workout: any
) => {
  const { workoutPhase } = inprogressWorkout;
  const currentWorkout = inprogressWorkout[workoutPhase];

  // Check wether main exercise has a last exercise in it
  if (workoutPhase === 'exercise') {
    const mainLength = workout.workout_exercises.main.length;
    if (currentWorkout.currentWorkoutInSequence === mainLength - 1) {
      return true;
    }

    return false;
  }

  if (currentWorkout.currentCount === currentWorkout.totalCount - 1) {
    return true;
  }

  return false;
};

export const getNextWorkoutPhase = (workoutPhase: WorkoutPhase) => {
  const nextPhases = {
    warmup: 'exercise',
    exercise: 'cooldown',
    cooldown: 'done',
  };

  return nextPhases[workoutPhase];
};
const GHOST_VISILIBITY = 'ghost';

export const isRestExercise = (
  exercise: ExpandedExercise,
  workoutPhase: WorkoutPhase
) => {
  if (
    workoutPhase === 'exercise' &&
    exercise.visibility.includes(GHOST_VISILIBITY)
  )
    return true;
  return false;
};

export const getCurrentWorkoutPhaseData = (workouts: InternalWorkoutState) => {
  if (!workouts || !workouts.inprogressWorkoutState) return null;

  const { workoutPhase } = workouts.inprogressWorkoutState;
  return workouts.inprogressWorkoutState[workoutPhase];
};

export const getCurrentExercise = (
  workouts: InternalWorkoutState
): ExpandedExercise | null => {
  if (!workouts || !workouts.inprogressWorkoutState) return null;

  const { workoutPhase } = workouts.inprogressWorkoutState;
  const { currentExercise } = workouts.inprogressWorkoutState[workoutPhase];

  return currentExercise;
};

export const getNextExercise = (
  workouts: InternalWorkoutState,
  workout: any
) => {
  if (!workouts || !workouts.inprogressWorkoutState) return null;

  const { workoutPhase } = workouts.inprogressWorkoutState;
  const { currentWorkoutInSequence } =
    workouts.inprogressWorkoutState[workoutPhase];
  const exerciseGroup = getExerciseGroupName(workoutPhase);

  return workout.workout_exercises[exerciseGroup][currentWorkoutInSequence + 1];
};

export const getPeriodicity = (
  exercise: ExpandedExercise,
  repsText: string
) => {
  const { duration, repetitions } = exercise;
  const isRepetitions = !!(repetitions && repetitions > 0);
  if (!isRepetitions) return convertToMin(duration);

  return `${repetitions} ${repsText}`;
};

export const isWorkoutRestDay = (day: any) =>
  day?.day_workouts[0]?.exercise_count === 0;
