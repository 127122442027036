import {
  apiHost,
  requestHeadersWithToken,
  fetchData,
} from 'helpers/requestHelpers';
import { api } from 'helpers/api';
import ResponseHandler from 'actions/ResponseHandler';
import i18n from 'i18n';
import { addError } from 'redux-flash-messages';

// eslint-disable-next-line import/prefer-default-export
export const unsubscribe = (id) => {
  const url = `${apiHost()}/api/v1/users/subscriptions/${id}/unsubscribe`;
  const request = new Request(url, {
    method: 'POST',
    headers: requestHeadersWithToken(),
  });
  return fetchData(request);
};

export const getUnsubscribeCommonDataAPI = (subscriptionId) => {
  const url = `${apiHost()}/api/v1/users/subscriptions/${subscriptionId}/unsubscribe-custom-data`;

  const request = new Request(url, {
    method: 'GET',
    headers: requestHeadersWithToken(),
  });

  return fetchData(request);
};

export const addFreeAccessToUserAPI = async (
  subscriptionId,
  freeAccessPayload
) => {
  try {
    const response = await api(
      `api/v1/users/subscriptions/${subscriptionId}/lock-v2`,
      {
        method: 'POST',
        body: freeAccessPayload,
      }
    );
    const resHandler = new ResponseHandler(response);

    return resHandler.getNormalizedResponse();
  } catch (e) {
    const text = i18n.t('error.500_error');
    addError({ text });
    return null;
  }
};

export const postUnsubscribeReason = async (subscriptionId, data) => {
  try {
    const response = await api(
      `api/v1/users/subscriptions/${subscriptionId}/unsubscribe-reason`,
      {
        method: 'POST',
        body: data.apiPayload,
      }
    );
    const resHandler = new ResponseHandler(response);
    return resHandler.getNormalizedResponse();
  } catch (e) {
    const text = i18n.t('error.500_error');
    addError({ text });
    return null;
  }
};

export const requestPauseSubscription = async (subscriptionId) => {
  try {
    const response = await api(
      `api/v1/users/subscriptions/${subscriptionId}/pause`,
      {
        method: 'POST',
      }
    );
    const resHandler = new ResponseHandler(response);

    return resHandler.getNormalizedResponse();
  } catch {
    const text = i18n.t('error.500_error');
    addError({ text });

    return null;
  }
};

export const requestUnpauseSubscription = async (subscriptionId) => {
  try {
    const response = await api(
      `api/v1/users/subscriptions/${subscriptionId}/pause`,
      {
        method: 'DELETE',
      }
    );
    const resHandler = new ResponseHandler(response);

    return resHandler.getNormalizedResponse();
  } catch {
    const text = i18n.t('error.500_error');
    addError({ text });

    return null;
  }
};
