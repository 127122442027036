import { ChatUpsellOfferType } from 'types/upsell';
import { CONTEXTUAL_OFFER_TYPE } from './paywallHelper';

declare global {
  interface Window {
    dataLayer: any;
  }
}

type TagEvent = {
  category: string;
  action: string;
  label: string;
  value: number;
};

type EventType = 'open' | 'close' | 'accept' | 'learnMore';

export enum EVENT_TYPE {
  open = 'open',
  close = 'close',
  accept = 'accept',
  learnMore = 'learnMore',
  reject = 'reject',
}

export const getContextualEvent = (
  contextualType: CONTEXTUAL_OFFER_TYPE,
  eventType: EventType
): TagEvent => {
  const events = {
    open: {
      category: `Contextual Popup - ${contextualType}`,
      action: 'Click',
      label: 'Open',
      value: 1,
    },
    close: {
      category: `Contextual Popup - ${contextualType}`,
      action: 'Click',
      label: 'Close',
      value: 1,
    },
    accept: {
      category: `Contextual Popup - ${contextualType}`,
      action: 'Click',
      label: 'Accept',
      value: 1,
    },
    learnMore: {
      category: `Contextual Popup - ${contextualType}`,
      action: 'Click',
      label: 'Learn more',
      value: 1,
    },
  };

  return events[eventType];
};

export const getPremiumShoppingListBundleEvent = (
  offerType: string,
  templateName: string,
  eventType: 'open' | 'close' | 'accept'
): TagEvent => {
  const versionName =
    templateName === 'PremiumShoppingListBundleV1' ? 'V1' : 'V2';
  const flowName =
    offerType === 'registration_premium_shopping_list_bundle'
      ? 'Registration flow'
      : 'Universal flow';

  const events = {
    open: {
      category: `${flowName} Premium-Shoppinglist Bundle offer ${versionName}`,
      action: 'Show',
      label: 'Open',
      value: 1,
    },
    close: {
      category: `${flowName} Premium-Shoppinglist Bundle offer ${versionName}`,
      action: 'Click',
      label: 'Close',
      value: 1,
    },
    accept: {
      category: `${flowName} Premium-Shoppinglist Bundle offer ${versionName}`,
      action: 'Click',
      label: 'Accept',
      value: 1,
    },
  };

  return events[eventType];
};

export const triggerContextualEvent = ({
  category,
  action,
  label,
  value,
}: TagEvent) => {
  window.dataLayer.push({
    event: 'contextual_modal_event',
    eventProps: {
      category,
      action,
      label,
      value,
    },
  });
};

export const triggerPremiumShoppingListBundleEvent = ({
  category,
  action,
  label,
  value,
}: TagEvent) => {
  window.dataLayer.push({
    event: 'premium_shoppinglist_bundle_event',
    eventProps: {
      category,
      action,
      label,
      value,
    },
  });
};

export const getGA4SessionId = (): number | undefined => {
  const pattern = /_ga_(.*?)=GS\d\.\d\.(.+?)(?:;|$)/;
  const match = document.cookie.match(pattern);
  const parts = match?.[2].split('.');

  return parts?.[0] ? parseInt(parts?.[0], 10) : undefined;
};

export const getChatUpsellEvent = (
  offerType: ChatUpsellOfferType,
  eventType: 'open' | 'accept' | 'reject',
  isModal: boolean
): TagEvent => {
  const flows: { [key in ChatUpsellOfferType]: string } = {
    registration_chat: 'Registration flow',
    email_chat: 'Email flow',
    // Default original chat-upsell flow
    locked_chat: 'Locked flow',
    universal_chat: 'Universal flow',
    // Locked chat flow after chat imitation
    locked_chat_imitation_flow: 'Imitation flow locked chat screen',
    universal_chat_imitation_flow: 'Imitation flow universal screen',
    // Locked chat flow
    locked_chat_regular_flow: 'Regular flow locked chat screen',
    universal_chat_regular_flow: 'Regular flow universal screen',
  };
  const currentFlow = flows[offerType];
  const initiatedFrom = isModal ? 'modal' : 'offer';

  const events = {
    open: {
      category: `ChatUpsell ${initiatedFrom} - ${currentFlow}`,
      action: 'Click',
      label: 'Open',
      value: 1,
    },
    accept: {
      category: `ChatUpsell ${initiatedFrom} - ${currentFlow}`,
      action: 'Click',
      label: 'Accept',
      value: 1,
    },
    reject: {
      category: `ChatUpsell ${initiatedFrom} - ${currentFlow}`,
      action: 'Click',
      label: 'Reject',
      value: 1,
    },
  };

  return events[eventType];
};

export const triggerChatUpsellEvent = ({
  offerType,
  eventType,
  isModal = false,
}: {
  offerType: ChatUpsellOfferType;
  eventType: 'open' | 'accept' | 'reject';
  isModal?: boolean;
}) => {
  const eventProps: TagEvent = getChatUpsellEvent(
    offerType,
    eventType,
    isModal
  );

  window.dataLayer.push({
    event: 'chat_upsell_event',
    eventProps,
  });
};

export const triggerGenomPlusEvent = ({
  offerType,
  templateName,
  eventType,
  selectedPlans,
}: {
  offerType: string;
  templateName: string;
  eventType: 'open' | 'accept' | 'reject';
  selectedPlans?: { [key: string]: boolean };
}) => {
  const versionNames: { [key: string]: string } = {
    GenomPlus: 'v1',
    GenomPlusV2: 'v2',
  };
  const version = versionNames[templateName];

  const retrieveAcceptedOfferText = () => {
    const acceptedTexts: { [key: string]: string } = {
      genom: 'Core',
      'genom+genom_blood_test': 'Core+Blood',
      'genom+genom_saliva_test': 'Core+Saliva',
      'genom+genom_micro_test': 'Core+Micro',
      'genom+genom_blood_test+genom_saliva_test': 'Core+Blood+Saliva',
      'genom+genom_blood_test+genom_micro_test': 'Core+Blood+Micro',
      'genom+genom_saliva_test+genom_micro_test': 'Core+Saliva+Micro',
      'genom+genom_blood_test+genom_saliva_test+genom_micro_test':
        'Core+Saliva+Blood+Micro',
    };

    // If "selectedPlans === undefined" that means second template
    if (selectedPlans === undefined) {
      return 'Core+Saliva+Blood+Micro';
    }

    const validKeys = Object.keys(selectedPlans).filter(
      (key) => selectedPlans[key] === true
    );
    const acceptedKey = validKeys.join('+');

    return acceptedTexts[acceptedKey];
  };

  const flows: { [key: string]: string } = {
    registration_genom: 'Registration flow',
    universal_genom: 'Universal flow',
    profile_genom: 'Profile flow',
    email_genom: 'Email flow',
  };
  const category = `GenomPlus offer - ${flows[offerType]}`;
  const events = {
    open: {
      category,
      action: 'Click',
      label: `Open_${version}`,
      value: 1,
    },
    accept: {
      category,
      action: 'Click',
      label: `Accept_${version} - ${retrieveAcceptedOfferText()}`,
      value: 1,
    },
    reject: {
      category,
      action: 'Click',
      label: `Reject_${version}`,
      value: 1,
    },
  };

  const eventProps: TagEvent = events[eventType];

  window.dataLayer.push({
    event: 'genom_plus_event',
    eventProps,
  });
};
