import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFasting } from 'actions/fastingActions';
import { FastingData } from 'types/fasting';

import { LoadingAnimation } from 'components/common';

type Props = {
  children: React.ReactNode;
};

function EnsureFastingDataFetched({ children }: Props) {
  const dispatch = useDispatch();
  const fasting = useSelector<any, FastingData>(
    (store) => store.rootReducer.fasting
  );
  const [fastingFetched, setFastingFetched] = useState(false);

  useEffect(() => {
    dispatch(getFasting());
    setFastingFetched(true);
  }, [dispatch]);

  if (!fastingFetched || fasting.isLoading) {
    return <LoadingAnimation />;
  }

  return <>{children}</>;
}

export default EnsureFastingDataFetched;
