import React from 'react';

import { SVG } from 'types/svg';

function ProblemZonesDiet(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <defs>
        <path
          id="problem_zones__a"
          d="M16.49 18.5v-1.75L14 19.25l2.49 2.5V20H22v-1.5h-5.51zm2-11V5.75L16 8.25l2.49 2.5V9H22V7.5h-3.51zm-8.99-2c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM5.75 8.9L3 23h2.1l1.75-8L9 17v6h2v-7.55L8.95 13.4l.6-3C10.85 12 12.8 13 15 13v-2c-1.85 0-3.45-1-4.35-2.45l-.95-1.6C9.35 6.35 8.7 6 8 6c-.25 0-.5.05-.75.15L2 8.3V13h2V9.65l1.75-.75z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="none" d="M0 0h24v24H0z" />
        <mask id="problem_zones__b" fill="#fff">
          <use xlinkHref="#problem_zones__a" />
        </mask>
        <g fill="#000" fillOpacity={0.54} mask="url(#problem_zones__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
}

export default ProblemZonesDiet;
