import React from 'react';

import { SVG } from 'types/svg';

function HeightKeto(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} />
        <path
          fill="#264653"
          fillRule="nonzero"
          d="M17.285.5L1.5 16.288a1.72 1.72 0 00.002 2.427l3.64 3.643c.67.668 1.755.669 2.427.002L23.357 6.573a1.724 1.724 0 000-2.429L19.715.501a1.724 1.724 0 00-2.43 0zM5.49 17.5l-.867.867-2.167-2.168.867-.868L5.49 17.5zm3.47 0l-.867.867-3.903-3.903.867-.869L8.96 17.5zm0-3.47l-.867.867-2.17-2.167.869-.868L8.96 14.03zm1.735-1.735l-.868.867-2.166-2.167.867-.868 2.167 2.168zm3.468 0l-.867.867-3.902-3.901.868-.868 3.901 3.902zm0-3.469l-.867.868-2.167-2.167.868-.868 2.166 2.167zm1.735-1.733l-.867.867-2.168-2.168.867-.867 2.168 2.168zm3.47 0l-.869.867-3.901-3.903.867-.868 3.903 3.904zm-.001-3.469l-.868.867-2.166-2.169.867-.866 2.167 2.168z"
        />
      </g>
    </svg>
  );
}

export default HeightKeto;
