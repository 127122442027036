import { useSelector } from 'react-redux';
import { InitialState } from 'reducers/initialState';
import { Modals } from 'types/modals';

const useModals = () => {
  const modals = useSelector<{ rootReducer: InitialState }, Modals[]>(
    (store) => store.rootReducer.modals
  );

  if (modals.length === 0) {
    return null;
  }

  return modals[0];
};

export default useModals;
