import * as t from 'actions/actionTypes';
import initialState from 'reducers/initialState';

export default (
  state = initialState.workouts,
  action: t.WorkoutsStateActionType
) => {
  switch (action.type) {
    case t.SET_WORKOUTS_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
