import React from 'react';

import { SVG } from 'types/svg';

function User(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 14c-3.18 0-5.76-3.14-5.76-7 0-4.25 2.26-7 5.76-7s5.76 2.75 5.76 7c0 3.88-2.58 7-5.76 7zm0-12.45C9.4 1.55 7.79 3.64 7.79 7c0 3 1.89 5.46 4.21 5.46S16.21 10 16.21 7c0-3.36-1.61-5.45-4.21-5.45z" />
      <path d="M19.47 24H4.53a3.28 3.28 0 0 1-3.28-3.28v-1.3a5.18 5.18 0 0 1 2.34-4.33 21.91 21.91 0 0 0 2.87-2.39.78.78 0 0 1 1.1 1.1 23.66 23.66 0 0 1-3.11 2.58 3.65 3.65 0 0 0-1.65 3v1.3a1.73 1.73 0 0 0 1.73 1.73h14.94a1.74 1.74 0 0 0 1.73-1.73v-1.3a3.65 3.65 0 0 0-1.65-3 23.66 23.66 0 0 1-3.11-2.58.78.78 0 0 1 1.1-1.1 21.91 21.91 0 0 0 2.87 2.39 5.2 5.2 0 0 1 2.34 4.33v1.3A3.28 3.28 0 0 1 19.47 24z" />
    </svg>
  );
}

export default User;
