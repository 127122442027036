import {
  apiHost,
  requestHeadersWithToken,
  fetchData,
} from 'helpers/requestHelpers';

export const getWorkoutData = (dayId, workoutId) => {
  const request = new Request(
    `${apiHost()}/api/v1/days/${dayId}/workouts/${workoutId}`,
    {
      method: 'GET',
      headers: requestHeadersWithToken(),
    }
  );
  return fetchData(request);
};

export const markWorkoutAsCompleted = (dayId, workoutId) => {
  const request = new Request(
    `${apiHost()}/api/v1/days/${dayId}/workouts/${workoutId}/complete`,
    {
      method: 'PUT',
      headers: requestHeadersWithToken(),
    }
  );
  return fetchData(request);
};

export const markWorkoutAsDraft = (dayId, workoutId) => {
  const request = new Request(
    `${apiHost()}/api/v1/days/${dayId}/workouts/${workoutId}/discard`,
    {
      method: 'PUT',
      headers: requestHeadersWithToken(),
    }
  );
  return fetchData(request);
};

export const submitWorkoutData = (dayId, workoutId, workout) => {
  const request = new Request(
    `${apiHost()}/api/v1/days/${dayId}/workouts/${workoutId}/receive_stats`,
    {
      method: 'PUT',
      body: JSON.stringify(workout),
      headers: requestHeadersWithToken(),
    }
  );
  return fetchData(request);
};

export const adjustWorkoutDifficulty = (dayId, workoutId, adjustment) => {
  const body = {
    workout: {
      adjustment,
    },
  };

  const request = new Request(
    `${apiHost()}/api/v1/days/${dayId}/workouts/${workoutId}/adjust_difficulty`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: requestHeadersWithToken(),
    }
  );
  return fetchData(request);
};
