import { useEffect, useState } from 'react';

import { Offer } from 'types/offer';

import { getOfferById } from 'api/OfferAPI';
import ResponseHandler from 'actions/ResponseHandler';

type OfferData = {
  isLoading: boolean;
  isError: boolean;
  offer: Offer | undefined;
};

const INITIAL_OFFER_DATA: OfferData = {
  isLoading: true,
  isError: false,
  offer: undefined,
};

type Props = {
  offerId: number;
};

function useOfferDataById({ offerId }: Props): OfferData {
  const [offerData, setOfferData] = useState<OfferData>(INITIAL_OFFER_DATA);

  useEffect(() => {
    const loadOffer = async () => {
      try {
        const response = await getOfferById(offerId as number);
        const offer = new ResponseHandler(response);

        if (!offer) {
          setOfferData({
            ...INITIAL_OFFER_DATA,
            isLoading: false,
            isError: true,
          });
          return;
        }

        setOfferData({
          ...INITIAL_OFFER_DATA,
          isLoading: false,
          offer: offer.getNormalizedResponse(),
        });
      } catch {
        setOfferData({
          ...INITIAL_OFFER_DATA,
          isLoading: false,
          isError: true,
        });
      }
    };

    loadOffer();
  }, [offerId]);

  return offerData;
}

export default useOfferDataById;
