import React from 'react';
import { Modal } from '@material-ui/core';

import { ModalType } from 'types/modals';
import { ModalSteps } from 'types/quiz';
import { Subscription } from 'types/subscription';
import { LoadingAnimation } from 'components/common';
import { useModals } from 'hooks';
import { PhysicalProductType } from 'types/physicalProducts';

const CookieSettings = React.lazy(
  () => import('./CookieSettings/CookieSettings')
);
const PlanUpdate = React.lazy(() => import('./PlanUpdate/PlanUpdate'));
const Testimonial = React.lazy(() => import('./Testimonial/Testimonial'));
const PremiumSubscriptionWarning = React.lazy(
  () => import('./PremiumSubscription/PremiumSubscriptionWarning')
);
const Unsubscribed = React.lazy(() => import('./Unsubscribed'));
const UnsubscribedV7 = React.lazy(
  () => import('./UnsubscribedV7/UnsubscribedV7')
);
const UnsubscribeV6 = React.lazy(() => import('./UnsubscribeV6/UnsubscribeV6'));
const UnsubscribeV8 = React.lazy(() => import('./UnsubscribeV8/UnsubscribeV8'));
const UnsubscribeV3 = React.lazy(() => import('./UnsubscribeV3/UnsubscribeV3'));
const SubscriptionPriceChanged = React.lazy(
  () => import('./SubscriptionPriceChanged/SubscriptionPriceChanged')
);
const CardSaveConfirmation = React.lazy(
  () => import('./CardSaveConfirmation/CardSaveConfirmation')
);
const StartFasting = React.lazy(() => import('./StartFasting/StartFasting'));
const StopFasting = React.lazy(() => import('./StopFasting'));
const FinishFasting = React.lazy(() => import('./FinishFasting'));
const StopEating = React.lazy(() => import('./StopEating'));
const CollagenSubCanceled = React.lazy(() => import('./CollagenSubCanceled'));
const PhysicalProductUnsubscribed = React.lazy(
  () => import('./PhysicalProductUnsubscribed')
);
const ContextualOffer = React.lazy(() => import('./ContextualOffer'));
const PausedSubscription = React.lazy(() => import('./PausedSubscription'));
const UnpauseSubscription = React.lazy(() => import('./UnpauseSubscription'));
const WelcomeBackPauseSubscription = React.lazy(
  () => import('./WelcomeBackPauseSubscription')
);
const PreunusbscribeShoppinglist = React.lazy(
  () => import('./PreunusbscribeShoppinglist')
);
const PreunsubscribeChat = React.lazy(() => import('./PreunsubscribeChat'));
const StartCancellationFee = React.lazy(() => import('./StartCancellationFee'));
const CancellationFee = React.lazy(() => import('./CancellationFee'));
const ModalOffer = React.lazy(() => import('./ModalOffer'));
const KetoChocolateBarsContextual = React.lazy(
  () => import('./KetoChocolateBarsContextual')
);

const GlobalModals: React.FC = () => {
  const currentModal = useModals();

  if (!currentModal) {
    return null;
  }

  const renderModal = () => {
    const {
      modal,
      customModalNode,
      subscription,
      unsubModalType,
      unsubscribeParams,
      offer,
      urlParams,
      progressTime,
      remainingTime,
      timer,
      fastingHours,
      onClose,
      physicalProductType,
      contextualType,
      mealType,
      forwardUrl,
      freeOfferName,
      freeOfferModalType,
      endDate,
      unsubscribeFlowId,
      offerType,
    } = currentModal;

    switch (modal) {
      case ModalType.cookieSettings:
        return <CookieSettings />;
      case ModalType.planUpdate:
        return <PlanUpdate />;
      case ModalType.testimonial:
        return <Testimonial />;
      case ModalType.premiumWarning:
        return <PremiumSubscriptionWarning />;
      case ModalType.unsubscribed:
        return <Unsubscribed subscription={subscription as Subscription} />;
      case ModalType.unsubscribev6: {
        return <UnsubscribeV6 unsubModalType={unsubModalType as ModalSteps} />;
      }
      case ModalType.unsubscribedv7: {
        return <UnsubscribedV7 />;
      }
      case ModalType.unsubscribev3:
        return <UnsubscribeV3 />;
      case ModalType.subscriptionPriceChanged:
        return (
          <SubscriptionPriceChanged unsubscribeParams={unsubscribeParams} />
        );
      case ModalType.cardSaveConfirmation:
        return (
          <CardSaveConfirmation
            offer={offer}
            urlParams={urlParams}
            onClose={onClose}
          />
        );
      case ModalType.startFasting:
        return <StartFasting />;
      case ModalType.stopFasting:
        return (
          <StopFasting
            progressTime={progressTime}
            remainingTime={remainingTime}
          />
        );
      case ModalType.finishFasting:
        return <FinishFasting progressTime={progressTime} timer={timer} />;
      case ModalType.stopEating:
        return (
          <StopEating
            progressTime={progressTime}
            remainingTime={remainingTime}
            fastingHours={fastingHours}
          />
        );
      case ModalType.collagenSubCanceled:
        return (
          <CollagenSubCanceled
            collagenSubscription={subscription as Subscription}
          />
        );
      case ModalType.physicalProductUnsubscribed:
        return (
          <PhysicalProductUnsubscribed
            productSubscription={subscription as Subscription}
            physicalProductType={physicalProductType as PhysicalProductType}
          />
        );
      case ModalType.contextualOffer:
        return (
          <ContextualOffer
            contextualType={contextualType}
            mealType={mealType}
            forwardUrl={forwardUrl}
          />
        );
      case ModalType.unsubscribedv8:
        return (
          <UnsubscribeV8
            freeOfferName={freeOfferName}
            freeOfferModalType={freeOfferModalType}
            endDate={endDate}
            unsubscribeFlowId={unsubscribeFlowId}
          />
        );
      case ModalType.pausedSubscription:
        return <PausedSubscription />;
      case ModalType.unpauseSubscription:
        return <UnpauseSubscription />;
      case ModalType.welcomeBackPauseSubscription:
        return <WelcomeBackPauseSubscription />;
      case ModalType.preunsubscribeShoppinglist:
        return <PreunusbscribeShoppinglist />;
      case ModalType.preunsubscribeChat:
        return <PreunsubscribeChat />;
      case ModalType.startCancellationFee:
        return <StartCancellationFee />;
      case ModalType.cancellationFee:
        return <CancellationFee />;
      case ModalType.modalOffer:
        return <ModalOffer offerType={offerType} />;
      case ModalType.customModal:
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <>{customModalNode}</>;
      case ModalType.ketoChocolateBars:
        return <KetoChocolateBarsContextual forwardUrl={forwardUrl} />;
      default:
        return null;
    }
  };

  return (
    <Modal open style={{ zIndex: 9999 }}>
      <React.Suspense fallback={<LoadingAnimation />}>
        {renderModal()}
      </React.Suspense>
    </Modal>
  );
};

export default GlobalModals;
