import React from 'react';

import { SVG } from 'types/svg';

function Gender(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M24 1.73v-.07a.91.91 0 0 0-.11-.16l-.06-.06a.91.91 0 0 0-.16-.11h-.07a.66.66 0 0 0-.27-.06h-3.74a.76.76 0 0 0 0 1.52h1.81l-2.62 2.57a7 7 0 0 0-4.3-1.48A6.92 6.92 0 0 0 10.74 5a7 7 0 1 0-4.5 12.86V19h-1.7a.76.76 0 0 0 0 1.52h1.7V22a.76.76 0 1 0 1.52 0v-1.49h1.69a.76.76 0 0 0 0-1.52H7.76v-1.16a7 7 0 0 0 3-1 6.92 6.92 0 0 0 3.74 1.09 7 7 0 0 0 5.37-11.5l2.61-2.61v1.82a.76.76 0 1 0 1.52 0V2a.73.73 0 0 0 0-.27zm-11.53 9.14a5.46 5.46 0 0 1-1.73 4 5.43 5.43 0 0 1 0-7.95 5.45 5.45 0 0 1 1.73 3.95zm-11 0A5.49 5.49 0 0 1 7 5.4a5.36 5.36 0 0 1 2.46.6 7 7 0 0 0 0 9.73 5.44 5.44 0 0 1-7.94-4.87zm13 5.48a5.49 5.49 0 0 1-2.47-.61A7 7 0 0 0 12 6a5.36 5.36 0 0 1 2.46-.61 5.48 5.48 0 0 1 0 11z" />
    </svg>
  );
}

export default Gender;
