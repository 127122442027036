import React from 'react';

import { SVG } from 'types/svg';

function LoadingHealthgenom(props: SVG) {
  return (
    <svg
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="prefix__5cce9342-aebb-4a13-939b-ea4a124d0725"
        >
          <stop stopColor="#FFE066" offset="0%" />
          <stop stopColor="#D88D28" offset="100%" />
        </linearGradient>
        <path
          id="prefix__f5b3d378-a475-4216-bc5d-3dd7395cfdc0"
          d="M0 0h16.615v16.615H0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          fill="url(#prefix__5cce9342-aebb-4a13-939b-ea4a124d0725)"
          cx={12}
          cy={12}
          r={12}
        />
        <g transform="translate(3.692 3.692)">
          <mask id="prefix__9ab4ff43-db49-48db-b0d3-a44812beb3f6" fill="#fff">
            <use xlinkHref="#prefix__f5b3d378-a475-4216-bc5d-3dd7395cfdc0" />
          </mask>
          <path
            d="m12.127 7.813-.712 1.22-1.481-4.667-1.611 6.245-1.498-7.332L4.62 7.813H2.93v.99h2.309l1.194-2.454 1.87 7.877L9.968 7.74l1.18 3.715 1.548-2.652h2.914a7.32 7.32 0 0 1-7.302 6.823C4.273 15.626.99 12.343.99 8.308A7.325 7.325 0 0 1 8.308.99c2.3 0 4.42 1.049 5.818 2.879l.039.05h1.229l-.482-.651A8.252 8.252 0 0 0 8.308 0C3.727 0 0 3.727 0 8.308c0 4.58 3.727 8.307 8.308 8.307a8.31 8.31 0 0 0 8.307-8.307v-.495h-4.488z"
            fill="#FFF5D0"
            mask="url(#prefix__9ab4ff43-db49-48db-b0d3-a44812beb3f6)"
          />
        </g>
      </g>
    </svg>
  );
}

export default LoadingHealthgenom;
