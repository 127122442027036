import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { isUserFree } from 'helpers/userHelper';
import { Subscription } from 'types/subscription';
import { User } from 'types/user';

type Props = {
  children: JSX.Element;
};

function EnsureSubTosAccepted({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);

  const userNeedToAcceptTos = () => {
    const { status } = user.subscription as Subscription;

    if (isUserFree(user)) {
      return false;
    }
    if (status !== 'temporal') {
      return false;
    }

    return true;
  };

  if (user.subscription && userNeedToAcceptTos()) {
    return <Redirect to="/sub_tos" />;
  }

  return <>{children}</>;
}

export default EnsureSubTosAccepted;
