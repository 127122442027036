import * as React from 'react';

const StopEating = () => (
  <svg width={104} height={104} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient
        x1="100%"
        y1="0%"
        x2="0%"
        y2="100%"
        id="prefix__acfcffca-5695-4a63-9118-4e964a695f4f"
      >
        <stop stopColor="#03CEA4" offset="0%" />
        <stop stopColor="#29ABE2" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle
        fill="url(#prefix__acfcffca-5695-4a63-9118-4e964a695f4f)"
        cx={52}
        cy={52}
        r={52}
      />
      <path
        d="M52 80c-7.506 0-14.506-2.952-19.82-8.18C26.868 66.505 24 59.505 24 52s2.952-14.506 8.18-19.82C37.495 26.868 44.495 24 52 24s14.506 2.952 19.82 8.18C77.132 37.495 80 44.495 80 52s-2.952 14.506-8.18 19.82C66.505 77.131 59.505 80 52 80zm0-54.229c-7 0-13.578 2.7-18.554 7.675C28.47 38.422 25.77 45 25.77 52c0 7 2.699 13.578 7.675 18.554C38.422 75.53 45 78.23 52 78.23s13.578-2.699 18.554-7.675C75.53 65.58 78.23 59 78.23 52s-2.699-13.578-7.675-18.554C65.578 28.47 59 25.77 52 25.77zm0 1.518C38.337 27.29 27.29 38.422 27.29 52c0 13.663 11.132 24.711 24.71 24.711 13.578 0 24.71-11.048 24.71-24.71 0-13.663-11.047-24.712-24.71-24.712zM28.892 54.193c-.675 0-1.265-.59-1.265-1.265 0-.675.59-1.265 1.265-1.265.674 0 1.264.591 1.264 1.265.001.675-.59 1.265-1.264 1.265zm23.024-26.482c.674 0 1.264.59 1.264 1.265 0 .675-.59 1.265-1.264 1.265-.675 0-1.265-.59-1.265-1.265 0-.674.505-1.265 1.265-1.265zm0 48.663c-.675 0-1.265-.59-1.265-1.265 0-.675.59-1.265 1.265-1.265.674 0 1.264.59 1.264 1.265-.084.675-.59 1.265-1.264 1.265zm12.228-33.23-9.023 9.025c.084.253.084.59.084.843A3.203 3.203 0 0 1 52 56.217a3.203 3.203 0 0 1-3.205-3.205c0-.253 0-.506.084-.674L36.397 39.856a1.476 1.476 0 0 1 0-2.109c.59-.59 1.518-.59 2.109 0l12.313 12.314c.337-.169.76-.253 1.18-.253.338 0 .76.084 1.097.169l8.94-8.94a1.475 1.475 0 0 1 2.108 0c.59.674.59 1.602 0 2.108zm10.964 11.049c-.674 0-1.264-.59-1.264-1.265 0-.675.59-1.265 1.264-1.265.675 0 1.265.59 1.265 1.265 0 .675-.59 1.265-1.265 1.265z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default StopEating;
