import React from 'react';

import { SVG } from 'types/svg';

function CardSaveConfirmationHealthgenom(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={105} height={104} xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="prefix__cc27d58b-db43-451a-8ef0-60a0cda6dd7c"
        >
          <stop stopColor="#FFE066" offset="0%" />
          <stop stopColor="#D88D28" offset="100%" />
        </linearGradient>
      </defs>
      <g transform="translate(.5)" fill="none" fillRule="evenodd">
        <circle
          fill="url(#prefix__cc27d58b-db43-451a-8ef0-60a0cda6dd7c)"
          cx={52}
          cy={52}
          r={52}
        />
        <path
          d="M54.026 24.39a.586.586 0 0 1-.586-.587v-3.217a.586.586 0 1 1 1.172 0v3.217a.585.585 0 0 1-.586.586zm-6.21 2.572c-.15 0-.3-.057-.415-.171l-2.274-2.275a.585.585 0 1 1 .829-.83l2.274 2.276a.585.585 0 0 1-.414 1zm12.421 0a.585.585 0 0 1-.414-1l2.275-2.275a.585.585 0 1 1 .829.829l-2.275 2.275a.587.587 0 0 1-.415.171zM72.423 59.96h-2.321a3.577 3.577 0 0 1 3.552 4.016c-.21 1.823-1.864 3.142-3.705 3.142h-3.1a3.58 3.58 0 0 1 3.552 4.016c-.211 1.828-1.864 3.148-3.705 3.148H45.187c-1.33-.041-2.233-.34-2.837-.756V51.677l7.339-11.771a2.56 2.56 0 0 0 .387-1.349v-7.591a1.5 1.5 0 0 1 1.178-1.466c2.005-.445 6.027-.72 7.668 3.6 0 0 1.735 5.27 0 12.538h12.574c1.84 0 3.488 1.325 3.705 3.148a3.583 3.583 0 0 1-3.552 4.016h.621c1.84 0 3.488 1.318 3.705 3.142a3.583 3.583 0 0 1-3.553 4.015l.001.002zM38.628 47.405H30.55a2.548 2.548 0 0 0-2.55 2.55v24.357a2.544 2.544 0 0 0 2.55 2.55h8.078a2.552 2.552 0 0 0 2.55-2.55V49.955a2.555 2.555 0 0 0-2.55-2.55zm-4.04 25.699a2.05 2.05 0 0 1-2.05-2.052c0-1.137.914-2.051 2.05-2.051a2.05 2.05 0 0 1 2.052 2.051c0 1.131-.92 2.052-2.051 2.052zm10.655-39.932h-3.217a.586.586 0 1 1 0-1.172h3.217a.586.586 0 1 1 0 1.172zm20.785 0H62.81a.586.586 0 1 1 0-1.172h3.217a.586.586 0 1 1 0 1.172z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default CardSaveConfirmationHealthgenom;
