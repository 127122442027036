import React from 'react';

import { SVG } from 'types/svg';

function EmailDiet(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <defs>
        <path
          id="prefix__abc"
          d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-16.000000, -2185.000000) translate(16.000000, 2185.000000)">
            <mask id="1w73e1kkhb" fill="#fff">
              <use xlinkHref="#prefix__abc" />
            </mask>
            <g fill="#000" fillOpacity="0.54" mask="url(#1w73e1kkhb)">
              <path d="M0 0H24V24H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default EmailDiet;
