import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import createFilterOptions from 'react-select-fast-filter-options';
import { withTranslation } from 'react-i18next';

import { isMaterialUI } from 'helpers/themeHelper';
import { SVGFillColors } from 'types/svg';

import { Select as MuiSelect } from '@material-ui/core';
import SvgIcon from 'components/common/SvgIcon';

class DropDownInput extends Component {
  static propTypes = {
    type: PropTypes.string,
    svgIcon: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    error: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const { options } = this.props;

    this.filterOptions = createFilterOptions({ options });
  }

  getWrapperClass = () => {
    const { type, error, className } = this.props;

    let wrapperClass = 'dropdown';

    if (className) {
      wrapperClass += ` ${className}`;
    }

    if (type) {
      wrapperClass += ` ${type}`;
    }

    if (error) {
      wrapperClass += ' has-error';
    }
    return wrapperClass;
  };

  render = () => {
    const { t, svgIcon, options, onChange, value, required } = this.props;

    if (isMaterialUI()) {
      return (
        <MuiSelect
          native
          variant="outlined"
          value={value.value || value}
          onChange={(e) => onChange({ value: e.target.value })}
          required={required}
        >
          {/*
            Empty option, so that "required" property works
            spec: https://html.spec.whatwg.org/multipage/form-elements.html#attr-select-required
          */}
          <option value=""> </option>
          {options.map((option) => (
            <option key={`${option.key}-${option.value}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </MuiSelect>
      );
    }

    return (
      <div className={this.getWrapperClass()}>
        {svgIcon && (
          <SvgIcon
            filename={svgIcon}
            fill={SVGFillColors.gray}
            className="dropdown__input__img"
          />
        )}
        <Select
          name="form-field-name"
          value={value}
          onChange={(e) => {
            if (!e) {
              return;
            }

            onChange(e);
          }}
          filterOptions={this.filterOptions}
          options={options}
          className="dropdown__input"
          placeholder={t('form.dropdown.placeholder')}
          noResultsText={t('form.dropdown.no_result')}
        />
        {required && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              height: 0,
              position: 'absolute',
              right: '50%',
            }}
            value={value || ''}
            required={required}
          />
        )}
      </div>
    );
  };
}

export default withTranslation()(DropDownInput);
