import { useEffect, useState } from 'react';

import settings from 'helpers/settings';

declare global {
  interface Window {
    googleMapsScriptLoaded: any;
    google: any;
  }
}

const useGoogleMaps = (): {
  isGAMapsReadyToUse: boolean;
} => {
  const [isGAMapsReadyToUse, setIsGAMapsReadyToUse] = useState(false);

  useEffect(() => {
    const getGoogleMaps = () => {
      try {
        if (window.google) {
          setIsGAMapsReadyToUse(true);

          return;
        }

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${settings.GOOGLE_PLACES_API_KEY}&libraries=places&callback=googleMapsScriptLoaded`;
        script.async = true;

        window.googleMapsScriptLoaded = () => {
          setIsGAMapsReadyToUse(true);
        };

        document.head.appendChild(script);
      } catch (e) {
        setIsGAMapsReadyToUse(false);
      }
    };

    getGoogleMaps();
  }, [setIsGAMapsReadyToUse]);

  return { isGAMapsReadyToUse };
};

export default useGoogleMaps;
