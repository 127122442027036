import React from 'react';
import { useTranslation } from 'react-i18next';
import useTheme from 'helpers/themeHook';
import { isMaterialUI, getMaterialUIColor } from 'helpers/themeHelper';

import { DynamicValues } from 'types/mealSurvey';

import {
  Radio,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';

import base from './styles/SelectButtons.module.scss';
import keto from './styles/SelectButtons_keto.module.scss';
import ketogo from './styles/SelectButtons_ketogo.module.scss';
import fasting from './styles/SelectButtons_fasting.module.scss';
import healthgenom from './styles/SelectButtons_healthgenom.module.scss';

type Props = {
  label: string;
  name: string;
  data: string[];
  inputType?: string;
  showLabel?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  I18nNamespace: string;
  description?: boolean;
  error?: string;
  multiple?: boolean;
  dynamicData?: DynamicValues[];
  disabled?: boolean;
};

const SelectButtons: React.FC<Props> = ({
  showLabel,
  error,
  I18nNamespace,
  data,
  label,
  onChange,
  value,
  inputType,
  name,
  description,
  multiple,
  dynamicData,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [s] = useTheme({ base, diet: {}, keto, ketogo, fasting, healthgenom });

  const getTOptions = (itemKey: string, dynamicObject: DynamicValues[]) => {
    const dynamicValue = dynamicObject.find((x) => x.key === itemKey);

    return dynamicValue?.options;
  };

  const getWrapperClass = () => {
    let wrapperClass = 'form__select_buttons';
    if (showLabel) wrapperClass += ' with-label';
    if (inputType) wrapperClass += ` ${inputType}`;
    if (error) wrapperClass += ' error';
    return wrapperClass;
  };

  const getInputLabel = () => {
    if (showLabel)
      return (
        <label htmlFor={name} className={s.select_label}>
          {t(`${I18nNamespace}.${label}.title`)}
        </label>
      );
    return null;
  };

  const twoOptionSelectButton = () => (
    <div className={getWrapperClass()}>
      {getInputLabel()}
      <div className={s.inputs}>
        {data.map((item, index) => (
          <React.Fragment key={item}>
            <input
              className={`form__select_buttons__radio ${s.radio}`}
              type="radio"
              name={name}
              value={item}
              id={`${label}-${item}`}
              onClick={onChange as any}
              onChange={onChange}
              checked={value === item}
              disabled={disabled}
            />
            <label
              className={`form__select_buttons__label ${item}
              ${index === 0 ? 'left' : 'right'} ${
                value === item && inputType === 'small' ? s.checked : ''
              } ${s.label}`}
              htmlFor={`${label}-${item}`}
            >
              {t(`${I18nNamespace}.${label}.${item}`)}
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  const multipleOptionSelectButton = () => {
    if (dynamicData) {
      return (
        <div className={getWrapperClass()}>
          {getInputLabel()}
          {dynamicData.map((item, index) => (
            <div
              className={`form__select_buttons__block ${
                index + 1 === dynamicData.length ? 'last' : ''
              }`}
              // eslint-disable-next-line react/no-array-index-key
              key={`select_${index}`}
            >
              <input
                className={`form__select_buttons__radio ${s.radio}`}
                type="radio"
                name={name}
                value={item.key}
                id={`${label}-${item.key}`}
                onClick={onChange as any}
                onChange={() => null}
                checked={value === item.key}
                disabled={disabled}
              />
              <label
                className={`form__select_buttons__label large ${s.label}`}
                htmlFor={`${label}-${item.key}`}
              >
                {t(`${I18nNamespace}.${label}.${item.key}`, {
                  ...getTOptions(item.key, dynamicData),
                })}
                {description && (
                  <span className="form__select_buttons__label__desc">
                    {t(`${I18nNamespace}.${label}.${item.key}_desc`)}
                  </span>
                )}
              </label>
            </div>
          ))}
          <div className="clear" />
        </div>
      );
    }

    return (
      <div className={getWrapperClass()}>
        {getInputLabel()}
        {data.map((item, index) => (
          <div
            className={`form__select_buttons__block ${
              index + 1 === data.length ? 'last' : ''
            }`}
            // eslint-disable-next-line react/no-array-index-key
            key={`select_${index}`}
          >
            <input
              className={`form__select_buttons__radio ${s.radio}`}
              type="radio"
              name={name}
              value={item}
              id={`${label}-${item}`}
              onClick={onChange as any}
              onChange={() => null}
              checked={value === item}
              disabled={disabled}
            />
            <label
              className={`form__select_buttons__label large ${s.label}`}
              htmlFor={`${label}-${item}`}
            >
              {t(`${I18nNamespace}.${label}.${item}`)}
              {description && (
                <span className="form__select_buttons__label__desc">
                  {t(`${I18nNamespace}.${label}.${item}_desc`)}
                </span>
              )}
            </label>
          </div>
        ))}
        <div className="clear" />
      </div>
    );
  };

  const renderMaterialUIButtons = () => {
    if (data.length === 2 && !multiple) {
      return (
        <FormControl variant="outlined">
          {showLabel && (
            <InputLabel id={`${name}_label`}>
              {t(`${I18nNamespace}.${label}.title`)}
            </InputLabel>
          )}
          <Select
            id={name}
            labelId={`${name}_label`}
            label={showLabel && t(`${I18nNamespace}.${label}.title`)}
            onChange={(e) =>
              onChange({ target: { name, value: e.target.value } } as any)
            }
            value={value}
            disabled={disabled}
          >
            {data.map((item) => (
              <MenuItem key={item} value={item}>
                {t(`${I18nNamespace}.${label}.${item}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    if (dynamicData) {
      return (
        <div className={s.container}>
          <List>
            {dynamicData.map((item) => (
              <React.Fragment key={item.key}>
                <ListItem
                  disabled={disabled}
                  button
                  selected={value === item.key}
                  onClick={() =>
                    onChange({ target: { name, value: item } } as any)
                  }
                  classes={{ selected: s.selected }}
                >
                  <ListItemText>
                    {t(`${I18nNamespace}.${label}.${item.key}`, {
                      ...getTOptions(item.key, dynamicData),
                    })}{' '}
                    {description &&
                      ` (${t(`${I18nNamespace}.${label}.${item.key}_desc`)})`}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <Radio
                      onClick={() =>
                        onChange({ target: { name, value: item } } as any)
                      }
                      edge="end"
                      checked={value === item.key}
                      tabIndex={-1}
                      color={getMaterialUIColor()}
                      disabled={disabled}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider variant="fullWidth" component="li" />
              </React.Fragment>
            ))}
          </List>
        </div>
      );
    }

    return (
      <div className={s.container}>
        <List>
          {data.map((item) => (
            <React.Fragment key={item}>
              <ListItem
                disabled={disabled}
                button
                selected={value === item}
                onClick={() =>
                  onChange({ target: { name, value: item } } as any)
                }
                classes={{ selected: s.selected }}
              >
                <ListItemText>
                  {t(`${I18nNamespace}.${label}.${item}`)}{' '}
                  {description &&
                    ` (${t(`${I18nNamespace}.${label}.${item}_desc`)})`}
                </ListItemText>
                <ListItemSecondaryAction>
                  <Radio
                    onClick={() =>
                      onChange({ target: { name, value: item } } as any)
                    }
                    edge="end"
                    checked={value === item}
                    tabIndex={-1}
                    color={getMaterialUIColor()}
                    disabled={disabled}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="fullWidth" component="li" />
            </React.Fragment>
          ))}
        </List>
      </div>
    );
  };

  if (isMaterialUI()) {
    return renderMaterialUIButtons();
  }

  return data.length === 2 && !multiple
    ? twoOptionSelectButton()
    : multipleOptionSelectButton();
};

export default SelectButtons;
