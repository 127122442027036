import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { User } from 'types/user';
import {
  isCollagenActive,
  isCollagenSubscriptionActive,
  isCollagenSubscriptionUnsubscribed,
  isLocaleAllowedForCollagen,
} from 'helpers/collagenHelper';

type Props = {
  children: React.ReactNode;
};

function EnsureCollagenActive({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);

  if (!isCollagenActive(user)) {
    return <Redirect to="/program" />;
  }
  if (isCollagenSubscriptionActive(user)) {
    return <>{children}</>;
  }
  if (isCollagenSubscriptionUnsubscribed(user)) {
    return <Redirect to="/program" />;
  }
  if (!isLocaleAllowedForCollagen()) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
}

export default EnsureCollagenActive;
