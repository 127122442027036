import {
  genomPlusSurveyApiGet,
  genomPlusSurveyApiPost,
} from 'api/GenomPlusSurveyApi';
import {
  GenomPlusSurveyAnswersType,
  GenomPlusSurveyDataType,
} from 'pages/GenomPlusSurvey/types';
import * as types from 'actions/actionTypes';
import { ThunkActionType } from 'store/types';

//
// Load
//
export const genomPlusSurveyLoadAction =
  (): types.GenomPlusSurveyLoadActionType => ({
    type: types.GENOM_PLUS_SURVEY_LOAD,
  });

export const genomPlusSurveyLoadSuccessAction = (
  data: GenomPlusSurveyDataType
): types.GenomPlusSurveySuccessActionType => ({
  type: types.GENOM_PLUS_SURVEY_LOAD_SUCCESS,
  data,
});

export const genomPlusSurveyLoadErrorAction = (
  error: Error
): types.GenomPlusSurveyErrorActionType => ({
  type: types.GENOM_PLUS_SURVEY_LOAD_ERROR,
  error,
});

export const getGenomPlusSurvey = (): ThunkActionType => (dispatch) => {
  dispatch(genomPlusSurveyLoadAction());

  return genomPlusSurveyApiGet()
    .then((response) => {
      dispatch(genomPlusSurveyLoadSuccessAction(response));
    })
    .catch((error) => {
      dispatch(genomPlusSurveyLoadErrorAction(error));
      throw error;
    });
};

//
// Save
//
export const genomPlusSurveySaveAction =
  (): types.GenomPlusSurveySaveActionType => ({
    type: types.GENOM_PLUS_SURVEY_SAVE,
  });

export const genomPlusSurveySaveSuccessAction = (
  data: GenomPlusSurveyDataType
): types.GenomPlusSurveySaveSuccessActionType => ({
  type: types.GENOM_PLUS_SURVEY_SAVE_SUCCESS,
  data,
});

export const genomPlusSurveySaveErrorAction = (
  error: Error
): types.GenomPlusSurveySaveErrorActionType => ({
  type: types.GENOM_PLUS_SURVEY_SAVE_ERROR,
  error,
});

export const saveGenomPlusSurvey =
  (answers: GenomPlusSurveyAnswersType): ThunkActionType =>
  (dispatch) =>
    genomPlusSurveyApiPost(answers)
      .then((response) => {
        dispatch(genomPlusSurveySaveSuccessAction(response));
      })
      .catch((error) => {
        dispatch(genomPlusSurveySaveErrorAction(error));
        throw error;
      });
