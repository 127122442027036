import * as React from 'react';

const FastingCup = () => (
  <svg width={18} height={20} xmlns="http://www.w3.org/2000/svg">
    <g fill="#03CEA4">
      <path d="M2.472 8.857h1.67l.185.345c.118.2.21.369.273.479.345.63 1.531 3.143 1.845 3.925.255.645.345 2.1.23 3.057-.068.67-.979 1.855-1.327 2.254a.69.69 0 0 0-.169.73.667.667 0 0 0 .67.345h5.792a.625.625 0 0 0 .5-1.092c-.345-.4-1.258-1.585-1.327-2.254-.097-.958-.025-2.413.23-3.057.318-.786 1.494-3.298 1.839-3.936l.272-.479.186-.344h1.67A2.494 2.494 0 0 0 17.5 6.34V4.718a2.491 2.491 0 0 0-2.49-2.488h-.345V1.034A1.033 1.033 0 0 0 13.631 0H3.869a1.035 1.035 0 0 0-1.035 1.034V2.23H2.49A2.494 2.494 0 0 0 0 4.718v1.65a2.49 2.49 0 0 0 2.472 2.49zm12.183-3.053V2.919H15a1.8 1.8 0 0 1 1.8 1.799v1.65a1.8 1.8 0 0 1-1.8 1.8h-1.265c.503-.927.903-1.796.92-2.364zM9.973 18.755H7.517a.345.345 0 1 1 0-.69h2.456a.345.345 0 1 1 0 .69zM7.7 17.377c0-.19.155-.345.345-.345h1.39a.345.345 0 1 1 0 .69h-1.38a.345.345 0 0 1-.344-.363l-.011.018zM5.92 4.632a.345.345 0 0 1 .346-.241h1.658L8.4 2.878a.345.345 0 0 1 .655 0l.51 1.513h1.66a.345.345 0 0 1 .196.627l-1.297.93.507 1.503a.345.345 0 0 1-.117.386.345.345 0 0 1-.407 0l-1.362-.94L7.4 7.84a.345.345 0 0 1-.407 0 .345.345 0 0 1-.117-.386l.49-1.506-1.331-.931a.345.345 0 0 1-.114-.386zm-5.248.086a1.8 1.8 0 0 1 1.8-1.799h.345v2.885c0 .568.417 1.437.92 2.364H2.473a1.8 1.8 0 0 1-1.8-1.799v-1.65z" />
      <path d="M2.607 12.097a.345.345 0 0 0 .344-.345v-.182h.183a.345.345 0 1 0 0-.69h-.183v-.193a.345.345 0 0 0-.69 0v.183H2.08a.345.345 0 1 0 0 .69h.183v.182a.345.345 0 0 0 .345.355zM4.01 15.95a.345.345 0 0 0 .345-.345v-.183h.182a.345.345 0 1 0 0-.689h-.182v-.183a.345.345 0 0 0-.69 0v.183h-.183a.345.345 0 1 0 0 .69h.183v.182a.345.345 0 0 0 .345.345zm-.176-2.275a.52.52 0 1 0-.007-1.042.52.52 0 0 0 .007 1.042zm0-.69a.18.18 0 1 1 0 .345.18.18 0 1 1 0-.344zm-.7 4.546a.524.524 0 1 0 0-1.048.524.524 0 0 0 0 1.048zm0-.689a.18.18 0 0 1 .176.18.18.18 0 1 1-.345 0 .179.179 0 0 1 .17-.193v.013zm-1.927-2.636a.524.524 0 1 0 1.047 0 .524.524 0 0 0-1.047 0zm.69 0a.18.18 0 0 1-.18.176.18.18 0 0 1-.229-.172.18.18 0 0 1 .229-.173.18.18 0 0 1 .193.169h-.014zm13.155-2.454v-.182h.182a.345.345 0 1 0 0-.69h-.182v-.193a.345.345 0 0 0-.69 0v.183h-.183a.345.345 0 1 0 0 .69h.183v.182a.345.345 0 1 0 .69 0v.01zm-1.749 4.198a.345.345 0 0 0 .345-.345v-.183h.183a.345.345 0 1 0 0-.689h-.183v-.183a.345.345 0 0 0-.69 0v.183h-.179a.345.345 0 1 0 0 .69h.183v.182c0 .189.152.343.341.345zm.177-2.275a.52.52 0 1 0-.008-1.042.52.52 0 0 0 .007 1.042zm0-.69a.18.18 0 1 1 0 .345.18.18 0 1 1 0-.344zm.7 4.546a.524.524 0 1 0 .006-1.048.524.524 0 0 0-.007 1.049zm0-.689a.18.18 0 1 1 0 .358.18.18 0 0 1 0-.358zm1.402-2.116a.52.52 0 1 0 0-1.041.52.52 0 0 0 0 1.041zm0-.689a.18.18 0 1 1-.175.18.179.179 0 0 1 .175-.19v.01z" />
    </g>
  </svg>
);

export default FastingCup;
