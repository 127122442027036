import React from 'react';

const ErrorIcon = () => (
  <svg
    className="errormark"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 52"
  >
    <circle className="errormark__circle" cx={26} cy={26} r={25} fill="none" />
    <line className="errormark_line-1" x1={16} y1={16} x2={36} y2={36} />
    <line className="errormark_line-2" x1={36} y1={16} x2={16} y2={36} />
  </svg>
);

export default ErrorIcon;
