import React from 'react';
import { useSelector } from 'react-redux';
import { User } from 'types/user';
import { shouldShowLoginOffer } from 'helpers/offerHelper';
import { getCurrentTheme } from 'helpers/themeHelper';
import { Redirect } from 'react-router-dom';

const EnsureUserHasSeenLoginOffer: React.FC = ({ children }) => {
  const user = useSelector<any, User>((store) => store.rootReducer.user);
  const currentTheme = getCurrentTheme();

  const canUserSeeLoginOffer = () => {
    if (!shouldShowLoginOffer(user)) {
      return false;
    }

    return true;
  };

  const getOfferUrl = () => {
    const { hostname } = window.location;

    if (currentTheme === 'monacofit') {
      return `/offer/${user.goal}_universal_pro?utm_source=${hostname}&utm_medium=enters_app&utm_campaign=glad_to_see_you_get_results_4_times_faster`;
    }

    return `/offer/sub_expired?utm_source=${hostname}&utm_medium=enters_app&utm_campaign=start_losing_weight_again`;
  };

  const redirectToOffer = () => {
    const offerUrl = getOfferUrl();

    return <Redirect to={`${offerUrl}`} />;
  };

  return <>{canUserSeeLoginOffer() ? redirectToOffer() : children}</>;
};

export default EnsureUserHasSeenLoginOffer;
