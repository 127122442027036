import React from 'react';

import { SVG } from 'types/svg';

function AgeDiet(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          id="prefix__84e2d8d9-a0b8-46e8-aefb-3e7e3257e825"
          d="M12 6a2 2 0 002-2c0-.38-.1-.73-.29-1.03L12 0l-1.71 2.97c-.19.3-.29.65-.29 1.03 0 1.1.9 2 2 2zm4.6 9.99l-1.07-1.07-1.08 1.07c-1.3 1.3-3.58 1.31-4.89 0l-1.07-1.07-1.09 1.07C6.75 16.64 5.88 17 4.96 17c-.73 0-1.4-.23-1.96-.61V21c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-4.61c-.56.38-1.23.61-1.96.61-.92 0-1.79-.36-2.44-1.01zM18 9h-5V7h-2v2H6c-1.66 0-3 1.34-3 3v1.54c0 1.08.88 1.96 1.96 1.96.52 0 1.02-.2 1.38-.57l2.14-2.13 2.13 2.13c.74.74 2.03.74 2.77 0l2.14-2.13 2.13 2.13c.37.37.86.57 1.38.57 1.08 0 1.96-.88 1.96-1.96V12C21 10.34 19.66 9 18 9z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__a93fc0d7-0c40-418e-9b60-52e5fbc6df28" fill="#fff">
          <use xlinkHref="#prefix__84e2d8d9-a0b8-46e8-aefb-3e7e3257e825" />
        </mask>
        <g
          fill="#000"
          fillOpacity={0.54}
          mask="url(#prefix__a93fc0d7-0c40-418e-9b60-52e5fbc6df28)"
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
}

export default AgeDiet;
