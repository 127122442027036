import { addError } from 'redux-flash-messages';
import { ThunkActionType } from 'store/types';

import * as t from 'actions/actionTypes';
import ResponseHandler from 'actions/ResponseHandler';
import { Cycle, Fasting, FastingCycle, Timer } from 'types/fasting';
import settings from 'helpers/settings';
import {
  addEatingStart,
  addFastingStart,
  retrieveFastingData,
  storeFastingCycle,
} from 'api/FastingAPI';
import { isLoading, isNotLoading } from './loadingActions';

export const fastingLoad = () => ({
  type: t.FASTING_LOAD,
});

export const fastingSuccess = (fasting: Fasting) => ({
  type: t.FASTING_SUCCESS,
  fasting,
});

export const fastingError = () => ({
  type: t.FASTING_ERROR,
});

export const startFastingLoad = () => ({
  type: t.START_FASTING_LOAD,
});

export const startFastingSuccess = (timer: Timer) => ({
  type: t.START_FASTING_SUCCESS,
  timer,
});

export const startFastingError = () => ({
  type: t.START_FASTING_ERROR,
});

export const startEatingLoad = () => ({
  type: t.START_EATING_LOAD,
});

export const startEatingSuccess = (timer: Timer) => ({
  type: t.START_EATING_SUCCESS,
  timer,
});

export const startEatingError = () => ({
  type: t.START_EATING_ERROR,
});

export const fastingCycleStore = (cycle: Cycle) => ({
  type: t.FASTING_CYCLE_STORE,
  cycle,
});

export const getFasting = (): ThunkActionType => (dispatch) => {
  dispatch(fastingLoad());
  return retrieveFastingData()
    .then((response) => {
      const resHandler = new ResponseHandler(response);
      if (resHandler.hasError()) {
        throw resHandler.getFlashMsgText();
      } else {
        dispatch(fastingSuccess(resHandler.getNormalizedResponse()));
      }
    })
    .catch((error) => {
      addError({ text: error });
      return dispatch(fastingError());
    });
};

export const addFastingCycle =
  (fastingCycle?: FastingCycle): ThunkActionType =>
  (dispatch) => {
    if (!settings.SHOW_FASTING) {
      return null;
    }
    if (!fastingCycle) {
      return null;
    }

    dispatch(isLoading());
    return storeFastingCycle(fastingCycle as FastingCycle)
      .then((response) => {
        const resHandler = new ResponseHandler(response);

        if (resHandler.hasError()) {
          throw resHandler.getFlashMsgText();
        } else {
          dispatch(fastingCycleStore(resHandler.response.data));
        }

        dispatch(isNotLoading());
      })
      .catch((error) => {
        addError({ text: error });
        dispatch(isNotLoading());
      });
  };

export const startFasting = (): ThunkActionType => (dispatch) => {
  dispatch(startFastingLoad());
  return addFastingStart()
    .then((response) => {
      const resHandler = new ResponseHandler(response);

      if (resHandler.hasError()) {
        throw resHandler.getFlashMsgText();
      } else {
        dispatch(startFastingSuccess(resHandler.response.data));
      }
    })
    .catch((error) => {
      addError({ text: error });
      return dispatch(startFastingError());
    });
};

export const startEating = (): ThunkActionType => (dispatch) => {
  dispatch(startEatingLoad());
  return addEatingStart()
    .then((response) => {
      const resHandler = new ResponseHandler(response);
      if (resHandler.hasError()) throw resHandler.getFlashMsgText();
      else {
        dispatch(startEatingSuccess(resHandler.response.data));
      }
    })
    .catch((error) => {
      addError({ text: error });
      return dispatch(startEatingError());
    });
};
