import React from 'react';

import { SvgIconProps, SVGFillColors } from 'types/svg';

import * as svgrIcons from 'assets/svgr';

function SvgIcon({
  filename,
  fill = SVGFillColors.gray,
  className = '',
  onClick,
}: SvgIconProps) {
  if (typeof filename !== 'string') {
    return filename;
  }

  const iconList = svgrIcons as any;
  const Icon = iconList[filename];

  if (!Icon) {
    return null;
  }

  return (
    <Icon
      fill={fill}
      className={`${filename} ${className}`}
      onClick={onClick}
    />
  );
}

export default SvgIcon;
