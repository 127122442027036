import React, { Suspense } from 'react';
import { BrowserTracing } from '@sentry/tracing';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import '@openfonts/poppins_latin-ext';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import './i18n';

import history from 'helpers/history';
import settings from 'helpers/settings';
import { materialUiTheme } from 'helpers/themeHelper';
import { bugsnagClient } from 'helpers/bugsnagHelper';

import { LoadingAnimation } from 'components/common';

import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';
import store from './store/store';
import routes from './routes';

import 'assets/style/style.sass';

const ErrorBoundary = bugsnagClient
  .getPlugin('react')
  .createErrorBoundary(React);

function onRouteUpdate() {
  if (document.body.classList.contains('modal-open')) {
    document.body.className = document.body.className.replace('modal-open', '');
  }
  window.scrollTo(0, 0);
}

// Init Sentry
Sentry.init({
  dsn: settings.SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0,
});

history.listen(() => onRouteUpdate());

render(
  <ErrorBoundary>
    <Suspense fallback={<LoadingAnimation />}>
      <Provider store={store}>
        <ThemeProvider theme={createTheme(materialUiTheme())}>
          <Router history={history}>{routes}</Router>
        </ThemeProvider>
      </Provider>
    </Suspense>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
