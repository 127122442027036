/* eslint-disable camelcase */
import { Observable, of } from 'rxjs';
import Pusher from 'pusher-js';
// eslint-disable-next-line import/no-cycle
import store from 'store/store';
import * as types from 'actions/actionTypes';
import * as chatActions from 'actions/chatActions';
import ResponseHandler from 'actions/ResponseHandler';
import { addError } from 'redux-flash-messages';
import {
  map,
  switchMap,
  mergeMap,
  tap,
  ignoreElements,
  debounceTime,
  catchError,
} from 'rxjs/operators';
import { getCableEndpoint } from 'api/SessionAPI';
import { loadOldMessages, sendMessage } from 'api/ChatAPI';

let socket$;
let msgBuffer = [];

const chatSocketObservable = () =>
  Observable.create((observer) => {
    observer.next(chatActions.chatSocketConnectAction());

    const {
      rootReducer: {
        chat: {
          cableEndpoint: {
            action,
            app_key,
            cluster,
            auth_endpoint,
            token,
            socket_channel,
          },
        },
      },
    } = store.getState();

    socket$ = new Pusher(app_key, {
      cluster,
      authEndpoint: auth_endpoint,
      forceTLS: true,
      auth: {
        headers: {
          Authorization: token,
        },
      },
    });
    socket$.connect();

    socket$.connection.bind('error', () => {
      observer.next(chatActions.chatSocketConnectErrorAction());
    });

    const channel = socket$.subscribe(socket_channel);
    channel.bind('pusher:subscription_succeeded', () => {
      observer.next(chatActions.chatSocketConnectSuccessAction());
    });

    channel.bind(action, (data) => {
      observer.next(chatActions.chatSendMessageSuccessAction(data));
    });
  });

export const chatSocketConnectEpic = (action$) =>
  action$
    .ofType(types.CHAT_INITIAL_MESSAGES.SUCCESS)
    .pipe(switchMap(() => chatSocketObservable()));

export const chatSocketDisconnectEpic = (action$) =>
  action$.ofType(types.CHAT_SOCKET_DISCONNECT.ACTION).pipe(
    switchMap(() =>
      Observable.create((observer) => {
        if (socket$) {
          socket$.disconnect();
          socket$ = null;
        }
        observer.next(chatActions.chatSocketDisconnectSuccessAction());
      })
    )
  );

export const chatSendMessageEpic = (action$) =>
  action$.ofType(types.CHAT_SEND_MESSAGE.ACTION).pipe(
    switchMap(({ payload }) =>
      sendMessage(payload, socket$.connection.socket_id).pipe(
        map((response) => chatActions.chatSendMessageSuccessAction(response)),
        catchError((err) => {
          const resHandler = new ResponseHandler(err.response);
          addError({ text: resHandler.getFlashMsgText });
          return of(chatActions.chatSendMessageErrorAction());
        })
      )
    )
  );

export const chatInitialMessagesEpic = (action$) =>
  action$.ofType(types.CHAT_INITIAL_MESSAGES.ACTION).pipe(
    switchMap(() =>
      loadOldMessages().pipe(
        map((response) =>
          chatActions.chatInitialMessagesSuccessAction(response)
        ),
        catchError((err) => {
          const resHandler = new ResponseHandler(err.response);
          addError({ text: resHandler.getFlashMsgText });
          return of(chatActions.chatInitialMessagesErrorAction());
        })
      )
    )
  );

export const chatOldMessagesEpic = (action$) =>
  action$.ofType(types.CHAT_OLD_MESSAGES.ACTION).pipe(
    switchMap(({ payload }) =>
      loadOldMessages(payload).pipe(
        map((response) => chatActions.chatOldMessagesSuccessAction(response)),
        catchError((err) => {
          const resHandler = new ResponseHandler(err.response);
          addError({ text: resHandler.getFlashMsgText });
          return of(chatActions.chatOldMessagesErrorAction());
        })
      )
    )
  );

export const chatSubscribeEpic = (action$) =>
  action$.ofType(types.CHAT_SUBSCRIBE.ACTION).pipe(
    mergeMap(() =>
      socket$
        .multiplex(
          () => ({
            command: 'subscribe',
            identifier: JSON.stringify({
              channel: 'V1::MessagesByUsersChannel',
            }),
          }),
          () => true,
          (data) => !['ping', 'welcome'].includes(data.type)
        )
        .pipe(
          map((data) => {
            switch (data.type) {
              case 'confirm_subscription':
                return chatActions.chatSubscribeSuccessAction();
              case 'new_message_success':
                return chatActions.chatAddMessageSentAction(data.message);
              case 'new_message_error':
                addError({ text: 'Error' });
                return chatActions.chatAddMessageErrorAction(data.message);
              case 'messages':
                if (data.message.length === 0) {
                  return chatActions.chatAllMessagesLoadedAction();
                }
                return chatActions.chatGetMessagesSuccessAction(data.message);

              default:
                return chatActions.chatAddMessageSuccessAction(data.message);
            }
          }),
          catchError(() => of(chatActions.chatDisconnectedAction()))
        )
    )
  );

export const chatGetInitialMessagesEpic = (action$) =>
  action$.ofType(types.CHAT_SUBSCRIBE.SUCCESS).pipe(
    tap(() => {
      const msg = {
        command: 'message',
        identifier: JSON.stringify({
          channel: 'V1::MessagesByUsersChannel',
        }),
        data: JSON.stringify({
          action: 'serve_messages',
          page_size: 10,
        }),
      };
      socket$.next(msg);
    }),
    ignoreElements()
  );

export const chatGetMessagesEpic = (action$) =>
  action$.ofType(types.CHAT_GET_MESSAGES.ACTION).pipe(
    tap(({ payload }) => {
      const msg = {
        command: 'message',
        identifier: JSON.stringify({
          channel: 'V1::MessagesByUsersChannel',
        }),
        data: JSON.stringify({
          action: 'serve_messages',
          ...payload,
        }),
      };
      socket$.next(msg);
    }),
    ignoreElements()
  );

export const chatAddMessageEpic = (action$) =>
  action$.ofType(types.CHAT_ADD_MESSAGE.ACTION).pipe(
    tap(({ payload }) => msgBuffer.push(payload)),
    debounceTime(1000),
    tap(() => {
      const msg = {
        command: 'message',
        identifier: JSON.stringify({
          channel: 'V1::MessagesByUsersChannel',
        }),
        data: JSON.stringify({
          action: 'receive_messages',
          messages: msgBuffer,
        }),
      };
      socket$.next(msg);
      msgBuffer = [];
    }),
    ignoreElements()
  );

export const getCableUrlEpic = (action$) =>
  action$.ofType(types.CABLE_GET_URL.ACTION).pipe(
    switchMap(() =>
      getCableEndpoint().pipe(
        map((response) => chatActions.getCableUrlSuccessAction(response)),
        catchError((err) => {
          const resHandler = new ResponseHandler(err.response);
          addError({ text: resHandler.getFlashMsgText });
          return of(chatActions.getCableUrlErrorAction());
        })
      )
    )
  );
