import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { isObjectEmpty } from 'helpers/accessItemsHelper';
import { AccessItemsData } from 'types/access';

import { getAccessItems } from 'actions/accessActions';

import { LoadingAnimation } from 'components/common';

type Props = {
  children: React.ReactNode;
};

function EnsureAccessItemsLoaded({ children }: Props) {
  const dispatch = useDispatch();
  const accessItems = useSelector<any, AccessItemsData>(
    (store) => store.rootReducer.accessItems
  );

  useEffect(() => {
    if (isObjectEmpty(accessItems)) dispatch(getAccessItems());
  }, [accessItems, dispatch]);

  if (isObjectEmpty(accessItems) || accessItems.isLoading) {
    return <LoadingAnimation />;
  }
  if (accessItems.hasError) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
}

export default EnsureAccessItemsLoaded;
