import React from 'react';

import { SVG } from 'types/svg';

function PremiumBackButtonHealthgenom(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          id="prefix__1ffbd16e-ac52-483b-a30e-74ef22da5100"
          d="M30.82 14.82L28 12 16 24l12 12 2.82-2.82L21.66 24z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__29439503-7ff5-498b-a71a-2b5152372add" fill="#fff">
          <use xlinkHref="#prefix__1ffbd16e-ac52-483b-a30e-74ef22da5100" />
        </mask>
        <g
          fill="#2884AA"
          mask="url(#prefix__29439503-7ff5-498b-a71a-2b5152372add)"
        >
          <path d="M0 0h48v48H0z" />
        </g>
      </g>
    </svg>
  );
}

export default PremiumBackButtonHealthgenom;
