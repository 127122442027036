import i18n from 'i18n';
import { addError } from 'redux-flash-messages';

import { api } from 'helpers/api';

import { MetaData, PrimerPayment, PrimerSession } from 'types/primer';
import { getPrimerPaymentId } from 'helpers/paymentHelper';

export const createPrimerSessionToken = async (
  userId: number,
  offerId: number,
  metaData: MetaData
): Promise<PrimerSession> => {
  try {
    const primerPaymodeId = getPrimerPaymentId();

    return await api(`pay/checkout/${offerId}/${userId}/${primerPaymodeId}`, {
      method: 'POST',
      body: metaData,
    });
  } catch (e) {
    addError({ text: i18n.t('error.500_error') });
    throw e;
  }
};

export const createPrimerPayment = async (
  clientSessionToken: string,
  paymentMethodToken: string
): Promise<PrimerPayment | boolean> => {
  try {
    return await api(`pay/primer/payments`, {
      method: 'POST',
      body: {
        session_identifier: clientSessionToken,
        payment_method_token: paymentMethodToken,
      },
    });
  } catch (e) {
    addError({ text: i18n.t('error.500_error') });
    return false;
  }
};

export const resumePrimerPayment = async (
  invoiceId: number,
  resumeToken: string
) => {
  try {
    return await api(`pay/primer/payments/${invoiceId}/resume`, {
      method: 'PATCH',
      body: {
        resume_token: resumeToken,
      },
    });
  } catch (e) {
    return false;
  }
};
