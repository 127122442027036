import React from 'react';

import { SVG } from 'types/svg';

function Send(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6.87 17.63l1.75 5.45 2.62-3.7-4.37-1.75zM19.63 5.25L9.11 16.83l10.52 4.56L24 .92 0 12.88l6.37 2.77 13.26-10.4z" />
    </svg>
  );
}

export default Send;
