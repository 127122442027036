import { Frequency } from 'types/payment';
import { AccessItems, AccessName, AccessType, Item } from 'types/access';

export const isBasicProAccess = (accessItems: AccessItems): boolean => {
  if (!accessItems[AccessName.proSubscription]) {
    return false;
  }

  return true;
};

export const isChatAccess = (accessItems: AccessItems): boolean => {
  if (!accessItems[AccessName.chat]) {
    return false;
  }

  return true;
};

export const isShoppingListAccess = (accessItems: AccessItems): boolean => {
  if (!accessItems[AccessName.shoppingList]) {
    return false;
  }

  return true;
};

export const isPremiumUpsellAccess = (accessItems: AccessItems): boolean => {
  if (!accessItems[AccessName.premiumUpsell]) {
    return false;
  }

  return true;
};

export const getAccessType = (
  accessItems: AccessItems,
  accessName: AccessName
): AccessType => {
  const basicProItem = accessItems[accessName];

  if (basicProItem?.subscription_id) {
    return AccessType.subscription;
  }
  if (basicProItem?.payment_id) {
    return AccessType.payment;
  }

  return AccessType.free;
};

export const getAccessItemData = (
  accessItems: AccessItems,
  accessName: AccessName
) => {
  const accessItem = accessItems[accessName];

  if (!accessItem) return null;

  return accessItem;
};

export const isAccessItemSubscription = (item: Item): boolean => {
  if (!item.subscription_id) return false;

  return true;
};

export const isAccessItemPayment = (item: Item): boolean => {
  if (!item.payment_id) return false;

  return true;
};

export const getSubscriptionItemMonthsNumber = (subFrequency: number) => {
  if (subFrequency === Frequency.threeMonth) {
    return 3;
  }
  if (subFrequency === Frequency.sixMonth) {
    return 6;
  }
  if (subFrequency === Frequency.year) {
    return 12;
  }

  return 1;
};

export const isObjectEmpty = (currentObject: object): boolean =>
  Object.keys(currentObject).length === 0;

export const getFreeItems = (accessItems: AccessItems) =>
  Object.keys(accessItems).filter(
    (item) => getAccessType(accessItems, item as AccessName) === AccessType.free
  );
