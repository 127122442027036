import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import browserHistory from 'helpers/history';
import { connect } from 'react-redux';
import { isUserFree } from 'helpers/userHelper';
import isEmpty from 'lodash/isEmpty';

class EnsureUserIsPro extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    children: PropTypes.array,
  };

  componentDidMount = () => this.checkIfUserIsFree();

  componentDidUpdate = () => this.checkIfUserIsFree();

  checkIfUserIsFree = () => {
    const { user } = this.props;
    if (!isEmpty(user) && isUserFree(user)) {
      browserHistory.replaceLater('/program');
    }
  };

  render() {
    const { user, children } = this.props;
    if (!isEmpty(user) && !isUserFree(user)) {
      return children;
    }
    return <Redirect to="/program" />;
  }
}

const mapStateToProps = (state) => ({
  user: state.rootReducer.user,
});

export default connect(mapStateToProps)(EnsureUserIsPro);
