import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Icon } from 'assets/images/modals/unsubscribe/keto_broken_heart.svg';
import { SubmitButton } from 'components/common';
import CommonModal from 'components/common/CommonModal';

import s from './WorkoutsUnsubscribeCompleted.module.scss';

const WorkoutsUnsubscribeCompleted = ({
  handleClose,
}: {
  handleClose: Function;
}) => {
  const { t } = useTranslation('workouts');

  const icon = <Icon />;

  const title = (
    <>
      <div className={s.textMargin}>
        {t('unsubscribe.unsubscribe_completed.title_1')}
      </div>
      <div>{t('unsubscribe.unsubscribe_completed.title_2')}</div>
    </>
  );

  const body = (
    <>
      <div className={s.textMargin}>
        {t('unsubscribe.unsubscribe_completed.text_1')}
      </div>
      <div>{t('unsubscribe.unsubscribe_completed.text_2')}</div>
    </>
  );

  const buttons = (
    <SubmitButton
      className={s.submitButton}
      type="large"
      label={t('unsubscribe.unsubscribe_completed.ok_label')}
      onClick={() => handleClose()}
    />
  );

  return (
    <CommonModal
      icon={icon}
      title={title}
      body={body}
      buttons={buttons}
      onClose={handleClose}
    />
  );
};

export default WorkoutsUnsubscribeCompleted;
