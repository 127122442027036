import React from 'react';

import { SVG } from 'types/svg';

function PasswordKeto(props: SVG) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <defs>
        <path
          id="prefix__d248de8a-6d4e-4bac-89b1-07b6b11eb350"
          d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__2949375c-8727-4c12-a575-165d4398149e" fill="#fff">
          <use xlinkHref="#prefix__d248de8a-6d4e-4bac-89b1-07b6b11eb350" />
        </mask>
        <g
          fill="#264653"
          mask="url(#prefix__2949375c-8727-4c12-a575-165d4398149e)"
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
}

export default PasswordKeto;
