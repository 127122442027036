import React from 'react';

import { SVG } from 'types/svg';

function Day(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.42 7.28l-.76-.76a1.87 1.87 0 0 0 .34-.28 2 2 0 0 0 0-2.8L20.56 1a2 2 0 0 0-2.8 0 1.6 1.6 0 0 0-.28.35l-.76-.77a2 2 0 0 0-2.81 0 2 2 0 0 0 0 2.8l1.94 1.94L5.32 15.85l-1.94-1.94a2 2 0 0 0-2.8 2.81l.76.76a1.87 1.87 0 0 0-.35.28 2 2 0 0 0 0 2.8L3.44 23a2 2 0 0 0 1.4.58 1.94 1.94 0 0 0 1.4-.58 2.26 2.26 0 0 0 .28-.35l.76.76a2 2 0 0 0 2.81 0 2 2 0 0 0 0-2.8l-1.94-1.93L18.68 8.15l1.94 1.94a2 2 0 0 0 2.8 0 2 2 0 0 0 0-2.81zM5.16 21.93a.45.45 0 0 1-.63 0l-2.46-2.46a.45.45 0 0 1 0-.63.48.48 0 0 1 .32-.13.46.46 0 0 1 .31.13l2.46 2.46a.45.45 0 0 1 0 .63zM18.71 2.39a.44.44 0 0 1 .45-.45.46.46 0 0 1 .31.13l2.46 2.46a.45.45 0 0 1 0 .63.46.46 0 0 1-.63 0L18.84 2.7a.44.44 0 0 1-.13-.31zM9 21.7a.45.45 0 0 1 0 .63.44.44 0 0 1-.63 0l-6.7-6.7a.42.42 0 0 1-.13-.32.4.4 0 0 1 .13-.31.42.42 0 0 1 .33-.13.44.44 0 0 1 .3.13l1.93 1.94 2.83 2.82zm8.59-14.64L7.06 17.59l-.65-.65L16.94 6.41zM22.33 9a.45.45 0 0 1-.63 0l-4.12-4.12-.64-.65L15 2.3a.45.45 0 0 1 0-.63.43.43 0 0 1 .31-.13.44.44 0 0 1 .32.13l6.7 6.7a.45.45 0 0 1 0 .63z" />
    </svg>
  );
}

export default Day;
