import React from 'react';

function UnsubscribeV8SuccessKetogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={104}
      height={104}
      fill="none"
    >
      <circle cx={52} cy={52} r={52} fill="url(#a)" />
      <path
        fill="#fff"
        d="M48.735 37.092a8.382 8.382 0 0 0 6.235-2.805 8.388 8.388 0 0 0 2.03-6.59 8.388 8.388 0 0 0-3.524-5.793 8.392 8.392 0 0 0-6.825-1.216 8.401 8.401 0 0 0-5.292 4.188 8.397 8.397 0 0 0-.379 6.94 8.407 8.407 0 0 0 4.796 4.735c.943.359 1.95.54 2.96.54Z"
      />
      <path
        fill="#fff"
        d="M69.408 20.443a2.479 2.479 0 0 0-3.45.622s-7.74 11.799-10.393 14.959c-1.98 2.358-3.867 3.016-6.71 2.623-1.404-.194-18.8-4.519-18.8-4.519a2.502 2.502 0 0 0-2.918 1.59 2.502 2.502 0 0 0 1.18 3.012c.098.051.199.096.302.134l18.868 6.945c.108.325 1.033 2.828 1.558 5.448.334 1.667.476 3.365.572 5.06.068 1.19.05 2.379.036 3.569-.014 1.226.187 2.426.673 3.557a79.918 79.918 0 0 1-.254 4.64c-.144 1.69-.409 3.369-.685 5.043-.349 2.115-.895 2.928-.932 2.983-.677.256-1.497.169-2.198.077a16.432 16.432 0 0 1-2.472-.545 26.803 26.803 0 0 1-2.106-.717c-.55-.212-1.086-.454-1.628-.684-.252-.108-.503-.226-.78-.286a3.018 3.018 0 0 0-.87-.06 3.059 3.059 0 0 0-1.598.602 3.06 3.06 0 0 0-1.115 3.162 3.05 3.05 0 0 0 1.52 1.94c2.418 1.322 5.005 2.457 7.708 3.043 1.463.318 2.982.474 4.472.26 1.548-.222 3.02-.871 4.144-1.973a7.024 7.024 0 0 0 .728-.84c.122-.203.277-.39.406-.59a13.1 13.1 0 0 0 .609-1.045c.374-.716.695-1.46.98-2.215.571-1.516 1.014-3.08 1.394-4.654.249-1.033.471-2.073.672-3.117.421-.018.838-.067 1.251-.148a49.94 49.94 0 0 1 2.028 4.68c-1.574.614-2.344 2.468-1.675 4.019.453 1.05 1.003 2.053 1.776 2.906.84.928 1.903 1.648 3.117 1.982a4.9 4.9 0 0 0 2.043.14 3.322 3.322 0 0 0 2.759-2.723c.527-3.104.023-6.277-.69-9.312a59.459 59.459 0 0 0-1.382-4.874c-.344-1.05-.7-2.103-1.113-3.13-.082-.203-.16-.408-.247-.61.102-.682.11-1.366.054-2.053-.057-.687-.104-1.375-.175-2.06a57.338 57.338 0 0 0-.698-4.841c-.636-3.334-1.53-6.63-2.844-9.764a32.882 32.882 0 0 0-1.167-2.51l8.821-16.522a2.479 2.479 0 0 0-.771-3.204c1.043.726-1.125-.78 0 0Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={52}
          x2={156}
          y1={156}
          y2={52}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#264653" />
          <stop offset={0.44} stopColor="#2A9D8F" />
          <stop offset={1} stopColor="#E9C46A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default UnsubscribeV8SuccessKetogo;
