import React from 'react';
import { useSelector } from 'react-redux';

import { InternalWorkoutState } from 'types/workouts';
import browserHistory from 'helpers/history';

type Props = {
  children: React.ReactNode;
};

function EnsureInternalWorkoutInprogress({ children }: Props) {
  const workouts = useSelector<any, InternalWorkoutState>(
    (store) => store.rootReducer.workouts
  );

  if (!workouts || !workouts.inprogressWorkoutState) {
    browserHistory.replace('/program');
    return null;
  }

  return <div>{children}</div>;
}

export default EnsureInternalWorkoutInprogress;
