export enum QuizLinks {
  followed_7_days = '/unsubscribe/unsubscribev6-quiz-1',
  already_achieved_goals = '/unsubscribe/unsubscribev6-quiz-2',
  tried_premium_upsell = '/unsubscribe/unsubscribev6-quiz-3a',
  time_investment = '/unsubscribe/unsubscribev6-quiz-3b',
}

export enum ObjectionLinks {
  try_7_days = '/unsubscribe/unsubscribev6-objection-1',
  try_different_meals = '/unsubscribe/unsubscribev6-objection-2',
  try_premium_upsell = '/unsubscribe/unsubscribev6-objection-3a',
}

export enum QuizQuestions {
  followed_7_days = 'followed_7_days',
  already_achieved_goals = 'already_achieved_goals',
  tried_premium_upsell = 'tried_premium_upsell',
  time_investment = 'time_investment',
}

export enum Objections {
  try_7_days = 'try_7_days',
  try_different_meals = 'try_different_meals',
  try_premium_upsell = 'try_premium_upsell',
}

type StepType = {
  [key: string]: string;
};

export const QUIZ_STEPS: StepType = {
  '1': QuizQuestions.followed_7_days,
  '2': QuizQuestions.already_achieved_goals,
  '3a': QuizQuestions.tried_premium_upsell,
  '3b': QuizQuestions.time_investment,
};

export const OBJECTIONS_STEPS: StepType = {
  '1': Objections.try_7_days,
  '2': Objections.try_different_meals,
  '3a': Objections.try_premium_upsell,
};

export type QueryParams = {
  step: string;
  [key: string]: string;
};

export enum QuizTestimonial {
  person1 = 'person_1',
  person2 = 'person_2',
  person3 = 'person_3',
}

export const ALLOWED_STEPS = ['1', '2', '3a', '3b'];

export enum ModalSteps {
  quote = 'quote',
  meal_survey = 'meal_survey',
  premium_upsell = 'premium_upsell',
  investment = 'investment',
  lock = 'lock',
}

export const MODAL_STEPS: {
  [key: string]: ModalSteps;
} = {
  quote: ModalSteps.quote,
  meal_survey: ModalSteps.meal_survey,
  premium_upsell: ModalSteps.premium_upsell,
  lock: ModalSteps.lock,
  investment: ModalSteps.investment,
};

export enum PremiumPrice {
  default = '100€',
}

export enum MinUserLoseWeight {
  lbs = 10,
  kg = 5,
}
