import React from 'react';

import { SVG } from 'types/svg';

function GenderHealthgenom(props: SVG) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} />
        <path
          d="m23.175 17.183.517-.49a.945.945 0 0 0 .308-.7.96.96 0 0 0-.304-.701A1.098 1.098 0 0 0 22.95 15h-.004c-.281 0-.544.105-.746.292l-.522.486-.825-.775a5.948 5.948 0 0 0 1.317-3.75c0-1.685-.698-3.266-1.965-4.456C18.937 5.61 17.253 4.95 15.46 4.95c-.78 0-1.538.12-2.254.366C12.007 4.454 10.57 4 9.044 4c-1.841 0-3.57.673-4.872 1.895-1.3 1.222-2.017 2.846-2.017 4.575 0 1.398.457 2.712 1.331 3.822l-.975.915.041-.27c.072-.54-.33-1.04-.907-1.114-.581-.07-1.114.31-1.185.849l-.45 3.166a.303.303 0 0 0-.008.11v.056a.735.735 0 0 0 .019.18c.037.19.135.366.285.507.199.186.465.285.746.285.038 0 .075 0 .143-.007l3.375-.43c.281-.035.529-.169.698-.384a.937.937 0 0 0 .202-.732c-.071-.543-.615-.923-1.185-.849L4 16.613l.975-.916c1.181.817 2.584 1.247 4.069 1.247.915 0 1.8-.166 2.633-.49a6.928 6.928 0 0 0 3.784 1.096 7.014 7.014 0 0 0 3.881-1.166l.844.796-.518.49a.958.958 0 0 0-.307.704c0 .264.112.51.307.697.402.377 1.107.37 1.493 0l.521-.49.521.49c.2.187.465.289.75.289.278 0 .548-.106.743-.289a.953.953 0 0 0-.004-1.402l-.517-.486zM9.044 5.986c.731 0 1.444.155 2.093.455a7.749 7.749 0 0 0-.42.36C9.453 7.986 8.75 9.567 8.75 11.255c0 1.3.428 2.57 1.216 3.62-.3.06-.612.088-.923.088a4.931 4.931 0 0 1-3.356-1.292c-.004-.007-.008-.014-.015-.018-.004-.003-.008-.01-.012-.014-.003 0-.003-.003-.007-.003-.896-.846-1.388-1.97-1.388-3.163 0-1.198.495-2.325 1.395-3.177a4.942 4.942 0 0 1 3.383-1.31zm3.169 2.216c.187-.18.394-.342.607-.483a4.288 4.288 0 0 1 1.006 2.755c0 1.197-.5 2.324-1.4 3.173-.15.141-.307.271-.476.391a4.116 4.116 0 0 1-1.08-2.782c-.003-1.156.473-2.24 1.343-3.054zm-.154 6.893c-.03-.025-.064-.046-.094-.074.03.028.06.049.094.074zm.413.292zm.33.194c.018.017.041.028.06.039a7.05 7.05 0 0 1-.357-.205c.098.057.195.113.297.166zm.904-.338c.07-.06.146-.127.213-.19 1.298-1.223 2.014-2.85 2.014-4.576a6.129 6.129 0 0 0-1.087-3.494 4.54 4.54 0 0 1 .618-.042c1.227 0 2.382.447 3.252 1.26.866.822 1.342 1.903 1.342 3.055 0 1.155-.476 2.24-1.342 3.053-1.32 1.233-3.315 1.589-5.01.934z"
          fill="#2884AA"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default GenderHealthgenom;
