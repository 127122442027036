export const getNavbarTabName = (path: string) => {
  if (/shopping-list/.test(path)) {
    return 'shopping-list';
  }
  if (/program/.test(path)) {
    return 'day';
  }
  if (/user|meal_survey/.test(path)) {
    return 'profile';
  }
  if (/chat/.test(path)) {
    return 'chat';
  }
  if (/premium/.test(path)) {
    return 'premium';
  }

  return '';
};

export const urlContainsValues = (lookupValues: string[]) => {
  const url = window.location.href;
  return lookupValues.some((path) => url.includes(path));
};
