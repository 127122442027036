import { useEffect, useState } from 'react';

import { getOfferByIdLegacy } from 'api/OfferAPI';
import { Offer } from 'types/offer';

type Props = {
  id: number;
};

function useOfferById({ id }: Props): {
  loadingOffer: boolean;
  offer: Offer | null;
} {
  const [loadingOffer, setLoadingOffer] = useState(true);
  const [offer, setOffer] = useState<Offer | null>(null);

  useEffect(() => {
    const loadOffer = async () => {
      const receivedOffer = await getOfferByIdLegacy(id);

      setOffer(receivedOffer as Offer);
      setLoadingOffer(false);
    };

    loadOffer();
  }, [id]);

  return { loadingOffer, offer };
}

export default useOfferById;
